import { MediaCenterConfiguration } from 'src/app/media-center/domain/mediacenter-configuration.model';

export class MediaCenterConfigurationAdapter {
  static adapt(item: any): MediaCenterConfiguration {
    if (!item) {
      return null;
    }

    return {
      infoBannerType: item.infoBannerType,
      languages: item.languages?.map((l: string) => l.toLowerCase()) ?? [],
      infoBannerMaxCharacters: item.infoBannerMaxCharacters,
    };
  }
}
