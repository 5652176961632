import { Component, OnInit } from '@angular/core';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { MeteoLivedataService } from 'src/app/maps/livedata/meteo-livedata.service';
import { SnowmakingDetailViewDisplayData } from 'src/app/maps/widgets/detailviews/snowmaking-detailview/snowmaking-detailview-displaydata.model';

@Component({
  selector: 'sis-snowmaking-detailview',
  templateUrl: './snowmaking-detailview.component.html',
  styleUrls: ['./snowmaking-detailview.component.scss'],
})
export class SnowmakingDetailViewComponent extends Unsubscriber implements OnInit {
  readonly temperatureUnit = '°C';
  readonly windSpeedUnit = 'km/h';

  displayData: SnowmakingDetailViewDisplayData[] = [];

  constructor(private masterDataService: MasterdataService, private meteoLiveDataService: MeteoLivedataService) {
    super();
  }

  ngOnInit(): void {
    this.masterDataService.masterData$
      .pipe(
        switchMap((masterData) =>
          this.meteoLiveDataService.meteoLiveDatas$.pipe(map((meteoLiveDatas) => ({ masterData, meteoLiveDatas })))
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ masterData, meteoLiveDatas }) => {
        if (!masterData || !meteoLiveDatas) {
          this.displayData = [];
          return;
        }

        const meteoStations = masterData.meteoStations.filter((m) => m.snowmakingWidgetOrder != null);

        const meteoDeviceSisIds = meteoStations.map((m) => m.meteoDeviceSisId);

        const meteoLiveData = meteoLiveDatas.filter((l) => meteoDeviceSisIds.includes(l.meteoDeviceSisId));

        this.displayData = meteoStations
          .map(
            (m) =>
              new SnowmakingDetailViewDisplayData(
                m,
                meteoLiveData.find((l) => l.meteoDeviceSisId === m.meteoDeviceSisId)
              )
          )
          .sort((a, b) => a.order - b.order);
      });
  }
}
