import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { combineLatest, filter, map, take, takeUntil, withLatestFrom } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { RopewaySettingsUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewaySettingsService } from 'src/app/maps/domain/ropeway-settings/ropeway-settings.service';

@Component({
  selector: 'sis-settings-modal',
  templateUrl: './ropeway-settings-modal.component.html',
  styleUrls: ['./ropeway-settings-modal.component.scss'],
})
export class RopewaySettingsModal extends Unsubscriber implements OnInit {
  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;
  readonly isAdministrator$ = this.userSettingsService.userSettings$.pipe(map((settings) => settings.isAdministrator));
  readonly hasAvailabilityFeatureWritePermission$ = this.destinationService.selectedTenantFeatures$.pipe(
    map((features) =>
      features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_ROPEWAY_AVAILABILITY, FeatureAccessLevel.WRITE))
      )
    )
  );
  readonly hasAvailabilityFeatureReadPermission$ = this.destinationService.selectedTenantFeatures$.pipe(
    map((features) =>
      features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_ROPEWAY_AVAILABILITY, FeatureAccessLevel.READ))
      )
    )
  );

  @Input() ropeway: Ropeway;
  @Input() tenantGuid: string;

  formGroup: FormGroup;
  editedSettings: Ropeway;
  edited: boolean = false;
  saving: boolean = false;
  propertyColSize: number = 4;
  private isAdministrator: boolean;

  constructor(
    private modalCtrl: ModalController,
    private screenSizeService: ScreenSizeService,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService,
    private ropewaySettingsService: RopewaySettingsService,
    private destinationService: DestinationService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    combineLatest([
      this.hasAvailabilityFeatureReadPermission$,
      this.hasAvailabilityFeatureWritePermission$,
      this.isAdministrator$,
    ])
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(([availabilityReadPermission, availabilityWritePermission, isAdmin]) => {
        this.formGroup = new FormGroup(
          {
            fullname: new FormControl<string>(this.ropeway.fullname, { validators: [Validators.required] }),
            alias: new FormControl<string>(this.ropeway.alias, [Validators.required]),
            constructedBy: new FormControl<string>(this.ropeway.constructedBy, [Validators.required]),
            controllerType: new FormControl<string>(this.ropeway.controllerType, [Validators.required]),
            maxTransportQuantityPerHour: new FormControl<number>(this.ropeway.maxTransportQuantityPerHour),
            operatingTimeFrom: new FormControl<string>(this.ropeway.operatingTimeFrom),
            operatingTimeUntil: new FormControl<string>(this.ropeway.operatingTimeUntil),
          },
          {
            validators: [this.validateDatetime()],
          }
        );

        if (availabilityReadPermission) {
          this.formGroup.addControl('availability', new FormControl<boolean>(this.ropeway.availability));

          if (!availabilityWritePermission) {
            this.formGroup.controls.availability.disable();
          }
        }

        this.isAdministrator = isAdmin;
        if (!this.isAdministrator) {
          this.formGroup.controls.fullname.disable({ emitEvent: false });
          this.formGroup.controls.alias.disable({ emitEvent: false });
          this.formGroup.controls.constructedBy.disable({ emitEvent: false });
          this.formGroup.controls.controllerType.disable({ emitEvent: false });
          this.formGroup.controls.maxTransportQuantityPerHour.disable({ emitEvent: false });
        }

        this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((values) => {
          this.editedSettings.fullname = values.fullname;
          this.editedSettings.alias = values.alias;
          this.editedSettings.constructedBy = values.constructedBy;
          this.editedSettings.controllerType = values.controllerType;
          if (values.maxTransportQuantityPerHour != null) {
            this.editedSettings.maxTransportQuantityPerHour = Number.parseInt(values.maxTransportQuantityPerHour);
          }
          this.editedSettings.operatingTimeFrom = values.operatingTimeFrom;
          this.editedSettings.operatingTimeUntil = values.operatingTimeUntil;
          if (values.availability != null) {
            this.editedSettings.availability = values.availability;
          }

          this.edited = this.isEdited();
        });
      });

    this.eventBus
      .observe(RopewaySettingsUpdatedEvent)
      .pipe(
        withLatestFrom(this.userSettingsService.userSettings$),
        filter(([event, userSettings]) => event.changedBy === userSettings.userGuid),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([event]) => {
        if (event.ropewaySettings.guid === this.ropeway.guid && event.updateSuccessful) {
          this.close();
        }
        this.saving = false;
      });

    this.reset();
  }

  isEdited(): boolean {
    let edited = false;

    if (this.isAdministrator) {
      edited =
        this.editedSettings.fullname !== this.ropeway.fullname ||
        this.editedSettings.alias !== this.ropeway.alias ||
        this.editedSettings.controllerType !== this.ropeway.controllerType ||
        this.editedSettings.constructedBy !== this.ropeway.constructedBy ||
        this.editedSettings.maxTransportQuantityPerHour !== this.ropeway.maxTransportQuantityPerHour;
    }
    return (
      edited ||
      this.editedSettings.operatingTimeFrom !== this.ropeway.operatingTimeFrom ||
      this.editedSettings.operatingTimeUntil !== this.ropeway.operatingTimeUntil ||
      this.editedSettings.availability !== this.ropeway.availability
    );
  }

  save(): void {
    if (!this.saving) {
      if (!this.edited) {
        this.close();
      } else {
        this.ropewaySettingsService
          .saveRopewaySettings({
            fullname: this.editedSettings.fullname,
            alias: this.editedSettings.alias,
            guid: this.editedSettings.guid,
            constructedBy: this.editedSettings.constructedBy,
            controllerType: this.editedSettings.controllerType,
            maxTransportQuantityPerHour: this.editedSettings.maxTransportQuantityPerHour,
            operatingTimeFrom: this.editedSettings.operatingTimeFrom,
            operatingTimeUntil: this.editedSettings.operatingTimeUntil,
            availability: this.editedSettings.availability,
            sisId: this.editedSettings.sisId,
            tenantGuid: this.tenantGuid,
            availabilityIsAvailable: this.editedSettings.availabilityIsAvailable,
          })
          .subscribe(() => (this.saving = false));

        this.saving = true;
      }
    }
  }

  validateDatetime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const availability = control.get('availability')?.value as boolean;

      if (availability === undefined || availability === false) {
        return null;
      }

      const operatingTimeFrom = control.get('operatingTimeFrom');
      const operatingTimeUntil = control.get('operatingTimeUntil');

      const errors: ValidationErrors = {};

      if (!operatingTimeFrom.value || operatingTimeFrom.value === '') {
        errors.operatingTimeFrom = true;
      }

      if (!operatingTimeUntil.value || operatingTimeUntil.value === '') {
        errors.operatingTimeUntil = true;
      }

      return errors;
    };
  }

  reset(): void {
    this.editedSettings = { ...this.ropeway };

    this.formGroup.controls.fullname.setValue(this.ropeway.fullname, { emitEvent: false });
    this.formGroup.controls.alias.setValue(this.ropeway.alias, { emitEvent: false });
    this.formGroup.controls.controllerType.setValue(this.ropeway.controllerType, { emitEvent: false });
    this.formGroup.controls.constructedBy.setValue(this.ropeway.constructedBy, { emitEvent: false });
    this.formGroup.controls.maxTransportQuantityPerHour.setValue(this.ropeway.maxTransportQuantityPerHour, {
      emitEvent: false,
    });
    this.formGroup.controls.operatingTimeFrom.setValue(this.ropeway.operatingTimeFrom, { emitEvent: false });
    this.formGroup.controls.operatingTimeUntil.setValue(this.ropeway.operatingTimeUntil, { emitEvent: false });
    this.formGroup.controls.availability?.setValue(this.ropeway.availability, { emitEvent: false });

    this.edited = false;
  }

  close(): void {
    this.modalCtrl.dismiss();
  }
}
