import { SisConverter } from 'src/app/core/utils/sis-converter';
import { MeteoInfoItem } from 'src/app/meteo-info/domain/meteo-info-item.model';

export class MeteoInfoItemAdapter {
  static adapt(items: any[]): MeteoInfoItem[] {
    if (!items) {
      return [];
    }

    return items
      .map((i) => ({
        guid: i.guid,
        tenantGuid: i.tenantGuid,
        location: i.location,
        altitude: i.altitude,
        temperature: SisConverter.toNumberOrUndefined(i.temperature),
        windSpeed: i.windSpeed == null ? null : SisConverter.toNumberOrUndefined(i.windSpeed),
        latestSnowfallUtc: SisConverter.toDateOrUndefined(i.latestSnowfall),
        snowDepth: SisConverter.toNumberOrUndefined(i.snowDepth),
        freshSnow: SisConverter.toNumberOrUndefined(i.freshSnow),
        avalancheDangerLevel: SisConverter.toNumberOrUndefined(i.avalancheDangerLevel),
        snowConditionId: SisConverter.toNumberOrUndefined(i.snowConditionId),
        slopeConditionId: SisConverter.toNumberOrUndefined(i.slopeConditionId),
        hasSTnetExport: i.hasSTnetExport,
        hasWeatherImport: i.hasWeatherImport,
        weather: i.weather,
        lastChange: SisConverter.toDateOrUndefined(i.lastChange),
        changedBy: i.changedBy,
        changedByEmail: i.changedByEmail,
      }))
      .sort((a, b) => a.altitude - b.altitude);
  }
}
