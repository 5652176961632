import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { Export } from 'src/app/domain/export/export.model';
import { ExportService } from 'src/app/domain/export/export.service';
import { Parking } from 'src/app/parking/domain/parking.model';
import { ParkingService } from 'src/app/parking/domain/parking.service';
import { ParkingExportMapping } from 'src/app/parking/domain/parking-export-mapping.model';
import { ParkingExportMappingPostData } from 'src/app/parking/domain/parking-export-mapping-postdata.model';
import { ParkingSetting } from 'src/app/parking/domain/parking-setting.enum';

@Component({
  selector: 'sis-parking-exports-edit-modal',
  templateUrl: './parking-exports-edit-modal.component.html',
  styleUrls: ['./parking-exports-edit-modal.component.scss'],
})
export class ParkingExportsEditModalComponent extends Unsubscriber implements OnInit {
  private readonly reset$ = new Subject<void>();

  @Input() parking: Parking;

  bigScreenMode: boolean;

  selectableExports: ParkingExportMapping[] = [];

  isUpdating: boolean;
  isEdited: boolean[] = [];
  hasUnsavedChanges: boolean;

  constructor(
    private modalCtrl: ModalController,
    private screenSizeService: ScreenSizeService,
    private parkingService: ParkingService,
    private exportService: ExportService
  ) {
    super();
  }

  ngOnInit() {
    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.reset$
      .pipe(
        switchMap(() => this.exportService.exports$),
        takeUntil(this.onDestroy$)
      )
      .subscribe((tenantExports) => {
        const selectableExports: ParkingExportMapping[] = [];
        tenantExports.forEach((e) => selectableExports.push({ export: e, enabled: false }));

        this.parking.exports.forEach((e) => {
          const mapping = selectableExports.find((s) => s.export.guid === e.guid);
          if (mapping != null) {
            mapping.enabled = true;
          }
        });

        this.selectableExports = selectableExports;
        this.checkForChanges();
      });

    this.parking
      .getUpdating(ParkingSetting.exportMappings)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((updating) => {
        this.isUpdating = updating;
        if (!updating) {
          this.reset();
        }
      });
  }

  checkForChanges(): void {
    this.hasUnsavedChanges = this.selectableExports.some(
      (e) =>
        (e.enabled && !this.parking.exports.find((i) => i.guid === e.export.guid)) ||
        (!e.enabled && this.parking.exports.find((i) => i.guid === e.export.guid))
    );
  }

  toggleExport(value: { export: Export; enabled: boolean }): void {
    value.enabled = !value.enabled;
    this.checkForChanges();
  }

  async save(): Promise<void> {
    const postData: ParkingExportMappingPostData = {
      parkingGuid: this.parking.guid,
      exportGuids: this.selectableExports.filter((e) => e.enabled).map((e) => e.export.guid),
    };

    await this.parkingService.postExportMappings(postData, this.parking);
    await this.modalCtrl.dismiss();
  }

  reset(): void {
    this.reset$.next();
  }

  cancel(): void {
    this.reset();
    this.modalCtrl.dismiss();
  }
}
