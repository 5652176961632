import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { ModalPopupComponent } from 'src/app/maps/modal-popup/modal-popup.component';
import { RopewayChartComponent } from 'src/app/maps/widgets/charts/ropeway-chart/ropeway-chart.component';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Component({
  selector: 'sis-ropeway-chart-widget',
  templateUrl: './ropeway-chart-widget.component.html',
})
export class RopewayChartWidgetComponent extends WidgetBase {
  constructor(
    private modalController: ModalController,
    private screenSizeService: ScreenSizeService,
    private userMessageService: UserMessageService
  ) {
    super();
  }

  async openChartModal(): Promise<void> {
    const bigScreenMode = await firstValueFrom(this.screenSizeService.bigScreenMode$);
    if (!bigScreenMode) {
      this.userMessageService.presentToast(
        new UserMessage({
          message: 'general.phrase.bigScreenExclusiveFeature',
          icon: UserMessageIcon.info,
          durationMs: 2000,
          position: 'bottom',
          color: UserMessageColor.blue,
        })
      );
      return;
    }

    const modal = await this.modalController.create({
      component: ModalPopupComponent,
      componentProps: { component: RopewayChartComponent },
      cssClass: 'sis-ropeway-chart-modal',
    });
    return modal.present();
  }
}
