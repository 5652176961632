import { SlideMapping } from 'src/app/media-center/playlists/domain/slide-mapping.model';

export class SlideMappingAdapter {
  static adapt(items: any): SlideMapping[] {
    const result: SlideMapping[] = [];

    if (items) {
      items.forEach((e: any) => {
        result.push({
          slideGuid: e.slideGuid,
          duration: e.duration,
          order: e.order,
        });
      });
    }

    return result;
  }
}
