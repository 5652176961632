import { Injectable } from '@angular/core';
import { BlobItem } from '@azure/storage-blob';
import { Observable, of, OperatorFunction, Subject } from 'rxjs';
import { map, mergeAll, mergeMap, scan, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { BlobStorageService } from 'src/app/core/blob-storage/blob-storage.service';
import {
  FilesSource,
  SisBlobFileRequestParameters,
  SisBlobItemsUpdate,
  SisBlobItemUpload,
  UploadCommand,
} from 'src/app/core/blob-storage/blob-storage.types';
import { BlobStorageContainerService } from 'src/app/core/blob-storage/blob-storage-container.service';

@Injectable({
  providedIn: 'root',
})
export class BlobStorageUploadService {
  private readonly uploadQueueInner$ = new Subject<UploadCommand>();

  readonly uploadProgress$: Observable<SisBlobItemUpload> = this.uploadQueueInner$.pipe(
    mergeMap((uploadCommand) =>
      this.uploadFile(uploadCommand.file, uploadCommand.folder, uploadCommand.fileNamePrefix, uploadCommand.metadata)
    )
  );

  readonly itemsInContainerWithUpload$: Observable<SisBlobItemUpload[]> = this.getItemsInContainerWithUploadProgress();

  constructor(
    private blobStorageService: BlobStorageService,
    private blobStorageContainerService: BlobStorageContainerService
  ) {}

  uploadItems(uploadCommand: UploadCommand): void {
    this.uploadQueueInner$.next(uploadCommand);
  }

  private uploadFile(
    file: File,
    folderName: string,
    fileNamePrefix: string,
    metadata?: {
      [propertyName: string]: string;
    }
  ): Observable<SisBlobItemUpload> {
    return this.blobStorageContainerService.getStorageOptionsWithContainer().pipe(
      switchMap((options) => {
        const fileRequestOptions: SisBlobFileRequestParameters = {
          storageUri: options.storageUri,
          storageAccessToken: options.storageAccessToken,
          containerName: options.containerName,
          filePath: `${folderName}/${fileNamePrefix ?? ''}${file.name}`,
        };

        return this.blobStorageService
          .uploadToBlobStorage(file, fileRequestOptions, metadata)
          .pipe(
            this.mapUploadResponse(file, fileRequestOptions, metadata),
            this.blobStorageContainerService.refreshSelectedContainer(options.containerName)
          );
      })
    );
  }

  private mapUploadResponse(
    file: File,
    options: SisBlobFileRequestParameters,
    metadata?: {
      [propertyName: string]: string;
    }
  ): OperatorFunction<number, SisBlobItemUpload> {
    return (source) =>
      source.pipe(
        map<number, SisBlobItemUpload>((progress) => ({
          filePath: options.filePath,
          fileSize: file.size,
          progress: (progress / file.size) * 100,
          width: metadata?.width,
          height: metadata?.height,
        })),
        startWith({
          filePath: options.filePath,
          fileSize: file.size,
          progress: 0,
          width: metadata?.width,
          height: metadata?.height,
        })
      );
  }

  private getItemsInContainerWithUploadProgress(): Observable<SisBlobItemUpload[]> {
    return of(
      this.blobStorageContainerService.itemsInContainer$.pipe(
        map<BlobItem[], SisBlobItemsUpdate>((blobs) => {
          const sisBlobItems: SisBlobItemUpload[] = blobs.map((blob) => ({
            filePath: blob.name,
            fileSize: blob.properties.contentLength,
            width: blob.metadata?.width,
            height: blob.metadata?.height,
          }));
          return { filesSource: FilesSource.container, blobs: sisBlobItems };
        })
      ),
      this.uploadProgress$.pipe(map((item) => ({ filesSource: FilesSource.upload, blobs: [item] })))
    ).pipe(
      mergeAll(),
      withLatestFrom(this.blobStorageContainerService.blobStorageUrl$),
      map(([update, url]) => {
        update.blobs.forEach((file) => (file.url = `${url}/${file.filePath}`));
        return update;
      }),
      scan((fileMap, update) => {
        update.blobs.forEach((item) => {
          const key = item.url;
          const uploading = item.progress != null && item.progress <= 100;
          const currentFile = fileMap.get(key);

          if (currentFile) {
            currentFile.progress = uploading ? item.progress : null;
            currentFile.height = item.height;
            currentFile.width = item.width;
          } else {
            fileMap.set(key, item);
          }
        });

        if (update.filesSource === FilesSource.container) {
          for (const file of fileMap.values()) {
            if (file.progress == null) {
              const newFile = update.blobs.find((item) => item.url === file.url);
              if (!newFile) {
                fileMap.delete(file.url);
              }
            }
          }
        }

        return fileMap;
      }, new Map<string, SisBlobItemUpload>()),
      map((fileMap) => [...fileMap.values()])
    );
  }
}
