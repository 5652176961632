import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { WebLink } from 'src/app/weblink-collection/domain/weblink-item.model';
import { WebLinkItemCategory } from 'src/app/weblink-collection/domain/weblink-item-category.enum';
import { WeblinkEditModalComponent } from 'src/app/weblink-collection/weblink-edit-modal/weblink-edit-modal.component';

@Component({
  selector: 'sis-weblink-item',
  templateUrl: './weblink-item.component.html',
  styleUrls: ['./weblink-item.component.scss'],
})
export class WebLinkItemComponent extends Unsubscriber implements OnInit {
  @Input() item: WebLink;
  @Input() showButtons: boolean = true;

  bigScreenMode: boolean;
  editPermission: boolean = false;

  private writePermission: boolean;

  constructor(
    private screenSizeService: ScreenSizeService,
    private userMessageService: UserMessageService,
    private modalCtrl: ModalController,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => {
      this.bigScreenMode = bigScreenMode;
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.WEBLINKCOLLECTION, FeatureAccessLevel.WRITE))
      );

      const readPermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.WEBLINKCOLLECTION, FeatureAccessLevel.READ))
      );
      this.editPermission =
        (this.item.category === WebLinkItemCategory.tenant && this.writePermission) ||
        (this.item.category === WebLinkItemCategory.private && readPermission);
    });
  }

  openLink(): void {
    window.open(this.item.url, '_blank');
  }

  copyUrl(): void {
    navigator.clipboard.writeText(this.item.url).then(() => {
      this.userMessageService.presentToast(
        new UserMessage({
          color: UserMessageColor.blue,
          durationMs: 500,
          icon: UserMessageIcon.info,
          message: 'general.phrase.copiedToClipboard',
          position: 'top',
        })
      );
    });
  }

  async openEditModal(): Promise<void> {
    if (this.editPermission) {
      const modal = await this.modalCtrl.create({
        component: WeblinkEditModalComponent,
        componentProps: {
          webLinkItem: this.item,
          writePermission: this.writePermission,
        },
        cssClass: 'sis-weblink-edit-modal',
        backdropDismiss: false,
      });
      await modal.present();
    }
  }
}
