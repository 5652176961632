import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { ApiConnectionEvent, InternetConnectionEvent } from 'src/app/core/eventbus/events';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';

@Injectable({
  providedIn: 'root',
})
export class ApiConnectionInterceptor implements HttpInterceptor {
  private readonly apiExceptionCodes = [400, 401, 500];

  constructor(private eventBusService: EventBusService, private navigator: NavigatorService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('/api/')) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (!this.apiExceptionCodes.includes(err?.status)) {
          this.notifyApiConnection(false);
        }
        this.notifyInternetConnection();
        return throwError('Connection error');
      }),
      tap(
        () => {},
        () => {},
        () => {
          this.notifyInternetConnection();
          this.notifyApiConnection(true);
        }
      )
    );
  }

  private notifyApiConnection(connected: boolean): void {
    const event: ApiConnectionEvent = new ApiConnectionEvent();
    event.apiConnected = connected;
    this.eventBusService.publish(event);
  }

  private notifyInternetConnection(): void {
    const event: InternetConnectionEvent = new InternetConnectionEvent();
    event.internetConnected = this.navigator.isOnline();
    this.eventBusService.publish(event);
  }
}
