import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnergyTrendAdapter } from 'src/app/maps/trenddata/energy-trend.adapter';
import { EnergyTrend } from 'src/app/maps/trenddata/energy-trend.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnergyTrendService {
  constructor(private http: HttpClient) {}

  requestEnergyTrend(energyDeviceId: string): Observable<EnergyTrend[]> {
    const url = environment.baseUrlApi + `/trenddata/energy/` + energyDeviceId;
    return this.http.get(url).pipe(map((data) => EnergyTrendAdapter.adapt(data)));
  }
}
