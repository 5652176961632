import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, from, interval, merge, Observable } from 'rxjs';
import { debounceTime, delayWhen, filter, map, takeUntil } from 'rxjs/operators';
import { BlobStorageContainerService } from 'src/app/core/blob-storage/blob-storage-container.service';
import { BlobDeleteService } from 'src/app/core/blob-storage/blob-storage-delete.service';
import { BlobStorageUploadService } from 'src/app/core/blob-storage/blob-storage-upload.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { FileSettings } from 'src/app/media-center/ckeditor/file-settings.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Injectable({
  providedIn: 'root',
})
export class CKEditorService extends Unsubscriber {
  private static readonly images = 'images';
  private static readonly videos = 'videos';
  private static readonly imageThumbnails = 'images/thumbnails';

  static readonly convertingPrefix = '_converting_';
  static readonly failedPrefix = '_failed_';

  readonly images$: Observable<FileSettings[]> = this.blobUploadService.itemsInContainerWithUpload$.pipe(
    map((blobs) => {
      const images = blobs.filter(
        (b) => b.filePath.startsWith(`${CKEditorService.images}/`) && !b.filePath.startsWith(`${CKEditorService.imageThumbnails}/`)
      );
      return images.map<FileSettings>((item) => ({
        name: item.filePath.replace(`${CKEditorService.images}/`, ''),
        path: item.filePath,
        url: item.url,
        thumbnailUrl: this.getThumbnailFilePath(item.url),
        type: CKEditorService.images,
        size: item.fileSize,
        progress: item.progress,
        width: item.width,
        height: item.height,
      }));
    })
  );

  readonly videos$: Observable<FileSettings[]> = this.blobUploadService.itemsInContainerWithUpload$.pipe(
    map((blobs) => {
      const videos = blobs
        .filter((b) => b.filePath.startsWith(`${CKEditorService.videos}/`))
        .filter((v) => v.filePath.endsWith('.mp4') || v.filePath.includes(CKEditorService.convertingPrefix));

      return videos.map((item) => ({
        name: item.filePath
          .replace(`${CKEditorService.videos}/`, '')
          .replace(CKEditorService.convertingPrefix, '')
          .replace(CKEditorService.failedPrefix, '')
          .split('/')
          .slice(-1)[0],
        path: item.filePath,
        url: item.url,
        height: item.height,
        width: item.width,
        thumbnailUrl: item.url.includes(CKEditorService.convertingPrefix) ? null : item.url.replace('.mp4', '.jpg'),
        type: CKEditorService.videos,
        size: item.fileSize,
        progress: item.progress,
        converting: item.filePath.includes(CKEditorService.convertingPrefix),
        conversionFailed: item.filePath.includes(CKEditorService.failedPrefix),
      }));
    })
  );

  readonly deletedImageResponse$: Observable<FileSettings> = this.blobDeleteService.deleteItemResponse$.pipe(
    filter((item) => item.filePath.startsWith(CKEditorService.images)),
    map((item) => ({
      name: item.filePath.replace(`${CKEditorService.images}/`, ''),
      path: item.filePath,
      url: item.url,
      type: CKEditorService.images,
      size: item.fileSize,
    }))
  );

  readonly deletedVideoResponse$: Observable<FileSettings> = this.blobDeleteService.deleteItemResponse$.pipe(
    filter((item) => item.filePath.startsWith(CKEditorService.videos)),
    map((item) => ({
      name: item.filePath.replace(`${CKEditorService.videos}/`, ''),
      path: item.filePath,
      url: item.url,
      type: CKEditorService.videos,
      size: item.fileSize,
    }))
  );

  constructor(
    private blobContainerService: BlobStorageContainerService,
    private blobUploadService: BlobStorageUploadService,
    private blobDeleteService: BlobDeleteService,
    private http: HttpClient,
    userMessageService: UserMessageService,
    private userSettingsService: UserSettingsService
  ) {
    super();

    merge(this.deletedImageResponse$, this.deletedVideoResponse$)
      .pipe(debounceTime(500), takeUntil(this.onDestroy$))
      .subscribe(() => {
        userMessageService.presentToast(
          new UserMessage({
            message: 'general.phrase.fileDeleted',
            icon: UserMessageIcon.success,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.green,
          })
        );
      });
  }

  async deleteFile(fileSettings: FileSettings): Promise<void> {
    const parentFolder = fileSettings.path.split('/').slice(-2)[0];

    if (fileSettings.conversionFailed) {
      this.deleteSingleFile(fileSettings.path);
      return;
    }

    if (parentFolder === fileSettings.type) {
      this.deleteSingleFile(`${fileSettings.type}/${fileSettings.name}`);

      if (fileSettings.type === CKEditorService.images && !fileSettings.name.includes('.svg')) {
        this.deleteSingleFile(`${CKEditorService.imageThumbnails}/${this.getThumbnailFileName(fileSettings.name)}`);
      }
    } else {
      await this.deleteFolder(`${fileSettings.type}/${parentFolder}/`);
    }
  }

  async uploadImage(
    file: File,
    metadata?: {
      [propertyName: string]: string;
    }
  ): Promise<void> {
    this.blobUploadService.uploadItems({ file, folder: CKEditorService.images, metadata });

    if (!file.name.includes('.svg')) {
      const thumbnailFile = await this.generateThumbnail(this.getThumbnailFileName(file.name), URL.createObjectURL(file), [160, 90]);

      this.blobUploadService.uploadItems({ file: thumbnailFile, folder: CKEditorService.imageThumbnails });
    }
  }

  async uploadVideo(file: File): Promise<void> {
    const userGuid = (await firstValueFrom(this.userSettingsService.userSettings$)).userGuid;
    this.blobUploadService.uploadItems({
      file,
      folder: CKEditorService.videos,
      fileNamePrefix: CKEditorService.convertingPrefix,
      metadata: {
        uploadedBy: userGuid,
      },
    });
  }

  requestHtmlFile(url: string): Observable<string> {
    return this.http.get(url, {
      responseType: 'text',
    });
  }

  async uploadAllThumbnails(): Promise<void> {
    const images = await firstValueFrom(this.images$.pipe(map((images) => images.filter((image) => !image.url.includes('.svg')))));

    from(images)
      .pipe(delayWhen((_, index) => interval(index * 500)))
      .subscribe(async (image) => {
        console.log('Creating thumbnail for: ' + image.name);

        const thumbnailFile = await this.generateThumbnail(this.getThumbnailFileName(image.name), image.url, [160, 90]);

        this.blobUploadService.uploadItems({ file: thumbnailFile, folder: CKEditorService.imageThumbnails });

        console.log('Creating metadata for: ' + image.name);

        const file = await this.createFile(image);
        const dimensions = await this.getHeightAndWidthFromUrl(image.url);

        const metadata: {
          [propertyName: string]: string;
        } = {
          ['height']: dimensions.height.toString(),
          ['width']: dimensions.width.toString(),
        };

        this.blobUploadService.uploadItems({ file, folder: CKEditorService.images, metadata });
      });
  }

  async getMetadata(file: File): Promise<{
    [propertyName: string]: string;
  }> {
    const dimensions = await this.getHeightAndWidthFromFile(file);

    const metadata: {
      [propertyName: string]: string;
    } = {
      ['height']: dimensions.height.toString(),
      ['width']: dimensions.width.toString(),
    };

    return metadata;
  }

  private async generateThumbnail(fileName: string, url: string, boundBox: number[]): Promise<File> {
    if (!boundBox || boundBox.length != 2) {
      throw 'You need to give the boundBox';
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('Context not available');
    }

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onerror = reject;
      img.setAttribute('crossorigin', 'anonymous');
      img.crossOrigin = '*';
      img.onload = () => {
        const scaleRatio = Math.max(...boundBox) / Math.max(img.width, img.height);
        const w = img.width * scaleRatio;
        const h = img.height * scaleRatio;
        canvas.width = w;
        canvas.height = h;
        ctx.drawImage(img, 0, 0, w, h);
        const dataUrl = canvas.toDataURL('image/jpeg');
        const blob = this.dataURLtoBlob(dataUrl);
        const thumbnailFile = new File([blob], fileName, { type: 'image/jpeg' });
        img.remove();
        return resolve(thumbnailFile);
      };
      img.src = url;
    });
  }

  private deleteSingleFile(filePath: string): void {
    this.blobDeleteService.deleteItem(filePath);
  }

  private async deleteFolder(path: string): Promise<void> {
    const blobs = await firstValueFrom(this.blobContainerService.itemsInContainer$);
    const filteredBlobs = blobs.filter((blob) => blob.name.includes(path));
    filteredBlobs.forEach((blob) => this.blobDeleteService.deleteItem(blob.name));
  }

  private dataURLtoBlob(dataurl: string): Blob {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = window.atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  private getThumbnailFilePath(filePath: string): string {
    if (filePath.includes('.svg')) {
      return filePath;
    }

    return this.getThumbnailFileName(filePath).replace(`/${CKEditorService.images}/`, `/${CKEditorService.imageThumbnails}/`);
  }

  private getThumbnailFileName(fileName: string): string {
    if (fileName.includes('.svg')) {
      return fileName;
    }

    return fileName.replace(/\.([^.]*)$/g, '-$1') + '.jpg';
  }

  private getHeightAndWidthFromFile(file: File): Promise<{ height: number; width: number }> {
    const dataUrl = window.URL.createObjectURL(file);
    return this.getHeightAndWidthFromUrl(dataUrl);
  }

  private getHeightAndWidthFromUrl(url: string): Promise<{ height: number; width: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onerror = reject;
      img.setAttribute('crossorigin', 'anonymous');
      img.crossOrigin = '*';
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = url;
    });
  }

  private async createFile(fileSettings: FileSettings): Promise<File> {
    const response = await fetch(fileSettings.url);
    const data = await response.blob();
    return new File([data], fileSettings.name);
  }
}
