import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomMarkerIconService {
  private readonly icons = new Map<string, Observable<string>>();

  constructor(private httpClient: HttpClient) {}

  getIconSvgAsHtml(iconName: string): Observable<string> {
    let iconObservable = this.icons.get(iconName);

    if (!iconObservable) {
      const url = `${environment.baseUrlCockpitBlobStorage}/public-assets/icons/${iconName}.svg`;
      iconObservable = this.httpClient.get(url, { responseType: 'text' }).pipe(shareReplay(1));
      this.icons.set(iconName, iconObservable);
    }

    return iconObservable;
  }
}
