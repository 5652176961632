import { WritablePart } from 'src/app/core/utils/type-helper';

export class DetailView {
  component: any;
  modal: boolean = false;
  height: number = 300;

  constructor(init?: Partial<WritablePart<DetailView>>) {
    Object.assign(this, init);
  }
}
