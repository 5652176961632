import { STnetStatusAdapter } from 'src/app/core/stnet/stnet-status.adapter';
import { MeteoInfo } from 'src/app/meteo-info/domain/meteo-info.model';
import { MeteoInfoItemAdapter } from 'src/app/meteo-info/domain/meteo-info-item.adapter';

export class MeteoInfoAdapter {
  static adapt(item: any): MeteoInfo {
    if (!item) {
      return {
        meteoInfoItems: [],
        stnetStatusArray: null,
      };
    }

    return {
      meteoInfoItems: MeteoInfoItemAdapter.adapt(item.meteoInfos),
      stnetStatusArray:
        item.sTnetStatusList?.filter((v: any) => v).map((status: any) => STnetStatusAdapter.adapt(status)) ?? [],
    };
  }
}
