<ion-header data-test="sismedia-infobanner-editor">
  <ion-toolbar>
    <ion-title data-test="sis-infobanner-editor-title">
      {{ 'mediacenter.term.infoBanner' | translate }}
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="close()"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding sis-infobanner-modal-content">
  <!-- Big Screen -->
  <ion-grid *ngIf="this.bigScreenMode" class="sis-modal-grid" style="overflow-y: hidden; max-height: 100%">
    <ion-row class="sis-infobanner-new-btn-row">
      <ion-col size="6" class="ion-no-padding">
        <ion-searchbar
          placeholder="{{ 'general.term.filter' | translate }}"
          type="text"
          [formControl]="searchControl"
          (ionChange)="this.searching = true"
          data-test="sis-infobanner-searchbar"
        >
        </ion-searchbar>
      </ion-col>
      <ion-col class="sis-global-center" size="0.5">
        <ion-spinner *ngIf="searching" name="bubbles"></ion-spinner>
      </ion-col>
      <ion-col class="sis-new-infobanner-btn-col">
        <sis-general-button
          [text]="'general.term.newEntry'"
          [expand]="'block'"
          [btnAlign]="'Left'"
          [btnType]="'Secondary'"
          [disabled]="!this.infoBannerWritePermission"
          (sisClick)="this.openTextModal(null)"
          data-test="sis-infobanner-new-entry-btn"
        >
        </sis-general-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-radio-group [(ngModel)]="this.selectedInfoBanner">
          <ion-grid class="sis-radiogroup-grid">
            <ion-row>
              <ion-col size="12" class="sis-infobanner-col sis-global-center">
                <ion-spinner *ngIf="!this.infoBanners" name="bubbles"></ion-spinner>
              </ion-col>
            </ion-row>
            <ion-row *ngFor="let infoBanner of this.infoBanners" class="sis-infobanner-row sis-global-hover">
              <ion-col size="1" class="sis-infobanner-col sis-global-center">
                <ion-radio
                  [value]="infoBanner"
                  data-test="infobanner-radio-button"
                  color="secondary"
                  [disabled]="!this.infoBannerWritePermission"
                ></ion-radio>
              </ion-col>
              <ion-col size="5" class="sis-infobanner-col">
                <span
                  class="sis-infobanner-label sis-global-text-overflow-ellipsis"
                  style="width: auto"
                  (click)="selectInfoBanner(infoBanner)"
                  >{{ infoBanner.name }}</span
                >
                <a
                  *ngIf="infoBanner.screenLocations"
                  class="sis-mediacenter-clickable"
                  matTooltip="{{ ('infobanner.phrase.tooltipTitle' | translate) + infoBanner.screenLocations }}"
                  matTooltipClass="sis-global-tooltip"
                >
                  <ion-icon class="sis-mediacenter-icon" name="alert-circle-outline"> </ion-icon>
                </a>
              </ion-col>
              <ion-col size="3" class="sis-infobanner-col sis-global-center">
                <span *ngIf="this.showBannerPosition" class="sis-infobanner-position">{{
                  infoBanner.position === 1
                    ? ('infobanner.term.fullscreen' | translate)
                    : ('infobanner.term.banner' | translate)
                }}</span>
              </ion-col>
              <ion-col size="1" class="sis-infobanner-col"
                ><a
                  *ngIf="this.showPreviewButton && !screen.updating"
                  class="sis-mediacenter-clickable"
                  (click)="this.openSlideShowPreview(screen.label, infoBanner.guid)"
                  matTooltip="{{ 'mediacenter.phrase.openSlideShowPreview' | translate }}"
                  data-test="infobanner-slideshow-preview-button"
                >
                  <ion-icon class="sis-mediacenter-icon" name="open-outline"> </ion-icon>
                </a>
              </ion-col>
              <ion-col size="1" class="sis-infobanner-col"
                ><a
                  *ngIf="!screen.updating"
                  [ngClass]="
                    this.infoBannerWritePermission
                      ? 'sis-mediacenter-clickable'
                      : 'sis-mediacenter-clickable-no-permission'
                  "
                  (click)="this.openTextModal(infoBanner)"
                  data-test="infobanner-text-edit-button"
                  matTooltip="{{ 'general.term.edit' | translate }}"
                >
                  <ion-icon class="sis-mediacenter-icon" name="create-outline"> </ion-icon>
                </a>
              </ion-col>
              <ion-col size="1" class="sis-infobanner-col"
                ><a
                  *ngIf="!screen.updating"
                  [ngClass]="
                    this.infoBannerWritePermission
                      ? 'sis-mediacenter-clickable'
                      : 'sis-mediacenter-clickable-no-permission'
                  "
                  (click)="this.deleteInfoBanner(infoBanner)"
                  data-test="infobanner-delete-button"
                  matTooltip="{{ 'general.term.delete' | translate }}"
                >
                  <ion-icon class="sis-mediacenter-icon" name="trash-outline"> </ion-icon>
                </a>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-radio-group>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- Small Screen -->
  <ion-grid *ngIf="!this.bigScreenMode" class="sis-mediacenter-no-scroll sis-mediacenter-grid-mobile">
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-searchbar
          placeholder="{{ 'general.term.filter' | translate }}"
          type="text"
          [formControl]="searchControl"
          (ionChange)="this.searching = true"
        >
        </ion-searchbar>
      </ion-col>
      <ion-col *ngIf="searching" class="sis-global-center" size="1.5">
        <ion-spinner name="bubbles" style="height: 1.5em"></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid *ngIf="!this.bigScreenMode" class="sis-mediacenter-grid-mobile sis-infobanner-grid-mobile">
    <div class="sis-infobanner-container-mobile">
      <ion-radio-group [(ngModel)]="this.selectedInfoBanner">
        <ion-row>
          <ion-col size="12" class="sis-infobanner-col sis-global-center">
            <ion-spinner *ngIf="!this.infoBanners" name="bubbles"></ion-spinner>
          </ion-col>
        </ion-row>
        <ion-row *ngFor="let infoBanner of this.infoBanners" class="sis-infobanner-row">
          <ion-col size="2" class="sis-infobanner-col sis-global-center">
            <ion-radio
              [value]="infoBanner"
              data-test="infobanner-radio-button"
              color="secondary"
              [disabled]="!this.infoBannerWritePermission"
            ></ion-radio>
          </ion-col>
          <ion-col size="7" class="sis-infobanner-col">
            <span
              class="sis-infobanner-label sis-global-text-overflow-ellipsis"
              style="width: auto"
              (click)="selectInfoBanner(infoBanner)"
              >{{ infoBanner.name }}</span
            >
            <a
              *ngIf="infoBanner.screenLocations"
              class="sis-mediacenter-clickable"
              matTooltip="{{ ('infobanner.phrase.tooltipTitle' | translate) + infoBanner.screenLocations }}"
              matTooltipClass="sis-global-tooltip"
            >
              <ion-icon class="sis-mediacenter-icon" name="alert-circle-outline"> </ion-icon>
            </a>
          </ion-col>
          <ion-col size="3" class="sis-infobanner-col sis-global-center">
            <span *ngIf="this.showBannerPosition" class="sis-infobanner-label">{{
              infoBanner.position === 1
                ? ('infobanner.term.fullscreen' | translate)
                : ('infobanner.term.banner' | translate)
            }}</span>
          </ion-col>
        </ion-row>
      </ion-radio-group>
    </div>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'mediacenter.phrase.selectScreens'"
      [btnType]="'Secondary'"
      [disabled]="!this.infoBannerWritePermission || !this.selectedInfoBanner"
      [width]="this.bigScreenMode ? '' : '100%'"
      (sisClick)="this.openScreensSelectorModal(this.selectedInfoBanner, this.screen)"
      class="sis-global-button-padding-right"
      data-test="sis-infobanner-select-screens-button"
    >
    </sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      [width]="this.bigScreenMode ? '' : '100%'"
      (sisClick)="this.close()"
      data-test="sis-infobanner-cancel-button"
    >
    </sis-general-button>
  </div>
</ion-footer>
