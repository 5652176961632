import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FirstEntriesGroup } from 'src/app/maps/domain/firstentriesgroup.model';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { KassandraForecastData } from 'src/app/maps/livedata/kassandra-forecast.model';
import { KassandraForecastService } from 'src/app/maps/livedata/kassandra-forecast.service';
import { SisOutdatedHelper } from 'src/app/maps/livedata/sis-outdated-helper';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-kassandra-widget',
  templateUrl: './kassandra-widget.component.html',
  styleUrls: ['./kassandra-widget.component.scss'],
})
export class KassandraWidgetComponent extends WidgetBase implements OnInit {
  private groups: FirstEntriesGroup[] = [];

  kassandraTotal: string;

  constructor(private masterdataService: MasterdataService, private kassandraService: KassandraForecastService) {
    super();
  }

  ngOnInit() {
    this.masterdataService.masterData$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.groups = data.firstEntriesGroups;
    });

    this.kassandraService.kassandra$.pipe(takeUntil(this.onDestroy$)).subscribe((kassandra) => {
      this.kassandraTotal = this.getKassandraTotal(kassandra);
    });
  }

  private getKassandraTotal(forecastDatas: KassandraForecastData[]): string {
    const data: KassandraForecastData = { forecastValue: null, forecastAt: new Date() };
    const group = this.groups.find((g) => g.wholeDestination === true);
    if (!group) {
      return '?';
    }
    group.rowKeys.forEach((rowKey: string) => {
      const forecast = forecastDatas.find((f) => f.rowKey === rowKey);
      if (forecast) {
        data.forecastValue += forecast.forecastValue;
        data.forecastAt = forecast.forecastAt < data.forecastAt ? forecast.forecastAt : data.forecastAt;
      }
    });

    return data && data.forecastValue && SisOutdatedHelper.dateIsToday(data.forecastAt)
      ? data.forecastValue.toString()
      : '?';
  }
}
