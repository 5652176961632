import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, merge, Observable } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { ClampingCountResetedEvent } from 'src/app/core/eventbus/events';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { ClampingCount } from 'src/app/maps/widgets/detailviews/clampingcount-detailview/domain/clampingcount.model';
import { ClampingCountResetMetaDataAdapter } from 'src/app/maps/widgets/detailviews/clampingcount-detailview/domain/clampingcount-reset-metadata.adapter';
import { ClampingCountResetMetadata } from 'src/app/maps/widgets/detailviews/clampingcount-detailview/domain/clampingcount-reset-metadata.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClampingCountResetService {
  private readonly clampingCountMetadataRequest$: Observable<ClampingCountResetMetadata[]> = this.selectedMapElementService.selectedRopeway$.pipe(
    filter((selectedRopeway) => !!selectedRopeway),
    switchMap((selectedRopeway) =>
      this.http
        .get(`${environment.baseUrlApi}/ropeway/clampingcount/metadata/${selectedRopeway.guid}`)
        .pipe(map((clampingCountResetMetaData: any) => ClampingCountResetMetaDataAdapter.adapt(clampingCountResetMetaData)))
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly clampingCountResetedEvent$: Observable<ClampingCountResetMetadata[]> = this.eventBus.observe(ClampingCountResetedEvent).pipe(
    withLatestFrom(this.selectedMapElementService.selectedRopeway$),
    filter(([event, selectedRopeway]) => event.ropewayGuid === selectedRopeway.guid),
    map(([event]) => event),
    withLatestFrom(this.clampingCountMetadataRequest$),
    withLatestFrom(this.userSettingsService.userSettings$),
    map(([[event, requestData], userSettings]) => {
      if (event.changedBy === userSettings.userGuid) {
        this.displayUserMessage(event.resetSuccessful);
      }

      if (event.resetSuccessful) {
        const changedVehicle = requestData.find((r) => r.vehicleId === event.vehicleId);

        if (changedVehicle) {
          changedVehicle.email = event.email;
          changedVehicle.lastReset = new Date(event.lastReset);
          changedVehicle.totalValueOnReset = event.totalValueOnReset;
          changedVehicle.isResetting = false;
        } else {
          requestData.push({
            email: event.email,
            lastReset: new Date(event.lastReset),
            totalValueOnReset: event.totalValueOnReset,
            vehicleId: event.vehicleId,
          });
        }
      }

      return requestData;
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly clampingCountMetadata$ = merge(this.clampingCountMetadataRequest$, this.clampingCountResetedEvent$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private http: HttpClient,
    private selectedMapElementService: SelectedMapElementService,
    private userMessageService: UserMessageService,
    private eventBus: EventBusService,
    private translateService: TranslateService,
    private alertController: AlertController,
    private userSettingsService: UserSettingsService
  ) {}

  async postResetClampingCount(vehicleId: number): Promise<void> {
    const url = `${environment.baseUrlApi}/ropeway/clampingcount/reset`;
    const ropewayGuid = (await firstValueFrom(this.selectedMapElementService.selectedRopeway$))?.guid;

    await firstValueFrom(
      this.http.post(url, { ropewayGuid, vehicleId }).pipe(
        catchError((error: HttpErrorResponse) => {
          this.displayUserMessage(false);
          throw error;
        })
      )
    );
  }

  async resetClampingCount(clampingCount: ClampingCount): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translateService.instant('general.term.danger'),
      subHeader: this.translateService.instant('ropewayClampingCount.phrase.reset'),
      message: `${this.translateService.instant('ropeway.term.vehicleId')}: ${clampingCount.vehicleId}<br/>${this.translateService.instant(
        'general.term.currentCount'
      )}: ${clampingCount.clampingCount - (clampingCount.metadata?.totalValueOnReset ?? 0)}<br/>${this.translateService.instant(
        'general.term.lastReset'
      )}: ${clampingCount.metadata?.lastReset.toLocaleString() ?? this.translateService.instant('general.term.never')}`,
      htmlAttributes: {
        'data-test': 'clampingCount-reset-alert',
      },
      buttons: [
        {
          text: this.translateService.instant('general.term.cancel'),
          handler: () => {},
        },
        {
          text: this.translateService.instant('general.term.reset'),
          handler: async () => {
            if (!clampingCount.metadata) {
              clampingCount.metadata = {};
            }
            clampingCount.metadata.isResetting = true;
            await this.postResetClampingCount(clampingCount.vehicleId);
          },
        },
      ],
      backdropDismiss: false,
      cssClass: 'sis-ion-alert',
    });

    await alert.present();
  }

  private displayUserMessage(updateSuccessful: boolean): void {
    const message = updateSuccessful ? 'general.phrase.resetSuccess' : 'general.phrase.resetFailed';

    const icon = updateSuccessful ? UserMessageIcon.success : UserMessageIcon.failed;
    const color = updateSuccessful ? UserMessageColor.green : UserMessageColor.red;
    const userMessage = new UserMessage({
      message,
      icon,
      durationMs: 2000,
      position: 'top',
      color,
    });
    this.userMessageService.presentToast(userMessage);
  }
}
