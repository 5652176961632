import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';

@Component({
  selector: 'sis-alarmlist-modal',
  templateUrl: './alarmlist-modal.component.html',
  styleUrls: ['./alarmlist-modal.component.scss'],
})
export class AlarmlistModalComponent {
  constructor(private modalCtrl: ModalController, public screenSizeService: ScreenSizeService) {}

  closeModal(): void {
    this.modalCtrl.dismiss();
  }
}
