import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SisBottomDrawerComponent } from 'src/app/bottom-drawer/bottom-drawer';

@NgModule({
  declarations: [SisBottomDrawerComponent],
  imports: [CommonModule, IonicModule],
  exports: [SisBottomDrawerComponent]
})
export class BottomDrawerModule {}
