import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { EventBusSubscriberBase } from 'src/app/core/eventbus/event-bus-subscriber-base';
import { AlarmLibraryOverrideUpdatedEvent, UserAlarmConfigUpdatedEvent } from 'src/app/core/eventbus/events';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { AlarmLibraryService } from 'src/app/maps/alarmlibrary/alarmlibrary.service';
import { AlarmLibraryItem } from 'src/app/maps/alarmlibrary/domain/alarmlibrary-item.model';
import { AlarmLibraryOverride } from 'src/app/maps/alarmlibrary/domain/alarmlibraryoverride.model';

@Component({
  selector: 'sis-alarmlibrary',
  templateUrl: './alarmlibrary.component.html',
  styleUrls: ['./alarmlibrary.component.scss'],
})
export class AlarmLibraryModal extends EventBusSubscriberBase implements OnInit {
  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  @Input() sisId: string;
  @Input() alias: string;
  @Input() searchString: string;

  alarms: AlarmLibraryItem[];
  alarmsFiltered: AlarmLibraryItem[];
  searchControl = new FormControl<string>('');
  notification = AlarmLibraryItem.notification;
  selectableLevels: string[];
  selectableLevelsWithoutFault: string[];
  levelSwitchAllowed: boolean;
  bigScreenMode: boolean;

  constructor(
    eventBus: EventBusService,
    private modalCtrl: ModalController,
    private alarmLibraryService: AlarmLibraryService,
    private screenSizeService: ScreenSizeService,
    private logbookModalService: LogbookModalService,
    private destinationService: DestinationService
  ) {
    super(eventBus);

    this.selectableLevels = [
      AlarmLibraryService.LevelFault,
      AlarmLibraryService.LevelAlarm,
      AlarmLibraryService.LevelWarning,
      AlarmLibraryService.LevelInfo,
    ];
    this.selectableLevelsWithoutFault = [
      AlarmLibraryService.LevelAlarm,
      AlarmLibraryService.LevelWarning,
      AlarmLibraryService.LevelInfo,
    ];
  }

  ngOnInit() {
    this.observe<UserAlarmConfigUpdatedEvent>(UserAlarmConfigUpdatedEvent, (event) => {
      this.updateListElement(event);
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroy$)).subscribe((search: string) => {
      this.filterAlarmLibraryList(search);
    });

    combineLatest([
      this.alarmLibraryService.getAlarmLibrary(this.sisId),
      this.destinationService.selectedTenant$,
    ]).subscribe(([alarms, tenant]) => {
      this.loadAlarms(alarms);

      this.observe(AlarmLibraryOverrideUpdatedEvent, (event) => {
        if (event.tenantGuid !== tenant.guid) {
          return;
        }
        const updatedEntry = event.alarmLibraryEntry;
        if (updatedEntry) {
          const item = this.alarms.find((a) => a.sisId === updatedEntry.sisId && a.code === updatedEntry.code);

          if (item) {
            if (updatedEntry.level) {
              item.level = updatedEntry.level;

              item.setUpdating(AlarmLibraryItem.level, false);
            }
            if (updatedEntry.info) {
              item.info = updatedEntry.info;
            }
            if (updatedEntry.message) {
              item.message = updatedEntry.message;
            }
          }
        }
      });

      this.searchControl.setValue(this.searchString);
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.levelSwitchAllowed = features.some((feature) =>
        feature.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT, FeatureAccessLevel.WRITE))
      );
    });
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }

  getLevels(alarmLibraryEntry: AlarmLibraryItem) {
    if (alarmLibraryEntry.defaultLevel !== AlarmLibraryService.LevelFault) {
      return this.selectableLevelsWithoutFault;
    }

    return this.selectableLevels;
  }

  getLevelTranslateString(level: string): string {
    return `alarming.term.${level}`;
  }

  isLevelUpdating(alarmLibraryEntry: AlarmLibraryItem): boolean {
    return alarmLibraryEntry.getUpdating(AlarmLibraryItem.level);
  }

  isSwitchableLevel(alarmLibraryEntry: AlarmLibraryItem): boolean {
    return this.levelSwitchAllowed && this.selectableLevels.includes(alarmLibraryEntry.level);
  }

  override(event: any, alarmLibraryEntry: AlarmLibraryItem): void {
    const level = event.detail.value;

    if (alarmLibraryEntry.level !== level && level != null) {
      const overrideSettings: AlarmLibraryOverride = {
        sisId: alarmLibraryEntry.sisId,
        code: alarmLibraryEntry.code,
        level,
      };

      alarmLibraryEntry.setUpdating(AlarmLibraryItem.level, true);
      this.alarmLibraryService.updateAlarmLibraryOverride(overrideSettings);
    }
  }

  private loadAlarms(alarms: AlarmLibraryItem[]): void {
    this.alarms = alarms.sort((a, b) => {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });

    this.alarmsFiltered = this.alarms;
  }

  private filterAlarmLibraryList(searchTerm: string): void {
    if (searchTerm == null) {
      return;
    }

    searchTerm = searchTerm.trim();
    this.alarmsFiltered = this.alarms.filter(
      (a) =>
        a.sisId.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        a.code.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        (a.message && a.message.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
      //      a.info.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
      //      a.level.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    );
  }

  async openLogbook(): Promise<void> {
    await this.logbookModalService.presentLogbookModal([[FeatureId.COCKPIT]]);
  }

  private updateListElement(event: UserAlarmConfigUpdatedEvent): void {
    if (!this.alarmsFiltered) {
      return;
    }

    const data = event.userAlarmConfig;
    if (data) {
      const listEntry = this.alarmsFiltered.find(
        (a) => a.sisId.toLowerCase() === data.sisId && a.code.toLowerCase() === data.alarmCode
      );
      if (listEntry) {
        listEntry.setSetting(data.setting, data.enabled);
        listEntry.setUpdating(data.setting, false);
      }
    }
  }
}
