import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastButton } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { EventBusSubscriberBase } from 'src/app/core/eventbus/event-bus-subscriber-base';
import { AlarmLibraryOverrideUpdatedEvent, SisMediaPlsUpdatedEvent } from 'src/app/core/eventbus/events';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Injectable({
  providedIn: 'root',
})
export class UserMessageService extends EventBusSubscriberBase {
  static readonly toastCssClass = 'sis-global-toast';

  private readonly minTimeBetweenSameMessageMs = 5000;
  private readonly messageOutdatedAfterMs = 20000;

  private userMessages: UserMessage[] = [];
  private toastActive: boolean;
  private messageTimeMap: Map<string, number>;

  constructor(
    eventBus: EventBusService,
    private toastController: ToastController,
    private translateService: TranslateService
  ) {
    super(eventBus);

    this.messageTimeMap = new Map<string, number>();

    this.setupSubscriptions();
  }

  async createToast(userMessage: UserMessage): Promise<HTMLIonToastElement> {
    const toastButtons: Array<string | ToastButton> = [];
    const translatedMessage = await this.getTranslatedString(userMessage.messageParts);
    if (userMessage.showCloseButton) {
      toastButtons.push({ side: 'end', icon: 'close' });
    }
    if (userMessage.icon) {
      toastButtons.push({ side: 'start', icon: userMessage.icon });
    }
    return this.toastController.create({
      message: translatedMessage,
      duration: userMessage.durationMs,
      position: userMessage.position,
      color: userMessage.color,
      cssClass: UserMessageService.toastCssClass,
      animated: true,
      buttons: toastButtons,
    });
  }

  async presentToast(userMessage: UserMessage): Promise<void> {
    if (!document.hasFocus()) {
      return;
    }

    const now = Date.now();
    const lastTimeSent = this.messageTimeMap.get(userMessage.messageParts.join(''));
    if (lastTimeSent && now < lastTimeSent + this.minTimeBetweenSameMessageMs) {
      return;
    }

    this.userMessages.push(userMessage);
    this.presentAllToasts();
  }

  async createSingleToast(userMessage: UserMessage): Promise<HTMLIonToastElement> {
    const toastButtons: Array<string | ToastButton> = [];
    const translatedMessage = await this.getTranslatedString(userMessage.messageParts);
    if (userMessage.showCloseButton) {
      toastButtons.push({ side: 'end', icon: 'close' });
    }
    if (userMessage.icon) {
      toastButtons.push({ side: 'start', icon: userMessage.icon });
    }
    return this.toastController.create({
      message: translatedMessage,
      duration: userMessage.durationMs,
      position: userMessage.position,
      color: userMessage.color,
      cssClass: UserMessageService.toastCssClass,
      animated: true,
      buttons: toastButtons,
    });
  }

  private setupSubscriptions(): void {
    this.observe<AlarmLibraryOverrideUpdatedEvent>(AlarmLibraryOverrideUpdatedEvent, (event) => {
      if (event && !event.successful) {
        const translateKey = 'general.phrase.saveFailed';
        this.presentToast(
          new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.failed,
            durationMs: 2500,
            position: 'top',
            color: UserMessageColor.red,
          })
        );
      }
    });

    this.observe<SisMediaPlsUpdatedEvent>(SisMediaPlsUpdatedEvent, (event) => {
      if (event.successfullyUpdatedDisplays.length > 0) {
        const toastMessage = [
          'userMessage.phrase.deviceSuccessfullyUpdated',
          '<br>- ',
          event.successfullyUpdatedDisplays.join('<br>- '),
        ];
        this.presentToast(
          new UserMessage({
            message: toastMessage,
            icon: UserMessageIcon.success,
            durationMs: 2500,
            position: 'top',
            color: UserMessageColor.green,
            showCloseButton: true,
          })
        );
      }
      if (event.unsuccessfullyUpdatedDisplays.length > 0) {
        const toastMessage = [
          'userMessage.phrase.deviceNotReachable',
          '<br>- ',
          event.unsuccessfullyUpdatedDisplays.join('<br>- '),
        ];
        this.presentToast(
          new UserMessage({
            message: toastMessage,
            icon: UserMessageIcon.failed,
            durationMs: 5000,
            position: 'top',
            color: UserMessageColor.red,
            showCloseButton: true,
          })
        );
      }
      if (event.displaysInSimulationMode && event.displaysInSimulationMode.length > 0) {
        const toastMessage = [
          'userMessage.phrase.deviceInSimulationMode',
          '<br>- ',
          event.displaysInSimulationMode.join('<br>- '),
        ];
        this.presentToast(
          new UserMessage({
            message: toastMessage,
            durationMs: 5000,
            position: 'top',
            color: UserMessageColor.blue,
            showCloseButton: true,
          })
        );
      }
    });
  }

  private getTranslatedString(translateKeys: string[]): Promise<string> {
    return firstValueFrom(
      this.translateService.get(translateKeys).pipe(map((translation) => Object.values(translation).join('')))
    );
  }

  private presentAllToasts(): void {
    if (!this.toastActive && this.userMessages.length > 0) {
      const userMessage = this.userMessages.shift();
      if (!userMessage || userMessage.timestamp + this.messageOutdatedAfterMs < Date.now()) {
        this.presentAllToasts();
        return;
      }

      const now = Date.now();
      const lastTimeSent = this.messageTimeMap.get(userMessage.messageParts.join(''));
      if (lastTimeSent && now < lastTimeSent + this.minTimeBetweenSameMessageMs) {
        this.presentAllToasts();
        return;
      }

      this.messageTimeMap.set(userMessage.messageParts.join(), Date.now());

      this.toastActive = true;
      this.createSingleToast(userMessage).then((toast) => {
        toast.onDidDismiss().then(() => {
          this.toastActive = false;
          this.presentAllToasts();
        });
        toast.present();
      });
    }
  }
}
