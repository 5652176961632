import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IonInput, ModalController } from '@ionic/angular';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { ManagedTenant } from 'src/app/user-management/domain/managed-tenant.model';
import { User } from 'src/app/user-management/domain/user.model';
import { UserManagementService } from 'src/app/user-management/user-management.service';
import { NIL as emptyGuid } from 'uuid';

@Component({
  selector: 'sis-user-management-edit-modal',
  templateUrl: './user-management-edit-modal.component.html',
  styleUrls: ['./user-management-edit-modal.component.scss'],
})
export class UserManagementEditModalComponent extends Unsubscriber implements OnInit {
  @Input() user: User;
  @Input() managedTenants: ManagedTenant[];
  @Input() isAdministrator: boolean;

  @ViewChild('emailInput', { static: false }) emailInput: IonInput;
  @ViewChild('displayNameInput', { static: false }) displayNameInput: IonInput;

  isLoggedInUser: boolean;
  isSaving = false;
  isDeleting = false;
  showSpinner = false;
  modifiedAndValid = false;

  activeTenantGuid: string;
  editedUser: User;
  formGroup: FormGroup;

  private loggedInUserGuid: string;

  bigScreenMode: boolean;

  constructor(
    private modalCtrl: ModalController,
    private userManagementService: UserManagementService,
    private userSettingsService: UserSettingsService,
    private destinationService: DestinationService,
    private confirmationDialogService: ConfirmationDialogService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit() {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((screenMode) => (this.bigScreenMode = screenMode));

    this.userSettingsService.userSettings$.pipe(takeUntil(this.onDestroy$)).subscribe((userSettings) => {
      this.loggedInUserGuid = userSettings.userGuid;
      this.isLoggedInUser = this.user?.userGuid === this.loggedInUserGuid;
    });

    this.destinationService.selectedTenant$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((tenant) => (this.activeTenantGuid = tenant.guid));

    this.formGroup = new FormGroup({
      email: new FormControl({ value: this.user?.email, disabled: this.user != null }, [
        Validators.required,
        Validators.email,
      ]),
      displayName: new FormControl({ value: this.user?.displayName, disabled: true }, [
        Validators.required,
        Validators.minLength(2),
      ]),
    });

    this.formGroup.valueChanges.pipe(debounceTime(200), takeUntil(this.onDestroy$)).subscribe(() => {
      if (this.editedUser) {
        this.editedUser.displayName = this.formGroup.controls.displayName.value;
        this.editedUser.email = this.formGroup.controls.email.value;
      }
      this.checkModifiedAndValid();
    });

    this.initEditedUser(this.user);

    if (!this.user) {
      this.editUserTenantMapping(this.activeTenantGuid, true);
    }

    this.managedTenants.sort((a, b) => {
      const includesA = this.user?.tenantGuids.includes(a.guid);
      const includesB = this.user?.tenantGuids.includes(b.guid);

      return includesA === includesB ? 0 : includesA ? -1 : 1;
    });

    setTimeout(() => this.emailInput.setFocus(), 200);
  }

  checkExistingUser(event: any): void {
    if (this.formGroup.controls.email.invalid) {
      return;
    }

    this.formGroup.controls.displayName.setValue(null, { emitEvent: false });
    this.formGroup.controls.displayName.disable({ emitEvent: false });
    this.showSpinner = true;

    const email = event.target.value;
    this.userManagementService
      .getUserInfoRequest(email)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user) => {
        this.user = user;
        if (user) {
          this.initEditedUser(user);
          this.formGroup.controls.displayName.disable({ emitEvent: false });
        } else {
          this.formGroup.controls.displayName.enable({ emitEvent: false });
          setTimeout(() => this.displayNameInput.setFocus(), 200);
        }

        this.isLoggedInUser = this.user?.userGuid === this.loggedInUserGuid;
        this.showSpinner = false;
      });
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  async onSave(): Promise<void> {
    if (this.editedUser.tenantGuids?.length === 0) {
      this.userManagementService.showFailureToast('usermanagement.phrase.userWithoutTenant');
      return;
    }

    this.isSaving = true;

    if (this.user) {
      this.editedUser.tenantGuids = this.editedUser.tenantGuids.filter((t) =>
        this.managedTenants.some((tenant) => tenant.guid === t && tenant.hasWritePermission)
      );

      const success = await this.userManagementService.updateUser(this.editedUser);
      if (success) {
        this.close();
      } else {
        this.isSaving = false;
      }
    } else {
      const success = await this.userManagementService.addUser(this.editedUser);
      if (success) {
        this.close();
      } else {
        this.isSaving = false;
      }
    }
  }

  async onDelete(): Promise<void> {
    const confirmed = await this.confirmationDialogService.presentAlert(
      'usermanagement.phrase.deleteUser',
      'general.term.yes'
    );

    if (confirmed && this.user) {
      this.isDeleting = true;
      const success = await this.userManagementService.deleteUser(this.user);
      if (success) {
        this.close();
      } else {
        this.isDeleting = false;
      }
    }
  }

  tenantAlias(_: number, tenant: ManagedTenant): string {
    return tenant.alias;
  }

  editUserTenantMapping(tenant: string, enable: boolean): void {
    if (enable) {
      this.editedUser.tenantGuids.push(tenant);
    } else {
      this.editedUser.tenantGuids = this.editedUser.tenantGuids.filter((t) => t !== tenant);
    }
    this.checkModifiedAndValid();
  }

  private initEditedUser(user: User | null): void {
    this.editedUser = {
      userGuid: user?.userGuid ?? emptyGuid,
      email: user?.email ?? null,
      displayName: user?.displayName ?? null,
      tenantGuids: user ? [...this.user.tenantGuids] : [],
      features: user ? [...this.user.features] : [],
      invitationAccepted: user?.invitationAccepted ?? false,
      isSisag: user?.isSisag,
    };

    this.formGroup.setValue(
      {
        email: this.editedUser.email,
        displayName: this.editedUser.displayName,
      },
      { emitEvent: false }
    );
  }

  private isEqualUser(user1: User, user2: User): boolean {
    return (
      user1 !== null &&
      user2 !== null &&
      user1?.displayName === user2?.displayName &&
      user1?.email === user2?.email &&
      user1?.tenantGuids.length === user2?.tenantGuids.length &&
      user1?.tenantGuids.every((x, index) => x === user2?.tenantGuids[index])
    );
  }

  private checkModifiedAndValid(): void {
    this.modifiedAndValid =
      !this.formGroup.invalid &&
      !this.isEqualUser(this.user, this.editedUser) &&
      this.editedUser.displayName?.length > 0 &&
      this.editedUser.tenantGuids?.length > 0;
  }
}
