import { OperatingInfoEntry } from 'src/app/operating-info/domain/operating-info-entry.model';
import { OperatingInfoTextAdapter as OperatingInfoTextAdapter } from 'src/app/operating-info/domain/operating-info-text.adapter';

export class OperatingInfoEntryAdapter {
  static adapt(item: any): OperatingInfoEntry[] {
    const library: OperatingInfoEntry[] = [];

    if (item) {
      item.forEach((e: any) => {
        if (e) {
          library.push({
            guid: e.guid,
            priority: e.priority,
            color: e.color,
            text: OperatingInfoTextAdapter.adapt(e.text),
            active: e.active,
          });
        }
      });
    }

    return library;
  }
}
