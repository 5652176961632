import { SlopesOperatingTimes } from 'src/app/slopes-operating-times/domain/slopes-operating-times.model';

export class SlopesOperatingTimesAdapter {
  static adapt(items: any): SlopesOperatingTimes[] {
    const slopesOperatingTimes: SlopesOperatingTimes[] = [];

    if (items) {
      items.forEach((item) => {
        slopesOperatingTimes.push({
          guid: item.guid,
          location: item.location,
          firstRide: item.firstRide,
          lastRide: item.lastRide,
          lastSlopeControl: item.lastSlopeControl,
          order: item.order,
        });
      });
    }

    return slopesOperatingTimes;
  }
}
