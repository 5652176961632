import { CurveLatLngExpression, CurvePathData, CurveSVGCommand } from '@elfalem/leaflet-curve';
const curveParams: CurveSVGCommand[] = ['M', 'L', 'H', 'V', 'C', 'S', 'Q', 'T', 'Z']; // should be the same as CurveSVGCommand

export function parseCurveCoords(svgCoords: string | undefined): CurvePathData | null {
  if (!svgCoords || svgCoords === '') {
    return null;
  }

  const coords: Array<CurveLatLngExpression | CurveSVGCommand> = [];

  let cs = svgCoords;
  curveParams.forEach((letter) => {
    cs = cs.replace(new RegExp(letter, 'g'), ` ${letter} `);
  });
  cs = cs.replace(/[ ]{2,}/g, ' ').trim();

  const parts = cs.split(' ');
  parts.forEach((part) => {
    if (part.length === 1) {
      coords.push(`${part[0]}` as CurveSVGCommand);
    } else {
      const ns = part.split(',');
      if (ns.length === 1) {
        coords.push([Number(ns[0])]);
      } else {
        coords.push([Number(ns[0]), Number(ns[1])]);
      }
    }
  });
  return coords;
}
