import { STnetCalculatedData } from 'src/app/stnet/domain/stnet-calculated-data.model';

export class STnetCalculatedDataAdapter {
  static adapt(item: any): STnetCalculatedData | null {
    if (!item) {
      return null;
    }

    return {
      stationId: item.stationId,
      liftsToday: item.liftsToday,
      liftsTotal: item.liftsTotal,
      slopesToday: item.slopesToday,
      slopesTotal: item.slopesTotal,
      slopesLengthMeterToday: item.slopesLengthMeterToday,
      slopesLengthMeterTotal: item.slopesLengthMeterTotal,
      valleyRunsToday: item.valleyRunsToday,
      valleyRunsTotal: item.valleyRunsTotal,
      classicTracksLengthMeterToday: item.classicTracksLengthMeterToday,
      classicTracksLengthMeterTotal: item.classicTracksLengthMeterTotal,
      skatingTracksLengthMeterToday: item.skatingTracksLengthMeterToday,
      skatingTracksLengthMeterTotal: item.skatingTracksLengthMeterTotal,
      halfpipesToday: item.halfpipesToday,
      halfpipesTotal: item.halfpipesTotal,
      snowcrossTracksToday: item.snowcrossTracksToday,
      snowcrossTracksTotal: item.snowcrossTracksTotal,
      snowparksToday: item.snowparksToday,
      snowparksTotal: item.snowparksTotal,
      airboardSlopesToday: item.airboardSlopesToday,
      airboardSlopesTotal: item.airboardSlopesTotal,
      snowtubeTracksToday: item.snowtubeTracksToday,
      snowtubeTracksTotal: item.snowtubeTracksTotal,
      tobogganRunsToday: item.tobogganRunsToday,
      tobogganRunsTotal: item.tobogganRunsTotal,
      tobogganRunsLengthMeterToday: item.tobogganRunsLengthMeterToday,
      tobogganRunsLengthMeterTotal: item.tobogganRunsLengthMeterTotal,
      winterHikingTrailsLengthMeterToday: item.winterHikingTrailsLengthMeterToday,
      winterHikingTrailsLengthMeterTotal: item.winterHikingTrailsLengthMeterTotal,
      snowshoeTrailsToday: item.snowshoeTrailsToday,
      snowshoeTrailsTotal: item.snowshoeTrailsTotal,
      snowshoeTrailsLengthMeterToday: item.snowshoeTrailsLengthMeterToday,
      snowshoeTrailsLengthMeterTotal: item.snowshoeTrailsLengthMeterTotal,
    };
  }
}
