import { SisMediaOperatingHours } from 'src/app/sismedia/sismedia-operating-hours-modal/domain/sismedia-operating-hours.model';
import { SisMediaOperatingHoursEntry } from 'src/app/sismedia/sismedia-operating-hours-modal/domain/sismedia-operating-hours-entry.model';

export class SisMediaOperatingHoursEntryAdapter {
  static adapt(item: any): SisMediaOperatingHours | null {
    if (!item) {
      return null;
    }

    const operatingHoursEntries: SisMediaOperatingHoursEntry[] = [];

    item.operatingHours?.forEach((operatingHoursEntry: any) => {
      const operatingHoursData: SisMediaOperatingHoursEntry = {
        assetGuid: operatingHoursEntry.assetGuid,
        weekday: operatingHoursEntry.weekday,
        status: operatingHoursEntry.status,
        from1: operatingHoursEntry.from1 ?? '',
        until1: operatingHoursEntry.until1 ?? '',
        from2: operatingHoursEntry.from2 ?? '',
        until2: operatingHoursEntry.until2 ?? '',
      };

      operatingHoursEntries.push(operatingHoursData);
    });

    operatingHoursEntries.sort((a, b) => a.weekday - b.weekday);

    return {
      enabled: item.enabled,
      entries: operatingHoursEntries,
    };
  }
}
