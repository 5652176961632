import { ScreenType } from "src/app/media-center/screens/domain/screen-type.model";

export class ScreenTypeAdapter {
  static adapt(items: any): ScreenType[] {
    const result: ScreenType[] =
      (items as any[])?.map<ScreenType>((e: any) => ({
        id: e.id,
        type: e.type,
      })) || [];

    return result;
  }
}
