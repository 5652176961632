import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MapIconClickedEvent } from 'src/app/core/eventbus/events';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { Configuration } from 'src/app/maps/domain/configuration.model';
import { RopewayEcoModeSettingsService } from 'src/app/maps/domain/ropeway-ecomode/ropeway-ecomode-settings.service';
import { EnergyLiveDataService } from 'src/app/maps/livedata/energy-livedata.service';
import { MeteoForecast } from 'src/app/maps/livedata/meteo-forecast.model';
import { MeteoForecastService } from 'src/app/maps/livedata/meteo-forecast.service';
import { MeteoLivedata } from 'src/app/maps/livedata/meteo-livedata.model';
import { MeteoLivedataService } from 'src/app/maps/livedata/meteo-livedata.service';
import { RopewayAvailabilityService } from 'src/app/maps/livedata/ropeway-availability.service';
import { RopewayEfaService } from 'src/app/maps/livedata/ropeway-efa.service';
import { RopewayLivedata } from 'src/app/maps/livedata/ropeway-livedata.model';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';
import { SambesiWhatsUpService } from 'src/app/maps/livedata/sambesi-whatsup.service';
import { DetailView } from 'src/app/maps/widget-list/widget-list-services/domain/detailview.model';
import { Widget } from 'src/app/maps/widget-list/widget-list-services/domain/widget.model';
import { WidgetName } from 'src/app/maps/widget-list/widget-list-services/domain/widgetname.enum';
import { AbbSmartSensorWidgetComponent } from 'src/app/maps/widgets/abbsmartsensor-widget/abbsmartsensor-widget.component';
import { AlarmWidgetComponent } from 'src/app/maps/widgets/alarm-widget/alarm-widget.component';
import { CabinPositionWidgetComponent } from 'src/app/maps/widgets/cabinposition-widget/cabinposition-widget.component';
import { IcingChartComponent } from 'src/app/maps/widgets/charts/icing-chart/icing-chart.component';
import { SpeedChartComponent } from 'src/app/maps/widgets/charts/speed-chart/speed-chart.component';
import { TemperatureChartComponent } from 'src/app/maps/widgets/charts/temperature-chart/temperature-chart.component';
import { TorqueChartComponent } from 'src/app/maps/widgets/charts/torque-chart/torque-chart.component';
import { WetBulbChartComponent } from 'src/app/maps/widgets/charts/wet-bulb-chart/wet-bulb-chart.component';
import { WindChartComponent } from 'src/app/maps/widgets/charts/wind-chart/wind-chart.component';
import { ClampingCountWidgetComponent } from 'src/app/maps/widgets/clampingcount-widget/clampingcount-widget.component';
import { AbbSmartSensorDetailViewComponent } from 'src/app/maps/widgets/detailviews/abbsmartsensor-detailview/abbsmartsensor-detailview.component';
import { AlarmDetailViewComponent } from 'src/app/maps/widgets/detailviews/alarm-detailview/alarm-detailview.component';
import { ClampingCountDetailViewComponent } from 'src/app/maps/widgets/detailviews/clampingcount-detailview/clampingcount-detailview.component';
import { EfaDetailViewComponent } from 'src/app/maps/widgets/detailviews/efa-detailview/efa-detailview.component';
import { RemoteAccessDetailViewComponent } from 'src/app/maps/widgets/detailviews/remote-access-detailview/remote-access-detailview.component';
import { RopewayAvailabilityDetailViewComponent } from 'src/app/maps/widgets/detailviews/ropeway-availability-detailview/ropeway-availability-detailview.component';
import { RopewayEcoModeDetailViewComponent } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/ropeway-ecomode-detailview.component';
import { RopewayEnergyDetailViewComponent } from 'src/app/maps/widgets/detailviews/ropeway-energy-detailview/ropeway-energy-detailview.component';
import { RopewaySettingsDetailViewComponent } from 'src/app/maps/widgets/detailviews/ropeway-settings-detailview/ropeway-settings-detailview.component';
import { SambesiWhatsUpDetailViewComponent } from 'src/app/maps/widgets/detailviews/sambesi-whatsup-detailview/sambesi-whatsup-detailview.component';
import { StoptimeDetailViewComponent } from 'src/app/maps/widgets/detailviews/stoptime-detailview/stoptime-detailview.component';
import { TransportWeightDetailViewComponent } from 'src/app/maps/widgets/detailviews/transport-weight-detailview/transport-weight-detailview.component';
import { TruScanDetailViewComponent } from 'src/app/maps/widgets/detailviews/truscan-detailview/truscan-detailview.component';
import { WebcamDetailViewComponent } from 'src/app/maps/widgets/detailviews/webcam-detailview/webcam-detailview.component';
import { EfaWidgetComponent } from 'src/app/maps/widgets/efa-widget/efa-widget.component';
import { EnergyWidgetComponent } from 'src/app/maps/widgets/energy-widget/energy-widget.component';
import { IcingWidgetComponent } from 'src/app/maps/widgets/icing-widget/icing-widget.component';
import { OpHoursWidgetComponent } from 'src/app/maps/widgets/ophours-widget/ophours-widget.component';
import { RemoteAccessWidgetComponent } from 'src/app/maps/widgets/remote-access-widget/remote-access-widget.component';
import { RopekmWidgetComponent } from 'src/app/maps/widgets/ropekm-widget/ropekm-widget.component';
import { RopewayAvailabilityWidgetComponent } from 'src/app/maps/widgets/ropeway-availability-widget/ropeway-availability-widget.component';
import { RopewayChartWidgetComponent } from 'src/app/maps/widgets/ropeway-chart-widget/ropeway-chart-widget.component';
import { RopewayEcoModeWidgetComponent } from 'src/app/maps/widgets/ropeway-ecomode-widget/ropeway-ecomode-widget.component';
import { RopewaySettingsWidgetComponent } from 'src/app/maps/widgets/ropeway-settings-widget/ropeway-settings-widget.component';
import { SambesiWhatsUpWidgetComponent } from 'src/app/maps/widgets/sambesi-whatsup-widget/sambesi-whatsup-widget.component';
import { SpeedWidgetComponent } from 'src/app/maps/widgets/speed-widget/speed-widget.component';
import { StoptimeWidgetComponent } from 'src/app/maps/widgets/stoptime-widget/stoptime-widget.component';
import { TemperatureWidgetComponent } from 'src/app/maps/widgets/temperature-widget/temperature-widget.component';
import { TorqueWidgetComponent } from 'src/app/maps/widgets/torque-widget/torque-widget.component';
import { TransportWeightWidgetComponent } from 'src/app/maps/widgets/transportweight-widget/transportweight-widget.component';
import { TripsWidgetComponent } from 'src/app/maps/widgets/trips-widget/trips-widget.component';
import { TruScanWidgetComponent } from 'src/app/maps/widgets/truscan-widget/truscan-widget.component';
import { UtilizationWidgetComponent } from 'src/app/maps/widgets/utilization-widget/utilization-widget.component';
import { WebcamWidgetComponent } from 'src/app/maps/widgets/webcam-widget/webcam-widget.component';
import { WetBulbWidgetComponent } from 'src/app/maps/widgets/wet-bulb-widget/wet-bulb-widget.component';
import { WindWidgetComponent } from 'src/app/maps/widgets/wind-widget/wind-widget.component';

@Injectable({
  providedIn: 'root',
})
export class RopewayWidgetListService {
  constructor(
    private eventBus: EventBusService,
    private energyLiveDataService: EnergyLiveDataService,
    private meteoForecastService: MeteoForecastService,
    private meteoLiveDataService: MeteoLivedataService,
    private ropewayLiveDataService: RopewayLivedataService,
    private ropewayEcoModeSettingsService: RopewayEcoModeSettingsService,
    private ropewayEfaService: RopewayEfaService,
    private sambesiWhatsUpService: SambesiWhatsUpService,
    private ropewayAvailabilityService: RopewayAvailabilityService
  ) {}

  buildWidgetList(
    mapIconClickedEvent: MapIconClickedEvent,
    userFeatures: Feature[],
    bigScreenMode: boolean
  ): Observable<Widget[]> {
    if (!userFeatures) {
      return of([]);
    }

    return combineLatest([
      this.ropewayLiveDataService.ropewayLiveData$,
      this.meteoLiveDataService.meteoLiveDatas$,
      this.meteoForecastService.forecasts$,
      this.energyLiveDataService.energy$,
      this.ropewayEcoModeSettingsService.ropewayEcoModeSettings$.pipe(
        map((settings) => settings.find((d) => d.ropewaySisId === mapIconClickedEvent?.ropeway.sisId))
      ),
      this.ropewayEfaService.efas$,
      this.sambesiWhatsUpService.messages$,
      this.ropewayAvailabilityService.ropewayAvailability$,
    ]).pipe(
      map(
        ([
          ropewayLiveDatas,
          meteoLiveDatas,
          meteoForecasts,
          energyLiveDatas,
          ropewayEcoModeSettings,
          efas,
          sambesiWhatsUpMessages,
          ropewayAvailability,
        ]) => {
          const event = mapIconClickedEvent;
          const ropeway = event?.ropeway;
          const ropewayMeteoStations = ropeway?.meteoStations;
          const ropewayEfas = efas.filter((a) => a.sisId === ropeway.sisId);

          let ropewayLiveData: RopewayLivedata;
          if (ropewayLiveDatas && ropeway) {
            ropewayLiveData = ropewayLiveDatas.find((d) => d.deviceSisId === ropeway.sisId);
          }

          const widgetsToRemove: WidgetName[] = [];

          let clickedRopewayMeteoLiveData: MeteoLivedata[] = [];
          let clickedRopewayMeteoForecastData: MeteoForecast[];

          if (ropewayMeteoStations) {
            if (meteoLiveDatas) {
              clickedRopewayMeteoLiveData = meteoLiveDatas.filter((liveData) =>
                ropewayMeteoStations.find((ms) => ms.meteoDeviceSisId === liveData.meteoDeviceSisId)
              );
            }
            if (meteoForecasts) {
              clickedRopewayMeteoForecastData = meteoForecasts.filter((forecastData) =>
                ropewayMeteoStations.find((ms) => ms.meteoDeviceSisId === forecastData.deviceSisId)
              );
            }
          }

          if (
            ropeway == null ||
            !(
              ropewayEfas.length > 0 &&
              userFeatures.some((f) =>
                f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_TELEMETRY, FeatureAccessLevel.READ))
              )
            )
          ) {
            widgetsToRemove.push(WidgetName.Efa);
          }
          if (
            !(ropeway.meteoStations?.length > 0) &&
            !(
              ropewayEfas.length > 0 &&
              userFeatures.some((f) =>
                f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_TELEMETRY, FeatureAccessLevel.READ))
              )
            )
          ) {
            widgetsToRemove.push(WidgetName.Alarm);
          }
          if (ropewayLiveData?.actualSpeedMps == null) {
            widgetsToRemove.push(WidgetName.Speed);
          }
          if (ropewayLiveData?.driveMotorCurrentAmpere == null && ropewayLiveData?.driveMotorTorquePercent == null) {
            widgetsToRemove.push(WidgetName.Torque);
          }
          if (ropewayLiveData?.ropeKm == null) {
            widgetsToRemove.push(WidgetName.RopeKm);
          }
          if (ropewayLiveData?.tripCount == null) {
            widgetsToRemove.push(WidgetName.TripCount);
          }
          if (ropewayLiveData?.operatingHours == null) {
            widgetsToRemove.push(WidgetName.OperatingHours);
          }
          if (ropewayLiveData?.vehicleOnTrackCountPercent == null && ropewayLiveData?.vehicleCountPercent == null) {
            widgetsToRemove.push(WidgetName.Utilization);
          }
          if (ropewayLiveData?.cabinPositionMeter == null) {
            widgetsToRemove.push(WidgetName.CabinPosition);
          }
          if (!ropewayLiveData) {
            widgetsToRemove.push(WidgetName.Stoptime);
          }

          if (!clickedRopewayMeteoLiveData?.find((liveData) => liveData.temperatureCelsius != null)) {
            widgetsToRemove.push(WidgetName.Temperature);
          }
          if (!event.meteoStation && !ropewayMeteoStations?.length) {
            widgetsToRemove.push(WidgetName.Wind);
          }

          if (!clickedRopewayMeteoForecastData?.find((forecastData) => forecastData.icingIntensity != null)) {
            widgetsToRemove.push(WidgetName.Icing);
          }

          const hasWetBulbTemperatureCelsiusForecast = clickedRopewayMeteoForecastData?.find(
            (forecastData) => forecastData.wetBulbTemperatureCelsius != null
          );
          const hasWetBulbTemperatureCelsiusLiveData = clickedRopewayMeteoLiveData?.find(
            (meteoData) => meteoData.wetBulbTemperatureCelsius != null
          );

          if (!hasWetBulbTemperatureCelsiusForecast && !hasWetBulbTemperatureCelsiusLiveData) {
            widgetsToRemove.push(WidgetName.WetBulb);
          }

          if (!ropewayLiveData?.vehicleData || !ropewayLiveData.vehicleData['transportWeightKg']) {
            widgetsToRemove.push(WidgetName.TransportWeight);
          }

          if (!ropewayLiveData?.vehicleData || !ropewayLiveData.vehicleData['clampingCount']) {
            widgetsToRemove.push(WidgetName.ClampingCount);
          }

          if (
            !ropeway?.abbSmartSensors?.length ||
            !userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_ABBSMARTSENSOR)
          ) {
            widgetsToRemove.push(WidgetName.AbbSmartSensor);
          }

          if (
            !ropeway?.truScanInstallations?.length ||
            !userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_TRUSCAN)
          ) {
            widgetsToRemove.push(WidgetName.TruScan);
          }

          if (
            bigScreenMode ||
            !userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_WEBCAM) ||
            !ropeway?.webcams?.length
          ) {
            widgetsToRemove.push(WidgetName.Webcam);
          }

          if (
            !userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_REMOTEACCESS) ||
            !ropeway?.remoteAccesses?.length
          ) {
            widgetsToRemove.push(WidgetName.RemoteAccess);
          }

          if (
            !userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_SAMBESI_WHATSUP) ||
            !sambesiWhatsUpMessages.some((m) => m.ropewaySisId === ropeway.sisId)
          ) {
            widgetsToRemove.push(WidgetName.SambesiWhatsUp);
          }

          const widgets = this.getWidgets(this.eventBus);

          const ropewayConfig = ropeway?.configurations?.find(
            (c) =>
              c.configKey === Configuration.driveTorqueNmKey || c.configKey === Configuration.driveMotorCurrentAmpereKey
          );

          const torqueWidget = widgets.find((widget) => widget.name === WidgetName.Torque);
          if (torqueWidget && ropewayConfig) {
            torqueWidget.tooltip =
              ropewayConfig.configKey === Configuration.driveMotorCurrentAmpereKey
                ? 'ropeway.term.motorcurrent'
                : 'ropeway.term.torque';
          }

          const windWidget = widgets.find((widget) => widget.name === WidgetName.Wind);

          windWidget.expandable =
            mapIconClickedEvent.meteoStation != null || mapIconClickedEvent?.ropeway?.meteoStations?.length > 0;
          if (mapIconClickedEvent.meteoStation != null) {
            windWidget.expand();
          } else {
            windWidget.close();
          }

          if (
            !userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_ENERGY) ||
            !energyLiveDatas.some((e) => ropeway.sisId === e.parentSisId)
          ) {
            widgetsToRemove.push(WidgetName.Energy);
          }

          if (!ropewayEcoModeSettings || !userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_ECOMODE)) {
            widgetsToRemove.push(WidgetName.RopewayEcoMode);
          }

          if (!userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_ROPEWAY_SETTINGS)) {
            widgetsToRemove.push(WidgetName.RopewaySettings);
          }

          if (
            !ropewayAvailability ||
            !ropeway.availability ||
            !userFeatures.some((f) =>
              f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_ROPEWAY_AVAILABILITY, FeatureAccessLevel.READ))
            )
          ) {
            widgetsToRemove.push(WidgetName.RopewayAvailability);
          }

          if (
            widgetsToRemove.includes(WidgetName.Wind) &&
            widgetsToRemove.includes(WidgetName.Torque) &&
            widgetsToRemove.includes(WidgetName.Speed)
          ) {
            widgetsToRemove.push(WidgetName.RopewayChart);
          }

          return widgets.filter((widget) => !widgetsToRemove.includes(widget.name));
        }
      ),
      take(1)
    );
  }

  private getWidgets(eventBus: EventBusService): Widget[] {
    return [
      new Widget({
        name: WidgetName.Speed,
        component: SpeedWidgetComponent,
        detailView: new DetailView({ component: SpeedChartComponent, modal: true }),
        tooltip: 'ropeway.term.speed',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Alarm,
        component: AlarmWidgetComponent,
        detailView: new DetailView({ component: AlarmDetailViewComponent }),
        tooltip: 'alarming.term.alarms',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Efa,
        component: EfaWidgetComponent,
        detailView: new DetailView({ component: EfaDetailViewComponent }),
        tooltip: 'general.term.efa',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Wind,
        component: WindWidgetComponent,
        detailView: new DetailView({ component: WindChartComponent, modal: true }),
        tooltip: 'ropeway.term.wind',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Torque,
        component: TorqueWidgetComponent,
        detailView: new DetailView({ component: TorqueChartComponent, modal: true }),
        tooltip: 'ropeway.term.torque',
        eventBus,
      }),
      new Widget({
        name: WidgetName.RopeKm,
        component: RopekmWidgetComponent,
        tooltip: 'ropeway.term.ropeKm',
        eventBus,
      }),
      new Widget({
        name: WidgetName.TripCount,
        component: TripsWidgetComponent,
        tooltip: 'ropeway.term.tripCount',
        eventBus,
      }),
      new Widget({
        name: WidgetName.OperatingHours,
        component: OpHoursWidgetComponent,
        tooltip: 'ropeway.term.operatingHours',
        eventBus,
      }),
      new Widget({
        name: WidgetName.TransportWeight,
        component: TransportWeightWidgetComponent,
        detailView: new DetailView({ component: TransportWeightDetailViewComponent }),
        tooltip: 'ropeway.term.transportweight',
        eventBus,
      }),
      new Widget({
        name: WidgetName.ClampingCount,
        component: ClampingCountWidgetComponent,
        detailView: new DetailView({ component: ClampingCountDetailViewComponent }),
        tooltip: 'ropeway.term.clampingCount',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Stoptime,
        component: StoptimeWidgetComponent,
        detailView: new DetailView({ component: StoptimeDetailViewComponent }),
        tooltip: 'ropeway.term.stopTime',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Utilization,
        component: UtilizationWidgetComponent,
        tooltip: 'ropeway.term.utilization',
        eventBus,
      }),
      new Widget({
        name: WidgetName.CabinPosition,
        component: CabinPositionWidgetComponent,
        tooltip: 'ropeway.term.cabinPosition',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Temperature,
        component: TemperatureWidgetComponent,
        detailView: new DetailView({ component: TemperatureChartComponent, modal: true }),
        tooltip: 'ropeway.term.temperature',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Icing,
        component: IcingWidgetComponent,
        detailView: new DetailView({ component: IcingChartComponent, modal: true }),
        tooltip: 'ropeway.term.icing',
        eventBus,
      }),
      new Widget({
        name: WidgetName.WetBulb,
        component: WetBulbWidgetComponent,
        detailView: new DetailView({ component: WetBulbChartComponent, modal: true }),
        tooltip: 'ropeway.term.wetBulbTemp',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Webcam,
        component: WebcamWidgetComponent,
        detailView: new DetailView({ component: WebcamDetailViewComponent, height: 212 }),
        tooltip: 'Webcam',
        eventBus,
      }),
      new Widget({
        name: WidgetName.AbbSmartSensor,
        component: AbbSmartSensorWidgetComponent,
        detailView: new DetailView({ component: AbbSmartSensorDetailViewComponent }),
        tooltip: 'general.term.abbSmartSensor',
        eventBus,
      }),
      new Widget({
        name: WidgetName.TruScan,
        component: TruScanWidgetComponent,
        detailView: new DetailView({ component: TruScanDetailViewComponent }),
        tooltip: 'general.term.truScan',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Energy,
        component: EnergyWidgetComponent,
        detailView: new DetailView({ component: RopewayEnergyDetailViewComponent }),
        tooltip: 'energy.term.power',
        eventBus,
      }),
      new Widget({
        name: WidgetName.RopewayEcoMode,
        component: RopewayEcoModeWidgetComponent,
        detailView: new DetailView({ component: RopewayEcoModeDetailViewComponent }),
        tooltip: 'general.term.ropewayEcoMode',
        eventBus,
      }),
      new Widget({
        name: WidgetName.SambesiWhatsUp,
        component: SambesiWhatsUpWidgetComponent,
        detailView: new DetailView({ component: SambesiWhatsUpDetailViewComponent }),
        tooltip: 'general.term.sambesiWhatsUp',
        eventBus,
      }),
      new Widget({
        name: WidgetName.RopewaySettings,
        component: RopewaySettingsWidgetComponent,
        detailView: new DetailView({ component: RopewaySettingsDetailViewComponent }),
        tooltip: 'general.term.settings',
        eventBus,
      }),
      new Widget({
        name: WidgetName.RopewayAvailability,
        component: RopewayAvailabilityWidgetComponent,
        detailView: new DetailView({ component: RopewayAvailabilityDetailViewComponent }),
        tooltip: 'general.term.ropewayAvailability',
        eventBus,
      }),
      new Widget({
        name: WidgetName.RopewayChart,
        component: RopewayChartWidgetComponent,
        tooltip: 'general.term.multiChart',
        eventBus,
      }),
      new Widget({
        name: WidgetName.RemoteAccess,
        component: RemoteAccessWidgetComponent,
        detailView: new DetailView({ component: RemoteAccessDetailViewComponent }),
        tooltip: 'general.term.remoteaccess',
        eventBus,
      }),
      // Not used yet
      // new Widget({
      //   name: WidgetName.PassengerCount,
      //   component: PassengerCountWidgetComponent,
      //   tooltip: 'ropeway.term.passengerCount',
      //   eventBus,
      // }),
      // new Widget({
      //   name: WidgetName.CarriageTilt,
      //   component: CarriagetiltWidgetComponent,
      //   tooltip: 'ropeway.term.cabinTilt',
      //   eventBus,
      // }),
      // new Widget({
      //   name: WidgetName.GearTemperature,
      //   component: GeartempWidgetComponent,
      //   tooltip: 'ropeway.term.gearTemperature',
      //   eventBus,
      // }),
      // new Widget({
      //   name: WidgetName.Zones,
      //   component: ZonesWidgetComponent,
      //   tooltip: 'ropeway.term.zoneMonitoring',
      //   eventBus,
      // }),
    ];
  }
}
