import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import * as signalR from '@microsoft/signalr';
import { firstValueFrom, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalrMessageAdapter extends Unsubscriber {
  private readonly _messageReceived$ = new Subject<string>();

  readonly messageReceived$ = this._messageReceived$.asObservable();

  constructor(private msalBroadcastService: MsalBroadcastService, private msalService: MsalService) {
    super();

    if (environment.isUiTest) {
      this.mockSignalRConnectionForUiTests();
    } else {
      this.msalBroadcastService.msalSubject$.pipe(take(1)).subscribe(() => {
        this.connect();
      });
    }
  }

  private connect(): void {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(environment.webapp + '/messagehub', {
        accessTokenFactory: () =>
          firstValueFrom(
            this.msalBroadcastService.inProgress$.pipe(
              filter((status) => status === InteractionStatus.None),
              switchMap(() =>
                this.msalService.acquireTokenSilent({
                  scopes: environment.msalScopes,
                })
              ),
              map((token) => token.accessToken)
            )
          ),
      })
      .withAutomaticReconnect()
      .build();

    this.addHubConnectionListeners(connection);
    this.startConnection(connection);
  }

  private addHubConnectionListeners(hubConnection: signalR.HubConnection) {
    hubConnection.on('NewMessage', (data: any) => {
      this._messageReceived$.next(data);
    });
  }

  private startConnection(hubConnection: signalR.HubConnection) {
    if (hubConnection.state === signalR.HubConnectionState.Connected) {
      return;
    }
    console.log('Connecting to SignalR Hub...');

    hubConnection
      .start()
      .then(() => {
        console.log('SignalR connected.');
      })
      .catch((err) => {
        console.log('Error while starting connection: ' + err);
      });
  }

  private mockSignalRConnectionForUiTests(): void {
    window['uiTestSignalRMessage$']
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event: any) => this._messageReceived$.next(event));
  }
}
