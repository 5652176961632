<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <g>
          <path
            d="M35,78.8,33.23,79a2.36,2.36,0,1,1-.3-2.18l.64-.09ZM92.3,67.33a2.37,2.37,0,0,0-2.55,1.55L88,69.13l1.4,2,.65-.09a2.35,2.35,0,0,0,1.62,1,2.38,2.38,0,0,0,.62-4.71ZM41.21,75.7l-6.55.92,1.4,2,6.55-.92Zm1.09-.15,1.4,2,6.55-.92-1.4-2Zm14.06-2-6.55.92,1.4,2,6.55-.92Zm1.09-.15,1.4,2,6.55-.92-1.4-2Zm14.19-2-6.55.92,1.4,2L73,73.46Zm1.09-.16,1.4,2,6.55-.91-1.4-2Zm14.19-2-6.55.92,1.4,2,6.55-.92Z"
            style="fill: #32849a"
          />
          <path
            d="M93.56,55.64,92,56.47a2.38,2.38,0,1,1-1-1.93l.57-.31Zm49.75-30.72a2.36,2.36,0,0,0-1.85,2.34l-1.54.84,2,1.41.57-.31a2.35,2.35,0,0,0,1.85.35,2.37,2.37,0,1,0-1.05-4.63Zm-45,25.64L92.52,53.7l2,1.42L100.35,52Zm1-.52,2,1.41,5.82-3.14-2-1.42Zm12.5-6.75L106,46.43l2,1.42,5.82-3.15Zm1-.53,2,1.42L120.6,41l-2-1.42ZM125.37,36l-5.81,3.15,2,1.41,5.82-3.14Zm1-.52,2,1.42,5.82-3.15-2-1.41ZM139,28.62l-5.82,3.14,2,1.42L141,30Z"
            style="fill: #979797"
          />
        </g>
        <g>
          <path
            d="M132.48,50l.81,9.69-.81,9.69a2.31,2.31,0,0,1-2.3,2.3H118.32a2.31,2.31,0,0,1-2.3-2.3l-.82-9.69L116,50a2.3,2.3,0,0,1,2.3-2.3h11.86A2.31,2.31,0,0,1,132.48,50Z"
            style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px"
          />
          <line
            x1="124.25"
            y1="47.69"
            x2="124.25"
            y2="38.64"
            style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px"
          />
          <polygon
            points="130.3 59.68 129.56 50.81 118.94 50.81 118.2 59.68 130.3 59.68"
            style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px"
          />
          <line
            x1="124.25"
            y1="59.68"
            x2="124.25"
            y2="68.54"
            style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px"
          />
        </g>
        <g>
          <g>
            <path
              d="M106.36,106.75l-1.27,15.14L106.36,137a3.61,3.61,0,0,0,3.6,3.6h18.52a3.62,3.62,0,0,0,3.6-3.61l1.27-15.13-1.27-15.14a3.61,3.61,0,0,0-3.6-3.6H110A3.61,3.61,0,0,0,106.36,106.75Z"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2.5px"
            />
            <line
              x1="119.22"
              y1="103.15"
              x2="119.22"
              y2="89.02"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2.5px"
            />
            <polygon
              points="109.77 121.89 110.93 108.03 127.51 108.03 128.67 121.89 109.77 121.89"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2.5px"
            />
            <line
              x1="119.22"
              y1="121.89"
              x2="119.22"
              y2="135.74"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2.5px"
            />
          </g>
          <path
            d="M153.36,97l.78.22a3,3,0,1,1-.73,2.66l-2.11-.59ZM79.16,78.73a3,3,0,0,0,5.13,2l.78.21,2.07-2.29L85,78.11a3,3,0,0,0-5.86.62Zm62.85,18L150,99,152,96.68l-8-2.21Zm-7.23-4.86-2.07,2.28,8,2.21,2.06-2.29Zm-11.2-.25,8,2.21,2.06-2.29-8-2.21Zm-7.23-4.86-2.07,2.28,8,2.21L124.31,89ZM105,86.5l8,2.21L115,86.42l-8-2.21Zm-7.23-4.86L95.7,83.92l8,2.21,2.06-2.29ZM86.4,81.35l8,2.21,2.06-2.29-8-2.21Z"
            style="fill: #979797"
          />
        </g>
        <rect width="187.5" height="225" style="fill: none" />
        <g>
          <g>
            <path
              d="M72,88.22l1,12.19L72,112.6a2.91,2.91,0,0,1-2.9,2.9H54.21a2.91,2.91,0,0,1-2.9-2.9l-1-12.19,1-12.19a2.91,2.91,0,0,1,2.9-2.9H69.12A2.91,2.91,0,0,1,72,88.22Z"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px"
            />
            <line
              x1="61.67"
              y1="85.32"
              x2="61.67"
              y2="73.94"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px"
            />
            <polygon
              points="69.27 100.41 68.34 89.25 54.99 89.25 54.06 100.41 69.27 100.41"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px"
            />
            <line
              x1="61.67"
              y1="100.41"
              x2="61.67"
              y2="111.57"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Widgettexte">
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
      >
        {{ totalDevices }}
      </text>
    </g>
  </g>
</svg>
