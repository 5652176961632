import { OperatingInfoText } from 'src/app/operating-info/domain/operating-info-text.model';

export class OperatingInfoTextAdapter {
  static adapt(item: any): OperatingInfoText[] {
    const texts: OperatingInfoText[] = [];

    item.forEach((e: any) => {
      texts.push({
        language: e.language,
        text: e.text,
        title: e.title,
        description: e.description,
      });
    });

    return texts;
  }
}
