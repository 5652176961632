import 'zone.js/dist/zone-error';

import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessaging, AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AppComponent } from 'src/app/app.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppServiceLoader } from 'src/app/app-service-loader.component';
import { BottomDrawerModule } from 'src/app/bottom-drawer/bottom-drawer.module';
import { CoreModule } from 'src/app/core/core.module';
import { ErrorPage } from 'src/app/error/error.page';
import { IonicGestureConfig } from 'src/app/hammerguesture.config';
import { HeaderComponent } from 'src/app/header/header.component';
import { MaintenanceBannerComponent } from 'src/app/header/maintenance-banner/maintenance-banner.component';
import { ApiConnectionInterceptor } from 'src/app/interceptor/api-connection.interceptor';
import { HttpCurrentTenantInterceptor } from 'src/app/interceptor/http-current-tenant.interceptor';
import { LoginComponent } from 'src/app/login/login.component';
import { MainMenuComponent } from 'src/app/main-menu/main-menu.component';
import { MainMenuListComponent } from 'src/app/main-menu/main-menu-list/main-menu-list.component';
import { MainMenuNewsComponent } from 'src/app/main-menu/main-menu-news/main-menu-news.component';
import { MainMenuNewsItemComponent } from 'src/app/main-menu/main-menu-news/main-menu-news-item/main-menu-news-item.component';
import { SelectDestinationModal } from 'src/app/main-menu/select-destination/select-destination.component';
import { SelectDestinationItemComponent } from 'src/app/main-menu/select-destination/select-destination-item/select-destination-item.component';
import { SelectLanguageModal } from 'src/app/main-menu/select-language/select-language.component';
import { TenantEditModal } from 'src/app/main-menu/tenant-edit-modal/tenant-edit.component';
import { AlarmLibraryModal } from 'src/app/maps/alarmlibrary/alarmlibrary.component';
import { AlarmLibraryItemIconComponent } from 'src/app/maps/alarmlibrary/alarmlibrary-item-icon/alarmlibrary-item-icon.component';
import { OperatingInfoPageModule } from 'src/app/operating-info/operating-info.page.module';
import { RemoteAccessComponent } from 'src/app/remote-access/remote-access.component';
import { ENV_PROVIDERS, environment } from 'src/environments/environment';

declare const VERSION: string;

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'cockpit.siscontrol.ch',
  },
  palette: {
    popup: {
      background: '#6b6b6b',
    },
    button: {
      background: '#0fafa9',
    },
  },
  theme: 'edgeless',
  position: 'bottom',
  type: 'info',
  showLink: false,
  content: {},
};

export function loggerCallback() {}

export function TranslateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend), './assets/translations/', `.json?version=${VERSION}`);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.msalAuthority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();

  if (!environment.isUiTest) {
    protectedResourceMap.set(environment.msalProtectedResource, environment.msalScopes);
    protectedResourceMap.set(environment.baseUrlApi, environment.msalScopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AppServiceLoader,
    LoginComponent,
    SelectDestinationModal,
    TenantEditModal,
    SelectDestinationItemComponent,
    SelectLanguageModal,
    MainMenuComponent,
    HeaderComponent,
    AlarmLibraryModal,
    AlarmLibraryItemIconComponent,
    MaintenanceBannerComponent,
    MainMenuListComponent,
    MainMenuNewsComponent,
    MainMenuNewsItemComponent,
    RemoteAccessComponent,
    ErrorPage,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
    }),
    IonicStorageModule.forRoot({
      driverOrder: ['localstorage'],
    }),
    AppRoutingModule,
    HammerModule,
    HttpClientModule,
    ReactiveFormsModule,
    BottomDrawerModule,
    MsalModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    LeafletModule,
    PortalModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    CoreModule,
    OperatingInfoPageModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    MatTooltipModule,
    ScrollingModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AngularFireDatabase,
    AngularFireAuth,
    AngularFireMessaging,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    MsalService,
    MsalBroadcastService,
    ENV_PROVIDERS,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: IonicGestureConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCurrentTenantInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiConnectionInterceptor,
      multi: true,
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        disableTooltipInteractivity: true,
      },
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
