import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { MasterData } from 'src/app/maps/domain/masterdata.model';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';

@Component({
  selector: 'sis-weathertrend-widget',
  templateUrl: './weathertrend-widget.component.html',
  styleUrls: ['./weathertrend-widget.component.scss'],
})
export class WeathertrendWidgetComponent extends Unsubscriber implements OnInit {
  private masterdata: MasterData;

  constructor(private masterdataService: MasterdataService, private navigator: NavigatorService) {
    super();
  }

  ngOnInit(): void {
    this.masterdataService.masterData$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => (this.masterdata = data));
  }

  openLink(): void {
    if (this.masterdata) {
      this.navigator.openInNewTab(this.masterdata.weatherTrendLink);
    }
  }
}
