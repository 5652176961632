import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';

@Injectable({
  providedIn: 'root',
})
export class AppService extends Unsubscriber {
  private readonly cookieAccepted = 'cookieAccepted';

  readonly loggedIn$ = new ReplaySubject<boolean>(1);
  readonly acquireTokenSilentReady$ = new ReplaySubject<boolean>(1);

  constructor(
    private cookieService: NgcCookieConsentService,
    private translateService: TranslateService,
    private navigatorService: NavigatorService
  ) {
    super();

    this.cookieService.popupClose$.pipe(take(1)).subscribe(() => {
      localStorage.setItem(this.cookieAccepted, 'true');
    });
    this.setupCookieBanner();

    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$), takeUntil(this.cookieService.popupClose$)).subscribe(() => {
      this.setupCookieBanner();
    });
  }

  deleteLocalStorage(): void {
    [...Object.keys(localStorage)].filter((key) => key !== this.cookieAccepted).forEach((key) => localStorage.removeItem(key));
  }

  cleanUpLocalStorage(): void {
    // remove old invalid msal tokens from localstorage
    const keys = [...Object.keys(localStorage)];
    const msalEntries = keys.filter((key) => key.includes('-login.windows.net-'));
    if (msalEntries.length > 4) {
      msalEntries.forEach((key) => localStorage.removeItem(key));

      const k = keys.find((key) => key.includes('active-account'));
      if (k) {
        localStorage.removeItem(k);
      }
    }
  }

  private setupCookieBanner() {
    try {
      this.cookieService?.destroy();
    } catch {
      // do nothing
    }

    if (localStorage.getItem(this.cookieAccepted) !== 'true') {
      const cookieTextTranslationString = 'general.phrase.cookies';
      const cookieUnderstoodTranslationString = 'general.term.understood';

      const cookieConfig = this.cookieService.getConfig();

      this.translateService
        .get([cookieTextTranslationString, cookieUnderstoodTranslationString])
        .pipe(take(1))
        .subscribe((translations) => {
          cookieConfig.content.message = translations[cookieTextTranslationString];
          cookieConfig.content.dismiss = translations[cookieUnderstoodTranslationString];
          cookieConfig.cookie.domain = this.navigatorService.baseUrl;

          this.cookieService.init(cookieConfig);
        });
    }
  }
}
