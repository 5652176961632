import { WritablePart } from 'src/app/core/utils/type-helper';

export enum RopewayStatusType {
  FAULT = 'fault',
  ALARM = 'alarm',
  WARNING = 'warning',
  INFO = 'info',
  OUTDATED = 'outdated',
  DRIVERUNS = 'driveRuns',
  DRIVEREADY = 'driveReady',
  DRIVE_DIRECTION_FORWARD = 'driveDirectionFwd',
  CONTROLLERENABLED = 'controllerEnabled',
  CONTROLLERACTIVE = 'controllerActive',
  OFFLINE = 'offline',
}

export class RopewayStatus {
  static getStatus(level: string): RopewayStatusType {
    switch (level) {
      case RopewayStatusType.ALARM:
        return RopewayStatusType.ALARM;
      case RopewayStatusType.FAULT:
        return RopewayStatusType.FAULT;
      case RopewayStatusType.INFO:
        return RopewayStatusType.INFO;
      case RopewayStatusType.WARNING:
        return RopewayStatusType.WARNING;
      case RopewayStatusType.OUTDATED:
        return RopewayStatusType.OUTDATED;
      case RopewayStatusType.DRIVERUNS:
        return RopewayStatusType.DRIVERUNS;
      case RopewayStatusType.DRIVEREADY:
        return RopewayStatusType.DRIVEREADY;
      case RopewayStatusType.DRIVE_DIRECTION_FORWARD:
        return RopewayStatusType.DRIVE_DIRECTION_FORWARD;
      case RopewayStatusType.CONTROLLERENABLED:
        return RopewayStatusType.CONTROLLERENABLED;
      default:
        console.error(`No RopewayStatus for ${level}`);
        return RopewayStatusType.ALARM;
    }
  }

  deviceSisId: string;
  deviceTimestamp: Date;
  gatewayTimestamp: Date;
  status: RopewayStatusType;
  active: boolean = false;
  outdated: boolean = false;

  constructor(init?: Partial<WritablePart<RopewayStatus>>) {
    Object.assign(this, init);
  }
}
