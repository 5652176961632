<ion-grid class="sis-global-page-grid sis-global-no-scrollbar">
  <sis-page-title [title]="'general.term.operatinginfo'" [featureId]="[[this.featureId]]"> </sis-page-title>
  <ion-row>
    <ion-col class="ion-no-padding">
      <ion-list>
        <ion-radio-group [(ngModel)]="this.selectedLibraryItem">
          <ion-item
            *ngFor="let item of library; trackBy this.itemId"
            button="false"
            data-test="operating-info-textselector-modal-item"
          >
            <sis-operating-info-item
              class="sis-operating-info-item"
              [entry]="item"
              [editable]="item.priority !== 0 && this.writePermission"
              data-test="sis-operating-info-item"
            ></sis-operating-info-item>
            <span style="width: 10px" slot="start"
              ><ion-radio
                slot="start"
                [disabled]="!this.writePermission"
                [value]="item"
                color="secondary"
                data-test="sis-operating-info-item-radio"
              ></ion-radio
            ></span>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col [size]="this.bigScreenMode ? 'auto' : 12">
      <div class="sis-operating-info-qr-code">
        <qrcode
          *ngIf="this.infoLinkString"
          [qrdata]="this.infoLinkString"
          [errorCorrectionLevel]="'M'"
          [allowEmptyString]="true"
          [elementType]="'svg'"
          [margin]="0"
          [width]="100"
          (click)="this.openOperatingInfo()"
        ></qrcode>
      </div>
    </ion-col>
    <ion-col *ngIf="this.bigScreenMode && this.infoLinkString">
      <a class="sis-operating-info-preview" (click)="this.openOperatingInfo(true)">
        <span>{{"general.term.operatinginfo" | translate}}</span>
        <ion-icon class="sis-operating-info-icon" name="open-outline"> </ion-icon>
      </a>
    </ion-col>
    <ion-col>
      <div style="text-align: right">
        <sis-general-button
          [text]="'general.term.newEntry'"
          [btnType]="'Secondary'"
          [width]="this.bigScreenMode ? '120px' : '100%'"
          [disabled]="!this.writePermission"
          (sisClick)="this.showLibraryItem(null)"
          [ngClass]="{ 'sis-global-button-padding-right': this.bigScreenMode }"
          data-test="sis-operating-info-textselector-new-entry-btn"
        ></sis-general-button>
        <sis-general-button
          [text]="'general.term.reset'"
          [btnType]="'Secondary'"
          [width]="this.bigScreenMode ? '120px' : '100%'"
          [disabled]="!this.changed || !this.writePermission"
          (sisClick)="this.reset()"
          [ngClass]="{ 'sis-global-button-padding-right': this.bigScreenMode }"
          data-test="sis-operating-info-textselector-reset-btn"
        ></sis-general-button>
        <sis-general-button
          [text]="'general.term.apply'"
          [btnType]="'Secondary'"
          [width]="this.bigScreenMode ? '120px' : '100%'"
          [disabled]="!this.changed || !this.writePermission"
          [showSpinner]="this.updating"
          (sisClick)="this.updateActiveText()"
          data-test="sis-operating-info-textselector-save-btn"
        ></sis-general-button>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
