<div class="sis-contextmenu" [style.left.px]="this.x" [style.top.px]="this.y" (mouseleave)="close()">
  <ion-item *ngIf="this.title" class="sis-contextmenu-title" lines="full">
    {{ this.title }}
  </ion-item>
  <ion-list>
    <ion-item
      class="sis-contextmenu-item"
      *ngFor="let item of this.menuItems"
      (click)="this.emitSelectedItem(item); $event.stopPropagation()"
      lines="full"
      [disabled]="item.disabled"
      data-test="contextmenu-item"
    >
      {{ item.translationString | translate }}
    </ion-item>
  </ion-list>
</div>
