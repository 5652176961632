import 'leaflet.fullscreen';

import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CRS,
  ImageOverlay,
  imageOverlay,
  LatLng,
  latLng,
  LatLngBoundsExpression,
  Layer,
  LayerGroup,
  layerGroup,
  Map,
  MapOptions,
} from 'leaflet';
import { combineLatest, firstValueFrom } from 'rxjs';
import { delay, map, takeUntil } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MapIconClickedEvent, MapIconHoveredEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { CustomMarker } from 'src/app/maps/domain/custom-marker.model';
import { MasterData } from 'src/app/maps/domain/masterdata.model';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { environment } from 'src/environments/environment';

declare let L: any;
@Component({
  selector: 'sis-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent extends Unsubscriber implements OnInit {
  static readonly defaultOpacity: number = 0.9;
  static readonly selectedOpacity: number = 0.6;
  static readonly maxZoom: number = 2;

  private readonly requiredFeatureForMapEdit = new Feature(FeatureId.COCKPIT, FeatureAccessLevel.WRITE);

  private imageResX: number;
  private imageResY: number;
  private imageBounds: LatLngBoundsExpression;
  private masterData: MasterData;

  readonly canEditMap$ = combineLatest([
    this.userSettingsService.userSettings$,
    this.destinationService.selectedTenantFeatures$,
  ]).pipe(
    map(
      ([userSettings, features]) =>
        userSettings.isAdministrator &&
        features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeatureForMapEdit))
    )
  );

  drawReady = false;
  editMode = false;

  fullScreenControl: any;
  map: Map;
  ropewayLayerGroup: LayerGroup = layerGroup();
  meteoLayerGroup: LayerGroup = layerGroup();
  customMarkerLayerGroup: LayerGroup = layerGroup();
  ropeways: Ropeway[] = [];
  meteoStations: MeteoStation[] = [];
  customMarkers: CustomMarker[] = [];
  options: MapOptions;
  layers: Layer[];
  layersControl: any;
  center: LatLng;
  panomapLoaded = false;
  panomapOverlay: ImageOverlay;
  wasVisibleAtLeastOnce = false;
  bigScreenMode: boolean;
  tooltipText: string;
  popupVisible: boolean;

  constructor(
    private eventBus: EventBusService,
    private zone: NgZone,
    private masterdataService: MasterdataService,
    private translateService: TranslateService,
    private screenSizeService: ScreenSizeService,
    private userSettingsService: UserSettingsService,
    private destinationService: DestinationService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();

    this.panomapOverlay = imageOverlay(
      '',
      [
        [0, 0],
        [0, 0],
      ],
      {}
    );

    this.initLeafletConfigureOptions();
    this.initLayers();
    this.initLayerControl();
  }

  ngOnInit(): void {
    combineLatest([this.selectedMapElementService.selectedMapElement$, this.screenSizeService.landscapeMode$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([event, landscapeMode]) => {
        if (this.map && (event.ropeway || event.meteoStation) && !landscapeMode) {
          this.panomapOverlay.setOpacity(MapComponent.selectedOpacity);
        } else {
          this.panomapOverlay.setOpacity(MapComponent.defaultOpacity);
        }
      });

    this.eventBus
      .observe(MapIconHoveredEvent)
      .pipe(
        map((event) => {
          if (event.hover) {
            const { ropeway, customMarker } = event;
            if (ropeway) {
              this.tooltipText = `${ropeway.fullname} (${ropeway.sisId.toUpperCase()})`;
              return true;
            }
            if (customMarker) {
              this.tooltipText = customMarker.alias;
              return true;
            }
          }
          return false;
        }),
        delay(50),
        takeUntil(this.onDestroy$)
      )
      .subscribe((isVisible) => {
        this.popupVisible = isVisible;
      });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.onDestroy$.subscribe(() => {
      this.eventBus.publish(new MapIconClickedEvent());
    });

    this.masterdataService.masterData$.pipe(takeUntil(this.onDestroy$)).subscribe((masterData) => {
      this.masterData = masterData;
      if (this.masterData?.panoMapFilename?.length) {
        this.meteoStations = this.masterData.meteoStations;
        this.ropeways = this.masterData.ropeways;
        this.customMarkers = this.masterData.customMarkers;
        this.drawReady = true;
      } else {
        this.drawReady = false;
      }
    });
  }

  setMapVisibility($event: boolean): void {
    if ($event) {
      this.wasVisibleAtLeastOnce = true;
    }
  }

  onMapReady(map: Map): void {
    // https://github.com/Asymmetrik/ngx-leaflet#getting-a-reference-to-the-map
    this.map = map;
    this.setupMap();
  }
  onFitMapToView(event: MouseEvent): void {
    event.stopPropagation();
    this.fitMapToView();
  }
  onFitMapToHeight(event: MouseEvent): void {
    event.stopPropagation();
    this.fitMapToHeight();
  }
  onZoomIn(event: MouseEvent): void {
    event.stopPropagation();
    this.zoomIn();
  }
  onZoomOut(event: MouseEvent): void {
    event.stopPropagation();
    this.zoomOut();
  }
  onFullscreen(event: MouseEvent): void {
    event.stopPropagation();
    this.fullscreen();
  }
  async onEditMap(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    const canEditMap = await firstValueFrom(this.canEditMap$);
    if (canEditMap) {
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.map.setMaxZoom(4);
      } else {
        this.map.setMaxZoom(MapComponent.maxZoom);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(e: Event) {
    if (e.target === window) {
      setTimeout(() => {
        this.zone.run(() => {
          this.adjustMinZoomToWindowSize();
        });
      }, 25);
    }
  }

  private adjustMinZoomToWindowSize() {
    const bounds: LatLngBoundsExpression = [
      [0, 0],
      [this.imageResY, 0],
    ];
    this.map.setMinZoom(-20);
    this.map.setMinZoom(this.map.getBoundsZoom(bounds, false));
  }
  private fitMapToView(): void {
    setTimeout(() => {
      this.zone.run(() => {
        this.map.fitBounds(this.imageBounds);
      });
    }, 25);
  }
  private fitMapToHeight(): void {
    setTimeout(() => {
      this.zone.run(() => {
        const bounds: LatLngBoundsExpression = [
          [0, 0],
          [this.imageResY, 0],
        ];
        this.adjustMinZoomToWindowSize();
        this.map.fitBounds(bounds);
      });
    }, 25);
  }
  private fitMapToNoBorder(): void {
    if (this.map.getSize().x > this.imageResX) {
      this.map.setView(this.center, 0.2);
    }
  }
  private zoomIn(): void {
    this.map.setZoom(this.map.getZoom() + 0.5);
  }
  private zoomOut(): void {
    this.map.setZoom(this.map.getZoom() - 0.5);
  }
  private fullscreen(): void {
    this.fullScreenControl.toggleFullScreen();
    this.fitMapToHeight();
  }
  private onMapClicked(): void {
    this.eventBus.publish(new MapIconClickedEvent());
  }

  private async setupMap(): Promise<void> {
    const panoMapUrl = `${environment.baseUrlCockpitBlobStorage}/public-assets/map/${this.masterData.panoMapFilename}`;

    const imageDimension = await this.getImageDimension(panoMapUrl);
    this.imageResY = imageDimension.heigth;
    this.imageResX = imageDimension.width;

    this.imageBounds = [
      [0, 0],
      [this.imageResY, this.imageResX],
    ];
    // add the static Panorama Destination Image to the map with the bounds given by the dimension (height and width) of the image.
    this.panomapOverlay = imageOverlay(panoMapUrl, this.imageBounds, { opacity: MapComponent.defaultOpacity });
    this.layers.push(this.panomapOverlay);

    if (this.bigScreenMode) {
      this.addFullScreenControl();
    }

    this.map.setMaxBounds(this.imageBounds);
    this.center = latLng([this.imageResY / 2, this.imageResX / 2]);
    this.fitMapToHeight();

    this.map.addEventListener('click', () => this.zone.run(() => this.onMapClicked()));

    this.map.addEventListener('drag', () => this.map.panInsideBounds(this.imageBounds, { animate: false }));
  }

  private addFullScreenControl(): void {
    if (this.fullScreenControl) {
      return;
    }
    this.fullScreenControl = L.control
      .fullscreen({
        forceSeparateButton: true,
      })
      .addTo(this.map);
  }

  private initLayers(): void {
    // https://github.com/Asymmetrik/ngx-leaflet#add-custom-layers-base-layers-markers-shapes-etc
    this.layers = [layerGroup([this.ropewayLayerGroup, this.meteoLayerGroup, this.customMarkerLayerGroup])];
  }

  private initLayerControl(): void {
    // https://github.com/Asymmetrik/ngx-leaflet#add-a-layers-control
    this.updateLayerControl();
    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.updateLayerControl();
    });
  }

  private updateLayerControl(): void {
    this.translateService
      .get(['general.term.ropeways', 'map.term.meteoStations'])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.layersControl = {
          overlays: {
            [res['general.term.ropeways']]: this.ropewayLayerGroup,
            [res['map.term.meteoStations']]: this.meteoLayerGroup,
          },
        };
      });
  }

  private initLeafletConfigureOptions(): void {
    // https://github.com/Asymmetrik/ngx-leaflet#create-and-configure-a-map
    this.options = {
      crs: CRS.Simple,
      wheelPxPerZoomLevel: 200,
      zoomControl: false,
      zoom: 0,
      minZoom: -1,
      maxZoom: MapComponent.maxZoom,
      zoomSnap: 0,
      zoomDelta: 0.1,
      maxBoundsViscosity: 0.5,
      attributionControl: false,
    };
  }

  private async getImageDimension(imageUrl: string): Promise<{ heigth: number; width: number }> {
    return new Promise((resolve) => {
      if (imageUrl.split('/').pop() !== '') {
        const img = new Image();
        img.onload = (event) => {
          const loadedImage: any = event.currentTarget;
          resolve({ width: loadedImage.width, heigth: loadedImage.height });
        };

        img.src = imageUrl;
      } else {
        resolve({ width: 0, heigth: 0 });
      }
    });
  }
}
