import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SisMediaAssetSubStatus } from 'src/app/sismedia/domain/sismedia-asset-substatus.enum';

@Component({
  selector: 'sis-sismedia-substatus',
  templateUrl: './sismedia-substatus.component.html',
  styleUrls: ['./sismedia-substatus.component.scss'],
})
export class SisMediaSubstatusPopoverComponent {
  @Input() status: number;
  openSubstatuses = [
    { label: 'sismedia.term.convoy', icon: 'sis-status-convoy', value: SisMediaAssetSubStatus.Convoy },
    { label: 'sismedia.term.circulation', icon: 'sis-status-circulation', value: SisMediaAssetSubStatus.Circulation },
  ];
  closedSubstatuses = [
    { label: 'sismedia.term.revision', icon: 'sis-status-revision', value: SisMediaAssetSubStatus.Revision },
    { label: 'sismedia.term.storm', icon: 'sis-status-storm', value: SisMediaAssetSubStatus.Storm },
    { label: 'sismedia.term.malfunction', icon: 'sis-status-malfunction', value: SisMediaAssetSubStatus.Malfunction },
  ];

  constructor(private popoverController: PopoverController) {}

  selectSubstatus(substatus: SisMediaAssetSubStatus) {
    this.popoverController.dismiss(substatus);
  }
}
