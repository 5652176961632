import { Component, Input, NgZone, OnChanges, OnInit } from '@angular/core';
import { DivIcon, divIcon, DomUtil, latLng, LatLngExpression, LayerGroup, Marker, marker } from 'leaflet';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MapIconClickedEvent, MapIconHoveredEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { Alarm } from 'src/app/domain/alarm.model';
import { Coords } from 'src/app/maps/domain/coords.model';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { MeteoForecastAlarmService } from 'src/app/maps/livedata/meteo-forecast-alarm.service';
import { MeteoLivedata } from 'src/app/maps/livedata/meteo-livedata.model';
import { MeteoLivedataService } from 'src/app/maps/livedata/meteo-livedata.service';
import { RopewayStatusType } from 'src/app/maps/livedata/ropeway-status.model';
import { RopewayStatusService } from 'src/app/maps/livedata/ropeway-status.service';
import { MarkerType } from 'src/app/maps/map/marker-edit/domain/marker-type.enum';
import { MarkerEditService } from 'src/app/maps/map/marker-edit/marker-edit.service';
import { WindIconService } from 'src/app/maps/map/meteo-layer/wind-icon/wind-icon.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import {
  ArrowStyle,
  WindDisplayCalculatorService,
} from 'src/app/maps/wind-display-calculator/wind-display-calculator.service';

@Component({
  selector: 'sis-wind-icon',
  template: '',
  styleUrls: ['./wind-icon.component.scss'],
})
export class WindIconComponent extends Unsubscriber implements OnInit, OnChanges {
  static readonly windMarkerPivotPoint: Coords = { x: 35, y: 35 };
  static readonly colorForecastNone = '#1c232a';
  static readonly colorForecastNormal = 'var(--ion-color-secondary)';
  static readonly colorForecastWarning = 'var(--ion-color-warning-shade)';

  windMarker: Marker;
  scaledWindMarker: Marker;
  bigWindMarker: Marker;

  private readonly cssMeteostationColorNormal = 'sis-meteostation-normal';
  private readonly cssMeteostationColorWarning = 'sis-meteostation-warning';
  private readonly cssMeteostationColorAlarm = 'sis-meteostation-alarm';
  private readonly cssMeteostationColorOffline = 'sis-meteostation-offline';
  private readonly cssMeteostationInactive = 'sis-meteostation-inactive';

  private readonly markerAdded$ = new ReplaySubject<void>(1);

  private selected = false;
  private highlighted = false;
  private isBig = false;
  private iconSize = 44;
  private iconSizeBig = 66;

  @Input() layerGroup: LayerGroup;
  @Input() meteoStation: MeteoStation;
  @Input() editMode: boolean;

  constructor(
    private eventBus: EventBusService,
    private windDisplayService: WindDisplayCalculatorService,
    private ropewayStatusService: RopewayStatusService,
    private zone: NgZone,
    private windIconService: WindIconService,
    private screenSizeService: ScreenSizeService,
    private meteoForecastAlarmService: MeteoForecastAlarmService,
    private meteoLivedataService: MeteoLivedataService,
    private markerEditService: MarkerEditService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnChanges(): void {
    if (this.windMarker) {
      if (this.editMode) {
        this.resetToDefault();
        this.windMarker.dragging.enable();
      } else {
        this.windMarker.dragging.disable();
      }
    }
  }

  ngOnInit(): void {
    this.windIconService.windIconSvgAsHtml$.pipe(take(1)).subscribe((svg) => {
      if (this.meteoStation.positionPanoMap) {
        const latLngWindMarker = latLng(this.meteoStation.positionPanoMap.y, this.meteoStation.positionPanoMap.x);

        let panoMapIconZoomFactor = this.meteoStation.panoMapIconZoomFactor ?? 1
        this.iconSize = this.iconSize * panoMapIconZoomFactor;
        this.iconSizeBig = this.iconSizeBig * panoMapIconZoomFactor;

        this.windMarker = this.createWindMarkerIcon(latLngWindMarker, svg);
        this.windMarker.addEventListener('add', () => {
          this.setForecastColorDefault();

          this.markerAdded$.next();
        });

        this.layerGroup.addLayer(this.windMarker);

        this.markerEditService.register(
          this.windMarker,
          MarkerType.MeteoStation,
          this.meteoStation.guid,
          (pos) => (this.meteoStation.positionPanoMap = pos),
          () => this.meteoStation.positionPanoMap
        );
      }
    });

    combineLatest([this.selectedMapElementService.selectedMapElement$, this.screenSizeService.landscapeMode$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([event, landscapeMode]) => {
        this.selected = event.meteoStation === this.meteoStation && !landscapeMode;
        this.setHighlight();
      });

    this.markerAdded$
      .pipe(
        switchMap(() =>
          combineLatest([
            this.meteoLivedataService.meteoLiveDatas$,
            this.ropewayStatusService.ropewayStatus$.pipe(
              map((statusMap) => statusMap.get(this.meteoStation.ropewaySisId))
            ),
          ])
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([meteoLiveDatas, statusMap]) => {
        this.updateMeteoData(meteoLiveDatas, statusMap);
      });

    this.markerAdded$
      .pipe(
        switchMap(() => this.meteoForecastAlarmService.alarms$),
        takeUntil(this.onDestroy$)
      )
      .subscribe((alarms) => {
        this.updateForecastColor(alarms);
      });
  }

  private createWindMarkerIcon(latLngWindMarker: LatLngExpression, svgHtmlContent: string): Marker {
    const windIcon = divIcon({
      html: svgHtmlContent.replace(/width="[^"]*"/, `width="${this.iconSize}"`),
      iconSize: [this.iconSize, this.iconSize],
      popupAnchor: [this.iconSize, this.iconSize],
      iconAnchor: [this.iconSize / 2, this.iconSize / 2],
      className: 'windIcon',
    });
    const windMarker: Marker = marker(latLngWindMarker, { icon: windIcon });
    windMarker.on('click', () => this.zone.run(() => this.onIconClick()));
    windMarker.on('mouseover', () => this.zone.run(() => this.createHoverEvent(true)));
    windMarker.on('mouseout', () => this.zone.run(() => this.createHoverEvent(false)));
    return windMarker;
  }

  private onIconClick(): void {
    const clickEvent = new MapIconClickedEvent();
    clickEvent.ropeway = this.meteoStation.ropeway;
    clickEvent.meteoStation = this.meteoStation;
    this.eventBus.publish(clickEvent);
  }

  private createHoverEvent(hover: boolean): void {
    if (!this.meteoStation?.ropeway) {
      return;
    }

    const event = new MapIconHoveredEvent();
    event.ropeway = this.meteoStation.ropeway;
    event.hover = hover;
    this.eventBus.publish(event);
  }

  private updateMeteoData(meteoLiveDatas: MeteoLivedata[], ropewayStatusMap: Map<RopewayStatusType, boolean>): void {
    if (!meteoLiveDatas || this.editMode) {
      this.resetToDefault();
      return;
    }

    const liveData = meteoLiveDatas.find((f) => f.meteoDeviceSisId === this.meteoStation.meteoDeviceSisId);
    if (!liveData || liveData.outdated) {
      this.resetToDefault();
      this.setVisibility(ropewayStatusMap);
      return;
    }
    this.updateIconSize(liveData);

    const element = this.getWindMarkerElement();
    if (element) {
      this.updateWindText(liveData, element);
      this.updateWindDirection(liveData, element);
      this.updateWindWarning(liveData, element);
      this.updateArrows(liveData, element);
      this.setVisibility(ropewayStatusMap);
    }
  }

  private setHighlight(cssClass?: string): void {
    const element = this.getWindMarkerElement();
    if (!element) {
      return;
    }
    if (this.highlighted || this.selected) {
      const cssHighlight = '-highlight';
      const status = cssClass ? cssClass : element.getElementsByClassName('wind-warning')[0].classList.item(1);
      const windDirectionLayers: Element[] = [
        element.getElementsByClassName('wind-direction-none')[0],
        element.getElementsByClassName('wind-direction')[0],
        element.getElementsByClassName('wind-direction-cross')[0],
      ];
      if (this.highlighted) {
        windDirectionLayers.forEach((windDirectionLayer) => {
          windDirectionLayer.classList.remove(
            this.cssMeteostationColorNormal + cssHighlight,
            this.cssMeteostationColorWarning + cssHighlight,
            this.cssMeteostationColorAlarm + cssHighlight,
            this.cssMeteostationColorOffline + cssHighlight
          );
        });
        this.highlighted = !this.highlighted;
      }
      if (this.selected) {
        windDirectionLayers.forEach((windDirectionLayer) => {
          windDirectionLayer.classList.add(status + cssHighlight);
        });
        this.highlighted = !this.highlighted;
      }
    }
  }

  private updateWindText(liveData: MeteoLivedata, element: HTMLElement): void {
    const windSpeed = this.windDisplayService.getWindSpeed(liveData);
    element.getElementsByClassName('wind-speed')[0].innerHTML = windSpeed;
    element.style.zIndex = liveData.windSpeedKmh === undefined ? '0' : windSpeed;
  }

  private updateWindDirection(liveData: MeteoLivedata, element: HTMLElement): void {
    const rotationElements = element.querySelectorAll('.wind-direction-cross,.wind-direction');
    rotationElements.forEach((rotationElement: Element) => {
      rotationElement.setAttribute(
        'transform',
        this.windDisplayService.getRotationTransform(
          liveData,
          this.meteoStation.directionOffsetPanoMap,
          this.meteoStation.directionOffsetNorth,
          WindIconComponent.windMarkerPivotPoint
        )
      ); // transform svg
    });
  }

  private updateWindWarning(liveData: MeteoLivedata, element: HTMLElement): void {
    const windWarningElement = element.getElementsByClassName('wind-warning')[0];
    const config = this.meteoStation.configuration?.find((f) => f.configKey === 'windSpeedKmh');
    const windWarnColor = this.windDisplayService.getColor(liveData, config);
    this.removeOldCssClass(windWarningElement);
    this.setNewClass(windWarningElement, windWarnColor);
  }

  private removeOldCssClass(element: Element): void {
    element.classList.remove(
      this.cssMeteostationColorNormal,
      this.cssMeteostationColorWarning,
      this.cssMeteostationColorAlarm,
      this.cssMeteostationColorOffline
    );
  }

  private setNewClass(element: Element, cssClass: string): void {
    this.setHighlight(cssClass);
    element.classList.add(cssClass);
  }

  private updateArrows(liveData: MeteoLivedata, element: Element): void {
    const arrowStyle = this.windDisplayService.getArrowStyle(liveData, this.meteoStation);
    this.applyArrowStyle(arrowStyle, element);
  }

  private updateIconSize(liveData: MeteoLivedata) {
    const windSpeed = liveData.windSpeedKmh;
    const icon: DivIcon = this.windMarker.getIcon();
    const html = icon.options.html.toString();

    this.scaledWindMarker = this.windMarker.setIcon(
      divIcon({
        html: html.replace(/width="[^"]*"/, `width="${this.iconSize}"`),
        iconAnchor: [this.iconSize / 2, this.iconSize / 2],
        iconSize: [this.iconSize, this.iconSize],
        className: 'windIcon',
      })
    );

    this.bigWindMarker = this.windMarker.setIcon(
      divIcon({
        html: html.replace(/width="[^"]*"/, `width="${this.iconSize}"`),
        iconAnchor: [this.iconSize / 2, this.iconSize / 2],
        iconSize: [this.iconSize + 16, this.iconSize + 16],
        className: 'windIcon',
      })
    );

    const windThreshold =
      this.meteoStation.configuration?.find((f) => f.configKey === 'windSpeedKmh')?.warnRangeHigh ??
      WindDisplayCalculatorService.defaultWarnRangeHigh;

    if ((windSpeed < windThreshold || windSpeed === undefined) && this.isBig) {
      this.isBig = false;
      return this.scaledWindMarker;
    }
    if (windSpeed >= windThreshold && !this.isBig) {
      this.isBig = true;
      return this.bigWindMarker;
    }
  }

  private applyArrowStyle(arrowStyle: ArrowStyle, element: Element): void {
    const elemDirCross = element.getElementsByClassName('wind-direction-cross')[0];
    const elemDir = element.getElementsByClassName('wind-direction')[0];
    const elemNone = element.getElementsByClassName('wind-direction-none')[0];
    switch (arrowStyle) {
      case ArrowStyle.ONE:
        elemDirCross.setAttribute('display', 'none');
        elemDir.setAttribute('display', 'inline');
        elemNone.setAttribute('display', 'none');
        return;
      case ArrowStyle.TWO:
        elemDirCross.setAttribute('display', 'inline');
        elemDir.setAttribute('display', 'none');
        elemNone.setAttribute('display', 'none');
        return;
      default:
        elemDirCross.setAttribute('display', 'none');
        elemDir.setAttribute('display', 'none');
        elemNone.setAttribute('display', 'inline');
        return;
    }
  }

  private updateForecastColor(meteoForecastAlarms: Alarm[]): void {
    if (meteoForecastAlarms && this.meteoStation) {
      const alarmsForThisMeteoStation = meteoForecastAlarms.filter(
        (a) => a.sisId === this.meteoStation.meteoDeviceSisId
      );
      if (alarmsForThisMeteoStation) {
        if (alarmsForThisMeteoStation.find((a) => a.active)) {
          this.setForecastColor(WindIconComponent.colorForecastWarning, this.getWindMarkerElement());
        } else {
          this.setForecastColorDefault();
        }
      }
    }
  }

  private setForecastColor(color: string, element: Element): void {
    if (element) {
      const colorElements = element.querySelectorAll('.wind-direction-cross,.wind-direction,.wind-direction-none');
      if (colorElements) {
        colorElements.forEach((c) => c.setAttribute('fill', color));
      }
    }
  }

  private setForecastColorDefault(): void {
    const element = this.getWindMarkerElement();
    const color =
      this.meteoStation && this.meteoStation.hasForecast
        ? WindIconComponent.colorForecastNormal
        : WindIconComponent.colorForecastNone;

    this.setForecastColor(color, element);
  }

  private getWindMarkerElement(): HTMLElement {
    if (this.windMarker) {
      return DomUtil.get(this.windMarker.getElement());
    }

    return null;
  }

  private resetToDefault(): void {
    const element = this.getWindMarkerElement();
    if (!element) {
      return;
    }

    const windWarningElement = element.getElementsByClassName('wind-warning')[0];
    this.removeOldCssClass(windWarningElement);
    this.setNewClass(windWarningElement, this.cssMeteostationColorOffline);

    const rotationElements = element.querySelectorAll('.wind-direction-cross,.wind-direction');
    rotationElements.forEach((rotationElement: Element) => {
      rotationElement.setAttribute('transform', '');
    });
    element.style.zIndex = '0';
    element.getElementsByClassName('wind-speed')[0].innerHTML = WindDisplayCalculatorService.windSpeedUnknown;
    this.applyArrowStyle(ArrowStyle.NONE, element);
  }

  private isControllerActive(ropewayStatusMap: Map<RopewayStatusType, boolean>): boolean {
    return !(ropewayStatusMap && ropewayStatusMap.get(RopewayStatusType.CONTROLLERACTIVE) === false);
  }

  private setVisibility(ropewayStatusMap: Map<RopewayStatusType, boolean>): void {
    const element = this.getWindMarkerElement();
    const active = this.isControllerActive(ropewayStatusMap);
    if (!element?.classList) {
      return;
    }
    if (active) {
      element.classList.remove(this.cssMeteostationInactive);
    } else {
      element.classList.add(this.cssMeteostationInactive);
    }
  }
}
