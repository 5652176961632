import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlarmlistPageModule } from 'src/app/alarmlist/alarmlist.module';
import { AppResolver } from 'src/app/app.resolver';
import { BreakdownInfoPageModule } from 'src/app/breakdowninfo/breakdowninfo.module';
import { CanActivateGuard } from 'src/app/can-activate.guard';
import { SisRoute } from 'src/app/domain/sis-route.model';
import { EmailDeliveryPageModule } from 'src/app/email-delivery/email-delivery.module';
import { ErrorPage } from 'src/app/error/error.page';
import { FeatureManagementPageModule } from 'src/app/feature-management/feature-management.module';
import { GeoMapPageModule } from 'src/app/geomap/geomap.module';
import { InfotextPageModule } from 'src/app/infotext/infotext.module';
import { SisItServicesPageModule } from 'src/app/it-services/sis-it-services.module';
import { LastSlopeControlPageModule } from 'src/app/last-slope-control/last-slope-control.module';
import { LedTickerPageModule } from 'src/app/led-ticker/led-ticker.module';
import { MapsPageModule } from 'src/app/maps/maps.module';
import { MediaCenterPageModule } from 'src/app/media-center/media-center.module';
import { MeteoInfoPageModule } from 'src/app/meteo-info/meteo-info.module';
import { OperatingInfoPageModule } from 'src/app/operating-info/operating-info.page.module';
import { ParkingPageModule } from 'src/app/parking/parking.module';
import { SisagAdminPageModule } from 'src/app/sisag-admin/sisag-admin.module';
import { SisMediaPageModule } from 'src/app/sismedia/sismedia.module';
import { SisMediaSettingPageModule } from 'src/app/sismedia-setting/sismedia-setting.module';
import { SlopesOperatingTimesPageModule } from 'src/app/slopes-operating-times/slopes-operating-times.module';
import { STnetPageModule } from 'src/app/stnet/stnet.module';
import { TimetableModule } from 'src/app/timetable/timetable.module';
import { UserManagementPageModule } from 'src/app/user-management/user-management.module';
import { WebLinkCollectionModule } from 'src/app/weblink-collection/weblink-collection.module';

export const routes: Routes = [
  {
    path: '',
    redirectTo: `default/${SisRoute.MAPS}`,
    pathMatch: 'full',
  },
  {
    path: `:destination/${SisRoute.MAPS}`,
    loadChildren: () => MapsPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.ALARMLIST}`,
    loadChildren: () => AlarmlistPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.SISITSERVICES}`,
    loadChildren: () => SisItServicesPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.SISMEDIASETTING}`,
    loadChildren: () => SisMediaSettingPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.SISMEDIA}/:category`,
    loadChildren: () => SisMediaPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.SISMEDIA}`,
    redirectTo: `:destination/${SisRoute.SISMEDIA}/${SisRoute.Lift}`,
  },
  {
    path: `:destination/${SisRoute.PARKING}`,
    loadChildren: () => ParkingPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.LASTSLOPECONTROL}`,
    loadChildren: () => LastSlopeControlPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.BREAKDOWNINFO}`,
    loadChildren: () => BreakdownInfoPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.OPERATINGINFO}`,
    loadChildren: () => OperatingInfoPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.MEDIACENTER}`,
    loadChildren: () => MediaCenterPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.GLOBAL}`,
    loadChildren: () => GeoMapPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.INFOTEXT}`,
    loadChildren: () => InfotextPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.METEOINFO}`,
    loadChildren: () => MeteoInfoPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.STNET}`,
    loadChildren: () => STnetPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.USERMANAGEMENT}`,
    loadChildren: () => UserManagementPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.WEBLINKCOLLECTION}`,
    loadChildren: () => WebLinkCollectionModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.TIMETABLE}`,
    loadChildren: () => TimetableModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.LEDTICKER}`,
    loadChildren: () => LedTickerPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.EMAILDELIVERY}`,
    loadChildren: () => EmailDeliveryPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.SLOPESOPERATINGTIMES}`,
    loadChildren: () => SlopesOperatingTimesPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.FEATUREMANAGEMENT}`,
    loadChildren: () => FeatureManagementPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate(), () => inject(CanActivateGuard).isAdministrator()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `:destination/${SisRoute.SISAGADMIN}`,
    loadChildren: () => SisagAdminPageModule,
    canActivate: [() => inject(CanActivateGuard).canActivate(), () => inject(CanActivateGuard).isAdministrator()],
    resolve: {
      hasDestination: AppResolver,
    },
  },
  {
    path: `${SisRoute.ERROR}`,
    component: ErrorPage,
  },
  {
    path: `:destination/${SisRoute.ERROR}`,
    redirectTo: `${SisRoute.ERROR}`,
  },
  {
    path: `:destination`,
    redirectTo: `:destination/${SisRoute.MAPS}`,
  },
  {
    path: '**',
    redirectTo: `default/${SisRoute.MAPS}`,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
