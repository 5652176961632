import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes.guard';
import { LedTickerPage } from 'src/app/led-ticker/led-ticker.page';
import { LedTickerGroupComponent } from 'src/app/led-ticker/led-ticker-group/led-ticker-group.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: LedTickerPage,
        canDeactivate: [PendingChangesGuard],
      },
    ]),
    TranslateModule,
    MatTooltipModule,
    CoreModule,
  ],
  declarations: [LedTickerPage, LedTickerGroupComponent],
})
export class LedTickerPageModule {}
