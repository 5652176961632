import { KassandraForecastData } from 'src/app/maps/livedata/kassandra-forecast.model';

export class KassandraForecastAdapter {
  static adapt(item: any): KassandraForecastData[] {
    const entries: KassandraForecastData[] = [];

    if (item) {
      item.forEach((e: any) => {
        entries.push({ rowKey: e.RowKey, forecastValue: e.ForecastValue, forecastAt: new Date(e.ForecastAt) });
      });
    }

    return entries;
  }
}
