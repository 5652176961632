<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <g>
          <circle cx="93.75" cy="93.75" r="24.13" style="fill: #989898" />
          <path
            d="M159.38,93.75a65.25,65.25,0,0,1-14.1,40.62l-18.63-18.64a39.57,39.57,0,1,0-65.8,0L42.22,134.37A65.62,65.62,0,1,1,159.38,93.75Z"
            style="fill: #91cddb"
          />
          <path d="M134.37,145.28a65.6,65.6,0,0,1-81.24,0l18.64-18.63a39.55,39.55,0,0,0,44,0Z" style="fill: #989898" />
          <text
            [attr.x]="textPositionX"
            [attr.y]="textPositionY"
            id="value"
            style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
            [style.font-size]="fontSize"
          >
            {{ kassandraTotal }}
            <tspan id="unit" style="font-size: 24px">Pers</tspan>
          </text>
        </g>
        <rect width="187.5" height="225" style="fill: none" />
      </g>
    </g>
  </g>
</svg>
