import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { ManagedTenant } from 'src/app/user-management/domain/managed-tenant.model';
import { User } from 'src/app/user-management/domain/user.model';
import { UserManagementService } from 'src/app/user-management/user-management.service';
import { UserManagementEditModalComponent } from 'src/app/user-management/user-management-edit-modal/user-management-edit-modal.component';

@Component({
  selector: 'sis-user-management-item',
  templateUrl: './user-management-item.component.html',
  styleUrls: ['./user-management-item.component.scss'],
})
export class UserManagementItemComponent extends Unsubscriber implements OnInit {
  @Input() user: User;
  @Input() tenantFeatures: Feature[];
  @Input() hasBatchPermission: boolean;
  @Input() writePermission: boolean;
  @Input() usersLoaded: boolean;
  @Input() isAdministrator: boolean;
  @Input() managedTenants: ManagedTenant[];

  @Output() contextMenuOnUser = new EventEmitter<{ user: User; event: any }>();

  bigScreenMode: boolean;

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private userManagementService: UserManagementService,
    private modalCtrl: ModalController,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }
  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((screenMode) => (this.bigScreenMode = screenMode));
  }
  trackFeatureIds(_: any, item: Feature) {
    return item.featureId;
  }

  async batchSetFeaturePermissions(): Promise<void> {
    if (this.hasBatchPermission) {
      const confirmed = await this.confirmationDialogService.presentAlert(
        'usermanagement.phrase.resetAllUserPermissionsDefault',
        'general.term.yes'
      );

      if (confirmed) {
        await this.userManagementService.setBatchPermissions(this.tenantFeatures, [this.user.userGuid]);
      }
    }
  }

  openEditModal(user: User, managedTenants: ManagedTenant[], isAdministrator: boolean): void {
    this.modalCtrl
      .create({
        component: UserManagementEditModalComponent,
        cssClass: 'sis-user-management-edit-modal',
        componentProps: {
          user,
          managedTenants,
          isAdministrator,
        },
        backdropDismiss: false,
      })
      .then((modal) => {
        modal.present();
        modal.onDidDismiss().then((overlayEventDetail) => (this.usersLoaded = !overlayEventDetail.data));
      });
  }

  emitContextMenuOnUser(event: any): void {
    this.contextMenuOnUser.emit({ user: this.user, event });
  }
}
