import { Attachment } from 'src/app/email-delivery/domain/attachment.model';
import { EmailDelivery } from 'src/app/email-delivery/domain/email-delivery.model';
import { Recipient } from 'src/app/email-delivery/domain/recipient.model';

export class EmailDeliveryAdapter {
  static adapt(item: any): EmailDelivery | null {
    if (!item) {
      return null;
    }

    const attachments: Attachment[] = [
      { guid: null, name: 'emaildelivery.phrase.noAttachment', pdfName: null, pdfUrl: null, outdated: false },
    ];
    if (item.attachments) {
      item.attachments.forEach((attachment: any) => {
        attachments.push({
          guid: attachment.guid,
          name: `${attachment.name} (${attachment.pdfName})`,
          pdfName: attachment.pdfName,
          pdfUrl: attachment.pdfUrl,
        });
      });
    }

    const recipients: Recipient[] = [];
    if (item.recipients) {
      item.recipients.forEach((recipient: any) => {
        recipients.push({
          guid: recipient.guid,
          name: recipient.name,
          email: recipient.email,
          location: recipient.location ?? '',
        });
      });
    }

    recipients.sort((a, b) => a.name.localeCompare(b.name));

    const lastSentAttachment = attachments.find((a) => a.guid == item.lastSentAttachmentGuid) || attachments[0];

    return {
      guid: item.guid,
      text: item.text,
      subject: item.subject,
      attachments,
      recipients,
      lastSentAttachment,
    };
  }
}
