<ion-row [ngClass]="class" class="sis-title-row ion-justify-content-end">
  <ion-col>{{ 'alarming.term.currentAlarms' | translate }}</ion-col>
  <ion-col size="3">
    <sis-timeformat-button></sis-timeformat-button>
    <sis-alarm-icon
      [iconSize]="15"
      [componentClass]="'sis-sidepane-alarm-text'"
      [showIfNoAlarm]="true"
      [displayedAlarmLevels]="this.alarmIconLevels"
      class="ion-float-right"
    >
    </sis-alarm-icon>
  </ion-col>
</ion-row>

<ion-grid class="sis-alarm-grid" [style.height.px]="gridHeight" [style.min-height.px]="gridHeight">
  <sis-sidepane-alarmlist-item
    *ngFor="let item of activeAlarms; trackBy: this.itemId"
    [alarm]="item"
    [clickable]="true"
    [showAliasInTitle]="true"
  ></sis-sidepane-alarmlist-item>
</ion-grid>
