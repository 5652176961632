import { Component, OnInit } from '@angular/core';
import { map, Observable, takeUntil } from 'rxjs';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { SlopesOperatingTimesService } from 'src/app/slopes-operating-times/slopes-operating-times.service';

@Component({
  selector: 'sis-slopes-operating-times-page',
  templateUrl: './slopes-operating-times.page.html',
  styleUrls: ['./slopes-operating-times.page.scss'],
})
export class SlopesOperatingTimesPage extends Unsubscriber implements OnInit {
  private readonly requiredWriteFeature = new Feature(
    FeatureId.SISMEDIA_SLOPESOPERATINGTIMES,
    FeatureAccessLevel.WRITE
  );

  readonly slopesOperatingTimesGuids$: Observable<string[]> =
    this.slopesOperatingTimesService.slopesOperatingTimes$.pipe(
      map((slopesOperatingTimes) => slopesOperatingTimes.sort((a, b) => a.order - b.order).map((s) => s.guid))
    );

  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  readonly hasChanges$ = this.slopesOperatingTimesService.unsavedChanges$.pipe(
    map((m) => [...m.values()].some((v) => v != null))
  );

  bigScreenMode: boolean;
  writePermission: boolean;
  isSaving: boolean;
  featureId = FeatureId.SISMEDIA_SLOPESOPERATINGTIMES;

  constructor(
    private slopesOperatingTimesService: SlopesOperatingTimesService,
    private screenSizeService: ScreenSizeService,
    private logbookModalService: LogbookModalService,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features?.some((f) => f.hasMinimumRequirementFor(this.requiredWriteFeature)) ?? false;
    });

    this.slopesOperatingTimesService.updated$.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.isSaving = false));
  }

  async save(): Promise<void> {
    this.isSaving = true;
    this.isSaving = await this.slopesOperatingTimesService.saveChanges();
  }

  reset(): void {
    this.slopesOperatingTimesService.requestReset$.next();
  }
}
