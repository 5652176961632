<div style="height: 100%; overflow: hidden">
  <ion-segment
    *ngIf="this.bigScreenMode$ | async"
    [value]="this.selectedTab"
    (ionChange)="selectionChanged($event.detail.value)"
    [disabled]="this.unsavedChanges"
  >
    <ion-segment-button [value]="tab.name" *ngFor="let tab of this.tabs">
      <ion-label>{{ 'mediacenter.term.' + tab.name | translate }}{{ tab.count$ | async }}</ion-label>
    </ion-segment-button>
  </ion-segment>

  <router-outlet></router-outlet>
</div>

<ion-toolbar class="sis-tabs" *ngIf="!(this.bigScreenMode$ | async)">
  <ion-tab-bar class="sis-tab-bar" [selectedTab]="this.selectedTab">
    <ion-tab-button
      *ngFor="let tab of this.tabs; let i = index"
      [className]="i === 0 ? 'sis-tab-left' : 'sis-tab'"
      [tab]="tab.name"
      (click)="selectionChanged(tab.name)"
    >
      {{ 'mediacenter.term.' + tab.name | translate }}{{ tab.count$ | async }}
    </ion-tab-button>
  </ion-tab-bar>
</ion-toolbar>
