import { RopewayEcoModeLivedata } from 'src/app/maps/livedata/ropeway-ecomode-livedata.model';
import { SisOutdatedHelper } from 'src/app/maps/livedata/sis-outdated-helper';

export class RopewayEcoModeLivedataAdapter {
  static adapt(items: any): RopewayEcoModeLivedata[] {
    const livedata: RopewayEcoModeLivedata[] = [];

    if (items) {
      items.forEach((element) => {
        livedata.push({
          deviceSisId: element.RowKey,
          ecoModeEnabledPLC: element.EcoModeEnabledPLC,
          peoplePerMinute: element.PeoplePerMinute,
          targetSpeedMps: element.TargetSpeedMps,
          workloadPercent: Math.min(element.WorkloadPercent, 100),
          outdated: SisOutdatedHelper.isOutdated(element.DeviceTimestamp),
        });
      });
    }

    return livedata;
  }
}
