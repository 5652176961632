<!-- Big Screen -->
<ion-row
  *ngIf="this.bigScreenMode && this.operatingHoursEntry"
  class="sis-ion-row vertical-align-content sis-global-hover"
>
  <ion-col
    size="1.5"
    style="padding-left: 10px"
    class="sis-weekday-label"
    [ngClass]="this.disabled ? 'sis-disabled' : null"
    >{{ weekdayTranslationString | translate }}</ion-col
  >
  <ion-col size="2.5">
    <ion-select
      [value]="this.operatingHoursEntry.status"
      (ionChange)="this.statusSelected($event)"
      [disabled]="this.disabled"
      interface="popover"
      [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
      class="sis-select"
      data-test="operating-hours-select"
    >
      <ion-select-option
        *ngFor="let status of this.statusTranslatingMapping"
        [value]="status.value"
        data-test="operating-hours-select-option"
        >{{ status.translationString | translate }}</ion-select-option
      >
    </ion-select>
  </ion-col>
  <ion-col class="sis-time-input-col">
    <div *ngIf="this.operatingHoursEntry.status">
      <ion-row>
        <ion-col size="0.8" class="sis-time-input-label-col" [ngClass]="this.disabled ? 'sis-disabled' : null">
          {{ 'general.term.fromTime' | translate }}:
        </ion-col>
        <ion-col size="1.7" class="sis-time-input-col-selection">
          <ion-input
            [value]="operatingHoursEntry.from1"
            type="time"
            class="sis-modal-input"
            (ionBlur)="timeChanged($event, 'from1')"
            [disabled]="this.disabled"
            required
            data-test="operating-hours-time-input"
          ></ion-input>
        </ion-col>
        <ion-col
          size="0.8"
          class="sis-time-input-label-col"
          [ngClass]="this.disabled ? 'sis-disabled' : null"
          *ngIf="this.operatingHoursEntry.status != 2"
        >
          {{ 'general.term.toTime' | translate }}:
        </ion-col>
        <ion-col size="1.7" class="sis-time-input-col-selection" *ngIf="this.operatingHoursEntry.status != 2">
          <ion-input
            [value]="operatingHoursEntry.until1"
            type="time"
            class="sis-modal-input"
            (ionBlur)="timeChanged($event, 'until1')"
            [disabled]="this.disabled"
            required
            data-test="operating-hours-time-input"
          ></ion-input>
        </ion-col>
        <ion-col
          size="0.8"
          class="sis-time-input-label-col"
          [ngClass]="this.disabled ? 'sis-disabled' : null"
          *ngIf="this.operatingHoursEntry.status != 2"
        >
          {{ 'general.term.fromTime' | translate }}:
        </ion-col>
        <ion-col size="1.7" class="sis-time-input-col-selection" *ngIf="this.operatingHoursEntry.status != 2">
          <ion-input
            [value]="operatingHoursEntry.from2"
            type="time"
            class="sis-modal-input"
            (ionBlur)="timeChanged($event, 'from2')"
            [disabled]="this.disabled"
            required
            data-test="operating-hours-time-input"
          ></ion-input>
        </ion-col>
        <ion-col size="0.8" class="sis-time-input-label-col" [ngClass]="this.disabled ? 'sis-disabled' : null">
          {{ 'general.term.toTime' | translate }}:
        </ion-col>
        <ion-col size="1.7" class="sis-time-input-col-selection">
          <ion-input
            [value]="operatingHoursEntry.until2"
            type="time"
            class="sis-modal-input"
            (ionBlur)="timeChanged($event, 'until2')"
            [disabled]="this.disabled"
            required
            data-test="operating-hours-time-input"
          ></ion-input>
        </ion-col>
      </ion-row>
    </div>
  </ion-col>
</ion-row>

<!-- Small Screen -->

<ion-row *ngIf="!this.bigScreenMode && this.operatingHoursEntry" class="sis-ion-row-small">
  <ion-row class="vertical-align-content sis-ion-row-item">
    <ion-col
      size="4"
      style="padding-left: 10px"
      class="sis-weekday-label"
      [ngClass]="this.disabled ? 'sis-disabled' : null"
      >{{ weekdayTranslationString | translate }}</ion-col
    >
    <ion-col size="7">
      <ion-select
        [value]="this.operatingHoursEntry.status"
        (ionChange)="this.statusSelected($event)"
        [disabled]="this.disabled"
        interface="popover"
        [interfaceOptions]="{ cssClass: 'sis-global-popover' }"
        class="sis-select"
        data-test="operating-hours-select"
      >
        <ion-select-option
          *ngFor="let status of this.statusTranslatingMapping"
          [value]="status.value"
          data-test="operating-hours-select-option"
          >{{ status.translationString | translate }}</ion-select-option
        >
      </ion-select>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="this.operatingHoursEntry.status" class="sis-ion-row-item">
    <ion-col class="sis-time-input-col">
      <div>
        <ion-row *ngIf="this.operatingHoursEntry.status === 2" class="sis-ion-row-item">
          <ion-col size="1.5" class="sis-time-input-label-col" [ngClass]="this.disabled ? 'sis-disabled' : null">
            {{ 'general.term.fromDate' | translate }}:
          </ion-col>
          <ion-col size="3.5" class="sis-time-input-col-selection">
            <ion-input
              [value]="operatingHoursEntry.from1"
              type="time"
              class="sis-modal-input"
              (ionBlur)="timeChanged($event, 'from1')"
              [disabled]="this.disabled"
              required
              data-test="operating-hours-time-input"
            ></ion-input>
          </ion-col>
          <ion-col size="1.5" class="sis-time-input-label-col" [ngClass]="this.disabled ? 'sis-disabled' : null">
            {{ 'general.term.toDate' | translate }}:
          </ion-col>
          <ion-col size="3.5" class="sis-time-input-col-selection">
            <ion-input
              [value]="operatingHoursEntry.until2"
              type="time"
              class="sis-modal-input"
              (ionBlur)="timeChanged($event, 'until2')"
              [disabled]="this.disabled"
              required
              data-test="operating-hours-time-input"
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="this.operatingHoursEntry.status != 2">
          <ion-row class="sis-ion-row-item">
            <ion-col size="1.5" class="sis-time-input-label-col" [ngClass]="this.disabled ? 'sis-disabled' : null">
              {{ 'general.term.fromDate' | translate }}:
            </ion-col>
            <ion-col size="3.5" class="sis-time-input-col-selection">
              <ion-input
                [value]="operatingHoursEntry.from1"
                type="time"
                class="sis-modal-input"
                (ionBlur)="timeChanged($event, 'from1')"
                [disabled]="this.disabled"
                required
                data-test="operating-hours-time-input"
              ></ion-input>
            </ion-col>
            <ion-col
              size="1.5"
              class="sis-time-input-label-col"
              [ngClass]="this.disabled ? 'sis-disabled' : null"
              *ngIf="this.operatingHoursEntry.status != 2"
            >
              {{ 'general.term.toDate' | translate }}:
            </ion-col>
            <ion-col size="3.5" class="sis-time-input-col-selection" *ngIf="this.operatingHoursEntry.status != 2">
              <ion-input
                [value]="operatingHoursEntry.until1"
                type="time"
                class="sis-modal-input"
                (ionBlur)="timeChanged($event, 'until1')"
                [disabled]="this.disabled"
                required
                data-test="operating-hours-time-input"
              ></ion-input>
            </ion-col>
          </ion-row>
          <ion-row class="sis-ion-row-item">
            <ion-col
              size="1.5"
              class="sis-time-input-label-col"
              [ngClass]="this.disabled ? 'sis-disabled' : null"
              *ngIf="this.operatingHoursEntry.status != 2"
            >
              {{ 'general.term.fromDate' | translate }}:
            </ion-col>
            <ion-col size="3.5" class="sis-time-input-col-selection" *ngIf="this.operatingHoursEntry.status != 2">
              <ion-input
                [value]="operatingHoursEntry.from2"
                type="time"
                class="sis-modal-input"
                (ionBlur)="timeChanged($event, 'from2')"
                [disabled]="this.disabled"
                required
                data-test="operating-hours-time-input"
              ></ion-input>
            </ion-col>
            <ion-col size="1.5" class="sis-time-input-label-col" [ngClass]="this.disabled ? 'sis-disabled' : null">
              {{ 'general.term.toDate' | translate }}:
            </ion-col>
            <ion-col size="3.5" class="sis-time-input-col-selection">
              <ion-input
                [value]="operatingHoursEntry.until2"
                type="time"
                class="sis-modal-input"
                (ionBlur)="timeChanged($event, 'until2')"
                [disabled]="this.disabled"
                required
                data-test="operating-hours-time-input"
              ></ion-input>
            </ion-col>
          </ion-row>
        </ion-row>
      </div>
    </ion-col>
  </ion-row>
</ion-row>
