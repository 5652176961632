<div class="sis-destination-icon-div">
  <ion-icon
    name="sis-icon-menu-sismedia"
    class="sis-destination-icon"
    [class.unvisible]="sisMediaTooltip.length <= 0"
    [matTooltip]="this.sisMediaTooltip"
    matTooltipClass="sis-global-tooltip"
  >
  </ion-icon>
  <ion-icon
    name="sis-icon-menu-map"
    class="sis-destination-icon"
    [class.unvisible]="cockpitTooltip.length <= 0"
    [matTooltip]="this.cockpitTooltip"
    matTooltipClass="sis-global-tooltip"
  ></ion-icon>
</div>
