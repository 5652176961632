<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'parking.term.exportSettings' | translate }} - {{ this.parking.name }}</ion-title>
    <ion-button slot="end" fill="clear" (click)="this.cancel()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <!-- Big Screen -->
  <ion-grid class="sis-modal-grid" *ngIf="this.bigScreenMode">
    <ion-row class="sis-global-row-40 sis-exports-edit-modal-title-row">
      <ion-col>
        {{ 'parking.phrase.exportTargets' | translate }}
      </ion-col>
    </ion-row>
    <ion-row
      *ngFor="let item of this.selectableExports"
      class="sis-global-row vertical-align-content sis-exports-edit-modal-option-row"
      [ngClass]="item.enabled ? 'sis-exports-edit-modal-option-row-selected' : null"
      (click)="this.toggleExport(item)"
      data-test="parking-export-mapping-item-row"
    >
      <ion-col class="sis-exports-edit-modal-icon-col">
        <ion-checkbox [checked]="item.enabled" class="sis-exports-edit-modal-icon" color="secondary"> </ion-checkbox>
      </ion-col>
      <ion-col>
        {{ item.export.name }}
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      [ngClass]="{ 'sis-global-button-padding-right': this.bigScreenMode }"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [disabled]="!this.hasUnsavedChanges || this.isUpdating"
      [showSpinner]="this.isUpdating"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      data-test="parking-export-mapping-save-btn"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [ngClass]="{ 'sis-general-button': this.bigScreenMode }"
      [btnType]="'Secondary'"
      (sisClick)="this.cancel()"
      [showSpinner]="this.isUpdating"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      data-test="parking-export-mapping-reset-btn"
    ></sis-general-button>
  </div>
</ion-footer>
