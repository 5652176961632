import { Component, Input } from '@angular/core';
import { LayerGroup } from 'leaflet';
import { CustomMarker } from 'src/app/maps/domain/custom-marker.model';

@Component({
  selector: 'sis-custom-marker-layer',
  templateUrl: './custom-marker-layer.component.html',
})
export class CustomMarkerLayerComponent {
  @Input() layerGroup: LayerGroup;
  @Input() customMarkers: CustomMarker[];
  @Input() editMode: boolean;
}
