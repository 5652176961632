import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LatLng, LeafletKeyboardEvent, Marker } from 'leaflet';
import { firstValueFrom, map, Observable, withLatestFrom } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MarkerPositionUpdatedEvent } from 'src/app/core/eventbus/events';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { Coords } from 'src/app/maps/domain/coords.model';
import { MarkerPositionUpdatePost as MarkerPositionUpdatePost } from 'src/app/maps/map/marker-edit/domain/marker-position-update-post.model';
import { MarkerType as MarkerType } from 'src/app/maps/map/marker-edit/domain/marker-type.enum';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MarkerEditService {
  constructor(
    private http: HttpClient,
    private userSettingsService: UserSettingsService,
    private eventBus: EventBusService,
    private userMessageService: UserMessageService
  ) {
    this.eventBus
      .observe(MarkerPositionUpdatedEvent)
      .pipe(withLatestFrom(this.userSettingsService.userSettings$))
      .subscribe(([markerPositionUpdatedEvent, userSettings]) => {
        if (markerPositionUpdatedEvent.changedBy !== userSettings.userGuid) {
          return;
        }
        if (markerPositionUpdatedEvent.successful) {
          this.userMessageService.presentToast(
            new UserMessage({
              message: 'map.phrase.markerPosition.changed',
              icon: UserMessageIcon.success,
              durationMs: 3000,
              position: 'top',
              color: UserMessageColor.green,
              showCloseButton: false,
            })
          );
        } else {
          this.userMessageService.presentToast(
            new UserMessage({
              message: 'general.phrase.saveFailed',
              icon: UserMessageIcon.failed,
              durationMs: 3000,
              position: 'top',
              color: UserMessageColor.red,
              showCloseButton: false,
            })
          );
        }
      });
  }

  register(
    marker: Marker,
    markerType: MarkerType,
    iconGuid: string,
    positionUpdateCallback: (position: Coords) => void,
    getOldPosition: () => Coords
  ) {
    marker.addEventListener('drag', () => {
      const markerPos = marker.getLatLng();
      marker.setLatLng(new LatLng(markerPos.lat, markerPos.lng));
    });
    marker.addEventListener('keydown', (e: LeafletKeyboardEvent) => {
      if (e.originalEvent.key === 'Escape') {
        const oldPositon = getOldPosition();
        marker.setLatLng(new LatLng(oldPositon.y, oldPositon.x));
        // Reset dragging
        marker.dragging?.disable();
        marker.dragging?.enable();
      }
    });
    marker.addEventListener('dragend', () => {
      const markerPos = marker.getLatLng();
      marker.setLatLng(new LatLng(markerPos.lat, markerPos.lng));
      this.saveMarkerPosition(marker, markerType, iconGuid, positionUpdateCallback, getOldPosition);
    });
  }

  private async saveMarkerPosition(
    marker: Marker,
    markerType: MarkerType,
    markerGuid: string,
    positionUpdateCallback: (position: Coords) => void,
    getOldPosition: () => Coords
  ) {
    const isAdministrator = await firstValueFrom(
      this.userSettingsService.userSettings$.pipe(map((userSettings) => userSettings.isAdministrator))
    );

    if (!isAdministrator) {
      const oldPositon = getOldPosition();
      marker.setLatLng(new LatLng(oldPositon.y, oldPositon.x));
      return;
    }

    const markerPos = marker.getLatLng();

    const position: Coords = {
      x: markerPos.lng,
      y: markerPos.lat
    };
    const postData: MarkerPositionUpdatePost = {
      guid: markerGuid,
      position: `{"x":${position.x.toFixed(2)},"y":${position.y.toFixed(2)}}`,
      markerType: markerType,
    };

    try {
      await firstValueFrom(this.updateMarkerPosition(postData));
      positionUpdateCallback({ x: markerPos.lng, y: markerPos.lat });
    } catch {
      const oldPositon = getOldPosition();
      marker.setLatLng(new LatLng(oldPositon.y, oldPositon.x));

      this.userMessageService.presentToast(
        new UserMessage({
          message: 'general.phrase.saveFailed',
          icon: UserMessageIcon.failed,
          durationMs: 3000,
          position: 'top',
          color: UserMessageColor.red,
          showCloseButton: false,
        })
      );
    }
  }

  private updateMarkerPosition(postData: MarkerPositionUpdatePost): Observable<void> {
    return this.http.post(`${environment.baseUrlApi}/marker/edit`, postData).pipe(map(() => { }));
  }
}
