<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'sambesi.term.filter' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()"><ion-icon class="sis-close-button" name="close"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item>
      <ion-label>{{ 'general.term.cockpit' | translate }}</ion-label>
      <ion-checkbox slot="start" [(ngModel)]="this.isCockpitChecked" color="secondary"></ion-checkbox>
    </ion-item>
    <ion-item>
      <ion-label>{{ 'general.term.sismedia' | translate }}</ion-label>
      <ion-checkbox slot="start" [(ngModel)]="this.isSisMediaChecked" color="secondary"></ion-checkbox>
    </ion-item>
    <ion-item>
      <ion-label>{{ 'general.term.general' | translate }}</ion-label>
      <ion-checkbox slot="start" [(ngModel)]="this.isGeneralChecked" color="secondary"></ion-checkbox>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <div style="padding: 10px">
    <sis-general-button
      [text]="'general.term.apply'"
      [width]="'100%'"
      [btnType]="'Secondary'"
      (sisClick)="this.apply()"
    ></sis-general-button>
  </div>
</ion-footer>
