import { Component, Input, OnInit } from '@angular/core';
import { merge, of, Subject } from 'rxjs';
import { delay, switchMap, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { RemoteAccess } from 'src/app/maps/domain/remote-access.model';
import { RemoteAccessStatusService } from 'src/app/maps/livedata/remote-access-status.service';
import { RemoteAccessService } from 'src/app/remote-access/remote-access.service';

@Component({
  selector: 'sis-remote-access-detailview-item',
  templateUrl: './remote-access-detailview-item.component.html',
  styleUrls: ['./remote-access-detailview-item.component.scss'],
})
export class RemoteAccessDetailViewItemComponent extends Unsubscriber implements OnInit {
  private readonly blockedDuration: number = 5000;
  private blockStatus$ = new Subject<boolean>();

  ready: boolean;
  blocked = false;
  statusIconName: string;
  statusClass: string;
  status: string;
  typeIconName: string;

  @Input() remoteAccess: RemoteAccess;

  constructor(
    private remoteAccessService: RemoteAccessService,
    private readonly remoteAccessStatusService: RemoteAccessStatusService
  ) {
    super();
  }

  ngOnInit(): void {
    this.blockStatus$
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap((blocked) => {
          if (blocked) {
            return merge(of(true), of(false).pipe(delay(this.blockedDuration)));
          }
          return of(false);
        })
      )
      .subscribe((blocked) => (this.blocked = blocked));

    this.remoteAccessStatusService.remoteAccessStatuses$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((remoteAccessStatuses) => {
        if (remoteAccessStatuses?.find((d) => d.guid === this.remoteAccess.guid)?.online) {
          this.statusIconName = 'checkmark-circle';
          this.statusClass = 'sis-online';
          this.status = 'online';
        } else {
          this.statusIconName = 'close-circle';
          this.statusClass = 'sis-offline';
          this.status = 'offline';
        }

        this.ready = true;
      });

    switch (this.remoteAccess.type) {
      case 'PC':
        this.typeIconName = 'sis-computer';
        break;
      case 'Display':
        this.typeIconName = 'sis-screen';
        break;
      default:
        this.typeIconName = 'help-outline';
    }
  }

  async openRemoteAccess(remoteAccess: RemoteAccess): Promise<void> {
    if (!this.blocked) {
      this.blockStatus$.next(true);
      const status = await this.remoteAccessService.openSession(
        remoteAccess,
        new Feature(FeatureId.COCKPIT_REMOTEACCESS, FeatureAccessLevel.WRITE)
      );
      this.blockStatus$.next(!status);
    }
  }
}
