import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { Alarm, AlarmLevel } from 'src/app/domain/alarm.model';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { MeteoForecastAlarmService } from 'src/app/maps/livedata/meteo-forecast-alarm.service';
import { RopewayAlarmService } from 'src/app/maps/livedata/ropeway-alarm.service';
import { MeteoStationService } from 'src/app/maps/meteostation-service/meteostation.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-alarm-widget',
  templateUrl: './alarm-widget.component.html',
  styleUrls: ['./alarm-widget.component.scss'],
})
export class AlarmWidgetComponent extends WidgetBase implements OnInit {
  static readonly defaultValue = 0;
  static readonly normalCssClass = 'sis-normal';
  static readonly warningCssClass = 'sis-warning';
  static readonly alarmCssClass = 'sis-alarm';

  count: number = AlarmWidgetComponent.defaultValue;
  cssClass: string = AlarmWidgetComponent.normalCssClass;

  constructor(
    private meteoStationService: MeteoStationService,
    private meteoForecastAlarmService: MeteoForecastAlarmService,
    private ropewayAlarmService: RopewayAlarmService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.selectedMapElementService.selectedRopeway$,
      this.meteoStationService.activeMeteoStation$.pipe(startWith(<MeteoStation>null)),
      this.ropewayAlarmService.alarms$.pipe(startWith(<Alarm[]>[])),
      this.meteoForecastAlarmService.alarms$.pipe(startWith(<Alarm[]>[])),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([ropeway, meteoStation, ropewayAlarms, forecastAlarms]) =>
        this.update(ropeway, meteoStation, ropewayAlarms, forecastAlarms)
      );
  }

  private update(ropeway: Ropeway, meteoStation: MeteoStation, ropewayAlarms: Alarm[], forecastAlarms: Alarm[]): void {
    const sisId = ropeway?.sisId ?? meteoStation?.meteoDeviceSisId ?? null;
    if (!sisId) {
      return;
    }

    const alarms: Alarm[] = [
      ...(ropewayAlarms?.filter((a) => a.active && a.sisId === sisId) ?? []),
      ...(forecastAlarms?.filter((a) => a.active && Alarm.includesSisId(a, sisId)) ?? []),
    ];

    if (!alarms?.length) {
      this.resetToDefault();
      return;
    }

    const highestLevelAlarmsPerCode = alarms.reduce((p: Map<string, Alarm>, c: Alarm) => {
      const alarm: Alarm = p.get(c.code);
      if (!alarm || (alarm.level === AlarmLevel.WARNING && c.level === AlarmLevel.ALARM)) {
        p.set(c.code, c);
      }
      return p;
    }, new Map<string, Alarm>());

    const uniqueAlarms = Array.from(highestLevelAlarmsPerCode.values());

    this.count = uniqueAlarms.length;
    this.cssClass = this.getCssClass(uniqueAlarms);
  }

  private resetToDefault(): void {
    this.count = AlarmWidgetComponent.defaultValue;
    this.cssClass = AlarmWidgetComponent.normalCssClass;
  }

  private getCssClass(alarms: Alarm[]): string {
    if (alarms.find((d) => d.level === AlarmLevel.ALARM)) {
      return AlarmWidgetComponent.alarmCssClass;
    }
    if (alarms.find((d) => d.level === AlarmLevel.WARNING)) {
      return AlarmWidgetComponent.warningCssClass;
    }

    return AlarmWidgetComponent.normalCssClass;
  }
}
