import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { MeteoInfoPage } from 'src/app/meteo-info/meteo-info.page';
import { MeteoInfoFieldNames } from 'src/app/meteo-info/meteo-info-field-names/meteo-info-field-names.component';
import { MeteoInfoItemComponent } from 'src/app/meteo-info/meteo-info-item/meteo-info-item.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatTooltipModule,
    RouterModule.forChild([
      {
        path: '',
        component: MeteoInfoPage,
      },
    ]),
    TranslateModule,
  ],
  declarations: [MeteoInfoPage, MeteoInfoFieldNames, MeteoInfoItemComponent],
})
export class MeteoInfoPageModule {}
