<div class="sis-maintenance" (click)="this.onclick()">
  <a class="sis-maintenance-close"></a>
  <div *ngIf="this.bigScreenMode$ | async">
    <div>{{ 'general.phrase.maintenance' | translate }}</div>
  </div>
  <div *ngIf="!(this.bigScreenMode$ | async)">
    <div>{{ 'general.phrase.maintenance1' | translate }}</div>
    <div>{{ 'general.phrase.maintenance2' | translate }}</div>
  </div>
</div>
