import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { SisMediaStatusUpdatedEvent, STnetStatusUpdatedEvent, STnetUpdatedEvent } from 'src/app/core/eventbus/events';
import { STnetExportResponse } from 'src/app/core/stnet/stnet-export-response.model';
import { STnetStatus } from 'src/app/core/stnet/stnet-status.model';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { STnetAdapter } from 'src/app/stnet/domain/stnet.adapter';
import { STnet } from 'src/app/stnet/domain/stnet.model';
import { STnetCalculatedDataAdapter } from 'src/app/stnet/domain/stnet-calculated-data.adapter';
import { STnetCalculatedData } from 'src/app/stnet/domain/stnet-calculated-data.model';
import { STnetItem } from 'src/app/stnet/domain/stnet-item.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class STnetService {
  private readonly baseRequestUrl: string = '/stnet/get';
  private readonly basePostUrl: string = '/stnet/post';
  private readonly baseStnetExportUrl: string = '/stnet/export';

  private readonly stnetStatusArrayResponse$ = new Subject<STnetStatus[]>();
  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_STNET, FeatureAccessLevel.READ);

  private readonly stnet$: Observable<STnet> = this.destinationService.selectedTenantFeatures$.pipe(
    filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
    distinctUntilChanged(),
    switchMap(() => this.http.get(`${environment.baseUrlApi}${this.baseRequestUrl}`)),
    map((data) => STnetAdapter.adapt(data)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly stnetStatusArrayUpdate$: Observable<STnetStatus[]> = merge(
    this.eventBus.observe(STnetStatusUpdatedEvent).pipe(
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(
        ([updatedEvent, tenant]) =>
          updatedEvent?.status?.featureId === FeatureId.SISMEDIA_STNET && updatedEvent?.tenantGuid === tenant?.guid
      ),
      map(([updatedEvent]) => [updatedEvent.status])
    ),
    this.stnetStatusArrayResponse$
  ).pipe(
    withLatestFrom(this.stnet$),
    map(([updatedStatusArray, stnet]) => {
      updatedStatusArray.forEach((updatedStatus) => {
        const currentStatus = stnet.stnetStatusArray.find((status) => status.stationId === updatedStatus.stationId);
        if (currentStatus) {
          Object.assign(currentStatus, updatedStatus);
        } else {
          stnet.stnetStatusArray.push(updatedStatus);
        }
      });

      return stnet.stnetStatusArray;
    })
  );

  private readonly stnetCalculatedDataUpdate$: Observable<STnetCalculatedData> = merge(
    this.eventBus.observe(SisMediaStatusUpdatedEvent),
    this.eventBus.observe(STnetUpdatedEvent)
  ).pipe(
    withLatestFrom(this.stnet$),
    switchMap(([, stnet]) =>
      this.http.get(`${environment.baseUrlApi}${this.baseRequestUrl}/calculated`).pipe(
        map((data: any) => {
          const updatedData = STnetCalculatedDataAdapter.adapt(data);

          if (stnet.calculatedData.stationId === updatedData.stationId) {
            Object.assign(stnet.calculatedData, updatedData);
          }

          return stnet.calculatedData;
        })
      )
    )
  );

  readonly stnetItems$: Observable<STnetItem> = merge(
    this.stnet$.pipe(map((stnet) => stnet.stnetItem)),
    this.eventBus
      .observe(STnetUpdatedEvent)
      .pipe(
        withLatestFrom(this.destinationService.selectedTenant$),
        filter(([updatedEvent, tenant]) => updatedEvent?.stnetItem?.tenantGuid === tenant?.guid.toString()),
        withLatestFrom(this.userSettingsService.userSettings$),
        tap(([[updatedEvent], userSettings]) => {
          if (updatedEvent?.stnetItem?.changedBy === userSettings.userGuid.toString()) {
            const translateKey = 'general.phrase.saved';
            const userMessage = new UserMessage({
              message: translateKey,
              icon: UserMessageIcon.success,
              durationMs: 2000,
              position: 'top',
              color: UserMessageColor.green,
            });
            this.userMessageService.presentToast(userMessage);
          }
        }),
        map(([[updatedEvent]]) => updatedEvent.stnetItem)
      )
      .pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      )
  );

  readonly stnetCalculatedData$: Observable<STnetCalculatedData> = merge(
    this.stnet$.pipe(map((stnet) => stnet.calculatedData)),
    this.stnetCalculatedDataUpdate$
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly stnetStatusArray$: Observable<STnetStatus[]> = merge(
    this.stnet$.pipe(map((stnet) => stnet.stnetStatusArray)),
    this.stnetStatusArrayUpdate$
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private eventBus: EventBusService,
    private http: HttpClient,
    private userMessageService: UserMessageService,
    private destinationService: DestinationService,
    private userSettingsService: UserSettingsService
  ) {}

  async saveSTnetItems(stnetItem: STnetItem): Promise<boolean> {
    const postData = {
      stationId: stnetItem.stationId,
      slopeConditionId: stnetItem.slopeConditionId,
      valleyRunMainConditionId: stnetItem.valleyRunMainConditionId,
      valleyRunPartialConditionId: stnetItem.valleyRunPartialConditionId,
      snowConditionMainId: stnetItem.snowConditionMainId,
      snowConditionPartialId: stnetItem.snowConditionPartialId,
      snowHeightMeasurementMethodId: stnetItem.snowHeightMeasurementMethodId,
      classicTrackConditionId: stnetItem.classicTrackConditionId,
      skatingTrackConditionId: stnetItem.skatingTrackConditionId,
      tobogganRunConditionId: stnetItem.tobogganRunConditionId,
      tobogganRunSkillTypeId: stnetItem.tobogganRunSkillTypeId,
      snowparkConditionId: stnetItem.snowparkConditionId,
      crossCountryTracksLowestAltitudeToday: stnetItem.crossCountryTracksLowestAltitudeToday,
      crossCountryTracksHighestAltitudeToday: stnetItem.crossCountryTracksHighestAltitudeToday,
      valleyRunLowestAltitudeToday: stnetItem.valleyRunLowestAltitudeToday,
      slopesSnowedLengthMeterToday: stnetItem.slopesSnowedLengthMeterToday,
      winterHikingTrailConditionId: stnetItem.winterHikingTrailConditionId,
      winterHikingTrailsLowestAltitudeTotal: stnetItem.winterHikingTrailsLowestAltitudeTotal,
      tobogganRunsWithFloodlightLengthMeterToday: stnetItem.tobogganRunsWithFloodlightLengthMeterToday,
      crossCountryTracksWithFloodlightLengthMeterToday: stnetItem.crossCountryTracksWithFloodlightLengthMeterToday,
      crossCountryFloodlightUntil: stnetItem.crossCountryFloodlightUntil,
      slopesFloodlightUntil: stnetItem.slopesFloodlightUntil,
      snowparkFloodlightUntil: stnetItem.snowparkFloodlightUntil,
      slopesFloodlightEnabled: stnetItem.slopesFloodlightEnabled,
      slopesWithFloodlightLengthMeterToday: stnetItem.slopesWithFloodlightLengthMeterToday,
      tobogganFloodlightEnabled: stnetItem.tobogganFloodlightEnabled,
      crossCountryFloodlightEnabled: stnetItem.crossCountryFloodlightEnabled,
      snowparkFloodlightEnabled: stnetItem.snowparkFloodlightEnabled,
    };

    return firstValueFrom(
      this.http.post(`${environment.baseUrlApi}${this.basePostUrl}`, postData).pipe(
        map(() => true),
        catchError(() => {
          const translateKey = 'general.phrase.saveFailed';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.failed,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);
          return of(false);
        })
      )
    );
  }

  async exportSTnetItems(): Promise<void> {
    const exportResponses = await firstValueFrom(
      this.http.get<STnetExportResponse[]>(`${environment.baseUrlApi}${this.baseStnetExportUrl}`).pipe(
        catchError(() => {
          const translateKey = 'stnet.phrase.export.unsuccessful';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.failed,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);
          return of<STnetExportResponse[]>([]);
        })
      )
    );

    const successResponses = exportResponses?.filter((response) => response.success);

    if (successResponses?.length && exportResponses.every((response) => response.success)) {
      const translateKey = 'stnet.phrase.export.successful';
      const userMessage = new UserMessage({
        message: translateKey,
        icon: UserMessageIcon.success,
        durationMs: 2000,
        position: 'top',
        color: UserMessageColor.green,
      });
      await this.userMessageService.presentToast(userMessage);
    } else {
      const translateKey = 'stnet.phrase.export.unsuccessful';
      const userMessage = new UserMessage({
        message: translateKey,
        icon: UserMessageIcon.failed,
        durationMs: 2000,
        position: 'top',
        color: UserMessageColor.red,
      });
      await this.userMessageService.presentToast(userMessage);
    }

    if (successResponses) {
      this.stnetStatusArrayResponse$.next(successResponses.map((response) => response.requestStatus));
    }
  }
}
