import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Alarm, AlarmLevel } from 'src/app/domain/alarm.model';
import { DestinationAlarmService } from 'src/app/maps/livedata/destination-alarm.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-destination-alarm-widget',
  templateUrl: './destination-alarm-widget.component.html',
  styleUrls: ['./destination-alarm-widget.component.scss'],
})
export class DestinationAlarmWidgetComponent extends WidgetBase implements OnInit {
  static readonly defaultValue = 0;
  static readonly normalClass = 'sis-normal';
  static readonly warningClass = 'sis-warning';
  static readonly alarmClass = 'sis-alarm';

  count: number = DestinationAlarmWidgetComponent.defaultValue;
  class: string = DestinationAlarmWidgetComponent.normalClass;

  constructor(private destinationAlarmService: DestinationAlarmService) {
    super();
  }

  ngOnInit(): void {
    this.destinationAlarmService.alarms$.pipe(takeUntil(this.onDestroy$)).subscribe((alarms) => {
      const activeAlarms = alarms?.filter((a) => a.active) ?? [];

      this.class = this.getClass(activeAlarms);
      this.count = activeAlarms.length;
    });
  }

  private getClass(alarms: Alarm[]): string {
    if (alarms.find((d) => d.level === AlarmLevel.ALARM)) {
      return DestinationAlarmWidgetComponent.alarmClass;
    }
    if (alarms.find((d) => d.level === AlarmLevel.WARNING)) {
      return DestinationAlarmWidgetComponent.warningClass;
    }

    return DestinationAlarmWidgetComponent.normalClass;
  }
}
