import moment from 'moment';
import { SisConverter } from 'src/app/core/utils/sis-converter';
import { RemoteAccess } from 'src/app/maps/domain/remote-access.model';
import { Screen } from 'src/app/media-center/screens/domain/screen.model';

export class ScreenAdapter {
  static adapt(items: any): Screen[] {
    const result: Screen[] =
      (items as any[])?.map<Screen>((e: any) => ({
        guid: e.guid,
        location: e.location,
        label: e.label,
        startTime: e.startTime ? moment(e.startTime, 'HH:mm:ss').format('HH:mm') : null,
        endTime: e.endTime ? moment(e.endTime, 'HH:mm:ss').format('HH:mm') : null,
        screenHeight: SisConverter.toNumberOrUndefined(e.screenHeight),
        screenWidth: SisConverter.toNumberOrUndefined(e.screenWidth),
        slideshowWidth: SisConverter.toNumberOrUndefined(e.slideshowWidth),
        slideshowHeight: SisConverter.toNumberOrUndefined(e.slideshowHeight),
        infoBannerAllowed: e.infoBannerAllowed,
        infoBannerGuid: e.infoBannerGuid,
        updating: false,
        playlistGuid: e.playlistGuid,
        ip: e.ip,
        remoteAccess: this.adaptRemoteAccess(e.remoteAccess),
        continuousOperation: e.startTime === '00:00:00' && e.endTime === '00:00:00',
        order: e.order,
        groupGuid: e.groupGuid,
        previewUrl: e.previewUrl,
        type: e.type,
      })) || [];

    return result;
  }

  private static adaptRemoteAccess(e: any): RemoteAccess {
    if (!e) {
      return null;
    }

    return {
      guid: e.guid,
      name: e.name,
      type: e.type,
      accessType: e.accessType,
      agentId: e.agentId,
      parentAlias: e.parentAlias,
      windowDimensions: e.windowDimensions,
    };
  }
}
