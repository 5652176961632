<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <path
          d="M109.31,25.09A22,22,0,0,0,78.19,56.2L93.75,71.76,109.31,56.2A22,22,0,0,0,109.31,25.09Zm-8.49,22.63a10,10,0,1,1,0-14.14A10,10,0,0,1,100.82,47.72Z"
          style="fill: #91cddb"
        />
        <path
          d="M46.45,87.7l-3.1.69a4.32,4.32,0,1,1-.86-3.9l1.15-.26ZM148.53,58.79A4.3,4.3,0,0,0,144.15,62l-3.11.68,2.82,3.48,1.16-.25a4.31,4.31,0,1,0,3.51-7.09ZM57.3,81.2,45.59,83.8l2.82,3.47,11.71-2.59Zm2-.43,2.82,3.47,11.71-2.59L71,78.18ZM84.42,75.2,72.7,77.79l2.82,3.48,11.71-2.6Zm2-.43,2.82,3.47,11.71-2.6-2.82-3.47Zm25.38-5.63L100,71.74l2.81,3.47,11.72-2.59Zm2-.43,2.81,3.47,11.72-2.59-2.82-3.47Zm25.38-5.62-11.72,2.59,2.82,3.48,11.72-2.6Z"
          style="fill: #979797"
        />
        <rect width="187.5" height="225" style="fill: none" />
        <g>
          <g>
            <path
              d="M129.78,97a5.65,5.65,0,0,0-5.64-5.63H63.36A5.65,5.65,0,0,0,57.72,97l-2,23.68,2,23.68A5.65,5.65,0,0,0,63.36,150h60.78a5.65,5.65,0,0,0,5.64-5.64l2-23.68ZM61.7,120.68l2-23.68h60.12l2,23.68Z"
              style="fill: none; stroke: #989898; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3.84px"
            />
            <line
              x1="93.75"
              y1="91.37"
              x2="93.75"
              y2="76"
              style="fill: none; stroke: #989898; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3.84px"
            />
          </g>
          <g>
            <line
              x1="81.83"
              y1="97"
              x2="81.83"
              y2="144.35"
              style="fill: none; stroke: #989898; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3.84px"
            />
            <line
              x1="105.68"
              y1="97"
              x2="105.68"
              y2="144.35"
              style="fill: none; stroke: #989898; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3.84px"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Widgettexte">
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
      >
        {{ cabinPositionMeter }}
        <tspan id="unit" style="font-size: 24px">m</tspan>
      </text>
    </g>
  </g>
</svg>
