<ion-header class="sis-logbook-header">
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="closeModal()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title>{{ 'logbook.term.logbook' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="this.bigScreenMode ? 'ion-padding-horizontal' : 'ion-no-padding'" data-test="logbook-content">
  <ion-grid class="ion-no-margin ion-no-padding sis-no-scroll">
    <ion-row>
      <ion-searchbar
        data-test="sis-logbook-searchbar"
        placeholder="Filter..."
        type="text"
        [formControl]="this.filterControl"
        (ionChange)="this.filtering = true"
        [ngClass]="this.bigScreenMode ? 'sis-logbook-searchbar' : 'sis-logbook-mobile-searchbar'"
      >
      </ion-searchbar>
    </ion-row>
    <ion-row *ngIf="bigScreenMode" class="sis-header-row sis-bottom-border">
      <ion-col size="1.5">{{ 'general.term.datetime' | translate }}</ion-col>
      <ion-col size="1.5">{{ 'general.term.feature' | translate }}</ion-col>
      <ion-col size="5">{{ 'logbook.term.action' | translate }}</ion-col>
      <ion-col size="2">{{ 'logbook.term.value' | translate }}</ion-col>
      <ion-col size="2">{{ 'general.term.user' | translate }}</ion-col>
    </ion-row>
  </ion-grid>

  <!-- Big Screen -->
  <ion-grid *ngIf="bigScreenMode && this.logbookEntriesFiltered" class="ion-no-margin ion-no-padding sis-logbook-grid">
    <cdk-virtual-scroll-viewport itemSize="40" minBufferPx="1000" maxBufferPx="2000">
      <ion-row
        *cdkVirtualFor="let item of this.logbookEntriesFiltered; trackBy: this.itemId"
        class="sis-logbook-row sis-bottom-border sis-global-hover"
        data-test="logbook-row"
      >
        <ion-col size="1.5">
          {{ item.formattedTimestamp }}
        </ion-col>
        <ion-col size="1.5">
          {{ item.translatedFeature }}
        </ion-col>
        <ion-col size="5">
          <div [innerHTML]="item.translatedAction"></div>
        </ion-col>
        <ion-col size="2">
          <div [innerHTML]="item.translatedValue"></div>
        </ion-col>
        <ion-col size="2">
          {{ item.email }}
        </ion-col>
      </ion-row>
    </cdk-virtual-scroll-viewport>
  </ion-grid>

  <!-- Small Screen -->
  <ion-grid
    *ngIf="!bigScreenMode && this.logbookEntriesFiltered"
    class="ion-no-margin ion-no-padding sis-logbook-mobile-grid"
  >
    <cdk-virtual-scroll-viewport itemSize="106" minBufferPx="1000" maxBufferPx="2000">
      <div
        *cdkVirtualFor="let item of this.logbookEntriesFiltered; trackBy: this.itemId"
        class="sis-logbook-mobile-item"
        data-test="logbook-row"
      >
        <ion-grid class="ion-no-padding ion-no-margin">
          <ion-row>
            <ion-col size="4" class="sis-logbook-mobile-label">{{ 'general.term.datetime' | translate }}:</ion-col>
            <ion-col class="sis-global-text-overflow-ellipsis">{{ item.formattedTimestamp }}</ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="4" class="sis-logbook-mobile-label">{{ 'general.term.feature' | translate }}:</ion-col>
            <ion-col class="sis-global-text-overflow-ellipsis">{{ item.translatedFeature }}</ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="4" class="sis-logbook-mobile-label">{{ 'logbook.term.action' | translate }}:</ion-col>
            <ion-col class="sis-global-text-overflow-ellipsis" [innerHTML]="item.translatedAction"></ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="4" class="sis-logbook-mobile-label">{{ 'logbook.term.value' | translate }}:</ion-col>
            <ion-col class="sis-global-text-overflow-ellipsis" [innerHTML]="item.translatedValue"></ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="4" class="sis-logbook-mobile-label">{{ 'general.term.user' | translate }}:</ion-col>
            <ion-col class="sis-global-text-overflow-ellipsis">{{ item.email }}</ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </cdk-virtual-scroll-viewport>
  </ion-grid>

  <ion-grid *ngIf="this.logbookEntriesFiltered == null" class="data-loading">
    <ion-row>
      <ion-col>
        <ion-spinner></ion-spinner>
        <p>{{ 'analytics.phrase.dataLoading' | translate }}</p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
