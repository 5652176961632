<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g
    transform="matrix(2.113,0,0,2.113,-97.71,-164.8)"
    [ngClass]="this.class"
    fill="none"
    stroke-linecap="round"
    stroke-miterlimit="10"
    stroke-width="2px"
    data-test="abbsmartsensor-widget"
  >
    <rect x="70.81" y="114.4" width="46.01" height="33.6" rx="3" />
    <line x1="78.09" x2="78.09" y1="114.4" y2="148.1" />
    <line x1="109.5" x2="109.5" y1="114.4" y2="148.1" />
    <rect x="64.4" y="128" width="6.42" height="6.42" />
    <line x1="80.52" x2="107.1" y1="131.2" y2="131.2" />
    <line x1="80.52" x2="107.1" y1="138.5" y2="138.5" />
    <line x1="80.52" x2="107.1" y1="143.4" y2="143.4" />
    <line x1="80.52" x2="107.1" y1="124" y2="124" />
    <line x1="80.52" x2="107.1" y1="119.1" y2="119.1" />
  </g>
</svg>
