import { SisConverter } from 'src/app/core/utils/sis-converter';
import { Alarm } from 'src/app/domain/alarm.model';

export class MeteoForecastAlarmAdapter {
  static adapt(item: any): Alarm[] {
    const alarms: Alarm[] = [];

    if (item) {
      item.forEach((e: any) => {
        alarms.push(
          new Alarm({
            sisId: e.DeviceSisId,
            tenantSisId: e.TenantSisId ?? e.PartitionKey,
            timestamp: new Date(e.GatewayTimestamp),
            active: SisConverter.toBoolean(e.Active),
            code: e.Code,
            info: e.Info,
            level: Alarm.getLevel(e.Level),
            text: e.Text,
          })
        );
      });
    }

    return alarms;
  }
}
