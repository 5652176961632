import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuController, ModalController } from '@ionic/angular';
import { debounceTime, filter, firstValueFrom, takeUntil, withLatestFrom } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { TenantUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-tenant-edit',
  templateUrl: 'tenant-edit.component.html',
  styleUrls: ['tenant-edit.component.scss'],
})
export class TenantEditModal extends Unsubscriber implements OnInit {
  private readonly basePostUrl: string = '/tenant';
  title: string;
  tenant: Tenant;
  editedTenant: Tenant = new Tenant({});
  formGroup: FormGroup;
  isSaving: boolean;

  constructor(
    private modalCtrl: ModalController,
    private menuCtrl: MenuController,
    private eventBus: EventBusService,
    private userMessageService: UserMessageService,
    private userSettingsService: UserSettingsService,
    private http: HttpClient
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      sisId: new FormControl<string>(this.tenant?.sisId ?? '', [Validators.required]),
      alias: new FormControl<string>(this.tenant?.alias ?? '', [Validators.required]),
      fullname: new FormControl<string>(this.tenant?.fullname ?? '', [Validators.required]),
      gatewayId: new FormControl<string>(this.tenant?.gatewayId ?? null),
      panoMapFilename: new FormControl<string>(this.tenant?.panoMapFilename ?? null),
      logoFilename: new FormControl<string>(this.tenant?.logoFilename ?? '', [Validators.required]),
      weatherTrendLink: new FormControl<string>(this.tenant?.weatherTrendLink ?? null),
      lat: new FormControl<number>(this.tenant?.positionGeoMap?.x ?? null),
      lon: new FormControl<number>(this.tenant?.positionGeoMap?.y ?? null),
      maxUsers: new FormControl<number>(this.tenant?.maxUsers ?? 10, [Validators.required]),
      timeZoneId: new FormControl<string>(this.tenant?.timeZoneId ?? 'W. Europe Standard Time', [Validators.required]),
    });

    this.formGroup.valueChanges.pipe(debounceTime(100), takeUntil(this.onDestroy$)).subscribe((changes) => {
      this.editedTenant.sisId = changes.sisId;
      this.editedTenant.alias = changes.alias;
      this.editedTenant.fullname = changes.fullname;
      this.editedTenant.gatewayId = changes.gatewayId;
      this.editedTenant.panoMapFilename = changes.panoMapFilename;
      this.editedTenant.logoFilename = changes.logoFilename;
      this.editedTenant.weatherTrendLink = changes.weatherTrendLink;
      this.editedTenant.positionGeoMap.x = Number.parseFloat(changes.lat) ?? null;
      this.editedTenant.positionGeoMap.y = Number.parseFloat(changes.lon) ?? null;
      this.editedTenant.maxUsers = Number.parseInt(changes.maxUsers);
      this.editedTenant.timeZoneId = changes.timeZoneId;
    });

    this.eventBus
      .observe(TenantUpdatedEvent)
      .pipe(
        takeUntil(this.onDestroy$),
        withLatestFrom(this.userSettingsService.userSettings$),
        filter(([event, userSettings]) => event.changedBy === userSettings.userGuid)
      )
      .subscribe(([event]) => {
        this.isSaving = false;

        if (event.updateSuccessful) {
          this.closeModal();

          const translateKey = 'general.phrase.saved';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.success,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.green,
          });
          this.userMessageService.presentToast(userMessage);
        } else {
          const translateKey = 'general.phrase.saveFailed';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.failed,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);
        }
      });
  }

  async save(): Promise<void> {
    this.isSaving = true;

    try {
      await firstValueFrom(
        this.http.post(`${environment.baseUrlApi}${this.basePostUrl}`, {
          tenantGuid: this.tenant?.guid,
          sisId: this.editedTenant.sisId,
          alias: this.editedTenant.alias,
          fullname: this.editedTenant.fullname,
          gatewayId: this.editedTenant.gatewayId,
          panoMapFilename: this.editedTenant.panoMapFilename,
          logoFilename: this.editedTenant.logoFilename,
          weatherTrendLink: this.editedTenant.weatherTrendLink,
          positionGeoMap:
            this.editedTenant.positionGeoMap.x && this.editedTenant.positionGeoMap.y
              ? `[${this.editedTenant.positionGeoMap.x},${this.editedTenant.positionGeoMap.y}]`
              : null,
          maxUsers: this.editedTenant.maxUsers,
          timeZoneId: this.editedTenant.timeZoneId,
        })
      );
    } catch {
      const translateKey = 'general.phrase.saveFailed';
      const userMessage = new UserMessage({
        message: translateKey,
        icon: UserMessageIcon.failed,
        durationMs: 2000,
        position: 'top',
        color: UserMessageColor.red,
      });
      this.userMessageService.presentToast(userMessage);
    }
  }

  async copyToClipboard(text: string): Promise<void> {
    await navigator.clipboard.writeText(text);
    const toast = await this.userMessageService.createSingleToast(
      new UserMessage({
        message: 'general.phrase.copiedToClipboard',
        icon: UserMessageIcon.info,
        durationMs: 2000,
        position: 'top',
        color: UserMessageColor.white,
      })
    );
    await toast.present();
  }

  closeModal() {
    this.modalCtrl.dismiss();
    this.menuCtrl.close('mainMenu');
  }
}
