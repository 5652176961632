import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SisMediaOperatingHoursEntry } from 'src/app/sismedia/sismedia-operating-hours-modal/domain/sismedia-operating-hours-entry.model';

@Component({
  selector: 'sis-sismedia-operating-hours-item',
  templateUrl: './sismedia-operating-hours-item.component.html',
  styleUrls: ['./sismedia-operating-hours-item.component.scss'],
})
export class SisMediaOperatingHoursItemComponent implements OnInit {
  readonly statusTranslatingMapping: Array<{ value: number; translationString: string }> = [
    { value: 0, translationString: 'operatinghours.term.status.closed' },
    { value: 1, translationString: 'operatinghours.term.status.open' },
    { value: 2, translationString: 'operatinghours.term.status.openThroughout' },
  ];

  @Output() isEdited = new EventEmitter<number>();
  @Input() disabled: boolean;
  @Input() bigScreenMode: boolean;
  @Input() operatingHoursEntry: SisMediaOperatingHoursEntry;

  weekdayTranslationString: string;

  ngOnInit() {
    if (this.operatingHoursEntry) {
      this.weekdayTranslationString = `general.term.weekday.${this.operatingHoursEntry.weekday}`;
    }
  }

  statusSelected(event: any): void {
    this.operatingHoursEntry.status = event.target.value;
    this.isEdited.emit(this.operatingHoursEntry.weekday);
  }

  timeChanged(event: any, property: string): void {
    this.operatingHoursEntry[property] = event.target.value;
    this.isEdited.emit(this.operatingHoursEntry.weekday);
  }
}
