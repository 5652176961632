<ion-row class="sis-global-row-40">
  <ion-col>
    <ion-grid style="padding: 0px">
      <ion-row>
        <ion-col class="sis-asset-type">
          {{ this.abbSmartSensor.assetType }}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="sis-asset-name">
          {{ this.abbSmartSensor.assetName }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-col>
  <ion-col size="auto" style="min-width: 25px" matTooltip="{{ 'abbSmartSensor.term.environment' | translate }}">
    <svg version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" class="sis-abbsmartsensor-icon">
      <g [ngClass]="this.cssClassForEnvironment" data-test="abbsmartsensor-item-environment-icon">
        <path
          d="m24 1.56a4 4 0 0 0-4 3.996 4 4 0 0 0 0 4e-3 4 4 0 0 0 0 0.061v20.779a10 10 0 0 0-6 9.16 10 10 0 0 0 10 10 10 10 0 0 0 10-10 10 10 0 0 0-6-9.16v-20.84a4 4 0 0 0 0-4e-3 4 4 0 0 0-4-3.996zm-0.05 2a2 2 0 0 1 0.05 0 2 2 0 0 1 2 1.996 2 2 0 0 1 0 4e-3v21.5a1 1 0 0 0 0 0.02 1 1 0 0 0 0.66 0.94 1 1 0 0 0 0.01 0 8 8 0 0 1 5.33 7.54 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 5.28-7.52 8 8 0 0 1 0.01 0 1 1 0 0 0 0.71-0.96 1 1 0 0 0 0-0.08v-21.389a2 2 0 0 1 0-0.051 2 2 0 0 1 0-4e-3 2 2 0 0 1 1.95-1.996z"
          stroke-width="1.0729"
        />
        <path d="m23 5.52v24.19a6.02 6.02 0 0 0-5.02 5.93 6.02 6.02 0 0 0 6.02 6.02 6.02 6.02 0 0 0 6.02-6.02 6.02 6.02 0 0 0-5.02-5.93v-24.19h-2z" />
      </g>
    </svg>
  </ion-col>
  <ion-col size="auto" style="min-width: 25px; padding-right: 5px" matTooltip="{{ 'abbSmartSensor.term.reliability' | translate }}">
    <svg version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" class="sis-abbsmartsensor-icon">
      <path
        d="m24.42 1.395a22 22 0 0 0-21.871 21.995 22 22 0 0 0 21.991 22.01 22 22 0 0 0 22-22.01v-0.07l-1.93-1.94h-0.17a20.01 20.01 0 0 1 0 0.02h-17.89v-18.088l-1.92-1.917h-0.09a22 22 0 0 0-0.12 0zm-0.03 2.001a20.01 20.01 0 0 1 0.15 0v18.074l1.92 1.92h18.08a20.01 20.01 0 0 1-20 19.99 20.01 20.01 0 0 1-19.994-19.99 20.01 20.01 0 0 1 19.844-19.994z"
        stroke-width="1.8142"
        [ngClass]="this.cssClassForReliability"
        data-test="abbsmartsensor-item-reliability-icon"
      />
    </svg>
  </ion-col>
  <ion-col size="auto" style="min-width: 25px; padding-right: 7px" matTooltip="{{ 'abbSmartSensor.term.stress' | translate }}">
    <svg version="1.1" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" class="sis-abbsmartsensor-icon">
      <path
        d="m22.6 1.262a22 22 0 0 0-20.111 23.618 22 22 0 0 0 2.199 8.02 0.9999 0.9999 0 0 0 0.09 0.18 0.9999 0.9999 0 0 0 0.923 0.47 0.9999 0.9999 0 0 0 0.485-0.2 0.9999 0.9999 0 0 0 0.146-0.1l12.178-10.06 3.91 5.89 1.41-0.11 7.81-7.92 0.03 2.65 2-0.01-0.09-5.91v-0.02l-6.18-0.33-0.1 1.99 2.97 0.18-7.01 7.12-3.19-4.82-0.5-0.76-1.88 0.14-11.691 9.64a20.01 20.01 0 0 1-1.5-6.19 20.01 20.01 0 0 1 18.231-21.475 20.01 20.01 0 0 1 0.16-0.012 20.01 20.01 0 0 1 21.48 18.407 20.01 20.01 0 0 1-18.4 21.48 20.01 20.01 0 0 1-12.62-3.32 1.012 1.023 85.59 0 0-0.16-0.1 1.012 1.023 85.59 0 0-0.57-0.13 1.012 1.023 85.59 0 0-0.96 1.09 1.012 1.023 85.59 0 0 0.4 0.73 1.012 1.023 85.59 0 0 0.14 0.09 22 22 0 0 0 13.92 3.63 22 22 0 0 0 20.24-23.62 22 22 0 0 0-23.62-20.249 22 22 0 0 0-0.14 0.011z"
        stroke-width="1.8142"
        [ngClass]="this.cssClassForStress"
        data-test="abbsmartsensor-item-stress-icon"
      />
    </svg>
  </ion-col>
</ion-row>
