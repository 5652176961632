import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WindIconService {
  readonly windIconSvgAsHtml$: Observable<string> = this.httpClient
    .get(`${environment.baseUrlCockpitBlobStorage}/public-assets/icons/sis-wind-icon.svg`, { responseType: 'text' })
    .pipe(shareReplay(1));
  
  constructor(private httpClient: HttpClient) {}
}
