import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { FirstEntriesAdapter } from 'src/app/maps/livedata/first-entries.adapter';
import { FirstEntriesData } from 'src/app/maps/livedata/first-entries.model';

@Injectable({
  providedIn: 'root',
})
export class FirstEntriesService extends LivedataServiceBase<FirstEntriesData> {
  protected readonly endpointUri = 'livedata/firstEntries';
  protected readonly requiredFeatures = [
    new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ),
    new Feature(FeatureId.COCKPIT_FIRSTENTRIES, FeatureAccessLevel.READ),
  ];

  readonly firstEntries$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, FirstEntriesAdapter.adapt, destinationService);
  }
}
