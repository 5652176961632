<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.close()" [disabled]="this.hasUnsavedChanges">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title class="sis-modal-title">{{ 'mediacenter.term.timeControl' | translate }} - {{ slide.name }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <!-- Big Screen -->
  <ion-grid *ngIf="this.bigScreenMode" class="sis-modal-grid">
    <form [formGroup]="this.formGroup">
      <ion-row class="sis-global-row-40 vertical-align-content sis-alternate-background">
        <ion-col size="2" style="padding-left: 0px">
          <b>{{ 'general.term.active' | translate }}</b>
        </ion-col>
        <ion-col size="auto">
          <ion-toggle
            class="sis-toggle-input"
            formControlName="timeControlEnabled"
            color="secondary"
            data-test="media-center-active-toggle"
          ></ion-toggle>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col class="ion-no-padding">
          <ion-grid class="sis-time-control-table-grid">
            <ion-row class="sis-global-row-40 vertical-align-content" style="margin-top: 10px; margin-bottom: 5px">
              <ion-col size="3" style="padding-left: 20px; height: 31px">
                <b>{{ 'mediacenter.term.startDate' | translate }}</b>
              </ion-col>
              <ion-col size="4" class="sis-datetime-col sis-text-col">
                <ion-input
                  type="datetime-local"
                  class="sis-modal-input"
                  formControlName="startDate"
                  [ngClass]="{ 'sis-modal-input-error': this.formGroup.errors?.dateTimesInvalid }"
                  data-test="media-center-start-date-input"
                ></ion-input>
              </ion-col>
            </ion-row>

            <ion-row class="sis-global-row-40 vertical-align-content" style="margin-bottom: 15px">
              <ion-col size="3" style="padding-left: 20px; height: 31px">
                <b>{{ 'mediacenter.term.endDate' | translate }}</b>
              </ion-col>
              <ion-col size="4" class="sis-datetime-col sis-text-col">
                <ion-input
                  type="datetime-local"
                  class="sis-modal-input"
                  formControlName="endDate"
                  [ngClass]="{
                    'sis-modal-input-error':
                      this.formGroup.errors?.dateTimesInvalid || this.formGroup.errors?.endDateTimeInvalid
                  }"
                  data-test="media-center-start-date-input"
                ></ion-input>
              </ion-col>
            </ion-row>

            <ion-row class="sis-global-row-40 vertical-align-content" style="margin-bottom: 15px">
              <ion-col size="3" style="padding-left: 20px; height: 31px">
                <b>{{ 'mediacenter.term.displayTime' | translate }}</b>
              </ion-col>
              <ion-col size="1" style="height: 31px">
                {{ 'general.term.fromTime' | translate }}
              </ion-col>
              <ion-col size="3" class="sis-datetime-col sis-text-col">
                <ion-input
                  type="time"
                  class="sis-modal-input"
                  formControlName="startTime"
                  data-test="media-center-start-time-input"
                ></ion-input>
              </ion-col>
              <ion-col size="1" style="padding-left: 10px; height: 31px">
                {{ 'general.term.toTime' | translate }}
              </ion-col>
              <ion-col size="3" class="sis-datetime-col sis-text-col">
                <ion-input
                  type="time"
                  class="sis-modal-input"
                  formControlName="endTime"
                  data-test="media-center-end-time-input"
                ></ion-input>
              </ion-col>
            </ion-row>

            <ion-row class="sis-global-row-40 vertical-align-content">
              <ion-col size="auto" style="padding-left: 20px; height: 31px">
                <b>{{ 'general.term.weekdays' | translate }}</b>
              </ion-col>
            </ion-row>

            <ion-row class="sis-global-row-40 vertical-align-content sis-alternate-background">
              <ion-col size="1" style="padding-left: 20px">
                <ion-checkbox
                  formControlName="mon"
                  data-test="media-center-weekday-checkbox"
                  color="secondary"
                ></ion-checkbox>
              </ion-col>
              <ion-col> {{ 'general.term.weekday.1' | translate }} </ion-col>
            </ion-row>

            <ion-row class="sis-global-row-40 vertical-align-content sis-alternate-background">
              <ion-col size="1" style="padding-left: 20px">
                <ion-checkbox
                  formControlName="tue"
                  data-test="media-center-weekday-checkbox"
                  color="secondary"
                ></ion-checkbox>
              </ion-col>
              <ion-col> {{ 'general.term.weekday.2' | translate }} </ion-col>
            </ion-row>

            <ion-row class="sis-global-row-40 vertical-align-content sis-alternate-background">
              <ion-col size="1" style="padding-left: 20px">
                <ion-checkbox
                  formControlName="wed"
                  data-test="media-center-weekday-checkbox"
                  color="secondary"
                ></ion-checkbox>
              </ion-col>
              <ion-col> {{ 'general.term.weekday.3' | translate }} </ion-col>
            </ion-row>

            <ion-row class="sis-global-row-40 vertical-align-content sis-alternate-background">
              <ion-col size="1" style="padding-left: 20px">
                <ion-checkbox
                  formControlName="thu"
                  data-test="media-center-weekday-checkbox"
                  color="secondary"
                ></ion-checkbox>
              </ion-col>
              <ion-col> {{ 'general.term.weekday.4' | translate }} </ion-col>
            </ion-row>

            <ion-row class="sis-global-row-40 vertical-align-content sis-alternate-background">
              <ion-col size="1" style="padding-left: 20px">
                <ion-checkbox
                  formControlName="fri"
                  data-test="media-center-weekday-checkbox"
                  color="secondary"
                ></ion-checkbox>
              </ion-col>
              <ion-col> {{ 'general.term.weekday.5' | translate }} </ion-col>
            </ion-row>

            <ion-row class="sis-global-row-40 vertical-align-content sis-alternate-background">
              <ion-col size="1" style="padding-left: 20px">
                <ion-checkbox
                  formControlName="sat"
                  data-test="media-center-weekday-checkbox"
                  color="secondary"
                ></ion-checkbox>
              </ion-col>
              <ion-col> {{ 'general.term.weekday.6' | translate }} </ion-col>
            </ion-row>

            <ion-row class="sis-global-row-40 vertical-align-content sis-alternate-background">
              <ion-col size="1" style="padding-left: 20px">
                <ion-checkbox
                  formControlName="sun"
                  data-test="media-center-weekday-checkbox"
                  color="secondary"
                ></ion-checkbox>
              </ion-col>
              <ion-col> {{ 'general.term.weekday.7' | translate }} </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [disabled]="!this.writePermission || !this.hasUnsavedChanges || this.isSaving"
      [showSpinner]="this.isSaving"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      class="sis-global-button-padding-right"
      data-test="media-center-save-btn"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.reset'"
      [btnType]="'Secondary'"
      (sisClick)="this.reset()"
      [disabled]="!this.writePermission || !this.isResetable || this.isSaving"
      [showSpinner]="this.isSaving"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      data-test="media-center-reset-btn"
    ></sis-general-button>
  </div>
</ion-footer>
