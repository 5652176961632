import { Component, Input, NgZone, OnInit } from '@angular/core';
import { LayerGroup } from 'leaflet';
import { combineLatest } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { DeviceListHoverEvent, MapIconClickedEvent, MapIconHoveredEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayStatusType } from 'src/app/maps/livedata/ropeway-status.model';
import { RopewayStatusService } from 'src/app/maps/livedata/ropeway-status.service';
import { RopewayCssClassNames } from 'src/app/maps/map/ropeway-layer/ropeway-line/ropeway-css-classnames';
import { RopewayCurve } from 'src/app/maps/map/ropeway-layer/ropeway-line/ropeway-curve';
import { RopewayPolyLine } from 'src/app/maps/map/ropeway-layer/ropeway-line/ropeway-polyline';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';

@Component({
  selector: 'sis-ropeway-line',
  template: '',
  styleUrls: ['./ropeway-line.component.scss'],
})
export class RopewayLineComponent extends Unsubscriber implements OnInit {
  static readonly ropewayLines: Map<string, RopewayLineComponent> = new Map<string, RopewayLineComponent>();

  lineOrCurve: RopewayPolyLine | RopewayCurve;
  bigScreenMode: boolean;

  @Input() layerGroup: LayerGroup;
  @Input() ropeway: Ropeway;

  constructor(
    private eventBus: EventBusService,
    private ropewayStatusService: RopewayStatusService,
    private zone: NgZone,
    private screenSizeService: ScreenSizeService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    if (this.ropeway) {
      RopewayLineComponent.ropewayLines.set(this.ropeway.sisId, this);
      this.initRopewayLayer();
    }
  }

  private initRopewayLayer() {
    if (!this.ropeway?.pathPanoMap?.length) {
      return;
    }

    this.drawLineOrCurve(this.ropeway);
    this.lineOrCurve.line.addEventListener('add', () => {
      this.observeUpdateEvents();
    });
  }

  private observeUpdateEvents() {
    combineLatest([
      this.ropewayStatusService.ropewayStatus$.pipe(map((statusMap) => statusMap.get(this.ropeway.sisId))),
      this.eventBus.observe(DeviceListHoverEvent).pipe(
        filter((event) => this.getShortSisId() === event.deviceSisId.split('.')[0]),
        map((event) => event.hover),
        startWith(false)
      ),
      combineLatest([this.selectedMapElementService.selectedMapElement$, this.screenSizeService.landscapeMode$]).pipe(
        map(([event, landScapeMode]) => {
          const shortSisId = this.getShortSisId();
          const clickedRopewayWithShortSisId = event.ropeway?.sisId?.split('.')[0] === shortSisId;
          const clickedMeteoStationOnRopewayWithShortSisId =
            event.meteoStation?.ropewaySisId?.split('.')[0] === shortSisId;

          return !landScapeMode && (clickedRopewayWithShortSisId || clickedMeteoStationOnRopewayWithShortSisId);
        }),
        startWith(false)
      ),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([ropewayStatusTypeMap, hover, selected]) => {
        this.updateRopewayLine(ropewayStatusTypeMap, hover || selected);
      });
  }

  private drawLineOrCurve(ropeway: Ropeway) {
    this.lineOrCurve = ropeway.pathPanoMap[0] === 'M' ? new RopewayCurve(ropeway) : new RopewayPolyLine(ropeway);

    this.lineOrCurve.create(this.layerGroup);

    this.lineOrCurve.clickable.options.bubblingMouseEvents = false;
    this.lineOrCurve.clickable.on('click', () => this.zone.run(() => this.onClick()));
    this.lineOrCurve.clickable.on('mouseover', () => this.zone.run(() => this.createHoverEvent(true)));
    this.lineOrCurve.clickable.on('mouseout', () => this.zone.run(() => this.createHoverEvent(false)));
  }

  private onClick(): void {
    const clickEvent = new MapIconClickedEvent();
    clickEvent.ropeway = this.ropeway;
    this.eventBus.publish(clickEvent);
  }

  private createHoverEvent(hover: boolean): void {
    if (!this.ropeway) {
      return;
    }

    const event = new MapIconHoveredEvent();
    event.ropeway = this.ropeway;
    event.hover = hover;
    this.eventBus.publish(event);
  }

  private updateRopewayLine(ropewayStatusTypeMap: Map<RopewayStatusType, boolean>, highlight: boolean): void {
    const cssClass = this.getColorClass(ropewayStatusTypeMap);
    if (this.lineOrCurve.line.getElement()) {
      this.lineOrCurve.line.getElement().setAttribute('class', cssClass);
    }
    if (this.lineOrCurve.border.getElement()) {
      this.lineOrCurve.border.getElement().setAttribute('class', cssClass + RopewayCssClassNames.cssBorder);
    }

    const active = this.isControllerActive(ropewayStatusTypeMap);

    this.setHighlight(active, cssClass, highlight);
    this.setVisibility(active);
  }

  private getColorClass(ropewayStatusTypeMap: Map<RopewayStatusType, boolean>): string {
    if (!ropewayStatusTypeMap) {
      return RopewayCssClassNames.cssRopewayDefault;
    }
    if (ropewayStatusTypeMap.get(RopewayStatusType.OFFLINE)) {
      return RopewayCssClassNames.cssRopewayOffline;
    }
    if (ropewayStatusTypeMap.get(RopewayStatusType.OUTDATED) || !ropewayStatusTypeMap.has(RopewayStatusType.OUTDATED)) {
      return RopewayCssClassNames.cssRopewayOutdated;
    }
    if (ropewayStatusTypeMap.get(RopewayStatusType.FAULT)) {
      return RopewayCssClassNames.cssRopewayAlarm;
    }
    if (ropewayStatusTypeMap.get(RopewayStatusType.DRIVERUNS)) {
      return RopewayCssClassNames.cssRopewayRunning;
    }
    return RopewayCssClassNames.cssRopewayDriveReady;
  }

  private setHighlight(controllerActive: boolean, cssClass: string, highlight: boolean): void {
    if (!controllerActive) {
      return;
    }
    const highlightDiv = this.lineOrCurve.highlight.getElement();
    if (!highlightDiv) {
      return;
    }
    if (highlight) {
      highlightDiv.setAttribute('class', cssClass + RopewayCssClassNames.cssHighlight);
    } else {
      highlightDiv.setAttribute('class', RopewayCssClassNames.cssRopewayInvisible);
    }
  }

  private isControllerActive(ropewayStatusTypeMap: Map<RopewayStatusType, boolean>): boolean {
    return !(ropewayStatusTypeMap && ropewayStatusTypeMap.get(RopewayStatusType.CONTROLLERACTIVE) === false);
  }

  private setVisibility(controllerActive: boolean): void {
    const elements = this.lineOrCurve.getRopewayElements();
    elements.forEach((el) => {
      if (el) {
        if (controllerActive) {
          el.classList.remove(RopewayCssClassNames.cssRopewayInactive);
        } else {
          el.classList.add(RopewayCssClassNames.cssRopewayInactive);
        }
      }
    });
  }

  private getShortSisId(): string {
    return this.ropeway.sisId?.split('.')[0];
  }
}
