import { FeatureAdapter } from 'src/app/domain/feature/feature.adapter';
import { ManagedTenant } from 'src/app/user-management/domain/managed-tenant.model';

export class ManagedTenantAdapter {
  static adapt(items: any): ManagedTenant[] {
    const tenants: ManagedTenant[] = [];
    if (!items) {
      return [];
    }

    items.forEach((e: any) => {
      tenants.push({
        guid: e.guid,
        alias: e.alias,
        activeUsers: e.activeUsers,
        maxUsers: e.maxUsers,
        hasWritePermission: e.hasWritePermission,
        features: FeatureAdapter.adapt(e.features).sort((a, b) => a.featureId - b.featureId),
      });
    });

    return tenants;
  }
}
