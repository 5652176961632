import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayTripLogAdapter } from 'src/app/maps/widgets/detailviews/stoptime-detailview/ropeway-triplog.adapter';
import { RopewayTripLog } from 'src/app/maps/widgets/detailviews/stoptime-detailview/ropeway-triplog.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RopewayTripLogService {
  private readonly url = environment.baseUrlApi + '/ropewaytriplog';

  readonly ropewayTripLogs$: Observable<RopewayTripLog[]> = this.selectedMapElementService.selectedRopeway$.pipe(
    switchMap((ropeway) => {
      if (!ropeway) {
        return of([]);
      }

      return timer(0, 10000).pipe(
        switchMap(() =>
          this.http.get(`${this.url}/${ropeway.sisId}`).pipe(map((data: any) => RopewayTripLogAdapter.adapt(data)))
        )
      );
    }),
    share()
  );

  constructor(private http: HttpClient, private selectedMapElementService: SelectedMapElementService) {}
}
