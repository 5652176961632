import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/maps/domain/configuration.model';
import { Coords } from 'src/app/maps/domain/coords.model';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { MeteoLivedata } from 'src/app/maps/livedata/meteo-livedata.model';

export enum ArrowStyle {
  NONE,
  ONE,
  TWO,
}

@Injectable({
  providedIn: 'root',
})
export class WindDisplayCalculatorService {
  static cssMeteostationColorNormal = 'sis-meteostation-normal';
  static cssMeteostationColorWarning = 'sis-meteostation-warning';
  static cssMeteostationColorAlarm = 'sis-meteostation-alarm';
  static cssMeteostationColorOffline = 'sis-meteostation-offline';
  static windSpeedUnknown = '?';

  static readonly defaultWarnRangeHigh = 40;
  static readonly defaultAlarmRangeHigh = 60;

  getColor(liveData: MeteoLivedata, config: Configuration): string {
    return this.getWindWarnColor(liveData.windSpeedKmh, config);
  }

  getRotationTransform(
    liveData: MeteoLivedata,
    directionOffsetPanomap: number,
    directionOffsetNorth: number,
    pivotPoint: Coords
  ): string {
    if (this.hasValue(liveData.windDirectionDegreeNorth) && this.hasValue(directionOffsetNorth)) {
      return `rotate(${liveData.windDirectionDegreeNorth - directionOffsetNorth + directionOffsetPanomap},${
        pivotPoint.x
      },${pivotPoint.y})`;
    } else if (this.hasValue(liveData.windDirectionDegreeAxis)) {
      return `rotate(${liveData.windDirectionDegreeAxis + directionOffsetPanomap},${pivotPoint.x},${pivotPoint.y})`;
    } else {
      return '';
    }
  }

  getWindSpeed(liveData: MeteoLivedata): string {
    return liveData.windSpeedKmh?.toString() ?? WindDisplayCalculatorService.windSpeedUnknown;
  }

  getArrowStyle(liveData: MeteoLivedata, meteoStation: MeteoStation): ArrowStyle {
    if (liveData.windSpeedKmh === 0) {
      return ArrowStyle.NONE;
    }

    switch (meteoStation.windDirection) {
      case 'none':
        return ArrowStyle.NONE;
      case 'directional':
        return ArrowStyle.ONE;
      case 'across':
        return ArrowStyle.TWO;
      default:
        return ArrowStyle.ONE;
    }
  }

  private hasValue(data: number): boolean {
    return !(data == null || isNaN(data));
  }

  private getWindWarnColor(windValue: number, config: Configuration): string {
    const warnRangeHigh = config?.warnRangeHigh ?? WindDisplayCalculatorService.defaultWarnRangeHigh;
    const alarmRangeHigh = config?.alarmRangeHigh ?? WindDisplayCalculatorService.defaultAlarmRangeHigh;

    if (windValue < warnRangeHigh) {
      return WindDisplayCalculatorService.cssMeteostationColorNormal;
    }
    if (windValue >= warnRangeHigh && windValue < alarmRangeHigh) {
      return WindDisplayCalculatorService.cssMeteostationColorWarning;
    }
    if (windValue >= alarmRangeHigh) {
      return WindDisplayCalculatorService.cssMeteostationColorAlarm;
    }

    return WindDisplayCalculatorService.cssMeteostationColorOffline;
  }
}
