<ion-grid class="sis-global-page-grid">
  <sis-page-title [title]="'general.term.timetable'" [featureId]="[[this.featureId]]"> </sis-page-title>
  <!-- Big Screen -->
  <ion-grid *ngIf="this.bigScreenMode" class="sis-timetable-no-scroll sis-timetable-grid ion-no-padding">
    <ion-row>
      <ion-col size="4">
        <ion-select
          style="max-width: 500px"
          class="sis-global-input"
          interface="popover"
          [interfaceOptions]="{ cssClass: 'sis-global-popover' , size: 'cover' }"
          data-test="timetable-track-select"
          [(ngModel)]="this.activeTrack"
          (ionChange)="onTrackSelected()"
        >
          <ion-select-option *ngFor="let track of this.tracks" [value]="track">{{ track.name }}</ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>

    <ng-container *ngIf="this.activeTrack?.type === 0">
      <ion-row style="align-items: center">
        <ion-col size="4">
          <ion-select
            style="max-width: 500px"
            *ngIf="this.activeTrack && this.refreshed"
            class="sis-global-input"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover' , size: 'cover' }"
            data-test="timetable-season-select"
            [(ngModel)]="this.activeSeason"
            (ionChange)="onSeasonSelected()"
          >
            <ion-select-option *ngFor="let season of this.activeTrack.seasons" [value]="season"
              ><div *ngIf="!season.isEmergencyTimetable && !season.isImportTimetable">
                {{ season.name }}: {{ season.validFrom | dateFormat }} - {{ season.validUntil | dateFormat }}
              </div>
              <div *ngIf="season.isEmergencyTimetable || season.isImportTimetable">{{ season.name }}</div>
            </ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col
          size="auto"
          *ngIf="this.writePermission && this.activeSeason && !this.activeSeason.isEmergencyTimetable && !this.activeSeason.isImportTimetable"
        >
          <a
            [ngClass]="this.writePermission ? 'sis-timetable-clickable' : 'sis-timetable-clickable-no-permission'"
            (click)="this.openSeasonModal()"
            data-test="timetable-season-edit-button"
            matTooltip="{{ 'general.term.edit' | translate }}"
          >
            <ion-icon class="sis-timetable-icon" name="create-outline"> </ion-icon>
          </a>
        </ion-col>
        <ion-col size="auto" *ngIf="this.showSeasonWarning" [matTooltip]="this.seasonWarningText">
          <ion-icon
            class="sis-timetable-icon"
            [ngClass]="this.showSeasonWarningColor ? 'sis-timetable-warning-icon' : 'sis-timetable-danger-icon'"
            name="warning-outline"
            data-test="timetable-warning-icon"
          ></ion-icon>
        </ion-col>
        <ion-col
          *ngIf="this.activeSeason && this.activeSeason.isImportTimetable"
          data-test="timetable-last-import-icon"
          class="sis-last-import"
          data-test="timetable-last-import"
        >
          {{ "timetable.phrase.lastImport" | translate }}: {{ this.activeSeason.lastImportDate | dateTimeFormat }}
          <ion-icon
            *ngIf="this.activeSeason.lastImportSuccessful"
            class="sis-last-import-icon sis-timetable-success-icon"
            name="checkmark-circle-outline"
            [matTooltip]="'timetable.phrase.importSuccessful' | translate"
            data-test="import-success-icon"
          ></ion-icon>
          <ion-icon
            *ngIf="!this.activeSeason.lastImportSuccessful"
            class="sis-last-import-icon sis-timetable-danger-icon"
            name="warning-outline"
            [matTooltip]="'timetable.phrase.importFailed' | translate"
            data-test="import-failed-icon"
          ></ion-icon>
        </ion-col>
        <ion-col *ngIf="this.activeSeason && this.activeSeason.lastSuccessfulRealTimeImport" size="auto" class="sis-last-import">
          {{ "timetable.phrase.lastSuccessfulRealTimeImport" | translate }}: {{ this.activeSeason.lastSuccessfulRealTimeImport | dateTimeFormat}}
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="auto">
          <sis-general-button
            class="sis-timetable-new"
            [text]="'timetable.phrase.newJourney'"
            [btnType]="'Secondary'"
            (sisClick)="this.writePermission && openJourneyModal()"
            [disabled]="!this.writePermission"
            data-test="timetable-new-journey-button"
          ></sis-general-button>
        </ion-col>
        <ion-col size="auto" *ngIf="this.activeSeason && this.activeSeason.isImportTimetable" style="margin-left: 20px">
          <sis-general-button
            class="sis-timetable-new"
            [text]="'timetable.phrase.importTimetable'"
            [btnType]="'Secondary'"
            (sisClick)="this.requestTimetableImport()"
            [disabled]="!this.writePermission || this.isImporting"
            [showSpinner]="this.isImporting"
            width="200px"
            data-test="timetable-import-button"
          ></sis-general-button>
        </ion-col>
      </ion-row>

      <ion-row class="sis-timetable-header">
        <ion-col size="1">{{ "timetable.term.vehicleNumberAbbr" | translate }}</ion-col>
        <ion-col
          *ngFor="let station of this.activeStations"
          [size]="this.activeStations.length > 5 ? 1 : 1.5"
          class="sis-global-text-overflow-ellipsis"
          >{{ station.name }}</ion-col
        >
        <ion-col *ngIf="this.activeStations.length <= 2">{{ "timetable.term.direction" | translate }}</ion-col>
        <ion-col *ngIf="this.activeStations.length > 2"></ion-col>
        <ion-col size="auto" style="min-width: 100px">{{ "general.term.edit" | translate }}</ion-col>
      </ion-row>

      <cdk-virtual-scroll-viewport itemSize="35" class="scroll-container sis-global-no-scrollbar" minBufferPx="280" maxBufferPx="560">
        <sis-timetable-journey-row
          *cdkVirtualFor="let journey of this.displayedJourneyElements"
          [journey]="journey"
          [stations]="this.activeStations"
          (openJourneyModalClick)="this.openJourneyModal($event)"
          [attr.data-test]="journey.datatest"
        ></sis-timetable-journey-row>
      </cdk-virtual-scroll-viewport>
    </ng-container>

    <ng-container *ngIf="this.activeTrack?.type === 1">
      <ion-row style="align-items: center">
        <ion-col size="4">
          <ion-select
            style="max-width: 500px"
            *ngIf="this.activeTrack && this.refreshed"
            class="sis-global-input"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover' , size: 'cover' }"
            data-test="timetable-interval-select"
            [(ngModel)]="this.activeInterval"
            (ionChange)="onIntervalSelected()"
          >
            <ion-select-option *ngFor="let interval of this.activeTrack.intervals" [value]="interval">
              <div>{{ interval.name }}</div>
            </ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>

      <ion-row style="padding: 0">
        <ion-col style="padding: 0">
          <sis-timetable-interval [interval]="this.activeInterval"></sis-timetable-interval>
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-grid>

  <!-- Small Screen -->
  <ion-grid *ngIf="!this.bigScreenMode" class="sis-timetable-no-scroll sis-timetable-grid ion-no-padding">
    <ion-row>
      <ion-col>
        <ion-select
          style="max-width: 500px"
          class="sis-global-input"
          interface="popover"
          [interfaceOptions]="{ cssClass: 'sis-global-popover' , size: 'cover' }"
          data-test="timetable-track-select"
          [(ngModel)]="this.activeTrack"
          (ionChange)="onTrackSelected()"
        >
          <ion-select-option *ngFor="let track of this.tracks" [value]="track">{{ track.name }}</ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
    <ng-container *ngIf="this.activeTrack?.type === 0">
      <ion-row style="align-items: center">
        <ion-col>
          <ion-select
            style="max-width: 500px"
            class="sis-global-input"
            *ngIf="this.activeTrack && this.refreshed"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover' , size: 'cover' }"
            data-test="timetable-season-select"
            [(ngModel)]="this.activeSeason"
            (ionChange)="onSeasonSelected()"
          >
            <ion-select-option *ngFor="let season of this.activeTrack.seasons" [value]="season"
              ><div *ngIf="!season.isEmergencyTimetable && !season.isImportTimetable">
                {{ season.name }}: {{ season.validFrom | dateFormat }} - {{ season.validUntil | dateFormat }}
              </div>
              <div *ngIf="season.isEmergencyTimetable || season.isImportTimetable">{{ season.name }}</div></ion-select-option
            >
          </ion-select>
        </ion-col>
        <ion-col size="auto" *ngIf="this.showSeasonWarning" [matTooltip]="this.seasonWarningText">
          <ion-icon
            class="sis-timetable-icon"
            [ngClass]="this.showSeasonWarningColor ? 'sis-timetable-warning-icon' : 'sis-timetable-danger-icon'"
            name="warning-outline"
            data-test="timetable-warning-icon"
          ></ion-icon>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="this.activeSeason && this.activeSeason.isImportTimetable" style="margin-bottom: 10px">
        <ion-col>
          <sis-general-button
            class="sis-timetable-new"
            [text]="'timetable.phrase.importTimetable'"
            [btnType]="'Secondary'"
            (sisClick)="this.requestTimetableImport()"
            [disabled]="!this.writePermission || this.isImporting"
            [showSpinner]="this.isImporting"
            data-test="timetable-import-button"
            width="100%"
          ></sis-general-button>
        </ion-col>
      </ion-row>

      <ng-container *ngIf="this.activeSeason && this.activeSeason.isImportTimetable">
        <ion-row style="margin-bottom: 10px">
          <ion-col> {{ "timetable.phrase.lastImport" | translate }}: </ion-col>
          <ion-col size="auto" style="min-width: 37px; margin-top: -1px">
            <ion-icon
              *ngIf="this.activeSeason.lastImportSuccessful"
              class="sis-last-import-icon sis-timetable-success-icon"
              name="checkmark-circle-outline"
              [matTooltip]="'timetable.phrase.importSuccessful' | translate"
            ></ion-icon>
            <ion-icon
              *ngIf="!this.activeSeason.lastImportSuccessful"
              class="sis-last-import-icon sis-timetable-danger-icon"
              name="warning-outline"
              [matTooltip]="'timetable.phrase.importFailed' | translate"
            ></ion-icon>
          </ion-col>
          <ion-col size="auto"> {{ this.activeSeason.lastImportDate | dateTimeFormat }} </ion-col>
        </ion-row>
        <ion-row *ngIf="this.activeSeason.lastSuccessfulRealTimeImport">
          <ion-col> {{ "timetable.phrase.lastSuccessfulRealTimeImport" | translate }}: </ion-col>
          <ion-col size="auto" style="min-width: 37px"></ion-col>
          <ion-col size="auto"> {{ this.activeSeason.lastSuccessfulRealTimeImport | dateTimeFormat}} </ion-col>
        </ion-row>
      </ng-container>

      <ion-row style="padding-bottom: 10px; border-bottom: 1px solid rgba(50, 132, 154, 0.2)"> </ion-row>

      <cdk-virtual-scroll-viewport itemSize="100" class="scroll-container sis-global-no-scrollbar" minBufferPx="280" maxBufferPx="560">
        <sis-timetable-journey-row-mobile
          *cdkVirtualFor="let journey of this.displayedJourneyElements"
          [journey]="journey"
          [stations]="this.activeStations"
        ></sis-timetable-journey-row-mobile>
      </cdk-virtual-scroll-viewport>
    </ng-container>

    <ng-container *ngIf="this.activeTrack?.type === 1">
      <ion-row *ngIf="this.activeTrack?.type === 1" style="align-items: center">
        <ion-col>
          <ion-select
            style="max-width: 500px"
            class="sis-global-input"
            *ngIf="this.activeTrack && this.refreshed"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover' , size: 'cover' }"
            data-test="timetable-interval-select"
            [(ngModel)]="this.activeInterval"
            (ionChange)="onIntervalSelected()"
          >
            <ion-select-option *ngFor="let interval of this.activeTrack.intervals" [value]="interval">
              <div>{{ interval.name }}</div>
            </ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>

      <ion-row style="padding: 0">
        <ion-col style="padding: 0">
          <sis-timetable-interval [interval]="this.activeInterval"></sis-timetable-interval>
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-grid>
</ion-grid>
