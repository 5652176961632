import { WritablePart } from 'src/app/core/utils/type-helper';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';

export class LogbookEntry {
  private static readonly separator = new RegExp('[{|}]', 'g');

  readonly actions: string[];
  readonly values: string[];

  translatedFeature: string;
  translatedAction: string;
  translatedValue: string;

  id: number;
  userGuid: string;
  email: string;
  tenantGuid: string;
  timestamp: Date;
  featureIds: FeatureId[];
  action: string;
  value: string;
  formattedTimestamp: string;

  constructor(init?: Partial<WritablePart<LogbookEntry>>) {
    Object.assign(this, init);

    this.actions = init.action?.split(LogbookEntry.separator) ?? [];
    this.values = init.value?.split(LogbookEntry.separator) ?? [];
  }
}
