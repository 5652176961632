/* eslint-disable */
interface String {
  format(...replacements: string[]): string;
}
if (!String.prototype.format) {
  String.prototype.format = function() {
    const args = arguments;
    return this.replace(/{(\d+)}/g, (match: string, number: number) =>
      typeof (args[number] !== 'undefined') ? args[number] : match
    );
  };
}
