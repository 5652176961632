import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Alarm, AlarmLevel } from 'src/app/domain/alarm.model';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { AlarmLibraryModal } from 'src/app/maps/alarmlibrary/alarmlibrary.component';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayEfaService } from 'src/app/maps/livedata/ropeway-efa.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayEfaHistoryService } from 'src/app/maps/widgets/detailviews/efa-detailview/efa-history.service';

@Component({
  selector: 'sis-efa-detailview',
  templateUrl: './efa-detailview.component.html',
  styleUrls: ['./efa-detailview.component.scss'],
})
export class EfaDetailViewComponent extends Unsubscriber implements OnInit {
  private ropeway: Ropeway;

  efa: Alarm;
  efaHistory: Alarm[] = [];
  canAccessAlarmLibrary: boolean;
  hasActiveAlarm: boolean = false;
  hasHistoryItems: boolean;

  constructor(
    private modalCtrl: ModalController,
    private destinationService: DestinationService,
    private efaHistoryService: RopewayEfaHistoryService,
    private ropewayEfaService: RopewayEfaService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedMapElementService.selectedRopeway$.pipe(takeUntil(this.onDestroy$)).subscribe((ropeway) => {
      this.ropeway = ropeway;
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.canAccessAlarmLibrary = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_TELEMETRY, FeatureAccessLevel.READ))
      );
    });

    combineLatest([
      this.efaHistoryService.ropewayEfaHistory$.pipe(startWith([])),
      this.ropewayEfaService.efas$,
      this.selectedMapElementService.selectedRopeway$,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([efaHistory, alarms, ropeway]) => {
        this.update(ropeway, alarms);

        if (this.efa) {
          this.efaHistory = efaHistory.filter((historyItem) => historyItem.timestampMs < this.efa.timestampMs);
        } else {
          this.efaHistory = efaHistory;
        }

        this.hasHistoryItems = this.efaHistory.length > 0;
      });
  }

  private update(ropeway: Ropeway, efas: Alarm[]): void {
    if (!efas) {
      return;
    }

    if (!ropeway) {
      this.resetToDefault();
      return;
    }

    const activeEfas = efas.filter((efa: Alarm) => efa.active && efa.sisId === ropeway.sisId);

    if (activeEfas && activeEfas.length > 0) {
      const fault = activeEfas.find((a) => a.level === AlarmLevel.FAULT);
      this.efa = fault ? fault : activeEfas[0];
      this.hasActiveAlarm = true;
    } else {
      this.resetToDefault();
    }
  }

  private resetToDefault(): void {
    this.efa = null;
    this.hasActiveAlarm = false;
  }

  async openAlarmLibrary() {
    if (!this.ropeway || !this.ropeway.sisId || !this.canAccessAlarmLibrary) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: AlarmLibraryModal,
      componentProps: {
        sisId: this.ropeway.sisId,
        alias: this.ropeway.alias,
      },
    });
    return modal.present();
  }

  itemId(_: number, alarm: Alarm): number {
    return alarm.timestampMs;
  }
}
