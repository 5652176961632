import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { AbbSmartSensor } from 'src/app/maps/domain/abbsmartsensor.model';
import { AbbSmartSensorConditionStatus, AbbSmartSensorData } from 'src/app/maps/livedata/abbsmartsensor-data.model';
import { AbbSmartSensorDataService } from 'src/app/maps/livedata/abbsmartsensor-data.service';

@Component({
  selector: 'sis-abbsmartsensor-item',
  templateUrl: './abbsmartsensor-item.component.html',
  styleUrls: ['./abbsmartsensor-item.component.scss'],
})
export class AbbSmartSensorItemComponent extends Unsubscriber implements OnInit {
  static readonly cssClassGood = 'sis-abbsmartsensor-good';
  static readonly cssClassTolerable = 'sis-abbsmartsensor-tolerable';
  static readonly cssClassPoor = 'sis-abbsmartsensor-poor';
  static readonly cssClassUnknown = 'sis-abbsmartsensor-unknown';

  private abbSmartSensorData: AbbSmartSensorData;

  cssclassForAvailability: string = AbbSmartSensorItemComponent.cssClassUnknown;
  cssClassForEnvironment: string = AbbSmartSensorItemComponent.cssClassUnknown;
  cssClassForReliability: string = AbbSmartSensorItemComponent.cssClassUnknown;
  cssClassForStress: string = AbbSmartSensorItemComponent.cssClassUnknown;

  @Input() abbSmartSensor: AbbSmartSensor;

  constructor(private abbSmartSensorDataService: AbbSmartSensorDataService) {
    super();
  }

  ngOnInit() {
    this.abbSmartSensorDataService.abbSmartSensorDatas$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((abbSmartSensorDatas) => {
        this.abbSmartSensorData = abbSmartSensorDatas.find(
          (sensorData) => sensorData.assetId === this.abbSmartSensor.assetId
        );

        if (this.abbSmartSensorData) {
          this.cssclassForAvailability = this.getCssClass(this.abbSmartSensorData.conditionIndexForAvailability);
          this.cssClassForEnvironment = this.getCssClass(this.abbSmartSensorData.conditionIndexForEnvironment);
          this.cssClassForReliability = this.getCssClass(this.abbSmartSensorData.conditionIndexForReliability);
          this.cssClassForStress = this.getCssClass(this.abbSmartSensorData.conditionIndexForStress);
        }
      });
  }

  private getCssClass(conditionStatus: AbbSmartSensorConditionStatus): string {
    switch (conditionStatus) {
      case AbbSmartSensorConditionStatus.Good:
        return AbbSmartSensorItemComponent.cssClassGood;
      case AbbSmartSensorConditionStatus.Tolerable:
        return AbbSmartSensorItemComponent.cssClassTolerable;
      case AbbSmartSensorConditionStatus.Poor:
        return AbbSmartSensorItemComponent.cssClassPoor;
      default:
        return AbbSmartSensorItemComponent.cssClassUnknown;
    }
  }
}
