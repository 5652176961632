import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { FirstEntriesGroup } from 'src/app/maps/domain/firstentriesgroup.model';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { FirstEntriesData } from 'src/app/maps/livedata/first-entries.model';
import { FirstEntriesService } from 'src/app/maps/livedata/first-entries.service';
import { KassandraForecastData } from 'src/app/maps/livedata/kassandra-forecast.model';
import { KassandraForecastService } from 'src/app/maps/livedata/kassandra-forecast.service';
import { SisOutdatedHelper } from 'src/app/maps/livedata/sis-outdated-helper';
import { FirstEntriesDetailViewModel } from 'src/app/maps/widgets/detailviews/first-entries-detailview/first-entries-detailview.model';

@Component({
  selector: 'sis-first-entries-detailview',
  templateUrl: './first-entries-detailview.component.html',
  styleUrls: ['./first-entries-detailview.component.scss'],
})
export class FirstEntriesDetailViewComponent extends Unsubscriber implements OnInit {
  private firstEntriesDatas: FirstEntriesData[];
  private forecastDatas: KassandraForecastData[];
  private firstEntriesGroups: FirstEntriesGroup[];

  timestamp: Date;
  firstEntriesAvailable: boolean;
  forecastAvailable: boolean;
  frequenciesAvailable: boolean;
  dataForDetailView: FirstEntriesDetailViewModel[];

  constructor(
    private destinationService: DestinationService,
    private masterdataService: MasterdataService,
    private firstEntriesService: FirstEntriesService,
    private kassandraService: KassandraForecastService
  ) {
    super();
  }

  ngOnInit() {
    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.firstEntriesAvailable = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_FIRSTENTRIES, FeatureAccessLevel.READ))
      );
      this.forecastAvailable = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_KASSANDRA, FeatureAccessLevel.READ))
      );
      this.frequenciesAvailable = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_FREQUENCIES, FeatureAccessLevel.READ))
      );
    });

    this.masterdataService.masterData$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.firstEntriesGroups = data.firstEntriesGroups;
    });

    this.firstEntriesService.firstEntries$.pipe(takeUntil(this.onDestroy$)).subscribe((firstEntries) => {
      this.firstEntriesDatas = firstEntries ?? [];
      this.dataForDetailView = this.getDataForDetailView();
    });

    this.kassandraService.kassandra$.pipe(takeUntil(this.onDestroy$)).subscribe((kassandra) => {
      this.forecastDatas = kassandra ?? [];
      this.dataForDetailView = this.getDataForDetailView();
    });
  }

  private getDataForDetailView(): FirstEntriesDetailViewModel[] {
    const firstEntriesDetailViewDatas: FirstEntriesDetailViewModel[] = [];
    this.firstEntriesGroups.forEach((g) => {
      const data: FirstEntriesDetailViewModel = {
        measuredValue: null,
        frequenciesValue: null,
        forecastValue: null,
        measuredAt: new Date(),
        forecastAt: new Date(),
        name: g.name,
        timeoutInSeconds: 0,
        detailViewOrder: g.detailViewOrder,
        wholeDestination: g.wholeDestination,
        hasForecast: false,
      };
      g.rowKeys.forEach((rowKey: string) => {
        if (this.firstEntriesDatas) {
          const firstEntries = this.firstEntriesDatas.find((f) => f.rowKey === rowKey);
          if (firstEntries) {
            data.measuredValue += firstEntries.measuredValue;
            data.frequenciesValue += firstEntries.frequenciesValue;
            data.measuredAt = firstEntries.measuredAt < data.measuredAt ? firstEntries.measuredAt : data.measuredAt;
            data.timeoutInSeconds =
              firstEntries.timeoutInSeconds > data.timeoutInSeconds
                ? firstEntries.timeoutInSeconds
                : data.timeoutInSeconds;
          }
        }

        if (this.forecastDatas) {
          const forecast = this.forecastDatas.find((f) => f.rowKey === rowKey);
          if (forecast) {
            data.hasForecast = true;
            data.forecastValue += forecast.forecastValue;
            data.forecastAt = forecast.forecastAt < data.forecastAt ? forecast.forecastAt : data.forecastAt;
          }
        }
      });

      this.timestamp = data.measuredAt;

      if (SisOutdatedHelper.isOutdated(data.measuredAt, data.timeoutInSeconds * 1000)) {
        data.measuredValue = null;
        data.frequenciesValue = null;
      }
      if (!SisOutdatedHelper.dateIsToday(data.forecastAt)) {
        data.forecastValue = null;
      }

      firstEntriesDetailViewDatas.push(data);
    });

    firstEntriesDetailViewDatas.sort((a, b) => a.detailViewOrder - b.detailViewOrder);
    return firstEntriesDetailViewDatas;
  }
}
