import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FirstEntriesGroup } from 'src/app/maps/domain/firstentriesgroup.model';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { FirstEntriesData } from 'src/app/maps/livedata/first-entries.model';
import { FirstEntriesService } from 'src/app/maps/livedata/first-entries.service';
import { SisOutdatedHelper } from 'src/app/maps/livedata/sis-outdated-helper';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-first-entries-widget',
  templateUrl: './first-entries-widget.component.html',
  styleUrls: ['./first-entries-widget.component.scss'],
})
export class FirstEntriesWidgetComponent extends WidgetBase implements OnInit {
  private groups: FirstEntriesGroup[] = [];

  firstEntriesTotal = WidgetBase.valueUnknown;

  constructor(private masterdataService: MasterdataService, private firstEntriesService: FirstEntriesService) {
    super();
  }

  ngOnInit() {
    this.masterdataService.masterData$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.groups = data.firstEntriesGroups;
    });

    this.firstEntriesService.firstEntries$.pipe(takeUntil(this.onDestroy$)).subscribe((firstEntries) => {
      this.firstEntriesTotal = this.getFirstEntriesTotal(firstEntries);
    });
  }

  private getFirstEntriesTotal(firstEntriesDatas: FirstEntriesData[]): string {
    const data: FirstEntriesData = {
      measuredValue: 0,
      frequenciesValue: 0,
      measuredAt: new Date(),
      timeoutInSeconds: 0,
    };
    const group = this.groups.find((g) => g.wholeDestination === true);
    if (!group) {
      return WidgetBase.valueUnknown;
    }

    group.rowKeys.forEach((rowKey: string) => {
      const firstEntries = firstEntriesDatas.find((f) => f.rowKey === rowKey);
      if (firstEntries) {
        data.measuredValue += firstEntries.measuredValue;
        data.measuredAt = firstEntries.measuredAt < data.measuredAt ? firstEntries.measuredAt : data.measuredAt;
        data.timeoutInSeconds =
          firstEntries.timeoutInSeconds > data.timeoutInSeconds ? firstEntries.timeoutInSeconds : data.timeoutInSeconds;
      }
    });

    return SisOutdatedHelper.isOutdated(data.measuredAt, data.timeoutInSeconds * 1000)
      ? WidgetBase.valueUnknown
      : data.measuredValue.toString();
  }
}
