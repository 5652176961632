import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { SisMediaAssetEditUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { SisMediaAssetCategory } from 'src/app/sismedia/domain/sismedia-asset-category.enum';
import { SisMediaItem } from 'src/app/sismedia/domain/sismedia-item.model';
import { SisMediaItemOrder } from 'src/app/sismedia/domain/sismedia-item-order.model';
import { SisMediaRouteParams } from 'src/app/sismedia/domain/sismedia-route-params.model';
import { SisMediaSeason } from 'src/app/sismedia/domain/sismedia-season.enum';
import { SisMediaEditModalComponent } from 'src/app/sismedia/sismedia-edit-modal/sismedia-edit-modal.component';
import { SisMediaItemService } from 'src/app/sismedia/sismedia-item/sismedia-item.service';
import { v4 as v4guid } from 'uuid';

@Component({
  selector: 'sis-sismedia-category',
  templateUrl: './sismedia-category.component.html',
  styleUrls: ['./sismedia-category.component.scss'],
})
export class SismediaCategoryComponent extends Unsubscriber implements OnInit {
  @Input() options: SisMediaRouteParams;

  readonly seasonIcons = [
    {
      season: SisMediaSeason.SummerAndWinter,
      iconName: 'sis-summer-and-winter-grey',
      iconNameSelected: 'sis-summer-and-winter-secondary',
      translateKey: 'general.term.summerAndWinter',
    },
    {
      season: SisMediaSeason.Summer,
      iconName: 'sis-summer-grey',
      iconNameSelected: 'sis-summer-secondary',
      translateKey: 'general.term.summer',
    },
    {
      season: SisMediaSeason.Winter,
      iconName: 'sis-winter-grey',
      iconNameSelected: 'sis-winter-secondary',
      translateKey: 'general.term.winter',
    },
  ];

  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;
  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;
  readonly isAdministrator$ = this.userSettingsService.userSettings$.pipe(map((settings) => settings.isAdministrator));
  readonly selectedSeason$ = this.userSettingsService.userSettings$.pipe(map((settings) => settings.selectedSeason));

  filterControl = new FormControl<string>('');

  displayedItems: SisMediaItem[];
  lastChange: { date: Date; name: string };

  anyItemHasInfo: boolean;
  anyItemHasSisMaps: boolean;

  hasAssetEditPermission: boolean;
  reorderingEnabled: boolean;
  labelMaxLength: number;
  featureId: FeatureId[][];

  private readonly sisMediaItems$ = this.sisMediaItemService.sisMediaItems$.pipe(
    map((items) => items.get(this.options.assetCategory)),
    map((items) => this.filterByItemType(items, this.options.includedItemTypes, this.options.excludedItemTypes)),
    map((items) => items.sort(this.sortItems))
  );

  private readonly reorderEnabled$ = new BehaviorSubject<boolean>(false);
  private readonly assetEditWriteFeature = new Feature(FeatureId.SISMEDIA_ASSET_EDIT, FeatureAccessLevel.WRITE);
  private assetCategoryWriteFeature: Feature;
  private sisMediaItemOrders: Map<string, number>;
  private allItems: SisMediaItem[];

  constructor(
    private sisMediaItemService: SisMediaItemService,
    private logbookModalService: LogbookModalService,
    private userSettingsService: UserSettingsService,
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService,
    private eventBus: EventBusService,
    private modalCtrl: ModalController
  ) {
    super();
  }

  ngOnInit(): void {
    this.assetCategoryWriteFeature = new Feature(this.options.featureId, FeatureAccessLevel.WRITE);

    combineLatest([
      this.sisMediaItems$,
      this.userSettingsService.userSettings$,
      this.reorderEnabled$,
      this.filterControl.valueChanges.pipe(debounceTime(300), startWith<string>('')),
    ])
      .pipe(
        map(([items, settings, reorderEnabled, search]) =>
          reorderEnabled
            ? items
            : items.filter(
                (item) =>
                  (settings.selectedSeason === SisMediaSeason.SummerAndWinter ||
                    item.season === SisMediaSeason.SummerAndWinter ||
                    item.season === settings.selectedSeason) &&
                  this.containsSearchStringInItemNameOrLabel(item, search)
              )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((items) => {
        this.displayedItems = items;
        this.updateLabelSize(items.map((i) => i.label));
        this.anyItemHasInfo = this.containsItemsWithInfo(this.displayedItems);
        this.anyItemHasSisMaps = this.containsItemsWithSisMaps(this.displayedItems);
      });

    this.sisMediaItems$.pipe(takeUntil(this.onDestroy$)).subscribe((items) => {
      this.allItems = items;
      this.setLastChange(items);
    });

    this.eventBus
      .observe(SisMediaAssetEditUpdatedEvent)
      .pipe(
        filter((event) => event.updateSuccessful),
        takeUntil(this.onDestroy$)
      )
      .subscribe((event) => {
        this.anyItemHasInfo = this.containsItemsWithInfo(this.displayedItems);
        this.anyItemHasSisMaps = this.containsItemsWithSisMaps(this.displayedItems);
        this.updateLabelSize(this.displayedItems.map((i) => i.label).concat(event.assetEdits.map((a) => a.label)));
      });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.hasAssetEditPermission =
        features.some((f) => f.hasMinimumRequirementFor(this.assetCategoryWriteFeature)) &&
        features.some((f) => f.hasMinimumRequirementFor(this.assetEditWriteFeature));
    });

    this.sisMediaItems$
      .pipe(
        switchMap(() =>
          this.sisMediaItemService.sisMediaCategoryItemOrderUpdated$.pipe(
            filter((updatedCategories) => updatedCategories.includes(this.options.assetCategory))
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => this.displayedItems.sort(this.sortItems));

    this.featureId = [
      [this.options.featureId],
      [FeatureId.SISMEDIA_ASSET_EDIT, this.options.featureId],
      [FeatureId.SISMEDIA_OPERATINGHOURS, this.options.featureId],
    ];
  }

  private updateLabelSize(labels: string[]) {
    this.labelMaxLength = Math.max(0, ...labels.filter((l) => !!l).map((l) => l.length));
  }

  async seasonSelected(season: SisMediaSeason): Promise<void> {
    await this.userSettingsService.updateUserSettings({
      selectedSeason: season,
    });
  }

  async onReorderClick(): Promise<void> {
    if (this.reorderingEnabled) {
      const reorderedItems: SisMediaItemOrder[] = this.allItems
        .filter((item) => this.sisMediaItemOrders.get(item.assetGuid) !== item.cockpitOrder)
        .map((item) => ({
          assetGuid: item.assetGuid,
          assetCategory: item.assetCategory,
          cockpitOrder: item.cockpitOrder,
        }));

      await this.sisMediaItemService.reorderItems(reorderedItems);
    } else {
      this.sisMediaItemOrders = new Map<string, number>(this.allItems.map((item) => [item.assetGuid, item.cockpitOrder]));
    }

    this.reorderEnabled$.next(!this.reorderingEnabled);
    this.reorderingEnabled = !this.reorderingEnabled;
  }

  async onReorderCancelClick() {
    this.displayedItems.forEach((item) => (item.cockpitOrder = this.sisMediaItemOrders.get(item.assetGuid)));
    this.displayedItems.sort(this.sortItems);
    this.reorderEnabled$.next(false);
    this.reorderingEnabled = false;
  }

  async openEditModal() {
    if (this.hasAssetEditPermission) {
      const sisMediaItem: any = {
        assetGuid: v4guid(),
        assetCategory: this.options.assetCategory,
      };
      const modal = await this.modalCtrl.create({
        component: SisMediaEditModalComponent,
        componentProps: {
          isNewItem: true,
          sisMediaItem: sisMediaItem,
          category: this.options.titleTranslationString.split('.')[2],
        },
        cssClass: `sis-sismedia-edit-modal-${SisMediaAssetCategory[sisMediaItem.assetCategory].toLowerCase()}`,
        backdropDismiss: false,
      });

      return modal.present();
    }
  }

  doReorder(ev: any) {
    this.allItems = ev.detail.complete(this.displayedItems);
    this.allItems.forEach((item, index) => (item.cockpitOrder = index));
  }

  private setLastChange(items: SisMediaItem[]): void {
    if (!items || items.length === 0) {
      return null;
    }

    const lastChangedItem = items.reduce((p, c) => (c.lastStatusChange > p.lastStatusChange ? c : p));

    this.lastChange = { date: lastChangedItem.lastStatusChange, name: lastChangedItem.name };
  }

  private sortItems(a: SisMediaItem, b: SisMediaItem): number {
    const order = a.cockpitOrder - b.cockpitOrder;
    if (order !== 0) {
      return order;
    }

    if (a.label && b.label) {
      const label = a.label.localeCompare(b.label);
      if (label !== 0) {
        return label;
      }
    }

    return a.name.localeCompare(b.name);
  }

  private filterByItemType(items: SisMediaItem[], includedItemTypes: string[], excludedItemTypes: string[]): SisMediaItem[] {
    if (includedItemTypes && includedItemTypes.length > 0) {
      items = items.filter((item) => includedItemTypes.includes(item.type));
    }

    if (excludedItemTypes && excludedItemTypes.length > 0) {
      items = items.filter((item) => !excludedItemTypes.includes(item.type));
    }

    return items;
  }

  private containsItemsWithInfo(items: SisMediaItem[]): boolean {
    return items.some((item) => item.info?.length > 0);
  }

  private containsItemsWithSisMaps(items: SisMediaItem[]): boolean {
    return items.some((item) => item.sisMapNames?.length > 0);
  }

  private containsSearchStringInItemNameOrLabel(item: SisMediaItem, search: string): boolean {
    const searchString = search ? search.toLowerCase() : undefined;
    if (searchString != null) {
      return item.name.toLowerCase().includes(searchString) || item.label?.toLowerCase()?.includes(searchString);
    }

    return true;
  }
}
