import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { EnergyLiveData } from 'src/app/maps/livedata/energy-livedata.model';
import { EnergyLiveDataService } from 'src/app/maps/livedata/energy-livedata.service';

@Component({
  selector: 'sis-energy-detailview',
  templateUrl: './energy-detailview.component.html',
  styleUrls: ['./energy-detailview.component.scss'],
})
export class EnergyDetailViewComponent extends Unsubscriber implements OnInit {
  private production = 'energy.term.production';
  private consumption = 'energy.term.consumption';

  missing: EnergyLiveData[] = [];
  categories = new Map<string, number>();
  allMissing: boolean;

  constructor(private energyLiveDataService: EnergyLiveDataService) {
    super();
  }

  ngOnInit() {
    this.update([]);
    this.energyLiveDataService.energy$.pipe(takeUntil(this.onDestroy$)).subscribe((energyLiveDatas) => {
      this.update(energyLiveDatas);
    });
  }

  private update(liveDatas: EnergyLiveData[]): void {
    const sumProd = liveDatas.reduce(
      (sum: number, current: EnergyLiveData) =>
        (sum += !current.outdated && current.powerW < 0 ? current.powerW / 1000 : 0),
      0
    );
    this.categories.set(this.production, Math.round(Math.abs(sumProd)));

    const sumCons = liveDatas.reduce(
      (sum: number, current: EnergyLiveData) =>
        (sum += !current.outdated && current.powerW > 0 ? current.powerW / 1000 : 0),
      0
    );
    this.categories.set(this.consumption, Math.round(Math.abs(sumCons)));

    this.missing = liveDatas.filter((d) => d.outdated || d.powerW == null);
    this.allMissing = liveDatas.length === this.missing.length;
  }
}
