import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';

@Component({
  selector: 'sis-feature-management-item',
  templateUrl: './feature-management-item.component.html',
  styleUrls: ['./feature-management-item.component.scss'],
})
export class FeatureManagementItemComponent implements OnInit {
  @Input() feature: any;
  @Output() featureChanged = new EventEmitter<void>();

  activeDisabled: boolean;

  ngOnInit(): void {
    this.activeDisabled = this.feature.id === FeatureId.USERMANAGEMENT && this.feature.active;
  }

  onChanged() {
    this.featureChanged.emit();
  }
}
