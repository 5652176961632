import { Component, OnInit } from '@angular/core';
import { map, switchMap, takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { GinLivedataService } from 'src/app/maps/livedata/gin-livedata.service';
import { GinDetailViewDisplayData } from 'src/app/maps/widgets/detailviews/gin-detailview/gin-detailview-displaydata.model';

@Component({
  selector: 'sis-gin-detailview',
  templateUrl: './gin-detailview.component.html',
  styleUrls: ['./gin-detailview.component.scss'],
})
export class GINDetailViewComponent extends Unsubscriber implements OnInit {
  displayDatas: GinDetailViewDisplayData[] = [];

  constructor(private masterDataService: MasterdataService, private ginLiveDataService: GinLivedataService) {
    super();
  }

  ngOnInit(): void {
    this.masterDataService.masterData$
      .pipe(
        switchMap((masterData) =>
          this.ginLiveDataService.ginLiveDatas$.pipe(map((ginLiveDatas) => ({ masterData, ginLiveDatas })))
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ masterData, ginLiveDatas }) => {
        if (!masterData || !ginLiveDatas) {
          this.displayDatas = [];
          return;
        }

        const ginConfigurations = masterData.ginConfigurations.filter((m) => m.detailViewOrder != null);

        const ginIds = ginConfigurations.map((m) => m.id);

        const ginLiveData = ginLiveDatas.filter((l) => ginIds.includes(l.id));

        this.displayDatas = ginConfigurations
          .map(
            (m) =>
              new GinDetailViewDisplayData(
                m,
                ginLiveData.find((l) => l.id === m.id)
              )
          )
          .sort((a, b) => a.order - b.order);
      });
  }

  trackByGinName(ginDatay: GinDetailViewDisplayData): string {
    return ginDatay.ginName;
  }
}
