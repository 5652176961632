<ion-grid class="sis-input-grid">
  <ion-row>
    <ion-col size="auto">
      <ion-input
        #nameInput
        size="80"
        [value]="this.slide.name ? this.slide.name.replace('.html', '') : ''"
        class="sis-global-input sis-global-text-input"
        type="text"
        data-test="name-input"
        [placeholder]='"mediacenter.term.namePlaceholder" | translate'
        (keyup)="this.slideNameChangedEvent(nameInput.value)"
        maxlength="100"
      />
    </ion-col>
    <ion-col size="auto">
      <sis-general-button
        [text]="'general.term.save'"
        [btnType]="'Secondary'"
        (sisClick)="this.save(nameInput.value)"
        [disabled]="this.slideExists || this.slideNameIsEmpty || !this.slideChanged"
        width="150px"
        data-test="ckeditor-save-button"
      ></sis-general-button>
    </ion-col>
    <ion-col size="auto">
      <sis-general-button
        [text]="'general.term.close'"
        [btnType]="'Secondary'"
        (sisClick)="this.close()"
        width="150px"
        data-test="ckeditor-close-button"
      ></sis-general-button>
    </ion-col>
    <ion-col size="auto">
      <sis-general-button
        [text]="'general.term.saveAsCopy'"
        [btnType]="'Secondary'"
        (sisClick)="this.showSaveAsCopyModal(nameInput.value)"
        width="150px"
        data-test="ckeditor-saveascopy-button"
      ></sis-general-button>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="sis-invalid-name">
      <ng-container *ngIf="this.slideExists"> {{ "mediacenter.phrase.slideExists" | translate }} </ng-container>
    </ion-col>
  </ion-row>
</ion-grid>

<ckeditor
  *ngIf="this.ckEditorData"
  editorUrl="/assets/ckeditor/ckeditor.js"
  [data]="this.ckEditorData"
  (ready)="this.setEditor($event)"
  (change)="this.slideChangedEvent()"
  [config]="{templates_files: [this.templateUrl]}"
></ckeditor>
