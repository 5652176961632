import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, merge, Observable, of, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MediaCenterPlaylistUpdatedEvent } from 'src/app/core/eventbus/events';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { PlaylistAdapter } from 'src/app/media-center/playlists/domain/playlist.adapter';
import { Playlist } from 'src/app/media-center/playlists/domain/playlist.model';
import { Slide } from 'src/app/media-center/slides/domain/slide.model';
import { MediaCenterSlidesService } from 'src/app/media-center/slides/media-center-slides.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaCenterPlaylistsService {
  private static readonly requestPlaylistUrl = '/mediacenter/playlists';
  private static readonly updatePlaylistUrl = '/mediacenter/playlist';

  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.READ);
  private readonly ownUpdateCompleted$ = new Subject<[Playlist, boolean]>();

  private readonly playlistsRequest$: Observable<Playlist[]> = this.destinationService.selectedTenantFeatures$.pipe(
    switchMap((features) => {
      if (features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))) {
        return this.http.get(`${environment.baseUrlApi}${MediaCenterPlaylistsService.requestPlaylistUrl}`);
      }
      return of([]);
    }),
    map((data) => PlaylistAdapter.adapt(data)),
    switchMap((playlists) =>
      this.slideService.slides$.pipe(
        map((slides) => {
          slides.forEach((slide) => (slide.inPlaylist = false));
          playlists
            .filter((playlist) => playlist.slideMappings != null)
            .forEach((playlist) => {
              playlist.slideMappings.forEach((slideMapping) => {
                const slide = slides.find((s) => s.guid === slideMapping.slideGuid);
                slideMapping.slide = slide;
                if (slide) {
                  slide.inPlaylist = true;
                }
              });
            });
          return playlists;
        })
      )
    ),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  private readonly playlistUpdate$: Observable<Playlist[]> = this.eventBus
    .observe(MediaCenterPlaylistUpdatedEvent)
    .pipe(
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([updatedEvent, tenant]) => updatedEvent?.tenantGuid === tenant?.guid),
      withLatestFrom(this.userSettingsService.userSettings$, this.playlistsRequest$, this.slideService.slides$),
      map(([[updatedEvent], userSettings, playlists, slides]) => {
        if (updatedEvent.changedBy === userSettings.userGuid) {
          this.presentToast(updatedEvent.success);
        }

        return this.handlePlaylistUpdate(updatedEvent, playlists, userSettings.userGuid, slides);
      })
    );

  readonly playlists$: Observable<Playlist[]> = merge(this.playlistsRequest$, this.playlistUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly ownUpdateCompleted: Observable<[Playlist, boolean]> = this.ownUpdateCompleted$.asObservable();

  readonly unsavedChanges$ = new BehaviorSubject<boolean>(false);

  constructor(
    private eventBus: EventBusService,
    private http: HttpClient,
    private destinationService: DestinationService,
    private userMessageService: UserMessageService,
    private userSettingsService: UserSettingsService,
    private slideService: MediaCenterSlidesService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  updatePlaylist(playlist: Playlist): void {
    this.update(playlist, false);
  }

  async deletePlaylist(playlist: Playlist): Promise<void> {
    let confirmed = await this.confirmationDialogService.presentAlert(
      'mediacenter.phrase.deletePlaylist',
      'mediacenter.term.delete'
    );

    if (confirmed) {
      if (playlist.assignedToScreen) {
        confirmed = await this.confirmationDialogService.presentAlert(
          'mediacenter.phrase.playlistInUse',
          'mediacenter.phrase.deleteAnyway'
        );

        if (confirmed) {
          this.update(playlist, true);
        }
      } else {
        this.update(playlist, true);
      }
    }
  }

  private async update(playlist: Playlist, deleting: boolean): Promise<void> {
    try {
      await firstValueFrom(
        this.http.post(`${environment.baseUrlApi}${MediaCenterPlaylistsService.updatePlaylistUrl}`, {
          playlist,
          delete: deleting,
        })
      );
    } catch {
      playlist.updating = false;
      await this.presentToast(false);
    }
  }

  private handlePlaylistUpdate(
    updatedEvent: MediaCenterPlaylistUpdatedEvent,
    playlists: Playlist[],
    userGuid: string,
    slides: Slide[]
  ): Playlist[] {
    const playlist = updatedEvent.playlist;
    const success = updatedEvent.success;
    const removed = updatedEvent.removed;
    const updatedPlaylist = playlists.find((p) => p.guid === playlist.guid);

    if (playlist.slideMappings != null) {
      playlist.slideMappings.forEach((slideMapping) => {
        const slide = slides.find((s) => s.guid === slideMapping.slideGuid);
        slideMapping.slide = slide;
        if (slide) {
          slide.inPlaylist = true;
        }
      });
    }

    if (updatedPlaylist) {
      updatedPlaylist.updating = false;

      if (success) {
        if (removed) {
          playlists.splice(
            playlists.findIndex((p) => p.guid === updatedPlaylist.guid),
            1
          );
        } else {
          updatedPlaylist.name = playlist.name;
          updatedPlaylist.slideMappings = playlist.slideMappings;
          updatedPlaylist.isNew = false;
          updatedPlaylist.fadeInEnabled = playlist.fadeInEnabled;
          updatedPlaylist.backgroundColor = playlist.backgroundColor;
        }
      }
    } else {
      if (!removed && success) {
        playlists.push(playlist);
      }
    }

    if (userGuid === updatedEvent.changedBy) {
      this.ownUpdateCompleted$.next([playlist, success]);
    }

    return playlists;
  }

  private async presentToast(success: boolean): Promise<void> {
    const message = success ? 'mediacenter.phrase.settingsSaved' : 'general.phrase.saveFailed';
    const color = success ? UserMessageColor.green : UserMessageColor.red;
    const icon = success ? UserMessageIcon.success : UserMessageIcon.failed;
    await this.userMessageService.presentToast(
      new UserMessage({ message, icon, durationMs: 2000, position: 'top', color })
    );
  }
}
