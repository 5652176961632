import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { EnergyLiveDataAdapter } from 'src/app/maps/livedata/energy-livedata.adapter';
import { EnergyLiveData } from 'src/app/maps/livedata/energy-livedata.model';

@Injectable({
  providedIn: 'root',
})
export class EnergyLiveDataService extends LivedataServiceBase<EnergyLiveData> {
  protected readonly endpointUri = 'livedata/energy';
  protected readonly requiredFeatures = [
    new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ),
    new Feature(FeatureId.COCKPIT_ENERGY, FeatureAccessLevel.READ),
  ];

  readonly energy$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, EnergyLiveDataAdapter.adapt, destinationService);
  }
}
