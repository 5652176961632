<ion-header>
  <ion-toolbar style="overflow: hidden">
    <ion-title>{{ this.title }}</ion-title>
    <img *ngIf="this.showExternalImageOverlay" slot="end" style="max-height: 24px" src="assets/images/www.png" />
    <ion-button slot="end" fill="clear" (click)="closeModal()">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <sis-webcam
    [isModal]="true"
    [updateInterval]="5000"
    [startIndex]="this.startIndex"
    (imageIndex)="this.setImageIndex($event)"
  ></sis-webcam>
</ion-content>
