import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { EnergyLiveData } from 'src/app/maps/livedata/energy-livedata.model';
import { EnergyLiveDataService } from 'src/app/maps/livedata/energy-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';

@Component({
  selector: 'sis-ropeway-energy-detailview',
  templateUrl: './ropeway-energy-detailview.component.html',
  styleUrls: ['./ropeway-energy-detailview.component.scss'],
})
export class RopewayEnergyDetailViewComponent extends Unsubscriber implements OnInit {
  devices: Array<{ displayName: string; power: string }> = [];
  totalPower: string;
  hasEnergyWh10Sec: boolean;

  constructor(
    private energyLiveDataService: EnergyLiveDataService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([this.energyLiveDataService.energy$, this.selectedMapElementService.selectedRopeway$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([energyLiveDatas, ropeway]) => {
        if (ropeway) {
          energyLiveDatas = energyLiveDatas.filter((d) => d.parentSisId === ropeway.sisId);
        }

        this.update(energyLiveDatas);
      });
  }

  trackByDisplayName(device: { displayName: string; power: string }): string {
    return device.displayName;
  }

  private update(liveDatas: EnergyLiveData[]): void {
    this.totalPower =
      !liveDatas.length || liveDatas.every((e) => e.outdated)
        ? '?'
        : Math.round(
            liveDatas
              .filter((e) => !e.outdated && e.powerW != null)
              .reduce((sum: number, current: EnergyLiveData) => (sum += current.powerW / 1000), 0)
          ) + ' kW';

    this.devices = liveDatas
      .map((e) => ({
        displayName: e.displayName,
        power: e.outdated || e.powerW == null ? '?' : Math.round(e.powerW / 1000) + ' kW',
      }))
      .sort((a, b) => a.displayName?.localeCompare(b.displayName));

    this.hasEnergyWh10Sec = liveDatas.some((d) => d.energyWh10Sec != null);
  }
}
