import { SlideMapping } from 'src/app/media-center/playlists/domain/slide-mapping.model';

export class Playlist {
  slideMappings: SlideMapping[] = [];
  updating: boolean = false;
  assignedToScreen: boolean;
  edited: boolean;
  isNew: boolean;

  constructor(
    public guid: string,
    public name: string,
    public fadeInEnabled: boolean,
    public backgroundColor: string
  ) {}

  isEqual(playlist: Playlist): boolean {
    let result = this.guid === playlist.guid && this.name === playlist.name;

    if (result) {
      result = this.slideMappings.every((s) =>
        playlist.slideMappings.find(
          (p) => p.duration === s.duration && p.order === s.order && p.slideGuid === s.slideGuid
        )
      );
    }

    return result;
  }
}
