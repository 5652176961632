import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayOperationWidgetBase } from 'src/app/maps/widgets/ropeway-operation-widget-base';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-ophours-widget',
  templateUrl: './ophours-widget.component.html',
  styleUrls: ['./ophours-widget.component.scss'],
})
export class OpHoursWidgetComponent extends RopewayOperationWidgetBase implements OnInit {
  operatingHours: string;

  constructor(ropewayLiveDataService: RopewayLivedataService, selectedMapElementService: SelectedMapElementService) {
    super(ropewayLiveDataService, selectedMapElementService);
    this.showOutdatedData = true;
  }

  ngOnInit(): void {
    this.ropewayLiveData$.pipe(takeUntil(this.onDestroy$)).subscribe((d) => {
      if (d.liveData?.operatingHours == null) {
        this.resetToDefault();
        return;
      }

      this.operatingHours = (Math.round(d.liveData.operatingHours * 10) / 10).toString();
    });
  }

  private resetToDefault(): void {
    this.operatingHours = WidgetBase.valueUnknown;
  }
}
