import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MeteoLivedataAdapter } from 'src/app/maps/livedata/meteo-livedata.adapter';
import { MeteoLivedata } from 'src/app/maps/livedata/meteo-livedata.model';

@Injectable({
  providedIn: 'root',
})
export class MeteoLivedataService extends LivedataServiceBase<MeteoLivedata> {
  protected readonly endpointUri = 'livedata/meteo';
  protected readonly requiredFeatures = [new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ)];

  readonly meteoLiveDatas$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, MeteoLivedataAdapter.adapt, destinationService);
  }
}
