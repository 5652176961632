<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 187.5 225"
  (click)="openLink()"
>
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <path
          d="M150,75a18.76,18.76,0,0,1-13.07,17.88,16.06,16.06,0,0,0-18.09-4.78,26.64,26.64,0,0,0-6.33-13.38A18.75,18.75,0,0,1,150,75Z"
          style="fill: none;stroke: #91cddb;stroke-miterlimit: 10;stroke-width: 4px"
        />
        <line
          x1="131.25"
          y1="37.5"
          x2="131.25"
          y2="52.5"
          style="fill: none;stroke: #91cddb;stroke-miterlimit: 10;stroke-width: 4px"
        />
        <line
          x1="153.75"
          y1="75"
          x2="168.75"
          y2="75"
          style="fill: none;stroke: #91cddb;stroke-miterlimit: 10;stroke-width: 4px"
        />
        <line
          x1="142.5"
          y1="94.48"
          x2="150"
          y2="107.48"
          style="fill: none;stroke: #91cddb;stroke-miterlimit: 10;stroke-width: 4px"
        />
        <line
          x1="112.5"
          y1="42.52"
          x2="120"
          y2="55.52"
          style="fill: none;stroke: #91cddb;stroke-miterlimit: 10;stroke-width: 4px"
        />
        <line
          x1="150.73"
          y1="63.75"
          x2="163.73"
          y2="56.25"
          style="fill: none;stroke: #91cddb;stroke-miterlimit: 10;stroke-width: 4px"
        />
        <line
          x1="150.73"
          y1="86.25"
          x2="163.73"
          y2="93.75"
          style="fill: none;stroke: #91cddb;stroke-miterlimit: 10;stroke-width: 4px"
        />
        <line
          x1="98.77"
          y1="56.25"
          x2="111.77"
          y2="63.75"
          style="fill: none;stroke: #91cddb;stroke-miterlimit: 10;stroke-width: 4px"
        />
        <line
          x1="142.5"
          y1="55.52"
          x2="150"
          y2="42.52"
          style="fill: none;stroke: #91cddb;stroke-miterlimit: 10;stroke-width: 4px"
        />
        <path
          d="M140.62,103.12a16.06,16.06,0,0,1-16.06,16.07h-75A21.43,21.43,0,1,1,66.7,84.91a26.78,26.78,0,0,1,52.14,3.19,16.07,16.07,0,0,1,21.78,15Z"
          style="fill: none;stroke: #989898;stroke-miterlimit: 10;stroke-width: 4px"
        />
        <rect width="187.5" height="225" style="fill: none" />
      </g>
    </g>
  </g>
</svg>
