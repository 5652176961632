import moment from 'moment';
import { TimetableInterval } from 'src/app/timetable/domain/timetable-interval.model';
import { TimetableIntervalDayAdapter } from 'src/app/timetable/domain/timetable-interval-day.adapter';

export class TimetableIntervalAdapter {
  static adapt(items: any): TimetableInterval[] {
    if (items) {
      return (items as any[]).map((interval: any) => ({
        guid: interval.guid,
        trackGuid: interval.trackGuid,
        name: interval.name,
        interval: interval.interval,
        operatingStart: TimetableIntervalAdapter.formatTime(interval.operatingStart),
        operatingEnd: TimetableIntervalAdapter.formatTime(interval.operatingEnd),
        intervalDays: (interval.days as any[]).map((day: any) => TimetableIntervalDayAdapter.adapt(day)),
      }));
    }

    return [];
  }

  private static formatTime(time: string) {
    return moment(time, 'HH:mm:ss').format('HH:mm');
  }
}
