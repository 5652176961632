import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { interval } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DateTimeFormatPipe } from 'src/app/core/utils/date-time-format.pipe';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayStatusTime } from 'src/app/maps/livedata/ropeway-status-time.model';
import { RopewayStatusTimeService } from 'src/app/maps/livedata/ropeway-status-time.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayTripLog } from 'src/app/maps/widgets/detailviews/stoptime-detailview/ropeway-triplog.model';
import { RopewayTripLogService } from 'src/app/maps/widgets/detailviews/stoptime-detailview/ropeway-triplog.service';
import { StoptimeDetailViewItem } from 'src/app/maps/widgets/detailviews/stoptime-detailview/stoptime-detailview-item';

@Component({
  selector: 'sis-stoptime-detailview',
  templateUrl: './stoptime-detailview.component.html',
  styleUrls: ['./stoptime-detailview.component.scss'],
})
export class StoptimeDetailViewComponent extends Unsubscriber implements OnInit {
  private readonly startThresholdMilliseconds = 2 * 60 * 1000;

  private statusTime: RopewayStatusTime;
  private isIphone: boolean;

  displayedLogs: StoptimeDetailViewItem[];
  offsetSpaToApiMilliseconds: number;

  constructor(
    private tripLogService: RopewayTripLogService,
    private masterdataService: MasterdataService,
    private screenSizeService: ScreenSizeService,
    private navigatorService: NavigatorService,
    private dateFormatPipe: DateTimeFormatPipe,
    private ropewayStatusTimeService: RopewayStatusTimeService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
    this.isIphone = this.navigatorService.isIphone();
  }

  ngOnInit(): void {
    interval(1000)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.displayedLogs?.length > 0) {
          this.displayedLogs[0].updateDisplayValues();
        }
      });

    this.selectedMapElementService.selectedRopeway$
      .pipe(
        filter((ropeway) => !!ropeway),
        switchMap((ropeway) =>
          this.ropewayStatusTimeService.ropewayStatusTimes$.pipe(
            filter((ropewayStatusTimes) => ropewayStatusTimes != null),
            map((ropewayStatusTimes) => ropewayStatusTimes.find((d) => d.deviceSisId === ropeway.sisId))
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((statusTime) => {
        this.statusTime = statusTime;
      });

    this.masterdataService.masterData$
      .pipe(
        filter((masterData) => !!masterData),
        takeUntil(this.onDestroy$)
      )
      .subscribe((masterData) => {
        this.offsetSpaToApiMilliseconds = masterData.offsetSpaToApiMilliseconds;
      });

    this.selectedMapElementService.selectedRopeway$
      .pipe(
        filter((ropeway) => !!ropeway),
        switchMap((ropeway) => this.tripLogService.ropewayTripLogs$.pipe(map((tripLogs) => ({ ropeway, tripLogs })))),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ ropeway, tripLogs }) => {
        this.updateDisplayData(ropeway, tripLogs);
        this.doScrollMagic();
      });
  }

  private updateDisplayData(ropeway: Ropeway, ropewayTripLogs: RopewayTripLog[]): void {
    if (!ropewayTripLogs || !ropeway) {
      return;
    }

    const tripLogs = ropewayTripLogs
      .filter((d) => d.deviceSisId === ropeway.sisId && this.isTodayLocalTime(d.startTime))
      .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

    if (tripLogs.length === 0) {
      return;
    }

    const newDisplayedLogs = [];

    for (let i = 0; i < tripLogs.length - 1; i++) {
      const tripLogEntry = tripLogs[i];
      const nextTripLogEntry = tripLogs[i + 1];

      const displayedTripEntry = new StoptimeDetailViewItem(
        this.offsetSpaToApiMilliseconds,
        this.dateFormatPipe,
        tripLogEntry.startTime,
        tripLogEntry.stopTime,
        null,
        null,
        null,
        false
      );

      const displayedStopEntry = new StoptimeDetailViewItem(
        this.offsetSpaToApiMilliseconds,
        this.dateFormatPipe,
        tripLogEntry.stopTime,
        nextTripLogEntry.startTime,
        tripLogEntry.code,
        tripLogEntry.level,
        tripLogEntry.message,
        true
      );

      newDisplayedLogs.push(displayedTripEntry, displayedStopEntry);
    }

    const lastTripLogEntry = tripLogs[tripLogs.length - 1];
    newDisplayedLogs.push(
      new StoptimeDetailViewItem(
        this.offsetSpaToApiMilliseconds,
        this.dateFormatPipe,
        lastTripLogEntry.startTime,
        lastTripLogEntry.stopTime,
        null,
        null,
        null,
        false
      )
    );

    if (this.statusTime && this.statusTime.initialStopTime && this.isTodayLocalTime(this.statusTime.initialStopTime)) {
      const lastTripLogEntry = tripLogs[tripLogs.length - 1];
      const currentStoptimeDisplayData = new StoptimeDetailViewItem(
        this.offsetSpaToApiMilliseconds,
        this.dateFormatPipe,
        this.statusTime.initialStopTime,
        null,
        lastTripLogEntry.code,
        lastTripLogEntry.level,
        lastTripLogEntry.message,
        true
      );

      const lastItemIndex = newDisplayedLogs.push(currentStoptimeDisplayData) - 1;

      if (this.statusTime.startTime) {
        const initialStartTime = this.statusTime.startTime.getTime();
        const now = Date.now() - this.offsetSpaToApiMilliseconds;

        if (initialStartTime + this.startThresholdMilliseconds < now) {
          newDisplayedLogs[lastItemIndex] = new StoptimeDetailViewItem(
            this.offsetSpaToApiMilliseconds,
            this.dateFormatPipe,
            this.statusTime.initialStopTime,
            this.statusTime.startTime,
            lastTripLogEntry.code,
            lastTripLogEntry.level,
            lastTripLogEntry.message,
            true
          );

          newDisplayedLogs.push(
            new StoptimeDetailViewItem(
              this.offsetSpaToApiMilliseconds,
              this.dateFormatPipe,
              this.statusTime.startTime,
              null,
              null,
              null,
              null,
              false
            )
          );
        }
      }
    }

    this.displayedLogs = newDisplayedLogs.reverse();
  }

  private isTodayLocalTime(date: Date): boolean {
    if (!date) {
      return false;
    }

    const localDate = moment.parseZone(date);
    const localNow = moment.parseZone(new Date());

    return localDate.isSame(localNow, 'day');
  }

  private doScrollMagic() {
    if (this.isIphone) {
      return;
    }

    const checkExist = setInterval(() => {
      const content = document.getElementById('ion-content-stoptime-list');
      if (content) {
        const inner = content.shadowRoot.querySelector('main');
        if (inner) {
          clearInterval(checkExist);

          this.screenSizeService.bigScreenMode$.pipe(take(1)).subscribe((bigScreenMode) => {
            if (bigScreenMode && !this.navigatorService.isMac()) {
              inner.style.marginRight = '-16.5px';
              inner.style.overflowY = 'scroll';
              inner.style.overflowX = 'hidden';
            } else {
              inner.style.marginRight = '0px';
              inner.style.overflowY = 'var(--overflow)';
            }
          });
        }
      }
    }, 50);
  }

  itemId(_: number, item: StoptimeDetailViewItem) {
    return item.displayedBeginTime;
  }
}
