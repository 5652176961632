import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayStatusTime } from 'src/app/maps/livedata/ropeway-status-time.model';
import { RopewayStatusTimeService } from 'src/app/maps/livedata/ropeway-status-time.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-stoptime-widget',
  templateUrl: './stoptime-widget.component.html',
  styleUrls: ['./stoptime-widget.component.scss'],
})
export class StoptimeWidgetComponent extends WidgetBase implements OnInit {
  private readonly stopColor = 'var(--ion-color-danger)';
  private readonly runColor = 'var(--ion-color-primary)';
  private readonly hoursUnit = 'h';
  private readonly minutesUnit = 'min';
  private readonly secondsUnit = 's';
  private readonly startThresholdMilliseconds = 2 * 60 * 1000;

  statusColor = this.runColor;
  displayedTime: string;
  displayedUnit: string;

  private startTimeMs: number;
  private initialStopTimeMs: number;
  private offsetSpaToApiMilliseconds: number;

  constructor(
    private masterdataService: MasterdataService,
    private ropewayStatusTimeService: RopewayStatusTimeService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    this.masterdataService.masterData$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.offsetSpaToApiMilliseconds = data.offsetSpaToApiMilliseconds;
    });

    interval(1000)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.updateDisplay();
      });

    this.selectedMapElementService.selectedRopeway$
      .pipe(
        switchMap((ropeway) =>
          this.ropewayStatusTimeService.ropewayStatusTimes$.pipe(
            map((ropewayStatusTimes) => ({ ropeway, ropewayStatusTimes }))
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ ropeway, ropewayStatusTimes }) => this.update(ropeway, ropewayStatusTimes));
  }

  private update(ropeway: Ropeway, ropewayStatusTimes: RopewayStatusTime[]) {
    if (!ropeway) {
      return;
    }

    const statusTimeData = ropewayStatusTimes.find((st) => st.deviceSisId === ropeway.sisId);
    if (statusTimeData) {
      this.startTimeMs = statusTimeData.startTime ? statusTimeData.startTime.getTime() : undefined;
      this.initialStopTimeMs = statusTimeData.initialStopTime ? statusTimeData.initialStopTime.getTime() : undefined;

      this.updateDisplay();
    }
  }

  private updateDisplay(): void {
    const now = Date.now() - this.offsetSpaToApiMilliseconds;

    const hasNoStartTimeAndNoStopTime = !this.startTimeMs && !this.initialStopTimeMs;
    const hasStartTime = this.startTimeMs != null;
    const hasStopTime = this.initialStopTimeMs != null;
    const isRunningForMoreThanThreshold = hasStartTime && this.startTimeMs + this.startThresholdMilliseconds < now;
    const startTimeIsAfterStopTime = !hasStopTime || (hasStartTime && this.initialStopTimeMs < this.startTimeMs);

    if (hasNoStartTimeAndNoStopTime || (startTimeIsAfterStopTime && isRunningForMoreThanThreshold)) {
      this.resetToDefault();
      return;
    }

    this.statusColor = this.stopColor;
    const milliseconds = now - this.initialStopTimeMs;
    const seconds = milliseconds / 1000;

    const hours = Math.floor(seconds / 3600);
    if (hours > 0) {
      this.displayedTime = hours.toString();
      this.displayedUnit = this.hoursUnit;
      return;
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes > 0) {
      this.displayedTime = minutes.toString();
      this.displayedUnit = this.minutesUnit;
      return;
    }

    this.displayedTime = Math.floor(seconds).toString();
    this.displayedUnit = this.secondsUnit;
  }

  private resetToDefault(): void {
    this.displayedTime = undefined;
    this.displayedUnit = undefined;
    this.statusColor = this.runColor;
  }
}
