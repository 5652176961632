import { Component } from '@angular/core';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-windtendency-widget',
  templateUrl: './windtendency-widget.component.html',
  styleUrls: ['./windtendency-widget.component.scss'],
})
export class WindtendencyWidgetComponent extends WidgetBase {
  windTendency: number;
}
