<div
  *ngIf="layers"
  leaflet
  class="sis-geo-map"
  [leafletOptions]="options"
  [leafletLayers]="layers"
  [leafletLayersControl]="layersControl"
  (leafletMapReady)="onMapReady($event)"
>
  <sis-tenant-layer [tenantGroup]="tenantGroup" [tenants]="tenants"></sis-tenant-layer>

  <ion-img
    class="sis-zoom-in-control"
    *ngIf="bigScreenMode"
    src="../../../assets/icons-map/sis-icon-map-zoom-in.svg"
    (click)="onZoomIn($event)"
  ></ion-img>
  <ion-img
    class="sis-zoom-out-control"
    *ngIf="bigScreenMode"
    src="../../../assets/icons-map/sis-icon-map-zoom-out.svg"
    (click)="onZoomOut($event)"
  ></ion-img>
  <ion-img
    class="sis-fullscreen-control"
    *ngIf="bigScreenMode"
    src="../../../assets/icons-map/sis-icon-map-fullscreen.svg"
    matTooltip="{{ 'map.term.fullscreen' | translate }}"
    (click)="onFullscreen($event)"
    matTooltipPosition="left"
  ></ion-img>
  <ion-img
    class="sis-fit-to-view-control"
    *ngIf="bigScreenMode"
    src="../../../assets/icons-map/sis-icon-map-fit-view.svg"
    matTooltip="{{ 'map.term.center' | translate }}"
    (click)="onFitBounds($event)"
    matTooltipPosition="left"
  ></ion-img>
</div>
