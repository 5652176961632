import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { LastSlopeControl } from 'src/app/last-slope-control/domain/last-slope-control.model';
import { LastSlopeControlService } from 'src/app/last-slope-control/last-slope-control.service';

@Component({
  templateUrl: './last-slope-control.page.html',
  styleUrls: ['./last-slope-control.page.scss'],
})
export class LastSlopeControlPage extends Unsubscriber implements OnInit {
  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  lastSlopeControls: LastSlopeControl[];
  bigScreenMode: boolean;
  featureId = FeatureId.SISMEDIA_LASTSLOPECONTROL;

  constructor(
    private lastSlopeControlService: LastSlopeControlService,
    private logbookModalService: LogbookModalService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit() {
    this.lastSlopeControlService.lastSlopeControls$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.lastSlopeControls = data.sort((a, b) => a.zone - b.zone);
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));
  }
}
