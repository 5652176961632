import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { filter, map, scan, shareReplay, switchMap } from 'rxjs/operators';
import { LogbookEntryAdapter } from 'src/app/core/components/logbook/domain/logbook-entry.adapter';
import { LogbookEntry } from 'src/app/core/components/logbook/domain/logbook-entry.model';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { LogbookUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogbookService {
  private readonly logbookApiUrl = 'logbook';
  private readonly request$ = new Subject<FeatureId[]>();

  readonly logbookEntries$ = this.destinationService.selectedTenant$.pipe(
    filter((tenant) => !!tenant),
    map((tenant) => tenant.guid),
    switchMap((tenantGuid) =>
      merge(
        this.request$.pipe(
          scan(
            (acc: { all: FeatureId[]; new: FeatureId[] }, requestedFeatures) => {
              acc.new = requestedFeatures.filter((feature) => !acc.all.includes(feature));
              acc.new.forEach((n) => acc.all.push(n));
              return acc;
            },
            { all: [], new: [] }
          ),
          map((acc) => acc.new),
          filter((requestedFeatures) => requestedFeatures.length > 0),
          switchMap((requestedFeatures) =>
            this.http
              .get(`${environment.baseUrlApi}/${this.logbookApiUrl}?features=${requestedFeatures.join(',')}`)
              .pipe(map((requestedEntries) => LogbookEntryAdapter.adapt(requestedEntries)))
          )
        ),
        this.eventBus.observe(LogbookUpdatedEvent).pipe(
          filter((event) => event.logbookEntry.tenantGuid === tenantGuid),
          map((event) => [event.logbookEntry])
        )
      ).pipe(
        scan((acc: LogbookEntry[], entries) => {
          const newEntries = entries.filter((entry) => !acc.some((a) => a.id === entry.id));
          newEntries.forEach((entry) => acc.push(entry));
          return acc;
        }, [])
      )
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private http: HttpClient,
    private eventBus: EventBusService,
    private destinationService: DestinationService
  ) {}

  requestLogbookEntries(features: FeatureId[]): void {
    this.request$.next(features);
  }
}
