import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BreakdownInfo } from 'src/app/breakdowninfo/domain/breakdowninfo.model';

@Component({
  templateUrl: './breakdowninfo-infoscreen.component.html',
  styleUrls: ['./breakdowninfo-infoscreen.component.scss'],
})
export class BreakdownInfoInfoScreenComponent {
  breakdownInfo: BreakdownInfo;
  safeInfoLink: string;
  iconName: string;

  constructor(private modalCtrl: ModalController) {}

  done(): void {
    this.modalCtrl.dismiss();
  }
}
