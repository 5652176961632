import { SisConverter } from 'src/app/core/utils/sis-converter';
import { RopewayTripLog } from 'src/app/maps/widgets/detailviews/stoptime-detailview/ropeway-triplog.model';

export class RopewayTripLogAdapter {
  static adapt(item: any): RopewayTripLog[] {
    const tripLogs: RopewayTripLog[] = [];

    if (item) {
      item.forEach((e: any) => {
        tripLogs.push({
          startTime: SisConverter.toDateOrUndefined(e.startTime),
          stopTime: SisConverter.toDateOrUndefined(e.stopTime),
          deviceSisId: e.deviceSisId,
          code: e.code,
          level: e.level,
          message: e.message,
        });
      });
    }

    return tripLogs;
  }
}
