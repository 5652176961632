import { Playlist } from 'src/app/media-center/playlists/domain/playlist.model';
import { SlideMappingAdapter } from 'src/app/media-center/playlists/domain/slide-mapping.adapter';

export class PlaylistAdapter {
  static adapt(items: any): Playlist[] {
    const result: Playlist[] = [];

    if (items) {
      items.forEach((e: any) => {
        const playlist = new Playlist(e.guid, e.name, e.fadeInEnabled, e.backgroundColor);

        playlist.slideMappings = SlideMappingAdapter.adapt(e.slideMappings);
        playlist.slideMappings = playlist.slideMappings.sort((a, b) => a.order - b.order);

        result.push(playlist);
      });
    }

    return result;
  }
}
