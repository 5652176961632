import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Alarm } from 'src/app/domain/alarm.model';
import { Configuration } from 'src/app/maps/domain/configuration.model';
import { Coords } from 'src/app/maps/domain/coords.model';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { MeteoForecastAlarmService } from 'src/app/maps/livedata/meteo-forecast-alarm.service';
import { MeteoLivedata } from 'src/app/maps/livedata/meteo-livedata.model';
import { MeteoLivedataService } from 'src/app/maps/livedata/meteo-livedata.service';
import { WindIconComponent } from 'src/app/maps/map/meteo-layer/wind-icon/wind-icon.component';
import { MeteoStationService } from 'src/app/maps/meteostation-service/meteostation.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';
import {
  ArrowStyle,
  WindDisplayCalculatorService,
} from 'src/app/maps/wind-display-calculator/wind-display-calculator.service';

@Component({
  selector: 'sis-wind-widget',
  templateUrl: './wind-widget.component.html',
  styleUrls: ['./wind-widget.component.scss'],
})
export class WindWidgetComponent extends Unsubscriber implements OnInit {
  static cssMeteostationColorOffline = 'sis-meteostation-offline';
  static windMarkerPivotPoint: Coords = { x: 93.75, y: 112.54 };
  static TYPE = 'wind';

  windSpeedKmh: string = WidgetBase.valueUnknown;
  colorClass = WindWidgetComponent.cssMeteostationColorOffline;
  background = '';
  updatedRotationTransform: string;
  directionWind: boolean;
  crossWind: boolean;
  isMeteostationOfRopeway: boolean;
  forecastColor = WindIconComponent.colorForecastNone;

  meteoStation: MeteoStation;

  private liveData: MeteoLivedata;
  private config: Configuration;
  private liveDatas: MeteoLivedata[];
  private forecastWarnings: Alarm[];

  constructor(
    private windDisplayService: WindDisplayCalculatorService,
    private meteoStationService: MeteoStationService,
    private meteoForecastAlarmService: MeteoForecastAlarmService,
    private meteoLiveDataService: MeteoLivedataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.meteoLiveDataService.meteoLiveDatas$.pipe(takeUntil(this.onDestroy$)).subscribe((meteoLiveDatas) => {
      this.liveDatas = meteoLiveDatas;
      this.updateLiveData();
    });

    this.meteoForecastAlarmService.alarms$.pipe(takeUntil(this.onDestroy$)).subscribe((alarms) => {
      this.forecastWarnings = alarms;
      if (this.meteoStation && this.meteoStation.hasForecast) {
        this.updateForecastWarning();
      }
    });

    this.meteoStationService.activeMeteoStation$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((meteoStation: MeteoStation) => {
        this.meteoStation = meteoStation;
        if (this.meteoStation == null) {
          return;
        }
        this.updateLiveData();
        this.isMeteostationOfRopeway = this.meteoStation.ropewaySisId != null;
        this.updateForecastWarning();
      });
  }

  private updateLiveData() {
    if (!this.liveDatas) {
      this.resetToDefault();
      return;
    }

    if (!this.meteoStation) {
      this.resetToDefault();
      return;
    }

    this.liveData = this.findDataForStation(this.meteoStation, this.liveDatas);
    if (!this.liveData || this.liveData.outdated) {
      this.resetToDefault();
      return;
    }

    this.config = this.findConfigurationForStation(this.meteoStation, 'windSpeedKmh');
    this.updateColor();
    this.updateTransform();
    this.updateText();
    this.updateWindArrows();
  }

  private updateColor(): void {
    this.colorClass = this.windDisplayService.getColor(this.liveData, this.config);
    this.background = '';
  }

  private updateTransform(): void {
    this.updatedRotationTransform = this.windDisplayService.getRotationTransform(
      this.liveData,
      0,
      this.meteoStation.directionOffsetNorth,
      WindWidgetComponent.windMarkerPivotPoint
    );
  }

  private updateText(): void {
    this.windSpeedKmh = this.windDisplayService.getWindSpeed(this.liveData);
  }

  private updateWindArrows(): void {
    const arrowStyle = this.windDisplayService.getArrowStyle(this.liveData, this.meteoStation);
    switch (arrowStyle) {
      case ArrowStyle.NONE:
        this.crossWind = false;
        this.directionWind = false;
        return;
      case ArrowStyle.ONE:
        this.crossWind = false;
        this.directionWind = true;
        return;
      case ArrowStyle.TWO:
        this.crossWind = true;
        this.directionWind = false;
        return;
      default:
        return;
    }
  }

  private updateForecastWarning(): void {
    if (this.meteoStation && this.meteoStation.hasForecast) {
      const hasForecastWarning =
        this.forecastWarnings &&
        this.meteoStation &&
        this.forecastWarnings.find((a) => a.sisId === this.meteoStation.meteoDeviceSisId && a.active) != null;

      this.forecastColor = hasForecastWarning
        ? WindIconComponent.colorForecastWarning
        : WindIconComponent.colorForecastNormal;
      return;
    }

    this.forecastColor = WindIconComponent.colorForecastNone;
  }

  private resetToDefault(): void {
    this.liveData = null;
    this.windSpeedKmh = WidgetBase.valueUnknown;
    this.colorClass = WindWidgetComponent.cssMeteostationColorOffline;
    this.background = '';
    this.updatedRotationTransform = '';
    this.directionWind = false;
    this.crossWind = false;
  }

  private findDataForStation(meteoStation: MeteoStation, liveDatas: MeteoLivedata[]): MeteoLivedata {
    return liveDatas.find((d) => d.meteoDeviceSisId === meteoStation.meteoDeviceSisId);
  }

  private findConfigurationForStation(meteoStation: MeteoStation, configKey: string): Configuration {
    return meteoStation.configuration.find((f) => f.configKey === configKey);
  }
}
