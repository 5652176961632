<div class="sis-widget-sidepane-container">
  <div *ngIf="showWebcams" class="sis-media-links">
    <sis-webcam></sis-webcam>
  </div>
  <div *ngIf="!showWebcams" class="sis-tenant-logo">
    <img src="{{ tenantLogoUrl }}" />
  </div>
  <div
    #t
    *ngIf="!this.showPlaceHolder"
    class="sis-sidepane-overview"
    (click)="this.showControllerSelector(t)"
    (contextmenu)="this.showControllerSelector(t)"
    oncontextmenu="return false"
    [ngStyle]="{ cursor: this.hasRedundantControllers ? 'pointer' : 'default' }"
  >
    <ion-grid>
      <ion-row>
        <ion-col size="2" class="sis-icon-col">
          <ion-icon *ngIf="this.iconName" [name]="this.iconName"></ion-icon>
        </ion-col>
        <ion-col *ngIf="this.ropeway">
          <div class="sis-ropeway-title" data-test="sis-ropeway-alias">{{ this.alias }}</div>
          <div class="sis-ropeway-subtitle" data-test="sis-ropeway-fullname-with-sisId">
            {{ this.fullNameWithSisId }}
          </div>
          <div class="sis-ropeway-techinfo">{{ this.controllerTypeWithConstructor ?? '&nbsp;' }}</div>
        </ion-col>
        <ion-col *ngIf="!this.ropeway">
          <div class="sis-ropeway-title">{{ this.alias }}</div>
          <div class="sis-ropeway-techinfo">{{ this.dataProvider }}</div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div *ngIf="this.showControllerContextMenu">
      <sis-controller-selector
        [x]="this.controllerContextMenuX"
        [y]="this.controllerContextMenuY"
        [ropeway]="this.ropeway"
        (done)="this.hideControllerContextMenu()"
      ></sis-controller-selector>
    </div>
  </div>

  <div class="sis-placeholder" *ngIf="this.showPlaceHolder"></div>

  <div class="sis-widget-list-container">
    <sis-widget-list></sis-widget-list>
  </div>

  <sis-sidepane-alarmlist *ngIf="this.destinationView" [ngClass]="this.cssClass"></sis-sidepane-alarmlist>
</div>
