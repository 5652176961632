import { RopewayEcoModeTrend } from 'src/app/maps/trenddata/ropeway-ecomode-trend.model';

export class RopewayEcoModeTrendAdapter {
  static adapt(items: any): RopewayEcoModeTrend[] {
    const trends: RopewayEcoModeTrend[] = [];

    if (items && items.length > 0) {
      items.forEach((e: any) => {
        trends.push({
          actualSpeedMps: e.actualSpeedMps,
          targetSpeedMps: e.targetSpeedMps,
          driveEnergyWh: e.driveEnergyWh,
          ecoModeActivatedCockpit: e.ecoModeActivatedCockpit,
          ecoModeEnabledPLC: e.ecoModeEnabledPLC,
          peoplePerMinute: e.peoplePerMinute,
          vehicleCountPercent: e.vehicleCountPercent,
          workloadPercent: e.workloadPercent,
          timestamp: new Date(e.timestamp),
        });
      });
    }

    return trends;
  }
}
