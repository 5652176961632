import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayLivedata } from 'src/app/maps/livedata/ropeway-livedata.model';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';

@Component({
  selector: 'sis-transport-weight-detailview',
  templateUrl: './transport-weight-detailview.component.html',
  styleUrls: ['./transport-weight-detailview.component.scss'],
})
export class TransportWeightDetailViewComponent extends Unsubscriber implements OnInit {
  transportWeightTons: string[];
  vehicleId: number[];

  constructor(
    private ropewayLiveDataService: RopewayLivedataService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
    this.transportWeightTons = [];
    this.vehicleId = [];
  }

  ngOnInit(): void {
    combineLatest([this.selectedMapElementService.selectedRopeway$, this.ropewayLiveDataService.ropewayLiveData$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([ropeway, ropewayLiveDatas]) => {
        this.update(ropeway, ropewayLiveDatas);
      });
  }

  private update(ropeway: Ropeway, ropewayLiveDatas: RopewayLivedata[]): void {
    if (!ropewayLiveDatas) {
      return;
    }

    if (!ropeway) {
      this.resetToDefault();
      return;
    }

    const ropewayLiveData = ropewayLiveDatas?.find((data: RopewayLivedata) => data.deviceSisId === ropeway.sisId);

    if (
      ropewayLiveData == null ||
      ropewayLiveData.vehicleData == null ||
      !ropewayLiveData.vehicleData['transportWeightKg']
    ) {
      return;
    }

    const transportWeightKg = ropewayLiveData.vehicleData['transportWeightKg'];
    this.transportWeightTons = this.convertToTons(transportWeightKg);
    this.vehicleId = ropewayLiveData.vehicleData['id'];
  }

  private resetToDefault(): void {
    this.transportWeightTons = [];
    this.vehicleId = [];
  }

  private convertToTons(transportWeightKg: number[]): string[] {
    const transportWeightTons: string[] = [];
    transportWeightKg.forEach((value) => {
      transportWeightTons.push((value / 1000).toFixed(2));
    });

    return transportWeightTons;
  }
}
