import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { FirebaseMessageAdapter } from 'src/app/core/live-message/adapter/firebase-message.adapter';
import { SignalrMessageAdapter } from 'src/app/core/live-message/adapter/signalr-message.adapter';
import { LiveMessage } from 'src/app/core/live-message/domain/live-message.model';
import { EventAdapterRegistry } from 'src/app/core/live-message/event.adapter';
import { Unsubscriber } from 'src/app/core/unsubscriber';

@Injectable({
  providedIn: 'root',
})
export class LiveMessageService extends Unsubscriber {
  constructor(
    private signalrAdapter: SignalrMessageAdapter,
    private eventBus: EventBusService,
    public firebaseAdapter: FirebaseMessageAdapter
  ) {
    super();

    this.setupSubscriptions();
  }

  private setupSubscriptions(): void {
    this.signalrAdapter.messageReceived$.pipe(takeUntil(this.onDestroy$)).subscribe((message: string) => {
      try {
        const messageData = JSON.parse(message);
        this.publishMessageEvent(messageData);
      } catch (ex) {
        console.error(`Live message with wrong format received: ${ex} (${message})`);
      }
    });
  }

  private publishMessageEvent(liveMessage: LiveMessage): void {
    const type = liveMessage.messageType;
    const adaptFunction = EventAdapterRegistry.get(type);
    const event = adaptFunction(liveMessage.payload);
    this.eventBus.publish(event);
  }
}
