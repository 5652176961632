import { SisConverter } from 'src/app/core/utils/sis-converter';
import { RopewayStatusTime } from 'src/app/maps/livedata/ropeway-status-time.model';

export class RopewayStatusTimeAdapter {
  static adapt(item: any): RopewayStatusTime[] {
    const data: RopewayStatusTime[] = [];

    if (item) {
      item.forEach((e: any) => {
        data.push({
          deviceSisId: e.DeviceSisId,
          startTime: SisConverter.toDateOrUndefined(e.StartTimestamp),
          initialStopTime: SisConverter.toDateOrUndefined(e.InitialStopTimestamp),
          code: e.Code,
        });
      });
    }

    return data;
  }
}
