<div class="no-alarm-message" *ngIf="!hasAlarm">
  {{ 'alarming.phrase.noAlarms' | translate }}
</div>
<ion-grid class="detailview-container sis-global-no-scrollbar">
  <sis-sidepane-alarmlist-item
    *ngFor="let alarm of this.activeAlarms"
    [alarm]="alarm"
    [clickable]="false"
  ></sis-sidepane-alarmlist-item>
  <ion-row class="sis-empty-row"></ion-row>
</ion-grid>
<ion-chip
  data-test="alarm-library-chip"
  *ngIf="this.canAccessAlarmLibrary"
  class="sis-alarmlibrary-button sis-global-round-btn"
  color="secondary"
  (click)="openAlarmLibrary()"
>
  <ion-label>{{ 'alarming.term.alarmLibrary' | translate }}</ion-label>
</ion-chip>
