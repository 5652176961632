import { Directive, ElementRef } from '@angular/core';
@Directive({
  selector: '[sis-ion-content-scrollbar]',
})
export class SisIonContentScrollbar {
  constructor(el: ElementRef) {
    const stylesheet = `
    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      background: var(--ion-color-primary);
    }
    ::-webkit-scrollbar-thumb {
      background: var(--ion-color-secondary);
    }
    `;

    const styleElmt = el.nativeElement.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      el.nativeElement.shadowRoot.appendChild(barStyle);
    }
  }
}
