import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, firstValueFrom, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RopewayAvailabilityTrendAdapter } from 'src/app/maps/trenddata/ropeway-availability-trend.adapter';
import { RopewayAvailabilityTrend } from 'src/app/maps/trenddata/ropeway-availability-trend.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RopewayAvailabilityTrendService {
  constructor(private http: HttpClient) {}

  getAvailabilityTrendData(ropewaySisId: string): Observable<RopewayAvailabilityTrend[]> {
    const url = environment.baseUrlApi + `/trenddata/availability/` + ropewaySisId;
    return this.http.get(url).pipe(
      catchError(() => EMPTY),
      map((data) => RopewayAvailabilityTrendAdapter.adapt(data))
    );
  }

  getAvailabilityLongtermTrendData(ropewaySisId: string, timespan: string): Observable<RopewayAvailabilityTrend[]> {
    const url = environment.baseUrlApi + `/ropewayavailability/${ropewaySisId}/${timespan}`;
    return this.http.get(url).pipe(
      catchError(() => EMPTY),
      map((data) => RopewayAvailabilityTrendAdapter.adapt(data))
    );
  }

  async postIgnore(tenantSisId: string, ropewaySisId: string, date: string, ignore: boolean): Promise<void> {
    const url = `${environment.baseUrlApi}/trenddata/availability/ignore`;
    await firstValueFrom(this.http.post(url, { tenantSisId, ropewaySisId, date, ignore }));
  }
}
