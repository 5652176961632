import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MapIconClickedEvent } from 'src/app/core/eventbus/events';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';

@Component({
  selector: 'sis-controller-selector',
  templateUrl: './controller-selector.component.html',
  styleUrls: ['./controller-selector.component.scss'],
})
export class ControllerSelectorComponent implements OnInit {
  @Input() x: number;
  @Input() y: number;
  @Input() ropeway: Ropeway;

  @Output() done = new EventEmitter();

  selectableRopeways: Ropeway[];

  constructor(private eventBus: EventBusService, private masterdataService: MasterdataService) {}

  ngOnInit(): void {
    this.setSelectableRopeways(this.ropeway);
  }

  selectRopeway(ropeway: Ropeway) {
    const event = new MapIconClickedEvent();
    event.ropeway = ropeway;
    this.eventBus.publish(event);

    this.close();
  }

  close() {
    this.done.emit(null);
  }

  private setSelectableRopeways(ropeway: Ropeway): void {
    this.masterdataService.masterData$.pipe(take(1)).subscribe((masterdata) => {
      const controllerNumber = +ropeway.sisId.split('.', 2)[1];
      const deviceSisId = this.getDeviceSisIdFromOtherController(ropeway, controllerNumber);

      this.selectableRopeways = [ropeway, masterdata.ropeways.find((r) => r.sisId === deviceSisId)].sort((a, b) =>
        a.sisId.localeCompare(b.sisId)
      );
    });
  }

  private getDeviceSisIdFromOtherController(ropeway: Ropeway, controllerNumber: number): string {
    return ropeway.sisId.replace(/\..*/, `.${(controllerNumber % 2) + 1}`);
  }
}
