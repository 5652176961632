import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { Alarm } from 'src/app/domain/alarm.model';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MeteoForecastAlarmAdapter } from 'src/app/maps/livedata/meteo-forecast-alarm.adapter';

@Injectable({
  providedIn: 'root',
})
export class MeteoForecastAlarmService extends LivedataServiceBase<Alarm> {
  protected readonly endpointUri = 'livedata/forecastalarm';
  protected readonly requiredFeatures = [new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ)];

  readonly alarms$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, MeteoForecastAlarmAdapter.adapt, destinationService);
  }
}
