<ion-content
  id="ion-content-stoptime-list"
  class="detailview-container ion-no-margin ion-no-padding sis-global-no-scrollbar"
>
  <cdk-virtual-scroll-viewport itemSize="40" minBufferPx="400" maxBufferPx="800">
    <div *cdkVirtualFor="let item of this.displayedLogs; trackBy: this.itemId">
      <ion-row class="sis-row" [ngClass]="{ 'sis-stop-row': item.stopped }" [matTooltip]="item.displayedMessage">
        <ion-col size="1.2">
          <ion-icon class="sis-stop-icon" [name]="item.stopped ? 'sis-stop' : 'sis-running'"></ion-icon>
        </ion-col>
        <ion-col size="1.3" class="sis-text-col">
          <span class="sis-text-time">{{ item.displayedBeginTime }}</span>
        </ion-col>
        <ion-col size="0.8" class="sis-text-col">
          <span class="sis-text-time" *ngIf="item.endTime">⟼</span>
        </ion-col>
        <ion-col size="1.5" class="sis-text-col">
          <span class="sis-text-time" *ngIf="item.endTime">{{ item.displayedEndTime }}</span>
        </ion-col>
        <ion-col size="3.5" class="sis-text-col ion-text-center">
          <span class="sis-text-code" *ngIf="item.stopped && item.displayedCode">{{ item.displayedCode }}</span>
        </ion-col>
        <ion-col class="sis-text-col ion-text-right">
          <span class="sis-text-duration"
            >{{ (item.stopped ? 'general.term.stop' : 'general.term.trip') | translate }}
          </span>
          <span class="sis-text-duration">{{ item.displayedTime }} {{ item.displayedUnit }}</span>
        </ion-col>
      </ion-row>
    </div>
  </cdk-virtual-scroll-viewport>
</ion-content>
