import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { AbbSmartSensorDataAdapter } from 'src/app/maps/livedata/abbsmartsensor-data.adapter';
import { AbbSmartSensorData } from 'src/app/maps/livedata/abbsmartsensor-data.model';

@Injectable({
  providedIn: 'root',
})
export class AbbSmartSensorDataService extends LivedataServiceBase<AbbSmartSensorData> {
  protected readonly endpointUri = 'livedata/abbsmartsensor';
  protected readonly requiredFeatures = [
    new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ),
    new Feature(FeatureId.COCKPIT_ABBSMARTSENSOR, FeatureAccessLevel.READ),
  ];

  readonly abbSmartSensorDatas$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, AbbSmartSensorDataAdapter.adapt, destinationService);
  }
}
