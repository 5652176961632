import { ClampingCountResetMetadata } from 'src/app/maps/widgets/detailviews/clampingcount-detailview/domain/clampingcount-reset-metadata.model';

export class ClampingCountResetMetaDataAdapter {
  static adapt(item: any): ClampingCountResetMetadata[] {
    const metadata: ClampingCountResetMetadata[] = [];

    item.forEach((e: any) => {
      metadata.push({
        vehicleId: e.vehicleId,
        email: e.email,
        lastReset: new Date(e.lastReset),
        totalValueOnReset: e.totalValueOnReset,
      });
    });

    return metadata;
  }
}
