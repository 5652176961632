import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { map, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { CKEditorService } from 'src/app/media-center/ckeditor/ckeditor.service';
import { FileSettings } from 'src/app/media-center/ckeditor/file-settings.model';

@Component({
  templateUrl: './image-browse.component.html',
  styleUrls: ['./image-browse.component.scss'],
})
export class ImageBrowseModal extends Unsubscriber implements OnInit {
  readonly acceptedFileFormats = '.jpg, .jpeg, .png, .gif, .svg';

  images: FileSettings[];

  constructor(
    private modalCtrl: ModalController,
    private ckEditorService: CKEditorService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super();
  }

  ngOnInit() {
    this.ckEditorService.images$
      .pipe(
        takeUntil(this.onDestroy$),
        map((fileSettings) => fileSettings.sort((a, b) => a.name.localeCompare(b.name)))
      )
      .subscribe((fileSettings) => {
        this.images = fileSettings;
      });
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }

  imageSelected(imageUrl: string): void {
    if (imageUrl) {
      this.modalCtrl.dismiss(imageUrl);
    }
  }

  async handleFileInput(event: any): Promise<void> {
    const files: FileList = event.target.files;

    if (!files?.length) {
      return;
    }

    const file = files.item(0);
    event.target.value = '';

    if (!this.acceptedFileFormats.includes(file.type.replace('image/', '').replace('+xml', '').toLocaleLowerCase())) {
      return;
    }

    let confirmed = true;

    if (this.images.find((image) => image.name.includes(file.name))) {
      confirmed = await this.confirmationDialogService.presentAlert(
        'mediacenter.phrase.overwriteFile',
        'mediacenter.term.overwrite'
      );
    }

    if (confirmed) {
      const metadata = await this.ckEditorService.getMetadata(file);

      await this.ckEditorService.uploadImage(file, metadata);
    }
  }

  itemId(_: number, item: FileSettings): string {
    return `${item.url}${item.progress}`;
  }

  async createAllThumbnails() {
    await this.ckEditorService.uploadAllThumbnails();
  }
}
