import { Component, Input, OnChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { firstValueFrom, map, Observable, ReplaySubject, switchMap } from 'rxjs';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { TimetableInterval } from 'src/app/timetable/domain/timetable-interval.model';
import { TimetableIntervalDay } from 'src/app/timetable/domain/timetable-interval-day.model';
import { IntervalDayEditorComponent } from 'src/app/timetable/interval-day-editor-modal/interval-day-editor.component';
import { TimetableService } from 'src/app/timetable/timetable.service';

@Component({
  selector: 'sis-timetable-interval',
  templateUrl: './timetable-interval.component.html',
  styleUrls: ['./timetable-interval.component.scss'],
})
export class TimetableIntervalComponent implements OnChanges {
  @Input() interval: TimetableInterval;

  private readonly activeInterval$ = new ReplaySubject<TimetableInterval>(1);

  readonly intervalDays$: Observable<TimetableIntervalDay[]> = this.activeInterval$.pipe(
    switchMap((activeInterval) =>
      this.timetableService.timetables$.pipe(
        map(
          (tracks) =>
            tracks.find((track) => track.guid === activeInterval.trackGuid).intervals.find((interval) => interval.guid === activeInterval.guid)
              .intervalDays
        )
      )
    )
  );

  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  constructor(
    private destinationService: DestinationService,
    private modalCtrl: ModalController,
    private timetableService: TimetableService,
    private screenSizeService: ScreenSizeService
  ) {}

  ngOnChanges(): void {
    this.activeInterval$.next(this.interval);
  }

  readonly writePermission$ = this.destinationService.selectedTenantFeatures$.pipe(
    map((features) => features.some((f) => f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_TIMETABLE, FeatureAccessLevel.WRITE))))
  );

  async openIntervalDayModal(day: TimetableIntervalDay): Promise<void> {
    const writePermission = await firstValueFrom(this.writePermission$);
    if (writePermission) {
      const modal = await this.modalCtrl.create({
        component: IntervalDayEditorComponent,
        cssClass: 'sis-interval-day-editor-modal',
        componentProps: {
          day,
          interval: this.interval,
        },
      });
      await modal.present();
    }
  }
}
