import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './operating-info-infoscreen.component.html',
  styleUrls: ['./operating-info-infoscreen.component.scss'],
})
export class OperatingInfoInfoScreenComponent {
  safeInfoLink: string;
  iconName: string;

  constructor(private modalCtrl: ModalController) {}

  done(): void {
    this.modalCtrl.dismiss();
  }
}
