import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { Export } from 'src/app/domain/export/export.model';
import { ExportService } from 'src/app/domain/export/export.service';
import { SisMediaItem } from 'src/app/sismedia/domain/sismedia-item.model';
import { SisMediaExportsEditService } from 'src/app/sismedia/sismedia-exports-edit-modal/sismedia-exports-edit.service';
import { SisMediaExportsEditPostData } from 'src/app/sismedia/sismedia-exports-edit-modal/sismedia-exports-edit-postdata.model';
import { SisMediaExportsMapping } from 'src/app/sismedia/sismedia-exports-edit-modal/sismedia-exports-mapping.model';

@Component({
  selector: 'sis-sismedia-exports-edit-modal',
  templateUrl: './sismedia-exports-edit-modal.component.html',
  styleUrls: ['./sismedia-exports-edit-modal.component.scss'],
})
export class SisMediaExportsEditModalComponent extends Unsubscriber implements OnInit {
  private readonly reset$ = new Subject<void>();

  @Input() sisMediaItem: SisMediaItem;

  bigScreenMode: boolean;

  selectableExports: SisMediaExportsMapping[] = [];

  isUpdating: boolean;
  isEdited: boolean[] = [];
  hasUnsavedChanges: boolean;

  constructor(
    private modalCtrl: ModalController,
    private screenSizeService: ScreenSizeService,
    private exportsEditModalService: SisMediaExportsEditService,
    private exportService: ExportService
  ) {
    super();
  }

  ngOnInit() {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.reset$
      .pipe(
        switchMap(() => this.exportService.exports$),
        takeUntil(this.onDestroy$)
      )
      .subscribe((tenantExports) => {
        const selectableExports: SisMediaExportsMapping[] = [];
        tenantExports.forEach((e) => selectableExports.push({ export: e, enabled: false }));

        this.sisMediaItem.exports.forEach((e) => {
          const mapping = selectableExports.find((s) => s.export.guid === e.guid);
          if (mapping != null) {
            mapping.enabled = true;
          }
        });

        this.selectableExports = selectableExports;
        this.checkForChanges();
      });

    this.sisMediaItem
      .getUpdating(SisMediaItem.ExportMappings)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((updating) => {
        this.isUpdating = updating;
        if (!updating) {
          this.reset();
        }
      });
  }

  checkForChanges(): void {
    this.hasUnsavedChanges = this.selectableExports.some(
      (e) =>
        (e.enabled && !this.sisMediaItem.exports.find((i) => i.guid === e.export.guid)) ||
        (!e.enabled && this.sisMediaItem.exports.find((i) => i.guid === e.export.guid))
    );
  }

  toggleExport(value: { export: Export; enabled: boolean }): void {
    value.enabled = !value.enabled;
    this.checkForChanges();
  }

  save(): void {
    const postData: SisMediaExportsEditPostData = {
      exportGuids: this.selectableExports.filter((e) => e.enabled).map((e) => e.export.guid),
      assetCategory: this.sisMediaItem.assetCategory,
      assetGuid: this.sisMediaItem.assetGuid,
    };

    this.exportsEditModalService.postExportMappings(postData, this.sisMediaItem).subscribe();
    this.modalCtrl.dismiss();
  }

  reset(): void {
    this.reset$.next();
  }

  cancel(): void {
    this.reset();
    this.modalCtrl.dismiss();
  }
}
