import { Injectable } from '@angular/core';
import { BlobDeleteResponse } from '@azure/storage-blob';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { map, mergeMap, share, startWith, switchMap } from 'rxjs/operators';
import { BlobStorageService } from 'src/app/core/blob-storage/blob-storage.service';
import { SisBlobItem } from 'src/app/core/blob-storage/blob-storage.types';
import { BlobStorageContainerService } from 'src/app/core/blob-storage/blob-storage-container.service';

@Injectable({
  providedIn: 'root',
})
export class BlobDeleteService {
  private readonly deleteQueueInner$ = new Subject<string>();

  readonly deleteItemResponse$: Observable<SisBlobItem> = this.deleteQueueInner$.pipe(
    mergeMap((filePath) => this.deleteFile(filePath)),
    share()
  );

  constructor(
    private blobStorageService: BlobStorageService,
    private blobStorageContainerService: BlobStorageContainerService
  ) {}

  deleteItem(filePath: string): void {
    this.deleteQueueInner$.next(filePath);
  }

  private deleteFile(filePath: string): Observable<SisBlobItem> {
    return this.blobStorageContainerService.getStorageOptionsWithContainer().pipe(
      switchMap((options) =>
        this.blobStorageService
          .deleteBlobItem({
            storageUri: options.storageUri,
            storageAccessToken: options.storageAccessToken,
            containerName: options.containerName,
            filePath,
          })
          .pipe(
            this.mapDeleteResponse(filePath),
            this.blobStorageContainerService.refreshSelectedContainer(options.containerName)
          )
      )
    );
  }

  private mapDeleteResponse(filePath: string): OperatorFunction<BlobDeleteResponse, SisBlobItem> {
    return (source) =>
      source.pipe(
        map(() => ({ filePath, url: undefined, fileSize: null })),
        startWith({ filePath, url: undefined, fileSize: null })
      );
  }
}
