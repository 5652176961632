import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RopewayEcoModeTrendAdapter } from 'src/app/maps/trenddata/ropeway-ecomode-trend.adapter';
import { RopewayEcoModeTrend } from 'src/app/maps/trenddata/ropeway-ecomode-trend.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RopewayEcoModeTrendService {
  constructor(private http: HttpClient) {}

  getEcoModeData(ropewaySisId: string): Observable<RopewayEcoModeTrend[]> {
    const url = environment.baseUrlApi + `/trenddata/ecomode/` + ropewaySisId;
    return this.http.get(url).pipe(map((data) => RopewayEcoModeTrendAdapter.adapt(data)));
  }
}
