import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import firebase from 'firebase/compat';
import { firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FirebaseMessageAdapter extends Unsubscriber {
  private readonly firebaseApiUrl = '/firebasetoken';

  constructor(
    private httpClient: HttpClient,
    private afDatabase: AngularFireDatabase,
    private afAuth: AngularFireAuth,
    private afMessaging: AngularFireMessaging
  ) {
    super();

    if (!environment.isUiTest) {
      this.initialise();
    }
  }

  private initialise(): void {
    this.afMessaging.messages
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((message) => this.handleForegroundMessage(message));

    this.setupServiceWorkerRegistration();

    this.connect();
  }

  private connect(): void {
    this.afMessaging.requestToken.pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (token) => {
        if (token) {
          console.log('Notification permission granted.');
          console.log('Firebase token: ' + token);
          this.postTokenToCockpitApi(token);
        } else {
          console.log('Notification permission denied');
        }
      },
      error: (err) => {
        console.log('Unable to get permission to notify.', err.message);
      },
    });
  }

  private async postTokenToCockpitApi(token: string): Promise<void> {
    const url = environment.baseUrlApi + this.firebaseApiUrl;
    await firstValueFrom(this.httpClient.post<void>(url, { token }));

    await this.updateToken(token);
  }

  private async updateToken(token: any): Promise<void> {
    const user = await firstValueFrom(this.afAuth.authState);
    if (!user) {
      return;
    }

    const data = { [user.uid]: token };
    await this.afDatabase.object('fcmTokens/').update(data);

    console.log('Run updateToken: ' + data);
  }

  private setupServiceWorkerRegistration() {
    navigator.serviceWorker.register('../../../firebase-messaging-sw.js');
  }

  private handleForegroundMessage(payload: firebase.messaging.MessagePayload) {
    // console.log('Foreground Message');
    // Message while app in foreground
    // TODO: toast? flashing icons? NO NOTIFICATION (bad design)
  }
}
