import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MeteoForecastAdapter } from 'src/app/maps/livedata/meteo-forecast.adapter';
import { MeteoForecast } from 'src/app/maps/livedata/meteo-forecast.model';

@Injectable({
  providedIn: 'root',
})
export class MeteoForecastService extends LivedataServiceBase<MeteoForecast> {
  protected readonly endpointUri = 'livedata/forecast';
  protected readonly requiredFeatures = [new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ)];

  readonly forecasts$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, MeteoForecastAdapter.adapt, destinationService);
  }
}
