import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayOperationWidgetBase } from 'src/app/maps/widgets/ropeway-operation-widget-base';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-transportweight-widget',
  templateUrl: './transportweight-widget.component.html',
  styleUrls: ['./transportweight-widget.component.scss'],
})
export class TransportWeightWidgetComponent extends RopewayOperationWidgetBase implements OnInit {
  transportWeightTons: string;

  constructor(ropewayLiveDataService: RopewayLivedataService, selectedMapElementService: SelectedMapElementService) {
    super(ropewayLiveDataService, selectedMapElementService);
  }

  ngOnInit(): void {
    this.ropewayLiveData$.pipe(takeUntil(this.onDestroy$)).subscribe((d) => {
      if (!d.liveData?.vehicleData['transportWeightKg']?.length) {
        this.resetToDefault();
        return;
      }

      this.transportWeightTons = this.calculateTransportWeightTons(d.liveData.vehicleData['transportWeightKg']).toFixed(
        0
      );
    });
  }

  private resetToDefault(): void {
    this.transportWeightTons = WidgetBase.valueUnknown;
  }

  private calculateTransportWeightTons(transportWeightKg: number[]): number {
    return transportWeightKg.reduce((sum, current) => sum + current, 0) / 1000;
  }
}
