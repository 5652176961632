import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { LedTickerUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { LedTickerAdapter } from 'src/app/led-ticker/domain/led-ticker.adapter';
import { LedTicker } from 'src/app/led-ticker/domain/led-ticker.model';
import { LedTickerItem } from 'src/app/led-ticker/domain/led-ticker-item.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LedTickerService {
  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_LEDTICKER, FeatureAccessLevel.READ);
  private readonly baseRequestUrl: string = '/ledticker/get';
  private readonly basePostUrl: string = '/ledticker/post';

  private readonly ledTickerRequest$: Observable<LedTicker[]> = this.destinationService.selectedTenantFeatures$.pipe(
    distinctUntilChanged(),
    filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
    switchMap(() => this.http.get(`${environment.baseUrlApi}${this.baseRequestUrl}`)),
    map((data) => {
      const ledTickers = LedTickerAdapter.adapt(data);
      ledTickers['updated'] = undefined;
      return ledTickers;
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly updatedLedTickers$: Observable<LedTicker[]> = this.eventBus.observe(LedTickerUpdatedEvent).pipe(
    withLatestFrom(this.destinationService.selectedTenant$),
    filter(([updatedEvent, tenant]) => updatedEvent?.tenantGuid === tenant?.guid),
    withLatestFrom(this.ledTickerRequest$, this.userSettingsService.userSettings$),
    map(([[updatedEvent], ledTickers, userSettings]) => {
      if (updatedEvent.changedBy === userSettings.userGuid) {
        if (!updatedEvent.updateSuccessful) {
          const userMessage = new UserMessage({
            message: 'general.phrase.saveFailed',
            icon: UserMessageIcon.failed,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);
        } else {
          const userMessage = new UserMessage({
            message: 'general.phrase.saved',
            icon: UserMessageIcon.success,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.green,
          });
          this.userMessageService.presentToast(userMessage);
        }
      }

      const updatedLedTicker = ledTickers.find((ticker) => ticker.guid === updatedEvent.ledTicker.guid);

      if (updatedEvent.updateSuccessful && updatedLedTicker) {
        updatedLedTicker.groupedItems = updatedEvent.ledTicker.groupedItems;
        updatedLedTicker.items = updatedEvent.ledTicker.items;
      }

      ledTickers['updated'] = updatedLedTicker?.guid;

      return ledTickers;
    })
  );

  readonly ledTickers$: Observable<LedTicker[]> = merge(this.ledTickerRequest$, this.updatedLedTickers$).pipe(
    map((ledTickers) => ledTickers.sort((a, b) => a.name.localeCompare(b.name))),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private destinationService: DestinationService,
    private http: HttpClient,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService,
    private userMessageService: UserMessageService
  ) {}

  async postLedTicker(ledTickerItems: LedTickerItem[]): Promise<boolean> {
    return firstValueFrom(
      this.http.post(`${environment.baseUrlApi}${this.basePostUrl}`, ledTickerItems).pipe(
        map(() => true),
        catchError(() => {
          const translateKey = 'general.phrase.saveFailed';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.failed,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);

          return of(false);
        })
      )
    );
  }
}
