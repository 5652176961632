<ion-row *ngIf="this.bigScreenMode$ | async" class="sis-page-title-header">
  <ion-col data-test="sis-page-title">{{ this.title | translate }}</ion-col>
  <div class="sis-filter-input">
    <ion-searchbar
      *ngIf="this.filterControl != undefined"
      placeholder="{{ 'general.term.filter' | translate }}"
      type="text"
      [formControl]="this.filterControl"
      data-test="page-title-asset-filter"
    >
    </ion-searchbar>
  </div>
  <div class="sis-button-div">
    <ng-container *ngIf="!this.reorderingEnabled">
      <ion-chip
        class="sis-global-round-btn"
        *ngIf="this.showAddButton && (this.ignoreAdminRequirement || (this.isAdministrator$ | async))"
        matTooltip="{{ 'general.term.add' | translate }}"
        color="secondary"
        data-test="page-title-new-asset-button"
        (click)="this._openEditModal()"
      >
        <ion-icon name="add-outline" style="margin-left: -4px"></ion-icon>
      </ion-chip>
      <ion-chip
        class="sis-global-round-btn"
        *ngIf="this.showReorderButton"
        color="secondary"
        data-test="page-title-reorder-button"
        (click)="this._onReorderClick()"
      >
        <ion-icon
          name="sis-reorder"
          style="margin-left: -4px"
          matTooltip="{{ 'general.phrase.editOrder' | translate }}"
          matTooltipClass="sis-global-tooltip"
        ></ion-icon>
      </ion-chip>
      <sis-logbook-button
        *ngIf="(this.logbookAvailable$ | async) && this.featureId != undefined"
        class="sis-logbook-button"
        [showText]="true"
        (click)="openLogbook()"
      >
      </sis-logbook-button>
    </ng-container>

    <ng-container *ngIf="this.reorderingEnabled">
      <ion-chip
        color="secondary"
        class="sis-global-round-btn"
        data-test="page-title-reorder-save-button"
        (click)="this._onReorderSaveClick()"
      >
        <ion-label>{{ 'general.term.save' | translate }}</ion-label>
      </ion-chip>
      <ion-chip
        color="secondary"
        class="sis-global-round-btn"
        data-test="page-title-reorder-cancel-button"
        (click)="this._onReorderCancelClick()"
      >
        <ion-label>{{ 'general.term.cancel' | translate }}</ion-label>
      </ion-chip>
    </ng-container>
  </div>
</ion-row>

<ion-row *ngIf="!(this.bigScreenMode$ | async)" class="sis-page-title-header-small">
  <ion-col>{{ this.title | translate }}</ion-col>
  <div style="position: absolute; right: 0px">
    <ion-chip
      class="sis-new-asset-button sis-global-round-btn"
      *ngIf="
        !this.reorderingEnabled &&
        (this.ignoreAdminRequirement || (this.isAdministrator$ | async)) &&
        this.showAddButton
      "
      matTooltip="{{ 'general.term.add' | translate }}"
      color="secondary"
      data-test="page-title-new-asset-button"
      (click)="this._openEditModal()"
    >
      <ion-icon name="add-outline" style="margin-left: -4px"></ion-icon>
    </ion-chip>
    <sis-logbook-button
      *ngIf="(this.logbookAvailable$ | async) && this.featureId != undefined"
      [showText]="false"
      (click)="openLogbook()"
    >
    </sis-logbook-button>
  </div>
</ion-row>
