import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MapIconClickedEvent } from 'src/app/core/eventbus/events';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { DetailView } from 'src/app/maps/widget-list/widget-list-services/domain/detailview.model';
import { Widget } from 'src/app/maps/widget-list/widget-list-services/domain/widget.model';
import { WidgetName } from 'src/app/maps/widget-list/widget-list-services/domain/widgetname.enum';
import { WebcamDetailViewComponent } from 'src/app/maps/widgets/detailviews/webcam-detailview/webcam-detailview.component';
import { WebcamWidgetComponent } from 'src/app/maps/widgets/webcam-widget/webcam-widget.component';

@Injectable({
  providedIn: 'root',
})
export class CustomMarkerWidgetListService {
  constructor(private eventBus: EventBusService) {}

  buildWidgetList(
    mapIconClickedEvent: MapIconClickedEvent,
    userFeatures: Feature[],
    bigScreenMode: boolean
  ): Observable<Widget[]> {
    if (!userFeatures) {
      return of([]);
    }

    const customMarker = mapIconClickedEvent.customMarker;

    const widgetsToRemove: WidgetName[] = [];
    if (
      bigScreenMode ||
      !userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_WEBCAM) ||
      !customMarker?.webcams?.length
    ) {
      widgetsToRemove.push(WidgetName.Webcam);
    }

    const widgets = this.getWidgets(this.eventBus);

    const filteredWidgets = widgets.filter((widget) => !widgetsToRemove.includes(widget.name));

    if (filteredWidgets.length === 1 && filteredWidgets[0].expandable) {
      filteredWidgets[0].expand();
    }

    return of(filteredWidgets);
  }

  private getWidgets(eventBus: EventBusService): Widget[] {
    return [
      new Widget({
        name: WidgetName.Webcam,
        component: WebcamWidgetComponent,
        detailView: new DetailView({ component: WebcamDetailViewComponent, height: 212 }),
        tooltip: 'Webcam',
        eventBus,
      }),
    ];
  }
}
