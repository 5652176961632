import { RemoteAccessStatus } from 'src/app/maps/livedata/remote-access-status.model';

export class RemoteAccessStatusAdapter {
  static adapt(item: any): RemoteAccessStatus[] {
    const remoteAccessStatuses: RemoteAccessStatus[] = [];

    if (item) {
      item.forEach((e: any) => {
        remoteAccessStatuses.push({ guid: e.Guid, online: e.Online });
      });
    }

    return remoteAccessStatuses;
  }
}
