<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widget_Hintergrund_temp" data-name="Widget Hintergrund temp">
      <rect width="187.5" height="225" style="fill:none" />
    </g>
    <g id="Widgets">
      <g>
        <g>
          <rect width="187.5" height="225" style="fill:none" />
          <path
            *ngIf="isMeteostationOfRopeway"
            d="M90.75,185.38l6-4v12l-6,4m0-26v12l6-4v-12Zm0-2,6-4v-12l-6,4m0,42v6.87h6V195.38Zm0-44,6-4v-12l-6,4m0-14v12l6-4v-12Zm0-2,6-4v-12l-6,4m0-13.76v12l6-4v-12Zm0-2,6-4v-12l-6,4m0-14v12l6-4v-12Zm0-2,6-4v-12l-6,4m0-14v12l6-4v-12Zm0-2,6-4v-12l-6,4m0-12.87V29.62l6-4V18.75Z"
            style="fill:#58585b"
          />
          <g id="northArrow" *ngIf="!isMeteostationOfRopeway" fill="#32849a">
            <path d="M 151,31.6 V 31.4 L 160.9,18 H 161.1 L 171,31.4 V 31.6 Z" id="arrow" />
            <g aria-label="N" transform="translate(-0.3)">
              <path
                d="M 151.3,36.03 H 155.3 L 168.4,54.82 L 168.3,36.03 H 171.3 V 58.49 H 167.3 L 154.3,39.7 V 58.49 H 151.3 Z"
                id="N"
              />
            </g>
          </g>
          <g>
            <line
              x1="18.75"
              x2="18.75"
              y1="18.75"
              y2="65.5"
              style="fill:none;stroke:#32849a;stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.98298681409786px"
            />
            <g>
              <path
                d="M56.25,33c0,3.61-1.5,7.67-3.35,7.67S49.55,36.63,49.55,33s1.5-6.52,3.35-6.52S56.25,29.42,56.25,33Z"
                style="fill:none;stroke:#32849a;stroke-miterlimit:10;stroke-width:1.98298681409786px"
              />
              <path
                d="M42.69,41.4c-2,0-3.79-4.52-3.79-8.42s2-7.18,4-7.18"
                style="fill:none;stroke:#32849a;stroke-miterlimit:10;stroke-width:1.98298681409786px"
              />
              <path
                d="M32.76,42.09c-2.22,0-4-5-4-9.24s1.8-7.76,4-7.76"
                style="fill:none;stroke:#32849a;stroke-miterlimit:10;stroke-width:1.98298681409786px"
              />
              <path
                d="M52.9,40.69l-30,2.09c-2.22,0-4-5.26-4-9.92s1.8-8.45,4-8.45l30,2.09"
                style="fill:none;stroke:#32849a;stroke-miterlimit:10;stroke-width:1.98298681409786px"
              />
            </g>
          </g>
          <text />
        </g>
        <g>
          <path
            id="direction-arrow-bidir"
            [attr.display]="crossWind ? 'inline' : 'none'"
            [attr.transform]="updatedRotationTransform"
            [attr.fill]="this.forecastColor"
            class="wind-direction-cross"
            display="none"
            d="M 93.497661,59.468 67.009381,85.95824 A 37.5,37.5 0 0 0 69.327731,141.11253 L 93.751561,165.53441 120.23789,139.04613 A 37.5,37.5 0 0 0 117.94102,83.90941 Z"
          />
          <path
            id="direction-arrow"
            [attr.display]="directionWind ? 'inline' : 'none'"
            [attr.transform]="updatedRotationTransform"
            [attr.fill]="this.forecastColor"
            class="wind-direction"
            display="none"
            d="M 120.23,139.04 A 37.5,37.5 0 1 0 67.23,139.02 L 93.75,165.53 Z"
          />
          <circle
            id="direction-none"
            class="wind-direction-none"
            [attr.display]="crossWind || directionWind ? 'none' : 'inline'"
            [attr.transform]="updatedRotationTransform"
            [attr.fill]="this.forecastColor"
            cx="93.75"
            cy="112.54"
            r="37.5"
          />
          <circle
            id="windWarningCircle"
            class="wind-warning"
            [ngClass]="this.colorClass"
            cx="93.75"
            cy="112.54"
            r="32.5"
          />
          <text
            id="value"
            x="50%"
            y="56%"
            style="text-anchor:middle;font-size:40px;fill:#1c232a;font-family:MyriadPro-Regular, Myriad Pro"
          >
            {{ windSpeedKmh }}
          </text>
        </g>
      </g>
    </g>
  </g>
</svg>
