import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { AlarmlistComponent } from 'src/app/alarmlist/alarmlist.component';
import { AlarmlistItemComponent } from 'src/app/alarmlist/alarmlist-item/alarmlist-item.component';
import { AlarmlistModalComponent } from 'src/app/alarmlist/alarmlist-modal/alarmlist-modal.component';
import { AlarmIconComponent } from 'src/app/core/alarm-icon/alarm-icon.component';
import { ContextMenuComponent } from 'src/app/core/components/context-menu/context-menu.component';
import { GeneralButtonComponent } from 'src/app/core/components/general-button/general-button.component';
import { LogbookModalComponent } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.component';
import { LogbookButtonComponent } from 'src/app/core/components/logbook-button/logbook-button.component';
import { OutdatedIconComponent } from 'src/app/core/components/outdated-icon/outdated-icon.component';
import { PageTitleComponent } from 'src/app/core/components/page-title/page-title.component';
import { TenantFeatureIconComponent } from 'src/app/core/components/tenant-feature-icon/tenant-feature-icon.component';
import { TimeformatButtonComponent } from 'src/app/core/components/timeformat-button/timeformat-button.component';
import { SisIonContentScrollbar } from 'src/app/core/scrollbar/sis-ion-content-scrollbar';
import { DateFormatPipe } from 'src/app/core/utils/date-format.pipe';
import { DateTimeFormatPipe } from 'src/app/core/utils/date-time-format.pipe';
import { InputIntegerOnlyDirective } from 'src/app/core/utils/input-integer-only.directive';
import { MarkdownPipe } from 'src/app/core/utils/markdown.pipe';
import { WeekdayPipe } from 'src/app/core/utils/weekday.pipe';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule, MatTooltipModule, ReactiveFormsModule, ScrollingModule],
  declarations: [
    InputIntegerOnlyDirective,
    DateFormatPipe,
    DateTimeFormatPipe,
    AlarmIconComponent,
    ContextMenuComponent,
    AlarmlistComponent,
    AlarmlistItemComponent,
    AlarmlistModalComponent,
    TimeformatButtonComponent,
    GeneralButtonComponent,
    LogbookButtonComponent,
    LogbookModalComponent,
    OutdatedIconComponent,
    SisIonContentScrollbar,
    MarkdownPipe,
    PageTitleComponent,
    WeekdayPipe,
    TenantFeatureIconComponent,
  ],
  exports: [
    InputIntegerOnlyDirective,
    DateFormatPipe,
    DateTimeFormatPipe,
    AlarmIconComponent,
    ContextMenuComponent,
    AlarmlistComponent,
    AlarmlistItemComponent,
    AlarmlistModalComponent,
    TimeformatButtonComponent,
    GeneralButtonComponent,
    LogbookButtonComponent,
    LogbookModalComponent,
    OutdatedIconComponent,
    SisIonContentScrollbar,
    MarkdownPipe,
    PageTitleComponent,
    WeekdayPipe,
    TenantFeatureIconComponent,
  ],
  providers: [TranslatePipe],
})
export class CoreModule { }
