import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { delay, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'sis-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends Unsubscriber implements OnInit {
  msalLoginReady = false;

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private appService: AppService
  ) {
    super();
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$.pipe(delay(0), takeUntil(this.onDestroy$)).subscribe((status) => {
      this.msalLoginReady = status === InteractionStatus.None || status === InteractionStatus.Startup;
    });
  }

  login(): void {
    this.appService.deleteLocalStorage();
    this.msalService.loginRedirect({
      scopes: environment.msalScopes,
    });
  }
}
