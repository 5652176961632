import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService {
  isOnline(): boolean {
    return window.navigator.onLine;
  }

  get baseUrl(): string {
    return window.location.origin.replace('4200', '5005');
  }

  onWindowUnload(callback: () => {}) {
    window.onbeforeunload = callback;
  }

  openInNewTab(url: string, properties: string = '') {
    window.open(url, '_system', ['location=yes', properties].join(','));
  }

  isIphone(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;

    return /iPhone/.test(userAgent);
  }

  isMac(): boolean {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  }
}
