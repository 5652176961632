<ion-row class="sis-title-row" style="padding: 0px">
  <ion-col>{{ title }}</ion-col>
</ion-row>
<form [formGroup]="formGroup">
  <ion-row
    *ngIf="this.showWeatherImportRows"
    class="sis-text-row"
    [style.opacity]="this.hasWeatherImport ? 100 : 0"
    data-test="meteo-info-weather-row"
  >
    <ion-col
      size="4"
      class="sis-global-input sis-img-col-short"
      matTooltip="{{ (this.hasWeatherImport ? 'general.phrase.imported' : '') | translate }}"
    >
      <img [src]="this.weatherIconUrl" class="sis-weather-icon" />
    </ion-col>
  </ion-row>
  <ion-row class="sis-text-row" data-test="meteo-info-temp-row">
    <ion-col size="4" class="sis-global-input sis-text-col-short"
      ><ion-input
        formControlName="temperature"
        class="sis-global-input sis-global-text-input"
        [ngClass]="{ 'sis-global-input-error': this.formControls.temperature.errors }"
        data-test="meteo-info-temp-item"
        matTooltip="{{ (this.hasWeatherImport ? 'general.phrase.imported' : '') | translate }}"
      ></ion-input>
    </ion-col>
    <ion-col size="auto" class="sis-text-unit">°C</ion-col>
  </ion-row>
  <ion-row class="sis-text-row" data-test="meteo-info-wind-row">
    <ion-col size="4" class="sis-global-input sis-text-col-short"
      ><ion-input
        formControlName="windSpeed"
        class="sis-global-input sis-global-text-input"
        [ngClass]="{ 'sis-global-input-error': this.formControls.windSpeed.errors }"
        data-test="meteo-info-wind-item"
      ></ion-input
    ></ion-col>
    <ion-col size="auto" class="sis-text-unit">km/h</ion-col>
  </ion-row>
  <ion-row class="sis-text-row" data-test="meteo-info-snow-depth-row">
    <ion-col size="4" class="sis-global-input sis-text-col-short"
      ><ion-input
        formControlName="snowDepth"
        class="sis-global-input sis-global-text-input"
        [ngClass]="{ 'sis-global-input-error': this.formControls.snowDepth.errors }"
        data-test="meteo-info-snow-depth-item"
      ></ion-input
    ></ion-col>
    <ion-col size="auto" class="sis-text-unit">cm</ion-col>
  </ion-row>
  <ion-row class="sis-text-row" data-test="meteo-info-fresh-snow-row">
    <ion-col size="4" class="sis-global-input sis-text-col-short"
      ><ion-input
        formControlName="freshSnow"
        class="sis-global-input sis-global-text-input"
        [ngClass]="{ 'sis-global-input-error': this.formControls.freshSnow.errors }"
        data-test="meteo-info-fresh-snow-item"
      ></ion-input
    ></ion-col>
    <ion-col size="auto" class="sis-text-unit">cm</ion-col>
  </ion-row>
  <ion-row class="sis-text-row">
    <ion-col class="sis-text-col" [ngClass]="{ 'sis-datetime-col': !this.bigScreenMode }">
      <ion-input
        type="date"
        class="sis-global-input"
        formControlName="latestSnowfall"
        data-test="meteo-info-latest-snowfall-item"
      ></ion-input>
    </ion-col>
  </ion-row>
  <ion-row
    class="sis-text-row"
    [ngClass]="{ 'sis-error-row': this.formControls.avalancheDangerLevel.errors }"
    data-test="meteo-info-avalanche-level-row"
  >
    <ion-col class="sis-text-col">
      <ion-select
        *ngIf="!this.rerender"
        class="sis-global-input"
        interface="popover"
        class="sis-global-input"
        [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
        formControlName="avalancheDangerLevel"
        data-test="meteo-info-avalanche-danger-level-select"
      >
        <ion-select-option
          *ngFor="let item of this.avalancheLevel"
          [value]="item.value"
          data-test="meteo-info-avalanche-danger-level-option"
          >{{ item.translationString | translate }}</ion-select-option
        >
      </ion-select>
    </ion-col>
  </ion-row>
  <ion-row
    class="sis-text-row"
    [ngClass]="{ 'sis-error-row': this.formControls.slopeConditionId.errors }"
    data-test="meteo-info-slope-condition-row"
  >
    <ion-col class="sis-text-col">
      <ion-select
        *ngIf="!this.rerender"
        class="sis-global-input"
        interface="popover"
        class="sis-global-input"
        [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
        formControlName="slopeConditionId"
        data-test="meteo-info-slope-condition-id-select"
      >
        <ion-select-option
          *ngFor="let item of this.slopeCondition"
          [value]="item.value"
          data-test="meteo-info-slope-condition-id-option"
          >{{ item.translationString | translate }}</ion-select-option
        >
      </ion-select>
    </ion-col>
  </ion-row>
  <ion-row
    class="sis-text-row"
    [ngClass]="{ 'sis-error-row': this.formControls.snowConditionId.errors }"
    data-test="meteo-info-snow-condition-row"
  >
    <ion-col class="sis-text-col">
      <ion-select
        *ngIf="!this.rerender"
        class="sis-global-input"
        interface="popover"
        class="sis-global-input"
        [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
        formControlName="snowConditionId"
        data-test="meteo-info-snow-condition-id-select"
      >
        <ion-select-option
          *ngFor="let item of this.snowCondition"
          [value]="item.value"
          data-test="meteo-info-snow-condition-id-option"
          >{{ item.translationString | translate }}</ion-select-option
        >
      </ion-select>
    </ion-col>
  </ion-row>
</form>
