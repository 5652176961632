import { OperatingInfoDefaultTitle } from 'src/app/operating-info/domain/operating-info-default-title.model';
import { OperatingInfoEntryAdapter } from 'src/app/operating-info/domain/operating-info-entry.adapter';
import { OperatingInfoPriority } from 'src/app/operating-info/domain/operating-info-priority';
import { OperatingInfoRequestData } from 'src/app/operating-info/domain/operating-info-request-data.model';

export class OperatingInfoRequestDataAdapter {
  static adapt(item: any): OperatingInfoRequestData | null {
    if (!item) {
      return null;
    }

    return {
      libraryEntries: OperatingInfoEntryAdapter.adapt(item.libraryEntries),
      priorities: this.getPriorities(item),
      defaultTitles: this.getDefaultTitles(item),
      url: item.url,
    };
  }

  private static getPriorities(item: any): OperatingInfoPriority[] {
    const result: OperatingInfoPriority[] = [];

    if (item && item.priorities) {
      item.priorities.forEach((priority) => {
        result.push({
          color: priority.color,
          description: priority.description,
          priority: priority.priority,
        });
      });
    }

    result.sort((a, b) => a.priority - b.priority);

    return result;
  }

  private static getDefaultTitles(item: any): OperatingInfoDefaultTitle[] {
    const result: OperatingInfoDefaultTitle[] = [];

    if (item && item.defaultTitles) {
      item.defaultTitles.forEach((defaultTitle) => {
        result.push({
          language: defaultTitle.language,
          title: defaultTitle.title,
        });
      });
    }

    return result;
  }
}
