import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekday',
})
export class WeekdayPipe implements PipeTransform {
  transform(value: Date): string {
    if (!value) {
      return '';
    }

    const daysOfWeek = [
      'general.term.weekday.7',
      'general.term.weekday.1',
      'general.term.weekday.2',
      'general.term.weekday.3',
      'general.term.weekday.4',
      'general.term.weekday.5',
      'general.term.weekday.6',
    ];

    const dayIndex = value.getDay();

    return daysOfWeek[dayIndex];
  }
}
