import { Component, OnInit } from '@angular/core';
import { filter, map, switchMap, takeUntil } from 'rxjs';
import { MathUtils } from 'src/app/core/utils/mathUtils';
import { RopewayAvailabilityService } from 'src/app/maps/livedata/ropeway-availability.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-ropeway-availability-widget',
  templateUrl: './ropeway-availability-widget.component.html',
})
export class RopewayAvailabilityWidgetComponent extends WidgetBase implements OnInit {
  availability: string;

  constructor(
    private ropewayAvailabilityService: RopewayAvailabilityService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedMapElementService.selectedRopeway$
      .pipe(
        filter((ropeway) => !!ropeway),
        switchMap((ropeway) =>
          this.ropewayAvailabilityService.ropewayAvailability$.pipe(
            map((livedata) => livedata.find((d) => d.ropewaySisId === ropeway.sisId))
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((livedata) => {
        if (!livedata) {
          this.availability = '?';
          return;
        }

        this.availability = `${MathUtils.roundTo(livedata.availability * 100, 1)} %`;
      });
  }
}
