<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.closeModal()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <sis-timeformat-button
      slot="start"
      class="sis-timeformat-button"
      *ngIf="!this.screenSizeService.bigScreenMode$ | async"
    ></sis-timeformat-button>
    <ion-title slot="start">{{ 'alarming.phrase.activeAlarms' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="sis-content">
  <sis-alarmlist [showTitle]="false"></sis-alarmlist>
</ion-content>
