import { Component } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { RemoteAccess } from 'src/app/maps/domain/remote-access.model';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';

@Component({
  selector: 'sis-remote-access-detailview',
  templateUrl: './remote-access-detailview.component.html',
  styleUrls: ['./remote-access-detailview.component.scss'],
})
export class RemoteAccessDetailViewComponent {
  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  readonly remoteAccesses$: Observable<RemoteAccess[]> = combineLatest([
    this.selectedMapElementService.selectedRopeway$,
    this.masterDataService.masterData$,
  ]).pipe(
    map(([ropeway, masterData]) => {
      if (ropeway) {
        return ropeway.remoteAccesses.sort((a, b) => (a.name < b.name ? -1 : 1));
      } else {
        return masterData.remoteAccesses;
      }
    }),
    startWith([])
  );

  constructor(
    private logbookModalService: LogbookModalService,
    private masterDataService: MasterdataService,
    private selectedMapElementService: SelectedMapElementService
  ) {}

  async openLogbook(): Promise<void> {
    await this.logbookModalService.presentLogbookModal([[FeatureId.COCKPIT_REMOTEACCESS]]);
  }
}
