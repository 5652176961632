
import { ManagedTenantAdapter } from 'src/app/user-management/domain/managed-tenant.adapter';
import { UserAdapter } from 'src/app/user-management/domain/user.adapter';
import { UserManagementData as UserManagementRequestData } from 'src/app/user-management/domain/user-management-data.model';

export class UserManagementDataAdapter {
  static adapt(item: any): UserManagementRequestData | null {
    if (!item) {
      return null;
    }

    return {
      users: UserAdapter.adapt(item.users),
      managedTenants: ManagedTenantAdapter.adapt(item.tenantInfos),
    };
  }
}
