<ion-row
  class="sis-mediacenter-row vertical-align-content"
  [ngClass]="{ 'sis-deleted': image.deleted, 'sis-mediacenter-row-hover': !image.progress }"
>
  <ion-col size="4" class="sis-image-container" (click)="this.onImageSelected(this.image.url)">
    <img *ngIf="!this.image.progress && this.image.thumbnailUrl" class="sis-image" [src]="this.image.thumbnailUrl" />
    <progress *ngIf="this.image.progress" max="100" [value]="this.image.progress"></progress>
  </ion-col>
  <ion-col class="sis-image-name-container" (click)="this.onImageSelected(this.image.url)">
    <div class="sis-image-name">{{ this.image.name }}</div>
    <ion-fab *ngIf="!this.image.progress" vertical="bottom" horizontal="end" class="sis-image-size-display">{{
      this.resolution
    }}</ion-fab>
    <ion-fab horizontal="end" *ngIf="!this.image.progress">
      <ion-button class="sis-imagebrowse-delete" expand="full" (click)="this.deleteFile(this.image, $event)">
        <ion-label>
          <ion-icon name="trash-outline"></ion-icon>
        </ion-label>
      </ion-button>
    </ion-fab>
  </ion-col>
</ion-row>
