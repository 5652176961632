<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <rect width="187.5" height="225" style="fill: none" />
        <g>
          <g>
            <path
              d="M110.94,69.36l17,20.35,13.33,22.9a6.29,6.29,0,0,1-1.54,8.74l-26.46,18.53a6.29,6.29,0,0,1-8.74-1.55L87.52,118,74.2,95.09a6.29,6.29,0,0,1,1.54-8.74L102.2,67.82A6.3,6.3,0,0,1,110.94,69.36Z"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <line
              x1="88.97"
              y1="77.09"
              x2="80.56"
              y2="65.08"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <polygon
              points="121.21 94.39 105.68 75.76 82 92.34 94.21 113.3 121.21 94.39"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <line
              x1="107.71"
              y1="103.85"
              x2="121.56"
              y2="123.64"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
          </g>
          <polyline
            points="75.63 58.04 61.25 37.5 61.25 154.88"
            style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
          />
          <path
            d="M77.55,50.35a20.7,20.7,0,0,1-16.3,7.9,21,21,0,0,1-6.38-1"
            style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
          />
        </g>
      </g>
    </g>
    <g>
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
      >
        ?
        <tspan id="unit" style="font-size: 24px">°</tspan>
      </text>
    </g>
  </g>
</svg>
