<svg version="1.1" viewBox="0 0 187.5 225" xmlns="http://www.w3.org/2000/svg">
  <g data-name="Ebene 2">
    <g id="Widget_Hintergrund_temp" data-name="Widget Hintergrund temp">
      <rect width="187.5" height="225" fill="none" />
    </g>
    <g id="Widgets">
      <rect width="187.5" height="225" fill="none" />
      <rect width="187.5" height="225" fill="none" />
      <circle cx="87.56" cy="137" r="13.83" fill="#b3bcbe" />
      <rect id="temperature" x="81.56" y="91.33" width="15.17" height="33.25" fill="#b3bcbe" />
      <path
        id="thermometer"
        d="m95.23 60.74a7.505 7.505 0 0 0-15.01 0v64.84a14.07 14.07 0 1 0 15.01 0z"
        fill="none"
        stroke="#989898"
        stroke-miterlimit="10"
        stroke-width="3px"
      />
      <path
        d="m122.6 35.67c-2 2.41-3.7 5.44-5 7.89-1.6 3.06-3.2 6.2-4.2 9.48-0.6 1.54-1.1 3.2-1.1 4.83-0.1 1.77 0.2 3.54 1.1 5.13 2.2 4.28 7.2 6.61 11.9 5.31 1.2-0.34 2.4-0.89 3.5-1.66 0.7-0.55 1.4-1.22 2-1.96 0.5-0.71 0.9-1.46 1.3-2.27 1.8-4.21 0.2-8.61-1.5-12.58-0.8-1.86-1.6-3.74-2.6-5.5-1.9-3.26-3.1-5.9-5.4-8.67"
        fill="#91cddb"
      />
      <path
        d="m143.9 59.21c-2 2.41-3.7 5.44-5 7.89-1.6 3.06-3.2 6.2-4.2 9.48-0.6 1.54-1.1 3.2-1.1 4.83 0 1.77 0.2 3.54 1.1 5.13 2.2 4.28 7.2 6.61 11.9 5.31 1.2-0.34 2.4-0.89 3.5-1.66 0.7-0.55 1.4-1.22 2-1.96 0.5-0.71 0.9-1.46 1.3-2.27 1.8-4.21 0.2-8.61-1.5-12.58-0.8-1.86-1.6-3.74-2.6-5.5-1.9-3.26-3.1-5.9-5.4-8.67"
        fill="#91cddb"
      />
      <g
        *ngIf="this.isForecastValue"
        data-test="forecast-icon"
        id="forecast"
        transform="matrix(.1342 0 0 .1342 -78.1 2.485)"
        fill="none"
      >
        <path d="m723.5 467.9h282.5" stroke="#989898" stroke-linecap="square" stroke-width="18.5" />
        <path d="m818.3 301.6v129.7" stroke="#32849a" stroke-linecap="square" stroke-width="22.35" />
        <path d="m1004 299v132.3" stroke="#32849a" stroke-linecap="square" stroke-width="22.35" />
        <path d="m911.2 355.4v75.9" stroke="#32849a" stroke-linecap="square" stroke-width="22.35" />
        <path d="m725.5 371.8v59.5" stroke="#32849a" stroke-linecap="square" stroke-width="22.35" />
        <path d="m723.6 321.9 92.1-100.7 96.4 75.9 93.9-108.8" stroke="#989898" stroke-width="18.5" />
        <path d="m1006 188.5v56.1" stroke="#989898" stroke-linecap="square" stroke-width="18.5" />
        <path d="m1006 188.2h-56" stroke="#989898" stroke-linecap="square" stroke-width="18.5" />
      </g>
    </g>
    <text
      [attr.x]="textPositionX"
      [attr.y]="textPositionY"
      id="value"
      style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
      [style.font-size]="fontSize"
    >
      {{ this.wetBulbTemperatureCelsius }}
      <tspan id="unit" font-size="24px">°C</tspan>
    </text>
  </g>
</svg>
