import { InfoBannerScreen } from 'src/app/media-center/screens/infobanner-modal/infobanner-screen-selector-modal/domain/infobanner-screen.model';

export class InfoBannerScreenGroup {
  guid: string;
  name: string;
  screens: InfoBannerScreen[];

  constructor(guid: string, name: string) {
    this.guid = guid;
    this.name = name;
    this.screens = [];
  }

  get groupActive(): boolean {
    return this.screens?.every((screen) => screen.newInfoBannerActive);
  }
}
