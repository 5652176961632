import { AbbSmartSensorData } from 'src/app/maps/livedata/abbsmartsensor-data.model';

export class AbbSmartSensorDataAdapter {
  static adapt(item: any): AbbSmartSensorData[] {
    const livedata: AbbSmartSensorData[] = [];

    if (item) {
      item.forEach((e: any) => {
        livedata.push({
          timestamp: e.Timestamp,
          assetId: Number.parseInt(e.AssetId),
          deviceSisId: e.DeviceSisId,
          tenantSisId: e.TenantSisId,
          conditionIndexForAvailability: e.ConditionIndexForAvailability,
          conditionIndexForEnvironment: e.ConditionIndexForEnvironment,
          conditionIndexForReliability: e.ConditionIndexForReliability,
          conditionIndexForStress: e.ConditionIndexForStress,
          overallConditionIndex: e.OverallConditionIndex,
        });
      });
    }

    return livedata;
  }
}
