import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { BreakdownInfoActiveTextAdapter } from 'src/app/breakdowninfo/domain/breakdowninfo-activetext.adapter';
import { BreakdownInfoActiveText } from 'src/app/breakdowninfo/domain/breakdowninfo-activetext.model';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { BreakdownInfoActiveTextUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BreakdownInfoActiveTextService {
  private static readonly activeTextsRequestUrl = '/breakdowninfo/activetexts';
  private static readonly useActiveTextPostUrl = '/breakdowninfo/useactivetext';

  private readonly requiredFeature = new Feature(FeatureId.BREAKDOWNINFO, FeatureAccessLevel.READ);

  readonly activeTextsRequest$: Observable<BreakdownInfoActiveText[]> =
    this.destinationService.selectedTenantFeatures$.pipe(
      switchMap((features) => {
        if (features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))) {
          return this.http
            .get(`${environment.baseUrlApi}${BreakdownInfoActiveTextService.activeTextsRequestUrl}`)
            .pipe(map((breakdownInfo: any) => BreakdownInfoActiveTextAdapter.adapt(breakdownInfo)));
        } else {
          return of([]);
        }
      }),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

  readonly activeTextsUpdate$: Observable<BreakdownInfoActiveText[]> = this.eventBus
    .observe(BreakdownInfoActiveTextUpdatedEvent)
    .pipe(
      filter((event) => event?.updateSuccessful),
      withLatestFrom(this.activeTextsRequest$),
      map(([event, activeTexts]) => {
        event.breakdownInfoActiveTexts.forEach((newText) => {
          const oldText = activeTexts.find((at) => at.breakdownInfoGuid === newText.breakdownInfoGuid);
          if (oldText) {
            Object.assign(oldText, newText);
          } else {
            activeTexts.push(newText);
          }
        });
        return activeTexts;
      })
    );

  readonly activeTexts$ = merge(this.activeTextsRequest$, this.activeTextsUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private http: HttpClient,
    private destinationService: DestinationService,
    private eventBus: EventBusService
  ) {}

  async updateActiveText(libraryGuid: string, breakdownInfoGuid: string, updateAll: boolean): Promise<void> {
    const url = `${environment.baseUrlApi}${BreakdownInfoActiveTextService.useActiveTextPostUrl}`;
    await firstValueFrom(
      this.http
        .post(url, { BreakdownInfoGuid: breakdownInfoGuid, ActiveLibraryEntryGuid: libraryGuid, UpdateAll: updateAll })
        .pipe(catchError(() => of()))
    );
  }
}
