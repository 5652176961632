import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakdownInfoInfoScreenComponent } from 'src/app/breakdowninfo/breakdowninfo-infoscreen/breakdowninfo-infoscreen.component';
import { BreakdownInfo } from 'src/app/breakdowninfo/domain/breakdowninfo.model';
import { BreakdownInfoActiveTextService } from 'src/app/breakdowninfo/domain/breakdowninfo-activetext.service';
import { BreakdownInfoLibraryService } from 'src/app/breakdowninfo/domain/breakdowninfo-library.service';
import { BreakdownInfoLibraryEntry } from 'src/app/breakdowninfo/domain/breakdowninfo-library-entry.model';
import { IconHelper } from 'src/app/core/icon-helper/icon-helper';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-breakdowninfo-text-selector',
  templateUrl: './breakdowninfo-text-selector.component.html',
  styleUrls: ['./breakdowninfo-text-selector.component.scss'],
})
export class BreakdownInfoTextSelectorComponent extends Unsubscriber implements OnInit {
  library: BreakdownInfoLibraryEntry[];
  breakdownInfo: BreakdownInfo;
  selectedLibraryItem: BreakdownInfoLibraryEntry;
  infoLinkString: string;
  iconName: string;
  bigScreenMode: boolean;

  constructor(
    private breakdownInfoActiveTextService: BreakdownInfoActiveTextService,
    private breakdownInfoLibraryService: BreakdownInfoLibraryService,
    private modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([this.breakdownInfoLibraryService.library$, this.breakdownInfoActiveTextService.activeTexts$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([library, activeTexts]) => {
        this.library = library;
        const activeText = activeTexts.find((d) => d.breakdownInfoGuid === this.breakdownInfo.guid);
        this.selectedLibraryItem = this.library?.find((e) => e.titleDe === activeText?.titleDe);
      });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.infoLinkString = this.generateInfoLink();
    const breakdownInfoIconName = this.breakdownInfo ? this.breakdownInfo.iconName : null;
    this.iconName = IconHelper.getIconName(breakdownInfoIconName);
  }

  done(closeModal: boolean): void {
    if (closeModal) {
      this.modalCtrl.dismiss();
    }
  }

  async update(all: boolean): Promise<void> {
    if (this.selectedLibraryItem) {
      await this.breakdownInfoActiveTextService.updateActiveText(
        this.selectedLibraryItem.guid,
        this.breakdownInfo.guid,
        all
      );
      this.breakdownInfo.isUpdating = true;
      this.done(true);
    }
  }

  generateInfoLink(): string {
    this.infoLinkString =
      environment.publicWebApp + '/breakdowninfo/' + this.breakdownInfo.guid.toString().toUpperCase();
    return this.infoLinkString;
  }

  async openBreakdownInfo(): Promise<void> {
    const safeInfoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.infoLinkString);

    const modal = await this.modalCtrl.create({
      component: BreakdownInfoInfoScreenComponent,
      cssClass: 'sis-mobile-size-modal',
      componentProps: {
        breakdownInfo: this.breakdownInfo,
        iconName: this.iconName,
        safeInfoLink,
      },
    });

    return modal.present();
  }
}
