import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AlarmlistService } from 'src/app/alarmlist/alarmlist.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { Alarm } from 'src/app/domain/alarm.model';

@Component({
  selector: 'sis-alarmlist',
  templateUrl: './alarmlist.component.html',
  styleUrls: ['./alarmlist.component.scss'],
})
export class AlarmlistComponent extends Unsubscriber implements OnInit {
  @Input() showTitle = true;

  alarms: Alarm[] = [];
  bigScreenMode: boolean;

  constructor(private alarmListService: AlarmlistService, private screenSizeService: ScreenSizeService) {
    super();
  }

  ngOnInit() {
    this.alarmListService.alarms$.pipe(takeUntil(this.onDestroy$)).subscribe((alarms) => {
      this.alarms = AlarmlistService.sortByTypeAndTimestamp(AlarmlistService.filterActive(alarms));
    });

    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));
  }

  itemId(_: number, alarm: Alarm): number {
    return alarm.timestampMs;
  }
}
