import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { WidgetUtils } from 'src/app/core/utils/widgetUtils';
import { Configuration } from 'src/app/maps/domain/configuration.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayLivedata } from 'src/app/maps/livedata/ropeway-livedata.model';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayOperationWidgetBase } from 'src/app/maps/widgets/ropeway-operation-widget-base';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-torque-widget',
  templateUrl: './torque-widget.component.html',
  styleUrls: ['./torque-widget.component.scss'],
})
export class TorqueWidgetComponent extends RopewayOperationWidgetBase implements OnInit {
  static rotationUnknown = 0;
  static unitNone = '';
  static unitAmps = 'A';
  static unitNewtonM = 'Nm';
  static unitPercent = '%';

  displayedValue: string;
  displayedUnit: string;
  rotationDeg = 0;
  showRotation = true;

  private config: Configuration;

  constructor(ropewayLiveDataService: RopewayLivedataService, selectedMapElementService: SelectedMapElementService) {
    super(ropewayLiveDataService, selectedMapElementService);
  }

  ngOnInit(): void {
    this.ropewayLiveData$.pipe(takeUntil(this.onDestroy$)).subscribe((d) => {
      this.config = this.findConfig(d.ropeway);
      this.update(d.ropeway, d.liveData);
    });
  }

  private update(ropeway: Ropeway, liveData: RopewayLivedata): void {
    let percentConfig: Configuration = null;
    let ampereConfig: Configuration = null;

    let hasAmpereValue = false;
    let hasPercentValue = false;

    let hasAmpereConfig = false;
    let hasPercentConfig = false;

    if (ropeway?.configurations) {
      percentConfig = ropeway.configurations.find((c) => c.configKey === Configuration.driveTorqueNmKey);
      hasPercentConfig = percentConfig != null;
      ampereConfig = ropeway.configurations.find((c) => c.configKey === Configuration.driveMotorCurrentAmpereKey);
      hasAmpereConfig = ampereConfig != null;
    }

    if (liveData) {
      hasAmpereValue = liveData.driveMotorCurrentAmpere != null;
      hasPercentValue = liveData.driveMotorTorquePercent != null;
    }

    if (hasPercentValue && hasPercentConfig) {
      const percent = liveData.driveMotorTorquePercent;
      this.updateWidget(
        TorqueWidgetComponent.unitNewtonM,
        Math.round(percentConfig.nominalValue * (percent / 100)).toString(),
        percent
      );
      return;
    }

    if (hasAmpereValue && hasAmpereConfig) {
      const percent = (liveData.driveMotorCurrentAmpere / ampereConfig.nominalValue) * 100;
      this.updateWidget(TorqueWidgetComponent.unitAmps, liveData.driveMotorCurrentAmpere.toString(), percent);
      return;
    }

    if (hasPercentValue && !hasPercentConfig) {
      this.updateWidget(
        TorqueWidgetComponent.unitPercent,
        liveData.driveMotorTorquePercent.toString(),
        liveData.driveMotorTorquePercent
      );
      return;
    }

    if (hasAmpereValue && !hasAmpereConfig) {
      this.updateWidget(TorqueWidgetComponent.unitAmps, liveData.driveMotorCurrentAmpere.toString());
      return;
    }

    this.resetToDefault();
  }

  private updateWidget(unit: string, value: string, rotation?: number): void {
    this.showRotation = rotation != null;
    this.rotateWidget(rotation ? rotation : 0);
    this.displayedValue = value;
    this.displayedUnit = unit;
  }

  private rotateWidget(driveMotorTorquePercent: number): void {
    this.rotationDeg = Math.abs(WidgetUtils.calculateDegreeWithLimit(driveMotorTorquePercent, 100, 0, -180));
  }

  private findConfig(ropeway: Ropeway): Configuration {
    return ropeway?.configurations?.find(
      (c) => c.configKey === Configuration.driveTorqueNmKey || c.configKey === Configuration.driveMotorCurrentAmpereKey
    );
  }

  private resetToDefault(): void {
    this.displayedValue = WidgetBase.valueUnknown;
    this.rotationDeg = TorqueWidgetComponent.rotationUnknown;
    this.displayedUnit = this.config
      ? this.config.configKey === Configuration.driveTorqueNmKey
        ? TorqueWidgetComponent.unitNewtonM
        : TorqueWidgetComponent.unitAmps
      : TorqueWidgetComponent.unitNone;
  }
}
