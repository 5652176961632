import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MathUtils } from 'src/app/core/utils/mathUtils';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayOperationWidgetBase } from 'src/app/maps/widgets/ropeway-operation-widget-base';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-ropekm-widget',
  templateUrl: './ropekm-widget.component.html',
  styleUrls: ['./ropekm-widget.component.scss'],
})
export class RopekmWidgetComponent extends RopewayOperationWidgetBase implements OnInit {
  ropeKm: string;

  constructor(ropewayLiveDataService: RopewayLivedataService, selectedMapElementService: SelectedMapElementService) {
    super(ropewayLiveDataService, selectedMapElementService);
    this.showOutdatedData = true;
  }

  ngOnInit(): void {
    this.ropewayLiveData$.pipe(takeUntil(this.onDestroy$)).subscribe((d) => {
      if (d.liveData?.ropeKm == null) {
        this.resetToDefault();
        return;
      }

      this.ropeKm = MathUtils.roundTo(d.liveData.ropeKm, 1);
    });
  }

  private resetToDefault(): void {
    this.ropeKm = WidgetBase.valueUnknown;
  }
}
