<svg
  version="1.1"
  viewBox="0 0 187.5 225"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:cc="http://creativecommons.org/ns#"
  xmlns:dc="http://purl.org/dc/elements/1.1/"
  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
>
  <g fill="none" stroke="#979797" stroke-linecap="round" stroke-linejoin="round" stroke-width="4px">
    <path
      d="m112.4 112.8 1.9 22-1.9 22a5.24 5.24 0 0 1-5.2 5.3h-26.89a5.24 5.24 0 0 1-5.23-5.3l-1.85-22 1.85-22a5.24 5.24 0 0 1 5.23-5.2h26.89a5.24 5.24 0 0 1 5.2 5.2z"
    />
    <line x1="93.75" x2="93.75" y1="107.6" y2="87.1" />
    <polygon points="81.72 114.7 80.03 134.8 107.5 134.8 105.8 114.7" />
    <line x1="93.75" x2="93.75" y1="134.8" y2="154.9" />
  </g>
  <path
    d="m47.17 102.1-3.06 0.8a4.31 4.31 0 1 1-1.06-3.83l1.13-0.32zm100.43-34.24a4.3 4.3 0 0 0-4.2 3.4l-3.1 0.85 3 3.32 1.2-0.31a4.31 4.31 0 1 0 3.1-7.26zm-89.93 27.14-11.56 3.2 3 3.3 11.57-3.18zm1.93-0.54 3 3.32 11.57-3.2-3-3.32zm24.83-6.86-11.57 3.2 3 3.33 11.56-3.21zm1.93-0.53 3 3.32 11.54-3.21-2.97-3.32zm25.04-6.95-11.55 3.21 2.95 3.32 11.6-3.21zm1.9-0.53 3 3.32 11.6-3.2-3-3.33zm25.1-6.94-11.6 3.2 3 3.32 11.6-3.17z"
    fill="#979797"
  />
  <rect width="187.5" height="225" fill="none" />
  <g transform="translate(.5933 4.01)" fill="#91cddb" stroke-width=".5414" aria-label="KG">
    <path d="m83.18 53.11 6.16-9.37h3.65l-9.44 13.14zm-1.75-9.37h2.9v15.38h-2.9zm4.31 7.6 2.49-1.58 5.41 9.36h-3.44z" />
    <path
      d="m105 50.52v3.01q0 1.71-0.7 3.02-0.7 1.3-2 2.02-1.3 0.71-2.93 0.71-1.68 0-2.97-0.67-1.28-0.67-1.98-1.9t-0.7-2.85v-4.53q0-1.72 0.68-3.02 0.7-1.3 1.97-2.01t2.94-0.71q1.39 0 2.49 0.57 1.2 0.56 2 1.59 0.8 1.04 1 2.4h-3.1q-0.1-0.48-0.5-0.83-0.4-0.36-0.9-0.54-0.46-0.19-0.99-0.19-0.77 0-1.36 0.34-0.58 0.34-0.91 0.96-0.32 0.62-0.32 1.44v4.53q0 0.72 0.33 1.27t0.93 0.85 1.39 0.3q0.83 0 1.33-0.32 0.6-0.33 0.9-0.94 0.4-0.62 0.4-1.45v-0.26h-2.36v-2.79z"
    />
  </g>
  <path
    d="m93.66 22.67a7.025 7.025 0 0 0-6.94 6.92 7.025 7.025 0 0 0 2.19 5.15h-6.15c-0.98 0-1.98 0.6-2.36 1.59l-13.67 32.47c-0.19 0.99-0.19 1.78 0.4 2.77 0.39 0.59 1.19 1.18 2.18 1.18h48.69c1.2 0 1.8-0.59 2.4-1.18 0.6-0.99 0.6-1.78 0.3-2.77l-13.5-32.47c-0.5-0.99-1.4-1.59-2.4-1.59h-6.2a7.025 7.025 0 0 0 2.3-5.15 7.025 7.025 0 0 0-7.24-6.92z"
    clip-rule="evenodd"
    fill="none"
    stroke="#91cddb"
    stroke-linejoin="round"
    stroke-miterlimit="2"
    stroke-width="4.5"
  />
  <text
    [attr.x]="textPositionX"
    [attr.y]="textPositionY"
    id="value"
    style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
    [style.font-size]="fontSize"
  >
    {{ transportWeightTons }}
    <tspan id="unit" style="font-size: 24px">t</tspan>
  </text>
</svg>
