import { BreakdownInfoLibraryEntry } from 'src/app/breakdowninfo/domain/breakdowninfo-library-entry.model';

export class BreakdownInfoLibraryEntryAdapter {
  static adapt(item: any): BreakdownInfoLibraryEntry[] {
    const library: BreakdownInfoLibraryEntry[] = [];

    item.forEach((e: any) => {
      library.push(
        new BreakdownInfoLibraryEntry({
          guid: e.guid,
          tenantGuid: e.tenantGuid,
          color: e.color,
          titleDe: e.titleDe,
          titleEn: e.titleEn,
          titleFr: e.titleFr,
          titleIt: e.titleIt,
          textDe: e.textDe,
          textEn: e.textEn,
          textFr: e.textFr,
          textIt: e.textIt,
        })
      );
    });

    return library;
  }
}
