import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Alarm } from 'src/app/domain/alarm.model';
import { DestinationAlarmService } from 'src/app/maps/livedata/destination-alarm.service';
import { GinAlarmService } from 'src/app/maps/livedata/gin-alarm.service';
import { MeteoForecastAlarmService } from 'src/app/maps/livedata/meteo-forecast-alarm.service';
import { RopewayAlarmService } from 'src/app/maps/livedata/ropeway-alarm.service';
import { RopewayEfaService } from 'src/app/maps/livedata/ropeway-efa.service';

@Injectable({
  providedIn: 'root',
})
export class AlarmlistService {
  static filterActive(alarms: Alarm[]): Alarm[] {
    return alarms?.filter((a) => a.active) ?? [];
  }

  static sortByTypeAndTimestamp(alarms: Alarm[]): Alarm[] {
    return alarms
      .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
      .sort((a, b) => Alarm.alarmLevelOrder.indexOf(a.level) - Alarm.alarmLevelOrder.indexOf(b.level));
  }

  readonly alarms$: Observable<Alarm[]> = combineLatest([
    this.destinationAlarmService.alarms$,
    this.ropewayAlarmService.alarms$,
    this.ropewayEfaService.efas$,
    this.meteoForecastAlarmService.alarms$,
    this.ginAlarmService.alarms$,
  ]).pipe(
    map(([destinationAlarms, ropewayAlarms, ropewayEfas, meteoForecastAlarms, ginAlarms]) => [
      ...destinationAlarms,
      ...ropewayAlarms,
      ...ropewayEfas,
      ...meteoForecastAlarms,
      ...ginAlarms,
    ]),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private meteoForecastAlarmService: MeteoForecastAlarmService,
    private destinationAlarmService: DestinationAlarmService,
    private ropewayAlarmService: RopewayAlarmService,
    private ropewayEfaService: RopewayEfaService,
    private ginAlarmService: GinAlarmService
  ) {}
}
