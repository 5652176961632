<ion-grid class="sis-no-padding sis-page-grid">
  <ion-row class="sis-global-row sis-title-row sis-no-padding">
    <ion-col class="sis-no-padding"> {{ 'ledticker.term.page' | translate }}: {{ this.pageNr }} </ion-col>
  </ion-row>
  <form [formGroup]="formGroup">
    <ion-row class="sis-text-row">
      <ion-col class="sis-bold sis-no-padding">
        {{ 'ledticker.term.active' | translate }}
      </ion-col>
      <ion-col class="sis-no-padding">
        <ion-toggle formControlName="active" color="secondary" data-test="ledticker-active-toggle"></ion-toggle>
      </ion-col>
    </ion-row>
    <ion-row class="sis-text-row">
      <ion-col class="sis-bold sis-no-padding" style="margin-top: -4px">
        {{ 'ledticker.term.duration' | translate }}
      </ion-col>
      <ion-col class="sis-no-padding">
        <ion-grid class="sis-no-padding" style="padding-left: 0px">
          <ion-row class="sis-text-row sis-no-padding" style="padding-left: 0px">
            <ion-col class="sis-no-padding" style="max-width: 60px">
              <ion-input
                formControlName="duration"
                class="sis-global-input sis-global-text-input"
                data-test="ledticker-duration-input"
              ></ion-input>
            </ion-col>
            <ion-col class="sis-no-padding sis-bold" style="margin-top: -4px"> s </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
    <ion-row class="sis-text-row">
      <ion-col class="sis-no-padding sis-bold">
        {{ 'ledticker.term.alignment' | translate }}
      </ion-col>
      <ion-col class="sis-no-padding">
        <ion-grid class="sis-no-padding">
          <ion-radio-group formControlName="alignment" style="width: 100%">
            <ion-row class="sis-no-padding sis-global-row">
              <ion-col size="4" style="padding: 0">
                <div class="sis-center-div">
                  <ion-icon name="sis-align-left"></ion-icon>
                  <ion-radio
                    value="left"
                    data-test="ledticker-alignment-radio"
                    color="secondary"
                    [disabled]="!this.writePermission"
                  ></ion-radio>
                </div>
              </ion-col>
              <ion-col size="4" style="padding: 0"
                ><div class="sis-center-div">
                  <ion-icon name="sis-align-center"></ion-icon>
                  <ion-radio
                    value="middle"
                    data-test="ledticker-alignment-radio"
                    color="secondary"
                    [disabled]="!this.writePermission"
                  ></ion-radio>
                </div>
              </ion-col>
              <ion-col size="4" style="padding: 0"
                ><div class="sis-center-div">
                  <ion-icon name="sis-align-right"></ion-icon>
                  <ion-radio
                    value="right"
                    data-test="ledticker-alignment-radio"
                    color="secondary"
                    [disabled]="!this.writePermission"
                  ></ion-radio>
                </div>
              </ion-col>
            </ion-row>
          </ion-radio-group>
        </ion-grid>
      </ion-col>
    </ion-row>

    <ion-row class="sis-no-padding sis-text-row" style="margin-bottom: -5px">
      <ion-col class="sis-bold">
        {{ 'ledticker.term.texts' | translate }}
      </ion-col>
    </ion-row>
    <ion-row
      class="sis-no-padding sis-text-row sis-global-row"
      style="padding-left: 5px; padding-right: 5px"
      *ngFor="let item of this.editableLedTickerItems; let i = index"
    >
      <ion-col>
        <ion-input
          [formControlName]="'text' + i"
          [maxlength]="this.maxTextLength"
          class="sis-global-input sis-global-text-input"
          data-test="ledticker-text-input"
        ></ion-input>
      </ion-col>
    </ion-row>
  </form>
</ion-grid>
