<div
  class="sis-chart"
  oncontextmenu="return false"
  [chart]="chart"
  style="height: 100%"
  (mousedown)="onMouseDown()"
  (click)="this.chartClicked($event)"
  (mouseup)="onMouseUp()"
  (mousemove)="onMouseMove()"
></div>

<div class="sis-pdf-export" *ngIf="this.isModal" (click)="this.exportChartPdf()">
  <ion-label color="primary">PDF</ion-label>
  <ion-icon name="download-outline" color="primary" style="zoom: 1.3"></ion-icon>
</div>

<div *ngIf="this.showContextMenu">
  <sis-context-menu
    [x]="this.contextMenuX"
    [y]="this.contextMenuY"
    [menuItems]="this.contextMenuItems"
    (menuItemSelected)="this.contextMenuItemSelected($event)"
  ></sis-context-menu>
</div>
