import moment from 'moment';
import { LogbookEntry } from 'src/app/core/components/logbook/domain/logbook-entry.model';

export class LogbookEntryAdapter {
  static adapt(item: any): LogbookEntry[] {
    const result: LogbookEntry[] = [];

    if (item && item.length > 0) {
      item.forEach((element: any) => {
        const entry = new LogbookEntry({
          id: element.id,
          userGuid: element.userGuid,
          email: element.email,
          tenantGuid: element.tenantGuid,
          timestamp: new Date(element.timestamp),
          featureIds: element.featureIds ?? [],
          action: element.action,
          value: element.value,
          formattedTimestamp: moment.utc(element.timestamp).local().format('DD.MM.YYYY HH:mm:ss'),
        });
        result.push(entry);
      });
    }

    return result;
  }
}
