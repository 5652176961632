<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.close()" data-test="breakdowninfo-library-modal-close-btn">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title translate>breakdowninfo.phrase.breakdownInfoLibrary</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="sis-modal-grid">
    <div>
      <ion-list>
        <ion-item class="sis-library-row" *ngFor="let item of this.library; let i = index">
          <ion-label>
            <sis-breakdowninfo-item [entry]="item" (click)="this.showLibraryItem(item)"></sis-breakdowninfo-item>
          </ion-label>
        </ion-item>
      </ion-list>
    </div>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'breakdowninfo.phrase.newEntry'"
      [width]="this.bigScreenMode ? '200px' : '100%'"
      [btnType]="'Secondary'"
      (sisClick)="showLibraryItem(null)"
      data-test="breakdowninfo-library-modal-new-entry-btn"
    ></sis-general-button>
  </div>
</ion-footer>
