import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateFormat',
  pure: false,
})
@Injectable({ providedIn: 'root' })
export class DateFormatPipe implements PipeTransform {
  transform(date: Date): string {
    return moment(date).local().format('DD.MM.YYYY');
  }
}
