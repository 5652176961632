<ion-header>
  <ion-toolbar>
    <ion-title data-test="sis-journey-editor-title">
      {{ (this.journey.items == null ? 'timetable.phrase.newJourney' : 'timetable.phrase.editJourney') | translate }}
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="close()" data-test="timetable-close-modal-button"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="sis-modal-grid">
    <ion-row>
      <form [formGroup]="this.formGroup">
        <ion-row class="sis-timetable-editor-row">
          <ion-col size="4">
            <ion-label class="sis-journey-editor-label" fixed>{{
              'timetable.term.vehicleNumberAbbr' | translate
            }}</ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-input
              type="text"
              class="sis-modal-input sis-modal-text-input"
              formControlName="vehicleNumber"
              maxlength="10"
              size="10"
              [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls.vehicleNumber?.errors }"
              data-test="timetable-vehicle-number-input"
            ></ion-input>
          </ion-col>
        </ion-row>

        <ion-row
          *ngIf="!this.hasMoreThanTwoStations"
          class="sis-timetable-editor-row"
          data-test="sis-timetable-editor-direction-row"
        >
          <ion-col size="4">
            <ion-label class="sis-journey-editor-label" fixed>{{ 'timetable.term.direction' | translate }}</ion-label>
          </ion-col>
          <ion-col>
            <ion-row class="sis-timetable-editor-row">
              <ion-radio-group formControlName="direction" style="width: 100%">
                <ion-col size="2">
                  <ion-col size="3" style="padding-left: 0px; margin-left: -5px">
                    <ion-radio
                      class="sis-radio-button-small"
                      [value]="1"
                      [disabled]="!this.isNewJourney"
                      color="secondary"
                      data-test="timetable-direction-radio"
                    ></ion-radio>
                  </ion-col>
                  <ion-col size="3">
                    <a
                      ngClass="sis-timetable-clickable-no-permission"
                      data-test="timetable-direction-icon"
                      matTooltip="{{ 'timetable.term.up' | translate }}"
                    >
                      <ion-icon name="arrow-up-outline" class="sis-timetable-icon-small"></ion-icon>
                    </a>
                  </ion-col>
                </ion-col>
                <ion-col size="2">
                  <ion-col size="3">
                    <ion-radio
                      class="sis-radio-button-small"
                      [value]="2"
                      [disabled]="!this.isNewJourney"
                      color="secondary"
                      data-test="timetable-direction-radio"
                    ></ion-radio>
                  </ion-col>
                  <ion-col size="3">
                    <a
                      ngClass="sis-timetable-clickable-no-permission"
                      data-test="timetable-direction-icon"
                      matTooltip="{{ 'timetable.term.down' | translate }}"
                    >
                      <ion-icon name="arrow-down-outline" class="sis-timetable-icon-small"></ion-icon>
                    </a>
                  </ion-col>
                </ion-col>
                <ion-col size="2">
                  <ion-col size="3">
                    <ion-radio
                      class="sis-radio-button-small"
                      [value]="0"
                      [disabled]="!this.isNewJourney"
                      color="secondary"
                      data-test="timetable-direction-radio"
                    ></ion-radio>
                  </ion-col>
                  <ion-col size="3">
                    <a
                      ngClass="sis-timetable-clickable-no-permission"
                      data-test="timetable-direction-icon"
                      matTooltip="{{ 'timetable.term.both' | translate }}"
                    >
                      <ion-icon name="arrow-up-outline" class="sis-timetable-icon-small"></ion-icon>
                      <ion-icon name="arrow-down-outline" class="sis-timetable-icon-small"></ion-icon>
                    </a>
                  </ion-col>
                </ion-col>
              </ion-radio-group>
            </ion-row>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="!this.hasMoreThanTwoStations" class="sis-timetable-editor-row">
          <ion-col size="4">
            <ion-label class="sis-journey-editor-label" fixed>{{
              'timetable.term.departureTime' | translate
            }}</ion-label>
          </ion-col>
          <ion-col>
            <ion-label class="sis-journey-editor-label" data-test="timetable-station-name" fixed>
              <span *ngIf="this.formGroup.value.direction === 0">
                {{ this.stations[0].name }} / {{ this.stations[1].name }}
              </span>
              <span *ngIf="this.formGroup.value.direction === 1">
                {{ this.stations[0].name }}
              </span>
              <span *ngIf="this.formGroup.value.direction === 2">
                {{ this.stations[1].name }}
              </span>
            </ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-input
              type="time"
              formControlName="departureTime"
              class="sis-modal-input"
              data-test="timetable-departure-time-input"
              [ngClass]="{ 'sis-modal-input-error': this.formGroup.errors?.departureTimesInvalid }"
              required
            ></ion-input>
          </ion-col>
        </ion-row>

        <ng-container *ngIf="this.hasMoreThanTwoStations">
          <ion-row
            *ngFor="let station of this.stations; let i = index"
            class="sis-timetable-editor-row"
            data-test="sis-timetable-time-input"
          >
            <ion-col size="4">
              <ion-label *ngIf="i == 0" class="sis-journey-editor-label" fixed>{{
                'timetable.term.departureTime' | translate
              }}</ion-label>
            </ion-col>
            <ion-col>
              <ion-label class="sis-journey-editor-label" data-test="timetable-station-name" fixed>
                {{ station.name }}
              </ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-input
                type="time"
                [formControlName]="'departureTime_' + station.guid"
                class="sis-modal-input"
                [ngClass]="{ 'sis-modal-input-error': this.formGroup.errors?.departureTimesInvalid }"
                data-test="timetable-departure-time-input"
                required
              ></ion-input>
            </ion-col>
          </ion-row>
        </ng-container>
        <ion-row class="sis-timetable-editor-row">
          <ion-col size="4">
            <ion-label class="sis-journey-editor-label" fixed>{{ 'timetable.term.validity' | translate }}</ion-label>
          </ion-col>
          <ion-col size="4" class="vertical-align-content">
            <ion-input
              formControlName="date"
              type="date"
              class="sis-global-input"
              (ionChange)="onDateChanged()"
              data-test="timetable-date-input"
            ></ion-input>
          </ion-col>
          <ion-col size="4" class="vertical-align-content">
            <a
              *ngIf="this.formGroup.value.date"
              class="sis-timetable-clickable"
              (click)="this.clearDate()"
              style="padding: 3px 6px"
            >
              <ion-icon name="close-outline" class="sis-timetable-icon-small"> </ion-icon>
            </a>
          </ion-col>
        </ion-row>
        <ion-row class="vertical-align-content sis-timetable-editor-checkbox-row">
          <ion-col size="1.7" (click)="this.onCheckBoxAreaClick('mo')">
            <ion-checkbox
              formControlName="mo"
              color="secondary"
              data-test="timetable-weekday-checkbox-1"
            ></ion-checkbox>
            <div>{{ 'general.abbr.weekday.1' | translate }}</div>
          </ion-col>
          <ion-col size="1.7" (click)="this.onCheckBoxAreaClick('tu')">
            <ion-checkbox
              formControlName="tu"
              color="secondary"
              data-test="timetable-weekday-checkbox-2"
            ></ion-checkbox>
            <div>
              {{ 'general.abbr.weekday.2' | translate }}
            </div>
          </ion-col>
          <ion-col size="1.7" (click)="this.onCheckBoxAreaClick('we')">
            <ion-checkbox
              formControlName="we"
              color="secondary"
              data-test="timetable-weekday-checkbox-3"
            ></ion-checkbox>
            <div>
              {{ 'general.abbr.weekday.3' | translate }}
            </div>
          </ion-col>
          <ion-col size="1.7" (click)="this.onCheckBoxAreaClick('th')">
            <ion-checkbox
              formControlName="th"
              color="secondary"
              data-test="timetable-weekday-checkbox-4"
            ></ion-checkbox>
            <div>
              {{ 'general.abbr.weekday.4' | translate }}
            </div>
          </ion-col>
          <ion-col size="1.7" (click)="this.onCheckBoxAreaClick('fr')">
            <ion-checkbox
              formControlName="fr"
              color="secondary"
              data-test="timetable-weekday-checkbox-5"
            ></ion-checkbox>
            <div>
              {{ 'general.abbr.weekday.5' | translate }}
            </div>
          </ion-col>
          <ion-col size="1.7" (click)="this.onCheckBoxAreaClick('sa')">
            <ion-checkbox
              formControlName="sa"
              color="secondary"
              data-test="timetable-weekday-checkbox-6"
            ></ion-checkbox>
            <div>
              {{ 'general.abbr.weekday.6' | translate }}
            </div>
          </ion-col>
          <ion-col size="1.7" (click)="this.onCheckBoxAreaClick('su')">
            <ion-checkbox
              formControlName="su"
              color="secondary"
              data-test="timetable-weekday-checkbox-0"
            ></ion-checkbox>
            <div>
              {{ 'general.abbr.weekday.0' | translate }}
            </div>
          </ion-col>
        </ion-row>
      </form>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [disabled]="!this.formGroup.dirty || this.isSaving"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [showSpinner]="this.isSaving"
      width="120px"
      data-test="timetable-save-button"
    >
    </sis-general-button>
    <sis-general-button [text]="'general.term.cancel'" [btnType]="'Secondary'" (sisClick)="this.close()" width="120px">
    </sis-general-button>
  </div>
</ion-footer>
