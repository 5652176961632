import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { BreakdownInfoActiveText } from 'src/app/breakdowninfo/domain/breakdowninfo-activetext.model';
import { BreakdownInfoLibraryService } from 'src/app/breakdowninfo/domain/breakdowninfo-library.service';
import { BreakdownInfoLibraryEntry } from 'src/app/breakdowninfo/domain/breakdowninfo-library-entry.model';
import { Unsubscriber } from 'src/app/core/unsubscriber';

@Component({
  selector: 'sis-breakdowninfo-item',
  templateUrl: './breakdowninfo-item.component.html',
  styleUrls: ['./breakdowninfo-item.component.scss'],
})
export class BreakdownInfoItemComponent extends Unsubscriber implements OnChanges, OnInit {
  @Input() entry: BreakdownInfoLibraryEntry | BreakdownInfoActiveText;

  color: string;
  title: string;
  text: string;

  constructor(
    private translateService: TranslateService,
    private breakdownInfoLibraryService: BreakdownInfoLibraryService
  ) {
    super();
  }

  ngOnInit(): void {
    this.breakdownInfoLibraryService.library$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.update());
    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.update());
  }

  ngOnChanges(): void {
    this.update();
  }

  private update() {
    if (!this.entry) {
      return;
    }

    const langKey = this.translateService.currentLang;
    const entry = this.entry.languageMap.get(langKey);

    this.color = this.entry.color;
    this.title = entry.title;
    this.text = entry.text;
  }
}
