import { Directive } from '@angular/core';
import { Unsubscriber } from 'src/app/core/unsubscriber';

@Directive()
export abstract class WidgetBase extends Unsubscriber {
  static valueUnknown = '?';

  textPositionX = '50%';
  textPositionY = '90%';
  fontSize = '40px';
}
