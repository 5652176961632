import { Component, OnInit } from '@angular/core';
import { combineLatest, filter, map, switchMap, takeUntil } from 'rxjs';
import { MathUtils } from 'src/app/core/utils/mathUtils';
import { RopewayEcoModeSettingsService } from 'src/app/maps/domain/ropeway-ecomode/ropeway-ecomode-settings.service';
import { RopewayEcoModeLivedataService } from 'src/app/maps/livedata/ropeway-ecomode-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-ropeway-ecomode-widget',
  templateUrl: './ropeway-ecomode-widget.component.html',
  styleUrls: ['./ropeway-ecomode-widget.component.scss'],
})
export class RopewayEcoModeWidgetComponent extends WidgetBase implements OnInit {
  private readonly colorEnabled = 'var(--ion-color-primary)';
  private readonly colorDisabled = 'var(--ion-color-danger)';
  private readonly colorNoData = 'var(--ion-color-medium)';

  color: string = this.colorNoData;
  targetSpeed: string;

  constructor(
    private ropewayEcoModeLivedataService: RopewayEcoModeLivedataService,
    private ropewayEcoModeSettingsService: RopewayEcoModeSettingsService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedMapElementService.selectedRopeway$
      .pipe(
        filter((ropeway) => !!ropeway),
        switchMap((ropeway) =>
          combineLatest([
            this.ropewayEcoModeLivedataService.ropewayEcoModeLiveData$.pipe(
              map((livedata) => livedata.find((d) => d.deviceSisId === ropeway.sisId))
            ),
            this.ropewayEcoModeSettingsService.ropewayEcoModeSettings$.pipe(
              map((settings) => settings.find((d) => d.ropewaySisId === ropeway.sisId))
            ),
          ])
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([livedata, ecoModeSettings]) => {
        if (livedata?.outdated) {
          this.color = this.colorNoData;
          this.targetSpeed = WidgetBase.valueUnknown;
          return;
        }

        if (!livedata || !ecoModeSettings.ecoModeEnabledCockpit) {
          this.color = this.colorNoData;
          this.targetSpeed = '';
          return;
        }

        this.color = livedata.ecoModeEnabledPLC ? this.colorEnabled : this.colorDisabled;
        this.targetSpeed = `${MathUtils.roundTo(livedata.targetSpeedMps, 1)} m/s`;
      });
  }
}
