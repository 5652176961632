import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { SisMediaAssetOverrideUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { SisMediaAssetStatus } from 'src/app/sismedia/domain/sismedia-asset-status.enum';
import { SisMediaItem } from 'src/app/sismedia/domain/sismedia-item.model';
import { SisMediaAssetOverrideEntry } from 'src/app/sismedia/sismedia-asset-override/domain/sismedia-asset-override-entry.model';
import { SisMediaAssetOverrideService } from 'src/app/sismedia/sismedia-asset-override/sismedia-asset-override.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Component({
  selector: 'sis-sismedia-asset-override-item',
  templateUrl: './asset-override-item.component.html',
  styleUrls: ['./asset-override-item.component.scss'],
})
export class AssetOverrideItemComponent extends Unsubscriber implements OnInit {
  @Input() assetOverrideItem: SisMediaAssetOverrideEntry;
  @Input() assetOverrideItems: SisMediaAssetOverrideEntry[];
  @Input() sisMediaItem: SisMediaItem;
  @Input() showHeader: boolean;
  @Input() writePermission: boolean;
  @Output() hasChangedEmitter = new EventEmitter<{
    guid: string;
    changed: boolean;
  }>();

  private readonly dataChanged$ = new Subject<SisMediaAssetOverrideEntry>();

  priorityInputCss: string;
  descriptionInputCss: string;
  isDeleting = false;
  isSaving = false;
  hasChanged = false;
  bigScreenMode: boolean;
  oldItem: SisMediaAssetOverrideEntry;

  constructor(
    private sisMediaAssetOverrideService: SisMediaAssetOverrideService,
    private screenSizeService: ScreenSizeService,
    private userMessageService: UserMessageService,
    private confirmationDialogService: ConfirmationDialogService,
    private eventBus: EventBusService
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataChanged$.pipe(debounceTime(300), takeUntil(this.onDestroy$)).subscribe(async (data) => {
      await this.sisMediaAssetOverrideService.updateAssetOverride(data, SisMediaAssetOverrideService.updateOperation);
      this.isSaving = false;
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.eventBus
      .observe(SisMediaAssetOverrideUpdatedEvent)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.checkChanges());

    this.oldItem = JSON.parse(JSON.stringify(this.assetOverrideItem));

    this.checkChanges();
  }

  startTimeChanged(event: any): void {
    const newTime = event.target.value;
    if (this.assetOverrideItem.startTime === newTime || !this.writePermission) {
      return;
    }
    this.assetOverrideItem.startTime = newTime;

    this.checkChanges();
  }

  endTimeChanged(event: any): void {
    const newTime = event.target.value;
    if (this.assetOverrideItem.endTime === newTime || !this.writePermission) {
      return;
    }
    this.assetOverrideItem.endTime = newTime;

    this.checkChanges();
  }

  overrideStatusChanged(overrideStatus: SisMediaAssetStatus): void {
    if (this.assetOverrideItem.overrideStatus === overrideStatus || !this.writePermission) {
      return;
    }
    this.assetOverrideItem.overrideStatus = overrideStatus;

    this.checkChanges();
  }

  activeChanged(event: any): void {
    if (this.writePermission) {
      this.assetOverrideItem.active = event.detail.checked;

      this.checkChanges();
    }
  }

  descriptionChanged(event: any): void {
    const newDescription = event.target.value;

    if (this.assetOverrideItem.description === newDescription) {
      return;
    }

    if (!newDescription) {
      this.descriptionInputCss = 'sis-input-invalid';
      this.presentErrorToast('user.message.assetOverrideInvalidDescription');
      return;
    }
    this.assetOverrideItem.description = newDescription;
    this.descriptionInputCss = '';

    this.checkChanges();
  }

  priorityChanged(event: any): void {
    const newPriority = event.target.value;

    if (this.assetOverrideItem.priority === newPriority || !this.writePermission) {
      return;
    }

    if (
      this.assetOverrideItems.find(
        (override) => override.priority === Number(newPriority) && override.guid !== this.assetOverrideItem.guid
      )
    ) {
      this.priorityInputCss = 'sis-input-invalid';
      this.presentErrorToast('user.message.assetOverrideDuplicatedPriority');
      return;
    }

    if (!newPriority || newPriority < 0 || newPriority > 100) {
      this.priorityInputCss = 'sis-input-invalid';
      this.presentErrorToast('user.message.assetOverridePriorityOutOfRange');
      return;
    }
    this.assetOverrideItem.priority = +newPriority;
    this.priorityInputCss = '';

    this.checkChanges();
  }

  reset() {
    this.assetOverrideItem = JSON.parse(JSON.stringify(this.oldItem));

    this.checkChanges();
  }

  async deleteEntry(): Promise<void> {
    if (this.writePermission) {
      const confirmed = await this.confirmationDialogService.presentAlert(
        'general.phrase.delete',
        'general.term.delete'
      );
      if (confirmed) {
        this.isDeleting = true;
        await this.sisMediaAssetOverrideService.updateAssetOverride(
          this.assetOverrideItem,
          SisMediaAssetOverrideService.deleteOperation
        );
      }
    }
  }

  saveEntry(): void {
    if (this.writePermission) {
      this.isSaving = true;
      this.oldItem = JSON.parse(JSON.stringify(this.assetOverrideItem));
      this.dataChanged$.next(this.assetOverrideItem);

      this.checkChanges();
    }
  }

  private presentErrorToast(translateKey: string) {
    const userMessage = new UserMessage({
      message: translateKey,
      icon: UserMessageIcon.failed,
      durationMs: 3000,
      position: 'top',
      color: UserMessageColor.red,
    });
    this.userMessageService.presentToast(userMessage);
  }

  private checkChanges(): void {
    const changed = JSON.stringify(this.assetOverrideItem) !== JSON.stringify(this.oldItem);

    this.hasChanged = changed;
    this.hasChangedEmitter.emit({ guid: this.assetOverrideItem.guid, changed });
  }
}
