import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { BlobStorageContainerService } from 'src/app/core/blob-storage/blob-storage-container.service';
import { ComponentCanDeactivate } from 'src/app/core/guards/pending-changes.guard';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { MediaCenterPlaylistsService } from 'src/app/media-center/playlists/media-center-playlists.service';
import { MediaCenterScreenService } from 'src/app/media-center/screens/media-center-screens.service';
import { MediaCenterSlidesService } from 'src/app/media-center/slides/media-center-slides.service';

@Component({
  templateUrl: './media-center.page.html',
  styleUrls: ['./media-center.page.scss'],
})
export class MediaCenterPage extends Unsubscriber implements OnInit, ComponentCanDeactivate {
  readonly screens = 'screens';
  readonly playlists = 'playlists';
  readonly slides = 'slides';

  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  tabs: Array<{ name: string; count$: Observable<string> }> = [];
  selectedTab: string;
  unsavedChanges: boolean;

  private readonly screensCount$ = this.screensService.screens$.pipe(
    map((p) => (p != null ? ` (${p.length})` : '')),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );
  private readonly playlistCount$ = this.playlistsService.playlists$.pipe(
    map((p) => (p != null ? ` (${p.length})` : '')),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );
  private readonly slidesCount$ = this.slidesService.slides$.pipe(
    map((p) => (p != null ? ` (${p.length})` : '')),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private bigScreenTabs: Array<{ name: string; count$: Observable<string> }> = [
    { name: this.screens, count$: this.screensCount$ },
    { name: this.playlists, count$: this.playlistCount$ },
    { name: this.slides, count$: this.slidesCount$ },
  ];
  private mobileScreenTabs: Array<{ name: string; count$: Observable<string> }> = [
    { name: this.screens, count$: this.screensCount$ },
    { name: this.slides, count$: this.slidesCount$ },
  ];

  constructor(
    private screenSizeService: ScreenSizeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private playlistsService: MediaCenterPlaylistsService,
    private screensService: MediaCenterScreenService,
    private slidesService: MediaCenterSlidesService,
    private blobStorageContainerService: BlobStorageContainerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => {
      if (bigScreenMode) {
        this.tabs = this.bigScreenTabs;
      } else {
        this.tabs = this.mobileScreenTabs;
      }
    });

    this.playlistsService.unsavedChanges$.pipe(takeUntil(this.onDestroy$)).subscribe((changes) => (this.unsavedChanges = changes));

    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.setSelectedTab(event.urlAfterRedirects);
      });
    this.setSelectedTab(this.router.url);

    // Subscribe to "mediaCenterBlobStorageUpdatedEvent" to display a toast about successful or failed video conversion without staying in upload mode.
    this.blobStorageContainerService.mediaCenterBlobStorageUpdatedEvent$.pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  selectionChanged(selectedTab: string) {
    if (!this.unsavedChanges) {
      this.selectedTab = selectedTab;
      this.router.navigate([selectedTab], { relativeTo: this.activatedRoute.parent, queryParamsHandling: 'preserve' });
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return !this.unsavedChanges;
  }

  private setSelectedTab(url: string): void {
    const lastUrlPart = url?.split('?')[0].split('/').pop();
    if (this.tabs.some((t) => t.name.includes(lastUrlPart))) {
      this.selectedTab = lastUrlPart;
    }
  }
}
