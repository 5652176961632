import { FeatureAdapter } from 'src/app/domain/feature/feature.adapter';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { Coords } from 'src/app/maps/domain/coords.model';

export class TenantAdapter {
  static adapt(item: any): Tenant[] {
    const tenants: Tenant[] = [];

    if (item) {
      item.forEach((element: any) => {
        const tenant = this.adaptTenant(element);

        if (tenant) {
          tenants.push(tenant);
        }
      });
    }
    return tenants;
  }

  static adaptTenant(item: any): Tenant | null {
    if (item) {
      let positionGeoMap: Coords = { x: -1000, y: -1000 };
      if (item.positionGeoMap && item.positionGeoMap !== '') {
        try {
          const coordArray: number[] = JSON.parse(item.positionGeoMap);
          positionGeoMap = { x: coordArray[0], y: coordArray[1] };
        } catch (ex) {
          console.error(`Unable to parse geomap coordinates: ${item.positionGeoMap}`);
        }
      }

      const features = FeatureAdapter.adapt(item.features);

      return new Tenant(
        {
          guid: item.guid,
          sisId: item.sisId,
          alias: item.alias,
          fullname: item.fullname,
          gatewayId: item.gatewayId,
          panoMapFilename: item.panoMapFilename,
          logoFilename: item.logoFilename,
          positionGeoMap,
          weatherTrendLink: item.weatherTrendLink,
          maxUsers: item.maxUsers,
          hasRopewaysOrLifts: item.hasRopeways,
          hasMySqlRouter: item.mySqlRouter,
          underMaintenance: item.underMaintenance,
          timeZoneId: item.timeZoneId,
        },
        features
      );
    }

    return null;
  }
}
