import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';

@Component({
  selector: 'sis-devices-on-detailview',
  templateUrl: './devices-on-detailview.component.html',
  styleUrls: ['./devices-on-detailview.component.scss'],
})
export class DevicesOnDetailViewComponent implements OnInit {
  ropeways: Ropeway[];

  constructor(private masterDataService: MasterdataService) {}

  ngOnInit() {
    this.masterDataService.masterData$.pipe(take(1)).subscribe((data) => {
      this.ropeways = data.ropeways.sort((a, b) => (a.alias < b.alias ? -1 : 1));
    });
  }
}
