import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { filter, takeUntil, withLatestFrom } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { TimetableIntervalDayUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { TimetableInterval } from 'src/app/timetable/domain/timetable-interval.model';
import { TimetableIntervalDay } from 'src/app/timetable/domain/timetable-interval-day.model';
import { TimetableService } from 'src/app/timetable/timetable.service';

@Component({
  templateUrl: './interval-day-editor.component.html',
  styleUrls: ['./interval-day-editor.component.scss'],
})
export class IntervalDayEditorComponent extends Unsubscriber implements OnInit {
  @Input() interval: TimetableInterval;
  @Input() day: TimetableIntervalDay;

  editedDay: TimetableIntervalDay;
  formGroup: FormGroup;
  isSaving = false;
  isEdited = false;

  constructor(
    private modalCtrl: ModalController,
    private timetableService: TimetableService,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.reset();

    this.formGroup = new FormGroup({
      operatingStart: new FormControl(this.editedDay.operatingStart, [Validators.required]),
      operatingEnd: new FormControl(this.editedDay.operatingEnd, [Validators.required]),
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((values) => {
      this.editedDay.operatingStart = values.operatingStart;
      this.editedDay.operatingEnd = values.operatingEnd;

      this.isEdited = JSON.stringify(this.day) !== JSON.stringify(this.editedDay);
    });

    this.eventBus
      .observe(TimetableIntervalDayUpdatedEvent)
      .pipe(
        withLatestFrom(this.userSettingsService.userSettings$),
        filter(([event, user]) => event.changedBy === user.userGuid),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        if (this.isSaving) {
          this.close();
        }
      });
  }

  async save(): Promise<void> {
    this.isSaving = true;

    await this.timetableService.updateIntervalDay({
      guid: this.editedDay.guid,
      intervalGuid: this.editedDay.intervalGuid,
      date: this.editedDay.date,
      journeys: [],
      operatingStart: this.editedDay.operatingStart,
      operatingEnd: this.editedDay.operatingEnd,
    });
  }

  async close(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  reset(): void {
    this.editedDay = JSON.parse(JSON.stringify(this.day));
    this.isEdited = false;
  }
}
