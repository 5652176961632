import { SisConverter } from 'src/app/core/utils/sis-converter';
import { RopewayLivedata } from 'src/app/maps/livedata/ropeway-livedata.model';
import { SisOutdatedHelper } from 'src/app/maps/livedata/sis-outdated-helper';

export class RopewayLivedataAdapter {
  static adapt(item: any): RopewayLivedata[] {
    const livedata: RopewayLivedata[] = [];

    if (item) {
      item.forEach((e: any) => {
        let vehicleData: Map<string, number[]>;

        if (e.Vehicle) {
          vehicleData = JSON.parse(e.Vehicle);
        }

        livedata.push({
          deviceSisId: e.DeviceSisId,
          deviceTimestamp: e.DeviceTimestamp,
          gatewayTimestamp: e.GatewayTimestamp,
          actualSpeedMps: SisConverter.toNumberOrUndefined(e.ActualSpeedMps),
          targetSpeedMps: SisConverter.toNumberOrUndefined(e.TargetSpeedMps),
          ropeTensionForceKn: SisConverter.toNumberOrUndefined(e.RopeTensionForceKn),
          ropeTensionPressureHpa: SisConverter.toNumberOrUndefined(e.RopeTensionPressureHpa),
          vehicleCount: SisConverter.toNumberOrUndefined(e.VehicleCount),
          vehicleCountPercent: SisConverter.toNumberOrUndefined(e.VehicleCountPercent),
          vehicleOnTrackCount: SisConverter.toNumberOrUndefined(e.VehicleOnTrackCount),
          vehicleOnTrackCountPercent: SisConverter.toNumberOrUndefined(e.VehicleOnTrackCountPercent),
          driveMotorTorqueNm: SisConverter.toNumberOrUndefined(e.DriveMotorTorqueNm),
          driveMotorTorquePercent: SisConverter.toNumberOrUndefined(e.DriveMotorTorquePercent),
          driveMotorCurrentAmpere: SisConverter.toNumberOrUndefined(e.DriveMotorCurrentAmpere),
          drivePowerKw: SisConverter.toNumberOrUndefined(e.DrivePowerKw),
          driveGearTemperatureDegree: SisConverter.toNumberOrUndefined(e.DriveGearTemperatureDegree),
          ropeKm: SisConverter.toNumberOrUndefined(e.RopeKm),
          operatingHours: SisConverter.toNumberOrUndefined(e.OperatingHours),
          tripCount: SisConverter.toNumberOrUndefined(e.TripCount),
          vehicleData,
          cabinPositionMeter: SisConverter.toNumberOrUndefined(e.CabinPositionMeter),
          outdated: SisOutdatedHelper.isOutdated(e.GatewayTimestamp),
        });
      });
    }

    return livedata;
  }
}
