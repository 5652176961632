import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MapIconClickedEvent } from 'src/app/core/eventbus/events';
import { MeteoForecastService } from 'src/app/maps/livedata/meteo-forecast.service';
import { MeteoLivedataService } from 'src/app/maps/livedata/meteo-livedata.service';
import { DetailView } from 'src/app/maps/widget-list/widget-list-services/domain/detailview.model';
import { Widget } from 'src/app/maps/widget-list/widget-list-services/domain/widget.model';
import { WidgetName } from 'src/app/maps/widget-list/widget-list-services/domain/widgetname.enum';
import { AlarmWidgetComponent } from 'src/app/maps/widgets/alarm-widget/alarm-widget.component';
import { TemperatureChartComponent } from 'src/app/maps/widgets/charts/temperature-chart/temperature-chart.component';
import { WetBulbChartComponent } from 'src/app/maps/widgets/charts/wet-bulb-chart/wet-bulb-chart.component';
import { WindChartComponent } from 'src/app/maps/widgets/charts/wind-chart/wind-chart.component';
import { AlarmDetailViewComponent } from 'src/app/maps/widgets/detailviews/alarm-detailview/alarm-detailview.component';
import { TemperatureWidgetComponent } from 'src/app/maps/widgets/temperature-widget/temperature-widget.component';
import { WetBulbWidgetComponent } from 'src/app/maps/widgets/wet-bulb-widget/wet-bulb-widget.component';
import { WindWidgetComponent } from 'src/app/maps/widgets/wind-widget/wind-widget.component';

@Injectable({
  providedIn: 'root',
})
export class MeteoStationWidgetListService {
  constructor(
    private eventBus: EventBusService,
    private meteoForecastService: MeteoForecastService,
    private meteoLiveDataService: MeteoLivedataService
  ) {}

  buildWidgetList(mapIconClickedEvent: MapIconClickedEvent): Observable<Widget[]> {
    return combineLatest([this.meteoLiveDataService.meteoLiveDatas$, this.meteoForecastService.forecasts$]).pipe(
      map(([meteoLiveDatas, meteoForecasts]) => {
        const widgetsToRemove: WidgetName[] = [];

        const clickedMeteoStationLiveData = meteoLiveDatas?.find(
          (liveData) => liveData.meteoDeviceSisId === mapIconClickedEvent.meteoStation.meteoDeviceSisId
        );
        const clickedMeteoStationForecastData = meteoForecasts?.find(
          (forecastData) => forecastData.deviceSisId === mapIconClickedEvent.meteoStation.meteoDeviceSisId
        );

        const hasIcingIntensityForecastData = clickedMeteoStationForecastData?.icingIntensity != null;
        const hasWetBulbTemperatureCelsiusForecast = clickedMeteoStationForecastData?.wetBulbTemperatureCelsius != null;
        const hasWetBulbTemperatureCelsiusLiveData = clickedMeteoStationLiveData?.wetBulbTemperatureCelsius != null;
        const hasTemperatureCelsiusLiveData = clickedMeteoStationLiveData?.temperatureCelsius != null;

        if (!hasIcingIntensityForecastData) {
          widgetsToRemove.push(WidgetName.Icing);
        }

        if (!hasWetBulbTemperatureCelsiusForecast && !hasWetBulbTemperatureCelsiusLiveData) {
          widgetsToRemove.push(WidgetName.WetBulb);
        }

        if (!hasTemperatureCelsiusLiveData) {
          widgetsToRemove.push(WidgetName.Temperature);
        }

        const widgets = this.getWidgets(this.eventBus);

        const windWidget = widgets.find((widget) => widget.name === WidgetName.Wind);

        windWidget.expandable =
          mapIconClickedEvent.meteoStation != null || mapIconClickedEvent?.ropeway?.meteoStations?.length > 0;
        if (mapIconClickedEvent.meteoStation != null) {
          windWidget.expand();
        } else {
          windWidget.close();
        }

        return widgets.filter((widget) => !widgetsToRemove.includes(widget.name));
      }),
      take(1)
    );
  }

  private getWidgets(eventBus: EventBusService): Widget[] {
    return [
      new Widget({
        name: WidgetName.Alarm,
        component: AlarmWidgetComponent,
        detailView: new DetailView({ component: AlarmDetailViewComponent }),
        tooltip: 'alarming.term.alarms',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Wind,
        component: WindWidgetComponent,
        detailView: new DetailView({ component: WindChartComponent, modal: true }),
        tooltip: 'ropeway.term.wind',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Temperature,
        component: TemperatureWidgetComponent,
        detailView: new DetailView({ component: TemperatureChartComponent, modal: true }),
        tooltip: 'ropeway.term.temperature',
        eventBus,
      }),
      new Widget({
        name: WidgetName.WetBulb,
        component: WetBulbWidgetComponent,
        detailView: new DetailView({ component: WetBulbChartComponent, modal: true }),
        tooltip: 'ropeway.term.wetBulbTemp',
        eventBus,
      }),
    ];
  }
}
