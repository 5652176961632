<ion-grid class="sis-detailview-container sis-global-no-scrollbar">
  <div class="sis-sticky">
    <ion-row class="sis-title-row">
      <ion-col size="5.75">{{ 'destination.term.location' | translate }}</ion-col>
      <ion-col *ngIf="firstEntriesAvailable" size="2" class="ion-text-right">
        <ion-icon
          name="sis-first-entries"
          class="sis-icon"
          matTooltip="{{ 'destination.term.firstEntries' | translate }}"
        ></ion-icon>
      </ion-col>
      <ion-col *ngIf="frequenciesAvailable" size="2" class="ion-text-right">
        <ion-icon
          name="sis-frequencies"
          class="sis-icon"
          matTooltip="{{ 'destination.term.personFrequencies' | translate }}"
        ></ion-icon>
      </ion-col>
      <ion-col *ngIf="forecastAvailable" size="2" class="ion-text-right">
        <ion-icon
          name="sis-kassandra"
          class="sis-icon"
          matTooltip="{{ 'destination.term.kassandraForecast' | translate }}"
        ></ion-icon>
      </ion-col>
      <ion-col size="0.25"></ion-col>
    </ion-row>
    <hr />
  </div>

  <ion-row
    *ngFor="let entries of this.dataForDetailView; let i = index; let odd = odd"
    class="sis-data-row"
    [ngClass]="{ odd: odd }"
  >
    <ion-col size="5.75" [ngClass]="entries.wholeDestination ? 'sis-font-bold' : 'sis-font-normal'">{{
      entries.name
    }}</ion-col>
    <ion-col
      *ngIf="firstEntriesAvailable"
      size="2"
      [ngClass]="entries.wholeDestination ? 'sis-font-bold' : 'sis-font-normal'"
      class="ion-text-right"
      >{{ entries.measuredValue == null ? '?' : entries.measuredValue }}</ion-col
    >
    <ion-col
      *ngIf="frequenciesAvailable"
      size="2"
      [ngClass]="entries.wholeDestination ? 'sis-font-bold' : 'sis-font-normal'"
      class="ion-text-right"
      >{{ entries.frequenciesValue == null ? '?' : entries.frequenciesValue }}</ion-col
    >
    <ion-col
      *ngIf="forecastAvailable && entries.hasForecast"
      size="2"
      [ngClass]="entries.wholeDestination ? 'sis-font-bold' : 'sis-font-normal'"
      class="ion-text-right"
      >{{ entries.forecastValue == null ? '' : entries.forecastValue }}</ion-col
    >
    <ion-col size="0.25"></ion-col>
  </ion-row>
</ion-grid>

<ion-grid class="sis-global-sticky-bottom">
  <ion-row>
    <ion-col>
      <ion-grid style="padding-left: 7px" *ngIf="this.timestamp">
        <ion-row>
          <ion-col>
            <span class="sis-timestamp">{{ 'general.phrase.lastUpdate' | translate }}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="sis-timestamp" data-test="first-entries-timestamp">{{
            this.timestamp | date: 'dd.MM.yyyy HH:mm'
          }}</ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
    <ion-col *ngIf="forecastAvailable" size="auto" style="padding-right: 7px">
      <a href="https://kassandra.siscloud.ch/" target="_blank" data-test="kassandra-link"
        >Kassandra<ion-icon class="sis-icon-open" name="open-outline"></ion-icon
      ></a>
    </ion-col>
  </ion-row>
</ion-grid>
