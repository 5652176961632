import { Component } from '@angular/core';
import { LiveMessageService } from 'src/app/core/live-message/live-message.service';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { VersionService } from 'src/app/version.service';

@Component({
  selector: 'sis-app-service-loader',
  template: '',
})
export class AppServiceLoader {
  constructor(
    public messageService: LiveMessageService,
    public userMessageService: UserMessageService,
    public versionService: VersionService
  ) {}
}
