import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { CKEditorService } from 'src/app/media-center/ckeditor/ckeditor.service';
import { FileSettings } from 'src/app/media-center/ckeditor/file-settings.model';

@Component({
  selector: 'sis-media-center-image-container',
  templateUrl: './image-container.component.html',
  styleUrls: ['./image-container.component.scss'],
})
export class ImageContainerComponent implements OnInit {
  @Input() image: FileSettings;

  @Output() imageSelected = new EventEmitter<string>();

  resolution: string;

  constructor(private confirmationDialogService: ConfirmationDialogService, private ckEditorService: CKEditorService) {}

  ngOnInit(): void {
    if (this.image.width && this.image.height) {
      this.resolution = `${this.image.width} x ${this.image.height}px`;
    }
  }

  onImageSelected(url: string): void {
    this.imageSelected.emit(url);
  }

  deleteFile(file: FileSettings, event: Event): void {
    event.stopPropagation();
    this.confirmationDialogService
      .presentAlert('mediacenter.phrase.deleteFile', 'mediacenter.term.delete')
      .then((confirm) => {
        if (confirm) {
          file.deleted = true;
          this.ckEditorService.deleteFile(file);
        }
      });
  }
}
