import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayLivedata } from 'src/app/maps/livedata/ropeway-livedata.model';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { ClampingCountResetService } from 'src/app/maps/widgets/detailviews/clampingcount-detailview/clampingcount-reset.service';
import { ClampingCount } from 'src/app/maps/widgets/detailviews/clampingcount-detailview/domain/clampingcount.model';
import { ClampingCountResetMetadata } from 'src/app/maps/widgets/detailviews/clampingcount-detailview/domain/clampingcount-reset-metadata.model';

@Component({
  selector: 'sis-clampingcount-detailview',
  templateUrl: './clampingcount-detailview.component.html',
  styleUrls: ['./clampingcount-detailview.component.scss'],
})
export class ClampingCountDetailViewComponent extends Unsubscriber implements OnInit {
  private readonly clampingCountKey = 'clampingCount';
  private readonly vehicleIdKey = 'id';

  writePermission: boolean;
  clampingCounts: ClampingCount[] = [];
  clampingCountResetMetadata: Map<number, ClampingCountResetMetadata> = new Map<number, ClampingCountResetMetadata>();
  loadingData: boolean = true;

  hasClampingCountResetFeature$: Observable<boolean> = this.destinationService.selectedTenantFeatures$.pipe(
    map((features) => {
      return (
        features?.some((f) => f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_ROPEWAY_CLAMPINGCOUNT_RESET, FeatureAccessLevel.READ))) ??
        false
      );
    })
  );

  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  private clampingCountResetMetaData$: Observable<ClampingCountResetMetadata[]> = this.hasClampingCountResetFeature$.pipe(
    switchMap((hasClampingCountFeature) => (hasClampingCountFeature ? this.clampingCountResetService.clampingCountMetadata$ : of([])))
  );

  constructor(
    private ropewayLiveDataService: RopewayLivedataService,
    private selectedMapElementService: SelectedMapElementService,
    private destinationService: DestinationService,
    private clampingCountResetService: ClampingCountResetService,
    private logbookModalService: LogbookModalService
  ) {
    super();
  }

  ngOnInit() {
    this.clampingCountResetMetaData$.pipe(takeUntil(this.onDestroy$)).subscribe((metadata) => {
      metadata.forEach((m) => {
        this.clampingCountResetMetadata.set(m.vehicleId, m);
      });
    });

    combineLatest([this.selectedMapElementService.selectedRopeway$, this.ropewayLiveDataService.ropewayLiveData$, this.clampingCountResetMetaData$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([ropeway, ropewayLiveDatas]) => {
        this.update(ropeway, ropewayLiveDatas);
        this.loadingData = false;
      });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission =
        features?.some((f) => f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_ROPEWAY_CLAMPINGCOUNT_RESET, FeatureAccessLevel.WRITE))) ??
        false;
    });
  }

  async resetClampingCount(clampingCount: ClampingCount) {
    await this.clampingCountResetService.resetClampingCount(clampingCount);
  }

  async openLogbook(): Promise<void> {
    await this.logbookModalService.presentLogbookModal([[FeatureId.COCKPIT_ROPEWAY_CLAMPINGCOUNT_RESET]]);
  }

  itemId(_: number, clampingCount: ClampingCount): number {
    return clampingCount.vehicleId;
  }

  private async update(ropeway: Ropeway, ropewayLiveDatas: RopewayLivedata[]): Promise<void> {
    if (!ropewayLiveDatas) {
      return;
    }

    if (!ropeway) {
      this.resetToDefault();
      return;
    }

    const ropewayLiveData = ropewayLiveDatas?.find((data: RopewayLivedata) => data.deviceSisId === ropeway.sisId);

    if (ropewayLiveData == null || ropewayLiveData.vehicleData == null || !ropewayLiveData.vehicleData[this.clampingCountKey]) {
      return;
    }

    const vehicleData = ropewayLiveData.vehicleData;
    const vehicleIds: number[] = vehicleData[this.vehicleIdKey];
    const clampingCounts: ClampingCount[] = [];

    for (let i = 0; i < vehicleIds.length; i++) {
      const vehicleId = vehicleIds[i];
      clampingCounts.push({
        clampingCount: vehicleData[this.clampingCountKey][i],
        vehicleId: vehicleId,
        metadata: this.clampingCountResetMetadata.get(vehicleId),
      });
    }

    Object.assign(this.clampingCounts, clampingCounts);
  }

  private resetToDefault(): void {
    this.clampingCounts = [];
    this.loadingData = true;
  }
}
