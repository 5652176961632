export class WidgetUtils {
  static calculateDegreeWithLimit(value: number, maxValue: number, minDegree: number, maxDegree: number): number {
    let ratio = value / maxValue;
    if (ratio > 1) {
      ratio = 1;
    } else {
      if (ratio < 0) {
        ratio = 0;
      }
    }

    const range = Math.abs(maxDegree - minDegree);

    const filled = range * ratio;

    if (minDegree < maxDegree) {
      return minDegree + filled;
    }

    return minDegree - filled;
  }
}
