<ion-grid>
  <ion-row>
    <ion-col></ion-col>
    <ion-col class="sis-global-title"> {{ "error.title" | translate }} </ion-col>
    <ion-col></ion-col>
  </ion-row>
  <ion-row>
    <ion-col></ion-col>
    <ion-col class="sis-text">
      <span class="ion-text-wrap">{{ "error.text" | translate}}</span>
    </ion-col>
    <ion-col></ion-col>
  </ion-row>
</ion-grid>
