import { Component, Input, NgZone, OnChanges, OnInit } from '@angular/core';
import { divIcon, DomUtil, latLng, LatLngExpression, LayerGroup, Marker, marker } from 'leaflet';
import { combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MapIconClickedEvent, MapIconHoveredEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { CustomMarker } from 'src/app/maps/domain/custom-marker.model';
import { CustomMarkerType } from 'src/app/maps/domain/custom-marker-type.model';
import { CustomMarkerIconService } from 'src/app/maps/map/custom-marker-layer/custom-marker-icon/custom-marker-icon.service';
import { MarkerType } from 'src/app/maps/map/marker-edit/domain/marker-type.enum';
import { MarkerEditService } from 'src/app/maps/map/marker-edit/marker-edit.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';

@Component({
  selector: 'sis-custom-marker-icon',
  template: '',
  styleUrls: ['./custom-marker-icon.component.scss'],
})
export class CustomMarkerIconComponent extends Unsubscriber implements OnInit, OnChanges {
  private static readonly cssClass = 'sis-custom-marker';

  private readonly requiredFeature = new Feature(FeatureId.COCKPIT_WEBCAM, FeatureAccessLevel.READ);

  readonly camerasAvailable$ = combineLatest([this.destinationService.selectedTenantFeatures$]).pipe(
    map(([features]) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature)))
  );
  private marker: Marker;

  private selected = false;
  private highlighted = false;

  @Input() layerGroup: LayerGroup;
  @Input() customMarker: CustomMarker;
  @Input() editMode: boolean;

  constructor(
    private eventBus: EventBusService,
    private zone: NgZone,
    private customMarkerIconService: CustomMarkerIconService,
    private screenSizeService: ScreenSizeService,
    private markerEditService: MarkerEditService,
    private selectedMapElementService: SelectedMapElementService,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.camerasAvailable$.pipe(take(1)).subscribe((cameraAvailable) => {
      if (cameraAvailable && this.customMarker.type === CustomMarkerType.Camera) {
        this.loadMarkerIcon();
      }
    });
  }

  ngOnChanges(): void {
    if (this.marker) {
      this.editMode ? this.marker.dragging.enable() : this.marker.dragging.disable();
    }
  }

  private loadMarkerIcon(): void {
    this.customMarkerIconService
      .getIconSvgAsHtml(this.customMarker.iconName)
      .pipe(take(1))
      .subscribe((svg) => {
        if (this.customMarker.positionPanoMap) {
          const latLngMarker = latLng(this.customMarker.positionPanoMap.y, this.customMarker.positionPanoMap.x);
          this.marker = this.createIconMarker(latLngMarker, svg);

          this.layerGroup.addLayer(this.marker);

          this.markerEditService.register(
            this.marker,
            MarkerType.CustomMarker,
            this.customMarker.guid,
            (pos) => (this.customMarker.positionPanoMap = pos),
            () => this.customMarker.positionPanoMap
          );
        }
      });

    combineLatest([this.selectedMapElementService.selectedMapElement$, this.screenSizeService.landscapeMode$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([event, landscapeMode]) => {
        this.selected = event.customMarker === this.customMarker && !landscapeMode;
        this.setHighlight();
      });
  }

  private createIconMarker(latLngExpression: LatLngExpression, svgHtmlContent: string): Marker {
    const icon = divIcon({
      html: svgHtmlContent,
      iconSize: [44, 44],
      popupAnchor: [44, 44],
      iconAnchor: [22, 22],
      className: 'customMarkerIcon',
    });
    const iconMarker: Marker = marker(latLngExpression, { icon: icon });
    iconMarker.on('click', () => this.zone.run(() => this.onIconClick()));
    iconMarker.on('mouseover', () => this.zone.run(() => this.onIconHover(true)));
    iconMarker.on('mouseout', () => this.zone.run(() => this.onIconHover(false)));
    return iconMarker;
  }

  private onIconClick(): void {
    const clickEvent = new MapIconClickedEvent();
    clickEvent.customMarker = this.customMarker;
    this.eventBus.publish(clickEvent);
  }

  private onIconHover(hover: boolean): void {
    if (!this.customMarker) {
      return;
    }

    const event = new MapIconHoveredEvent();
    event.customMarker = this.customMarker;
    event.hover = hover;
    this.eventBus.publish(event);
  }

  private setHighlight(): void {
    const element = this.getIconMarkerElement();
    if (!element) {
      return;
    }

    const cssHighlight = '-highlight';
    const path = element.getElementsByClassName(CustomMarkerIconComponent.cssClass)[0];

    if (this.highlighted || this.selected) {
      path.classList.add(CustomMarkerIconComponent.cssClass + cssHighlight);
    } else {
      path.classList.remove(CustomMarkerIconComponent.cssClass + cssHighlight);
    }
  }

  private getIconMarkerElement(): HTMLElement {
    if (this.marker) {
      return DomUtil.get(this.marker.getElement());
    }

    return null;
  }
}
