<ion-row
  *ngIf="this.journey && this.journey.guid != null"
  class="sis-timetable-journey-row sis-global-hover"
  data-test="timetable-journey-row"
>
  <ion-col size="1" class="sis-timetable-padding-left sis-vehicle-number"> {{ journey.vehicleNumber }} </ion-col>
  <ion-col
    *ngFor="let timetableItem of this.timetableItems"
    [size]="this.timetableItems.length > 5 ? 1 : 1.5"
    class="sis-timetable-padding-left"
  >
    <sis-timetable-time-item
      [ngClass]="this.journey.isValid ? '' : 'sis-timetable-row-greyed-out'"
      [timetableItem]="timetableItem"
    ></sis-timetable-time-item>
  </ion-col>
  <ng-container *ngIf="stations.length <= 2">
    <ion-col *ngIf="journey.direction == 0" class="sis-timetable-padding-left">
      <a ngClass="sis-timetable-clickable-no-permission" matTooltip="{{ 'timetable.term.both' | translate }}">
        <ion-icon name="arrow-up-outline" class="sis-timetable-icon-small"></ion-icon
        ><ion-icon name="arrow-down-outline" class="sis-timetable-icon-small"></ion-icon>
      </a>
    </ion-col>
    <ion-col *ngIf="journey.direction == 1" class="sis-timetable-padding-left">
      <a ngClass="sis-timetable-clickable-no-permission" matTooltip="{{ 'timetable.term.up' | translate }}">
        <ion-icon name="arrow-up-outline" class="sis-timetable-icon-small"></ion-icon>
      </a>
    </ion-col>
    <ion-col *ngIf="journey.direction == 2" class="sis-timetable-padding-left">
      <a ngClass="sis-timetable-clickable-no-permission" matTooltip="{{ 'timetable.term.down' | translate }}">
        <ion-icon name="arrow-down-outline" class="sis-timetable-icon-small"></ion-icon>
      </a>
    </ion-col>
  </ng-container>
  <ion-col *ngIf="this.hasValidity">
    <a
      class="sis-timetable-clickable"
      style="padding: 3px 6px"
      data-test="timetable-edit-journey-info-icon"
      matTooltip="{{ 'timetable.term.validity' | translate }}: {{ journey.validityTooltip }}"
    >
      <ion-icon name="information-circle-outline" class="sis-timetable-icon-small"> </ion-icon>
    </a>
  </ion-col>
  <ion-col *ngIf="stations.length > 2"></ion-col>
  <ion-col size="auto" class="sis-timetable-padding-left" style="min-width: 100px">
    <a
      *ngIf="!this.journey.imported"
      [ngClass]="(this.writePermission$ | async) ? 'sis-timetable-clickable' : 'sis-timetable-clickable-no-permission'"
      style="padding: 3px 6px"
      (click)="this.openJourneyModal(journey)"
      data-test="timetable-edit-journey-button"
      matTooltip="{{ 'general.term.edit' | translate }}"
    >
      <ion-icon name="create-outline" class="sis-timetable-icon-small"> </ion-icon>
    </a>
    <a
      *ngIf="!this.journey.imported"
      [ngClass]="(this.writePermission$ | async) ? 'sis-timetable-clickable' : 'sis-timetable-clickable-no-permission'"
      style="padding: 3px 6px"
      (click)="this.deleteJourney(journey)"
      data-test="timetable-delete-journey-button"
      matTooltip="{{ 'general.term.delete' | translate }}"
    >
      <ion-icon name="trash-outline" class="sis-timetable-icon-small"> </ion-icon>
    </a>
  </ion-col>
</ion-row>

<ion-row *ngIf="this.journey && this.journey.guid == null" class="sis-timetable-day-separator">
  <ion-col>
    <div class="sis-timetable-day-separator-div-1">
      <div class="sis-timetable-day-separator-div-2">
        <span class="sis-timetable-day-separator-span">{{
          this.journey.vehicleNumber == 'day-separator'
            ? ('timetable.term.tomorrow' | translate)
            : ('timetable.term.upcomingAndPassed' | translate)
        }}</span>
      </div>
    </div>
  </ion-col>
</ion-row>
