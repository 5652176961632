import { Component, Input, OnChanges, OnInit } from '@angular/core';
import moment from 'moment';
import { filter, takeUntil } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { TimetableJourneyUpdatedEvent, TimetableRealTimeDataUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { TimetableItem } from 'src/app/timetable/domain/timetable-item.model';

@Component({
  selector: 'sis-timetable-time-item',
  templateUrl: './time-item.component.html',
  styleUrls: ['./time-item.component.scss'],
})
export class TimetableTimeItemComponent extends Unsubscriber implements OnChanges, OnInit {
  @Input() timetableItem: TimetableItem;

  time: string;
  delay: string;

  constructor(private eventBus: EventBusService) {
    super();
  }

  ngOnInit(): void {
    this.eventBus
      .observe(TimetableRealTimeDataUpdatedEvent)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(
          (event) =>
            this.timetableItem && event.realTimeDatas.find((r) => r.itemGuid === this.timetableItem.guid) != null
        )
      )
      .subscribe((event) => {
        this.timetableItem.estimatedTime = event.realTimeDatas.find(
          (r) => r.itemGuid === this.timetableItem.guid
        ).estimatedTime;

        this.delay = this.calculateDelay(this.timetableItem);
      });

    this.eventBus
      .observe(TimetableJourneyUpdatedEvent)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event) => {
        if (this.timetableItem) {
          const updatedItem = event.timetableJourney.items.find((i) => i.guid === this.timetableItem.guid);

          if (updatedItem) {
            this.timetableItem = updatedItem;
            this.ngOnChanges();
          }
        }
      });
  }

  ngOnChanges(): void {
    this.time = this.timetableItem ? this.formatTime(this.timetableItem.departureTime) : '-';
    this.delay = this.calculateDelay(this.timetableItem);
  }

  private calculateDelay(timetableItem: TimetableItem): string {
    if (timetableItem?.estimatedTime == null) {
      return '';
    }

    const delayMinutes =
      moment(this.timetableItem.estimatedTime, 'HH:mm:ss').diff(moment(this.timetableItem.departureTime, 'HH:mm:ss')) /
      60000;

    if (delayMinutes <= 0) {
      return '';
    }

    return `+${delayMinutes}'`;
  }

  private formatTime(time: string) {
    return moment(time, 'HH:mm:ss').format('HH:mm');
  }
}
