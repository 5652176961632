<ion-row
  [ngClass]="this.cssClass"
  [ngStyle]="this.cssStyle"
  class="sis-alarm-row"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
  (click)="mouseClick()"
  (contextmenu)="openContextMenu($event)"
  (press)="openContextMenu($event)"
  oncontextmenu="return false"
  data-test="sidepane-alarmlist-item"
>
  <ion-col class="sis-title-text">
    {{ this.displayedTitle }}
  </ion-col>
  <ion-col size="4.5">
    <span class="sis-timestamp"
      ><i>{{ this.formattedTimestamp.formattedTimestamp$ | async }}</i></span
    >
  </ion-col>
  <div class="sis-small-text" data-test="alarm-item-text">
    {{ this.displayedText }}
  </div>
  <div *ngIf="this.showContextMenu">
    <sis-context-menu
      [x]="this.contextMenuX"
      [y]="this.contextMenuY"
      [menuItems]="this.contextMenuItems"
      (menuItemSelected)="this.contextMenuItemSelected($event)"
    ></sis-context-menu>
  </div>
</ion-row>
