import { SambesiWhatsUpMessage } from 'src/app/maps/livedata/sambesi-whatsup-message.model';

export class SambesiWhatsUpMessageAdapter {
  static adapt(items: any): SambesiWhatsUpMessage[] {
    if (!items) {
      return [];
    }
    if (!Array.isArray(items)) {
      throw new Error('Error parsing Sambesi Whats Up Messages');
    }
    return items.map((item) => ({
      assignedPerson: item.AssignedPerson,
      changeType: item.ChangeType,
      date: new Date(item.Date),
      installationAbbreviation: item.InstallationAbbreviation,
      installationName: item.InstallationName,
      installationType: item.InstallationType,
      jobName: item.JobName,
      nodeName: item.NodeName,
      ropewaySisId: item.RopewaySisId,
      tenant: item.Tenant,
      additionalInformation: item.AdditionalInformation,
      timestamp: new Date(item.Timestamp),
    }));
  }
}
