<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <rect width="187.35" height="225" style="fill: none" />
        <g id="Warnsignal">
          <path
            d="M149.26,130.1c.41.63.12,1.15-.63,1.15H38.87c-.75,0-1-.52-.63-1.15L93,45.3a.82.82,0,0,1,1.48,0Z"
            style="fill: none; stroke-miterlimit: 10; stroke-width: 4.80190544128418px"
            [ngClass]="this.class"
          />
          <g>
            <path
              d="M94.06,106.1h-.62a3,3,0,0,1-3.12-2.95L88.1,77.46c0-1.62,3.62-2.95,5.34-2.95h.62c1.72,0,5.34,1.33,5.34,2.95l-2.22,25.69A3,3,0,0,1,94.06,106.1Z"
              [ngClass]="this.class"
            />
            <ellipse cx="93.75" cy="117.28" rx="3.43" ry="3.25" [ngClass]="this.class" />
          </g>
        </g>
      </g>
    </g>
    <g>
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
      >
        {{ this.count }}
      </text>
    </g>
  </g>
</svg>
