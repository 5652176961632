import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { CoreModule } from 'src/app/core/core.module';
import { OperatingInfoPage } from 'src/app/operating-info/operating-info.page';
import { OperatingInfoInfoScreenComponent } from 'src/app/operating-info/operating-info-infoscreen/operating-info-infoscreen.component';
import { OperatingInfoItemComponent } from 'src/app/operating-info/operating-info-item/operating-info-item.component';
import { OperatingInfoEntryEditComponent } from 'src/app/operating-info/sis-operating-info-entry-edit/sis-operating-info-entry-edit.component';

@NgModule({
  declarations: [
    OperatingInfoPage,
    OperatingInfoItemComponent,
    OperatingInfoEntryEditComponent,
    OperatingInfoInfoScreenComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    QRCodeModule,
    CoreModule,
    MatTooltipModule,
    RouterModule.forChild([
      {
        path: '',
        component: OperatingInfoPage,
      },
    ]),
    TranslateModule,
    ReactiveFormsModule,
  ],
})
export class OperatingInfoPageModule {}
