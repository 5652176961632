import { Slide } from 'src/app/media-center/slides/domain/slide.model';

export class SlideAdapter {
  static adapt(items: any): Slide[] {
    const result: Slide[] = [];

    if (items) {
      items.forEach((e: any) => {
        const slide = new Slide({
          guid: e.guid,
          name: e.slideName,
          filePath: e.filePath,
          url: e.url,
          fileType: e.fileType,
          duration: e.duration ? e.duration : 0,
          canEdit: e.canEdit,
          startDate: e.startDate,
          endDate: e.endDate,
          startTime: e.startTime ?? '00:00',
          endTime: e.endTime ?? '23:59',
          enabled: e.enabled,
          timeControlEnabled: e.timeControlEnabled,
          mon: e.mon,
          tue: e.tue,
          wed: e.wed,
          thu: e.thu,
          fri: e.fri,
          sat: e.sat,
          sun: e.sun,
        });

        slide.thumbnailUrl = e.thumbnailUrl;

        result.push(slide);
      });
    }

    return result;
  }
}
