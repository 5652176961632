import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';

@Component({
  selector: 'sis-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {
  @Input() title: string;
  @Input() featureId: FeatureId[][];
  @Input() reorderingEnabled: boolean;
  @Input() ignoreAdminRequirement: boolean;
  @Input() showAddButton: boolean;
  @Input() showReorderButton: boolean;
  @Input() filterControl: FormControl<string>;

  @Output() onReorderClick: EventEmitter<boolean> = new EventEmitter();
  @Output() onReorderCancelClick: EventEmitter<boolean> = new EventEmitter();
  @Output() openEditModal: EventEmitter<boolean> = new EventEmitter();

  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;
  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;
  readonly isAdministrator$ = this.userSettingsService.userSettings$.pipe(map((settings) => settings.isAdministrator));

  constructor(
    private logbookModalService: LogbookModalService,
    private userSettingsService: UserSettingsService,
    private screenSizeService: ScreenSizeService
  ) {}

  async openLogbook(): Promise<void> {
    await this.logbookModalService.presentLogbookModal(this.featureId);
  }

  _onReorderClick(): void {
    this.filterControl?.disable();
    this.onReorderClick.emit();
  }

  _onReorderCancelClick(): void {
    this.filterControl?.enable();
    this.onReorderCancelClick.emit();
  }

  _onReorderSaveClick(): void {
    this.filterControl?.enable();
    this.onReorderClick.emit();
  }

  _openEditModal(): void {
    this.openEditModal.emit();
  }
}
