<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.closeModal()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title>{{ 'destination.phrase.select' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-searchbar
          #searchBar
          placeholder="{{ 'general.term.filter' | translate }}"
          type="text"
          [formControl]="searchControl"
          (ionChange)="this.searching = true"
        >
        </ion-searchbar>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="searching" class="spinner-container">
      <ion-col>
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-list>
          <sis-select-destination-item
            *ngFor="let tenant of this.filteredTenants; let i = index"
            [tenant]="tenant"
            [selectedTenantGuid]="this.selectedTenantGuid"
            [isSelected]="i === selectedIndex"
            (mouseenter)="this.handleMouseEnter(i)"
            (mouseleave)="this.handleMouseLeave()"
            (click)="this.handleMouseClick()"
          ></sis-select-destination-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
