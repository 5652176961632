import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import {
  ParkingCockpitOrderUpdatedEvent,
  ParkingCurrentOccupancyUpdatedEvent,
  ParkingEditedEvent,
  ParkingExportMappingUpdatedEvent,
  ParkingStatusUpdatedEvent,
} from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Export } from 'src/app/domain/export/export.model';
import { ExportService } from 'src/app/domain/export/export.service';
import { UserSettings } from 'src/app/domain/user-settings/user-settings.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { ParkingAdapter } from 'src/app/parking/domain/parking.adapter';
import { Parking } from 'src/app/parking/domain/parking.model';
import { ParkingExportMappingPostData } from 'src/app/parking/domain/parking-export-mapping-postdata.model';
import { ParkingSetting } from 'src/app/parking/domain/parking-setting.enum';
import { ParkingStatus } from 'src/app/parking/domain/parking-status.enum';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ParkingService {
  private static readonly url: string = '/parking';

  private readonly parkingsRequest$: Observable<Parking[]> = this.destinationService.selectedTenantFeatures$.pipe(
    filter((tenant) => !!tenant),
    switchMap(() =>
      this.http
        .get<Parking[]>(`${environment.baseUrlApi}${ParkingService.url}`)
        .pipe(map((data) => ParkingAdapter.adapt(data)))
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly parkingStatusUpdated$: Observable<Parking[]> = this.eventBus.observe(ParkingStatusUpdatedEvent).pipe(
    withLatestFrom(this.userSettingService.userSettings$, this.destinationService.selectedTenant$),
    filter(([event, , tenant]) => event.tenantGuid === tenant.guid),
    withLatestFrom(this.parkingsRequest$),
    map(([[event, user], parkings]) => this.handleStatusUpdatedEvent(event, parkings, user))
  );

  private readonly parkingUpdated$: Observable<Parking[]> = this.eventBus.observe(ParkingEditedEvent).pipe(
    filter((event) => event.updateSuccessful),
    withLatestFrom(this.userSettingService.userSettings$, this.destinationService.selectedTenant$),
    filter(([event, , tenant]) => event.tenantGuid === tenant.guid),
    withLatestFrom(this.parkingsRequest$),
    map(([[event, user], parkings]) => this.handleEditedEvent(event, parkings, user))
  );

  private readonly exportMappingUpdated$: Observable<Parking[]> = this.eventBus
    .observe(ParkingExportMappingUpdatedEvent)
    .pipe(
      filter((event) => event.updateSuccessful),
      withLatestFrom(this.destinationService.selectedTenant$, this.exportService.exports$),
      filter(([event, tenant]) => event.tenantGuid === tenant.guid),
      withLatestFrom(this.parkingsRequest$),
      map(([[event, , exports], parkings]) => this.handleExportMappingUpdatedEvent(event, parkings, exports))
    );

  private readonly parkingCockpitOrderUpdated$: Observable<Parking[]> = this.eventBus
    .observe(ParkingCockpitOrderUpdatedEvent)
    .pipe(
      filter((event) => event.updateSuccessful),
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([event, tenant]) => event.tenantGuid === tenant.guid),
      withLatestFrom(this.parkingsRequest$),
      map(([[event], parkings]) => this.handleCockpitOrderUpdatedEvent(event, parkings))
    );

  private readonly parkingCurrentOccupancyUpdated$: Observable<Parking[]> = this.eventBus
    .observe(ParkingCurrentOccupancyUpdatedEvent)
    .pipe(
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([event, tenant]) => event.tenantGuid === tenant.guid),
      withLatestFrom(this.parkingsRequest$),
      map(([[event], parkings]) => this.handleCurrentOccupancyUpdatedEvent(event, parkings))
    );

  readonly parkings$: Observable<Parking[]> = merge(
    this.parkingsRequest$,
    this.parkingStatusUpdated$,
    this.parkingUpdated$,
    this.exportMappingUpdated$,
    this.parkingCockpitOrderUpdated$,
    this.parkingCurrentOccupancyUpdated$
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private userSettingService: UserSettingsService,
    private http: HttpClient,
    private eventBus: EventBusService,
    private userMessageService: UserMessageService,
    private destinationService: DestinationService,
    private exportService: ExportService
  ) {}

  async updateStatus(parkingGuid: string, status: ParkingStatus): Promise<void> {
    try {
      await firstValueFrom(
        this.http.post(`${environment.baseUrlApi}${ParkingService.url}/status`, {
          parkingGuid,
          status,
        })
      );
    } catch {
      const userGuid = (await firstValueFrom(this.userSettingService.userSettings$)).userGuid.toString();
      this.eventBus.publish(this.createErrorEvent(parkingGuid, userGuid));
    }
  }

  async editParking(parkings: Parking[]): Promise<void> {
    if (!parkings) {
      return;
    }

    try {
      await firstValueFrom(this.http.post(`${environment.baseUrlApi}${ParkingService.url}/edit`, parkings));
    } catch {
      this.presentToast(false);
    }
  }

  async postExportMappings(postData: ParkingExportMappingPostData, parking: Parking): Promise<void> {
    parking.setUpdating(ParkingSetting.exportMappings, true);

    await firstValueFrom(
      this.http.post(`${environment.baseUrlApi}/parking/export`, postData).pipe(
        catchError(() => {
          parking.setUpdating(ParkingSetting.exportMappings, false);
          const translateKey = 'general.phrase.saveFailed';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.failed,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);
          return of(null);
        })
      )
    );
  }

  private presentToast(success: boolean): void {
    const message = success ? 'general.phrase.saved' : 'general.phrase.saveFailed';
    const color = success ? UserMessageColor.green : UserMessageColor.red;
    const icon = success ? UserMessageIcon.success : UserMessageIcon.failed;
    this.userMessageService.presentToast(new UserMessage({ message, icon, durationMs: 2000, position: 'top', color }));
  }

  private createErrorEvent(parkingGuid: string, changedby: string): ParkingStatusUpdatedEvent {
    const event = new ParkingStatusUpdatedEvent();
    event.parkingGuid = parkingGuid;
    event.changedBy = changedby;
    return event;
  }

  // Update handler methods

  private handleStatusUpdatedEvent(
    event: ParkingStatusUpdatedEvent,
    parkings: Parking[],
    user: UserSettings
  ): Parking[] {
    const updatedItem = parkings.find((item) => item.guid === event.parkingGuid);
    if (updatedItem) {
      if (event.updateSuccessful) {
        updatedItem.status = event.status;
        updatedItem.lastStatusChange = event.lastStatusChange;
      }

      if (event.changedBy === user.userGuid) {
        const message = event.updateSuccessful ? 'sismedia.phrase.statusUpdated' : 'sismedia.phrase.updateFailed';
        const icon = event.updateSuccessful ? UserMessageIcon.success : UserMessageIcon.failed;
        const color = event.updateSuccessful ? UserMessageColor.green : UserMessageColor.red;

        this.userMessageService.presentToast(
          new UserMessage({
            message,
            icon,
            durationMs: 2000,
            position: 'top',
            color,
          })
        );
      }

      updatedItem.setUpdating(ParkingSetting.status, false);
    }

    return parkings;
  }

  private handleEditedEvent(event: ParkingEditedEvent, parkings: Parking[], user: UserSettings): Parking[] {
    event.parkings.forEach((eventParking) => {
      const updatedParking = parkings.find((parking) => parking.guid === eventParking.guid);
      if (updatedParking) {
        updatedParking.name = eventParking.name;
        updatedParking.nameEn = eventParking.nameEn;
        updatedParking.label = eventParking.label;
        updatedParking.info = eventParking.info;
        updatedParking.custom = eventParking.custom;
        updatedParking.cockpitOrder = eventParking.cockpitOrder;
        updatedParking.currentOccupancy = eventParking.currentOccupancy;
        updatedParking.occupancyOffset = eventParking.occupancyOffset;
        updatedParking.maxCapacity = eventParking.maxCapacity;
        updatedParking.autoStatus = eventParking.autoStatus;
        updatedParking.status = eventParking.status;

        updatedParking.setUpdating(ParkingSetting.edit, false);
        updatedParking.setUpdating(ParkingSetting.status, false);
      }
    });

    if (event.changedBy === user.userGuid) {
      this.presentToast(event.updateSuccessful);
    }

    return parkings;
  }

  private handleCockpitOrderUpdatedEvent(event: ParkingCockpitOrderUpdatedEvent, parkings: Parking[]) {
    event.parkings.forEach((p) => {
      const updatedParking = parkings.find((i) => i.guid == p.guid);
      if (updatedParking) {
        updatedParking.cockpitOrder = p.cockpitOrder;
      }
    });

    return parkings;
  }

  private handleExportMappingUpdatedEvent(
    event: ParkingExportMappingUpdatedEvent,
    parkings: Parking[],
    exports: Export[]
  ): Parking[] {
    const updatedItem = parkings.find((p) => p.guid === event.parkingGuid);
    if (updatedItem) {
      updatedItem.exports = exports.filter((e) => event.exportGuids.includes(e.guid));
      updatedItem.setUpdating(ParkingSetting.exportMappings, false);
    }
    return parkings;
  }

  private handleCurrentOccupancyUpdatedEvent(
    event: ParkingCurrentOccupancyUpdatedEvent,
    parkings: Parking[]
  ): Parking[] {
    const updatedItem = parkings.find((p) => p.guid === event.parkingGuid);
    if (updatedItem) {
      updatedItem.currentOccupancy = event.currentOccupancy;
      updatedItem.status = event.status;
      updatedItem.setUpdating(ParkingSetting.status, false);
      updatedItem.setUpdating(ParkingSetting.occupancy, false);
    }
    return parkings;
  }
}
