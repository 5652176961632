export class RopewayCssClassNames {
  static readonly cssRopewayRunning = 'sis-ropeway-running';
  static readonly cssRopewayDriveReady = 'sis-ropeway-driveready';
  static readonly cssRopewayDefault = 'sis-ropeway-default';
  static readonly cssRopewayOutdated = 'sis-ropeway-outdated';
  static readonly cssRopewayOffline = 'sis-ropeway-offline';
  static readonly cssRopewayAlarm = 'sis-ropeway-alarm';
  static readonly cssRopewayInvisible = 'sis-ropeway-invisible';
  static readonly cssRopewayClickable = 'sis-ropeway-clickable';

  static readonly cssRopewayInactive = 'sis-ropeway-inactive';

  static readonly cssBorder = '-border';
  static readonly cssHighlight = '-highlight';
}
