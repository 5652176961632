import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { BreakdownInfoLibraryService } from 'src/app/breakdowninfo/domain/breakdowninfo-library.service';
import { BreakdownInfoLibraryEntry } from 'src/app/breakdowninfo/domain/breakdowninfo-library-entry.model';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { v4 as v4guid } from 'uuid';

@Component({
  selector: 'sis-breakdowninfo-library-item',
  templateUrl: './breakdowninfo-library-item.component.html',
  styleUrls: ['./breakdowninfo-library-item.component.scss'],
})
export class BreakdownInfoLibraryItemComponent extends Unsubscriber implements OnInit {
  readonly green = BreakdownInfoLibraryEntry.colorGreen;
  readonly yellow = BreakdownInfoLibraryEntry.colorYellow;

  oldItem: BreakdownInfoLibraryEntry;
  libraryItem: BreakdownInfoLibraryEntry;
  bigScreenMode: boolean;

  @Input() breakdownInfoLibraryEntry: BreakdownInfoLibraryEntry;

  constructor(
    private breakdownInfoLibraryService: BreakdownInfoLibraryService,
    private modalCtrl: ModalController,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setItem(this.breakdownInfoLibraryEntry);

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));
  }

  async insert(): Promise<void> {
    if (this.validate()) {
      await this.breakdownInfoLibraryService.updateLibrary(
        this.libraryItem,
        false,
        BreakdownInfoLibraryService.insertOperation
      );
      this.close();
    }
  }

  async update(): Promise<void> {
    if (this.validate()) {
      await this.breakdownInfoLibraryService.updateLibrary(
        this.libraryItem,
        false,
        BreakdownInfoLibraryService.updateOperation
      );
      this.close();
    }
  }

  async delete(): Promise<void> {
    await this.breakdownInfoLibraryService.updateLibrary(
      this.oldItem,
      false,
      BreakdownInfoLibraryService.deleteOperation
    );
    this.close();
  }

  validate(): boolean {
    if (
      this.libraryItem &&
      this.libraryItem.titleDe &&
      this.libraryItem.titleEn &&
      this.libraryItem.titleFr &&
      this.libraryItem.titleIt &&
      this.libraryItem.textDe &&
      this.libraryItem.textEn &&
      this.libraryItem.textFr &&
      this.libraryItem.textIt
    ) {
      return true;
    }
    return false;
  }

  private setItem(item: BreakdownInfoLibraryEntry): void {
    this.oldItem = item;
    this.libraryItem =
      this.oldItem != null
        ? new BreakdownInfoLibraryEntry(item)
        : new BreakdownInfoLibraryEntry({
            guid: v4guid(),
            color: BreakdownInfoLibraryEntry.colorGreen,
          });
  }

  close(): void {
    this.modalCtrl.dismiss();
  }
}
