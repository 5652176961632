import { Component, Input, OnInit } from '@angular/core';
import { MeteoInfoItem } from 'src/app/meteo-info/domain/meteo-info-item.model';

@Component({
  selector: 'sis-meteo-info-field-names',
  templateUrl: './meteo-info-field-names.component.html',
  styleUrls: ['./meteo-info-field-names.component.scss'],
})
export class MeteoInfoFieldNames implements OnInit {
  @Input() hasSTnetExport: boolean;
  @Input() meteoInfoItemsInRow: MeteoInfoItem[];

  showWeatherImportRows: boolean;

  ngOnInit(): void {
    this.showWeatherImportRows = this.meteoInfoItemsInRow.some((m) => m.hasWeatherImport);
  }
}
