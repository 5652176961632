import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Options } from 'highcharts';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { Configuration } from 'src/app/maps/domain/configuration.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewaySpeedTrend } from 'src/app/maps/trenddata/ropeway-speed-trend.model';
import { RopewaySpeedTrendService } from 'src/app/maps/trenddata/ropeway-speed-trend.service';
import { ChartBase } from 'src/app/maps/widgets/charts/chart-base';
import { ChartSettings } from 'src/app/maps/widgets/charts/chart-settings.model';

@Component({
  selector: 'sis-speed-chart',
  templateUrl: './speed-chart.component.html',
  styleUrls: ['./speed-chart.component.scss'],
})
export class SpeedChartComponent extends ChartBase implements OnInit {
  static readonly speedSeries = 'speedSeries';

  private static readonly titleTranslateString = 'ropeway.term.speed';

  private readonly plotlineAlarm = 'PLOT1';
  private readonly ropeway$ = this.chartAdapter.chartReady$.pipe(
    take(1),
    switchMap(() => this.selectedMapElementService.selectedRopeway$)
  );

  constructor(
    private ropewaySpeedTrendService: RopewaySpeedTrendService,
    private selectedMapElementService: SelectedMapElementService,
    timeFormatService: TimeFormatService,
    translateService: TranslateService,
    chartSettings: ChartSettings
  ) {
    super(translateService, chartSettings?.inModal, timeFormatService);
  }

  ngOnInit(): void {
    this.ropeway$
      .pipe(
        distinctUntilChanged(),
        filter((ropeway) => !!ropeway),
        switchMap((ropeway) => {
          this.initChart(ropeway);
          this.chartAdapter.showLoading();

          return this.chartAdapter
            .loadData([this.ropewaySpeedTrendService.getSpeedData(ropeway.sisId)])
            .pipe(map((data: [RopewaySpeedTrend[]]) => ({ ropeway, data: data[0] })));
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ ropeway, data }) => {
        this.updateChartData(ropeway, data);
        this.chartAdapter.hideLoading();
      });
  }

  private initChart(ropeway: Ropeway): void {
    this.deviceAlias = ropeway.alias;
    this.updateTitle();

    if (this.isModal) {
      this.updateModalTitle();
    }

    this.chartAdapter.showLoading();
    this.chartAdapter.removeAllSeries();
    this.chartAdapter.addSeries(SpeedChartComponent.speedSeries, {
      name: this.title,
      id: 'speed',
      type: 'spline' as any,
      lineWidth: 1,
      className: '',
      color: this.getTrendLineColor(),
    });

    this.chartAdapter.reflowChart(this.onDestroy$);
  }

  private updateChartData(ropeway: Ropeway, trendDatas: RopewaySpeedTrend[]): void {
    if (!trendDatas) {
      this.resetChart();
      return;
    }

    const minX = this.chartAdapter.getTimeInMsHoursAgo(4);
    trendDatas = trendDatas.filter((t) => t.timestamp.getTime() >= minX);

    if (trendDatas.length === 0) {
      this.resetChart();
      this.chartAdapter.showNoData();
      return;
    }

    const config = this.getConfiguration(ropeway);
    const colorZones = this.getZones(config);
    const speedData = this.getData(trendDatas).sort((i1, i2) => i1[0] - i2[0]);

    this.setPlotLines(config);
    this.chartAdapter.setSeriesOptions(SpeedChartComponent.speedSeries, { zones: colorZones } as any);
    this.chartAdapter.setSeriesData(SpeedChartComponent.speedSeries, speedData);

    this.maxY = this.getMaxYAxisValue(config, trendDatas);

    if (!this.isZoomed) {
      this.chartAdapter.setYAxisExtremes(0, this.minY, this.maxY);
      this.chartAdapter.setXAxisExtremes(0, null, null);
    }

    if (this.isZoomed && this.isZoomedToEnd) {
      this.chartAdapter.setXAxisExtremes(0, this.zoomMin, null);
    }
  }

  private getMaxYAxisValue(config: Configuration, data: RopewaySpeedTrend[]): number {
    const maxSpeed = Math.max(...data.map((d) => d.speed ?? null));

    const nominalSpeed = config ? config.nominalValue : 0;

    return Math.ceil(Math.max(nominalSpeed, maxSpeed) + 0.1);
  }

  private getConfiguration(ropeway: Ropeway): Configuration {
    return ropeway?.configurations?.find((f) => f.configKey === 'actualSpeedMps');
  }

  private getZones(config: Configuration): any[] {
    const zones = [];
    if (!config) {
      return zones;
    }
    if (config.nominalValue) {
      zones.push({
        value: config.nominalValue,
        className: 'zone-nominal',
      });
    }
    zones.push({
      value: 400,
      className: 'zone-alarm',
    });
    return zones;
  }

  private setPlotLines(config: Configuration): void {
    this.chartAdapter.removeYPlotBand(0, this.plotlineAlarm);

    if (config && config.nominalValue) {
      this.chartAdapter.addYPlotBand(0, {
        from: config.nominalValue,
        to: 400,
        className: 'zone-medium',
        id: this.plotlineAlarm,
      });
    }
  }

  private getData(data: RopewaySpeedTrend[]): number[][] {
    return data.map((item) => {
      const timeStampInMs = item.timestamp.getTime();
      return [timeStampInMs, item.speed];
    });
  }

  private resetChart(): void {
    this.chartAdapter.clearAllSeriesData();
  }

  protected translateTexts(translateService: TranslateService): void {
    translateService
      .get([SpeedChartComponent.titleTranslateString])
      .pipe(take(1))
      .subscribe((res) => {
        this.title = res[SpeedChartComponent.titleTranslateString];

        this.chartAdapter?.updateOptions({
          title: {
            text: this.isModal ? '' : this.title,
          },
        });

        if (this.isModal) {
          this.updateModalTitle();
        }
      });
  }

  protected getChartOptions(): Options {
    return {
      time: {
        useUTC: false,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      legend: {
        enabled: this.isModal,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      xAxis: {
        type: 'datetime',
        crosshair: this.isModal,
        tickInterval: 1 * 60 * 60 * 1000, // 1 hours in ms
        events: {
          setExtremes: (event) => this.handleSetExtremesEvent(event),
        },
      },
      chart: {
        zoomType: 'xy',
        panKey: 'ctrl',
        panning: {
          enabled: true,
        },
        animation: false,
        style: {
          fontFamily: 'Myriad Pro',
        },
        resetZoomButton: {
          theme: {
            stroke: 'var(--ion-color-primary)',
            height: 8,
          },
        },
      },
      yAxis: {
        title: {
          text: '[m/s]',
          align: 'high',
          offset: 0,
          rotation: 0,
          y: -15,
          style: {
            fontSize: '12px',
          },
        },
        lineWidth: 1,
        tickInterval: 2,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: this.isModal,
        split: true,
        valueSuffix: 'm/s',
        xDateFormat: '%d.%m.%y / %H:%M:%S',
      },
      lang: {
        noData: this.noDataText,
        loading: this.loadingText,
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      loading: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
          },
        },
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
    };
  }
}
