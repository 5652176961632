import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { Playlist } from 'src/app/media-center/playlists/domain/playlist.model';
import { MediaCenterPlaylistsService } from 'src/app/media-center/playlists/media-center-playlists.service';
import { Screen } from 'src/app/media-center/screens/domain/screen.model';
import { ScreenGroup } from 'src/app/media-center/screens/domain/screen-group.model';
import { ScreenType } from 'src/app/media-center/screens/domain/screen-type.model';
import { MediaCenterScreenService } from 'src/app/media-center/screens/media-center-screens.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Component({
  templateUrl: './screeneditor.component.html',
  styleUrls: ['./screeneditor.component.scss', '../../media-center-global.scss'],
})
export class ScreeneditorComponent extends Unsubscriber implements OnInit {
  @ViewChild(MatAutocompleteTrigger) private autocompleteTrigger: MatAutocompleteTrigger;

  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  screen: Screen;
  formGroup: FormGroup;
  allScreenLabels: string[];
  allScreenNames: string[];
  playlistsFiltered: Playlist[];
  groupsFiltered: ScreenGroup[];
  screenTypesFiltered: ScreenType[];
  showInfoBannerAllowedCheckbox: boolean = false;
  isNewScreen: boolean = false;

  private editedScreen: Screen;
  private infoBannerFeatureAccessLevel: FeatureAccessLevel;
  private playlists: Playlist[];
  private screens: Screen[];
  private groups: ScreenGroup[];
  private screenTypes: ScreenType[];

  constructor(
    private modalCtrl: ModalController,
    private userMessageService: UserMessageService,
    private screensService: MediaCenterScreenService,
    private userSettingsService: UserSettingsService,
    private playlistService: MediaCenterPlaylistsService,
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit() {
    this.editedScreen = JSON.parse(JSON.stringify(this.screen));
    this.isNewScreen = this.screen.location == null;

    this.screensService.screens$.pipe(takeUntil(this.onDestroy$)).subscribe((screens) => {
      this.screens = screens;
    });

    this.formGroup = new FormGroup({
      location: new FormControl<string>(this.editedScreen?.location, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9äÄöÖüÜ\\s-&+()_]*$'),
        Validators.minLength(2),
        this.validate('location'),
      ]),
      label: new FormControl<string>(this.editedScreen?.label, [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9\\-]*$'),
        this.validate('label'),
      ]),
      screenHeight: new FormControl<number>(this.editedScreen?.screenHeight || null, [
        Validators.pattern('^\\d+$'),
        Validators.required,
      ]),
      screenWidth: new FormControl<number>(this.editedScreen?.screenWidth || null, [
        Validators.pattern('^\\d+$'),
        Validators.required,
      ]),
      slideshowWidth: new FormControl<number>(
        {
          value: this.editedScreen.slideshowWidth,
          disabled: this.editedScreen.previewUrl != null,
        },
        [Validators.pattern('^\\d+$'), Validators.required]
      ),
      slideshowHeight: new FormControl<number>(
        {
          value: this.editedScreen.slideshowHeight,
          disabled: this.editedScreen.previewUrl != null,
        },
        [Validators.pattern('^\\d+$'), Validators.required]
      ),
      ip: new FormControl<string>(this.editedScreen?.ip || null, [
        Validators.pattern(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/),
      ]),
      group: new FormControl<ScreenGroup>(null),
      previewUrl: new FormControl<string>(this.editedScreen?.previewUrl !== '' ? this.editedScreen?.previewUrl : null, [
        Validators.pattern(/^(https?:\/\/)([\w-]+\.)+[\w-]+(\/[\w-./?%&=+]*)?(#.*)?$/),
      ]),
      playlist: new FormControl<Playlist>(null),
      screenType: new FormControl<ScreenType>(null),
      infoBannerAllowed: new FormControl<boolean>(this.editedScreen?.infoBannerAllowed),
    });

    this.formGroup.controls['previewUrl'].valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((previewUrl) => {
      if (!previewUrl) {
        this.formGroup.controls['slideshowWidth'].disable({ emitEvent: false });
        this.formGroup.controls['slideshowHeight'].disable({ emitEvent: false });
        this.formGroup.controls['slideshowWidth'].setValue(this.editedScreen.screenWidth, { emitEvent: false });
        this.formGroup.controls['slideshowHeight'].setValue(this.editedScreen.screenHeight, { emitEvent: false });
        this.formGroup.controls['previewUrl'].setValue(null, { emitEvent: false });
      } else {
        this.formGroup.controls['slideshowWidth'].enable({ emitEvent: false });
        this.formGroup.controls['slideshowHeight'].enable({ emitEvent: false });
        this.formGroup.controls['slideshowWidth'].setValue(this.editedScreen.slideshowWidth, { emitEvent: false });
        this.formGroup.controls['slideshowHeight'].setValue(this.editedScreen.slideshowHeight, { emitEvent: false });
      }
    });

    if (!this.formGroup.controls['previewUrl'].value) {
      this.formGroup.controls['slideshowWidth'].disable({ emitEvent: false });
      this.formGroup.controls['slideshowHeight'].disable({ emitEvent: false });
      this.formGroup.controls['slideshowWidth'].setValue(this.editedScreen.screenWidth, { emitEvent: false });
      this.formGroup.controls['slideshowHeight'].setValue(this.editedScreen.screenHeight, { emitEvent: false });
      this.formGroup.controls['previewUrl'].setValue(null);
    } else {
      this.formGroup.controls['slideshowWidth'].enable({ emitEvent: false });
      this.formGroup.controls['slideshowHeight'].enable({ emitEvent: false });
      this.formGroup.controls['slideshowWidth'].setValue(this.editedScreen.slideshowWidth, { emitEvent: false });
      this.formGroup.controls['slideshowHeight'].setValue(this.editedScreen.slideshowHeight, { emitEvent: false });
    }

    this.playlistService.playlists$.pipe(takeUntil(this.onDestroy$)).subscribe((playlists) => {
      this.playlists = playlists.sort((a, b) => a.name.localeCompare(b.name));
      this.playlistsFiltered = this.playlists;

      this.formGroup?.controls['playlist'].setValue(
        this.playlists.find((p) => p.guid === this.editedScreen?.playlistGuid),
        { emitEvent: false }
      );
    });

    this.screensService.screenGroups$.pipe(takeUntil(this.onDestroy$)).subscribe((groups) => {
      this.groups = groups.sort((a, b) => a.order - b.order);
      this.groupsFiltered = this.groups;

      this.formGroup?.controls['group'].setValue(
        this.groups.find((g) => g.guid === this.editedScreen?.groupGuid),
        { emitEvent: false }
      );
    });

    this.screensService.screenTypes$.pipe(takeUntil(this.onDestroy$)).subscribe((screenTypes) => {
      this.screenTypes = screenTypes.sort((a, b) => a.type.localeCompare(b.type));
      this.screenTypesFiltered = this.screenTypes;

      this.formGroup?.controls['screenType'].setValue(
        this.screenTypes.find((st) => st.id === this.editedScreen?.type),
        { emitEvent: false }
      );
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.infoBannerFeatureAccessLevel =
        features.find((feature) => feature.featureId === FeatureId.SISMEDIA_MEDIACENTER_INFOBANNER)
          ?.featureAccessLevel ?? FeatureAccessLevel.NONE;
      this.showInfoBannerAllowedCheckbox = this.infoBannerFeatureAccessLevel > FeatureAccessLevel.NONE;

      this.infoBannerFeatureAccessLevel === FeatureAccessLevel.READ
        ? this.formGroup.controls['infoBannerAllowed'].disable({ emitEvent: false })
        : this.formGroup.controls['infoBannerAllowed'].enable({ emitEvent: false });
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.editedScreen.location = value.location;
      this.editedScreen.label = value.label;
      this.editedScreen.screenHeight = value.screenHeight;
      this.editedScreen.screenWidth = value.screenWidth;
      if (this.editedScreen.previewUrl == null || this.editedScreen.previewUrl == '') {
        this.editedScreen.slideshowHeight = value.screenHeight;
        this.editedScreen.slideshowWidth = value.screenWidth;
      } else {
        this.editedScreen.slideshowWidth = value.slideshowWidth;
        this.editedScreen.slideshowHeight = value.slideshowHeight;
      }
      if (this.editedScreen.slideshowHeight == 0 && this.editedScreen.slideshowWidth == 0) {
        this.editedScreen.slideshowHeight = this.editedScreen.screenHeight;
        this.editedScreen.slideshowWidth = this.editedScreen.screenWidth;
      }
      this.editedScreen.ip = value.ip;
      this.editedScreen.playlistGuid = value.playlist?.guid;
      this.editedScreen.playlistName = value.playlist?.name;
      this.editedScreen.groupGuid = value.group?.guid;
      this.editedScreen.previewUrl = value.previewUrl;
      this.editedScreen.type = value.screenType?.id;
      this.editedScreen.infoBannerAllowed = value.infoBannerAllowed;
    });
  }

  private validate(property: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const invalid = this.screens.some(
        (s) => s.guid !== this.screen.guid && s[property]?.toLocaleLowerCase() === control?.value?.toLocaleLowerCase()
      );

      return invalid ? { tooltip: 'mediacenter.phrase.duplicateName' } : null;
    };
  }

  selectPlaylist(event: { option: MatOption; source: MatOption }) {
    if (event.option.selected) {
      this.playlistsFiltered = this.playlists;
    }
  }

  selectGroup(event: { option: MatOption; source: MatOption }) {
    if (event.option.selected) {
      this.groupsFiltered = this.groups;
    }
  }

  selectScreenType(event: { option: MatOption; source: MatOption }) {
    if (event.option.selected) {
      this.screenTypesFiltered = this.screenTypes;
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async save(): Promise<void> {
    if (
      this.allScreenLabels?.includes(this.formGroup.value.label) ||
      this.allScreenNames?.includes(this.formGroup.value.location)
    ) {
      return this.userMessageService.presentToast(
        new UserMessage({
          message: 'mediacenter.phrase.labelExists',
          icon: UserMessageIcon.failed,
          durationMs: 4000,
          position: 'top',
          color: UserMessageColor.red,
        })
      );
    }
    this.screen.updating = true;

    if (!this.editedScreen.startTime) {
      this.editedScreen.startTime = '00:00:00';
    }

    if (!this.editedScreen.endTime) {
      this.editedScreen.endTime = '00:00:00';
    }

    if (await this.screensService.updateScreens([this.editedScreen])) {
      this.close();
    }
    this.screen.updating = false;
  }

  updateFilter(filterString: string, controlName: 'playlist' | 'group' | 'screenType'): void {
    if (typeof filterString === 'string') {
      if (controlName === 'playlist') {
        this.playlistsFiltered = this.playlists.filter((p) =>
          p.name.toLocaleLowerCase().includes(filterString?.toLocaleLowerCase())
        );
      }

      if (controlName === 'group') {
        this.groupsFiltered = this.groups.filter((g) =>
          g.name?.toLocaleLowerCase().includes(filterString?.toLocaleLowerCase())
        );
      }

      if (controlName === 'screenType') {
        this.screenTypesFiltered = this.screenTypes.filter((st) =>
          st.type?.toLocaleLowerCase().includes(filterString?.toLocaleLowerCase())
        );
      }

      if (filterString === '') {
        this.removeSelection(controlName);
      }
    }
  }

  removeSelection(controlName: 'playlist' | 'group' | 'screenType'): void {
    this.autocompleteTrigger.autocomplete.options.forEach((o) => o.deselect());
    this.formGroup.controls[controlName].setValue(null);
    this.formGroup.markAsDirty();
  }

  formatInputValue(inputValue: Playlist | ScreenGroup | ScreenType): string {
    if (inputValue == null) {
      return '';
    }

    if ('name' in inputValue) {
      return inputValue.name;
    } else if ('type' in inputValue) {
      return inputValue.type;
    }

    return '';
  }
}
