import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MapIconClickedEvent } from 'src/app/core/eventbus/events';

@Injectable({
  providedIn: 'root',
})
export class SelectedMapElementService {
  readonly selectedMapElement$: Observable<MapIconClickedEvent> = this.eventBus.observe(MapIconClickedEvent).pipe(
    startWith(new MapIconClickedEvent()),
    distinctUntilChanged(
      (prev, curr) =>
        prev.ropeway === curr.ropeway &&
        prev.meteoStation === curr.meteoStation &&
        prev.customMarker === curr.customMarker
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly selectedRopeway$ = this.selectedMapElement$.pipe(map((event) => event.ropeway));

  constructor(private eventBus: EventBusService) {}
}
