import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, UrlSegment } from '@angular/router';
import { combineLatest, firstValueFrom } from 'rxjs';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { SisRoute } from 'src/app/domain/sis-route.model';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { TenantService } from 'src/app/domain/tenant/tenant.service';
import { UserSettings } from 'src/app/domain/user-settings/user-settings.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class AppResolver implements Resolve<boolean> {
  private static Routes: SisRoute[] = [];

  private static _initialize = (() => {
    AppResolver.Routes = [
      new SisRoute([SisRoute.MAPS], FeatureId.COCKPIT),
      new SisRoute([SisRoute.ALARMLIST], FeatureId.COCKPIT),
      new SisRoute([SisRoute.MEDIACENTER], FeatureId.SISMEDIA_MEDIACENTER),
      new SisRoute([SisRoute.EDITOR], FeatureId.SISMEDIA_MEDIACENTER),
      new SisRoute([SisRoute.SISMEDIA, SisRoute.Lift], FeatureId.SISMEDIA_LIFT),
      new SisRoute([SisRoute.SISMEDIA, SisRoute.Slope], FeatureId.SISMEDIA_SLOPE),
      new SisRoute([SisRoute.SISMEDIA, SisRoute.CrossCountry], FeatureId.SISMEDIA_CROSSCOUNTRY),
      new SisRoute([SisRoute.SISMEDIA, SisRoute.Sledging], FeatureId.SISMEDIA_SLEDGING),
      new SisRoute([SisRoute.SISMEDIA, SisRoute.Gastro], FeatureId.SISMEDIA_GASTRO),
      new SisRoute([SisRoute.SISMEDIA, SisRoute.Trail], FeatureId.SISMEDIA_TRAIL),
      new SisRoute([SisRoute.SISMEDIA, SisRoute.Bike], FeatureId.SISMEDIA_BIKE),
      new SisRoute([SisRoute.SISMEDIA, SisRoute.Poi], FeatureId.SISMEDIA_POI),
      new SisRoute([SisRoute.SISMEDIASETTING], FeatureId.SISMEDIA_ASSET_EDIT),
      new SisRoute([SisRoute.PARKING], FeatureId.PARKING),
      new SisRoute([SisRoute.LASTSLOPECONTROL], FeatureId.SISMEDIA_LASTSLOPECONTROL),
      new SisRoute([SisRoute.INFOTEXT], FeatureId.SISMEDIA_INFOTEXT),
      new SisRoute([SisRoute.METEOINFO], FeatureId.SISMEDIA_METEOINFO),
      new SisRoute([SisRoute.STNET], FeatureId.SISMEDIA_STNET),
      new SisRoute([SisRoute.SISITSERVICES], FeatureId.SISITSERVICES),
      new SisRoute([SisRoute.GLOBAL], FeatureId.GLOBALVIEW),
      new SisRoute([SisRoute.BREAKDOWNINFO], FeatureId.BREAKDOWNINFO),
      new SisRoute([SisRoute.OPERATINGINFO], FeatureId.OPERATINGINFO),
      new SisRoute([SisRoute.USERMANAGEMENT], FeatureId.USERMANAGEMENT),
      new SisRoute([SisRoute.WEBLINKCOLLECTION], FeatureId.WEBLINKCOLLECTION),
      new SisRoute([SisRoute.TIMETABLE], FeatureId.SISMEDIA_TIMETABLE),
      new SisRoute([SisRoute.LEDTICKER], FeatureId.SISMEDIA_LEDTICKER),
      new SisRoute([SisRoute.EMAILDELIVERY], FeatureId.SISMEDIA_EMAILDELIVERY),
      new SisRoute([SisRoute.SLOPESOPERATINGTIMES], FeatureId.SISMEDIA_SLOPESOPERATINGTIMES),
      new SisRoute([SisRoute.SISAGADMIN], null),
      new SisRoute([SisRoute.FEATUREMANAGEMENT], null),
    ];
  })();

  constructor(
    private userSettingService: UserSettingsService,
    private tenantService: TenantService,
    private destinationService: DestinationService,
    private router: Router
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
    const [userSettings, tenants] = await firstValueFrom(
      combineLatest([this.userSettingService.userSettings$, this.tenantService.tenants$])
    );

    return this.handleIt(userSettings, tenants, route);
  }

  private async handleIt(
    userSettings: UserSettings,
    tenants: Tenant[],
    route: ActivatedRouteSnapshot
  ): Promise<boolean> {
    let featureUrlPart = route.url.slice(1);
    if (featureUrlPart) {
      const destination: string = route.params['destination'];

      const currentTenant = await firstValueFrom(this.destinationService.selectedTenant$);

      /* 3 outcomes:
       * If <destination> in the url is not "default", we find the tenant
       * If <destination> is "default", or we find no tenant with this sisid, we use the current tenant
       * If current tenant is undefined, we use the default tenant
       */
      const targetTenant = tenants.find((t) => t.sisId === destination);
      const tenant = targetTenant || currentTenant || userSettings.defaultTenant;

      const features = await firstValueFrom(tenant.features$);

      if (this.isTargetFeatureAllowed(featureUrlPart, features)) {
        if (targetTenant) {
          if (!currentTenant || currentTenant.sisId !== targetTenant.sisId) {
            // If current tenant not equals target tenant
            this.changeDestinationTo(targetTenant);
          }

          // If we have a targetTenant and it has the feature we want to route to, we are done routing
          return true;
        }
      } else {
        // If the feature is not allowed, route to the first allowed feature
        featureUrlPart = this.getFirstAllowedFeatureUrlSegments(features);
      }

      // Else, we route again with the selected tenant and an allowed feature of that tenant
      this.routeWithTenant(tenant, featureUrlPart);
      return false;
    }

    return false;
  }

  private changeDestinationTo(tenant: Tenant): void {
    this.destinationService.changeDestination(tenant);
  }

  private routeWithTenant(tenant: Tenant, path: UrlSegment[]): void {
    if (path.some((p) => p.path == null)) {
      this.router.navigate(['error']);
      return;
    }

    const lastSegment = path[path.length - 1];
    const [urlPart, queryParams] = lastSegment.path.split('?');
    lastSegment.path = urlPart;

    const url = [tenant.sisId];
    path.forEach((s) => url.push(s.path));

    if (queryParams) {
      const params = queryParams.split('&');

      const queryParamObj = params.reduce((p, c) => {
        const [key, value] = c.split('=');
        p[key] = value;
        return p;
      }, {});

      this.router.navigate(url, { queryParams: queryParamObj });
    } else {
      this.router.navigate(url);
    }
  }

  private isTargetFeatureAllowed(featureUrlPart: UrlSegment[], allowedFeatures: Feature[]): boolean {
    if (!featureUrlPart || featureUrlPart.length === 0) {
      return true;
    }

    const featureUrl = featureUrlPart.reduce((p, c) => `${p}/${c.path.split('?')[0]}`, '');

    const route = AppResolver.Routes.find((route) => route.featureUrl === featureUrl);

    return (
      route &&
      (!route.requiredFeatureId ||
        allowedFeatures.some((f) => f.featureId === route.requiredFeatureId && f.featureAccessLevel > 0))
    );
  }

  private getFirstAllowedFeatureUrlSegments(features: Feature[]): UrlSegment[] {
    for (const [, route] of AppResolver.Routes.entries()) {
      if (features.some((f) => f.featureId === route.requiredFeatureId && f.featureAccessLevel > 0)) {
        return route.urlSegments;
      }
    }

    return [new UrlSegment(null, {})];
  }
}
