<sis-bottom-drawer
  class="sis-bottom-drawer"
  [minimumHeight]="drawerMinimumHeight"
  [dockedHeight]="drawerDockedHeight"
  [distanceTop]="drawerDistanceTop"
  [state]="this.drawerState"
  [disableDrag]="disableDrag"
  (onDrawerStateChange)="this.onDrawerStateChange($event)"
>
  <div class="drawer-content" #drawerContent>
    <div
      #t
      class="sis-grid-title"
      (click)="titleClicked()"
      (press)="this.showControllerSelector(t)"
      oncontextmenu="return false"
    >
      <ion-icon class="sis-ropeway-icon" *ngIf="this.iconName" [name]="this.iconName"></ion-icon>
      <ion-label class="sis-label" [ngClass]="{ 'sis-destination-label': !this.ropeway }"
        >{{ this.label }}
        <div *ngIf="this.ropeway" class="sis-label2">
          {{ this.ropeway.fullname }} ({{ this.ropeway.sisId | uppercase }})
        </div>
      </ion-label>
      <ion-icon class="sis-icon" [attr.name]="this.arrowIconName"></ion-icon>
      <div *ngIf="this.showControllerContextMenu">
        <sis-controller-selector
          [x]="this.controllerContextMenuX"
          [y]="this.controllerContextMenuY"
          [ropeway]="this.ropeway"
          (done)="this.hideControllerContextMenu()"
        ></sis-controller-selector>
      </div>
    </div>

    <div
      class="sis-widget-list-container"
      [ngClass]="{ 'sis-scrollable': this.scrollable }"
      (touchstart)="touchStart()"
      (touchend)="touchEnd()"
    >
      <sis-widget-list class="sis-widget-list"></sis-widget-list>
    </div>
  </div>
</sis-bottom-drawer>
