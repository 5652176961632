import { RopewayEcoModeSettings } from 'src/app/maps/domain/ropeway-ecomode/ropeway-ecomode-settings.model';

export class RopewayEcoModeSettingsAdapter {
  static adapt(items: any): RopewayEcoModeSettings[] {
    const ecoModeSettings: RopewayEcoModeSettings[] = [];

    if (items) {
      items.forEach((element) => {
        ecoModeSettings.push({
          ropewayGuid: element.ropewayGuid,
          ropewaySisId: element.ropewaySisId,
          ecoModeEnabledCockpit: element.ecoModeEnabledCockpit,
          maxTransportQuantityPerHour: element.maxTransportQuantityPerHour,
          speedEmpty: element.speedEmpty,
          speedLow: element.speedLow,
          speedMedium: element.speedMedium,
          speedHigh: element.speedHigh,
          thresholdLow: element.thresholdLow,
          thresholdMedium: element.thresholdMedium,
        });
      });
    }

    return ecoModeSettings;
  }
}
