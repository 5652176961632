<ion-grid class="sis-detailview-container sis-global-no-scrollbar">
  <ion-row class="sis-data-row sis-title-row">
    <ion-col size="8">{{ 'general.term.category' | translate }}</ion-col>
    <ion-col size="3" class="ion-text-right">{{ 'energy.term.power' | translate }}</ion-col>
  </ion-row>

  <hr />

  <ion-row
    *ngFor="let category of this.categories | keyvalue; let i = index; let odd = odd"
    class="sis-data-row"
    [ngClass]="{ odd: odd }"
  >
    <ion-col size="8">{{ category.key | translate }}</ion-col>
    <ion-col size="3" class="ion-text-right">{{ this.allMissing ? '?' : category.value }} kW</ion-col>
  </ion-row>

  <hr />

  <div *ngIf="this.missing?.length > 0" data-test="missing-devices">
    <ion-row style="margin-bottom: 10px">
      <ion-col size="1">
        <ion-icon [ngClass]="this.allMissing ? 'sis-alarm-icon' : 'sis-warn-icon'" name="warning"></ion-icon>
      </ion-col>
      <ion-col>
        {{ 'energy.phrase.missingDevices' | translate }}
      </ion-col>
      <ion-col>
        {{ this.missing.length }}
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let item of this.missing; let i = index; let odd = odd" [ngClass]="{ odd: odd }">
      <ion-col size="1"> </ion-col>
      <ion-col size="11">
        {{ item.displayName }}
      </ion-col>
    </ion-row>
  </div>
</ion-grid>
