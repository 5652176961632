import { Component, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { FeatureId } from 'src/app//domain/feature/feature-id.model';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { Attachment } from 'src/app/email-delivery/domain/attachment.model';
import { EmailDelivery } from 'src/app/email-delivery/domain/email-delivery.model';
import { Recipient } from 'src/app/email-delivery/domain/recipient.model';
import { EmailDeliveryService } from 'src/app/email-delivery/email-delivery.service';

@Component({
  selector: 'sis-email-delivery',
  templateUrl: './email-delivery.page.html',
  styleUrls: ['./email-delivery.page.scss'],
})
export class EmailDeliveryPage extends Unsubscriber {
  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;
  readonly recipients$: Observable<Recipient[]> = this.emailDeliveryService.recipients$.pipe(
    filter((recipients) => !!recipients),
    switchMap((recipients) =>
      this.searchControl.valueChanges.pipe(
        startWith(this.searchControl.value ?? ''),
        debounceTime(200),
        map((searchString) => searchString.toLowerCase()),
        map((searchString) =>
          recipients.filter((recipient) =>
            Object.values(recipient).some((key) => key?.toLowerCase().includes(searchString))
          )
        )
      )
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private testEmailRecipient: string;

  bigScreenMode: boolean;
  writePermission: boolean;
  rerender: boolean = false;
  controlsEnabled: boolean = true;

  isPdfCreating: boolean;
  isSending: boolean;

  emailDelivery: EmailDelivery;
  emailText: string;
  subjectText: string;
  emptyText: boolean;
  emptySubject: boolean;

  attachments: Attachment[];
  selectedAttachment: Attachment;

  selectedTab = '0';
  searchControl = new FormControl<string>('');

  newRecipient: Recipient;

  featureId = FeatureId.SISMEDIA_EMAILDELIVERY;

  constructor(
    private translateService: TranslateService,
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService,
    private emailDeliveryService: EmailDeliveryService,
    private logbookModalService: LogbookModalService,
    private msalService: MsalService,
    private alertController: AlertController
  ) {
    super();
  }

  ngOnInit(): void {
    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission =
        features?.some((f) =>
          f.hasMinimumRequirementFor(new Feature(this.featureId, FeatureAccessLevel.WRITE))
        ) ?? false;
    });

    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => {
      this.bigScreenMode = bigScreenMode;
    });

    this.emailDeliveryService.pdfGenerated$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.isPdfCreating = false;
    });

    this.emailDeliveryService.emailDelivery$
      .pipe(
        filter((emailDelivery) => !!emailDelivery),
        takeUntil(this.onDestroy$)
      )
      .subscribe((emailDelivery) => {
        this.isSending = false;
        this.emailDelivery = emailDelivery;
        this.emailText = emailDelivery.text;
        this.subjectText = emailDelivery.subject;
        this.attachments = emailDelivery.attachments;
        this.attachmentChanged(emailDelivery.lastSentAttachment);
      });

    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.rerender = true;
      setTimeout(() => {
        this.rerender = false;
      }, 50);
    });

    this.testEmailRecipient = this.msalService.instance.getActiveAccount().username;
  }

  downloadPdf(): void {
    if (!this.selectedAttachment?.outdated) {
      const link = document.createElement('a');
      link.href = this.selectedAttachment.pdfUrl + '?' + (Math.random() * 10000).toString();
      link.click();
    }
  }

  selectionChanged(index: string): void {
    this.selectedTab = index;
  }

  emailTextChanged(text: string): void {
    this.emailText = text;
    this.emptyText = this.isEmpty(text);
  }

  subjectTextChanged(text: string): void {
    this.subjectText = text;
    this.emptySubject = this.isEmpty(text);
  }

  attachmentChanged(item: Attachment): void {
    this.selectedAttachment = item;

    if (item.pdfUrl != null) {
      this.emailDeliveryService.checkAttachmentOutdated(item.pdfUrl).subscribe((outdated) => {
        item.outdated = outdated;
      });
    }
  }

  async createPdf(): Promise<void> {
    if (this.writePermission && !this.isPdfCreating && this.selectedAttachment?.pdfUrl != null) {
      this.isPdfCreating = true;
      const success = await this.emailDeliveryService.postGeneratePdf(this.selectedAttachment.guid);
      if (!success) {
        this.isPdfCreating = false;
      }
    }
  }

  openTestEmailDialog(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      this.alertController
        .create({
          header: await this.getTranslatedString('emaildelivery.phrase.sendTestEmailTo'),
          subHeader: '',
          inputs: [
            {
              name: 'email',
              type: 'text',
              value: `${this.testEmailRecipient}`,
            },
          ],
          buttons: [
            {
              text: await this.getTranslatedString('emaildelivery.phrase.sendEmail'),
              handler: async (data: { email: string }) => {
                if (data?.email?.length) {
                  await this.sendEmail(data.email);
                  this.testEmailRecipient = data.email;
                  resolve();
                  return true;
                }
                return false;
              },
            },
            {
              text: await this.getTranslatedString('general.term.cancel'),
              role: 'cancel',
            },
          ],
          backdropDismiss: false,
        })
        .then((modal) => {
          modal.present();
        });
    });
  }

  async sendEmail(recipient?: string): Promise<void> {
    if (
      this.writePermission &&
      !this.isSending &&
      !this.emptySubject &&
      !this.emptyText &&
      !this.selectedAttachment?.outdated
    ) {
      this.isSending = true;
      const success = await this.emailDeliveryService.postSendEmail(
        this.selectedAttachment?.guid,
        this.emailText,
        this.subjectText,
        recipient
      );
      if (!success) {
        this.isSending = false;
      }
    }
  }

  editItem(editing: boolean) {
    this.controlsEnabled = !editing;

    if (!editing && this.newRecipient) {
      this.newRecipient = null;
    }
  }

  addRecipient() {
    this.controlsEnabled = false;
    this.newRecipient = {
      email: null,
      guid: null,
      location: null,
      name: null,
    };
  }

  private isEmpty(str: string): boolean {
    return !str || str.length === 0;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return this.controlsEnabled;
  }

  private getTranslatedString(translateKey: string): Promise<string> {
    return firstValueFrom(this.translateService.get(translateKey));
  }
}
