import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Options } from 'highcharts';
import moment from 'moment';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { MeteoStationService } from 'src/app/maps/meteostation-service/meteostation.service';
import { ForecastTrend } from 'src/app/maps/trenddata/forecast-trend.model';
import { MeteoTrendService } from 'src/app/maps/trenddata/meteo-trend.service';
import { ChartBase } from 'src/app/maps/widgets/charts/chart-base';
import { ChartSettings } from 'src/app/maps/widgets/charts/chart-settings.model';

@Component({
  selector: 'sis-icing-chart',
  templateUrl: './icing-chart.component.html',
  styleUrls: ['./icing-chart.component.scss'],
})
export class IcingChartComponent extends ChartBase implements OnInit {
  static readonly icingForecastSeries = 'icingForecastSeries';
  static readonly plotlineWarn = 'PLOT1';

  private static readonly titleTranslateString = 'ropeway.term.icing';
  private static readonly intensityHighTranslateString = 'icing.term.intensityHigh';
  private static readonly intensityMediumTranslateString = 'icing.term.intensityMedium';
  private static readonly intensityLowTranslateString = 'icing.term.intensityLow';
  private static readonly intensityNoneTranslateString = 'icing.term.intensityNone';

  private readonly defaultMaxY = 3.2;
  private readonly defaultMinY = 0;
  private readonly lineWidth = 2;
  private readonly meteoStation$ = this.chartAdapter.chartReady$.pipe(
    take(1),
    switchMap(() => this.meteoStationService.meteoStationWithForecast$)
  );

  private intensityHighText: string;
  private intensityMediumText: string;
  private intensityLowText: string;
  private intensityNoneText: string;

  constructor(
    private meteoForecastDataService: MeteoTrendService,
    private meteoStationService: MeteoStationService,
    timeFormatService: TimeFormatService,
    translateService: TranslateService,
    chartSettings: ChartSettings
  ) {
    super(translateService, chartSettings?.inModal, timeFormatService);

    this.maxY = this.defaultMaxY;
    this.minY = this.defaultMinY;
  }

  ngOnInit(): void {
    this.meteoStation$
      .pipe(
        distinctUntilChanged(),
        filter((meteoStation) => !!meteoStation),
        switchMap((meteoStation) => {
          this.initChart(meteoStation);
          this.chartAdapter.showLoading();

          const observables = [this.meteoForecastDataService.getForecastTrends(meteoStation.guid)];

          return this.chartAdapter.loadData(observables);
        }),
        map((data: [ForecastTrend[]]) => data[0]),
        takeUntil(this.onDestroy$)
      )
      .subscribe((data: ForecastTrend[]) => {
        this.updateChartData(data);
        this.chartAdapter.hideLoading();
      });
  }

  private initChart(meteoStation: MeteoStation) {
    this.deviceAlias = meteoStation.ropeway?.alias;
    this.updateTitle();

    this.chartAdapter.showLoading();
    this.chartAdapter.removeAllSeries();
    this.chartAdapter.addSeries(IcingChartComponent.icingForecastSeries, {
      name: this.title,
      id: IcingChartComponent.icingForecastSeries,
      type: 'spline' as any,
      lineWidth: this.lineWidth,
      className: 'line-forecast',
      color: this.getTrendLineColor(),
    });

    this.chartAdapter.reflowChart(this.onDestroy$);
  }

  private updateChartData(forecasts: ForecastTrend[]): void {
    if (!forecasts) {
      this.resetChart();
      return;
    }

    const minX = Date.now();
    const maxX = this.chartAdapter.getTimeInMsDaysAgo(-1);

    forecasts = forecasts.filter((t) => t.timestamp.getTime() >= minX && t.timestamp.getTime() <= maxX);

    if (forecasts.length === 0) {
      this.resetChart();
      this.chartAdapter.showNoData();
      return;
    }

    const colorZones = this.getZones();
    const icingData = this.getData(forecasts).sort((i1, i2) => i1[0] - i2[0]);

    this.chartAdapter.setSeriesOptions(IcingChartComponent.icingForecastSeries, { zones: colorZones } as any);
    this.chartAdapter.setSeriesData(IcingChartComponent.icingForecastSeries, icingData);

    if (!this.isZoomed) {
      this.chartAdapter.setYAxisExtremes(0, this.minY, this.maxY);
      this.chartAdapter.setXAxisExtremes(0, minX, maxX);
    }

    if (this.isZoomed && this.isZoomedToEnd) {
      this.chartAdapter.setXAxisExtremes(0, this.zoomMin, null);
    }
  }

  private getZones(): any[] {
    const zones = [];
    zones.push({
      value: 0.1,
      className: 'zone-nominal',
    });
    zones.push({
      value: 2.1,
      className: 'zone-warn',
    });
    zones.push({
      value: 3.2,
      className: 'zone-alarm',
    });
    return zones;
  }

  private getData(data: ForecastTrend[]): number[][] {
    return data.map((item) => {
      const timeStampInMs = item.timestamp.getTime();
      return [timeStampInMs, item.icingIntensity];
    });
  }

  private resetChart(): void {
    this.chartAdapter.clearAllSeriesData();
  }

  protected translateTexts(translateService: TranslateService): void {
    translateService
      .get([
        IcingChartComponent.titleTranslateString,
        IcingChartComponent.intensityHighTranslateString,
        IcingChartComponent.intensityMediumTranslateString,
        IcingChartComponent.intensityLowTranslateString,
        IcingChartComponent.intensityNoneTranslateString,
      ])
      .pipe(take(1))
      .subscribe((res) => {
        this.title = res[IcingChartComponent.titleTranslateString];
        this.intensityHighText = res[IcingChartComponent.intensityHighTranslateString];
        this.intensityMediumText = res[IcingChartComponent.intensityMediumTranslateString];
        this.intensityLowText = res[IcingChartComponent.intensityLowTranslateString];
        this.intensityNoneText = res[IcingChartComponent.intensityNoneTranslateString];

        if (this.chartAdapter) {
          this.chartAdapter.updateOptions({
            title: {
              text: this.isModal ? '' : this.title,
            },
          });
        }

        if (this.isModal) {
          this.updateModalTitle();
        }
      });
  }

  protected getChartOptions(): Options {
    const intensityHighText = this.intensityHighText;
    const intensityMediumText = this.intensityMediumText;
    const intensityLowText = this.intensityLowText;
    const intensityNoneText = this.intensityNoneText;
    return {
      time: {
        useUTC: false,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      legend: {
        enabled: this.isModal,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      xAxis: {
        type: 'datetime',
        crosshair: this.isModal,
        tickInterval: this.isModal ? 60 * 60 * 1000 : 4 * 60 * 60 * 1000, // 1 hour in modal, otherwise 4 hours (in ms)
        events: {
          setExtremes: (event) => this.handleSetExtremesEvent(event),
        },
      },
      chart: {
        zoomType: 'xy',
        panKey: 'ctrl',
        panning: {
          enabled: true,
        },
        animation: false,
        style: {
          fontFamily: 'Myriad Pro',
        },
        resetZoomButton: {
          theme: {
            stroke: 'var(--ion-color-primary)',
            height: 8,
          },
        },
      },
      yAxis: {
        title: {
          text: '',
          align: 'high',
          offset: 0,
          rotation: 0,
          y: -15,
          style: {
            fontSize: '12px',
          },
        },
        lineWidth: 1,
        tickInterval: 1,
        endOnTick: false,
        labels: {
          style: {
            fontSize: '12px',
          },
          formatter() {
            switch (this.value) {
              case 1:
                return intensityLowText;
              case 2:
                return intensityMediumText;
              case 3:
                return intensityHighText;
              default:
                return intensityNoneText;
            }
          },
          rotation: -90,
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: this.isModal,
        split: true,
        formatter() {
          const bottomText = `<span style="font-size: 8pt">${moment(new Date(this.x)).format(
            'DD.MM.YYYY / HH:mm:ss'
          )}</span>`;
          switch (this.y) {
            case 1:
              return [bottomText, intensityLowText];
            case 2:
              return [bottomText, intensityMediumText];
            case 3:
              return [bottomText, intensityHighText];
            default:
              return [bottomText, intensityNoneText];
          }
        },
      },
      lang: {
        noData: this.noDataText,
        loading: this.loadingText,
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      loading: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
          },
        },
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
    };
  }
}
