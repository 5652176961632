import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { EditToken } from 'src/app/sisag-admin/domain/edit-token.model';
import { SisagAdminService } from 'src/app/sisag-admin/sisag-admin.service';
import { environment } from 'src/environments/environment';
import { v4 as v4guid } from 'uuid';

@Component({
  selector: 'sis-sismap-edit-token',
  templateUrl: './sismap-edit-token.component.html',
  styleUrls: ['./sismap-edit-token.component.scss'],
})
export class SisMapEditTokenComponent extends Unsubscriber implements OnInit {
  editTokenItems: EditToken[];
  itemsWithEditToken: EditToken[];
  itemsWithoutEditToken: EditToken[];
  selectedEditToken: EditToken;

  constructor(private editTokenService: SisagAdminService) {
    super();
  }

  ngOnInit(): void {
    this.editTokenService.editTokens$.pipe(takeUntil(this.onDestroy$)).subscribe((editTokens) => {
      this.editTokenItems = editTokens.sort((a, b) => a.name.localeCompare(b.name));
      this.splitEditTokenItems();
    });
  }

  private splitEditTokenItems(): void {
    this.itemsWithEditToken = this.editTokenItems.filter((item) => item.editToken != null);
    this.itemsWithoutEditToken = this.editTokenItems.filter((item) => item.editToken == null);
  }

  async updateEditToken(insert: boolean, editToken: EditToken): Promise<void> {
    const postData: EditToken = {
      guid: editToken.guid,
      tenantSisId: editToken.tenantSisId,
      name: editToken.name,
      areaUrlName: editToken.areaUrlName,
      season: editToken.season,
      editToken: insert ? v4guid() : null,
    };
    const operation = insert ? 'insert' : 'delete';

    await this.editTokenService.insertOrDeleteEditToken(postData, operation);

    this.selectedEditToken = null;
  }

  async openUrl(editToken: EditToken): Promise<void> {
    const url =
      editToken.season == 0
        ? `${environment.publicWebApp}/${editToken.tenantSisId}/sismap/${editToken.areaUrlName}/${editToken.season}?season=1&edit=${editToken.editToken}`
        : `${environment.publicWebApp}/${editToken.tenantSisId}/sismap/${editToken.areaUrlName}/${editToken.season}?edit=${editToken.editToken}`;

    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.focus();
    } else {
      alert('Bitte erlauben Sie Pop-ups für diese Website.');
    }
  }
}
