import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ItemReorderEventDetail } from '@ionic/angular';
import { takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { WebLink } from 'src/app/weblink-collection/domain/weblink-item.model';
import { WebLinkItemCategory } from 'src/app/weblink-collection/domain/weblink-item-category.enum';
import { WebLinkCollectionService } from 'src/app/weblink-collection/weblink-collection.service';

@Component({
  selector: 'sis-weblink-group',
  templateUrl: './weblink-group.component.html',
  styleUrls: ['./weblink-group.component.scss'],
})
export class WeblinkGroupComponent extends Unsubscriber implements OnInit, OnChanges {
  @Input() category: WebLinkItemCategory;
  @Input() writePermission: boolean;
  @Input() readPermission: boolean;
  @Input() reorderingEnabled: boolean;
  @Input() tenantAlias: string;

  items: WebLink[] = [];
  eWebLinkItemCategory = WebLinkItemCategory;
  canReorder: boolean;

  constructor(private webLinkCollectionService: WebLinkCollectionService) {
    super();
  }

  ngOnInit() {
    this.webLinkCollectionService.webLinks$.pipe(takeUntil(this.onDestroy$)).subscribe((webLinks) => {
      this.items = webLinks
        .filter((weblink) => weblink.category === this.category)
        .sort((a, b) => a.displayOrder - b.displayOrder);
    });
  }

  ngOnChanges() {
    this.canReorder =
      this.reorderingEnabled &&
      ((this.category === WebLinkItemCategory.tenant && this.writePermission) ||
        (this.category === WebLinkItemCategory.private && this.readPermission));
  }

  doReorder(ev: CustomEvent<ItemReorderEventDetail>): void {
    ev.detail.complete(this.items);
    this.items.forEach((item, index) => (item.displayOrder = index));
  }
}
