import { HttpClient } from '@angular/common/http';
import { Directive } from '@angular/core';
import { BehaviorSubject, EMPTY, merge, Observable, of, Subject, timer } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { environment } from 'src/environments/environment';

@Directive()
export abstract class LivedataServiceBase<S> {
  private readonly updateInterval$ = new BehaviorSubject<number>(10000);
  private readonly updateTrigger$ = new Subject<void>();

  protected abstract readonly requiredFeatures: Feature[];
  protected abstract readonly endpointUri: string;

  protected liveData$: Observable<S[]> = this.destinationService.selectedTenant$.pipe(
    filter((tenant) => !!tenant),
    switchMap((tenant) =>
      tenant.features$.pipe(
        map((allowedFeatures) => ({
          tenant,
          hasRequiredFeature: this.requiredFeatures.every((f) =>
            allowedFeatures.some((a) => a.hasMinimumRequirementFor(f))
          ),
        }))
      )
    ),
    distinctUntilChanged((x, y) => x.tenant === y.tenant && x.hasRequiredFeature === y.hasRequiredFeature),
    switchMap(({ hasRequiredFeature }) => {
      if (hasRequiredFeature) {
        return timer(0, 1000).pipe(
          exhaustMap(() =>
            this.requestSasToken().pipe(
              map((sasToken) => {
                let expiryDuration: number;
                if (!sasToken) {
                  expiryDuration = 0;
                } else {
                  const expiryParam = new URLSearchParams(sasToken).get('se');
                  const expiryDate = new Date(expiryParam).valueOf();
                  expiryDuration = expiryDate - Date.now() - 10000;
                }

                return { sasToken, expiryDuration };
              }),
              switchMap(({ sasToken, expiryDuration }) => {
                const killTimer = timer(expiryDuration);

                return merge(
                  this.updateInterval$.pipe(
                    switchMap((updateInterval) => this.getLiveDataRepeatedly(sasToken, updateInterval))
                  ),
                  this.updateTrigger$.pipe(switchMap(() => this.getLiveDataRepeatedly(sasToken, 0)))
                ).pipe(takeUntil(killTimer));
              })
            )
          )
        );
      } else {
        return of([]);
      }
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private http: HttpClient,
    private adapt: (items: any) => S[],
    protected destinationService: DestinationService
  ) {}

  setInterval(updateInterval: number): void {
    this.updateInterval$.next(updateInterval);
  }

  forceLiveDataUpdate(): void {
    this.updateTrigger$.next();
  }

  private requestSasToken(): Observable<string> {
    const url = `${environment.baseUrlApi}/${this.endpointUri}`;
    return this.http.get(url).pipe(
      catchError(() => of(null)),
      map((sas) => sas?.toString()),
      take(1)
    );
  }

  private getLiveDataRepeatedly(sasToken: string, updateInterval: number): Observable<S[]> {
    if (!sasToken || updateInterval == null) {
      return of([]);
    }

    const timerObservable = updateInterval > 0 ? timer(0, updateInterval) : of(0);

    return timerObservable.pipe(
      switchMap(() => this.http.get(sasToken).pipe(catchError(() => EMPTY))),
      map((data: any) => (data == null ? [] : this.adapt(data.value)))
    );
  }
}
