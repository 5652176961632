import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private readonly bigScreenThreshold = 992; // ionic lg

  private readonly screenMode$ = fromEvent(window, 'resize').pipe(
    startWith(<Event>null),
    map(() => {
      const bigScreenMode = this.platform.width() > this.bigScreenThreshold;
      const landscapeMode = this.platform.isLandscape() && !bigScreenMode;

      return { bigScreenMode, landscapeMode };
    }),
    shareReplay(1)
  );

  readonly bigScreenMode$ = this.screenMode$.pipe(
    map(({ bigScreenMode }) => bigScreenMode),
    distinctUntilChanged(),
    shareReplay(1)
  );

  readonly landscapeMode$ = this.screenMode$.pipe(
    map(({ landscapeMode }) => landscapeMode),
    distinctUntilChanged(),
    shareReplay(1)
  );

  constructor(private platform: Platform) {}
}
