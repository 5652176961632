<ion-row class="sis-global-row-40">
  <ion-col>
    <ion-grid style="padding: 0px">
      <ion-col class="sis-item-type">
        {{ 'ropeway.term.rope' | translate }}
      </ion-col>
      <ion-row>
        <ion-col size="5" class="sis-item-name">
          {{ this.truScan.name }}
        </ion-col>
        <ion-col class="sis-item-date">
          {{ this.truScan.ropeTimestamp | date : 'dd.MM.yyyy HH:mm' }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-col>
  <ion-col
    size="auto"
    style="min-width: 25px; padding-right: 7px"
    matTooltip="{{ 'ropeway.term.ropeHealth' | translate }}: {{ this.truScan.health }} %"
  >
    <div style="width: 50px; height: 29px; align-items: center" [chart]="this.chart"></div>
  </ion-col>
</ion-row>
