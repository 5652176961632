import { TimetableJourney } from 'src/app/timetable/domain/timetable-journey.model';
import { TimetableSeason } from 'src/app/timetable/domain/timetable-season.model';

export class TimetableSeasonAdapter {
  static adapt(items: any): TimetableSeason[] {
    const seasons: TimetableSeason[] = [];

    if (items) {
      items.forEach((item: any) => {
        const journeys: TimetableJourney[] = item.journeys;
        seasons.push({
          guid: item.guid,
          tenantGuid: item.tenantGuid,
          name: item.name,
          journeys,
          isEmergencyTimetable: item.isEmergencyTimetable,
          isImportTimetable: item.isImportTimetable,
          lastImportDate: item.lastImportDateTime ? new Date(item.lastImportDateTime) : null,
          lastSuccessfulRealTimeImport: item.lastSuccessfulRealTimeImport
            ? new Date(item.lastSuccessfulRealTimeImport)
            : null,
          lastImportSuccessful: item.lastImportSuccessful,
          trackGuid: item.trackGuid,
          validFrom: item.isImportTimetable ? null : item.validFrom,
          validUntil: item.isImportTimetable ? null : item.validUntil,
        });
      });
    }

    return seasons;
  }
}
