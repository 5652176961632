import { WritablePart } from 'src/app/core/utils/type-helper';

export class Slide {
  static readonly daysArray: string[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  private internalThumbnailUrl: string;

  guid: string;
  name: string;
  filePath: string;
  data: string;
  url: string;
  fileType: string;
  duration: number;
  canEdit: boolean;
  timeControlEnabled: boolean;
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  enabled: boolean;
  thumbnail: string;
  deleted: boolean;
  updating: boolean;
  inPlaylist: boolean;

  disabledByTimeControl: boolean;

  static areSlidesEqual(s1: Slide, s2: Slide): boolean {
    for (const key of Object.keys(s1)) {
      if (s2[key] !== s1[key] && key !== 'disabledByTimeControl' && key !== 'daysArray') {
        return false;
      }
    }
    return true;
  }

  set thumbnailUrl(thumbnailUrl: string) {
    this.internalThumbnailUrl = thumbnailUrl;

    if (this.fileType === 'video') {
      this.thumbnail = 'assets/images/video-dummy-large.svg';
      return;
    }

    if (thumbnailUrl === null) {
      this.thumbnail = 'assets/images/image-dummy-large.svg';
      return;
    }

    this.thumbnail = thumbnailUrl;
  }

  get thumbnailUrl() {
    return this.internalThumbnailUrl;
  }

  constructor(init?: Partial<WritablePart<Slide>>) {
    Object.assign(this, init);
  }
}
