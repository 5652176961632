import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import {
  SisMediaAssetCockpitOrderUpdatedEvent,
  SisMediaAssetEditUpdatedEvent,
  SisMediaAssetExportMappingUpdatedEvent,
  SisMediaOperatingHoursUpdatedEvent,
  SisMediaStatusUpdatedEvent,
} from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Export } from 'src/app/domain/export/export.model';
import { ExportService } from 'src/app/domain/export/export.service';
import { UserSettings } from 'src/app/domain/user-settings/user-settings.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { SisMediaAssetSubStatus } from 'src/app/sismedia//domain/sismedia-asset-substatus.enum';
import { SisMediaAssetCategory } from 'src/app/sismedia/domain/sismedia-asset-category.enum';
import { SisMediaAssetEdit } from 'src/app/sismedia/domain/sismedia-asset-edit.model';
import { SisMediaAssetRequest } from 'src/app/sismedia/domain/sismedia-asset-request.model';
import { SisMediaAssetStatus } from 'src/app/sismedia/domain/sismedia-asset-status.enum';
import { SisMediaAssetType } from 'src/app/sismedia/domain/sismedia-asset-type.model';
import { SisMediaItemAdapter } from 'src/app/sismedia/domain/sismedia-item.adapter';
import { SisMediaItem } from 'src/app/sismedia/domain/sismedia-item.model';
import { SisMediaItemOrder } from 'src/app/sismedia/domain/sismedia-item-order.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SisMediaItemService {
  private static readonly url: string = '/sismedia';

  static readonly insertOrUpdateOperation = 'insertOrUpdate';
  static readonly deleteOperation = 'delete';

  private readonly _sisMediaAssetOrderUpdated$ = new Subject<SisMediaAssetCategory[]>();

  private readonly sisMediaAssetsAndTypes$: Observable<{
    items: Map<SisMediaAssetCategory, SisMediaItem[]>;
    types: Map<SisMediaAssetCategory, SisMediaAssetType[]>;
  }> = this.destinationService.selectedTenantFeatures$.pipe(
    filter((tenant) => !!tenant),
    switchMap(() =>
      this.http.get<SisMediaAssetRequest[]>(`${environment.baseUrlApi}${SisMediaItemService.url}/assets`).pipe(
        map((data) => {
          const types = data.reduce(
            (p, c) =>
              p.set(
                c.assetCategory,
                c.assetTypes?.sort((a, b) => a.description.localeCompare(b.description))
              ),
            new Map<SisMediaAssetCategory, SisMediaAssetType[]>()
          );

          const items = data.reduce(
            (p, c) => p.set(c.assetCategory, SisMediaItemAdapter.adapt(c.assets)),
            new Map<SisMediaAssetCategory, SisMediaItem[]>()
          );

          return { items, types };
        })
      )
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly _sisMediaItems$: Observable<Map<SisMediaAssetCategory, SisMediaItem[]>> =
    this.sisMediaAssetsAndTypes$.pipe(map(({ items: assets }) => assets));

  private readonly sisMediaStatusUpdated$: Observable<Map<SisMediaAssetCategory, SisMediaItem[]>> = this.eventBus
    .observe(SisMediaStatusUpdatedEvent)
    .pipe(
      withLatestFrom(this.userSettingService.userSettings$, this.destinationService.selectedTenant$),
      filter(([event, , tenant]) => event.tenantGuid === tenant.guid),
      withLatestFrom(this._sisMediaItems$),
      map(([[event, user], itemMap]) => this.handleStatusUpdatedEvent(event, itemMap, user))
    );

  private readonly sisMediaItemsUpdated$: Observable<Map<SisMediaAssetCategory, SisMediaItem[]>> = this.eventBus
    .observe(SisMediaAssetEditUpdatedEvent)
    .pipe(
      filter((event) => event.updateSuccessful),
      withLatestFrom(this.userSettingService.userSettings$, this.destinationService.selectedTenant$),
      filter(([event, , tenant]) => event.tenantGuid === tenant.guid),
      withLatestFrom(this._sisMediaItems$),
      map(([[event, user], itemMap]) => this.handleAssetEditUpdatedEvent(event, itemMap, user))
    );

  private readonly sisMediaOperatingHoursUpdated$: Observable<Map<SisMediaAssetCategory, SisMediaItem[]>> =
    this.eventBus.observe(SisMediaOperatingHoursUpdatedEvent).pipe(
      filter((event) => event.updateSuccessful),
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([event, tenant]) => event.updateSuccessful && event.tenantGuid === tenant.guid),
      withLatestFrom(this._sisMediaItems$),
      map(([[event], itemMap]) => this.handleOperatingHoursUpdatedEvent(event, itemMap))
    );

  private readonly sisMediaAssetExportMappingUpdated$: Observable<Map<SisMediaAssetCategory, SisMediaItem[]>> =
    this.eventBus.observe(SisMediaAssetExportMappingUpdatedEvent).pipe(
      filter((event) => event.updateSuccessful),
      withLatestFrom(this.destinationService.selectedTenant$, this.exportService.exports$),
      filter(([event, tenant]) => event.tenantGuid === tenant.guid),
      withLatestFrom(this._sisMediaItems$),
      map(([[event, , exports], itemMap]) => this.handleExportMappingUpdatedEvent(event, itemMap, exports))
    );

  private readonly sisMediaAssetCockpitOrderUpdated$: Observable<Map<SisMediaAssetCategory, SisMediaItem[]>> =
    this.eventBus.observe(SisMediaAssetCockpitOrderUpdatedEvent).pipe(
      filter((event) => event.updateSuccessful),
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([event, tenant]) => event.updateSuccessful && event.tenantGuid === tenant.guid),
      withLatestFrom(this._sisMediaItems$),
      map(([[event], itemMap]) => this.handleOrderUpdateEvent(event, itemMap))
    );

  readonly sisMediaItems$: Observable<Map<SisMediaAssetCategory, SisMediaItem[]>> = merge(
    this._sisMediaItems$,
    this.sisMediaItemsUpdated$,
    this.sisMediaStatusUpdated$,
    this.sisMediaOperatingHoursUpdated$,
    this.sisMediaAssetExportMappingUpdated$,
    this.sisMediaAssetCockpitOrderUpdated$
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly sisMediaTypes$: Observable<Map<SisMediaAssetCategory, SisMediaAssetType[]>> =
    this.sisMediaAssetsAndTypes$.pipe(map(({ types }) => types));

  readonly sisMediaCategoryItemOrderUpdated$ = this._sisMediaAssetOrderUpdated$.asObservable();

  constructor(
    private userSettingService: UserSettingsService,
    private http: HttpClient,
    private eventBus: EventBusService,
    private userMessageService: UserMessageService,
    private destinationService: DestinationService,
    private exportService: ExportService
  ) {}

  async updateStatus(
    assetGuid: string,
    assetCategory: SisMediaAssetCategory,
    value: SisMediaAssetStatus,
    subStatus: SisMediaAssetSubStatus
  ): Promise<void> {
    try {
      await firstValueFrom(
        this.http.post(`${environment.baseUrlApi}${SisMediaItemService.url}/status`, {
          assetGuid: assetGuid,
          assetCategory,
          status: value,
          subStatus: subStatus,
        })
      );
    } catch {
      const userGuid = (await firstValueFrom(this.userSettingService.userSettings$)).userGuid.toString();
      this.eventBus.publish(this.createErrorEvent(assetGuid, assetCategory, userGuid));
    }
  }

  async insertOrUpdateItems(sisMediaAssetEdits: SisMediaAssetEdit[], operation: string): Promise<void> {
    if (!sisMediaAssetEdits?.length) {
      return;
    }

    try {
      await firstValueFrom(
        this.http.post(`${environment.baseUrlApi}${SisMediaItemService.url}/asset/update`, {
          Assets: sisMediaAssetEdits,
          Operation: operation,
        })
      );
    } catch {
      this.presentToast(false);
    }
  }

  async reorderItems(reorderPostDatas: SisMediaItemOrder[]): Promise<void> {
    try {
      await firstValueFrom(
        this.http.post(`${environment.baseUrlApi}${SisMediaItemService.url}/asset/reorder`, reorderPostDatas)
      );
    } catch {
      this.presentToast(false);
    }
  }

  private presentToast(success: boolean, deleted: boolean = false, assetName: string = null): void {
    const message = success
      ? deleted
        ? ["'", assetName, "' ", 'sismedia.phrase.assetDeleted']
        : 'general.phrase.saved'
      : 'general.phrase.saveFailed';
    const color = success ? UserMessageColor.green : UserMessageColor.red;
    const icon = success ? UserMessageIcon.success : UserMessageIcon.failed;
    this.userMessageService.presentToast(new UserMessage({ message, icon, durationMs: 2000, position: 'top', color }));
  }

  private createErrorEvent(
    assetGuid: string,
    assetCategory: SisMediaAssetCategory,
    changedby: string
  ): SisMediaStatusUpdatedEvent {
    const event = new SisMediaStatusUpdatedEvent();
    event.assetCategory = assetCategory;
    event.guid = assetGuid;
    event.changedBy = changedby;
    return event;
  }

  // Update handler methods

  private handleStatusUpdatedEvent(
    event: SisMediaStatusUpdatedEvent,
    itemMap: Map<SisMediaAssetCategory, SisMediaItem[]>,
    user: UserSettings
  ): Map<SisMediaAssetCategory, SisMediaItem[]> {
    const updatedItem = itemMap.get(event?.assetCategory)?.find((item) => item.assetGuid === event.guid);
    if (updatedItem) {
      if (event.updateSuccessful) {
        updatedItem.status = event.status;
        updatedItem.subStatus = event.subStatus;
        updatedItem.activeStatus = event.activeStatus;
        updatedItem.statusOverrideActive = event.statusOverrideActive;
        updatedItem.lastStatusChange = event.lastStatusChange;
      }

      if (event.changedBy === user.userGuid) {
        const message = event.updateSuccessful ? 'sismedia.phrase.statusUpdated' : event.failReason;
        const icon = event.updateSuccessful ? UserMessageIcon.success : UserMessageIcon.failed;
        const color = event.updateSuccessful ? UserMessageColor.green : UserMessageColor.red;

        this.userMessageService.presentToast(
          new UserMessage({
            message,
            icon,
            durationMs: 2000,
            position: 'top',
            color,
          })
        );
      }

      updatedItem.setUpdating(SisMediaItem.Status, false);
    }

    return itemMap;
  }

  private handleAssetEditUpdatedEvent(
    event: SisMediaAssetEditUpdatedEvent,
    itemMap: Map<SisMediaAssetCategory, SisMediaItem[]>,
    user: UserSettings
  ): Map<SisMediaAssetCategory, SisMediaItem[]> {
    const items: SisMediaItem[] = [].concat(...[...itemMap.values()]);

    if (event.operation === SisMediaItemService.insertOrUpdateOperation) {
      let assetUpdated = false;
      items.forEach((item: SisMediaItem) => {
        const updatedAsset = event.assetEdits.find((asset) => asset.assetGuid === item.assetGuid);
        if (updatedAsset) {
          Object.assign(item, updatedAsset);
          assetUpdated = true;

          item.setUpdating(SisMediaItem.AssetEdit, false);
        }
      });

      if (event.changedBy === user.userGuid && assetUpdated) {
        this.presentToast(true);
      }

      if (!assetUpdated) {
        const items = SisMediaItemAdapter.adapt(event.assetEdits);
        items.forEach((item) => {
          itemMap.set(item.assetCategory, [...itemMap.get(item.assetCategory), item]);
          if (event.changedBy === user.userGuid) {
            this.userMessageService.presentToast(
              new UserMessage({
                message: ["'", item.name, "' ", 'sismedia.phrase.assetCreated'],
                icon: UserMessageIcon.success,
                durationMs: 2000,
                position: 'top',
                color: UserMessageColor.green,
              })
            );
          }
        });
      }
    }

    if (event.operation === SisMediaItemService.deleteOperation) {
      const asset = event.assetEdits[0];
      const items = itemMap.get(asset.assetCategory);
      const index = items.findIndex((i) => i.assetGuid === asset.assetGuid);
      if (index > -1) {
        items.splice(index, 1);
        if (event.changedBy === user.userGuid) {
          this.userMessageService.presentToast(
            new UserMessage({
              message: ["'", asset.name, "' ", 'sismedia.phrase.assetDeleted'],
              icon: UserMessageIcon.success,
              durationMs: 2000,
              position: 'top',
              color: UserMessageColor.green,
            })
          );
        }
      }
    }
    return itemMap;
  }

  private handleOperatingHoursUpdatedEvent(
    event: SisMediaOperatingHoursUpdatedEvent,
    itemMap: Map<SisMediaAssetCategory, SisMediaItem[]>
  ): Map<SisMediaAssetCategory, SisMediaItem[]> {
    const items = [].concat(...[...itemMap.values()]);
    items.forEach((item: SisMediaItem) => {
      if (item.assetGuid === event.assetGuid) {
        if (event.operatingHoursEnabled != null) {
          item.operatingHoursEnabled = event.operatingHoursEnabled;
        }
        item.setUpdating(SisMediaItem.OperatingHours, false);
      }
    });
    return itemMap;
  }

  private handleOrderUpdateEvent(
    event: SisMediaAssetCockpitOrderUpdatedEvent,
    itemMap: Map<SisMediaAssetCategory, SisMediaItem[]>
  ) {
    const ordersUpdated = new Set<SisMediaAssetCategory>();
    event.assetOrders.forEach((assetOrder) => {
      const updatedItem = itemMap.get(assetOrder.assetCategory).find((i) => i.assetGuid == assetOrder.assetGuid);
      if (updatedItem) {
        updatedItem.cockpitOrder = assetOrder.cockpitOrder;
        ordersUpdated.add(assetOrder.assetCategory);
      }
    });

    if (ordersUpdated.size > 0) {
      this._sisMediaAssetOrderUpdated$.next([...ordersUpdated.values()]);
    }

    return itemMap;
  }

  private handleExportMappingUpdatedEvent(
    event: SisMediaAssetExportMappingUpdatedEvent,
    itemMap: Map<SisMediaAssetCategory, SisMediaItem[]>,
    exports: Export[]
  ): Map<SisMediaAssetCategory, SisMediaItem[]> {
    const items = itemMap.get(event.assetCategory);
    items.forEach((item: SisMediaItem) => {
      if (item.assetGuid === event.assetGuid) {
        item.exports = exports.filter((e) => event.exportGuids.includes(e.guid));
        item.setUpdating(SisMediaItem.ExportMappings, false);
      }
    });
    return itemMap;
  }
}
