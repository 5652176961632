import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MasterData } from 'src/app/maps/domain/masterdata.model';
import { DetailView } from 'src/app/maps/widget-list/widget-list-services/domain/detailview.model';
import { Widget } from 'src/app/maps/widget-list/widget-list-services/domain/widget.model';
import { WidgetAction } from 'src/app/maps/widget-list/widget-list-services/domain/widget-action.enum';
import { WidgetName } from 'src/app/maps/widget-list/widget-list-services/domain/widgetname.enum';
import { AbbSmartSensorWidgetComponent } from 'src/app/maps/widgets/abbsmartsensor-widget/abbsmartsensor-widget.component';
import { DestinationAlarmWidgetComponent } from 'src/app/maps/widgets/destination-alarm-widget/destination-alarm-widget.component';
import { AbbSmartSensorDetailViewComponent } from 'src/app/maps/widgets/detailviews/abbsmartsensor-detailview/abbsmartsensor-detailview.component';
import { DestinationAlarmDetailViewComponent } from 'src/app/maps/widgets/detailviews/destination-alarm-detailview/destination-alarm-detailview.component';
import { DevicesOnDetailViewComponent } from 'src/app/maps/widgets/detailviews/devices-on-detailview/devices-on-detailview.component';
import { EnergyDetailViewComponent } from 'src/app/maps/widgets/detailviews/energy-detailview/energy-detailview.component';
import { FirstEntriesDetailViewComponent } from 'src/app/maps/widgets/detailviews/first-entries-detailview/first-entries-detailview.component';
import { GINDetailViewComponent } from 'src/app/maps/widgets/detailviews/gin-detailview/gin-detailview.component';
import { RemoteAccessDetailViewComponent } from 'src/app/maps/widgets/detailviews/remote-access-detailview/remote-access-detailview.component';
import { SambesiWhatsUpDetailViewComponent } from 'src/app/maps/widgets/detailviews/sambesi-whatsup-detailview/sambesi-whatsup-detailview.component';
import { SnowmakingDetailViewComponent } from 'src/app/maps/widgets/detailviews/snowmaking-detailview/snowmaking-detailview.component';
import { DevicesonWidgetComponent } from 'src/app/maps/widgets/deviceson-widget/deviceson-widget.component';
import { EnergyWidgetComponent } from 'src/app/maps/widgets/energy-widget/energy-widget.component';
import { FirstEntriesWidgetComponent } from 'src/app/maps/widgets/first-entries-widget/first-entries-widget.component';
import { GINWidgetComponent } from 'src/app/maps/widgets/gin-widget/gin-widget.component';
import { KassandraWidgetComponent } from 'src/app/maps/widgets/kassandra-widget/kassandra-widget.component';
import { RemoteAccessWidgetComponent } from 'src/app/maps/widgets/remote-access-widget/remote-access-widget.component';
import { SambesiWhatsUpWidgetComponent } from 'src/app/maps/widgets/sambesi-whatsup-widget/sambesi-whatsup-widget.component';
import { SnowmakingWidgetComponent } from 'src/app/maps/widgets/snowmaking-widget/snowmaking-widget.component';
import { WeathertrendWidgetComponent } from 'src/app/maps/widgets/weathertrend-widget/weathertrend-widget.component';

@Injectable({
  providedIn: 'root',
})
export class DestinationWidgetListService {
  constructor(private eventBus: EventBusService) {}

  buildWidgetList(masterdata: MasterData, userFeatures: Feature[]): Observable<Widget[]> {
    if (!userFeatures) {
      return of([]);
    }

    const widgetsToRemove: WidgetName[] = [];
    if (!userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_FIRSTENTRIES)) {
      widgetsToRemove.push(WidgetName.FirstEntries);
    }
    if (!userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_KASSANDRA)) {
      widgetsToRemove.push(WidgetName.Kassandra);
    }
    if (!userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_ENERGY)) {
      widgetsToRemove.push(WidgetName.Energy);
    }
    if (!userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_SNOWMAKING)) {
      widgetsToRemove.push(WidgetName.Snowmaking);
    }
    if (
      !userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_REMOTEACCESS) ||
      !masterdata?.remoteAccesses?.length
    ) {
      widgetsToRemove.push(WidgetName.RemoteAccess);
    }
    if (
      !userFeatures.find((feature) =>
        feature.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_ABBSMARTSENSOR, FeatureAccessLevel.READ))
      )
    ) {
      widgetsToRemove.push(WidgetName.AbbSmartSensor);
    }

    if (!userFeatures.some((f) => f.featureId === FeatureId.COCKPIT_SAMBESI_WHATSUP)) {
      widgetsToRemove.push(WidgetName.SambesiWhatsUp);
    }

    if (!masterdata?.ginConfigurations?.length) {
      widgetsToRemove.push(WidgetName.GIN);
    }

    const widgets = this.getWidgets(this.eventBus);

    return of(widgets.filter((widget) => !widgetsToRemove.includes(widget.name)));
  }

  private getWidgets(eventBus: EventBusService): Widget[] {
    return [
      new Widget({
        name: WidgetName.DevicesOn,
        component: DevicesonWidgetComponent,
        detailView: new DetailView({ component: DevicesOnDetailViewComponent }),
        tooltip: 'ropeway.term.devicesOn',
        eventBus,
      }),
      new Widget({
        name: WidgetName.DestinationAlarm,
        component: DestinationAlarmWidgetComponent,
        detailView: new DetailView({ component: DestinationAlarmDetailViewComponent }),
        tooltip: 'alarming.term.alarms',
        eventBus,
      }),
      new Widget({
        name: WidgetName.FirstEntries,
        component: FirstEntriesWidgetComponent,
        detailView: new DetailView({ component: FirstEntriesDetailViewComponent }),
        tooltip: 'ropeway.term.personFrequency',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Kassandra,
        component: KassandraWidgetComponent,
        detailView: new DetailView({ component: FirstEntriesDetailViewComponent }),
        tooltip: 'Kassandra',
        eventBus,
        action: WidgetAction.ExternalLink,
      }),
      new Widget({
        name: WidgetName.WeatherTrend,
        component: WeathertrendWidgetComponent,
        tooltip: 'ropeway.term.weatherTrend',
        eventBus,
        action: WidgetAction.ExternalLink,
      }),
      new Widget({
        name: WidgetName.Energy,
        component: EnergyWidgetComponent,
        detailView: new DetailView({ component: EnergyDetailViewComponent }),
        tooltip: 'energy.term.power',
        eventBus,
      }),
      new Widget({
        name: WidgetName.Snowmaking,
        component: SnowmakingWidgetComponent,
        detailView: new DetailView({ component: SnowmakingDetailViewComponent }),
        tooltip: 'destination.term.snowmaking',
        eventBus,
      }),
      new Widget({
        name: WidgetName.AbbSmartSensor,
        component: AbbSmartSensorWidgetComponent,
        detailView: new DetailView({ component: AbbSmartSensorDetailViewComponent }),
        tooltip: 'general.term.abbSmartSensor',
        eventBus,
      }),
      new Widget({
        name: WidgetName.SambesiWhatsUp,
        component: SambesiWhatsUpWidgetComponent,
        detailView: new DetailView({ component: SambesiWhatsUpDetailViewComponent }),
        tooltip: 'general.term.sambesiWhatsUp',
        eventBus,
      }),
      new Widget({
        name: WidgetName.RemoteAccess,
        component: RemoteAccessWidgetComponent,
        detailView: new DetailView({ component: RemoteAccessDetailViewComponent }),
        tooltip: 'general.term.remoteaccess',
        eventBus,
      }),
      new Widget({
        name: WidgetName.GIN,
        component: GINWidgetComponent,
        detailView: new DetailView({ component: GINDetailViewComponent }),
        tooltip: 'general.term.gin',
        eventBus,
      }),
      // new Widget({
      //   name: WidgetName.WindTendency,
      //   component: WindtendencyWidgetComponent,
      //   tooltip: 'ropeway.term.windTendency',
      //   eventBus,
      // }),
    ];
  }
}
