<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <path
    transform="translate(1410,-1860) scale(16)"
    d="m -85.23,126.1 c -0.11,-0.1 -0.24,-0.3 -0.24,-0.5 0,-0.1 0.1,-0.2 0.19,-0.3 0.1,-0.1 0.34,-0.5 0.55,-0.7 l 0.37,-0.5 v -0.3 c -0.18,-0.7 -0.11,-1.6 0.21,-2.2 0.27,-0.5 0.88,-1.1 1.38,-1.3 0.74,-0.4 1.74,-0.7 2.7,-0.7 0.47,0 0.47,0 0.61,0.1 0.1,0.1 0.13,0.2 0.1,0.6 0,1 -0.42,2.4 -0.82,3 -0.26,0.4 -0.74,0.7 -1.32,1 -0.66,0.4 -1.19,0.5 -1.83,0.5 h -0.53 l -0.39,0.5 c -0.24,0.3 -0.48,0.6 -0.53,0.7 -0.1,0.1 -0.27,0.1 -0.4,0.1 z m 0.79,-1 c 0.27,-0.4 0.53,-0.7 0.53,-0.7 0,0 0.35,0 0.74,0 0.64,0 0.72,0 1.22,-0.2 1.3,-0.5 1.64,-0.8 2.07,-2.2 0.15,-0.6 0.31,-1.4 0.31,-1.8 v -0.3 l -0.39,0.1 c -0.22,0 -0.64,0 -0.93,0.1 -1.22,0.2 -1.91,0.5 -2.54,1.2 -0.58,0.5 -0.66,0.8 -0.71,1.6 0,1 -0.1,1 0.92,0 0.85,-0.8 1.54,-1.4 2.07,-1.6 0.18,-0.1 0.31,-0.2 0.34,-0.2 0,0 0.13,0.3 0.13,0.3 0,0 -0.18,0.1 -0.37,0.2 -0.53,0.3 -1.27,0.9 -1.96,1.6 -0.84,0.8 -2.09,2.4 -2.09,2.5 0,0.2 0.19,0 0.66,-0.6 z"
    style="opacity: 1; fill-opacity: 1; stroke-width: 0.2646"
    [style.fill]="this.color"
  />
  <g>
    <text
      [attr.x]="textPositionX"
      [attr.y]="textPositionY"
      id="value"
      style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
      [style.font-size]="fontSize"
    >
      {{ this.targetSpeed }}
    </text>
  </g>
</svg>
