import { SisConverter } from 'src/app/core/utils/sis-converter';
import { EnergyLiveData } from 'src/app/maps/livedata/energy-livedata.model';
import { SisOutdatedHelper } from 'src/app/maps/livedata/sis-outdated-helper';

export class EnergyLiveDataAdapter {
  static adapt(item: any): EnergyLiveData[] {
    const livedata: EnergyLiveData[] = [];

    if (item) {
      item.forEach((e: any) => {
        livedata.push({
          deviceSisId: e.DeviceSisId,
          parentSisId: e.ParentSisId,
          timestamp: e.GatewayTimestamp,
          category: e.Category,
          powerW: SisConverter.toNumberOrUndefined(e.PowerW),
          energyWh10Sec: SisConverter.toNumberOrUndefined(e.EnergyWh10Sec),
          outdated: SisOutdatedHelper.isOutdated(e.GatewayTimestamp),
          displayName: e.DisplayName,
        });
      });
    }

    return livedata;
  }
}
