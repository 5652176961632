import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin } from 'rxjs';
import {
  debounceTime,
  defaultIfEmpty,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { LogbookService } from 'src/app/core/components/logbook/domain/logbook.service';
import { LogbookEntry } from 'src/app/core/components/logbook/domain/logbook-entry.model';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';

@Component({
  templateUrl: './logbook-modal.component.html',
  styleUrls: ['./logbook-modal.component.scss'],
})
export class LogbookModalComponent extends Unsubscriber implements OnInit {
  @Input() featureFilters: FeatureId[][] = [];

  bigScreenMode: boolean;
  filtering: boolean;
  filterControl = new FormControl<string>('');
  logbookEntriesFiltered: LogbookEntry[];

  constructor(
    private modalCtrl: ModalController,
    private logbookService: LogbookService,
    private translateService: TranslateService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    combineLatest([
      this.logbookService.logbookEntries$.pipe(
        map((entries) =>
          entries.filter((entry) =>
            this.featureFilters.some((requiredFeatures) =>
              requiredFeatures.every((entryFeature) => entry.featureIds.includes(entryFeature))
            )
          )
        ),
        distinctUntilChanged(this.arraysEqual),
        switchMap((logbookEntries) =>
          this.translateService.onLangChange.pipe(
            startWith({}),
            switchMap(() =>
              forkJoin(
                logbookEntries.map((logbookEntry) => {
                  const translateKey = 'logbook.feature.' + FeatureId[logbookEntry.featureIds[0]].toLowerCase();
                  return this.translateService
                    .get([translateKey, ...logbookEntry.actions, ...logbookEntry.values])
                    .pipe(
                      map((translations) => {
                        logbookEntry.translatedFeature = translations[translateKey];
                        logbookEntry.translatedAction = logbookEntry.actions
                          .map((action) => translations[action])
                          .join('');
                        logbookEntry.translatedValue = logbookEntry.values.map((value) => translations[value]).join('');

                        return logbookEntry;
                      })
                    );
                })
              ).pipe(
                defaultIfEmpty([]),
                map(() => logbookEntries)
              )
            )
          )
        )
      ),
      this.filterControl.valueChanges.pipe(debounceTime(300), startWith<string>('')),
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        map((data) => this.filterLogbookEntries(data[0], data[1])),
        map((data) => data.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())),
        debounceTime(10)
      )
      .subscribe((logbookEntriesFiltered) => {
        this.logbookEntriesFiltered = logbookEntriesFiltered;
      });

    const uniqueFeaturesToRequest = [].concat(...this.featureFilters).filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    this.logbookService.requestLogbookEntries(uniqueFeaturesToRequest);
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }

  itemId(_: number, item: LogbookEntry): string {
    return item.id.toString();
  }

  private filterLogbookEntries(logbookEntries: LogbookEntry[], filter: string): LogbookEntry[] {
    this.filtering = false;
    if (filter == null || filter.length === 0) {
      return logbookEntries;
    }

    filter = filter.trim().toLowerCase();
    return logbookEntries.filter(
      (entry) =>
        entry.email?.toLowerCase().includes(filter) ||
        entry.translatedAction.toLowerCase().includes(filter) ||
        entry.translatedValue.toLowerCase().includes(filter) ||
        entry.translatedFeature.toLowerCase().includes(filter) ||
        entry.formattedTimestamp.includes(filter)
    );
  }

  private arraysEqual(a: LogbookEntry[], b: LogbookEntry[]): boolean {
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
}
