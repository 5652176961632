<!-- BigScreen -->
<ion-row *ngIf="this.stnetDisplayedItem && this.bigScreenMode">
  <ion-col>
    <ion-grid *ngIf="this.formGroup">
      <form [formGroup]="formGroup">
        <!-- snow report -->
        <ion-row class="sis-global-row-50 sis-title-row" style="margin-top: 0px" data-test="snow-report">
          <ion-col size="0.5"><img class="sis-icon" [src]="this.iconPath + 'sis-snow.svg'" /></ion-col>
          <ion-col>{{ 'stnet.term.snowReport' | translate }}</ion-col>
        </ion-row>

        <ion-row class="sis-global-row-50">
          <!-- snow condition -->
          <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.snowType' | translate }}</ion-col>
          <ion-col size="2.5" class="sis-input-col">
            <ion-select
              *ngIf="!this.rerender"
              class="sis-global-input"
              interface="popover"
              [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
              formControlName="snowConditionMainId"
            >
              <ion-select-option *ngFor="let item of this.snowConditions" [value]="item.value">{{
                item.translationString | translate
              }}</ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col size="1"></ion-col>
          <!-- snow condition partial-->
          <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.snowTypePartial' | translate }}</ion-col>
          <ion-col size="2.5" class="sis-input-col">
            <ion-select
              *ngIf="!this.rerender"
              class="sis-global-input"
              interface="popover"
              [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
              formControlName="snowConditionPartialId"
            >
              <ion-select-option *ngFor="let item of this.snowConditions" [value]="item.value">{{
                item.translationString | translate
              }}</ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col></ion-col>
        </ion-row>

        <!-- lifts -->
        <ion-row class="sis-global-row-50 sis-title-row" data-test="lifts" *ngIf="this.sisMediaSetting">
          <ion-col size="0.5"><img class="sis-icon" [src]="this.iconPath + 'sis-pb.svg'" /></ion-col>
          <ion-col>{{ 'general.term.lifts' | translate }}</ion-col>
        </ion-row>

        <ion-row class="sis-global-row-50" *ngIf="this.sisMediaSetting">
          <!-- winter season -->
          <ion-col size="2.5" class="sis-subtitle-text"
            >{{ 'stnet.term.winterSeason' | translate
            }}<ion-icon
              name="information-circle-outline"
              class="sis-info-icon"
              matTooltip="{{ 'stnet.phrase.sismediaSettingInfo' | translate }}"
              matTooltipClass="sis-global-tooltip"
            ></ion-icon
          ></ion-col>
          <ion-col size="auto" style="min-width: 50px">
            <div class="sis-stnet-term">
              {{ 'general.term.fromDate' | translate }}
            </div>
          </ion-col>
          <ion-col size="1" class="sis-input-col">
            <div>
              {{ this.sisMediaSetting.winterSeasonStartDate | dateFormat }}
            </div>
          </ion-col>

          <ion-col size="auto" style="min-width: 50px">
            <div class="sis-stnet-term">
              {{ 'general.term.toDate' | translate }}
            </div>
          </ion-col>
          <ion-col class="sis-input-col">
            <div>
              {{ this.sisMediaSetting.winterSeasonEndDate | dateFormat }}
            </div>
          </ion-col>
        </ion-row>

        <ion-row class="sis-global-row-50" *ngIf="this.sisMediaSetting">
          <!-- operating times -->
          <ion-col size="2.5" class="sis-subtitle-text"
            >{{ 'stnet.term.operatingTimes' | translate
            }}<ion-icon
              name="information-circle-outline"
              class="sis-info-icon"
              matTooltip="{{ 'stnet.phrase.sismediaSettingInfo' | translate }}"
              matTooltipClass="sis-global-tooltip"
            ></ion-icon
          ></ion-col>
          <ion-col size="auto" style="min-width: 50px">
            <div class="sis-stnet-term">
              {{ 'general.term.fromTime' | translate }}
            </div>
          </ion-col>
          <ion-col size="1" class="sis-input-col">
            <div>
              {{ this.sisMediaSetting.liftsOpenFrom }}
            </div>
          </ion-col>
          <ion-col size="auto" style="min-width: 50px">
            <div class="sis-stnet-term">
              {{ 'general.term.toTimeOnly' | translate }}
            </div>
          </ion-col>
          <ion-col class="sis-input-col">
            <div>
              {{ this.sisMediaSetting.liftsOpenTo }}
            </div>
          </ion-col>
        </ion-row>

        <!-- slope -->
        <ng-container *ngIf="this.calculatedData.slopesTotal > 0">
          <ion-row class="sis-global-row-50 sis-title-row" data-test="slopes">
            <ion-col size="0.5"><img class="sis-icon" [src]="this.iconPath + 'sis-piste-schwer.svg'" /></ion-col>
            <ion-col>{{ 'general.term.slopes' | translate }}</ion-col>
          </ion-row>

          <ion-row class="sis-global-row-50">
            <!-- slope condition -->
            <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.slopeCondition' | translate }}</ion-col>
            <ion-col size="2.5" class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="slopeConditionId"
                data-test="slopeConditionId"
              >
                <ion-select-option
                  *ngFor="let item of this.slopeConditions"
                  [value]="item.value"
                  data-test="slopeConditionIdOption"
                  >{{ item.translationString | translate }}</ion-select-option
                >
              </ion-select>
            </ion-col>
            <ion-col size="1"></ion-col>
            <!-- artificially snowed -->
            <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.artificiallySnowed' | translate }}</ion-col>
            <ion-col size="2" class="sis-input-col"
              ><ion-input
                class="sis-global-input sis-global-text-input"
                formControlName="slopesSnowedLengthMeterToday"
                [ngClass]="{ 'sis-global-input-error': this.formControls.slopesSnowedLengthMeterToday?.errors }"
                data-test="stnet-artificially-snowed"
              ></ion-input
            ></ion-col>
            <ion-col size="auto">
              <div class="sis-stnet-term">
                {{ 'stnet.term.meter' | translate }}
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="sis-global-row-50">
            <!-- snow height measurement method -->
            <ion-col size="2.5" class="sis-subtitle-text">{{
              'stnet.term.snowHeightMeasurementMethod' | translate
            }}</ion-col>
            <ion-col size="2.5" class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="snowHeightMeasurementMethodId"
                data-test="snowHeightMeasurementMethodId"
              >
                <ion-select-option
                  *ngFor="let item of this.snowHeightMeasurementMethods"
                  [value]="item.value"
                  data-test="snowHeightMeasurementMethodIdOption"
                  >{{ item.translationString | translate }}</ion-select-option
                >
              </ion-select>
            </ion-col>
          </ion-row>
        </ng-container>

        <!-- valley run -->
        <ng-container *ngIf="this.calculatedData.valleyRunsTotal > 0">
          <ion-row class="sis-global-row-50 sis-title-row" data-test="valley-run">
            <ion-col size="0.5"><img class="sis-icon" [src]="this.iconPath + 'sis-talabfahrt.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.valleyRun' | translate }}</ion-col>
          </ion-row>

          <ion-row class="sis-global-row-50">
            <!-- valley run condition -->
            <ion-col size="2.5" class="sis-subtitle-text">{{
              'stnet.term.valleyRunMainCondition' | translate
            }}</ion-col>
            <ion-col size="2.5" class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="valleyRunMainConditionId"
              >
                <ion-select-option *ngFor="let item of this.valleyRunConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="2.5" class="sis-subtitle-text">{{
              'stnet.term.valleyRunPartialCondition' | translate
            }}</ion-col>
            <ion-col size="2.5" class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="valleyRunPartialConditionId"
              >
                <ion-select-option *ngFor="let item of this.valleyRunConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col></ion-col>
          </ion-row>

          <ion-row class="sis-global-row-50">
            <!-- valley run altitude -->
            <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.valleyRunTo' | translate }}</ion-col>
            <ion-col size="1.5" class="sis-input-col"
              ><ion-input
                class="sis-global-input sis-global-text-input"
                formControlName="valleyRunLowestAltitudeToday"
                [ngClass]="{ 'sis-global-input-error': this.formControls?.valleyRunLowestAltitudeToday?.errors }"
                data-test="valleyRunLowestAltitudeToday"
              ></ion-input
            ></ion-col>
            <ion-col size="1">
              <div class="sis-stnet-term">
                {{ 'general.term.masl' | translate }}
              </div>
            </ion-col>
            <ion-col></ion-col>
          </ion-row>
        </ng-container>

        <!-- cross country -->
        <ng-container
          *ngIf="
            this.calculatedData.classicTracksLengthMeterTotal > 0 ||
            this.calculatedData.skatingTracksLengthMeterTotal > 0
          "
        >
          <ion-row class="sis-global-row-50 sis-title-row" data-test="cross-country">
            <ion-col size="0.5"><img class="sis-icon" [src]="this.iconPath + 'sis-langlauf.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.crossCountry' | translate }}</ion-col>
          </ion-row>

          <ion-row class="sis-global-row-50">
            <!-- cross country classic condition -->
            <ng-container *ngIf="this.calculatedData.classicTracksLengthMeterTotal > 0">
              <ion-col size="2.5" class="sis-subtitle-text" data-test="cross-country-classic">{{
                'stnet.term.crossCountryClassicCondition' | translate
              }}</ion-col>
              <ion-col size="2.5" class="sis-input-col">
                <ion-select
                  *ngIf="!this.rerender"
                  class="sis-global-input"
                  interface="popover"
                  [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                  formControlName="classicTrackConditionId"
                >
                  <ion-select-option *ngFor="let item of this.slopeConditions" [value]="item.value">{{
                    item.translationString | translate
                  }}</ion-select-option>
                </ion-select>
              </ion-col>
              <ion-col size="1"></ion-col>
            </ng-container>

            <!-- cross country skating condition -->
            <ng-container *ngIf="this.calculatedData.skatingTracksLengthMeterTotal > 0">
              <ion-col size="2.5" class="sis-subtitle-text" data-test="cross-country-skating">{{
                'stnet.term.crossCountrySkatingCondition' | translate
              }}</ion-col>
              <ion-col size="2.5" class="sis-input-col">
                <ion-select
                  *ngIf="!this.rerender"
                  class="sis-global-input"
                  interface="popover"
                  [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                  formControlName="skatingTrackConditionId"
                >
                  <ion-select-option *ngFor="let item of this.slopeConditions" [value]="item.value">{{
                    item.translationString | translate
                  }}</ion-select-option>
                </ion-select>
              </ion-col>
            </ng-container>
            <ion-col></ion-col>
          </ion-row>

          <ion-row class="sis-global-row-50">
            <!-- cross country altitude from / to -->
            <ion-col size="2.5" class="sis-subtitle-text">{{
              'stnet.term.altitudeCrossCountryNet' | translate
            }}</ion-col>
            <ion-col size="0.5" style="text-align: end">
              <div class="sis-stnet-term">
                {{ 'general.term.fromDate' | translate }}
              </div>
            </ion-col>
            <ion-col size="2" class="sis-input-col"
              ><ion-input
                inputmode="numeric"
                class="sis-global-input"
                formControlName="crossCountryTracksLowestAltitudeToday"
                [ngClass]="{
                  'sis-global-input-error':
                    this.formControls.crossCountryTracksLowestAltitudeToday?.errors ||
                    this.formGroup.errors?.crossCountryTracksAltitudeToday
                }"
              ></ion-input
            ></ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="0.5" style="text-align: end">
              <div class="sis-stnet-term">
                {{ 'general.term.toDate' | translate }}
              </div>
            </ion-col>
            <ion-col size="2" class="sis-input-col"
              ><ion-input
                class="sis-global-input sis-global-text-input"
                formControlName="crossCountryTracksHighestAltitudeToday"
                [ngClass]="{
                  'sis-global-input-error':
                    this.formControls.crossCountryTracksHighestAltitudeToday?.errors ||
                    this.formGroup.errors?.crossCountryTracksAltitudeToday
                }"
              ></ion-input
            ></ion-col>
            <ion-col size="auto">
              <div class="sis-stnet-term">
                {{ 'general.term.masl' | translate }}
              </div>
            </ion-col>
          </ion-row>
        </ng-container>

        <!-- snow park -->
        <ng-container
          *ngIf="
            this.calculatedData.halfpipesTotal > 0 ||
            this.calculatedData.snowcrossTracksTotal > 0 ||
            this.calculatedData.snowparksTotal > 0 ||
            this.calculatedData.snowtubeTracksTotal > 0 ||
            this.calculatedData.airboardSlopesTotal > 0
          "
        >
          <ion-row class="sis-global-row-50 sis-title-row" data-test="snow-park">
            <ion-col size="0.5"><img class="sis-icon" [src]="this.iconPath + 'sis-snowpark.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.snowpark' | translate }}</ion-col>
          </ion-row>

          <ion-row class="sis-global-row-50">
            <!-- snow park condition -->
            <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.snowparkCondition' | translate }}</ion-col>
            <ion-col size="2.5" class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="snowparkConditionId"
              >
                <ion-select-option *ngFor="let item of this.snowparkConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col></ion-col>
          </ion-row>
        </ng-container>

        <!-- toboggan -->
        <ng-container *ngIf="this.calculatedData.tobogganRunsTotal > 0">
          <ion-row class="sis-global-row-50 sis-title-row" data-test="toboggan">
            <ion-col size="0.5"><img class="sis-icon" [src]="this.iconPath + 'sis-schlitteln.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.tobogganRun' | translate }}</ion-col>
          </ion-row>

          <ion-row class="sis-global-row-50">
            <!-- toboggan condition -->
            <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.tobogganRunCondition' | translate }}</ion-col>
            <ion-col size="2.5" class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="tobogganRunConditionId"
              >
                <ion-select-option *ngFor="let item of this.slopeConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col size="1"></ion-col>
            <!-- toboggan skill -->
            <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.tobogganRunSkillType' | translate }}</ion-col>
            <ion-col size="2.5" class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="tobogganRunSkillTypeId"
              >
                <ion-select-option *ngFor="let item of this.tobogganRunSkills" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col></ion-col>
          </ion-row>
        </ng-container>

        <!-- winter hiking -->
        <ng-container *ngIf="this.calculatedData.winterHikingTrailsLengthMeterTotal > 0">
          <ion-row class="sis-global-row-50 sis-title-row" data-test="winter-hiking">
            <ion-col size="0.5"><img class="sis-icon" [src]="this.iconPath + 'sis-schneeschuh.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.winterHiking' | translate }}</ion-col>
          </ion-row>

          <ion-row class="sis-global-row-50">
            <!-- winter hiking condition -->
            <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.winterHikingCondition' | translate }}</ion-col>
            <ion-col size="2.5" class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="winterHikingTrailConditionId"
              >
                <ion-select-option *ngFor="let item of this.trailConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col size="1"></ion-col>
            <!-- winter hiking from -->
            <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.winterHikingFrom' | translate }}</ion-col>
            <ion-col size="1.5" class="sis-input-col">
              <ion-input
                class="sis-global-input sis-global-text-input"
                formControlName="winterHikingTrailsLowestAltitudeTotal"
                [ngClass]="{
                  'sis-global-input-error': this.formControls.winterHikingTrailsLowestAltitudeTotal?.errors
                }"
              ></ion-input>
            </ion-col>
            <ion-col size="1">
              <div class="sis-stnet-term">
                {{ 'general.term.masl' | translate }}
              </div>
            </ion-col>
          </ion-row>
        </ng-container>

        <!-- night lighting -->
        <ng-container *ngIf="this.nightLightEnabled">
          <ion-row class="sis-global-row-50 sis-title-row" data-test="night-light">
            <ion-col size="0.5"><img class="sis-icon" [src]="this.iconPath + 'sis-flutlicht.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.nightLighting' | translate }}</ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="2.5" class="sis-subtitle-text"></ion-col>
            <ion-col style="padding: 0">
              <ion-grid style="padding: 0">
                <!-- slopes -->
                <ion-row
                  class="sis-global-row-50"
                  *ngIf="this.calculatedData.slopesTotal > 0"
                  data-test="floodlight-slopes"
                >
                  <ion-col size="auto">
                    <ion-checkbox
                      formControlName="slopesFloodlightEnabled"
                      (ionChange)="this.slopesFloodlightEnabled($event.detail.checked)"
                      color="secondary"
                    ></ion-checkbox>
                  </ion-col>
                  <ion-col size="2">
                    <div class="sis-stnet-term">
                      {{ 'general.term.slopes' | translate }}
                    </div>
                  </ion-col>
                  <ion-col size="2" style="text-align: end">
                    <div class="sis-stnet-term">
                      {{ 'general.term.toTimeOnly' | translate }}
                    </div>
                  </ion-col>
                  <ion-col size="2" class="sis-input-col">
                    <ion-input
                      type="time"
                      formControlName="slopesFloodlightUntil"
                      class="sis-global-input"
                      [ngClass]="{ 'sis-global-input-error': this.formControls.slopesFloodlightUntil?.errors }"
                      required
                    ></ion-input>
                  </ion-col>
                  <ion-col size="1"></ion-col>
                  <ion-col size="auto" style="text-align: end">
                    <div class="sis-stnet-term">
                      {{ 'general.term.length' | translate }}
                    </div>
                  </ion-col>
                  <ion-col size="2"
                    ><ion-input
                      class="sis-global-input sis-global-text-input"
                      formControlName="slopesWithFloodlightLengthMeterToday"
                      [ngClass]="{
                        'sis-global-input-error': this.formControls.slopesWithFloodlightLengthMeterToday?.errors
                      }"
                    ></ion-input>
                  </ion-col>
                  <ion-col size="auto">
                    <div class="sis-stnet-term">
                      {{ 'stnet.term.meter' | translate }}
                    </div>
                  </ion-col>
                </ion-row>

                <!-- cross country -->
                <ion-row
                  class="sis-global-row-50"
                  *ngIf="
                    this.calculatedData.classicTracksLengthMeterTotal > 0 ||
                    this.calculatedData.skatingTracksLengthMeterTotal > 0
                  "
                  data-test="floodlight-cross-country"
                >
                  <ion-col size="auto">
                    <ion-checkbox
                      formControlName="crossCountryFloodlightEnabled"
                      (ionChange)="this.crossCountryFloodlightEnabled($event.detail.checked)"
                      color="secondary"
                    ></ion-checkbox>
                  </ion-col>
                  <ion-col size="2">
                    <div class="sis-stnet-term">
                      {{ 'stnet.term.crossCountry' | translate }}
                    </div>
                  </ion-col>
                  <ion-col size="2" style="text-align: end">
                    <div class="sis-stnet-term">
                      {{ 'general.term.toTimeOnly' | translate }}
                    </div>
                  </ion-col>
                  <ion-col size="2" class="sis-input-col"
                    ><ion-input
                      type="time"
                      class="sis-global-input"
                      formControlName="crossCountryFloodlightUntil"
                      [ngClass]="{ 'sis-global-input-error': this.formControls.crossCountryFloodlightUntil?.errors }"
                      required
                    ></ion-input>
                  </ion-col>
                  <ion-col size="1"></ion-col>
                  <ion-col size="auto" style="text-align: end">
                    <div class="sis-stnet-term">
                      {{ 'general.term.length' | translate }}
                    </div>
                  </ion-col>
                  <ion-col size="2"
                    ><ion-input
                      class="sis-global-input sis-global-text-input"
                      formControlName="crossCountryTracksWithFloodlightLengthMeterToday"
                      [ngClass]="{
                        'sis-global-input-error':
                          this.formControls.crossCountryTracksWithFloodlightLengthMeterToday?.errors
                      }"
                    ></ion-input
                  ></ion-col>
                  <ion-col size="0.5">
                    <div class="sis-stnet-term">
                      {{ 'stnet.term.meter' | translate }}
                    </div>
                  </ion-col>
                </ion-row>

                <!-- snow park -->
                <ion-row
                  class="sis-global-row-50"
                  *ngIf="
                    this.calculatedData.halfpipesTotal > 0 ||
                    this.calculatedData.snowcrossTracksTotal > 0 ||
                    this.calculatedData.snowparksTotal > 0 ||
                    this.calculatedData.airboardSlopesTotal > 0 ||
                    this.calculatedData.snowtubeTracksTotal > 0
                  "
                  data-test="floodlight-snowpark"
                >
                  <ion-col size="auto">
                    <ion-checkbox
                      formControlName="snowparkFloodlightEnabled"
                      (ionChange)="this.snowparkFloodlightEnabled($event.detail.checked)"
                      color="secondary"
                    ></ion-checkbox>
                  </ion-col>
                  <ion-col size="2">
                    <div class="sis-stnet-term">
                      {{ 'stnet.term.snowpark' | translate }}
                    </div>
                  </ion-col>
                  <ion-col size="2" style="text-align: end">
                    <div class="sis-stnet-term">
                      {{ 'general.term.toTimeOnly' | translate }}
                    </div>
                  </ion-col>
                  <ion-col size="2" class="sis-input-col"
                    ><ion-input
                      type="time"
                      formControlName="snowparkFloodlightUntil"
                      class="sis-global-input"
                      [ngClass]="{ 'sis-global-input-error': this.formControls.snowparkFloodlightUntil?.errors }"
                      required
                    ></ion-input>
                  </ion-col>
                  <ion-col></ion-col>
                </ion-row>

                <!-- toboggan -->
                <ion-row
                  class="sis-text-row"
                  *ngIf="this.calculatedData.tobogganRunsTotal > 0"
                  data-test="floodlight-boboggan"
                >
                  <ion-col size="auto">
                    <ion-checkbox
                      formControlName="tobogganFloodlightEnabled"
                      (ionChange)="this.tobogganFloodlightEnabled($event.detail.checked)"
                      color="secondary"
                      data-test="tobogganFloodlightEnabled"
                    ></ion-checkbox>
                  </ion-col>
                  <ion-col size="2">
                    <div class="sis-stnet-term">
                      {{ 'stnet.term.tobogganRun' | translate }}
                    </div>
                  </ion-col>
                  <ion-col size="5"></ion-col>
                  <ion-col size="auto" style="text-align: end">
                    <div class="sis-stnet-term">
                      {{ 'general.term.length' | translate }}
                    </div>
                  </ion-col>
                  <ion-col size="2" class="sis-input-col"
                    ><ion-input
                      class="sis-global-input sis-global-text-input"
                      formControlName="tobogganRunsWithFloodlightLengthMeterToday"
                      [ngClass]="{
                        'sis-global-input-error': this.formControls.tobogganRunsWithFloodlightLengthMeterToday?.errors
                      }"
                    ></ion-input>
                  </ion-col>
                  <ion-col size="0.5">
                    <div class="sis-stnet-term">
                      {{ 'stnet.term.meter' | translate }}
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
        </ng-container>
      </form>
    </ion-grid>
  </ion-col>
</ion-row>

<!-- Small Screen -->
<ion-row *ngIf="this.stnetDisplayedItem && !this.bigScreenMode">
  <ion-col>
    <ion-grid *ngIf="this.formGroup">
      <form [formGroup]="formGroup">
        <!-- snow report -->
        <ion-row class="sis-global-row-50 sis-title-row" style="margin-top: 0px">
          <ion-col size="1.5"><img class="sis-icon" [src]="this.iconPath + 'sis-snow.svg'" /></ion-col>
          <ion-col>{{ 'stnet.term.snowReport' | translate }}</ion-col>
        </ion-row>

        <!-- snow condition main -->
        <ion-row class="sis-text-row-small">
          <ion-col size="7" class="sis-subtitle-text">{{ 'stnet.term.snowType' | translate }}</ion-col>
          <ion-col class="sis-input-col">
            <ion-select
              *ngIf="!this.rerender"
              class="sis-global-input"
              interface="popover"
              [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
              formControlName="snowConditionMainId"
            >
              <ion-select-option *ngFor="let item of this.snowConditions" [value]="item.value">{{
                item.translationString | translate
              }}</ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
        <!-- snow condition partial -->
        <ion-row class="sis-text-row-small">
          <ion-col size="7" class="sis-subtitle-text">{{ 'stnet.term.snowTypePartial' | translate }}</ion-col>
          <ion-col class="sis-input-col">
            <ion-select
              *ngIf="!this.rerender"
              class="sis-global-input"
              interface="popover"
              [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
              formControlName="snowConditionPartialId"
            >
              <ion-select-option *ngFor="let item of this.snowConditions" [value]="item.value">{{
                item.translationString | translate
              }}</ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>

        <!-- lifts -->
        <ion-row class="sis-global-row-50 sis-title-row" *ngIf="this.sisMediaSetting">
          <ion-col size="1.5"><img class="sis-icon" [src]="this.iconPath + 'sis-pb.svg'" /></ion-col>
          <ion-col>{{ 'general.term.lifts' | translate }}</ion-col>
        </ion-row>

        <ion-row class="sis-text-row-small" *ngIf="this.sisMediaSetting">
          <ion-col class="sis-subtitle-text" size="7"
            >{{ 'stnet.term.winterSeason' | translate
            }}<ion-icon
              name="information-circle-outline"
              class="sis-info-icon"
              matTooltip="{{ 'stnet.phrase.sismediaSettingInfo' | translate }}"
              matTooltipClass="sis-global-tooltip"
            ></ion-icon
          ></ion-col>
        </ion-row>
        <ion-row class="sis-text-row-small" *ngIf="this.sisMediaSetting">
          <ion-col offset="7" size="auto" style="min-width: 50px">
            {{ 'general.term.fromDate' | translate }}
          </ion-col>
          <ion-col>
            {{ this.sisMediaSetting.winterSeasonFromDate | dateFormat }}
          </ion-col>
        </ion-row>
        <ion-row class="sis-text-row-small" *ngIf="this.sisMediaSetting">
          <ion-col offset="7" size="auto" style="min-width: 50px">
            {{ 'general.term.toDate' | translate }}
          </ion-col>
          <ion-col>
            {{ this.sisMediaSetting.winterSeasonEndDate | dateFormat }}
          </ion-col>
        </ion-row>

        <!-- operating times -->
        <ion-row class="sis-text-row-small" *ngIf="this.sisMediaSetting">
          <ion-col size="7" class="sis-subtitle-text"
            >{{ 'stnet.term.operatingTimes' | translate
            }}<ion-icon
              name="information-circle-outline"
              class="sis-info-icon"
              matTooltip="{{ 'stnet.phrase.sismediaSettingInfo' | translate }}"
              matTooltipClass="sis-global-tooltip"
            ></ion-icon
          ></ion-col>
        </ion-row>
        <ion-row class="sis-text-row-small" *ngIf="this.sisMediaSetting">
          <ion-col offset="7" size="auto" style="min-width: 50px">
            {{ 'general.term.fromTime' | translate }}
          </ion-col>
          <ion-col>
            {{ this.sisMediaSetting.liftsOpenFrom }}
          </ion-col>
        </ion-row>
        <ion-row class="sis-text-row-small" *ngIf="this.sisMediaSetting">
          <ion-col offset="7" size="auto" style="min-width: 50px">
            {{ 'general.term.toTimeOnly' | translate }}
          </ion-col>
          <ion-col>
            {{ this.sisMediaSetting.liftsOpenTo }}
          </ion-col>
        </ion-row>

        <!-- slope -->
        <ion-row class="sis-global-row-50 sis-title-row">
          <ion-col size="1.5"><img class="sis-icon" [src]="this.iconPath + 'sis-piste-schwer.svg'" /></ion-col>
          <ion-col>{{ 'general.term.slopes' | translate }}</ion-col>
        </ion-row>

        <!-- slope condition -->
        <ng-container *ngIf="this.calculatedData.slopesTotal > 0">
          <ion-row class="sis-text-row-small">
            <ion-col size="7" class="sis-subtitle-text">{{ 'stnet.term.slopeCondition' | translate }}</ion-col>
            <ion-col class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="slopeConditionId"
              >
                <ion-select-option *ngFor="let item of this.slopeConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
          <!-- artificially snowed -->
          <ion-row class="sis-text-row-small">
            <ion-col size="7" class="sis-subtitle-text">{{ 'stnet.term.artificiallySnowed' | translate }}</ion-col>
            <ion-col size="3" class="sis-input-col"
              ><ion-input
                class="sis-global-input sis-global-text-input"
                formControlName="slopesSnowedLengthMeterToday"
                [ngClass]="{ 'sis-global-input-error': this.formControls.slopesSnowedLengthMeterToday?.errors }"
              ></ion-input
            ></ion-col>
            <ion-col size="2">
              <div class="sis-stnet-term">
                {{ 'stnet.term.meter' | translate }}
              </div>
            </ion-col>
          </ion-row>
          <!-- snow height measurement method -->
          <ion-row class="sis-text-row-small">
            <ion-col size="7" class="sis-subtitle-text">{{
              'stnet.term.snowHeightMeasurementMethod' | translate
            }}</ion-col>
            <ion-col size="5" class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="snowHeightMeasurementMethodId"
              >
                <ion-select-option *ngFor="let item of this.snowHeightMeasurementMethods" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
        </ng-container>

        <!-- valley run -->
        <ng-container *ngIf="this.calculatedData.valleyRunsTotal > 0">
          <ion-row class="sis-global-row-50 sis-title-row">
            <ion-col size="1.5"><img class="sis-icon" [src]="this.iconPath + 'sis-talabfahrt.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.valleyRun' | translate }}</ion-col>
          </ion-row>

          <!-- valley run condition -->
          <ion-row class="sis-text-row-small">
            <ion-col size="7" class="sis-subtitle-text">{{ 'stnet.term.valleyRunMainCondition' | translate }}</ion-col>
            <ion-col class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="valleyRunMainConditionId"
              >
                <ion-select-option *ngFor="let item of this.valleyRunConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
          <ion-row class="sis-text-row-small">
            <ion-col size="7" class="sis-subtitle-text">{{
              'stnet.term.valleyRunPartialCondition' | translate
            }}</ion-col>
            <ion-col class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="valleyRunPartialConditionId"
              >
                <ion-select-option *ngFor="let item of this.valleyRunConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
          <!-- valley run altitude -->
          <ion-row class="sis-text-row-small">
            <ion-col size="7" class="sis-subtitle-text">{{ 'stnet.term.valleyRunTo' | translate }}</ion-col>
            <ion-col size="3" class="sis-input-col"
              ><ion-input
                class="sis-global-input sis-global-text-input"
                formControlName="valleyRunLowestAltitudeToday"
                [ngClass]="{ 'sis-global-input-error': this.formControls.valleyRunLowestAltitudeToday?.errors }"
              ></ion-input
            ></ion-col>
            <ion-col size="2">
              <div class="sis-stnet-term">
                {{ 'general.term.masl' | translate }}
              </div>
            </ion-col>
          </ion-row>
        </ng-container>

        <!-- cross country -->
        <ng-container
          *ngIf="
            this.calculatedData.classicTracksLengthMeterTotal > 0 ||
            this.calculatedData.skatingTracksLengthMeterTotal > 0
          "
        >
          <ion-row class="sis-global-row-50 sis-title-row">
            <ion-col size="1.5"><img class="sis-icon" [src]="this.iconPath + 'sis-langlauf.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.crossCountry' | translate }}</ion-col>
          </ion-row>

          <!-- cross country classic condition -->
          <ion-row class="sis-text-row-small" *ngIf="this.calculatedData.classicTracksLengthMeterTotal > 0">
            <ion-col size="7" class="sis-subtitle-text">{{
              'stnet.term.crossCountryClassicCondition' | translate
            }}</ion-col>
            <ion-col class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="classicTrackConditionId"
              >
                <ion-select-option *ngFor="let item of this.slopeConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>

          <!-- cross country skating condition -->
          <ion-row class="sis-text-row-small" *ngIf="this.calculatedData.skatingTracksLengthMeterTotal > 0">
            <ion-col size="7" class="sis-subtitle-text">{{
              'stnet.term.crossCountrySkatingCondition' | translate
            }}</ion-col>
            <ion-col class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="skatingTrackConditionId"
              >
                <ion-select-option *ngFor="let item of this.slopeConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
          <!-- cross country altitude -->
          <ion-row class="sis-text-row-small">
            <ion-col class="sis-subtitle-text">{{ 'stnet.term.altitudeCrossCountryNet' | translate }}</ion-col>
          </ion-row>
          <ion-row class="sis-text-row-small">
            <ion-col size="2" style="text-align: end">
              <div class="sis-stnet-term">
                {{ 'general.term.fromDate' | translate }}
              </div>
            </ion-col>
            <ion-col size="3" class="sis-input-col"
              ><ion-input
                class="sis-global-input sis-global-text-input"
                formControlName="crossCountryTracksLowestAltitudeToday"
                [ngClass]="{
                  'sis-global-input-error':
                    this.formControls.crossCountryTracksLowestAltitudeToday?.errors ||
                    this.formGroup.errors?.crossCountryTracksAltitudeToday
                }"
              ></ion-input
            ></ion-col>
            <ion-col size="2" style="text-align: end">
              <div class="sis-stnet-term">
                {{ 'general.term.toDate' | translate }}
              </div>
            </ion-col>
            <ion-col size="3" class="sis-input-col"
              ><ion-input
                class="sis-global-input sis-global-text-input"
                formControlName="crossCountryTracksHighestAltitudeToday"
                [ngClass]="{
                  'sis-global-input-error':
                    this.formControls.crossCountryTracksHighestAltitudeToday?.errors ||
                    this.formGroup.errors?.crossCountryTracksAltitudeToday
                }"
              ></ion-input
            ></ion-col>
            <ion-col size="2">
              <div class="sis-stnet-term">
                {{ 'general.term.masl' | translate }}
              </div>
            </ion-col>
          </ion-row>
        </ng-container>

        <!-- snow park -->
        <ng-container
          *ngIf="
            this.calculatedData.halfpipesTotal > 0 ||
            this.calculatedData.snowcrossTracksTotal > 0 ||
            this.calculatedData.snowparksTotal > 0 ||
            this.calculatedData.snowtubeTracksTotal > 0 ||
            this.calculatedData.airboardSlopesTotal > 0
          "
        >
          <ion-row class="sis-global-row-50 sis-title-row">
            <ion-col size="1.5"><img class="sis-icon" [src]="this.iconPath + 'sis-snowpark.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.snowpark' | translate }}</ion-col>
          </ion-row>

          <!-- snowpark conditon -->
          <ion-row class="sis-text-row-small">
            <ion-col size="7" class="sis-subtitle-text">{{ 'stnet.term.snowparkCondition' | translate }}</ion-col>
            <ion-col class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                class="sis-global-input"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="snowparkConditionId"
              >
                <ion-select-option *ngFor="let item of this.snowparkConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
        </ng-container>

        <!-- toboggan -->
        <ng-container *ngIf="this.calculatedData.tobogganRunsTotal > 0">
          <ion-row class="sis-global-row-50 sis-title-row">
            <ion-col size="1.5"><img class="sis-icon" [src]="this.iconPath + 'sis-schlitteln.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.tobogganRun' | translate }}</ion-col>
          </ion-row>

          <!-- toboggan condition -->
          <ion-row class="sis-text-row-small">
            <ion-col size="7" class="sis-subtitle-text">{{ 'stnet.term.tobogganRunCondition' | translate }}</ion-col>
            <ion-col class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="tobogganRunConditionId"
              >
                <ion-select-option *ngFor="let item of this.slopeConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
          <!-- toboggan skill -->
          <ion-row class="sis-text-row-small">
            <ion-col size="7" class="sis-subtitle-text">{{ 'stnet.term.tobogganRunSkillType' | translate }}</ion-col>
            <ion-col class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="tobogganRunSkillTypeId"
              >
                <ion-select-option *ngFor="let item of this.tobogganRunSkills" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
        </ng-container>

        <!-- winter hiking -->
        <ng-container *ngIf="this.calculatedData.winterHikingTrailsLengthMeterTotal > 0">
          <ion-row class="sis-global-row-50 sis-title-row">
            <ion-col size="1.5"><img class="sis-icon" [src]="this.iconPath + 'sis-schneeschuh.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.winterHiking' | translate }}</ion-col>
          </ion-row>

          <!-- winter hiking condition -->
          <ion-row class="sis-text-row-small">
            <ion-col size="7" class="sis-subtitle-text">{{ 'stnet.term.winterHikingCondition' | translate }}</ion-col>
            <ion-col class="sis-input-col">
              <ion-select
                *ngIf="!this.rerender"
                class="sis-global-input"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                formControlName="winterHikingTrailConditionId"
              >
                <ion-select-option *ngFor="let item of this.trailConditions" [value]="item.value">{{
                  item.translationString | translate
                }}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
          <!-- winter hiking from -->
          <ion-row class="sis-text-row-small">
            <ion-col size="7" class="sis-subtitle-text">{{ 'stnet.term.winterHikingFrom' | translate }}</ion-col>
            <ion-col size="3" class="sis-input-col"
              ><ion-input
                class="sis-global-input sis-global-text-input"
                formControlName="winterHikingTrailsLowestAltitudeTotal"
                [ngClass]="{
                  'sis-global-input-error': this.formControls.winterHikingTrailsLowestAltitudeTotal?.errors
                }"
              ></ion-input>
            </ion-col>
            <ion-col size="2">
              <div class="sis-stnet-term">
                {{ 'general.term.masl' | translate }}
              </div>
            </ion-col>
          </ion-row>
        </ng-container>

        <!-- night lighting -->
        <ng-container *ngIf="this.nightLightEnabled">
          <ion-row class="sis-global-row-50 sis-title-row">
            <ion-col size="1.5"><img class="sis-icon" [src]="this.iconPath + 'sis-flutlicht.svg'" /></ion-col>
            <ion-col>{{ 'stnet.term.nightLighting' | translate }}</ion-col>
          </ion-row>

          <!-- slopes -->
          <ng-container *ngIf="this.calculatedData.slopesTotal > 0">
            <ion-row class="sis-text-row-small">
              <ion-col size="1">
                <ion-checkbox
                  formControlName="slopesFloodlightEnabled"
                  (ionChange)="this.slopesFloodlightEnabled($event.detail.checked)"
                  color="secondary"
                ></ion-checkbox>
              </ion-col>
              <ion-col size="auto">
                <div class="sis-stnet-term">
                  {{ 'general.term.slopes' | translate }}
                </div>
              </ion-col>
            </ion-row>
            <ion-row class="sis-text-row-small">
              <ion-col size="1.5"></ion-col>
              <ion-col size="3" style="text-align: end">
                <div class="sis-stnet-term">
                  {{ 'general.term.toTimeOnly' | translate }}
                </div>
              </ion-col>
              <ion-col size="4" class="sis-input-col"
                ><ion-input
                  type="time"
                  formControlName="slopesFloodlightUntil"
                  class="sis-global-input"
                  [ngClass]="{ 'sis-global-input-error': this.formControls.slopesFloodlightUntil?.errors }"
                  required
                ></ion-input>
              </ion-col>
              <ion-col></ion-col>
            </ion-row>
            <ion-row class="sis-text-row-small">
              <ion-col size="1.5"></ion-col>
              <ion-col size="3" style="text-align: end">
                <div class="sis-stnet-term">
                  {{ 'general.term.length' | translate }}
                </div>
              </ion-col>
              <ion-col size="4" class="sis-input-col"
                ><ion-input
                  class="sis-global-input sis-global-text-input"
                  formControlName="slopesWithFloodlightLengthMeterToday"
                  [ngClass]="{
                    'sis-global-input-error': this.formControls.slopesWithFloodlightLengthMeterToday?.errors
                  }"
                ></ion-input>
              </ion-col>
              <ion-col size="auto">
                <div class="sis-stnet-term">
                  {{ 'stnet.term.meter' | translate }}
                </div>
              </ion-col>
            </ion-row>

            <ion-row class="sis-separator-row-10"></ion-row>
          </ng-container>

          <!-- cross country -->
          <ng-container
            *ngIf="
              this.calculatedData.classicTracksLengthMeterTotal > 0 ||
              this.calculatedData.skatingTracksLengthMeterTotal > 0
            "
          >
            <ion-row class="sis-text-row-small">
              <ion-col size="1">
                <ion-checkbox
                  formControlName="crossCountryFloodlightEnabled"
                  (ionChange)="this.crossCountryFloodlightEnabled($event.detail.checked)"
                  color="secondary"
                ></ion-checkbox>
              </ion-col>
              <ion-col size="auto">
                <div class="sis-stnet-term">
                  {{ 'stnet.term.crossCountry' | translate }}
                </div>
              </ion-col>
            </ion-row>
            <ion-row class="sis-text-row-small">
              <ion-col size="1.5"></ion-col>
              <ion-col size="3" style="text-align: end">
                <div class="sis-stnet-term">
                  {{ 'general.term.toTimeOnly' | translate }}
                </div>
              </ion-col>
              <ion-col size="4" class="sis-input-col"
                ><ion-input
                  type="time"
                  formControlName="crossCountryFloodlightUntil"
                  class="sis-global-input"
                  [ngClass]="{ 'sis-global-input-error': this.formControls.crossCountrylightUntil?.errors }"
                  required
                ></ion-input>
              </ion-col>
              <ion-col></ion-col>
            </ion-row>
            <ion-row class="sis-text-row-small">
              <ion-col size="1.5"></ion-col>
              <ion-col size="3" style="text-align: end">
                <div class="sis-stnet-term">
                  {{ 'general.term.length' | translate }}
                </div>
              </ion-col>
              <ion-col size="4" class="sis-input-col"
                ><ion-input
                  class="sis-global-input sis-global-text-input"
                  formControlName="crossCountryTracksWithFloodlightLengthMeterToday"
                  [ngClass]="{
                    'sis-global-input-error': this.formControls.crossCountryTracksWithFloodlightLengthMeterToday?.errors
                  }"
                ></ion-input>
              </ion-col>
              <ion-col size="auto">
                <div class="sis-stnet-term">
                  {{ 'stnet.term.meter' | translate }}
                </div>
              </ion-col>
            </ion-row>

            <ion-row class="sis-separator-row-10"></ion-row>
          </ng-container>

          <!-- snow park -->
          <ng-container
            *ngIf="
              this.calculatedData.halfpipesTotal > 0 ||
              this.calculatedData.snowcrossTracksTotal > 0 ||
              this.calculatedData.snowparksTotal > 0 ||
              this.calculatedData.snowtubeTracksTotal > 0 ||
              this.calculatedData.airboardSlopesTotal > 0
            "
          >
            <ion-row class="sis-text-row-small">
              <ion-col size="1">
                <ion-checkbox
                  formControlName="snowparkFloodlightEnabled"
                  (ionChange)="this.snowparkFloodlightEnabled($event.detail.checked)"
                  color="secondary"
                ></ion-checkbox>
              </ion-col>
              <ion-col size="auto">{{ 'stnet.term.snowpark' | translate }}</ion-col>
            </ion-row>
            <ion-row class="sis-text-row-small">
              <ion-col size="1.5"></ion-col>
              <ion-col size="3" style="text-align: end">
                <div class="sis-stnet-term">
                  {{ 'general.term.toTimeOnly' | translate }}
                </div>
              </ion-col>
              <ion-col size="4" class="sis-input-col"
                ><ion-input
                  type="time"
                  formControlName="snowparkFloodlightUntil"
                  class="sis-global-input"
                  [ngClass]="{ 'sis-global-input-error': this.formControls.snowparkFloodlightUntil?.errors }"
                  required
                ></ion-input>
              </ion-col>
              <ion-col></ion-col>
            </ion-row>

            <ion-row class="sis-separator-row-10"></ion-row>
          </ng-container>

          <!-- toboggan -->
          <ng-container *ngIf="this.calculatedData.tobogganRunsTotal > 0">
            <ion-row class="sis-text-row-small">
              <ion-col size="1">
                <ion-checkbox
                  formControlName="tobogganFloodlightEnabled"
                  (ionChange)="this.tobogganFloodlightEnabled($event.detail.checked)"
                  color="secondary"
                ></ion-checkbox>
              </ion-col>
              <ion-col size="auto">{{ 'stnet.term.tobogganRun' | translate }}</ion-col>
            </ion-row>
            <ion-row class="sis-text-row-small">
              <ion-col size="1.5"></ion-col>
              <ion-col size="3" style="text-align: end">
                <div class="sis-stnet-term">
                  {{ 'general.term.length' | translate }}
                </div>
              </ion-col>
              <ion-col size="4" class="sis-input-col"
                ><ion-input
                  class="sis-global-input sis-global-text-input"
                  formControlName="tobogganRunsWithFloodlightLengthMeterToday"
                  [ngClass]="{
                    'sis-global-input-error': this.formControls.tobogganRunsWithFloodlightLengthMeterToday?.errors
                  }"
                ></ion-input>
              </ion-col>
              <ion-col size="auto">
                <div class="sis-stnet-term">
                  {{ 'stnet.term.meter' | translate }}
                </div>
              </ion-col>
            </ion-row>
          </ng-container>
        </ng-container>
      </form>
    </ion-grid>
  </ion-col>
</ion-row>
