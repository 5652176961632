import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from 'ckeditor4-angular';
import { CoreModule } from 'src/app/core/core.module';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes.guard';
import { SisRoute } from 'src/app/domain/sis-route.model';
import { CKEditorPage } from 'src/app/media-center/ckeditor/ckeditor.page';
import { ImageBrowseModal } from 'src/app/media-center/image-browse/image-browse.component';
import { ImageContainerComponent } from 'src/app/media-center/image-browse/image-container/image-container.component';
import { MediaCenterPage } from 'src/app/media-center/media-center.page';
import { MediaCenterPlaylistsComponent } from 'src/app/media-center/playlists/media-center-playlists.component';
import { PlaylistItemComponent } from 'src/app/media-center/playlists/playlist-item/playlist-item.component';
import { InfoBannerComponent } from 'src/app/media-center/screens/infobanner-modal/infobanner.component';
import { InfoBannerScreensSelectorComponent } from 'src/app/media-center/screens/infobanner-modal/infobanner-screen-selector-modal/infobanner-screen-selector.component';
import { InfoBannerTextComponent } from 'src/app/media-center/screens/infobanner-modal/infobanner-text-modal/infobanner-text.component';
import { MediaCenterScreensComponent } from 'src/app/media-center/screens/media-center-screens.component';
import { OperatinghourComponent } from 'src/app/media-center/screens/operatinghour-modal/operatinghour.component';
import { MediaCenterScreenGoupComponent } from 'src/app/media-center/screens/screen-group/screen-group.component';
import { ScreeneditorComponent } from 'src/app/media-center/screens/screeneditor-modal/screeneditor.component';
import { MediaCenterSlidesComponent } from 'src/app/media-center/slides/media-center-slides.component';
import { TimeControlModalComponent } from 'src/app/media-center/slides/time-control-modal/time-control-modal.component';
import { VideoBrowseModal } from 'src/app/media-center/video-browse/video-browse.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CKEditorModule,
    TranslateModule,
    MatTooltipModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    ScrollingModule,
    HttpClientModule,
    AngularEditorModule,
    RouterModule.forChild([
      {
        path: SisRoute.EDITOR,
        component: CKEditorPage,
      },
      {
        path: '',
        component: MediaCenterPage,
        canDeactivate: [PendingChangesGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'screens',
          },
          {
            path: 'screens',
            component: MediaCenterScreensComponent,
          },
          {
            path: 'playlists',
            component: MediaCenterPlaylistsComponent,
          },
          {
            path: 'slides',
            component: MediaCenterSlidesComponent,
          },
        ],
      },
    ]),
    CoreModule,
  ],
  declarations: [
    MediaCenterPage,
    MediaCenterSlidesComponent,
    CKEditorPage,
    ImageBrowseModal,
    VideoBrowseModal,
    MediaCenterScreensComponent,
    MediaCenterPlaylistsComponent,
    ScreeneditorComponent,
    PlaylistItemComponent,
    TimeControlModalComponent,
    ImageContainerComponent,
    InfoBannerComponent,
    InfoBannerTextComponent,
    OperatinghourComponent,
    MediaCenterScreenGoupComponent,
    InfoBannerScreensSelectorComponent,
  ],
})
export class MediaCenterPageModule {}
