import { TemperatureTrend } from 'src/app/maps/trenddata/temperature-trend.model';

export class TemperatureTrendAdapter {
  static adapt(items: any): TemperatureTrend[] {
    const trends: TemperatureTrend[] = [];

    if (items && items.length > 0) {
      let lastTimestampMs = new Date(items[0].timestamp).getTime();
      items.forEach((e: any) => {
        const timestampMs = new Date(e.timestamp).getTime();
        const timestampMsMaxExpectedValue = lastTimestampMs + 600_000;
        if (timestampMs > timestampMsMaxExpectedValue) {
          trends.push({ timestamp: new Date(timestampMsMaxExpectedValue) });
        }
        lastTimestampMs = timestampMs;

        trends.push({
          temperatureCelsiusAverage: e.temperatureCelsiusAverage != null ? Number(e.temperatureCelsiusAverage) : null,
          timestamp: new Date(e.timestamp),
        });
      });
    }

    return trends;
  }
}
