import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, merge, Observable, shareReplay, switchMap, withLatestFrom } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { SambesiWhatsUpMessagesUpdatedEvent } from 'src/app/core/eventbus/events';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { SambesiWhatsUpMessageAdapter } from 'src/app/maps/livedata/sambesi-whatsup-message.adapter';
import { SambesiWhatsUpMessage } from 'src/app/maps/livedata/sambesi-whatsup-message.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SambesiWhatsUpService extends LivedataServiceBase<SambesiWhatsUpMessage> {
  private readonly baseEndpointUrl = 'sambesi/whatsup';
  protected readonly requiredFeatures: Feature[] = [
    new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ),
    new Feature(FeatureId.COCKPIT_SAMBESI_WHATSUP, FeatureAccessLevel.READ),
  ];
  protected readonly endpointUri = `${this.baseEndpointUrl}/messages`;

  private readonly messagesLive$ = this.liveData$.pipe(
    map((messages) => {
      messages.sort(
        (m1, m2) => -(m1.date.getTime() - m2.date.getTime() || m1.ropewaySisId.localeCompare(m2.ropewaySisId))
      );
      return messages;
    })
  );

  private readonly messagesUpdated$ = this.eventBus.observe(SambesiWhatsUpMessagesUpdatedEvent).pipe(
    withLatestFrom(this.destinationService.selectedTenant$, this.userSettingsService.userSettings$),
    switchMap(([event, tenant, userSettings]) => {
      if (event.updateSuccessful) {
        if (event.changedBy === userSettings.userGuid) {
          const translateKey = 'sambesi.message.success';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.success,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.green,
          });

          this.userMessageService.presentToast(userMessage);
        }
        if (event.tenantGuid === tenant.guid) {
          this.forceLiveDataUpdate();
        }
      } else if (event.changedBy === userSettings.userGuid) {
        const translateKey = 'sambesi.message.failed';
        const userMessage = new UserMessage({
          message: translateKey,
          icon: UserMessageIcon.failed,
          durationMs: 2000,
          position: 'top',
          color: UserMessageColor.red,
        });

        this.userMessageService.presentToast(userMessage);
      }

      return this.messagesLive$;
    })
  );

  readonly messages$ = merge(this.messagesLive$, this.messagesUpdated$).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly citrixLink$ = this.destinationService.selectedTenant$.pipe(
    switchMap((tenant) =>
      this.httpClient
        .get(`${environment.baseUrlApi}/${this.baseEndpointUrl}/citrix/${tenant.guid}`, { responseType: 'json' })
        .pipe(
          map((data) => {
            if (data == null) {
              return null;
            }
            if (typeof data !== 'string') {
              throw new Error('Expected citrix link to be string but was not');
            }
            return data;
          })
        )
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  constructor(
    private httpClient: HttpClient,
    destinationService: DestinationService,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService,
    private userMessageService: UserMessageService
  ) {
    super(httpClient, SambesiWhatsUpMessageAdapter.adapt, destinationService);
    this.setInterval(3600000);
  }

  updateSambesiMessages(tenantGuid: string, ropewayGuid: string | null): Observable<void> {
    return this.httpClient
      .post(`${environment.baseUrlApi}/${this.baseEndpointUrl}/update`, { tenantGuid, ropewayGuid })
      .pipe(map(() => {}));
  }
}
