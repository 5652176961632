import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { WidgetSelectedEvent } from 'src/app/core/eventbus/events';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { DetailView } from 'src/app/maps/widget-list/widget-list-services/domain/detailview.model';
import { WidgetAction } from 'src/app/maps/widget-list/widget-list-services/domain/widget-action.enum';
import { v4 as v4guid } from 'uuid';

export class Widget {
  expandable: boolean;
  css: string;
  name: any;
  expanded: boolean = false;

  component: any;
  detailView: DetailView;
  tooltip: any;
  eventBus: EventBusService;
  action: WidgetAction = WidgetAction.None;

  constructor(init?: Partial<WritablePart<Widget>>) {
    Object.assign(this, init);
    this.expandable = this.detailView != null;
    this.name = this.name ?? v4guid();
    if (this.expandable) {
      this.action = WidgetAction.Expandable;
    }
  }

  toggleExpanded(): void {
    this.expanded = this.expandable && !this.expanded;
    if (this.expanded) {
      this.publishExpandedEvent(true);
    }
  }

  expand(): void {
    this.expanded = this.expandable;
    if (this.expanded) {
      this.publishExpandedEvent(true);
    }
  }

  close(): void {
    this.expanded = false;
  }

  private publishExpandedEvent(expanded: boolean) {
    const event = new WidgetSelectedEvent();
    event.expandable = expanded;
    this.eventBus.publish(event);
  }
}
