<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widget_Hintergrund_temp" data-name="Widget Hintergrund temp">
      <rect width="187.5" height="225" style="fill: none" />
    </g>
    <g id="Widgets">
      <g>
        <rect width="187.5" height="225" style="fill: none" />
        <g>
          <g>
            <rect y="0" x="0" id="rect867" style="fill: none" height="225" width="187.5" />
            <path
              style="fill: #91cddb"
              d="M 91.779297 45.029297 A 67.5 67.5 0 0 0 26.25 112.5 L 56.371094 112.5 A 37.5 37.5 0 0 1 56.369141 112.43945 A 37.5 37.5 0 0 1 93.869141 74.939453 A 37.5 37.5 0 0 1 131.36914 112.43945 A 37.5 37.5 0 0 1 131.36523 112.5 L 161.25 112.5 A 67.5 67.5 0 0 0 91.779297 45.029297 z "
            />
            <path
              style="fill: #bccbd4"
              d="M 41.150391 70.199219 A 67.19 67.19 0 0 0 26.25 112.5 L 56.371094 112.5 A 37.5 37.5 0 0 1 56.369141 112.43945 A 37.5 37.5 0 0 1 64.597656 89.056641 L 41.150391 70.199219 z "
            />
            <path
              style="fill: #989898"
              d="M 146.35938 70.199219 L 123.06641 88.927734 A 37.5 37.5 0 0 1 131.36914 112.43945 A 37.5 37.5 0 0 1 131.36523 112.5 L 161.25 112.5 A 67.18 67.18 0 0 0 146.35938 70.199219 z "
            />
            <path
              id="Zeiger"
              [attr.transform]="'rotate(' + rotationDeg + ', 93.75, 112.5)'"
              [attr.opacity]="this.showRotation ? '1' : '0'"
              style="
                fill: #000000;
                fill-opacity: 0.99356914;
                stroke-width: 7.65733099;
                stroke-miterlimit: 4;
                stroke-dasharray: none;
              "
              d="M 9.390625 110.69922 L 9.390625 114.29688 L 56.435547 114.29688 A 37.5 37.5 0 0 1 56.369141 112.43945 A 37.5 37.5 0 0 1 56.457031 110.69922 L 9.390625 110.69922 z "
              transform="rotate( 0 , 93.75, 112.5)"
            />
          </g>
          <g>
            <rect
              x="70.81"
              y="114.45"
              width="46.01"
              height="33.6"
              rx="3"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 2px"
            />
            <line
              x1="78.09"
              y1="114.45"
              x2="78.09"
              y2="148.05"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 2px"
            />
            <line
              x1="109.54"
              y1="114.45"
              x2="109.54"
              y2="148.05"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 2px"
            />
            <rect
              x="64.4"
              y="128.04"
              width="6.42"
              height="6.42"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 2px"
            />
            <line
              x1="80.52"
              y1="131.25"
              x2="107.12"
              y2="131.25"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 2px"
            />
            <line
              x1="80.52"
              y1="138.53"
              x2="107.12"
              y2="138.53"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 2px"
            />
            <line
              x1="80.52"
              y1="143.38"
              x2="107.12"
              y2="143.38"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 2px"
            />
            <line
              x1="80.52"
              y1="123.97"
              x2="107.12"
              y2="123.97"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 2px"
            />
            <line
              x1="80.52"
              y1="119.12"
              x2="107.12"
              y2="119.12"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 2px"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Widgettexte">
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
      >
        {{ displayedValue }}
        <tspan id="unit" style="font-size: 24px">{{ displayedUnit }}</tspan>
      </text>
    </g>
  </g>
</svg>
