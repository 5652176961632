import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { GlobalAlarmAdapter } from 'src/app/geomap/livedata/globalalarm.adapter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalAlarmService {
  private readonly url = environment.baseUrlApi + '/globalalarm';
  private readonly intervalMs = 10000;

  readonly alarms$ = timer(0, this.intervalMs).pipe(
    switchMap(() => this.http.get(this.url).pipe(map((data: any) => GlobalAlarmAdapter.adapt(data)))),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(private http: HttpClient) {}
}
