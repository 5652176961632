import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { GeoMapPage } from 'src/app/geomap/geomap.page';
import { GeoMapComponent } from 'src/app/geomap/map/geomap.component';
import { TenantLayerComponent } from 'src/app/geomap/map/tenant-layer/tenant-layer.component';
import { TenantMarkerComponent } from 'src/app/geomap/map/tenant-layer/tenant-marker/tenant-marker.component';

const routes: Routes = [
  {
    path: '',
    component: GeoMapPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    LeafletModule,
    MatTooltipModule,
    CoreModule,
    TranslateModule,
  ],
  declarations: [GeoMapPage, GeoMapComponent, TenantLayerComponent, TenantMarkerComponent],
})
export class GeoMapPageModule {}
