<ion-select
  class="sis-global-input sis-access-level-selector"
  [ngClass]="accessLevelClass"
  [ngModel]="selectedAccessLevel"
  interface="popover"
  [interfaceOptions]="{ cssClass: 'sis-global-popover' }"
  [interfaceOptions]="popoverOptions"
  (ngModelChange)="changeAccessLevel($event)"
  [selectedText]="label"
  [matTooltip]="translatedFeatureName"
  [disabled]="disabled"
  data-test="user-management-access-level-select"
>
  <ion-select-option *ngFor="let item of this.accessLevels | keyvalue" [value]="item.key">{{
    item.value.translateString | translate
  }}</ion-select-option>
</ion-select>
