import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { Feature } from 'src/app/domain/feature/feature.model';
import { Tenant } from 'src/app/domain/tenant/tenant.model';

@Injectable({
  providedIn: 'root',
})
export class DestinationService {
  private readonly selectedTenant = new BehaviorSubject<Tenant>(undefined);

  readonly selectedTenant$ = this.selectedTenant.pipe(distinctUntilChanged());

  readonly selectedTenantFeatures$: Observable<Feature[]> = this.selectedTenant$.pipe(
    filter((tenant) => !!tenant),
    switchMap((tenant) => tenant.features$.asObservable())
  );

  changeDestination(to: Tenant): void {
    this.selectedTenant.next(to);
  }
}
