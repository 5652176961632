import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export class Unsubscriber implements OnDestroy {
  private readonly _onDestroy$ = new Subject<void>();

  readonly onDestroy$ = this._onDestroy$.asObservable();

  ngOnDestroy() {
    // Unsubscribe from whatever used takeUntil(this.onDestroy$)
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
