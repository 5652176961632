import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { filter, firstValueFrom, map, takeUntil } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { RopewaySettingsUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewaySettingsModal } from 'src/app/maps/widgets/detailviews/ropeway-settings-detailview/modal/ropeway-settings-modal.component';

@Component({
  selector: 'sis-ropeway-settings-detailview',
  templateUrl: './ropeway-settings-detailview.component.html',
  styleUrls: ['./ropeway-settings-detailview.component.scss'],
})
export class RopewaySettingsDetailViewComponent extends Unsubscriber implements OnInit {
  private readonly requiredFeatureWrite = new Feature(FeatureId.COCKPIT_ROPEWAY_SETTINGS, FeatureAccessLevel.WRITE);
  readonly hasWritePermission$ = this.destinationService.selectedTenantFeatures$.pipe(
    map((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeatureWrite)))
  );
  readonly hasAvailabilityFeatureReadPermission$ = this.destinationService.selectedTenantFeatures$.pipe(
    map((features) => features.some((f) => f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_ROPEWAY_AVAILABILITY, FeatureAccessLevel.READ))))
  );

  ropeway: Ropeway;
  operatingTimeFromFormatted: string;
  operatingTimeUntilFormatted: string;

  constructor(
    private destinationService: DestinationService,
    private selectedMapElementService: SelectedMapElementService,
    private modalCtrl: ModalController,
    private eventBus: EventBusService
  ) {
    super();
  }
  ngOnInit(): void {
    this.selectedMapElementService.selectedRopeway$
      .pipe(
        filter((ropeway) => !!ropeway),
        takeUntil(this.onDestroy$)
      )
      .subscribe((ropeway) => {
        this.ropeway = ropeway;
        this.operatingTimeFromFormatted = this.formatTime(this.ropeway.operatingTimeFrom);
        this.operatingTimeUntilFormatted = this.formatTime(this.ropeway.operatingTimeUntil);
      });

    this.eventBus
      .observe(RopewaySettingsUpdatedEvent)
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event) => event.ropewaySettings.guid == this.ropeway.guid)
      )
      .subscribe((event) => {
        Object.assign(this.ropeway, event.ropewaySettings);
        this.operatingTimeFromFormatted = this.formatTime(this.ropeway.operatingTimeFrom);
        this.operatingTimeUntilFormatted = this.formatTime(this.ropeway.operatingTimeUntil);
      });
  }

  async openSettingsModal(): Promise<void> {
    const hasWritePermission = await firstValueFrom(this.hasWritePermission$);

    if (hasWritePermission) {
      const modal = await this.modalCtrl.create({
        component: RopewaySettingsModal,
        componentProps: {
          ropeway: this.ropeway,
          tenantGuid: (await firstValueFrom(this.destinationService.selectedTenant$)).guid,
        },
        cssClass: 'sis-ropeway-settings-modal',
      });
      await modal.present();
    }
  }

  private formatTime(time: string): string {
    if (!time || time === '') {
      return '';
    }

    const timeParts = time.split(':', 2);

    if (timeParts.length != 2) {
      return '';
    }

    return `${timeParts[0]}:${timeParts[1]}`;
  }
}
