import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MediaCenterService } from 'src/app/media-center/media-center.service';
import { Playlist } from 'src/app/media-center/playlists/domain/playlist.model';
import { SlideMapping } from 'src/app/media-center/playlists/domain/slide-mapping.model';
import { MediaCenterPlaylistsService } from 'src/app/media-center/playlists/media-center-playlists.service';
import { Slide } from 'src/app/media-center/slides/domain/slide.model';
import { MediaCenterSlidesService } from 'src/app/media-center/slides/media-center-slides.service';

@Component({
  selector: 'sis-media-center-playlist-item',
  templateUrl: './playlist-item.component.html',
  styleUrls: ['./playlist-item.component.scss', '../../media-center-global.scss'],
})
export class PlaylistItemComponent extends Unsubscriber implements OnInit, OnChanges, OnDestroy {
  @ViewChild('playlistScrollElement') playlistScrollElement: ElementRef;

  @Input() playlist: Playlist;
  @Input() expanded: boolean;
  @Input() otherExpanded: boolean;

  @Output() toggleExpanded = new EventEmitter();
  @Output() edited = new EventEmitter(true);
  @Output() removeNewPlaylist = new EventEmitter(true);

  searching: boolean = false;
  searchTerm: string;
  writePermission: boolean;
  readPermission: boolean;
  searchControl = new FormControl<string>('');
  slidesFiltered: Slide[];
  playlistRowClass = 'sis-playlist-row';
  playlistEditCopy: Playlist;
  editPlaylistNameDisabled: boolean = true;
  bigScreenMode: boolean;
  invalidName: boolean;
  inputSelected: boolean;

  daysArray: string[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  private slides: Slide[];
  private playlists: Playlist[];

  constructor(
    private destinationService: DestinationService,
    private slidesService: MediaCenterSlidesService,
    private playlistService: MediaCenterPlaylistsService,
    private mediaCenterService: MediaCenterService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initialiseEditData();

    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => {
      this.bigScreenMode = bigScreenMode;
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.WRITE))
      );
      this.readPermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.READ))
      );
    });

    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroy$)).subscribe((search: string) => {
      this.searching = false;
      this.searchTerm = search ? search.toLowerCase() : undefined;
      this.filterSlides();
    });

    this.slidesService.slides$.pipe(takeUntil(this.onDestroy$)).subscribe((slides) => {
      this.slides = slides.sort((a, b) => a.name?.localeCompare(b.name));
      this.filterSlides();
    });

    this.playlistService.ownUpdateCompleted.pipe(takeUntil(this.onDestroy$)).subscribe(([playlist, success]) => {
      if (success && playlist.guid === this.playlist.guid) {
        this.initialiseEditData();
      }
    });

    this.playlistService.playlists$.pipe(takeUntil(this.onDestroy$)).subscribe((playlists) => {
      this.playlists = playlists;
      this.checkPlaylistName(this.playlistEditCopy.name);

      if (!this.playlist.edited) {
        this.initialiseEditData();
      }
    });
  }

  ngOnChanges(): void {
    this.playlistRowClass = this.expanded
      ? 'sis-playlist-highlight-background sis-playlist-row-expanded'
      : 'sis-playlist-row';
  }

  ngOnDestroy(): void {
    this.cancel();
  }

  doReorder(ev: any) {
    this.setEdited(true);
    this.playlistEditCopy.slideMappings = ev.detail.complete(this.playlistEditCopy.slideMappings);
    this.playlistEditCopy.slideMappings.forEach((slideMapping, index) => (slideMapping.order = index));
  }

  removeSlideFromPlaylist(slideMapping: SlideMapping): void {
    this.setEdited(true);
    this.playlistEditCopy.slideMappings = this.playlistEditCopy.slideMappings.filter(
      (mapping) => mapping !== slideMapping
    );
  }

  editPlaylistName(event: any): void {
    if (this.expanded) {
      event.stopPropagation();
    }
  }

  playlistNameChanged(name: string): void {
    this.checkPlaylistName(name);
    this.setEdited(true);
  }

  addSlideToPlaylist(slide: Slide): void {
    this.setEdited(true);
    let order = 0;

    if (this.playlistEditCopy.slideMappings?.length > 0) {
      const highestOrder = this.playlistEditCopy.slideMappings.reduce((prev, curr) =>
        prev?.order > curr.order ? prev : curr
      ).order;
      order = highestOrder + 1;
    }
    const slideMappingToAdd: SlideMapping = { slideGuid: slide.guid, duration: slide.duration, order };
    slideMappingToAdd.slide = slide;
    this.playlistEditCopy.slideMappings.push(slideMappingToAdd);

    setTimeout(() => {
      this.scrollPlaylistToBottom();
    });
  }

  durationInput(event: any, slideMapping: SlideMapping): void {
    this.setEdited(true);
    const duration = Number.parseInt(event.target.value, 10);

    if (
      this.writePermission &&
      duration &&
      !Number.isNaN(duration) &&
      duration >= 0 &&
      duration !== slideMapping.duration
    ) {
      slideMapping.duration = duration;
    }
  }

  savePlaylist(): void {
    if (this.writePermission && !this.invalidName && this.playlist.edited) {
      this.playlistService.updatePlaylist(this.playlistEditCopy);
    }
  }

  async deletePlaylist(event: any, playlist: Playlist): Promise<void> {
    event.stopPropagation();
    if (this.writePermission && !this.playlist.isNew) {
      await this.playlistService.deletePlaylist(playlist);
    }
  }

  cancel(): void {
    if (this.playlist.isNew) {
      this.removeNewPlaylist.emit();
    } else {
      this.initialiseEditData();
      this.checkPlaylistName(this.playlistEditCopy.name);
    }
  }

  openSlideShowPreview(): void {
    this.mediaCenterService.openSlideShowPlaylistPreview(this.playlist.name);
  }

  itemId(_: number, slide: Slide): string {
    return slide.url;
  }

  stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  private checkPlaylistName(name: string): void {
    this.invalidName =
      !name || name.length === 0 || this.playlists.some((p) => p.name === name && p.guid !== this.playlist.guid);
  }

  private initialiseEditData(): void {
    this.playlistEditCopy = new Playlist(
      this.playlist.guid,
      this.playlist.name,
      this.playlist.fadeInEnabled,
      this.playlist.backgroundColor
    );
    this.playlistEditCopy.assignedToScreen = this.playlist.assignedToScreen;
    this.playlistEditCopy.slideMappings = this.playlist.slideMappings
      .filter((mapping) => !!mapping.slide)
      .map((mapping) => ({
        slideGuid: mapping.slideGuid,
        duration: mapping.duration,
        order: mapping.order,
        slide: mapping.slide,
      }));

    this.setEdited(this.playlist.isNew);
  }

  private filterSlides(): void {
    let filteredSlides = this.slides;

    if (this.searchTerm) {
      filteredSlides = this.slides.filter((d) => d.name.toLowerCase().includes(this.searchTerm));
    }

    this.slidesFiltered = filteredSlides;
  }

  private scrollPlaylistToBottom(): void {
    this.playlistScrollElement.nativeElement.scrollTop = this.playlistScrollElement.nativeElement.scrollHeight;
  }

  private setEdited(edited: boolean): void {
    this.playlist.edited = edited;
    this.edited.emit();
  }
}
