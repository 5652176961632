import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { IonSearchbar, MenuController, ModalController } from '@ionic/angular';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { TenantService } from 'src/app/domain/tenant/tenant.service';

@Component({
  selector: 'sis-select-destination',
  templateUrl: 'select-destination.component.html',
  styleUrls: ['select-destination.component.scss'],
})
export class SelectDestinationModal extends Unsubscriber implements OnInit {
  tenants: Tenant[];
  filteredTenants: Tenant[];
  searchControl: FormControl<string>;

  searching = false;
  selectedTenantGuid: string;
  selectedIndex: number | null = 0;

  @ViewChild('searchBar', { static: false }) searchBar: IonSearchbar;

  constructor(
    private modalCtrl: ModalController,
    private tenantdataService: TenantService,
    private destinationService: DestinationService,
    private menuCtrl: MenuController,
    private router: Router
  ) {
    super();

    this.tenantdataService.tenants$.pipe(take(1)).subscribe((tenants) => this.updateTenants(tenants));

    this.destinationService.selectedTenant$.pipe(takeUntil(this.onDestroy$)).subscribe((tenant) => {
      this.selectedTenantGuid = tenant ? tenant.guid : null;
    });

    this.searchControl = new FormControl<string>('');
  }

  ngOnInit() {
    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroy$)).subscribe((search: string) => {
      this.searching = false;
      this.filterTenantList(search);
    });

    setTimeout(() => this.searchBar.setFocus(), 400);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowDown':
      case 'Tab':
        this.selectedIndex =
          this.selectedIndex === null || this.selectedIndex >= this.filteredTenants.length - 1
            ? 0
            : this.selectedIndex + 1;
        event.preventDefault();
        break;
      case 'ArrowUp':
        this.selectedIndex =
          this.selectedIndex === null || this.selectedIndex <= 0
            ? this.filteredTenants.length - 1
            : this.selectedIndex - 1;
        event.preventDefault();
        break;
      case 'Enter':
        if (this.selectedIndex !== null) {
          this.selectTenant();
        }
        event.preventDefault();
        break;
    }
  }

  handleMouseEnter(index: number) {
    this.selectedIndex = index;
  }

  handleMouseLeave() {
    this.selectedIndex = null;
  }

  handleMouseClick() {
    if (this.selectedIndex !== null) {
      this.selectTenant();
    }
  }

  clearSelection() {
    this.selectedIndex = 0;
  }

  selectTenant() {
    if (this.selectedIndex !== null && this.filteredTenants.length > 0) {
      const selectedTenant = this.filteredTenants[this.selectedIndex];
      const route = this.router.url.split('/');
      const spliceCount = route.length > 2 ? 2 : 1;
      route.splice(0, spliceCount);
      route.unshift(selectedTenant.sisId);
      this.router.navigate(route);

      this.closeModal();
    }
  }

  updateTenantList(ev: any) {
    const val: string = ev.target.value;
    this.filterTenantList(val);
  }

  closeModal() {
    this.modalCtrl.dismiss();
    this.menuCtrl.close('mainMenu');
  }

  private filterTenantList(searchTerm: string): void {
    this.filteredTenants = this.tenants.filter((d) => d.alias.toLowerCase().includes(searchTerm.toLowerCase()));
    this.clearSelection();
  }

  private updateTenants(tenants: Tenant[]): void {
    this.tenants = tenants;
    this.filteredTenants = this.tenants.sort((a, b) => (a.alias > b.alias ? 1 : -1));
  }
}
