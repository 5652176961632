import { FeatureAdapter } from 'src/app/domain/feature/feature.adapter';
import { User } from 'src/app/user-management/domain/user.model';

export class UserAdapter {
  static adapt(items: any): User[] {
    const users: User[] = [];
    if (!items) {
      return [];
    }

    items.forEach((e: any) => {
      users.push({
        userGuid: e.userGuid,
        email: e.email,
        displayName: e.displayName,
        invitationAccepted: e.invitationAccepted,
        isSisag: e.isSisag,
        features: FeatureAdapter.adapt(e.features).sort((a, b) => a.featureId - b.featureId),
        tenantGuids: e.tenantGuids,
      });
    });

    return users;
  }
}
