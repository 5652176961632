<ion-header>
  <ion-toolbar>
    <ion-title class="sis-season-editor-title">
      {{ 'timetable.phrase.editSeason' | translate }}
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="close()" data-test="timetable-close-modal-button"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="sis-modal-grid">
    <form [formGroup]="this.formGroup">
      <ion-row class="sis-global-row-50">
        <ion-col size="5.5">
          <ion-label class="sis-season-editor-label" fixed>{{ 'timetable.term.timetableName' | translate }}</ion-label>
        </ion-col>
        <ion-col size="6.5">
          <ion-input
            type="text"
            class="sis-modal-input sis-modal-text-input"
            formControlName="name"
            maxlength="100"
            data-test="timetable-seasonName-input"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls.name.errors }"
            matTooltip="{{ this.formGroup.controls.name.errors?.nameInvalid | translate }}"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="sis-global-row-50">
        <ion-col size="4">
          <ion-label class="sis-season-editor-label" fixed>{{ 'timetable.term.validity' | translate }}</ion-label>
        </ion-col>
        <ion-col size="1.5">
          <ion-label class="sis-season-editor-label" fixed>{{ 'timetable.term.validFrom' | translate }}</ion-label>
        </ion-col>
        <ion-col size="auto">
          <ion-input
            formControlName="validFrom"
            type="date"
            class="sis-modal-input"
            data-test="timetable-validFrom-input"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.errors?.dateTimesInvalid }"
            matTooltip="{{ this.formGroup.errors?.dateTimesInvalid | translate }}"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row class="sis-global-row-50">
        <ion-col size="4"> </ion-col>
        <ion-col size="1.5">
          <ion-label class="sis-season-editor-label" fixed>{{ 'timetable.term.validUntil' | translate }}</ion-label>
        </ion-col>
        <ion-col size="auto">
          <ion-input
            formControlName="validUntil"
            type="date"
            class="sis-modal-input"
            data-test="timetable-validUntil-input"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.errors?.dateTimesInvalid }"
            matTooltip="{{ this.formGroup.errors?.dateTimesInvalid | translate }}"
          ></ion-input>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      [disabled]="!this.canSave"
      [btnType]="'Secondary'"
      width="120px"
      (sisClick)="this.save()"
      class="sis-global-button-padding-right"
      data-test="timetable-save-button"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      width="120px"
      (sisClick)="this.close()"
    ></sis-general-button>
  </div>
</ion-footer>
