import { Component } from '@angular/core';

@Component({
  selector: 'sis-gin-widget',
  templateUrl: './gin-widget.component.html',
  styleUrls: ['./gin-widget.component.scss'],
})
export class GINWidgetComponent {
  constructor() {}
}
