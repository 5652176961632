<ion-grid class="sis-global-page-grid sis-page-grid sis-global-no-scrollbar">
  <sis-page-title [title]="'general.term.featuremanagement' | translate"> </sis-page-title>
  <ion-grid class="sis-no-scroll sis-feature-management-grid ion-no-padding">
    <ion-row>
      <ng-container *ngFor="let range of this.featureRanges">
        <ion-col>
          <ion-row class="sis-feature-management-header">
            <ng-container *ngFor="let header of this.headers">
              <ion-col [size]="header.size">{{ header.label | translate }}</ion-col>
            </ng-container>
          </ion-row>
          <ng-container *ngFor="let feature of this.editedFeatures">
            <ng-container *ngIf="feature.id >= range.min && feature.id < range.max">
              <sis-feature-management-item
                [feature]="feature"
                (featureChanged)="onChanged()"
              ></sis-feature-management-item>
            </ng-container>
          </ng-container>
        </ion-col>
      </ng-container>
    </ion-row>

    <ion-row class="sis-footer">
      <ion-col>
        <sis-general-button
          [text]="'general.term.save'"
          class="sis-global-button-padding-right"
          [btnType]="'Secondary'"
          (sisClick)="this.save()"
          [disabled]="this.isSaving || !this.unsavedChanges"
          [showSpinner]="this.isSaving"
          width="120px"
          data-test="feature-management-save-btn"
        ></sis-general-button>
        <sis-general-button
          [text]="'general.term.reset'"
          [btnType]="'Secondary'"
          (sisClick)="this.reset()"
          [disabled]="this.isSaving || !this.unsavedChanges"
          [showSpinner]="this.isSaving"
          width="120px"
          data-test="feature-management-reset-btn"
        ></sis-general-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-grid>
