<ion-grid class="sis-detailview-container sis-global-no-scrollbar">
  <ion-row class="sis-data-row">
    <ion-col size="8">{{ 'energy.term.devices' | translate }}</ion-col>
    <ion-col size="3" class="ion-text-right">{{ 'energy.term.power' | translate }}</ion-col>
  </ion-row>

  <hr />

  <ion-row
    *ngFor="let device of this.devices; let odd = odd; trackBy: trackByDisplayName"
    class="sis-data-row"
    [ngClass]="{ odd: odd }"
  >
    <ion-col size="8">{{ device.displayName }}</ion-col>
    <ion-col size="3" class="ion-text-right">{{ device.power }}</ion-col>
  </ion-row>

  <hr />

  <ion-row class="sis-data-row" style="font-weight: bold">
    <ion-col size="8">{{ 'general.term.total' | translate }}</ion-col>
    <ion-col size="3" class="ion-text-right">{{ this.totalPower }}</ion-col>
  </ion-row>

  <ion-row *ngIf="this.hasEnergyWh10Sec">
    <sis-ropeway-energy-chart style="width: 100%; height: 300px"></sis-ropeway-energy-chart>
  </ion-row>
</ion-grid>
