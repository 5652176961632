import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { UserSettingsUpdatedEvent } from 'src/app/core/eventbus/events';
import { UserSettingsAdapter } from 'src/app/domain/user-settings/user-settings.adapter';
import { UserSettings } from 'src/app/domain/user-settings/user-settings.model';
import { UserSettingsPost } from 'src/app/domain/user-settings/user-settings-post.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';
import { v4 as v4guid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private readonly userSettingsUrl = '/user/settings';
  private readonly updaterId = v4guid();
  private readonly immediateUpdate$ = new Subject<UserSettingsUpdatedEvent>();

  private readonly userSettingsRequest$: Observable<UserSettings> = this.http
    .get(environment.baseUrlApi + this.userSettingsUrl)
    .pipe(
      map((data: any) => UserSettingsAdapter.adapt(data)),
      shareReplay(1)
    );

  private readonly userSettingsUpdated$: Observable<UserSettings> = merge(
    this.eventBus.observe(UserSettingsUpdatedEvent).pipe(
      tap((event) => {
        if (event.updateSuccessful) {
          if (event.userSettings.guiLanguage) {
            this.userMessageService.presentToast(
              new UserMessage({
                message: 'user.message.languagePersistSuccess',
                icon: UserMessageIcon.success,
                durationMs: 2000,
                position: 'top',
                color: UserMessageColor.green,
              })
            );
          }
        } else {
          this.displayErrorMessage(event.userSettings);
        }
      })
    ),
    this.immediateUpdate$
  ).pipe(
    filter((event) => event.updateSuccessful && event.updaterId !== this.updaterId),
    withLatestFrom(this.userSettingsRequest$),
    map(([event, settings]) => {
      const updatedSettings = event.userSettings;
      if (updatedSettings.guiLanguage != null) {
        settings.guiLanguage = updatedSettings.guiLanguage;
      }
      if (updatedSettings.selectedSeason != null) {
        settings.selectedSeason = updatedSettings.selectedSeason;
      }
      if (updatedSettings.timeFormat != null) {
        settings.timeFormat = updatedSettings.timeFormat;
      }
      if (updatedSettings.lastNewsViewed != null) {
        settings.lastNewsViewed = updatedSettings.lastNewsViewed;
      }
      return settings;
    })
  );

  readonly userSettings$ = merge(this.userSettingsRequest$, this.userSettingsUpdated$).pipe(shareReplay(1));

  constructor(
    private http: HttpClient,
    private userMessageService: UserMessageService,
    private eventBus: EventBusService
  ) {}

  async updateUserSettings(userSettings: UserSettingsPost): Promise<void> {
    userSettings.updaterId = this.updaterId;
    this.doImmediateUpdate(userSettings);
    try {
      await firstValueFrom(this.http.post(`${environment.baseUrlApi}${this.userSettingsUrl}`, userSettings));
    } catch {
      this.displayErrorMessage(userSettings);
    }
  }

  /*
    We don't want to wait for the update from the API before changing the settings in the SPA, even if the API fails
  */
  private doImmediateUpdate(userSettings: UserSettingsPost): void {
    const event = new UserSettingsUpdatedEvent();
    event.userSettings = {
      timeFormat: userSettings.timeFormat,
      guiLanguage: userSettings.guiLanguage,
      selectedSeason: userSettings.selectedSeason,
      lastNewsViewed: userSettings.newsViewed ? new Date() : null,
    };
    event.updateSuccessful = true;
    this.immediateUpdate$.next(event);
  }

  private displayErrorMessage(userSettings?: UserSettings): void {
    this.userMessageService.presentToast(
      new UserMessage({
        color: UserMessageColor.red,
        icon: UserMessageIcon.failed,
        message: this.getErrorMessage(userSettings),
        position: 'top',
      })
    );
  }

  private getErrorMessage(userSettings?: UserSettings): string {
    if (!userSettings) {
      return 'general.phrase.saveFailed';
    } else {
      if (userSettings.guiLanguage != null) {
        return 'user.message.languagePersistFailed';
      }
      if (userSettings.timeFormat != null) {
        return 'user.message.timeFormatPersistFailed';
      }
    }
    return 'general.phrase.saveFailed';
  }
}
