import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';

export class FeatureAdapter {
  static adapt(item: any): Feature[] {
    if (!item) {
      return [];
    }

    const features = [];

    item.forEach((element: { featureId: FeatureId; featureAccessLevel: FeatureAccessLevel; featureOrder: number; adminOnly: boolean }) => {
      const feature: FeatureId = element.featureId;
      const featureAccessLevel: FeatureAccessLevel = element.featureAccessLevel;
      const featureOrder: number = element.featureOrder;
      const adminOnly: boolean = element.adminOnly;

      features.push(new Feature(feature, featureAccessLevel, adminOnly, featureOrder));
    });

    return features;
  }
}
