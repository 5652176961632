import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AlarmlistService } from 'src/app/alarmlist/alarmlist.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Alarm, AlarmLevel } from 'src/app/domain/alarm.model';

@Component({
  selector: 'sis-alarm-icon',
  templateUrl: './alarm-icon.component.html',
  styleUrls: ['./alarm-icon.component.scss'],
})
export class AlarmIconComponent extends Unsubscriber implements OnInit {
  static readonly ALARM_COLOR: string = 'sis-alarm-color';
  static readonly NORMAL_COLOR: string = 'sis-normal-color';
  static readonly INFO_COLOR: string = 'sis-info-color';
  static readonly WARNING_COLOR: string = 'sis-warning-color';

  static readonly BLACK: 'sis-black-text-color';
  static readonly WHITE: 'sis-white-text-color';

  @Input() iconSize: string;
  @Input() componentClass: string;
  @Input() displayedAlarmLevels: AlarmLevel[];
  @Input() hideNumber = false;
  @Input() showIfNoAlarm = false;

  displayNumber = false;
  activeAlarmCount: number;
  textClass: string;
  alarmClass: string;
  iconClasses: string;

  private hasActiveAlarms = false;
  private activeAlarms: Alarm[];

  constructor(private alarmlistService: AlarmlistService) {
    super();
  }

  ngOnInit() {
    this.updateDisplayValues([]);
    this.alarmlistService.alarms$.pipe(takeUntil(this.onDestroy$)).subscribe((alarms) => {
      if (this.displayedAlarmLevels?.length) {
        alarms = alarms.filter((a) => this.displayedAlarmLevels.includes(a.level));
      }

      this.updateDisplayValues(alarms);
    });
  }

  private getColorClass(): string {
    if (!this.activeAlarms) {
      return AlarmIconComponent.NORMAL_COLOR;
    }

    if (
      this.activeAlarms.find(
        (a) => a.level === AlarmLevel.ALARM || a.level === AlarmLevel.FAULT || a.level === AlarmLevel.SYSTEM
      )
    ) {
      return AlarmIconComponent.ALARM_COLOR;
    }

    if (this.activeAlarms.find((a) => a.level === AlarmLevel.WARNING)) {
      return AlarmIconComponent.WARNING_COLOR;
    }

    return AlarmIconComponent.INFO_COLOR;
  }

  private getTextColor(): string {
    if (
      this.activeAlarms &&
      this.activeAlarms.find((a) => a.level === AlarmLevel.ALARM || a.level === AlarmLevel.FAULT)
    ) {
      return AlarmIconComponent.WHITE;
    }

    return AlarmIconComponent.BLACK;
  }

  private getTextClass(): string {
    if (this.hasActiveAlarms && this.componentClass === 'sis-sidepane-alarm-text') {
      return `${this.componentClass} ${this.getTextColor()}`;
    }
    return `${this.componentClass} ${this.hasActiveAlarms ? this.getColorClass() : AlarmIconComponent.NORMAL_COLOR}`;
  }

  private getAlarmClass(): string {
    if (this.hasActiveAlarms && this.componentClass?.includes('sis-header-alarm-text')) {
      return this.getColorClass();
    }
    if (this.hasActiveAlarms) {
      return '';
    }
    return AlarmIconComponent.NORMAL_COLOR;
  }

  private updateDisplayValues(alarms: Alarm[]) {
    this.activeAlarms = alarms.filter((a) => a.active);
    this.activeAlarmCount = this.activeAlarms.length;
    this.hasActiveAlarms = this.activeAlarmCount > 0;

    this.textClass = this.getTextClass();
    const alarmClass = this.getAlarmClass();
    const iconClass = this.componentClass?.includes('-mobile') ? 'sis-alarm-icon-mobile' : 'sis-alarm-icon';
    this.iconClasses = `${alarmClass} ${iconClass}`;
    this.displayNumber = !this.hideNumber && (this.hasActiveAlarms || this.componentClass !== 'sis-header-alarm-text');
  }
}
