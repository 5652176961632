import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayEcoModeTrend } from 'src/app/maps/trenddata/ropeway-ecomode-trend.model';
import { RopewayEcoModeTrendService } from 'src/app/maps/trenddata/ropeway-ecomode-trend.service';
import { ChartAdapter } from 'src/app/maps/widgets/charts/chart-adapter';
import { ChartSettingsService } from 'src/app/maps/widgets/charts/chart-settings.service';
import { ChartSelector } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/chart-selector.model';
import { EcoModeChartHandler } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-chart-handler';

export class EcoModeActualSpeedHandler extends EcoModeChartHandler {
  private static readonly speedTranslateString = 'ecomode.term.actualSpeed';
  private static readonly trendColor = '#d30000ff';

  private seriesName: string;

  constructor(
    protected chartSettingsService: ChartSettingsService,
    protected chartAdapter: ChartAdapter,
    private ropewayEcoModeTrendService: RopewayEcoModeTrendService,
    translateService: TranslateService,
    ropeway: Ropeway,
    yAxisUnits: string[]
  ) {
    super(chartSettingsService, chartAdapter, translateService, ropeway, yAxisUnits);

    this.seriesId = ropeway.sisId + 'actualSpeedMps';
    this.seriesName = translateService.instant(EcoModeActualSpeedHandler.speedTranslateString);
  }

  seriesObservable(): Observable<Array<{ seriesId: string; data: number[][] }>> {
    return this.chartSettingsService.getRopewaySetting(this.ropeway.sisId, this.seriesId).pipe(
      switchMap((enabled) => {
        if (enabled) {
          this.chartAdapter.addSeries(this.seriesId, {
            name: this.seriesName,
            id: this.seriesId,
            type: 'line' as any,
            step: 'left',
            lineWidth: 1,
            className: '',
            tooltip: {
              valueSuffix: ' m/s',
            },
            color: EcoModeActualSpeedHandler.trendColor,
            yAxis: this.yAxisUnits.indexOf('m/s'),
          });

          return this.chartAdapter
            .loadData([this.ropewayEcoModeTrendService.getEcoModeData(this.ropeway.sisId)])
            .pipe(map((data) => [{ seriesId: this.seriesId, data: this.getData(data[0]) }]));
        } else {
          this.chartAdapter.removeSeries(this.seriesId);
          return of([{ seriesId: this.seriesId, data: null }]);
        }
      })
    );
  }

  getChartSelectors(): ChartSelector[] {
    return [
      {
        setting: this.seriesId,
        titleStrings: [EcoModeActualSpeedHandler.speedTranslateString],
        ropewaySisId: this.ropeway.sisId,
        selected$: this.chartSettingsService.getRopewaySetting(this.ropeway.sisId, this.seriesId),
      },
    ];
  }

  private getData(data: RopewayEcoModeTrend[]): number[][] {
    return (
      data
        ?.map((item) => {
          const timeStampInMs = item.timestamp.getTime();
          return [timeStampInMs, Math.round(item.actualSpeedMps * 10) / 10];
        })
        .sort((i1, i2) => i1[0] - i2[0]) ?? []
    );
  }
}
