<sis-outdated-icon
  *ngIf="this.outdated"
  color="var(--ion-color-danger)"
  [timestamp]="this.dataTimestamp"
></sis-outdated-icon>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <rect x="0.1" width="187.5" height="225" style="fill: none" />
        <path
          d="M60,60.82H48l-.73-1.09L44,54.84l.94,0H56Zm10-6H58l4,6H74Zm2,0,4,6H88l-4-6ZM94,44.17l-4.68,4.68,6,6H85.76l4,6h5.53l-6,6L94,71.48l13.65-13.66Zm31.73,10.66h-12l4,6h12Zm14.72,1.07-.72-1.08h-12l4,6h12ZM56,99.82H44l3.26,4.91.73,1.09H60Zm14,0H58l4,6H74Zm2,0,4,6H88l-4-6Zm25.75,0h-12l4,6h12Zm2,0,4,6h12l-4-6Zm26,0h-12l4,6h12Zm14.72,1.07-.72-1.08h-12l4,6h11l1,0Zm-109-42.15L38,62a.47.47,0,0,1,.12.09A19.13,19.13,0,0,1,45,60.82h1L42.08,55A25.12,25.12,0,0,0,31.42,58.75ZM29.91,68a19.41,19.41,0,0,1,6.2-5l-6.3-3.13a25.52,25.52,0,0,0-7.37,8.58L29.76,68Zm-4.16,9.19a19,19,0,0,1,2.88-7.43l-7,.46a25.08,25.08,0,0,0-2.12,10.14c0,.33,0,.65,0,1l6.11-4.07A.46.46,0,0,1,25.75,77.15Zm1,10a19.13,19.13,0,0,1-1.26-6.87q0-.5,0-1l-5.86,3.91a25.08,25.08,0,0,0,3.76,10.64S26.7,87.23,26.74,87.19Zm5.88,8.2a19.41,19.41,0,0,1-5-6.2L24.5,95.5a25.57,25.57,0,0,0,8.58,7.36l-.47-7.31A.86.86,0,0,1,32.62,95.39Zm9.19,4.17a19.28,19.28,0,0,1-7.43-2.88l.46,7A25.08,25.08,0,0,0,45,105.82h1ZM145.92,61.09A19.13,19.13,0,0,1,153.35,64l-.46-7a25.25,25.25,0,0,0-10.14-2.11h-1L145.85,61A.46.46,0,0,1,145.92,61.09Zm9.19,4.16a19.36,19.36,0,0,1,5,6.2l3.13-6.3a25.63,25.63,0,0,0-8.58-7.38S155.12,65.2,155.11,65.25Zm7.11,16.07,5.86-3.9a25.12,25.12,0,0,0-3.76-10.66S161,73.41,161,73.45h0a19.08,19.08,0,0,1,1.26,6.86C162.25,80.66,162.24,81,162.22,81.32ZM149.6,98.58a19.23,19.23,0,0,1-6.85,1.24h-1l3.87,5.83a25.11,25.11,0,0,0,10.66-3.75S149.64,98.61,149.6,98.58Zm8.22-5.89a19.56,19.56,0,0,1-6.21,5l6.31,3.13a25.71,25.71,0,0,0,7.37-8.58S157.85,92.7,157.82,92.69Zm10.41-13.33S162,83.49,162,83.5a18.87,18.87,0,0,1-2.88,7.42l7-.45a25.1,25.1,0,0,0,2.12-10.15C168.25,80,168.24,79.68,168.23,79.36Z"
          style="fill: #32849a"
        />
      </g>
    </g>
    <g id="Widgettexte">
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
        [style.fill]="this.outdated ? 'var(--ion-color-medium)' : '#1c232a'"
      >
        {{ ropeKm }}
        <tspan id="unit" style="font-size: 24px">km</tspan>
      </text>
    </g>
  </g>
</svg>
