import { MediaCenterNetworkState } from 'src/app/media-center/network-state/network-state.model';

export class MediaCenterNetworkStateAdapter {
  static adapt(item: any): MediaCenterNetworkState[] {
    const networkStates: MediaCenterNetworkState[] =
      item?.map((e: any) => ({
        deviceType: e.DeviceType,
        deviceName: e.DeviceName,
        deviceIp: e.DeviceIp,
        status: e.Status,
        timestamp: new Date(e.NmsTimestamp),
      })) || [];

    return networkStates;
  }
}
