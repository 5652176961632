<ion-list *ngIf="this.status == 2" class="background-color" data-test="sismedia-substatus-open">
  <ion-item *ngFor="let substatus of openSubstatuses" (click)="selectSubstatus(substatus.value)">
    <ion-icon name="{{ substatus.icon }}" color="black"></ion-icon>
    {{ substatus.label | translate }}
  </ion-item>
</ion-list>

<ion-list *ngIf="this.status == 1" class="background-color" data-test="sismedia-substatus-closed">
  <ion-item *ngFor="let substatus of closedSubstatuses" (click)="selectSubstatus(substatus.value)">
    <ion-icon name="{{ substatus.icon }}" color="black"></ion-icon>
    {{ substatus.label | translate }}
  </ion-item>
</ion-list>
