import { Ropeway } from 'src/app/maps/domain/ropeway.model';

export class IconHelper {
  static readonly meteoStationIconName = 'sis-meteostation';

  static getRopewayIconName(ropeway: Ropeway): string {
    if (ropeway && ropeway.ropewayType) {
      let type = ropeway.ropewayType.substring(0, 2);
      type = type === 'ft' ? 'pb' : type === 'sf' ? 'sk' : type;
      return `sis-${type}`;
    }
    return 'sis-pb';
  }

  static getIconName(type: string): string {
    if (type != null && typeof type === 'string') {
      switch (type.toLowerCase()) {
        case 'pendelbahn':
          return 'sis-pb';
        case 'gondelbahn':
          return 'sis-kb';
        case 'sesselbahn2':
        case 'sesselbahn3':
        case 'sesselbahn4':
        case 'sesselbahn6':
          return 'sis-sk';
        case 'standseilbahn':
          return 'sis-st';
        case 'pisteleicht':
          return 'sis-piste-leicht';
        case 'pistemittel':
          return 'sis-piste-mittel';
        case 'pisteschwer':
          return 'sis-piste-schwer';
        case 'pistefreeride':
          return 'sis-piste-freeride';
        case 'langlaufklassisch':
          return 'sis-langlauf-klassisch';
        case 'langlaufskating':
          return 'sis-langlauf-skating';
      }

      return `sis-${type}`;
    }

    return 'sis-placeholder';
  }
}
