import { Component, Injector, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChartSettings } from 'src/app/maps/widgets/charts/chart-settings.model';

@Component({
  selector: 'sis-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
})
export class ModalPopupComponent {
  @Input() component: any;

  isInModalInjector: Injector;

  constructor(private modalCtrl: ModalController, injector: Injector) {
    const settings = new ChartSettings({ inModal: true });

    this.isInModalInjector = Injector.create({
      providers: [{ provide: ChartSettings, useValue: settings }],
      parent: injector,
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
