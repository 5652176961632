import { Component, OnInit } from '@angular/core';
import { combineLatest, takeUntil } from 'rxjs';
import { Alarm, AlarmLevel } from 'src/app/domain/alarm.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayEfaService } from 'src/app/maps/livedata/ropeway-efa.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-efa-widget',
  templateUrl: './efa-widget.component.html',
  styleUrls: ['./efa-widget.component.scss'],
})
export class EfaWidgetComponent extends WidgetBase implements OnInit {
  static readonly efaCodeDefault = '0';
  static readonly normalClass = 'sis-efa-normal';
  static readonly alarmClass = 'sis-efa-alarm';
  static readonly fontFamilyMyriadPro = 'Myriad Pro';
  static readonly fontFamilyMyriadProCond = 'Myriad Pro Condensed';

  cssClass = EfaWidgetComponent.normalClass;
  fontFamily = EfaWidgetComponent.fontFamilyMyriadPro;
  efacode = EfaWidgetComponent.efaCodeDefault;

  constructor(
    private ropewayEfaService: RopewayEfaService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.selectedMapElementService.selectedRopeway$, this.ropewayEfaService.efas$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([ropeway, efas]) => this.update(ropeway, efas));
  }

  private update(ropeway: Ropeway, efas: Alarm[]): void {
    if (!efas) {
      return;
    }

    if (!ropeway) {
      this.resetToDefault();
      return;
    }

    const d = efas.filter((efa: Alarm) => efa.active && efa.sisId === ropeway.sisId);

    let efa: Alarm;
    if (d && d.length > 0) {
      const fault = d.find((a) => a.level === AlarmLevel.FAULT);
      efa = fault ? fault : d[0];
    } else {
      this.resetToDefault();
      return;
    }

    this.setClass(efa);
    this.setFontFamily(efa);
    this.efacode = efa.code.toUpperCase().replace(/EFA_|IFI_/g, '');
  }

  private resetToDefault(): void {
    this.efacode = EfaWidgetComponent.efaCodeDefault;
    this.cssClass = EfaWidgetComponent.normalClass;
    this.fontFamily = EfaWidgetComponent.fontFamilyMyriadPro;
  }

  private setClass(alarm: Alarm): void {
    this.cssClass = alarm.level === AlarmLevel.FAULT ? EfaWidgetComponent.alarmClass : EfaWidgetComponent.normalClass;
  }

  private setFontFamily(alarm: Alarm): void {
    this.fontFamily =
      alarm.code.length > 10 ? EfaWidgetComponent.fontFamilyMyriadProCond : EfaWidgetComponent.fontFamilyMyriadPro;
  }
}
