import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChartSettingsService {
  private readonly ropewayChartSettings = new Map<string, Map<string, BehaviorSubject<boolean>>>();

  private readonly _forecastEnabled$ = new BehaviorSubject<boolean>(true);

  readonly forecastEnabled$ = this._forecastEnabled$.asObservable();

  async toggleForecast(): Promise<void> {
    const forecastEnabled = await firstValueFrom(this.forecastEnabled$);
    this._forecastEnabled$.next(!forecastEnabled);
  }

  async toggleRopewaySetting(ropewaySisId: string, setting: string): Promise<void> {
    const ropewaySettings = this.getRopewaySettings(ropewaySisId);
    const subject = this.getSetting(ropewaySettings, setting);

    const enabled = await firstValueFrom(subject);
    subject.next(!enabled);
  }

  getRopewaySetting(ropewaySisId: string, setting: string): Observable<boolean> {
    const ropewaySettings = this.getRopewaySettings(ropewaySisId);
    return this.getSetting(ropewaySettings, setting).asObservable();
  }

  private getRopewaySettings(ropewaySisId: string): Map<string, BehaviorSubject<boolean>> {
    if (!this.ropewayChartSettings.has(ropewaySisId)) {
      this.ropewayChartSettings.set(ropewaySisId, new Map<string, BehaviorSubject<boolean>>());
    }

    return this.ropewayChartSettings.get(ropewaySisId);
  }

  private getSetting(
    ropewaySettings: Map<string, BehaviorSubject<boolean>>,
    setting: string
  ): BehaviorSubject<boolean> {
    if (!ropewaySettings.has(setting)) {
      ropewaySettings.set(setting, new BehaviorSubject<boolean>(true));
    }

    return ropewaySettings.get(setting);
  }
}
