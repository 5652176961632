import { NmsData } from 'src/app/it-services/livedata/nms-data.model';

export class NmsDataAdapter {
  static adapt(item: any): NmsData[] {
    const nmsDatas: NmsData[] =
      item?.map((e: any) => ({
        deviceType: e.DeviceType,
        deviceName: e.DeviceName,
        deviceIp: e.DeviceIp,
        status: e.Status,
        timestamp: new Date(e.NmsTimestamp),
      })) || [];

    return nmsDatas;
  }
}
