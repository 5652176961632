<ion-header>
  <ion-toolbar>
    <ion-title data-test="sis-infobanner-screen-selector-modal-title">
      {{ 'mediacenter.term.infoBanner' | translate }} "{{ this.infoBanner?.name }}"
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="close(false)"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="sis-mediacenter-no-scroll sis-modal-grid" style="height: 100%">
    <div *ngIf="!this.ready" class="sis-spinner-div">
      <ion-spinner></ion-spinner>
    </div>
    <div *ngIf="this.ready" class="sis-screens-div">
      <ion-row class="sis-screen-row" style="height: 41px">
        <ion-col size="auto" class="sis-checkbox-col">
          <ion-checkbox
            [checked]="this.allGroupsSelected"
            (ionChange)="!this.ignoreIonChangeEvent && this.selectAllScreens($event)"
            color="secondary"
            data-test="sis-infobanner-select-all-screens-checkbox"
          ></ion-checkbox>
        </ion-col>
        <ion-col>{{ 'mediacenter.term.all' | translate }}</ion-col>
      </ion-row>
      <ng-container *ngFor="let group of this.screenGroups">
        <ion-row *ngIf="group.guid" class="sis-group-row">
          <ion-col size="auto" class="sis-checkbox-col">
            <ion-checkbox
              [checked]="group.groupActive"
              (ionChange)="!this.ignoreIonChangeEvent && this.selectAllScreensOfGroup(group, $event)"
              color="secondary"
              data-test="sis-infobanner-select-group-checkbox"
            ></ion-checkbox>
          </ion-col>
          <ion-col>
            {{ group.name }}
          </ion-col>
        </ion-row>
        <ng-container *ngFor="let screen of group.screens">
          <ion-row class="sis-screen-row">
            <ion-col size="auto" class="sis-checkbox-col">
              <ion-checkbox
                [checked]="screen.newInfoBannerActive"
                (ionChange)="!this.ignoreIonChangeEvent && this.selectScreen(screen, $event)"
                color="secondary"
                data-test="sis-infobanner-select-screen-checkbox"
              ></ion-checkbox>
            </ion-col>
            <ion-col data-test="sis-infobanner-screen-selector-screen-location">
              {{ screen.location }} <br />
              <span style="font-size: 13px">{{ screen.label }}</span>
            </ion-col>
            <ion-col
              *ngIf="screen.activeInfoBannerGuid && screen.activeInfoBannerGuid !== this.infoBanner.guid"
              size="auto"
            >
              <a
                class="sis-mediacenter-clickable"
                [matTooltip]="('mediacenter.phrase.activeInfoBanner' | translate) + screen.activeInfoBannerName"
                matTooltipClass="sis-global-tooltip"
                data-test="sis-infobanner-active-infobanner-info-tooltip"
              >
                <ion-icon
                  class="sis-mediacenter-icon"
                  name="alert-circle-outline"
                  data-test="sis-infobanner-active-infobanner-info"
                >
                </ion-icon> </a
            ></ion-col>
          </ion-row>
        </ng-container>
      </ng-container>
    </div>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      [btnType]="'Secondary'"
      data-test="sis-infobanner-screen-selector-modal-save-btn"
      class="sis-global-button-padding-right"
      [width]="(bigScreenMode$ | async) ? '' : '100%'"
      (sisClick)="this.save()"
    >
    </sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      data-test="sis-infobanner-screen-selector-modal-cancel-btn"
      [width]="(bigScreenMode$ | async) ? '' : '100%'"
      (sisClick)="this.close(false)"
    >
    </sis-general-button>
  </div>
</ion-footer>
