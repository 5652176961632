import { Component, OnInit } from '@angular/core';
import { take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AlarmlistService } from 'src/app/alarmlist/alarmlist.service';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MapIconClickedEvent, RopewaySettingsUpdatedEvent } from 'src/app/core/eventbus/events';
import { IconHelper } from 'src/app/core/icon-helper/icon-helper';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { CustomMarker } from 'src/app/maps/domain/custom-marker.model';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-widget-sidepane',
  templateUrl: './widget-sidepane.component.html',
  styleUrls: ['./widget-sidepane.component.scss'],
})
export class WidgetSidepaneComponent extends Unsubscriber implements OnInit {
  showWebcams: boolean;
  showControllerContextMenu: boolean;
  controllerContextMenuX: number;
  controllerContextMenuY: number;
  tenantLogoUrl: string;
  ropeway: Ropeway;
  hasRemoteControl = false;
  hasRedundantControllers = false;
  showPlaceHolder = true;
  destinationView = true;
  iconName: string;
  cssClass: string;
  alias: string;
  fullNameWithSisId: string;
  controllerTypeWithConstructor: string;
  dataProvider: string;

  constructor(
    private destinationService: DestinationService,
    private alarmListService: AlarmlistService,
    private selectedMapElementService: SelectedMapElementService,
    private eventBus: EventBusService
  ) {
    super();

    this.alarmListService.alarms$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      setTimeout(() => (this.cssClass = Math.random().toString()), 50);
    });
  }

  ngOnInit() {
    this.selectedMapElementService.selectedMapElement$.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
      this.setSelectedElementValues(event.ropeway, event.meteoStation, event.customMarker);
      this.selectActiveWebcams(event);
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.hasRemoteControl = features.some((f) => f.featureId === FeatureId.COCKPIT_REMOTEACCESS);
    });

    this.destinationService.selectedTenant$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.showDestinationView();
    });

    this.eventBus
      .observe(RopewaySettingsUpdatedEvent)
      .pipe(takeUntil(this.onDestroy$), withLatestFrom(this.selectedMapElementService.selectedMapElement$))
      .subscribe(([updatedEvent, selectedMapElement]) => {
        if (selectedMapElement.ropeway && selectedMapElement.ropeway.guid == updatedEvent.ropewaySettings.guid) {
          const selectedRopeway = selectedMapElement.ropeway;
          selectedRopeway.alias = updatedEvent.ropewaySettings.alias;
          selectedRopeway.fullname = updatedEvent.ropewaySettings.fullname;
          selectedRopeway.constructedBy = updatedEvent.ropewaySettings.constructedBy;
          selectedRopeway.controllerType = updatedEvent.ropewaySettings.controllerType;

          this.setSelectedElementValues(selectedRopeway, null, null);
        }
      });
  }

  showControllerSelector(div: any): void {
    this.showControllerContextMenu = this.ropeway && this.ropeway.hasRedundantControllers;
    this.controllerContextMenuX = div.offsetLeft + 65;
    this.controllerContextMenuY = div.offsetTop + 10;
  }

  hideControllerContextMenu(): void {
    this.showControllerContextMenu = false;
  }

  private setSelectedElementValues(ropeway: Ropeway, meteoStation: MeteoStation, customMarker: CustomMarker): void {
    this.ropeway = ropeway || meteoStation?.ropeway;

    if (ropeway) {
      this.fullNameWithSisId = `${ropeway.fullname} (${ropeway.sisId.toUpperCase()})`;
      this.controllerTypeWithConstructor = [ropeway.controllerType, ropeway.constructedBy].filter((t) => t).join(' | ');
      if (!this.controllerTypeWithConstructor) {
        this.controllerTypeWithConstructor = null;
      }

      this.alias = ropeway.alias;
      this.iconName = IconHelper.getRopewayIconName(this.ropeway);
    } else if (meteoStation) {
      this.iconName = IconHelper.meteoStationIconName;
      this.fullNameWithSisId = undefined;
      this.controllerTypeWithConstructor = undefined;
      this.alias = meteoStation.alias;
      this.dataProvider = meteoStation.dataProvider ?? '';
    } else if (customMarker) {
      this.iconName = customMarker.iconName;
      this.fullNameWithSisId = undefined;
      this.alias = customMarker.alias;
      this.dataProvider = undefined;
    } else {
      this.iconName = undefined;
    }

    this.showPlaceHolder = this.destinationView = !(ropeway || meteoStation || customMarker);
    this.hasRedundantControllers = this.ropeway && this.ropeway.hasRedundantControllers;
  }

  private selectActiveWebcams(event: MapIconClickedEvent): void {
    this.showWebcams = event.ropeway != null || event.meteoStation != null || event.customMarker != null;
    if (this.showWebcams) {
      this.tenantLogoUrl = undefined;
    } else {
      this.showDestinationView();
    }
  }

  private showDestinationView(): void {
    this.setSelectedElementValues(null, null, null);
    this.showWebcams = false;

    this.destinationService.selectedTenant$.pipe(take(1)).subscribe((tenant) => {
      if (tenant && tenant.logoFilename) {
        this.tenantLogoUrl = environment.baseUrlCockpitBlobStorage + '/public-assets/logo/' + tenant.logoFilename;
      } else {
        this.tenantLogoUrl = undefined;
      }
    });
  }
}
