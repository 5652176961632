import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { LogbookModalComponent } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.component';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';

@Injectable({
  providedIn: 'root',
})
export class LogbookModalService {
  private readonly requiredFeature = new Feature(FeatureId.LOGBOOK, FeatureAccessLevel.READ);

  readonly logbookAvailable$ = this.destinationService.selectedTenantFeatures$.pipe(
    map((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature)))
  );

  constructor(private modalCtrl: ModalController, private destinationService: DestinationService) {}

  async presentLogbookModal(featureFilters: FeatureId[][]): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: LogbookModalComponent,
      componentProps: {
        featureFilters,
      },
    });
    await modal.present();
  }
}
