import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { MeteoForecast } from 'src/app/maps/livedata/meteo-forecast.model';
import { MeteoForecastService } from 'src/app/maps/livedata/meteo-forecast.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-icing-widget',
  templateUrl: './icing-widget.component.html',
  styleUrls: ['./icing-widget.component.scss'],
})
export class IcingWidgetComponent extends WidgetBase implements OnInit {
  private readonly dangerColor = 'var(--ion-color-danger)';
  private readonly warningColor = 'var(--ion-color-warning)';
  private readonly defaultColor = 'var(--ion-color-primary)';

  private readonly intensityHighTranslateString = 'icing.term.intensityHigh';
  private readonly intensityMediumTranslateString = 'icing.term.intensityMedium';
  private readonly intensityLowTranslateString = 'icing.term.intensityLow';
  private readonly intensityNoneTranslateString = 'icing.term.intensityNone';

  private readonly icingIntensityTranslateString$ = new BehaviorSubject<string>(WidgetBase.valueUnknown);

  readonly icingIntensity$: Observable<string> = this.icingIntensityTranslateString$.pipe(
    switchMap((s) => this.translateService.stream(s))
  );
  icingColor = this.defaultColor;

  constructor(private translateService: TranslateService, private meteoForecastService: MeteoForecastService) {
    super();
  }

  ngOnInit(): void {
    this.resetToDefault();

    this.meteoForecastService.forecasts$.pipe(takeUntil(this.onDestroy$)).subscribe((forecasts) => {
      this.update(forecasts);
    });
  }

  private getTranslatedString(translateKey: string): Promise<string> {
    return firstValueFrom(this.translateService.get(translateKey));
  }

  private update(forecasts: MeteoForecast[]): Promise<void> {
    if (!forecasts) {
      this.resetToDefault();
      return;
    }

    const icingIntensity = Math.max(-1, ...forecasts.filter((m) => !m.outdated).map((m) => m.icingIntensity));
    let translateString: string;

    switch (icingIntensity) {
      case 0:
        translateString = this.intensityNoneTranslateString;
        this.icingColor = this.defaultColor;
        break;
      case 1:
        translateString = this.intensityLowTranslateString;
        this.icingColor = this.warningColor;
        break;
      case 2:
        translateString = this.intensityMediumTranslateString;
        this.icingColor = this.warningColor;
        break;
      case 3:
        translateString = this.intensityHighTranslateString;
        this.icingColor = this.dangerColor;
        break;
      default:
        translateString = WidgetBase.valueUnknown;
        WidgetBase.valueUnknown;
        break;
    }

    this.icingIntensityTranslateString$.next(translateString);
  }

  private resetToDefault(): void {
    this.icingIntensityTranslateString$.next(WidgetBase.valueUnknown);
    this.icingColor = this.defaultColor;
  }
}
