import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, withLatestFrom } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { RopewaySettingsUpdatedEvent } from 'src/app/core/eventbus/events';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { RopewaySettings } from 'src/app/maps/domain/ropeway-settings/ropeway-settings.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RopewaySettingsService {
  private readonly basePostUrl: string = '/ropeway/settings';

  constructor(
    private http: HttpClient,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService,
    private userMessageService: UserMessageService
  ) {
    this.eventBus
      .observe(RopewaySettingsUpdatedEvent)
      .pipe(withLatestFrom(this.userSettingsService.userSettings$))
      .subscribe(([event, userSettings]) => {
        if (event.changedBy === userSettings.userGuid) {
          this.displayUserMessage(event.updateSuccessful);
        }
      });
  }

  saveRopewaySettings(ropewaySettings: RopewaySettings): Observable<boolean> {
    ropewaySettings.availability = ropewaySettings.availability && ropewaySettings.availabilityIsAvailable;

    return this.http.post(`${environment.baseUrlApi}${this.basePostUrl}`, ropewaySettings).pipe(
      map(() => true),
      catchError(() => {
        this.displayUserMessage(false);
        return of(false);
      })
    );
  }

  private displayUserMessage(updateSuccessful: boolean): void {
    const message = updateSuccessful ? 'general.phrase.saved' : 'general.phrase.saveFailed';
    const icon = updateSuccessful ? UserMessageIcon.success : UserMessageIcon.failed;
    const color = updateSuccessful ? UserMessageColor.green : UserMessageColor.red;
    const userMessage = new UserMessage({
      message,
      icon,
      durationMs: 2000,
      position: 'top',
      color,
    });
    this.userMessageService.presentToast(userMessage);
  }
}
