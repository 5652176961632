import { Component, Input } from '@angular/core';
import { AlarmLibraryService } from 'src/app/maps/alarmlibrary/alarmlibrary.service';
import { AlarmLibraryItem } from 'src/app/maps/alarmlibrary/domain/alarmlibrary-item.model';

@Component({
  selector: 'sis-alarmlibrary-item-icon',
  templateUrl: './alarmlibrary-item-icon.component.html',
  styleUrls: ['./alarmlibrary-item-icon.component.scss'],
})
export class AlarmLibraryItemIconComponent {
  @Input() alarm: AlarmLibraryItem;
  @Input() setting: string;
  @Input() icon: string;
  @Input() disabledIcon: string;

  constructor(private alarmLibraryService: AlarmLibraryService) {}

  showSpinner(): boolean {
    return this.alarm.getUpdating(this.setting);
  }

  getIcon(): string {
    return this.alarm.getSetting(this.setting) ? this.icon : this.disabledIcon;
  }

  toggleSetting() {
    this.alarm.setUpdating(this.setting, true);
    this.alarmLibraryService.updateSetting(
      this.alarm.sisId,
      this.alarm.code,
      this.setting,
      !this.alarm.getSetting(this.setting)
    );
  }
}
