import { RopewayAvailabilityTrend } from 'src/app/maps/trenddata/ropeway-availability-trend.model';

export class RopewayAvailabilityTrendAdapter {
  static adapt(items: any): RopewayAvailabilityTrend[] {
    const trends: RopewayAvailabilityTrend[] = [];

    if (items && items.length > 0) {
      items.forEach((e: any) => {
        trends.push({
          tenantSisId: e.tenantSisId,
          ropewaySisId: e.ropewaySisId,
          availability: e.availability,
          year: e.year,
          month: e.month,
          week: e.week,
          day: e.day,
          date: new Date(e.date),
          ignore: e.ignore,
        });
      });
    }

    return trends;
  }
}
