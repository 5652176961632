import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TruScanData } from 'src/app/maps/livedata/truscan-data.model';
import { TruScanDataService } from 'src/app/maps/livedata/truscan-data.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-truscan-widget',
  templateUrl: './truscan-widget.component.html',
  styleUrls: ['./truscan-widget.component.scss'],
})
export class TruScanWidgetComponent extends WidgetBase implements OnInit {
  static readonly cssClassGood = 'sis-truscan-good';
  static readonly cssClassTolerable = 'sis-truscan-tolerable';
  static readonly cssClassPoor = 'sis-truscan-poor';
  static readonly cssClassUnknown = 'sis-truscan-unknown';

  displayedValue: string = WidgetBase.valueUnknown;
  class: string = TruScanWidgetComponent.cssClassGood;

  constructor(
    private truScanDataService: TruScanDataService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.truScanDataService.truScanDatas$.pipe(filter((truScanDatas) => !!truScanDatas)),
      this.selectedMapElementService.selectedRopeway$,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([truScanDatas, ropeway]) => {
        this.class = this.getCssClass(truScanDatas, ropeway?.sisId);
        this.displayedValue = Math.min(
          ...truScanDatas.filter((data) => data.ropewaySisId == ropeway?.sisId).map((item) => item.health)
        ).toString();
      });
  }

  private getCssClass(truScanDatas: TruScanData[], selectedRopewaySisId: string): string {
    let cssClass = TruScanWidgetComponent.cssClassGood;

    let data = truScanDatas;

    if (selectedRopewaySisId) {
      data = data.filter((data) => data.ropewaySisId === selectedRopewaySisId);
    }

    const smallestRopeHealth = Math.min(
      ...data.filter((d) => d.ropewaySisId == selectedRopewaySisId).map((item) => item.health)
    );

    if (smallestRopeHealth >= 50) {
      cssClass = TruScanWidgetComponent.cssClassGood;
    } else if (smallestRopeHealth <= 10) {
      cssClass = TruScanWidgetComponent.cssClassPoor;
    } else {
      cssClass = TruScanWidgetComponent.cssClassTolerable;
    }

    return cssClass;
  }
}
