import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { SisMediaSetting } from 'src/app/sismedia-setting/sismedia-setting.model';
import { SisMediaSettingService } from 'src/app/sismedia-setting/sismedia-setting.service';
import { STnetCalculatedData } from 'src/app/stnet/domain/stnet-calculated-data.model';
import { STnetItem } from 'src/app/stnet/domain/stnet-item.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-stnet-editable',
  templateUrl: './stnet-editable.component.html',
  styleUrls: ['./stnet-editable.component.scss'],
})
export class STnetEditableComponent extends Unsubscriber implements OnChanges {
  private readonly tobogganRunsWithFloodlightLengthMeterTodayFormControl = new FormControl('', [
    Validators.min(0),
    Validators.max(1000000),
    Validators.pattern(/^\d+$/),
  ]);
  private readonly crossCountryFloodlightUntilFormControl = new FormControl<any>(null);
  private readonly crossCountryTracksWithFloodlightLengthMeterTodayFormControl = new FormControl('', [
    Validators.min(0),
    Validators.max(1000000),
    Validators.pattern(/^\d+$/),
  ]);
  private readonly slopesFloodlightUntilFormControl = new FormControl<any>(null);
  private readonly slopesWithFloodlightLengthMeterTodayFormControl = new FormControl('', [
    Validators.min(0),
    Validators.max(1000000),
    Validators.pattern(/^\d+$/),
  ]);
  private readonly snowparkFloodlightUntilFormControl = new FormControl<any>(null);

  readonly slopeConditions: Array<{ value: number; translationString: string }> = [
    { value: 801, translationString: 'stnet.phrase.condition.good' },
    { value: 802, translationString: 'stnet.phrase.condition.goodFair' },
    { value: 803, translationString: 'stnet.phrase.condition.fairGood' },
    { value: 804, translationString: 'stnet.phrase.condition.fair' },
    { value: 805, translationString: 'stnet.phrase.condition.closed' },
    { value: 806, translationString: 'stnet.phrase.condition.eos' },
    { value: 808, translationString: 'stnet.phrase.condition.satAndSunOnly' },
  ];

  readonly snowHeightMeasurementMethods: Array<{
    value: number;
    translationString: string;
  }> = [
    { value: 0, translationString: 'stnet.phrase.snowHeightMeasurementMethod.noInfo' },
    { value: 1, translationString: 'stnet.phrase.snowHeightMeasurementMethod.measuringField' },
    { value: 2, translationString: 'stnet.phrase.snowHeightMeasurementMethod.slf' },
    { value: 3, translationString: 'stnet.phrase.snowHeightMeasurementMethod.estimated' },
  ];

  readonly valleyRunConditions: Array<{ value: number; translationString: string }> = [
    { value: 701, translationString: 'stnet.phrase.condition.good' },
    { value: 702, translationString: 'stnet.phrase.condition.fair' },
    { value: 703, translationString: 'stnet.phrase.condition.closed' },
  ];

  readonly snowConditions: Array<{ value: number; translationString: string }> = [
    { value: 900, translationString: 'stnet.phrase.condition.noInfo' },
    { value: 901, translationString: 'stnet.phrase.condition.powder' },
    { value: 905, translationString: 'stnet.phrase.condition.hard' },
    { value: 909, translationString: 'stnet.phrase.condition.sulz' },
    { value: 913, translationString: 'stnet.phrase.condition.wet' },
  ];

  readonly trailConditions: Array<{ value: number; translationString: string }> = [
    { value: 1201, translationString: 'stnet.phrase.condition.good' },
    { value: 1202, translationString: 'stnet.phrase.condition.accessible' },
    { value: 1203, translationString: 'stnet.phrase.condition.closed' },
    { value: 1204, translationString: 'stnet.phrase.condition.eos' },
  ];

  readonly snowparkConditions: Array<{ value: number; translationString: string }> = [
    { value: 1100, translationString: 'stnet.phrase.condition.prepared' },
    { value: 1101, translationString: 'stnet.phrase.condition.closed' },
    { value: 1102, translationString: 'stnet.phrase.condition.eos' },
    { value: 1104, translationString: 'stnet.phrase.condition.open' },
    { value: 1105, translationString: 'stnet.phrase.condition.notPrepared' },
  ];

  readonly tobogganRunSkills: Array<{ value: number; translationString: string }> = [
    { value: 1007, translationString: 'stnet.phrase.skills.noInfo' },
    { value: 1000, translationString: 'stnet.phrase.skills.novice' },
    { value: 1001, translationString: 'stnet.phrase.skills.noviceAndIntermediate' },
    { value: 1002, translationString: 'stnet.phrase.skills.noviceAndAdvanced' },
    { value: 1003, translationString: 'stnet.phrase.skills.intermediate' },
    { value: 1004, translationString: 'stnet.phrase.skills.intermediateAndAdvanced' },
    { value: 1005, translationString: 'stnet.phrase.skills.advanced' },
  ];

  @Input() stnetItem: STnetItem;
  @Input() stnetDisplayedItem: STnetItem;
  @Input() calculatedData: STnetCalculatedData;
  @Output() formDataValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formDataModified: EventEmitter<boolean> = new EventEmitter<boolean>();

  writePermission: boolean;
  bigScreenMode: boolean;
  formGroup: FormGroup;
  formControls: {
    [key: string]: AbstractControl;
  };
  rerender: boolean = false;
  iconPath: string;
  nightLightEnabled: boolean;
  sisMediaSetting: SisMediaSetting;

  constructor(
    private destinationService: DestinationService,
    private screenSizeService: ScreenSizeService,
    private translateService: TranslateService,
    private sisMediaSettingService: SisMediaSettingService
  ) {
    super();
  }

  ngOnInit() {
    this.iconPath = `${environment.baseUrlCockpitBlobStorage}/public-assets/icons/sismedia/`;

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((screenMode) => (this.bigScreenMode = screenMode));

    this.sisMediaSettingService.sisMediaSetting$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((sisMediaSetting) => (this.sisMediaSetting = sisMediaSetting));

    this.nightLightEnabled =
      this.calculatedData.slopesTotal > 0 ||
      this.calculatedData.classicTracksLengthMeterTotal > 0 ||
      this.calculatedData.skatingTracksLengthMeterTotal > 0 ||
      this.calculatedData.halfpipesTotal > 0 ||
      this.calculatedData.snowcrossTracksTotal > 0 ||
      this.calculatedData.snowparksTotal > 0 ||
      this.calculatedData.airboardSlopesTotal > 0 ||
      this.calculatedData.snowtubeTracksTotal > 0 ||
      this.calculatedData.tobogganRunsTotal > 0;

    this.formGroup = new FormGroup(
      {
        // snow
        snowConditionMainId: new FormControl(),
        snowConditionPartialId: new FormControl(),
        // slope
        slopeConditionId: new FormControl(),
        slopesSnowedLengthMeterToday: new FormControl('', [
          Validators.min(0),
          Validators.max(1000000),
          Validators.pattern(/^\d*$/),
        ]),
        snowHeightMeasurementMethodId: new FormControl(),
        // valley run
        valleyRunMainConditionId: new FormControl(),
        valleyRunPartialConditionId: new FormControl(),
        valleyRunLowestAltitudeToday: new FormControl('', [
          Validators.min(0),
          Validators.max(5000),
          Validators.pattern(/^\d*$/),
        ]),
        // cross country
        classicTrackConditionId: new FormControl(),
        skatingTrackConditionId: new FormControl(),
        crossCountryTracksLowestAltitudeToday: new FormControl('', [
          Validators.min(0),
          Validators.max(5000),
          Validators.pattern(/^\d*$/),
        ]),
        crossCountryTracksHighestAltitudeToday: new FormControl('', [
          Validators.min(0),
          Validators.max(5000),
          Validators.pattern(/^\d*$/),
        ]),
        // snow park
        snowparkConditionId: new FormControl(),
        // toboggan
        tobogganRunConditionId: new FormControl(),
        tobogganRunSkillTypeId: new FormControl(),
        // winter hiking
        winterHikingTrailConditionId: new FormControl(),
        winterHikingTrailsLowestAltitudeTotal: new FormControl('', [
          Validators.min(0),
          Validators.max(5000),
          Validators.pattern(/^\d*$/),
        ]),
        // flood lighting
        slopesFloodlightEnabled: new FormControl(),
        slopesFloodlightUntil: this.slopesFloodlightUntilFormControl,
        slopesWithFloodlightLengthMeterToday: this.slopesWithFloodlightLengthMeterTodayFormControl,
        crossCountryFloodlightEnabled: new FormControl(),
        crossCountryFloodlightUntil: this.crossCountryFloodlightUntilFormControl,
        crossCountryTracksWithFloodlightLengthMeterToday:
          this.crossCountryTracksWithFloodlightLengthMeterTodayFormControl,
        snowparkFloodlightEnabled: new FormControl(),
        snowparkFloodlightUntil: this.snowparkFloodlightUntilFormControl,
        tobogganFloodlightEnabled: new FormControl(),
        tobogganRunsWithFloodlightLengthMeterToday: this.tobogganRunsWithFloodlightLengthMeterTodayFormControl,
      },
      {
        validators: [this.validateCrossCountryTracksAltitudeToday()],
      }
    );

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_STNET, FeatureAccessLevel.WRITE))
      );

      if (this.writePermission) {
        this.formGroup.enable();
      } else {
        this.formGroup.disable();
      }

      if (this.stnetDisplayedItem) {
        this.setValues(this.stnetDisplayedItem);
      }
    });

    this.formControls = this.formGroup.controls;

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      const changedSTnetItem: STnetItem = this.formGroup.getRawValue();
      if (this.stnetDisplayedItem) {
        // snow
        this.stnetDisplayedItem.snowConditionMainId = changedSTnetItem.snowConditionMainId;
        this.stnetDisplayedItem.snowConditionPartialId = changedSTnetItem.snowConditionPartialId;
        // slope
        this.stnetDisplayedItem.slopeConditionId = changedSTnetItem.slopeConditionId;
        this.stnetDisplayedItem.slopesSnowedLengthMeterToday = this.getNumberOrNull(
          changedSTnetItem.slopesSnowedLengthMeterToday
        );
        this.stnetDisplayedItem.snowHeightMeasurementMethodId = changedSTnetItem.snowHeightMeasurementMethodId;
        // valley run
        this.stnetDisplayedItem.valleyRunMainConditionId = changedSTnetItem.valleyRunMainConditionId;
        this.stnetDisplayedItem.valleyRunPartialConditionId = changedSTnetItem.valleyRunPartialConditionId;
        this.stnetDisplayedItem.valleyRunLowestAltitudeToday = this.getNumberOrNull(
          changedSTnetItem.valleyRunLowestAltitudeToday
        );
        // cross country
        this.stnetDisplayedItem.classicTrackConditionId = changedSTnetItem.classicTrackConditionId;
        this.stnetDisplayedItem.skatingTrackConditionId = changedSTnetItem.skatingTrackConditionId;
        this.stnetDisplayedItem.crossCountryTracksLowestAltitudeToday = this.getNumberOrNull(
          changedSTnetItem.crossCountryTracksLowestAltitudeToday
        );
        this.stnetDisplayedItem.crossCountryTracksHighestAltitudeToday = this.getNumberOrNull(
          changedSTnetItem.crossCountryTracksHighestAltitudeToday
        );
        // snow park
        this.stnetDisplayedItem.snowparkConditionId = changedSTnetItem.snowparkConditionId;
        // toboggan
        this.stnetDisplayedItem.tobogganRunConditionId = changedSTnetItem.tobogganRunConditionId;
        this.stnetDisplayedItem.tobogganRunSkillTypeId = changedSTnetItem.tobogganRunSkillTypeId;
        // winter hiking
        this.stnetDisplayedItem.winterHikingTrailConditionId = changedSTnetItem.winterHikingTrailConditionId;
        this.stnetDisplayedItem.winterHikingTrailsLowestAltitudeTotal = this.getNumberOrNull(
          changedSTnetItem.winterHikingTrailsLowestAltitudeTotal
        );
        // flood light
        this.stnetDisplayedItem.slopesFloodlightEnabled = changedSTnetItem.slopesFloodlightEnabled;
        this.stnetDisplayedItem.slopesFloodlightUntil = changedSTnetItem.slopesFloodlightUntil ?? null;
        this.stnetDisplayedItem.slopesWithFloodlightLengthMeterToday = this.getNumberOrNull(
          changedSTnetItem.slopesWithFloodlightLengthMeterToday
        );
        this.stnetDisplayedItem.crossCountryFloodlightEnabled = changedSTnetItem.crossCountryFloodlightEnabled;
        this.stnetDisplayedItem.crossCountryFloodlightUntil = changedSTnetItem.crossCountryFloodlightUntil ?? null;
        this.stnetDisplayedItem.crossCountryTracksWithFloodlightLengthMeterToday = this.getNumberOrNull(
          changedSTnetItem.crossCountryTracksWithFloodlightLengthMeterToday
        );
        this.stnetDisplayedItem.snowparkFloodlightEnabled = changedSTnetItem.snowparkFloodlightEnabled;
        this.stnetDisplayedItem.snowparkFloodlightUntil = changedSTnetItem.snowparkFloodlightUntil ?? null;
        this.stnetDisplayedItem.tobogganFloodlightEnabled = changedSTnetItem.tobogganFloodlightEnabled;
        this.stnetDisplayedItem.tobogganRunsWithFloodlightLengthMeterToday = this.getNumberOrNull(
          changedSTnetItem.tobogganRunsWithFloodlightLengthMeterToday
        );

        this.formDataValid.emit(this.formGroup.valid);
        this.formDataModified.emit(!this.stNetItemsEquals(this.stnetItem, this.stnetDisplayedItem));
      }
    });

    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.rerender = true;
      setTimeout(() => {
        this.rerender = false;
      }, 50);
    });
  }

  ngOnChanges(): void {
    if (this.stnetDisplayedItem) {
      this.setValues(this.stnetDisplayedItem);
    }
  }

  validateCrossCountryTracksAltitudeToday(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fromControl = control.get('crossCountryTracksLowestAltitudeToday');
      const toControl = control.get('crossCountryTracksHighestAltitudeToday');

      const from = Number.parseInt(fromControl.value);
      const to = Number.parseInt(toControl.value);

      return !isNaN(from) && !isNaN(to) && from >= to ? { crossCountryTracksAltitudeToday: true } : null;
    };
  }

  private setValues(stnetItem: STnetItem): void {
    this.formGroup?.setValue(
      {
        // snow
        snowConditionMainId: stnetItem.snowConditionMainId,
        snowConditionPartialId: stnetItem.snowConditionPartialId,
        // slope
        slopeConditionId: stnetItem.slopeConditionId,
        slopesSnowedLengthMeterToday: stnetItem.slopesSnowedLengthMeterToday,
        snowHeightMeasurementMethodId: stnetItem.snowHeightMeasurementMethodId,
        // valley run
        valleyRunMainConditionId: stnetItem.valleyRunMainConditionId,
        valleyRunPartialConditionId: stnetItem.valleyRunPartialConditionId,
        valleyRunLowestAltitudeToday: stnetItem.valleyRunLowestAltitudeToday,
        // cross country
        classicTrackConditionId: stnetItem.classicTrackConditionId,
        skatingTrackConditionId: stnetItem.skatingTrackConditionId,
        crossCountryTracksLowestAltitudeToday: stnetItem.crossCountryTracksLowestAltitudeToday,
        crossCountryTracksHighestAltitudeToday: stnetItem.crossCountryTracksHighestAltitudeToday,
        // snow park
        snowparkConditionId: stnetItem.snowparkConditionId,
        // toboggan
        tobogganRunConditionId: stnetItem.tobogganRunConditionId,
        tobogganRunSkillTypeId: stnetItem.tobogganRunSkillTypeId,
        // winter hiking
        winterHikingTrailConditionId: stnetItem.winterHikingTrailConditionId,
        winterHikingTrailsLowestAltitudeTotal: stnetItem.winterHikingTrailsLowestAltitudeTotal,
        // flood light
        slopesFloodlightEnabled: stnetItem.slopesFloodlightEnabled,
        slopesFloodlightUntil: stnetItem.slopesFloodlightUntil ?? null,
        slopesWithFloodlightLengthMeterToday: stnetItem.slopesWithFloodlightLengthMeterToday,
        crossCountryFloodlightEnabled: stnetItem.crossCountryFloodlightEnabled,
        crossCountryFloodlightUntil: stnetItem.crossCountryFloodlightUntil ?? null,
        crossCountryTracksWithFloodlightLengthMeterToday: stnetItem.crossCountryTracksWithFloodlightLengthMeterToday,
        snowparkFloodlightEnabled: stnetItem.snowparkFloodlightEnabled,
        snowparkFloodlightUntil: stnetItem.snowparkFloodlightUntil ?? null,
        tobogganFloodlightEnabled: stnetItem.tobogganFloodlightEnabled,
        tobogganRunsWithFloodlightLengthMeterToday: stnetItem.tobogganRunsWithFloodlightLengthMeterToday,
      },
      { emitEvent: false }
    );

    this.tobogganFloodlightEnabled(stnetItem.tobogganFloodlightEnabled);
    this.crossCountryFloodlightEnabled(stnetItem.crossCountryFloodlightEnabled);
    this.slopesFloodlightEnabled(stnetItem.slopesFloodlightEnabled);
    this.snowparkFloodlightEnabled(stnetItem.snowparkFloodlightEnabled);
  }

  crossCountryFloodlightEnabled(enabled: boolean) {
    if (enabled && this.writePermission) {
      this.crossCountryTracksWithFloodlightLengthMeterTodayFormControl.enable();
      this.crossCountryFloodlightUntilFormControl.enable();
    } else {
      this.crossCountryTracksWithFloodlightLengthMeterTodayFormControl.disable();
      this.crossCountryFloodlightUntilFormControl.disable();
    }
  }

  tobogganFloodlightEnabled(enabled: boolean) {
    if (enabled && this.writePermission) {
      this.tobogganRunsWithFloodlightLengthMeterTodayFormControl.enable();
    } else {
      this.tobogganRunsWithFloodlightLengthMeterTodayFormControl.disable();
    }
  }

  slopesFloodlightEnabled(enabled: boolean) {
    if (enabled && this.writePermission) {
      this.slopesFloodlightUntilFormControl.enable();
      this.slopesWithFloodlightLengthMeterTodayFormControl.enable();
    } else {
      this.slopesFloodlightUntilFormControl.disable();
      this.slopesWithFloodlightLengthMeterTodayFormControl.disable();
    }
  }

  snowparkFloodlightEnabled(enabled: boolean) {
    if (enabled && this.writePermission) {
      this.snowparkFloodlightUntilFormControl.enable();
    } else {
      this.snowparkFloodlightUntilFormControl.disable();
    }
  }

  private getNumberOrNull(item: string | number): number {
    if (item == null) {
      return null;
    }

    if (typeof item === 'number') {
      return item;
    }

    if (!item?.match(/^\d+$/)) {
      return NaN;
    }

    return Number.parseInt(item);
  }

  private stNetItemsEquals(item: STnetItem, otherItem: STnetItem): boolean {
    return (
      // snow
      item.snowConditionMainId === otherItem.snowConditionMainId &&
      item.snowConditionPartialId === otherItem.snowConditionPartialId &&
      // slope
      item.slopeConditionId === otherItem.slopeConditionId &&
      item.slopesSnowedLengthMeterToday === otherItem.slopesSnowedLengthMeterToday &&
      item.snowHeightMeasurementMethodId === otherItem.snowHeightMeasurementMethodId &&
      // valley run
      item.valleyRunMainConditionId === otherItem.valleyRunMainConditionId &&
      item.valleyRunPartialConditionId === otherItem.valleyRunPartialConditionId &&
      item.valleyRunLowestAltitudeToday === otherItem.valleyRunLowestAltitudeToday &&
      // cross country
      item.classicTrackConditionId === otherItem.classicTrackConditionId &&
      item.skatingTrackConditionId === otherItem.skatingTrackConditionId &&
      item.crossCountryTracksLowestAltitudeToday === otherItem.crossCountryTracksLowestAltitudeToday &&
      item.crossCountryTracksHighestAltitudeToday === otherItem.crossCountryTracksHighestAltitudeToday &&
      // snow park
      item.snowparkConditionId === otherItem.snowparkConditionId &&
      // toboggan
      item.tobogganRunConditionId === otherItem.tobogganRunConditionId &&
      item.tobogganRunSkillTypeId === otherItem.tobogganRunSkillTypeId &&
      // winter hiking
      item.winterHikingTrailConditionId === otherItem.winterHikingTrailConditionId &&
      item.winterHikingTrailsLowestAltitudeTotal === otherItem.winterHikingTrailsLowestAltitudeTotal &&
      // flood light
      item.slopesFloodlightEnabled === otherItem.slopesFloodlightEnabled &&
      item.slopesFloodlightUntil === otherItem.slopesFloodlightUntil &&
      item.slopesWithFloodlightLengthMeterToday === otherItem.slopesWithFloodlightLengthMeterToday &&
      item.crossCountryFloodlightEnabled === otherItem.crossCountryFloodlightEnabled &&
      item.crossCountryFloodlightUntil === otherItem.crossCountryFloodlightUntil &&
      item.crossCountryTracksWithFloodlightLengthMeterToday ===
        otherItem.crossCountryTracksWithFloodlightLengthMeterToday &&
      item.snowparkFloodlightEnabled === otherItem.snowparkFloodlightEnabled &&
      item.snowparkFloodlightUntil === otherItem.snowparkFloodlightUntil &&
      item.tobogganFloodlightEnabled === otherItem.tobogganFloodlightEnabled &&
      item.tobogganRunsWithFloodlightLengthMeterToday === otherItem.tobogganRunsWithFloodlightLengthMeterToday
    );
  }
}
