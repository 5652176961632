<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widget_Hintergrund_temp" data-name="Widget Hintergrund temp">
      <rect width="187.5" height="225" style="fill: none" />
    </g>
    <g id="Widgets">
      <g>
        <rect y="0" x="0" id="rect867" style="fill: none" height="225" width="187.5" />
        <path
          style="fill: #91cddb"
          d="M 91.779297 45.029297 A 67.5 67.5 0 0 0 26.25 112.5 L 56.371094 112.5 A 37.5 37.5 0 0 1 56.369141 112.43945 A 37.5 37.5 0 0 1 93.869141 74.939453 A 37.5 37.5 0 0 1 131.36914 112.43945 A 37.5 37.5 0 0 1 131.36523 112.5 L 161.25 112.5 A 67.5 67.5 0 0 0 91.779297 45.029297 z "
        />
        <path
          style="fill: #bccbd4"
          d="M 41.150391 70.199219 A 67.19 67.19 0 0 0 26.25 112.5 L 56.371094 112.5 A 37.5 37.5 0 0 1 56.369141 112.43945 A 37.5 37.5 0 0 1 64.597656 89.056641 L 41.150391 70.199219 z "
        />
        <path
          style="fill: #989898"
          d="M 146.35938 70.199219 L 123.06641 88.927734 A 37.5 37.5 0 0 1 131.36914 112.43945 A 37.5 37.5 0 0 1 131.36523 112.5 L 161.25 112.5 A 67.18 67.18 0 0 0 146.35938 70.199219 z "
        />
        <g>
          <g>
            <path
              d="m 108.68,110.6 1.47,17.58 -1.47,17.58 a 4.19,4.19 0 0 1 -4.18,4.18 H 83 a 4.19,4.19 0 0 1 -4.18,-4.18 L 77.34,128.18 78.82,110.6 A 4.19,4.19 0 0 1 83,106.42 h 21.5 a 4.19,4.19 0 0 1 4.18,4.18 z"
              style="
                fill: none;
                stroke: #32849a;
                stroke-width: 1.99938917px;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <line
              x1="93.75"
              y1="106.42"
              x2="93.75"
              y2="90"
              style="
                fill: none;
                stroke: #32849a;
                stroke-width: 1.99938917px;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <polygon
              points="104.72,128.18 103.37,112.08 84.13,112.08 82.78,128.18 "
              style="
                fill: none;
                stroke: #32849a;
                stroke-width: 1.99938917px;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
            <line
              x1="93.75"
              y1="128.17999"
              x2="93.75"
              y2="144.27"
              style="
                fill: none;
                stroke: #32849a;
                stroke-width: 1.99938917px;
                stroke-linecap: round;
                stroke-linejoin: round;
              "
            />
          </g>
          <line
            x1="72.650002"
            y1="129.78"
            x2="52.91"
            y2="129.78"
            style="
              fill: none;
              stroke: #32849a;
              stroke-width: 1.99938917px;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            x1="73.199997"
            y1="121.96"
            x2="60.189999"
            y2="121.96"
            style="
              fill: none;
              stroke: #32849a;
              stroke-width: 1.99938917px;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
          <line
            x1="73.580002"
            y1="137.59"
            x2="60.560001"
            y2="137.59"
            style="
              fill: none;
              stroke: #32849a;
              stroke-width: 1.99938917px;
              stroke-linecap: round;
              stroke-linejoin: round;
            "
          />
        </g>
        <g id="Widgettexte">
          <text
            [attr.x]="textPositionX"
            [attr.y]="textPositionY"
            id="value"
            style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
            [style.font-size]="fontSize"
          >
            {{ actualSpeedMps }}
            <tspan style="font-size: 24px" id="unit">m/s</tspan>
          </text>
        </g>
        <path
          id="Zeiger"
          [attr.transform]="'rotate(' + rotationDeg + ', 93.75, 112.5)'"
          style="
            opacity: 1;
            fill: #000000;
            fill-opacity: 0.99356914;
            stroke-width: 7.65733099;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
          "
          d="M 9.390625 110.69922 L 9.390625 114.29688 L 56.435547 114.29688 A 37.5 37.5 0 0 1 56.369141 112.43945 A 37.5 37.5 0 0 1 56.457031 110.69922 L 9.390625 110.69922 z "
          transform="rotate( 0 , 93.75, 112.5)"
        />
      </g>
    </g>
  </g>
</svg>
