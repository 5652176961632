import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { IconHelper } from 'src/app/core/icon-helper/icon-helper';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { SisMediaAssetCategory } from 'src/app/sismedia/domain/sismedia-asset-category.enum';
import { SisMediaAssetEdit } from 'src/app/sismedia/domain/sismedia-asset-edit.model';
import { SisMediaAssetImportType } from 'src/app/sismedia/domain/sismedia-asset-import-type.enum';
import { SisMediaAssetStatus } from 'src/app/sismedia/domain/sismedia-asset-status.enum';
import { SisMediaItem } from 'src/app/sismedia/domain/sismedia-item.model';
import { SisMediaSeason } from 'src/app/sismedia/domain/sismedia-season.enum';
import { SisMediaEditModalFieldOptions } from 'src/app/sismedia/sismedia-edit-modal/sismedia-edit-modal-field-options.model';
import { SisMediaItemService } from 'src/app/sismedia/sismedia-item/sismedia-item.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-sismedia-edit-modal',
  templateUrl: './sismedia-edit-modal.component.html',
  styleUrls: ['./sismedia-edit-modal.component.scss'],
})
export class SisMediaEditModalComponent extends Unsubscriber implements OnInit {
  private static readonly defaultFieldOptions = [
    {
      propertyName: 'name',
      maxLength: 100,
      inputType: 'text',
      inputLabelTranslateKey: 'sismediaEdit.term.nameDe',
      dataTestKey: 'sismedia-edit-name-de-input',
      required: true,
      includedInImports: [SisMediaAssetImportType.Toubiz],
    },
    {
      propertyName: 'nameEn',
      maxLength: 100,
      inputType: 'text',
      inputLabelTranslateKey: 'sismediaEdit.term.nameEn',
      dataTestKey: 'sismedia-edit-name-en-input',
    },
    {
      propertyName: 'info',
      maxLength: 300,
      inputType: 'textarea',
      inputLabelTranslateKey: 'sismediaEdit.term.info',
      dataTestKey: 'sismedia-edit-info-input',
      includedInImports: [SisMediaAssetImportType.Toubiz],
    },
    {
      propertyName: 'custom',
      maxLength: 100,
      inputType: 'text',
      inputLabelTranslateKey: 'sismediaEdit.term.custom',
      dataTestKey: 'sismedia-edit-custom-input',
    },
  ];

  private static readonly inputFieldOptionsMap: Map<SisMediaAssetCategory, SisMediaEditModalFieldOptions[]> = new Map([
    [
      SisMediaAssetCategory.Lift,
      [
        ...SisMediaEditModalComponent.defaultFieldOptions,
        {
          propertyName: 'label',
          maxLength: 5,
          inputType: 'text',
          inputLabelTranslateKey: 'sismediaEdit.term.label',
          dataTestKey: 'sismedia-edit-label-input',
        },
        {
          propertyName: 'firstRide',
          maxLength: 5,
          inputType: 'time',
          inputLabelTranslateKey: 'sismediaEdit.term.firstRide',
        },
        {
          propertyName: 'lastRide',
          maxLength: 5,
          inputType: 'time',
          inputLabelTranslateKey: 'sismediaEdit.term.lastRide',
        },
        {
          propertyName: 'travelTime',
          maxLength: 5,
          inputType: 'number',
          inputLabelTranslateKey: 'sismediaEdit.term.travelTime',
        },
        {
          propertyName: 'throughput',
          maxLength: 6,
          inputType: 'number',
          inputLabelTranslateKey: 'sismediaEdit.term.throughput',
        },
        {
          propertyName: 'website',
          maxLength: 0,
          inputType: 'website',
          inputLabelTranslateKey: 'sismedia.term.website',
        },
      ],
    ],
    [
      SisMediaAssetCategory.Slope,
      [
        ...SisMediaEditModalComponent.defaultFieldOptions,
        {
          propertyName: 'label',
          maxLength: 5,
          inputType: 'text',
          inputLabelTranslateKey: 'sismediaEdit.term.label',
          dataTestKey: 'sismedia-edit-label-input',
        },
        {
          propertyName: 'lengthMeter',
          maxLength: 6,
          inputType: 'number',
          inputLabelTranslateKey: 'sismediaEdit.term.lengthMeter',
        },
        {
          propertyName: 'website',
          maxLength: 0,
          inputType: 'website',
          inputLabelTranslateKey: 'sismedia.term.website',
        },
      ],
    ],
    [
      SisMediaAssetCategory.Poi,
      [
        ...SisMediaEditModalComponent.defaultFieldOptions,
        {
          propertyName: 'website',
          maxLength: 0,
          inputType: 'website',
          inputLabelTranslateKey: 'sismedia.term.website',
        },
      ],
    ],
    [
      SisMediaAssetCategory.Gastro,
      [
        ...SisMediaEditModalComponent.defaultFieldOptions,
        {
          propertyName: 'contact',
          maxLength: 100,
          inputType: 'text',
          inputLabelTranslateKey: 'sismedia.term.contact',
          dataTestKey: 'sismedia-edit-contact-input',
          includedInImports: [SisMediaAssetImportType.Toubiz],
        },
        {
          propertyName: 'location',
          maxLength: 100,
          inputType: 'text',
          inputLabelTranslateKey: 'sismedia.term.location',
          dataTestKey: 'sismedia-edit-location-input',
          includedInImports: [SisMediaAssetImportType.Toubiz],
        },
        {
          propertyName: 'phone',
          maxLength: 0,
          inputType: 'tel',
          inputLabelTranslateKey: 'sismedia.term.phone',
          dataTestKey: 'sismedia-edit-phone-input',
          includedInImports: [SisMediaAssetImportType.Toubiz],
        },
        {
          propertyName: 'email',
          maxLength: 0,
          inputType: 'email',
          inputLabelTranslateKey: 'sismedia.term.email',
          dataTestKey: 'sismedia-edit-email-input',
          includedInImports: [SisMediaAssetImportType.Toubiz],
        },
        {
          propertyName: 'website',
          maxLength: 0,
          inputType: 'website',
          inputLabelTranslateKey: 'sismedia.term.website',
          dataTestKey: 'sismedia-edit-website-input',
          includedInImports: [SisMediaAssetImportType.Toubiz],
        },
      ],
    ],
    [
      SisMediaAssetCategory.Trail,
      [
        ...SisMediaEditModalComponent.defaultFieldOptions,
        {
          propertyName: 'label',
          maxLength: 5,
          inputType: 'text',
          inputLabelTranslateKey: 'sismediaEdit.term.label',
          dataTestKey: 'sismedia-edit-label-input',
        },
        {
          propertyName: 'lengthMeter',
          maxLength: 6,
          inputType: 'number',
          inputLabelTranslateKey: 'sismediaEdit.term.lengthMeter',
        },
        {
          propertyName: 'elevation',
          maxLength: 5,
          inputType: 'number',
          inputLabelTranslateKey: 'sismediaEdit.term.elevation',
        },
        {
          propertyName: 'duration',
          maxLength: 5,
          inputType: 'number',
          inputLabelTranslateKey: 'sismediaEdit.term.duration',
        },
        {
          propertyName: 'website',
          maxLength: 0,
          inputType: 'website',
          inputLabelTranslateKey: 'sismedia.term.website',
        },
      ],
    ],
  ]);

  private static readonly seasonIcons = new Map<
    SisMediaSeason,
    { iconName: string; iconNameDisabled: string; translateKey: string }
  >([
    [
      SisMediaSeason.SummerAndWinter,
      {
        iconName: 'sis-summer-and-winter-black',
        iconNameDisabled: 'sis-summer-and-winter-grey',
        translateKey: 'sismediaEdit.term.summerAndWinter',
      },
    ],
    [
      SisMediaSeason.Summer,
      {
        iconName: 'sis-summer-black',
        iconNameDisabled: 'sis-summer-grey',
        translateKey: 'sismediaEdit.term.summer',
      },
    ],
    [
      SisMediaSeason.Winter,
      {
        iconName: 'sis-winter-black',
        iconNameDisabled: 'sis-winter-grey',
        translateKey: 'sismediaEdit.term.winter',
      },
    ],
  ]);

  @Input() isNewItem: boolean;
  @Input() sisMediaItem: SisMediaItem;
  @Input() category: string;

  inputFieldOptions: SisMediaEditModalFieldOptions[];
  sisMediaItemEdit: SisMediaItem;
  seasonIcon: { iconName: string; iconNameDisabled: string; translateKey: string };

  writePermission = false;
  isSaving = false;
  hasUnsavedChanges = false;
  deleteAllowed = false;
  deleteAllowedTooltip: string;

  hasStatus: boolean;
  typeIcon: string;

  titleTranslationString: string;
  formGroup: FormGroup;

  importType?: SisMediaAssetImportType;
  importTypeNameTranslationString: string;

  private hasMySqlRouter: boolean;

  readonly sisMediaAssetTypes$ = this.sisMediaItemService.sisMediaTypes$.pipe(
    map((types) => types.get(this.sisMediaItem.assetCategory))
  );
  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;
  readonly hasMySqlRouter$ = this.destinationService.selectedTenant$.pipe(map((tenant) => tenant.hasMySqlRouter));

  constructor(
    private modalCtrl: ModalController,
    private sisMediaItemService: SisMediaItemService,
    private destinationService: DestinationService,
    private screenSizeService: ScreenSizeService,
    private confirmationDialogService: ConfirmationDialogService,
    private userMessageService: UserMessageService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.sisMediaItem) {
      this.importType = this.sisMediaItem.importType;
      if (this.importType != null) {
        this.importTypeNameTranslationString =
          'sismedia.phrase.imported.' + SisMediaAssetImportType[this.importType].toLowerCase();
      }

      this.sisMediaItemEdit = JSON.parse(JSON.stringify(this.sisMediaItem));

      this.hasStatus = this.sisMediaItemEdit.status != SisMediaAssetStatus.NoStatus;
      this.seasonIcon = SisMediaEditModalComponent.seasonIcons.get(this.sisMediaItemEdit.season);

      this.inputFieldOptions = SisMediaEditModalComponent.inputFieldOptionsMap.get(this.sisMediaItemEdit.assetCategory);

      this.formGroup = new FormGroup({
        hasStatus: new FormControl<boolean>(this.hasStatus),
        season: new FormControl<number>(this.sisMediaItemEdit.season, [Validators.required]),
        type: new FormControl<string>(this.sisMediaItemEdit.type, [Validators.required]),
      });

      this.inputFieldOptions.forEach((option) => {
        const formControl = new FormControl(
          this.sisMediaItemEdit[option.propertyName],
          option.required ? [Validators.required] : []
        );

        this.formGroup.addControl(option.propertyName, formControl);
      });

      this.updateTypeIcon();

      this.deleteAllowed =
        this.sisMediaItemEdit.exports?.length === 0 && this.sisMediaItemEdit.sisMapNames?.length === 0;

      if (this.deleteAllowed) {
        this.deleteAllowedTooltip = '';
      } else {
        this.deleteAllowedTooltip = this.translateService.instant('sismediaEdit.phrase.cannotDelete') + ':';
        if (this.sisMediaItemEdit.exports?.length > 0) {
          this.deleteAllowedTooltip += '\n - ' + this.translateService.instant('sismediaEdit.phrase.partOfExport');
        }
        if (this.sisMediaItem.sisMapNames?.length > 0) {
          this.deleteAllowedTooltip += '\n - ' + this.translateService.instant('sismediaEdit.phrase.shownOnSisMap');
        }
      }

      this.titleTranslationString = this.sisMediaItemEdit.id
        ? `sismediaEdit.term.${this.category}.edit`
        : `sismediaEdit.term.${this.category}.new`;

      this.setValues();

      this.formGroup.valueChanges.pipe(debounceTime(50), takeUntil(this.onDestroy$)).subscribe((value) => {
        if (value.hasStatus === true) {
          if (this.sisMediaItemEdit.status === SisMediaAssetStatus.NoStatus) {
            this.sisMediaItemEdit.status = SisMediaAssetStatus.Disabled;
          }
        } else {
          if (value.hasStatus === false) {
            this.sisMediaItemEdit.status = SisMediaAssetStatus.NoStatus;
          }
        }

        this.hasStatus = this.sisMediaItemEdit.status != SisMediaAssetStatus.NoStatus;

        this.seasonIcon = SisMediaEditModalComponent.seasonIcons.get(value.season) ?? {
          iconName: '',
          iconNameDisabled: '',
          translateKey: '',
        };

        this.sisMediaItemEdit.type = value.type;
        this.sisMediaItemEdit.season = value.season;

        this.inputFieldOptions
          .filter((o) => o.includedInImports == null || !o.includedInImports.includes(this.importType))
          .forEach((option) => {
            let newValue = value[option.propertyName];

            if (newValue === '' || newValue == null) {
              newValue = null;
            } else {
              if (option.inputType === 'number') {
                newValue = Number.parseInt(newValue);
              }
              if (option.inputType === 'time') {
                newValue = newValue.padEnd(8, ':00');
              }
            }

            this.sisMediaItemEdit[option.propertyName] = newValue;
          });

        this.updateTypeIcon();

        this.checkForChanges();
      });
    }

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_ASSET_EDIT, FeatureAccessLevel.WRITE))
      );
      this.setDisabled();
    });

    this.destinationService.selectedTenant$
      .pipe(
        map((tenant) => tenant.hasMySqlRouter),
        takeUntil(this.onDestroy$)
      )
      .subscribe((hasMySqlRouter) => {
        this.hasMySqlRouter = hasMySqlRouter;
        this.setDisabled();
      });
  }

  async save(): Promise<void> {
    if (this.formGroup.invalid || !this.hasUnsavedChanges) {
      return;
    }

    this.isSaving = true;

    this.setDisabled();

    // update status for existing assets
    if (!this.isNewItem && this.sisMediaItemEdit.status !== this.sisMediaItem.status) {
      await this.sisMediaItemService.updateStatus(
        this.sisMediaItemEdit.assetGuid,
        this.sisMediaItemEdit.assetCategory,
        this.sisMediaItemEdit.status
      );
    }

    // insert new or update existing item except status
    await this.sisMediaItemService.insertOrUpdateItems(
      [this.sisMediaItemEdit],
      SisMediaItemService.insertOrUpdateOperation
    );

    await this.modalCtrl.dismiss();
  }

  async delete(): Promise<void> {
    const confirmed = await this.confirmationDialogService.presentAlert(
      'sismediaEdit.phrase.deleteAsset',
      'general.term.delete'
    );
    if (confirmed) {
      const postData: SisMediaAssetEdit = {
        assetGuid: this.sisMediaItemEdit.assetGuid,
        name: this.sisMediaItemEdit.name,
        assetCategory: this.sisMediaItemEdit.assetCategory,
      };
      await this.sisMediaItemService.insertOrUpdateItems([postData], SisMediaItemService.deleteOperation);
      await this.modalCtrl.dismiss();
    }
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  async copyToClipboard(text: string): Promise<void> {
    await navigator.clipboard.writeText(text);
    const toast = await this.userMessageService.createSingleToast(
      new UserMessage({
        message: 'general.phrase.copiedToClipboard',
        icon: UserMessageIcon.info,
        durationMs: 2000,
        position: 'top',
        color: UserMessageColor.white,
      })
    );
    await toast.present();
  }

  private checkForChanges(): void {
    this.hasUnsavedChanges = JSON.stringify(this.sisMediaItem) !== JSON.stringify(this.sisMediaItemEdit);
  }

  private updateTypeIcon(): void {
    const typeIconName = IconHelper.getIconName(this.sisMediaItemEdit.type);
    this.typeIcon =
      this.sisMediaItemEdit.assetCategory === SisMediaAssetCategory.Poi
        ? `${environment.baseUrlPublicBlobStorage}/public-assets/icons/sismedia/styles/sisag/${typeIconName.substring(
            4
          )}.svg`
        : `${environment.baseUrlCockpitBlobStorage}/public-assets/icons/sismedia/${typeIconName}.svg`;
  }

  private setValues(): void {
    this.formGroup.controls.season.setValue(this.sisMediaItemEdit.season);
    this.formGroup.controls.type.setValue(this.sisMediaItemEdit.type);

    this.inputFieldOptions.forEach((option) => {
      this.formGroup.controls[option.propertyName].setValue(this.sisMediaItemEdit[option.propertyName]);
    });
  }

  private setDisabled(): void {
    if (!this.writePermission || this.isSaving) {
      this.formGroup.controls.type.disable();
      this.inputFieldOptions.forEach((option) => {
        this.formGroup.controls[option.propertyName].disable({ emitEvent: false });
      });
    } else {
      this.formGroup.controls.type.enable();
      this.inputFieldOptions.forEach((option) => {
        this.formGroup.controls[option.propertyName].enable({ emitEvent: false });
      });
    }

    if (!this.writePermission || this.isSaving || (this.hasMySqlRouter && !this.isNewItem)) {
      this.formGroup.controls.hasStatus.disable({ emitEvent: false });
    } else {
      this.formGroup.controls.hasStatus.enable({ emitEvent: false });
    }

    if (!this.writePermission || this.isSaving) {
      this.formGroup.controls.season.disable({ emitEvent: false });
    } else {
      this.formGroup.controls.season.enable({ emitEvent: false });
    }

    this.inputFieldOptions.forEach((option) => {
      if (option.includedInImports != null && option.includedInImports.includes(this.importType)) {
        this.formGroup.controls[option.propertyName].disable({ emitEvent: false });
      }
    });
  }
}
