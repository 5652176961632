import { Component, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';

@Component({
  selector: 'sis-select-language',
  templateUrl: 'select-language.component.html',
  styleUrls: ['select-language.component.scss'],
})
export class SelectLanguageModal implements OnInit {
  readonly languages = [
    { name: 'Deutsch', key: 'de' },
    { name: 'Français', key: 'fr' },
    { name: 'Italiano', key: 'it' },
    { name: 'English', key: 'en' },
  ];

  selectedLanguage: string;

  constructor(
    private modalCtrl: ModalController,
    private translateService: TranslateService,
    private userSettingsService: UserSettingsService,
    private menuCtrl: MenuController
  ) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.currentLang;
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
    this.menuCtrl.close('mainMenu');
  }

  async swapLanguage(languageKey: string): Promise<void> {
    if (this.selectedLanguage !== languageKey) {
      this.translateService.use(languageKey);
      await this.userSettingsService.updateUserSettings({
        guiLanguage: languageKey,
      });
    }
    this.closeModal();
  }
}
