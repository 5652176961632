<sis-outdated-icon
  *ngIf="this.outdated"
  color="var(--ion-color-danger)"
  [timestamp]="this.dataTimestamp"
></sis-outdated-icon>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <rect width="187.5" height="225" style="fill: none" />
        <g>
          <g>
            <polygon
              points="63.66 86.39 67.44 77.35 61.9 65.55 66.29 61.18 77.91 66.74 86.86 62.97 91.29 50.65 97.38 50.66 101.58 62.98 110.53 66.72 122.22 60.86 126.48 65.26 120.84 77.09 124.45 86.11 136.23 90.32 136.51 96.62 124.37 100.85 120.56 110.09 126 121.86 121.64 126.24 110.05 120.69 101.11 124.46 96.69 136.78 90.56 136.85 86.33 124.61 76.99 120.72 65.53 126.42 61.49 122.2 67.14 110.35 63.46 101.26 51.29 96.86 51.41 90.74 51.41 90.74 63.66 86.39 63.66 86.39"
              style="fill: none; stroke: #989898; stroke-miterlimit: 10; stroke-width: 4px"
            />
            <circle
              cx="93.9"
              cy="93.75"
              r="20.02"
              style="fill: none; stroke: #989898; stroke-miterlimit: 10; stroke-width: 4px"
            />
          </g>
          <polyline
            points="93.9 78.7 93.9 93.75 103.27 90.89"
            style="fill: none; stroke: #32849a; stroke-miterlimit: 10; stroke-width: 4px"
          />
          <path
            d="M84.62,30.92A63.5,63.5,0,1,1,49.48,48.37"
            style="fill: none; stroke: #32849a; stroke-miterlimit: 10; stroke-width: 4px"
          />
        </g>
        <polyline
          points="30.1 52.64 49.48 48.27 48.97 68.14"
          style="fill: none; stroke: #32849a; stroke-miterlimit: 10; stroke-width: 4px"
        />
      </g>
    </g>
    <g id="Widgettexte">
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
        [style.fill]="this.outdated ? 'var(--ion-color-medium)' : '#1c232a'"
      >
        {{ operatingHours }}
        <tspan id="unit" style="font-size: 24px">h</tspan>
      </text>
    </g>
  </g>
</svg>
