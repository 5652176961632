<div *ngIf="!this.sisMediaSettingEdited" class="sis-spinner-div">
  <ion-spinner></ion-spinner>
</div>

<ion-grid class="sis-global-page-grid">
  <sis-page-title [title]="'general.term.sismediaSetting'"> </sis-page-title>

  <!-- BigScreen -->
  <ion-row *ngIf="this.formGroup && this.sisMediaSettingEdited && (this.bigScreenMode$ | async)">
    <ion-col>
      <ion-grid>
        <form [formGroup]="formGroup">
          <ion-row class="sis-global-row-50">
            <!-- winter season -->
            <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.winterSeason' | translate }}</ion-col>
            <ion-col size="0.5" style="text-align: end">
              <div class="sis-sismedia-setting-term">{{ 'general.term.fromDate' | translate }}</div>
            </ion-col>
            <ion-col class="sis-input-col" size="2">
              <ion-input
                type="date"
                class="sis-global-input"
                formControlName="winterSeasonStartDate"
                [ngClass]="{ 'sis-global-input-error': this.formGroup?.errors?.seasonDatesInvalid }"
              ></ion-input>
            </ion-col>

            <ion-col size="1"></ion-col>
            <ion-col size="0.5" style="text-align: end">
              <div class="sis-sismedia-setting-term">{{ 'general.term.toDate' | translate }}</div>
            </ion-col>
            <ion-col size="2" class="sis-input-col">
              <ion-input
                class="sis-global-input"
                type="date"
                formControlName="winterSeasonEndDate"
                [ngClass]="{ 'sis-global-input-error': this.formGroup?.errors?.seasonDatesInvalid }"
              ></ion-input>
            </ion-col>
          </ion-row>

          <ion-row class="sis-global-row-50">
            <!-- operating times -->
            <ion-col size="2.5" class="sis-subtitle-text">{{ 'stnet.term.operatingTimes' | translate }}</ion-col>
            <ion-col size="0.5" style="text-align: end">
              <div class="sis-sismedia-setting-term">{{ 'general.term.fromTime' | translate }}</div>
            </ion-col>
            <ion-col size="2" class="sis-input-col">
              <ion-input
                type="time"
                formControlName="liftsOpenFrom"
                class="sis-global-input"
                [ngClass]="{ 'sis-global-input-error': this.formGroup.errors?.operatingTimesInvalid }"
                required
              ></ion-input>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="0.5" style="text-align: end">
              <div class="sis-sismedia-setting-term">{{ 'general.term.toTimeOnly' | translate }}</div>
            </ion-col>
            <ion-col size="2" class="sis-input-col">
              <ion-input
                type="time"
                formControlName="liftsOpenTo"
                class="sis-global-input"
                [ngClass]="{ 'sis-global-input-error': this.formGroup.errors?.operatingTimesInvalid }"
                required
              ></ion-input>
            </ion-col>
          </ion-row>
        </form>
      </ion-grid>
    </ion-col>
  </ion-row>

  <!-- SmallScreen -->
  <ion-row *ngIf="this.formGroup && this.sisMediaSettingEdited && !(this.bigScreenMode$ | async)" style="padding: 5px">
    <ion-col>
      <ion-grid>
        <form [formGroup]="formGroup">
          <ion-row class="sis-text-row-small">
            <ion-col class="sis-subtitle-text">{{ 'stnet.term.winterSeason' | translate }}</ion-col>
          </ion-row>
          <ion-row class="sis-text-row-small">
            <ion-col size="2" style="text-align: end">
              <div class="sis-sismedia-setting-term">{{ 'general.term.fromDate' | translate }}</div>
            </ion-col>
            <ion-col size="3.5" class="sis-input-col"
              ><ion-input
                type="date"
                class="sis-global-input"
                formControlName="winterSeasonStartDate"
                [ngClass]="{ 'sis-global-input-error': this.formGroup.errors?.seasonDatesInvalid }"
              ></ion-input>
            </ion-col>
            <ion-col size="2.5" style="text-align: end">
              <div class="sis-sismedia-setting-term">{{ 'general.term.toDate' | translate }}</div>
            </ion-col>
            <ion-col size="3.5" class="sis-input-col"
              ><ion-input
                type="date"
                class="sis-global-input"
                formControlName="winterSeasonEndDate"
                [ngClass]="{ 'sis-global-input-error': this.formGroup.errors?.seasonDatesInvalid }"
              ></ion-input>
            </ion-col>
          </ion-row>

          <!-- operating times -->
          <ion-row class="sis-text-row-small">
            <ion-col class="sis-subtitle-text">{{ 'stnet.term.operatingTimes' | translate }}</ion-col>
          </ion-row>
          <ion-row class="sis-text-row-small">
            <ion-col size="2" style="text-align: end">
              <div class="sis-sismedia-setting-term">{{ 'general.term.fromTime' | translate }}</div>
            </ion-col>
            <ion-col size="3.5" class="sis-input-col"
              ><ion-input
                type="time"
                formControlName="liftsOpenFrom"
                class="sis-global-input"
                [ngClass]="{ 'sis-global-input-error': this.formGroup.errors?.operatingTimesInvalid }"
                required
              ></ion-input>
            </ion-col>
            <ion-col size="2.5" style="text-align: end">
              <div class="sis-sismedia-setting-term">{{ 'general.term.toTimeOnly' | translate }}</div>
            </ion-col>
            <ion-col size="3.5" class="sis-input-col"
              ><ion-input
                type="time"
                formControlName="liftsOpenTo"
                class="sis-global-input"
                [ngClass]="{ 'sis-global-input-error': this.formGroup.errors?.operatingTimesInvalid }"
                required
              ></ion-input>
            </ion-col>
          </ion-row>
        </form>
      </ion-grid>
    </ion-col>
  </ion-row>

  <ion-row class="sis-footer" *ngIf="this.formGroup && this.sisMediaSettingEdited">
    <ion-col>
      <sis-general-button
        [text]="'general.term.save'"
        [ngClass]="{'sis-global-button-padding-right' : this.bigScreenMode$ | async}"
        [btnType]="'Secondary'"
        [showSpinner]="this.saving"
        (sisClick)="this.save()"
        [disabled]="!(this.writePermission$ | async) || !this.edited || this.saving"
        [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      ></sis-general-button>
      <sis-general-button
        [text]="'general.term.reset'"
        [ngClass]="{'sis-global-button-padding-right' : this.bigScreenMode$ | async}"
        [btnType]="'Secondary'"
        [showSpinner]="this.saving"
        (sisClick)="this.reset()"
        [disabled]="!(this.writePermission$ | async) || !this.edited || this.saving"
        [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      ></sis-general-button>
    </ion-col>
  </ion-row>
</ion-grid>
