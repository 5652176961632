import { Configuration } from 'src/app/maps/domain/configuration.model';
import { RopewayTorqueTrend } from 'src/app/maps/trenddata/ropeway-torque-trend.model';

export class RopewayTorqueTrendAdapter {
  static adapt(items: any, configurations: Configuration[]): RopewayTorqueTrend[] {
    const trends: RopewayTorqueTrend[] = [];
    let percentConfig: Configuration = null;
    let ampereConfig: Configuration = null;

    let hasAmpereValue = false;
    let hasPercentValue = false;

    let hasAmpereConfig = false;
    let hasPercentConfig = false;

    let lastTimestampMs = null;

    if (configurations) {
      percentConfig = configurations.find((c) => c.configKey === Configuration.driveTorqueNmKey);
      hasPercentConfig = percentConfig != null;
      ampereConfig = configurations.find((c) => c.configKey === Configuration.driveMotorCurrentAmpereKey);
      hasAmpereConfig = ampereConfig != null;
    }

    if (items && items.length > 0) {
      hasAmpereValue = items[0].driveMotorCurrentAmpere != null;
      hasPercentValue = items[0].driveMotorTorquePercent != null;
      lastTimestampMs = new Date(items[0].timestamp).getTime();

      items.forEach((e: any) => {
        const timestampMs = new Date(e.timestamp).getTime();
        const timestampMsMaxExpectedValue = lastTimestampMs + 21_000;
        if (timestampMs > timestampMsMaxExpectedValue) {
          trends.push(new RopewayTorqueTrend({ timestamp: new Date(timestampMsMaxExpectedValue) }));
        }
        lastTimestampMs = timestampMs;

        trends.push(
          new RopewayTorqueTrend({
            torqueAbsolute: e.driveMotorCurrentAmpere,
            torquePercent: e.driveMotorTorquePercent,
            timestamp: new Date(e.timestamp),
          })
        );
      });
    }

    if (hasPercentValue && hasPercentConfig) {
      return this.updateAbsoluteValue(trends, percentConfig.nominalValue);
    }

    if (hasAmpereValue && hasAmpereConfig) {
      return this.updateTorquePercent(trends, ampereConfig.nominalValue);
    }

    if (hasPercentValue && !hasPercentConfig) {
      return this.onlyPercent(trends);
    }

    if (hasAmpereValue && !hasAmpereConfig) {
      return this.onlyAmpere(trends);
    }
  }

  private static onlyPercent(trends: RopewayTorqueTrend[]): RopewayTorqueTrend[] {
    trends.forEach((data) => {
      data.unit = RopewayTorqueTrend.unitPercent;
      data.torqueAbsolute = null;
    });
    return trends;
  }

  private static onlyAmpere(trends: RopewayTorqueTrend[]): RopewayTorqueTrend[] {
    trends.forEach((data) => {
      data.unit = RopewayTorqueTrend.unitAmpere;
      data.torquePercent = data.torqueAbsolute;
      data.torqueAbsolute = null;
    });
    return trends;
  }

  private static updateAbsoluteValue(trends: RopewayTorqueTrend[], nominalValue: number): RopewayTorqueTrend[] {
    trends.forEach((data) => {
      data.unit = RopewayTorqueTrend.unitNewton;
      data.torqueAbsolute = (nominalValue * data.torquePercent) / 100;
    });
    return trends;
  }

  private static updateTorquePercent(trends: RopewayTorqueTrend[], nominalValue: number): RopewayTorqueTrend[] {
    trends.forEach((data) => {
      data.unit = RopewayTorqueTrend.unitAmpere;
      data.torquePercent = (data.torqueAbsolute / nominalValue) * 100;
    });
    return trends;
  }
}
