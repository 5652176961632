import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { AbbSmartSensor } from 'src/app/maps/domain/abbsmartsensor.model';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { AbbSmartSensorDataService } from 'src/app/maps/livedata/abbsmartsensor-data.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';

@Component({
  selector: 'sis-abbsmartsensor-detailview',
  templateUrl: './abbsmartsensor-detailview.component.html',
  styleUrls: ['./abbsmartsensor-detailview.component.scss'],
})
export class AbbSmartSensorDetailViewComponent extends Unsubscriber implements OnInit {
  abbSmartSensors: AbbSmartSensor[];
  timestamp: Date;

  constructor(
    private masterDataService: MasterdataService,
    private abbSmartSensorDataService: AbbSmartSensorDataService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.selectedMapElementService.selectedRopeway$, this.masterDataService.masterData$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([ropeway, masterData]) => {
        if (ropeway) {
          const abbSmartSensors = ropeway.abbSmartSensors;
          this.abbSmartSensors = abbSmartSensors?.sort((a, b) => (a.assetName < b.assetName ? -1 : 1));
          return;
        }

        this.abbSmartSensors = masterData?.abbSmartSensors?.sort((a, b) => (a.assetName < b.assetName ? -1 : 1));
      });

    this.abbSmartSensorDataService.abbSmartSensorDatas$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((abbSmartSensorDatas) => {
        this.timestamp = abbSmartSensorDatas?.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))[0].timestamp;
      });
  }
}
