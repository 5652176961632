import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { BreakdownInfoLibraryEntryAdapter } from 'src/app/breakdowninfo/domain/breakdowninfo-library-entry.adapter';
import { BreakdownInfoLibraryEntry } from 'src/app/breakdowninfo/domain/breakdowninfo-library-entry.model';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { BreakdownInfoLibraryUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BreakdownInfoLibraryService {
  static readonly insertOperation = 'insert';
  static readonly updateOperation = 'update';
  static readonly deleteOperation = 'delete';

  private static readonly requestLibraryUrl: string = '/breakdowninfo/library';
  private static readonly updateLibraryPostUrl: string = '/breakdowninfo/updatelibraryitem';

  private readonly requiredFeature = new Feature(FeatureId.BREAKDOWNINFO, FeatureAccessLevel.READ);

  readonly libraryRequest$: Observable<BreakdownInfoLibraryEntry[]> =
    this.destinationService.selectedTenantFeatures$.pipe(
      switchMap((features) => {
        if (features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))) {
          return this.http
            .get(`${environment.baseUrlApi}${BreakdownInfoLibraryService.requestLibraryUrl}`)
            .pipe(map((breakdownInfo: any) => BreakdownInfoLibraryEntryAdapter.adapt(breakdownInfo)));
        } else {
          return of([]);
        }
      }),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

  readonly libraryUpdate$: Observable<BreakdownInfoLibraryEntry[]> = this.eventBus
    .observe(BreakdownInfoLibraryUpdatedEvent)
    .pipe(
      withLatestFrom(this.libraryRequest$),
      map(([event, library]) => {
        const oldEntry = library.find((l) => l.guid === event.breakdownInfo.guid);
        if (oldEntry) {
          if (!event.removed) {
            Object.assign(oldEntry, event.breakdownInfo);
          } else {
            library.splice(library.indexOf(oldEntry), 1);
          }
        } else {
          library.push(event.breakdownInfo);
        }
        return library;
      })
    );

  readonly library$ = merge(this.libraryRequest$, this.libraryUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private http: HttpClient,
    private destinationService: DestinationService,
    private eventBus: EventBusService
  ) {}

  async updateLibrary(
    breakdownInfoLibraryEntry: BreakdownInfoLibraryEntry,
    updateAll: boolean,
    operation: string
  ): Promise<void> {
    const url = `${environment.baseUrlApi}${BreakdownInfoLibraryService.updateLibraryPostUrl}`;
    await firstValueFrom(
      this.http
        .post(url, {
          BreakdownInfo: breakdownInfoLibraryEntry,
          UpdateAll: updateAll,
          Operation: operation,
        })
        .pipe(catchError(() => of()))
    );
  }
}
