import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { MeteoForecast } from 'src/app/maps/livedata/meteo-forecast.model';
import { MeteoForecastService } from 'src/app/maps/livedata/meteo-forecast.service';
import { MeteoLivedata } from 'src/app/maps/livedata/meteo-livedata.model';
import { MeteoLivedataService } from 'src/app/maps/livedata/meteo-livedata.service';
import { MeteoStationService } from 'src/app/maps/meteostation-service/meteostation.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-wet-bulb-widget',
  templateUrl: './wet-bulb-widget.component.html',
  styleUrls: ['./wet-bulb-widget.component.scss'],
})
export class WetBulbWidgetComponent extends WidgetBase implements OnInit {
  private readonly wetBulbTemperatureCelsius$: Observable<number> = combineLatest([
    this.meteoLiveDataService.meteoLiveDatas$.pipe(startWith(<MeteoLivedata[]>[])),
    this.meteoForecastService.forecasts$.pipe(startWith(<MeteoForecast[]>[])),
  ]).pipe(
    withLatestFrom(
      this.meteoStationService.relevantMeteoStationWithWetBulbTemperature$.pipe(
        filter((meteoStation) => !!meteoStation),
        map((meteoStation) => meteoStation.meteoDeviceSisId)
      )
    ),
    map(([[meteoLiveDatas, meteoForecastDatas], meteoDeviceSisId]) => {
      const meteoLiveData = meteoLiveDatas?.find((d) => d.meteoDeviceSisId === meteoDeviceSisId);
      const meteoForecastData = meteoForecastDatas?.find((d) => d.deviceSisId === meteoDeviceSisId);

      const wetBulbLiveData = meteoLiveData
        ? meteoLiveData.outdated
          ? null
          : meteoLiveData.wetBulbTemperatureCelsius
        : null;

      const wetBulbForecastTemperatureCelsius = meteoForecastData
        ? meteoForecastData.outdated
          ? null
          : meteoForecastData.wetBulbTemperatureCelsius
        : null;

      this.isForecastValue = wetBulbLiveData == null && wetBulbForecastTemperatureCelsius != null;

      return wetBulbLiveData ?? wetBulbForecastTemperatureCelsius;
    })
  );

  wetBulbTemperatureCelsius: string = WidgetBase.valueUnknown;
  isForecastValue: boolean = false;

  constructor(
    private meteoStationService: MeteoStationService,
    private meteoForecastService: MeteoForecastService,
    private meteoLiveDataService: MeteoLivedataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.wetBulbTemperatureCelsius$.pipe(takeUntil(this.onDestroy$)).subscribe((wetBulbTemperatureCelsius) => {
      this.calcAndSetDisplayValues(wetBulbTemperatureCelsius);
    });
  }

  private calcAndSetDisplayValues(wetBulbValue: number) {
    this.wetBulbTemperatureCelsius =
      wetBulbValue != null ? Math.round(wetBulbValue).toString() : WidgetBase.valueUnknown;
  }
}
