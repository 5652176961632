import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { divIcon, latLng, LayerGroup, Marker, marker } from 'leaflet';
import { take, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { AlarmLevel } from 'src/app/domain/alarm.model';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { GlobalAlarmService } from 'src/app/geomap/livedata/globalalarm.service';
import { TenantMarkerService } from 'src/app/geomap/map/tenant-layer/tenant-marker/tenant-marker.service';

@Component({
  selector: 'sis-tenant-marker',
  templateUrl: './tenant-marker.component.html',
  styleUrls: ['./tenant-marker.component.scss'],
})
export class TenantMarkerComponent extends Unsubscriber implements OnInit {
  private readonly noDataColor = 'var(--sis-color-ropewayoffline)';
  private readonly okColor = 'var(--ion-color-primary)';
  private readonly alarmColor = 'var(--ion-color-danger)';
  private readonly warningColor = 'var(--ion-color-warning)';
  private readonly infoColor = 'var(--ion-color-primary)';

  tenantMarker: Marker;

  @Input() tenantGroup: LayerGroup;
  @Input() tenant: Tenant;

  constructor(
    private zone: NgZone,
    private router: Router,
    private tenantMarkerService: TenantMarkerService,
    private globalAlarmService: GlobalAlarmService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initMarker();
  }

  private initMarker() {
    if (!this.tenant?.positionGeoMap || !this.tenantGroup) {
      return;
    }

    this.tenantMarkerService.svgAsHtml$.pipe(take(1)).subscribe((svg) => {
      const iconWidth = 70;
      const iconHeight = 85;
      const iconZoom = 0.5;
      const tenantIcon = divIcon({
        html: svg,
        iconSize: [iconWidth * iconZoom, iconHeight * iconZoom],
        iconAnchor: [(iconWidth * iconZoom) / 2, iconHeight * iconZoom],
        tooltipAnchor: [0, -(iconHeight * iconZoom)],
        className: this.tenant.hasRopewaysOrLifts ? 'pointer' : 'default',
      });
      this.tenantMarker = marker(latLng(this.tenant.positionGeoMap.x, this.tenant.positionGeoMap.y), {
        icon: tenantIcon,
      });
      this.tenantMarker.bindTooltip(`<b>${this.tenant.alias}</b><br>(${this.tenant.sisId.toUpperCase()})`, {
        direction: 'top',
      });
      this.tenantMarker.on('click', () => this.zone.run(() => this.onIconClick()));
      this.tenantMarker.on('add', () => {
        this.onMarkerAdded();
      });
      this.tenantGroup.addLayer(this.tenantMarker);
    });
  }

  private onMarkerAdded(): void {
    this.tenantMarker.removeEventListener('add');

    this.setIconColor(this.tenantMarker, this.tenant.hasRopewaysOrLifts ? this.okColor : this.noDataColor);
    this.setTestAttribute(this.tenantMarker, this.tenant.sisId);

    this.globalAlarmService.alarms$.pipe(takeUntil(this.onDestroy$)).subscribe((alarms) => {
      const globalAlarm = alarms.find((a) => a.tenantSisId === this.tenant.sisId);
      if (globalAlarm) {
        this.setIconColor(this.tenantMarker, this.getColor(globalAlarm.alarms));

        if (globalAlarm.alarms.get(AlarmLevel.SYSTEM)) {
          this.tenantMarker.getElement().classList.add('blinking');
        } else {
          this.tenantMarker.getElement().classList.remove('blinking');
        }
      }
    });
  }

  private onIconClick(): void {
    if (this.tenant.hasRopewaysOrLifts) {
      this.router.navigate([this.tenant.sisId, 'maps']);
    }
  }

  private setTestAttribute(icon: Marker, sisId: string): void {
    const e = icon.getElement();
    e.setAttribute('data-test', 'sis-globalmap-tenant-marker-' + sisId);
  }

  private setIconColor(icon: Marker, color: string): void {
    const e = icon.getElement().querySelectorAll('path')[0];
    e.setAttribute('fill', color);
  }

  private getColor(alarmCounts: Map<AlarmLevel, number>): string {
    if (
      alarmCounts.get(AlarmLevel.FAULT) > 0 ||
      alarmCounts.get(AlarmLevel.ALARM) > 0 ||
      alarmCounts.get(AlarmLevel.SYSTEM) > 0
    ) {
      return this.alarmColor;
    }
    if (alarmCounts.get(AlarmLevel.WARNING) > 0) {
      return this.warningColor;
    }
    if (
      alarmCounts.get(AlarmLevel.INFO) > 0 ||
      alarmCounts.get(AlarmLevel.BYPASS) > 0 ||
      alarmCounts.get(AlarmLevel.CHECK) > 0
    ) {
      return this.infoColor;
    }
    return this.okColor;
  }
}
