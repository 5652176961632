import { Component, Input, OnInit } from '@angular/core';
import { NmsData } from 'src/app/it-services/livedata/nms-data.model';

@Component({
  selector: 'sis-nms-item',
  templateUrl: './nms-item.component.html',
  styleUrls: ['./nms-item.component.scss'],
})
export class NmsItemComponent implements OnInit {
  @Input() item: NmsData;
  @Input() bigScreenMode: boolean;

  deviceIconName: string;
  statusIconName: string;
  statusClass: string;
  isDateValid: boolean;

  ngOnInit(): void {
    this.deviceIconName = `sis-${this.item.deviceType}`;
    this.statusIconName = this.getStatusIconName();
    this.statusClass = this.getStatusClass();
    this.isDateValid = this.item.timestamp instanceof Date && !isNaN(this.item.timestamp.getTime());
  }

  private getStatusIconName(): string {
    switch (this.item.status) {
      case 'online':
        return 'checkmark-circle';
      case 'offline':
        return 'close-circle';
      default:
        return 'help-circle';
    }
  }

  private getStatusClass(): string {
    switch (this.item.status) {
      case 'online':
        return 'sis-online';
      case 'offline':
        return 'sis-offline';
      default:
        return 'sis-unknown';
    }
  }
}
