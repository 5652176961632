import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { ManagedTenant } from 'src/app/user-management/domain/managed-tenant.model';
import { UserManagementService } from 'src/app/user-management/user-management.service';

@Component({
  selector: 'sis-tenant-feature-icon-component',
  templateUrl: './tenant-feature-icon.component.html',
  styleUrls: ['./tenant-feature-icon.component.scss'],
})
export class TenantFeatureIconComponent extends Unsubscriber implements OnInit {
  @Input() tenant: ManagedTenant | Tenant;

  featureTranslationMap: Map<FeatureId, string>;
  sisMediaTooltip: string;
  cockpitTooltip: string;

  constructor(private userManagementService: UserManagementService) {
    super();
  }

  ngOnInit() {
    this.userManagementService.featureTranslationMap$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((featureTranslationMap) => {
        const tooltips = this.getTooltips(this.tenant, featureTranslationMap);
        this.cockpitTooltip = tooltips[0];
        this.sisMediaTooltip = tooltips[1];
      });
  }

  private getTooltips(
    tenant: ManagedTenant | Tenant,
    featureTranslationMap: Map<FeatureId, string>
  ): [string, string] {
    const [cockpitFeatures, sismediaFeatures] = this.validateFeatures(tenant);

    const translatedCockpitFeatures = cockpitFeatures
      .sort((a, b) => a.featureOrder - b.featureOrder)
      .map((feature) => featureTranslationMap.get(feature.featureId))
      .join('\n');

    const translatedSismediaFeatures = sismediaFeatures
      .sort((a, b) => a.featureOrder - b.featureOrder)
      .map((feature) => featureTranslationMap.get(feature.featureId))
      .join('\n');

    return [translatedCockpitFeatures, translatedSismediaFeatures];
  }

  private validateFeatures(tenant: ManagedTenant | Tenant): [Feature[], Feature[]] {
    let features: Feature[];

    if ('features$' in tenant && tenant.features$) {
      features = tenant.features$.value;
    } else {
      features = (tenant as ManagedTenant).features;
    }

    const cockpitFeatures = features.filter(
      (f) => f.featureId >= 1000 && f.featureId <= 1999
    );
    const sismediaFeatures = features.filter(
      (f) => f.featureId >= 2000 && f.featureId < 3000
    );

    return [cockpitFeatures, sismediaFeatures];
  }
}
