import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { FeatureUpdatedEvent, MaintenanceUpdatedEvent, UserPermissionUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Feature } from 'src/app/domain/feature/feature.model';
import { TenantAdapter } from 'src/app/domain/tenant/tenant.adapter';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantService extends Unsubscriber {
  private readonly urlPathAllTenants = '/tenant';

  readonly tenants$: Observable<Tenant[]> = this.http.get(environment.baseUrlApi + this.urlPathAllTenants).pipe(
    map((data: any) => TenantAdapter.adapt(data)),
    shareReplay(1)
  );

  constructor(private http: HttpClient, eventBusService: EventBusService, userSettingsService: UserSettingsService) {
    super();

    eventBusService
      .observe(UserPermissionUpdatedEvent)
      .pipe(
        withLatestFrom(userSettingsService.userSettings$),
        filter(([event, userSettings]) => event.userGuid === userSettings.userGuid),
        withLatestFrom(this.tenants$),
        map(([[event], tenants]) => ({
          event,
          updatedTenant: tenants.find((tenant) => tenant.guid === event.tenantGuid),
        })),
        filter(({ updatedTenant }) => !!updatedTenant),
        switchMap(({ event, updatedTenant }) =>
          updatedTenant.features$.pipe(
            take(1),
            tap((features) => {
              const feature = features.find((f) => f.featureId === event.featureId);
              if (feature) {
                feature.featureAccessLevel = event.featureAccessLevel;
              } else {
                features.push(new Feature(event.featureId, event.featureAccessLevel));
              }
              updatedTenant.features$.next(features);
            })
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    eventBusService
      .observe(FeatureUpdatedEvent)
      .pipe(
        withLatestFrom(this.tenants$),
        map(([event, tenants]) => ({
          event,
          updatedTenant: tenants.find((tenant) => tenant.guid === event.tenantGuid),
        })),
        filter(({ updatedTenant }) => !!updatedTenant),
        switchMap(({ event, updatedTenant }) =>
          updatedTenant.features$.pipe(
            take(1),
            tap((features) => {
              const activeFeatures = event.features.filter((e) => e.active);
              features = features.filter((f) => activeFeatures.find((a) => a.id === f.featureId));
              updatedTenant.features$.next(features);
            })
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    eventBusService
      .observe(MaintenanceUpdatedEvent)
      .pipe(withLatestFrom(this.tenants$), takeUntil(this.onDestroy$))
      .subscribe(([event, tenants]) => {
        tenants.forEach((tenant) => {
          tenant.underMaintenance = event.tenantSisIdsUnderMaintenance.includes(tenant.sisId);
        });
      });
  }

  // todo ensure proper interface of positionGeoMap
}
