<ng-container *ngFor="let p of appPages">
  <ion-item
    *ngIf="p.visible"
    class="menu-page-item"
    [ngClass]="{ 'menu-page-item-disabled': !p.enabled }"
    (click)="this.menuItemClick(p)"
    [attr.data-test]="p.url + '-menu-button'"
  >
    <ion-icon [name]="p.iconName" class="ion-menu-icon" [ngClass]="{ 'ion-menu-icon-disabled': !p.enabled }"> </ion-icon>
    <ion-label>{{ p.title | translate }}</ion-label>
    <ion-icon
      class="menu-page-dropdown-icon"
      *ngIf="p.submenu?.length > 0"
      slot="end"
      [name]="p.open ? 'chevron-down-outline' : 'chevron-forward-outline'"
    ></ion-icon>
  </ion-item>
  <ng-container *ngIf="p.submenu?.length > 0 && p.open && p.enabled">
    <ng-container *ngFor="let s of p.submenu">
      <ion-item *ngIf="s.visible" class="submenu-page-item" (click)="this.menuItemClick(s)" [attr.data-test]="s.url + '-sub-menu-button'">
        <ion-icon [name]="s.iconName" class="ion-menu-icon"></ion-icon>
        <ion-label>{{ s.title | translate }}</ion-label>
      </ion-item>
    </ng-container>
  </ng-container>
</ng-container>
