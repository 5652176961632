import { MasterdataAdapter } from 'src/app/maps/domain/masterdata.adapter';
import { RopewaySettings } from 'src/app/maps/domain/ropeway-settings/ropeway-settings.model';

export class RopewaySettingsAdapter {
  static adapt(item: any): RopewaySettings {
    if (!item) {
      return null;
    }

    return {
      sisId: item.sisId,
      guid: item.guid,
      tenantGuid: item.tenantGuid,
      alias: item.alias,
      fullname: item.fullname,
      ropewayType: item.ropewayType,
      constructedBy: item.constructedBy,
      controllerType: item.controllerType,
      maxTransportQuantityPerHour: item.maxTransportQuantityPerHour,
      operatingTimeFrom: item.operatingTimeFrom,
      operatingTimeUntil: item.operatingTimeUntil,
      availability: item.availability,
      availabilityIsAvailable: MasterdataAdapter.isAvailabilityAvailable(item.sisId, item.controllerType),
    };
  }
}
