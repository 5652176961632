import { STnetItem } from 'src/app/stnet/domain/stnet-item.model';

export class STnetItemAdapter {
  static adapt(item: any): STnetItem | null {
    if (!item) {
      return null;
    }

    return {
      tenantGuid: item.tenantGuid,
      stationId: item.stationId,
      stationName: item.stationName,
      // snow
      snowConditionMainId: item.snowConditionMainId,
      snowConditionPartialId: item.snowConditionPartialId,
      // slope
      slopeConditionId: item.slopeConditionId,
      slopesSnowedLengthMeterToday: item.slopesSnowedLengthMeterToday,
      snowHeightMeasurementMethodId: item.snowHeightMeasurementMethodId,
      // valley run
      valleyRunMainConditionId: item.valleyRunMainConditionId,
      valleyRunPartialConditionId: item.valleyRunPartialConditionId,
      valleyRunLowestAltitudeToday: item.valleyRunLowestAltitudeToday,
      // cross country
      classicTrackConditionId: item.classicTrackConditionId,
      skatingTrackConditionId: item.skatingTrackConditionId,
      crossCountryTracksLowestAltitudeToday: item.crossCountryTracksLowestAltitudeToday,
      crossCountryTracksHighestAltitudeToday: item.crossCountryTracksHighestAltitudeToday,
      // snow park
      snowparkConditionId: item.snowparkConditionId,
      // toboggan
      tobogganRunConditionId: item.tobogganRunConditionId,
      tobogganRunSkillTypeId: item.tobogganRunSkillTypeId,
      // winter hiking
      winterHikingTrailConditionId: item.winterHikingTrailConditionId,
      winterHikingTrailsLowestAltitudeTotal: item.winterHikingTrailsLowestAltitudeTotal,
      // flood light
      slopesFloodlightEnabled: item.slopesFloodlightEnabled,
      slopesFloodlightUntil: item.slopesFloodlightUntil,
      slopesWithFloodlightLengthMeterToday: item.slopesWithFloodlightLengthMeterToday,
      crossCountryFloodlightEnabled: item.crossCountryFloodlightEnabled,
      crossCountryFloodlightUntil: item.crossCountryFloodlightUntil,
      crossCountryTracksWithFloodlightLengthMeterToday: item.crossCountryTracksWithFloodlightLengthMeterToday,
      snowparkFloodlightEnabled: item.snowparkFloodlightEnabled,
      snowparkFloodlightUntil: item.snowparkFloodlightUntil,
      tobogganFloodlightEnabled: item.tobogganFloodlightEnabled,
      tobogganRunsWithFloodlightLengthMeterToday: item.tobogganRunsWithFloodlightLengthMeterToday,
      // changes
      lastChange: new Date(item.lastChange),
      changedBy: item.changedBy,
      changedByEmail: item.changedByEmail,
    };
  }
}
