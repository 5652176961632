import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { RopewayAvailabilityAdapter } from 'src/app/maps/livedata/ropeway-availability.adapter';
import { RopewayAvailability } from 'src/app/maps/livedata/ropeway-availability.model';

@Injectable({
  providedIn: 'root',
})
export class RopewayAvailabilityService extends LivedataServiceBase<RopewayAvailability> {
  protected readonly endpointUri = 'livedata/ropewayavailability';
  protected readonly requiredFeatures = [
    new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ),
    new Feature(FeatureId.COCKPIT_ROPEWAY_AVAILABILITY, FeatureAccessLevel.READ),
  ];

  readonly ropewayAvailability$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, RopewayAvailabilityAdapter.adapt, destinationService);
  }
}
