<ion-grid class="sis-asset-container sis-global-no-scrollbar" data-test="abbsmartsensor-detailview">
  <sis-abbsmartsensor-item
    *ngFor="let abbSmartSensor of this.abbSmartSensors"
    [abbSmartSensor]="abbSmartSensor"
    class="sis-detail-item"
    data-test="abbsmartsensor-item"
  ></sis-abbsmartsensor-item>
</ion-grid>

<ion-grid class="sis-global-sticky-bottom">
  <ion-row>
    <ion-col>
      <ion-grid *ngIf="this.timestamp" style="padding-left: 7px">
        <ion-row>
          <ion-col>
            <span class="sis-timestamp">{{ 'general.phrase.lastUpdate' | translate }}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="sis-timestamp" data-test="abbSmartSensorTimestamp">{{
            timestamp | date: 'dd.MM.yyyy HH:mm'
          }}</ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
    <ion-col size="auto" style="padding-right: 7px">
      <a href="https://smartsensor.abb.com/conditionmonitor/" target="_blank" data-test="abbSmartSensorLink"
        >ABB Smart Sensor <ion-icon class="sis-icon-open" name="open-outline"></ion-icon
      ></a>
    </ion-col>
  </ion-row>
</ion-grid>
