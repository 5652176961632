<ion-app>
  <sis-app-service-loader *ngIf="!isIframe && this.isLoggedIn"></sis-app-service-loader>
  <sis-header></sis-header>
  <sis-login *ngIf="!isIframe && !this.isLoggedIn && !this.loginInProgress" style="height: 100%"></sis-login>
  <sis-main-menu *ngIf="!isIframe && this.isLoggedIn"></sis-main-menu>
  <ion-content id="sis-main-content" *ngIf="!isIframe && this.isLoggedIn">
    <router-outlet></router-outlet>
  </ion-content>
  <sis-remote-access *ngIf="!isIframe && this.isLoggedIn"></sis-remote-access>
  <ion-spinner name="crescent" *ngIf="!isIframe && this.loginInProgress" class="sis-login-spinner"></ion-spinner>
</ion-app>
