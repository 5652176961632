import { InfoBanner } from 'src/app/media-center/screens/infobanner-modal/domain/infobanner.model';

export class InfoBannerAdapter {
  static adapt(items: any): InfoBanner[] {
    const result: InfoBanner[] = [];

    if (items) {
      items.forEach((e: any) => {
        result.push({
          guid: e.guid,
          tenantGuid: e.tenantGuid,
          position: e.position,
          name: e.name,
          background: e.background,
          content: e.content,
          height: e.height,
          screenGuids: e.screenGuids,
          textDe: e.textDe,
          textEn: e.textEn,
          textFr: e.textFr,
          textIt: e.textIt,
          textCategory: e.textCategory,
        });
      });
    }

    return result;
  }
}
