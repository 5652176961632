import { Injectable } from '@angular/core';
import { BlobItem, ContainerItem } from '@azure/storage-blob';
import { combineLatest, EMPTY, MonoTypeOperatorFunction, Observable, of, Subject } from 'rxjs';
import { filter, finalize, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { BlobStorageService } from 'src/app/core/blob-storage/blob-storage.service';
import { SisBlobContainerRequestParameters, SisBlobStorageRequestParameters } from 'src/app/core/blob-storage/blob-storage.types';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MediaCenterBlobStorageUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BlobStorageContainerService {
  private readonly refreshContainer$ = new Subject<string>();
  private readonly refreshTrigger$ = this.refreshContainer$.pipe(
    withLatestFrom(this.destinationService.selectedTenant$.pipe(map((t) => t.sisId))),
    switchMap(([containerName, tenantSisId]) => {
      // only refresh if it is the currently selected container (no refresh for images/videos of another tenant)
      if (containerName === tenantSisId) {
        return of(1);
      } else {
        return EMPTY;
      }
    })
  );

  private readonly selectedContainer$: Observable<string> = this.destinationService.selectedTenant$.pipe(map((tenant) => tenant?.sisId ?? undefined));

  readonly containers$: Observable<ContainerItem[]> = this.getStorageRequestParameters().pipe(
    switchMap((options) => this.blobStorageService.getContainers(options))
  );

  readonly mediaCenterBlobStorageUpdatedEvent$ = this.eventBus.observe(MediaCenterBlobStorageUpdatedEvent).pipe(
    withLatestFrom(this.userSettingsService.userSettings$),
    map(([event, userSettings]) => {
      if (event?.uploadedBy && event.uploadedBy === userSettings.userGuid) {
        if (event.successful) {
          const translateKey = ['mediacenter.phrase.videoConversionSuccess', `: ${event.fileName}`];
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.success,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.green,
          });
          this.userMessageService.presentToast(userMessage);
        } else {
          const translateKey = ['mediacenter.phrase.videoConversionFailed', `: ${event.fileName}`];
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.failed,
            durationMs: 10000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);
        }
      }
    })
  );

  readonly itemsInContainer$: Observable<BlobItem[]> = combineLatest([
    this.selectedContainer$,
    this.mediaCenterBlobStorageUpdatedEvent$.pipe(startWith(<MediaCenterBlobStorageUpdatedEvent>null)),
    this.refreshTrigger$.pipe(startWith(1)),
  ]).pipe(
    map(([containerName]) => containerName),
    filter((containerName) => !!containerName),
    switchMap((containerName) =>
      this.getStorageRequestParameters().pipe(
        switchMap((options) =>
          this.blobStorageService.listBlobsInContainer({
            storageUri: options.storageUri,
            storageAccessToken: options.storageAccessToken,
            containerName,
          })
        )
      )
    )
  );

  readonly blobStorageUrl$: Observable<string> = this.selectedContainer$.pipe(
    filter((containerId) => !!containerId),
    map((containerId) => `${environment.baseUrlMediaCenterBlobStorage}/${containerId}`)
  );

  constructor(
    private eventBus: EventBusService,
    private blobStorageService: BlobStorageService,
    private destinationService: DestinationService,
    private userSettingsService: UserSettingsService,
    private userMessageService: UserMessageService
  ) {}

  refreshSelectedContainer<T>(containerName: string): MonoTypeOperatorFunction<T> {
    return (source) =>
      source.pipe(
        finalize(() => {
          this.refreshContainer$.next(containerName);
        })
      );
  }

  getStorageOptionsWithContainer(): Observable<SisBlobContainerRequestParameters> {
    return this.getStorageRequestParameters().pipe(
      withLatestFrom(this.selectedContainer$),
      map(([options, containerName]) => ({
        storageUri: options.storageUri,
        storageAccessToken: options.storageAccessToken,
        containerName,
      }))
    );
  }

  private getStorageRequestParameters(): Observable<SisBlobStorageRequestParameters> {
    return this.blobStorageService.getBlobStorageRequestParameters();
  }
}
