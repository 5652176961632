<ion-grid class="sis-detailview-container sis-global-no-scrollbar">
  <div class="sis-sticky">
    <ion-row class="sis-title-row">
      <ion-col size="8">{{ 'destination.term.location' | translate }}</ion-col>
      <ion-col class="ion-text-right">
        <ion-icon
          name="sis-wetbulb-temperature"
          class="sis-icon"
          matTooltip="{{ 'ropeway.term.wetBulbTemp' | translate }} [{{ this.temperatureUnit | translate }}]"
        ></ion-icon>
      </ion-col>
      <ion-col class="ion-text-right">
        <ion-icon
          name="sis-temperature"
          class="sis-icon"
          matTooltip="{{ 'ropeway.term.temperature' | translate }} [{{ this.temperatureUnit | translate }}]"
        ></ion-icon>
      </ion-col>
      <ion-col class="ion-text-right">
        <ion-icon
          name="sis-wind"
          class="sis-icon"
          matTooltip="{{ 'ropeway.term.wind' | translate }}  [{{ this.windSpeedUnit | translate }}]"
        ></ion-icon>
      </ion-col>
    </ion-row>
    <hr />
  </div>

  <ion-row
    *ngFor="let data of this.displayData; let i = index; let odd = odd"
    class="sis-data-row"
    [ngClass]="{ odd: odd }"
    data-test="snowmaking-detailview-row"
  >
    <ion-col size="8" data-test="snowmaking-location">{{ data.alias }}</ion-col>
    <ion-col class="ion-text-right" data-test="snowmaking-wetbulbtemperature">{{ data.wetBulbTemperature }}</ion-col>
    <ion-col class="ion-text-right" data-test="snowmaking-temperature">{{ data.temperature }}</ion-col>
    <ion-col class="ion-text-right" data-test="snowmaking-windspeed">{{ data.windSpeed }}</ion-col>
  </ion-row>
</ion-grid>
