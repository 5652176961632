export interface MediaCenterConfiguration {
  infoBannerType: MediaCenterInfoBannerType;
  languages: string[];
  infoBannerMaxCharacters: number;
}

export enum MediaCenterInfoBannerType {
  WYSIWYG = 0,
  PlainText = 1,
}
