import { SisConverter } from 'src/app/core/utils/sis-converter';
import { MeteoForecast } from 'src/app/maps/livedata/meteo-forecast.model';
import { SisOutdatedHelper } from 'src/app/maps/livedata/sis-outdated-helper';

const timeoutInMs = 1 * 60 * 60 * 1000; // 1 hour

export class MeteoForecastAdapter {
  static adapt(item: any): MeteoForecast[] {
    const forecastdata: MeteoForecast[] = [];

    if (item) {
      item.forEach((e: any) => {
        forecastdata.push({
          deviceSisId: e.RowKey,
          icingIntensity: SisConverter.toNumberOrUndefined(e.IcingIntensity),
          iceMass: SisConverter.toNumberOrUndefined(e.IceMass),
          windSpeedKmh: SisConverter.toNumberOrUndefined(e.WindSpeedKmh),
          temperatureCelsius: SisConverter.toNumberOrUndefined(e.TemperatureCelsius),
          wetBulbTemperatureCelsius: SisConverter.toNumberOrUndefined(e.WetBulbTemperatureCelsius),
          outdated: SisOutdatedHelper.isOutdated(e.Timestamp, timeoutInMs),
        });
      });
    }

    return forecastdata;
  }
}
