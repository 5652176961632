import { Component, Input, OnInit } from '@angular/core';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-select-destination-item',
  templateUrl: './select-destination-item.component.html',
  styleUrls: ['./select-destination-item.component.scss'],
})
export class SelectDestinationItemComponent extends Unsubscriber implements OnInit {
  @Input() tenant: Tenant;
  @Input() selectedTenantGuid: string;
  @Input() isSelected: boolean;

  logoUrl: string;


  constructor() {
    super();
  }

  ngOnInit() {
    this.logoUrl = this.tenant.logoFilename ? environment.baseUrlCockpitBlobStorage + '/public-assets/logo/' + this.tenant.logoFilename : '';
  }
}
