import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { InfoBannerMappingUpdatedEvent, InfoBannerUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { InfoBannerAdapter } from 'src/app/media-center/screens/infobanner-modal/domain/infobanner.adapter';
import { InfoBanner } from 'src/app/media-center/screens/infobanner-modal/domain/infobanner.model';
import { InfoBannerMapping } from 'src/app/media-center/screens/infobanner-modal/domain/infobanner-mapping.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InfoBannerService {
  private static readonly requestInfoBannersUrl = '/sismedia/infobanner';
  private static readonly updateInfoBannerUrl = '/sismedia/infobanner/update';
  private static readonly updateInfoBannerMappingUrl = '/sismedia/infobanner/mapping/update';

  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_MEDIACENTER_INFOBANNER, FeatureAccessLevel.READ);

  private readonly infoBannerRequest$: Observable<InfoBanner[]> = this.destinationService.selectedTenantFeatures$.pipe(
    filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
    switchMap(() => this.http.get(`${environment.baseUrlApi}${InfoBannerService.requestInfoBannersUrl}`)),
    map((data) => InfoBannerAdapter.adapt(data)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly infoBannerUpdate$: Observable<InfoBanner[]> = this.eventBus.observe(InfoBannerUpdatedEvent).pipe(
    withLatestFrom(this.destinationService.selectedTenant$),
    filter(([updatedEvent, tenant]) => updatedEvent?.infoBanner.tenantGuid === tenant?.guid),
    withLatestFrom(this.infoBannerRequest$),
    map(([[updatedEvent], infoBanner]) => {
      const updatedInfoBanner = infoBanner.find((s) => s.guid === updatedEvent.infoBanner.guid);

      if (updatedInfoBanner) {
        if (updatedEvent.updateSuccessful) {
          if (updatedEvent.removed) {
            infoBanner.splice(infoBanner.indexOf(updatedInfoBanner), 1);
          } else {
            Object.assign(updatedInfoBanner, updatedEvent.infoBanner);
          }
        }
      } else {
        if (updatedEvent.updateSuccessful) {
          infoBanner.push(updatedEvent.infoBanner);
        }
      }
      this.presentToast(updatedEvent.updateSuccessful, 'infobannerUpdate', updatedEvent.removed);

      return infoBanner;
    })
  );

  private readonly infoBannerMappingUpdate$: Observable<InfoBanner[]> = this.eventBus
    .observe(InfoBannerMappingUpdatedEvent)
    .pipe(
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([updatedEvent, tenant]) => updatedEvent?.tenantGuid === tenant?.guid),
      withLatestFrom(this.infoBannerRequest$),
      map(([[updatedEvent], infoBanners]) => {
        updatedEvent.infoBannerMappings.forEach((infoBannerMaping) => {
          const updatedInfoBanner = infoBanners.find((s) => s.guid === infoBannerMaping.infoBannerGuid);

          if (updatedInfoBanner) {
            if (updatedEvent.updateSuccessful) {
              updatedInfoBanner.screenGuids = infoBannerMaping.screenGuids;
              Object.assign(infoBanners, updatedEvent.infoBanners);
            }
          } else {
            if (updatedEvent.updateSuccessful) {
              Object.assign(infoBanners, updatedEvent.infoBanners);
            }
          }
        });
        this.presentToast(updatedEvent.updateSuccessful, 'mapping', false);

        return infoBanners;
      })
    );

  readonly infoBanner$ = merge(this.infoBannerRequest$, this.infoBannerUpdate$, this.infoBannerMappingUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private eventBus: EventBusService,
    private http: HttpClient,
    private userMessageService: UserMessageService,
    private destinationService: DestinationService
  ) { }

  updateInfoBanner(infoBanner: InfoBanner): Observable<InfoBanner> {
    return this.update(infoBanner, false);
  }

  updateInfoBannerMapping(infoBannerMappings: InfoBannerMapping[]): Observable<InfoBannerMapping[]> {
    return this.updateMappings(infoBannerMappings);
  }

  deleteInfoBanner(infoBanner: InfoBanner): Observable<InfoBanner> {
    return this.update(infoBanner, true);
  }

  private update(infoBanner: InfoBanner, deleting: boolean): Observable<InfoBanner> {
    return this.http
      .post(`${environment.baseUrlApi}${InfoBannerService.updateInfoBannerUrl}`, {
        infoBanner,
        delete: deleting,
      })
      .pipe(map(() => infoBanner));
  }

  private updateMappings(infoBannerMappings: InfoBannerMapping[]): Observable<InfoBannerMapping[]> {
    return this.http
      .post(`${environment.baseUrlApi}${InfoBannerService.updateInfoBannerMappingUrl}`, {
        infoBannerMappings: infoBannerMappings,
      })
      .pipe(map(() => infoBannerMappings));
  }

  presentToast(success: boolean, operation: string, deleted: boolean): void {
    let message = '';

    switch (operation) {
      case 'mapping':
        message = success ? 'infobanner.phrase.infoBannerUpdated' : 'infobanner.phrase.infoBannerUpdateFailed';
        break;
      case 'infobannerUpdate':
        message = success
          ? deleted
            ? 'infobanner.phrase.infoBannerTextDeleted'
            : 'infobanner.phrase.infoBannerTextUpdated'
          : deleted
            ? 'infobanner.phrase.infoBannerTextDeleteFailed'
            : 'infobanner.phrase.infoBannerTextUpdateFailed';
        break;
      case 'infobannerNew':
        message = success ? 'infobanner.phrase.newInfoBannerCreated' : 'infobanner.phrase.newInfoBannerFailed';
        break;
      default:
        return;
    }

    const color = success ? UserMessageColor.green : UserMessageColor.red;
    const icon = success ? UserMessageIcon.success : UserMessageIcon.failed;
    this.userMessageService.presentToast(new UserMessage({ message, icon, durationMs: 2000, position: 'top', color }));
  }
}
