<ion-content #ionContent id="ion-content-widget-list" *ngIf="!this.isIphone">
  <ion-grid class="sis-grid ion-no-padding">
    <ion-row></ion-row>
    <div *ngFor="let row of this.widgets; let idx = index">
      <ion-row>
        <ion-col
          *ngFor="let widget of row"
          (click)="onClick(widget)"
          [ngClass]="getWidgetClass(widget)"
          [matTooltip]="this.bigScreenMode ? (widget?.tooltip | translate) : null"
          data-test="sis-widget"
          class="widget-container"
        >
          <div *ngIf="widget" [ngStyle]="{ cursor: widgetCursor(widget) }" #widgetCol>
            <svg
              *ngIf="widget.expandable && !widget.expanded"
              class="svg-absolute"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 187.5 225"
            >
              <g id="expandTriangle" data-test="sis-expandable">
                <rect width="187.5" height="225" style="fill: none" />
                <g>
                  <polygon
                    points="158.5 220 182.5 220 182.5 196 158.5 220"
                    transform="translate(-2 5)"
                    style="fill: #91cddb"
                  />
                </g>
              </g>
            </svg>

            <svg
              *ngIf="hasLink(widget)"
              class="svg-absolute"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 187.5 225"
            >
              <g id="externalLink">
                <rect width="187.5" height="225" style="fill: none" />
                <g transform="rotate(90) translate(65, -347) scale(0.88)">
                  <path
                    d="M176.58,196.92v5.27a3.89,3.89,0,0,1,.5,1.92v8a4,4,0,0,1-8,0v-8a3.89,3.89,0,0,1,.5-1.92v-5.27a8,8,0,0,0-4.5,7.19v8a8,8,0,0,0,16,0v-8A8,8,0,0,0,176.58,196.92Z"
                    style="fill: #91cddb"
                  />
                  <path
                    d="M163.58,212.11v-5.27a3.89,3.89,0,0,1-.5-1.92v-8a4,4,0,0,1,8,0v8a3.89,3.89,0,0,1-.5,1.92v5.27a8,8,0,0,0,4.5-7.19v-8a8,8,0,0,0-16,0v8A8,8,0,0,0,163.58,212.11Z"
                    style="fill: #91cddb"
                  />
                </g>
              </g>
            </svg>

            <ng-container *ngComponentOutlet="widget.component"></ng-container>
          </div>
        </ion-col>
      </ion-row>
      <div *ngFor="let widget of row">
        <ion-row
          *ngIf="widget && widget.expanded"
          [ngStyle]="{ cursor: detailCursor(widget) }"
          (click)="presentModal(widget)"
        >
          <ion-col>
            <div
              [ngStyle]="{ 'max-height.px': widget.detailView.height, 'height.px': widget.detailView.height }"
              id="detailview-container"
              data-test="sis-detail-view-container"
            >
              <ng-container *ngComponentOutlet="widget.detailView.component"></ng-container>
            </div>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="widget && widget.expanded" class="sis-grid-bottom"> </ion-row>
      </div>
    </div>
  </ion-grid>
</ion-content>

<ion-grid class="sis-grid ion-no-padding" *ngIf="this.isIphone">
  <ion-row></ion-row>
  <div *ngFor="let rows of widgets; let idx = index">
    <ion-row>
      <ion-col
        *ngFor="let widget of rows"
        [matTooltip]="null"
        (click)="onClick(widget)"
        [ngClass]="getWidgetClass(widget)"
      >
        <div *ngIf="widget" [ngStyle]="{ cursor: widgetCursor(widget) }" #widgetCol>
          <svg
            *ngIf="widget.expandable && !widget.expanded"
            class="svg-absolute"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 187.5 225"
          >
            <g id="expandTriangle">
              <rect width="187.5" height="225" style="fill: none" />
              <g>
                <polygon
                  points="158.5 220 182.5 220 182.5 196 158.5 220"
                  transform="translate(-2 5)"
                  style="fill: #91cddb"
                />
              </g>
            </g>
          </svg>

          <svg *ngIf="hasLink(widget)" class="svg-absolute" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187.5 225">
            <g id="externalLink">
              <rect width="187.5" height="225" style="fill: none" />
              <g transform="rotate(90) translate(65, -347) scale(0.88)">
                <path
                  d="M176.58,196.92v5.27a3.89,3.89,0,0,1,.5,1.92v8a4,4,0,0,1-8,0v-8a3.89,3.89,0,0,1,.5-1.92v-5.27a8,8,0,0,0-4.5,7.19v8a8,8,0,0,0,16,0v-8A8,8,0,0,0,176.58,196.92Z"
                  style="fill: #91cddb"
                />
                <path
                  d="M163.58,212.11v-5.27a3.89,3.89,0,0,1-.5-1.92v-8a4,4,0,0,1,8,0v8a3.89,3.89,0,0,1-.5,1.92v5.27a8,8,0,0,0,4.5-7.19v-8a8,8,0,0,0-16,0v8A8,8,0,0,0,163.58,212.11Z"
                  style="fill: #91cddb"
                />
              </g>
            </g>
          </svg>

          <div>
            <ng-container *ngComponentOutlet="widget.component"></ng-container>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <div *ngFor="let widget of rows">
      <ion-row
        *ngIf="widget && widget.expanded"
        [ngStyle]="{ cursor: detailCursor(widget) }"
        (click)="presentModal(widget)"
      >
        <ion-col>
          <div
            [ngStyle]="{ 'max-height.px': widget.detailView.height, 'height.px': widget.detailView.height }"
            id="detailview-container"
          >
            <ng-container *ngComponentOutlet="widget.detailView.component"></ng-container>
          </div>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="widget && widget.expanded" class="sis-grid-bottom"> </ion-row>
    </div>
  </div>
</ion-grid>
