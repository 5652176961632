import { Component } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { STnetStatus } from 'src/app/core/stnet/stnet-status.model';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MeteoInfoItem } from 'src/app/meteo-info/domain/meteo-info-item.model';
import { MeteoInfoService } from 'src/app/meteo-info/meteo-info.service';

@Component({
  selector: 'sis-meteo-info-page',
  templateUrl: './meteo-info.page.html',
  styleUrls: ['./meteo-info.page.scss'],
})
export class MeteoInfoPage extends Unsubscriber {
  private readonly displayedMeteoInfoItems$: Observable<MeteoInfoItem[][]> = combineLatest([
    this.meteoInfoService.meteoInfoItems,
    this.screenSizeService.bigScreenMode$,
  ]).pipe(
    takeUntil(this.onDestroy$),
    map(([meteoInfoItems, bigScreenMode]) => {
      if (!meteoInfoItems?.length) {
        return [];
      }

      const columns = bigScreenMode ? 4 : 1;
      const rows = meteoInfoItems.length / columns;
      const displayedItems: MeteoInfoItem[][] = [];

      for (let i = 0; i < rows; i++) {
        displayedItems.push([]);
        for (let j = i * columns; j < i * columns + columns; j++) {
          if (meteoInfoItems[j]) {
            displayedItems[i].push(meteoInfoItems[j]);
          }
        }
      }

      return displayedItems;
    })
  );

  private readonly validIds: boolean[] = [];
  private readonly modifiedIds: boolean[] = [];
  private readonly allItemsValid$ = new Subject<boolean>();
  private readonly anyItemEdited$ = new Subject<boolean>();

  bigScreenMode: boolean;
  lastChanged: MeteoInfoItem;
  displayedMeteoInfoItems: { source: MeteoInfoItem[][]; editable: MeteoInfoItem[][] };
  writePermission: boolean;
  anyItemEdited: boolean;
  allItemsValid: boolean;
  stnetStatusArray: STnetStatus[] = [];
  isSaving: boolean;
  hasSTnetExport: boolean;
  showSTnetButton: boolean;

  constructor(
    private meteoInfoService: MeteoInfoService,
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService
  ) {
    super();

    this.allItemsValid$.pipe(debounceTime(1), takeUntil(this.onDestroy$)).subscribe((b) => (this.allItemsValid = b));
    this.anyItemEdited$.pipe(debounceTime(1), takeUntil(this.onDestroy$)).subscribe((b) => (this.anyItemEdited = b));
  }

  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((screenMode) => (this.bigScreenMode = screenMode));

    combineLatest([this.displayedMeteoInfoItems$, this.destinationService.selectedTenantFeatures$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([meteoInfoItems, features]) => {
        this.resetDisplayedMeteoInfoItems(meteoInfoItems);

        const allItems = meteoInfoItems.reduce((accumulator, value) => accumulator.concat(value), []);
        const lastChange = new Date(Math.max(...allItems.map((e) => new Date(e.lastChange)?.getTime())));

        this.lastChanged = allItems.find((item) => item.lastChange?.getTime() === lastChange?.getTime());
        this.isSaving = false;

        this.writePermission = features.some((f) =>
          f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_METEOINFO, FeatureAccessLevel.WRITE))
        );

        this.hasSTnetExport = meteoInfoItems.some((items) => items.some((item) => !!item.hasSTnetExport));

        this.showSTnetButton =
          this.hasSTnetExport &&
          features.some((f) =>
            f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_STNET, FeatureAccessLevel.WRITE))
          );
      });

    this.meteoInfoService.stnetStatusArray$.pipe(takeUntil(this.onDestroy$)).subscribe((stnetStatusArray) => {
      this.stnetStatusArray = stnetStatusArray;
    });
  }

  reset(): void {
    if (!this.isSaving) {
      this.resetDisplayedMeteoInfoItems(this.displayedMeteoInfoItems.source);
    }
  }

  async save(): Promise<void> {
    if (this.writePermission && this.allItemsValid && !this.isSaving) {
      const meteoInfoItems = [];
      for (const c of this.displayedMeteoInfoItems.editable) {
        for (const m of c) {
          if (m != null) {
            meteoInfoItems.push(m);
          }
        }
      }

      if (meteoInfoItems.length > 0) {
        this.isSaving = true;

        const success = await this.meteoInfoService.saveMeteoInfoItems(meteoInfoItems);
        if (!success) {
          this.isSaving = false;
        }
      }
    }
  }

  async exportSTnetData(): Promise<void> {
    await this.meteoInfoService.exportSTnetMeteoInfoItems();
  }

  onFormDataValid(event: boolean, index: number): void {
    this.validIds[index] = event;
    this.allItemsValid$.next(!this.validIds.some((b) => !b));
  }

  onFormDataModified(event: boolean, index: number): void {
    this.modifiedIds[index] = event;
    this.anyItemEdited$.next(this.modifiedIds.some((b) => b));
  }

  resetDisplayedMeteoInfoItems(source: MeteoInfoItem[][]): void {
    const editable: MeteoInfoItem[][] = [];

    for (const row of source) {
      const copies: MeteoInfoItem[] = [];
      editable.push(copies);
      for (const meteoInfo of row) {
        copies.push({
          altitude: meteoInfo.altitude,
          avalancheDangerLevel: meteoInfo.avalancheDangerLevel,
          changedBy: meteoInfo.changedBy,
          changedByEmail: meteoInfo.changedByEmail,
          freshSnow: meteoInfo.freshSnow,
          guid: meteoInfo.guid,
          lastChange: meteoInfo.lastChange,
          latestSnowfallUtc: meteoInfo.latestSnowfallUtc,
          location: meteoInfo.location,
          slopeConditionId: meteoInfo.slopeConditionId,
          snowConditionId: meteoInfo.snowConditionId,
          hasSTnetExport: meteoInfo.hasSTnetExport,
          hasWeatherImport: meteoInfo.hasWeatherImport,
          snowDepth: meteoInfo.snowDepth,
          temperature: meteoInfo.temperature,
          windSpeed: meteoInfo.windSpeed,
          weather: meteoInfo.weather,
          tenantGuid: meteoInfo.tenantGuid,
        });
      }
    }

    this.displayedMeteoInfoItems = { source, editable };
  }
}
