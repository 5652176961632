import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { ExportAdapter } from 'src/app/domain/export/export.adapter';
import { Export } from 'src/app/domain/export/export.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  readonly exports$: Observable<Export[]> = this.destinationService.selectedTenant$.pipe(
    switchMap(() => this.httpClient.get(`${environment.baseUrlApi}/export/all`)),
    map((data) => ExportAdapter.adapt(data)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(private destinationService: DestinationService, private httpClient: HttpClient) {}
}
