import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { LastSlopeControl } from 'src/app/last-slope-control/domain/last-slope-control.model';
import { LastSlopeControlService } from 'src/app/last-slope-control/last-slope-control.service';

@Component({
  selector: 'sis-zone-item',
  templateUrl: './zone-item.component.html',
  styleUrls: ['./zone-item.component.scss'],
})
export class ZoneItemComponent extends Unsubscriber implements OnInit {
  private static readonly requiredFeature = new Feature(FeatureId.SISMEDIA_LASTSLOPECONTROL, FeatureAccessLevel.WRITE);

  @Input() guid: string;

  editItem: LastSlopeControl;
  writePermission: boolean;
  bigScreenMode: boolean;
  hasChanged: boolean = false;

  private item: LastSlopeControl;

  constructor(
    private screenSizeService: ScreenSizeService,
    private lastSlopeControlService: LastSlopeControlService,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit() {
    this.lastSlopeControlService.lastSlopeControls$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.item = data.find((d) => d.guid === this.guid);
      this.editItem = JSON.parse(JSON.stringify(this.item));
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission =
        features.some((f) => f.hasMinimumRequirementFor(ZoneItemComponent.requiredFeature)) ?? false;
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));
  }

  timeChanged(event: any, timeIndex: number): void {
    const newTime = event.target.value;
    if (!this.writePermission || this.editItem.times[timeIndex] === newTime) {
      return;
    }

    this.editItem.times[timeIndex] = newTime;

    this.hasChanged = this.hasUnsavedChanges();
  }

  radioGroupChange(): void {
    this.hasChanged = this.hasUnsavedChanges();
  }

  reset() {
    this.editItem = JSON.parse(JSON.stringify(this.item));
    this.hasChanged = false;
  }

  async saveEntry(): Promise<void> {
    if (this.writePermission) {
      this.hasChanged = false;
      await this.lastSlopeControlService.updateLastSlopeControl(this.editItem);
    }
  }

  private hasUnsavedChanges(): boolean {
    return (
      this.item.activeTime !== this.editItem.activeTime || this.item.times.some((t) => !this.editItem.times.includes(t))
    );
  }
}
