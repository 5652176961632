import { Component, Input } from '@angular/core';
import { TimetableItem } from 'src/app/timetable/domain/timetable-item.model';
import { TimetableJourney } from 'src/app/timetable/domain/timetable-journey.model';
import { TimetableStation } from 'src/app/timetable/domain/timetable-station.model';

@Component({
  selector: 'sis-timetable-journey-row-mobile',
  templateUrl: './journey-row-mobile.component.html',
  styleUrls: ['./journey-row-mobile.component.scss'],
})
export class JourneyRowMobileComponent {
  @Input() journey: TimetableJourney;
  @Input() stations: TimetableStation[] = [];

  stationTimetableItemMapping = new Map<string, TimetableItem>();

  ngOnChanges(): void {
    if (this.journey && this.stations) {
      this.stationTimetableItemMapping.clear();
      this.stations.forEach((s) => {
        this.stationTimetableItemMapping.set(
          s.guid,
          this.journey.items?.find((i) => i.stationGuids.includes(s.guid))
        );
      });
    }
  }
}
