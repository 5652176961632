import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { FormattedTimestamp } from 'src/app/core/utils/formatted-timestamp';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { Alarm, AlarmLevel } from 'src/app/domain/alarm.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';

@Component({
  selector: 'sis-alarmlist-item',
  templateUrl: './alarmlist-item.component.html',
  styleUrls: ['./alarmlist-item.component.scss'],
})
export class AlarmlistItemComponent extends Unsubscriber implements OnInit {
  private static readonly alarmClass = 'sis-alarm-item';
  private static readonly warningClass = 'sis-warning-item';
  private static readonly infoClass = 'sis-info-item';

  @Input() index: number;
  @Input() bigScreenMode: boolean;
  @Input() alarm: Alarm;

  timestamp: FormattedTimestamp;
  alias: string;
  cssClass: string;
  levelTranslateString: string;

  constructor(
    private userSettingService: UserSettingsService,
    private timeFormatService: TimeFormatService,
    private translateService: TranslateService,
    private masterdataService: MasterdataService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.alarm) {
      this.timestamp = new FormattedTimestamp(this.userSettingService, this.translateService, this.timeFormatService, this.alarm.timestamp);

      this.masterdataService.masterData$.pipe(takeUntil(this.onDestroy$)).subscribe((masterData) => {
        const ginStationName = this.alarm.code.startsWith('GIN') ? this.alarm.info : null;
        this.alias = masterData.ropeways.find((r) => Alarm.includesSisId(this.alarm, r.sisId))?.alias ?? ginStationName;
      });

      this.cssClass = this.getCssClass();
      this.levelTranslateString = this.getLevelTranslateString(this.alarm.level);
    }
  }

  private getLevelTranslateString(level: string): string {
    return `alarming.term.${level}`;
  }

  private getCssClass(): string {
    if (this.alarm.level === AlarmLevel.WARNING) {
      return AlarmlistItemComponent.warningClass;
    }

    if (this.alarm.level === AlarmLevel.ALARM || this.alarm.level === AlarmLevel.FAULT || this.alarm.level === AlarmLevel.SYSTEM) {
      return AlarmlistItemComponent.alarmClass;
    }

    return AlarmlistItemComponent.infoClass;
  }
}
