import 'hammerjs';
import '@angular/compiler';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'src/app/app.module';
import { environment } from 'src/environments/environment';

if (window['supported'] && !window['outdated']) {
  if (environment.production) {
    enableProdMode();
    if (window) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.console.log = () => {};
    }
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
}
