<ion-grid class="sis-global-page-grid sis-global-no-scrollbar" data-test="weblink-collection-grid">
  <sis-page-title
    [title]="'general.term.sisitservices'"
  >
  </sis-page-title>

  <ion-row *ngIf="this.bigScreenMode" class="sis-it-services-header">
    <ion-col size="6">{{ "general.term.device" | translate }}</ion-col>
    <ion-col size="3">{{ "general.term.status" | translate }}</ion-col>
    <ion-col size="3">{{ "general.term.lastUpdate" | translate }}</ion-col>
  </ion-row>

  <ion-row *ngIf="!this.bigScreenMode" class="sis-it-services-header-small-screen">
    <ion-col size="6">{{ "general.term.device" | translate }}</ion-col>
    <ion-col size="2" class="sis-global-center">{{ "general.term.status" | translate }}</ion-col>
    <ion-col size="4" class="sis-global-right">{{ "general.term.lastUpdate" | translate }}</ion-col>
  </ion-row>
  <sis-nms-item
    *ngFor="let item of nmsDatas"
    [item]="item"
    [bigScreenMode]="this.bigScreenMode"
    class="sis-alternate-background"
  >
  </sis-nms-item>
</ion-grid>
