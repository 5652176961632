import { Component, Input } from '@angular/core';
import { LayerGroup } from 'leaflet';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';

@Component({
  selector: 'sis-meteo-layer',
  templateUrl: './meteo-layer.component.html',
})
export class MeteoLayerComponent {
  @Input() layerGroup: LayerGroup;
  @Input() meteoStations: MeteoStation[];
  @Input() editMode: boolean;
}
