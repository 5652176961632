import { Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';

@Component({
  selector: 'sis-timeformat-button',
  templateUrl: './timeformat-button.component.html',
  styleUrls: ['./timeformat-button.component.scss'],
})
export class TimeformatButtonComponent extends Unsubscriber {
  private timeFormat: number;

  constructor(private userSettingService: UserSettingsService) {
    super();
    this.userSettingService.userSettings$.pipe(takeUntil(this.onDestroy$)).subscribe((userSettings) => {
      this.timeFormat = userSettings.timeFormat;
    });
  }

  async switchTimeFormat(): Promise<void> {
    await this.userSettingService.updateUserSettings({
      timeFormat: (this.timeFormat % 2) + 1,
    });
  }
}
