<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <g>
          <path
            d="M 93.75 28.130859 L 93.75 46.730469 A 47.02 47.02 0 0 1 140.76953 93.75 A 47.02 47.02 0 0 1 93.75 140.76953 A 47.02 47.02 0 0 1 46.730469 93.75 L 28.130859 93.75 A 65.620003 65.620003 0 0 0 93.75 159.36914 A 65.620003 65.620003 0 0 0 159.36914 93.75 A 65.620003 65.620003 0 0 0 93.75 28.130859 z "
            style="fill: #91cddb"
          />
          <g id="g1065">
            <g>
              <path
                d="M 105,60.5 93.49,78.56 90.08,83.94 H 105 l -17.81,21.12 4.86,-11.54 2,-4.86 H 80.66 L 94.74,60.5 H 105 M 111.37,57 H 92.58 L 75,92.16 H 88.83 L 75,125 112.5,80.47 H 96.44 L 111.33,57 Z"
                style="fill: #989898"
              />
            </g>
          </g>
          <text
            [attr.x]="textPositionX"
            [attr.y]="textPositionY"
            id="value"
            style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
            [style.font-size]="fontSize"
          >
            {{ displayedValue }}
            <tspan id="unit" style="font-size: 24px">kW</tspan>
          </text>
        </g>
        <g id="g1065">
          <g id="path1010">
            <path
              d="M105,60.5,93.49,78.56l-3.41,5.38H105L87.19,105.06l4.86-11.54,2-4.86H80.66L94.74,60.5H105m6.37-3.5H92.58L75,92.16H88.83L75,125l37.5-44.53H96.44L111.33,57Z"
              style="fill: #989898"
            />
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
