import moment from 'moment';
import { TimetableIntervalDay } from 'src/app/timetable/domain/timetable-interval-day.model';

export class TimetableIntervalDayAdapter {
  static adapt(day: any): TimetableIntervalDay {
    if (day) {
      return {
        guid: day.guid,
        intervalGuid: day.intervalGuid,
        date: new Date(day.date),
        operatingStart: TimetableIntervalDayAdapter.formatTime(day.operatingStart),
        operatingEnd: TimetableIntervalDayAdapter.formatTime(day.operatingEnd),
        journeys: (day.journeys as any[]).map((journey: any) => ({
          guid: journey.guid,
          intervalDayGuid: journey.intervalDayGuid,
          direction: journey.direction,
          items: (journey.items as any[]).map((item: any) => ({
            guid: item.guid,
            intervalJourneyGuid: item.intervalJourneyGuid,
            stationGuid: item.stationGuid,
            departureDateTimeLocal: new Date(item.departureDateTimeLocal),
          })),
        })),
      };
    }

    return null;
  }

  private static formatTime(time: string) {
    return moment(time, 'HH:mm:ss').format('HH:mm');
  }
}
