<ion-grid class="sis-grid">
  <ion-row><ion-col></ion-col></ion-row>
  <ion-row *ngIf="this.showWeatherImportRows" class="sis-global-input sis-text-row">
    <ion-col>{{ 'meteoinfo.term.weather' | translate }}</ion-col>
  </ion-row>
  <ion-row class="sis-global-input sis-text-row">
    <ion-col>{{ 'meteoinfo.term.temperature' | translate }}</ion-col>
  </ion-row>
  <ion-row class="sis-global-input sis-text-row">
    <ion-col>{{ 'meteoinfo.term.windSpeed' | translate }}</ion-col>
  </ion-row>
  <ion-row class="sis-global-input sis-text-row">
    <ion-col>
      {{ 'meteoinfo.term.snowDepth' | translate }}
      <span class="sis-stnet-info" *ngIf="this.hasSTnetExport">STnet</span></ion-col
    >
  </ion-row>
  <ion-row class="sis-global-input sis-text-row">
    <ion-col>
      {{ 'meteoinfo.term.freshSnow' | translate }}
      <span class="sis-stnet-info" *ngIf="this.hasSTnetExport">STnet</span></ion-col
    >
  </ion-row>
  <ion-row class="sis-global-input sis-text-row">
    <ion-col>
      {{ 'meteoinfo.term.latestSnowfall' | translate }}
      <span class="sis-stnet-info" *ngIf="this.hasSTnetExport">STnet</span>
    </ion-col>
  </ion-row>
  <ion-row class="sis-global-input sis-text-row">
    <ion-col> {{ 'meteoinfo.term.avalancheDangerLevel' | translate }}</ion-col>
  </ion-row>
  <ion-row class="sis-global-input sis-text-row">
    <ion-col> {{ 'meteoinfo.term.slopeCondition' | translate }}</ion-col>
  </ion-row>
  <ion-row class="sis-global-input sis-text-row">
    <ion-col> {{ 'meteoinfo.term.snowCondition' | translate }}</ion-col>
  </ion-row>
</ion-grid>
