import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RopewaySpeedTrendAdapter } from 'src/app/maps/trenddata/ropeway-speed-trend.adapter';
import { RopewaySpeedTrend } from 'src/app/maps/trenddata/ropeway-speed-trend.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RopewaySpeedTrendService {
  constructor(private http: HttpClient) {}

  getSpeedData(ropewaySisId: string): Observable<RopewaySpeedTrend[]> {
    const url = environment.baseUrlApi + `/trenddata/speed/` + ropewaySisId;
    return this.http.get(url).pipe(map((data) => RopewaySpeedTrendAdapter.adapt(data)));
  }
}
