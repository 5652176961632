import { WetBulbTrend } from 'src/app/maps/trenddata/wet-bulb-trend.model';

export class WetBulbTrendAdapter {
  static adapt(items: any): WetBulbTrend[] {
    const trends: WetBulbTrend[] = [];

    if (items && items.length > 0) {
      let lastTimestampMs = new Date(items[0].timestamp).getTime();
      items.forEach((e: any) => {
        const timestampMs = new Date(e.timestamp).getTime();
        const timestampMsMaxExpectedValue = lastTimestampMs + 600_000;
        if (timestampMs > timestampMsMaxExpectedValue) {
          trends.push({ timestamp: new Date(timestampMsMaxExpectedValue) });
        }
        lastTimestampMs = timestampMs;

        trends.push({
          wetBulbTemperatureCelsius:
            e.wetBulbTemperatureCelsiusAverage != null ? Number(e.wetBulbTemperatureCelsiusAverage) : null,
          timestamp: new Date(e.timestamp),
        });
      });
    }

    return trends;
  }
}
