<svg
  id="uuid-fee14e00-ee55-45e2-9e4c-a24b54573e7b"
  viewBox="0 0 187.5 225"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg"
>
  <defs id="defs1">
    <style id="style1">
      .uuid-f0b68364-b866-43c6-b237-a159b2745891 {
        fill: #fb970b;
      }
      .uuid-41e83504-e248-4923-8b17-3378420fc069 {
        fill: #069;
      }
      .uuid-029f8ef7-b855-4cdb-9138-eab8c7a73ce2 {
        fill: #e5e5e5;
      }
      .uuid-138837b4-77c1-4b18-95f9-8e9ca9fc3935 {
        fill: #9bcbf0;
      }
      .uuid-1ef9851e-227d-42a7-a184-a5a5f5cc6e2a {
        fill: #d1e7f8;
      }
      .uuid-f4fc4605-119f-4b64-baec-ac1f63a1d833 {
        fill: #d5d5d5;
      }
      .uuid-649bafaf-0542-435a-9a38-0f3fe5a7f45e {
        fill: #293d47;
      }
    </style>
  </defs>
  <g id="g3" transform="matrix(3.7842793,0,0,3.7842793,33.201531,48.140668)">
    <path
      class="uuid-41e83504-e248-4923-8b17-3378420fc069"
      d="M 1.5,27 C 1.37,27 1.24,26.95 1.15,26.85 C 0.95,26.65 0.95,26.34 1.15,26.14 L 3.15,24.14 C 3.35,23.94 3.66,23.94 3.86,24.14 C 4.06,24.34 4.06,24.65 3.86,24.85 L 1.86,26.85 C 1.76,26.95 1.63,27 1.51,27 Z"
      id="path1"
    />
    <path
      class="uuid-41e83504-e248-4923-8b17-3378420fc069"
      d="M 5.5,23 C 5.37,23 5.24,22.95 5.15,22.85 C 4.95,22.65 4.95,22.34 5.15,22.14 L 7.15,20.14 C 7.35,19.94 7.66,19.94 7.86,20.14 C 8.06,20.34 8.06,20.65 7.86,20.85 L 5.86,22.85 C 5.76,22.95 5.63,23 5.51,23 Z"
      id="path2"
    />
    <path
      class="uuid-41e83504-e248-4923-8b17-3378420fc069"
      d="M 1.5,23 C 1.37,23 1.24,22.95 1.15,22.85 C 0.95,22.65 0.95,22.34 1.15,22.14 L 3.15,20.14 C 3.35,19.94 3.66,19.94 3.86,20.14 C 4.06,20.34 4.06,20.65 3.86,20.85 L 1.86,22.85 C 1.76,22.95 1.63,23 1.51,23 Z"
      id="path3"
    />
  </g>
  <g id="g6" transform="matrix(3.7842793,0,0,3.7842793,33.201531,48.140668)">
    <path
      class="uuid-1ef9851e-227d-42a7-a184-a5a5f5cc6e2a"
      d="M 28.5,14.5 H 27.5 V 12.5 C 27.5,10.29 25.71,8.5 23.5,8.5 C 22.96,4.6 19.54,1.5 15.5,1.5 C 11.46,1.5 7.99,4.55 7.5,8.5 H 6.5 C 3.74,8.5 1.5,10.74 1.5,13.5 C 1.5,16.26 3.74,18.5 6.5,18.5 H 14.5 L 15.5,16.5 L 17.5,20.5 L 19.5,14.5 L 20.5,18.5 H 28.5 C 29.6,18.5 30.5,17.6 30.5,16.5 C 30.5,15.4 29.6,14.5 28.5,14.5 Z"
      id="path4"
    />
    <path
      class="uuid-138837b4-77c1-4b18-95f9-8e9ca9fc3935"
      d="M 24.5,17.5 V 18.5 H 20.5 L 19.5,14.5 L 17.5,20.5 L 15.5,16.5 L 14.5,18.5 H 10.5 V 17.5 C 10.5,13.63 13.63,10.5 17.5,10.5 C 21.37,10.5 24.5,13.63 24.5,17.5 Z"
      id="path5"
    />
    <path
      class="uuid-41e83504-e248-4923-8b17-3378420fc069"
      d="M 28.5,19 H 24 V 18 H 28.5 C 29.33,18 30,17.33 30,16.5 C 30,15.67 29.33,15 28.5,15 H 27.5 C 27.22,15 27,14.78 27,14.5 V 12.5 C 27,10.57 25.43,9 23.5,9 C 23.25,9 23.04,8.82 23,8.57 C 22.49,4.89 19.2,2 15.5,2 C 11.8,2 8.46,4.82 8,8.56 C 7.97,8.81 7.76,9 7.5,9 H 6.5 C 4.02,9 2,11.02 2,13.5 C 2,15.98 4.02,18 6.5,18 H 11 V 19 H 6.5 C 3.47,19 1,16.53 1,13.5 C 1,10.47 3.47,8 6.5,8 H 7.07 C 7.79,3.98 11.33,1 15.5,1 C 19.67,1 23.17,3.99 23.93,8.02 C 26.21,8.24 28,10.16 28,12.5 V 14 H 28.5 C 29.88,14 31,15.12 31,16.5 C 31,17.88 29.88,19 28.5,19 Z"
      id="path6"
    />
  </g>
  <g id="g10" transform="matrix(3.7842793,0,0,3.7842793,33.201531,48.140668)">
    <path
      class="uuid-f0b68364-b866-43c6-b237-a159b2745891"
      d="M 17.5,21 C 17.31,21 17.14,20.89 17.05,20.72 L 15.5,17.61 L 14.95,18.72 C 14.87,18.89 14.69,19 14.5,19 H 11 V 18 H 14.19 L 15.05,16.28 C 15.22,15.94 15.77,15.94 15.94,16.28 L 17.4,19.2 L 19.02,14.35 C 19.09,14.14 19.29,14 19.51,14.01 C 19.73,14.01 19.92,14.17 19.98,14.39 L 20.89,18.01 H 24 V 19.01 H 20.5 C 20.27,19.01 20.07,18.85 20.02,18.63 L 19.44,16.3 L 17.98,20.67 C 17.92,20.86 17.74,21 17.54,21.01 C 17.53,21.01 17.52,21.01 17.5,21.01 Z"
      id="path7"
    />
    <path
      class="uuid-029f8ef7-b855-4cdb-9138-eab8c7a73ce2"
      d="M 17.5,10.5 C 13.63,10.5 10.5,13.63 10.5,17.5 C 10.5,18.92 10.77,20.4 11.5,21.5 L 5,28 C 4.69,28.27 4.5,28.56 4.5,29 C 4.5,29.83 5.17,30.5 6,30.5 C 6.44,30.5 6.72,30.3 7,30 L 13.5,23.5 C 14.6,24.23 16.08,24.5 17.5,24.5 C 21.37,24.5 24.5,21.37 24.5,17.5 C 24.5,13.63 21.37,10.5 17.5,10.5 Z M 17.5,23 C 14.47,23 12,20.53 12,17.5 C 12,14.47 14.47,12 17.5,12 C 20.53,12 23,14.47 23,17.5 C 23,20.53 20.53,23 17.5,23 Z"
      id="path8"
    />
    <path
      class="uuid-f4fc4605-119f-4b64-baec-ac1f63a1d833"
      d="M 21.39,13.61 C 22.39,14.61 23,15.98 23,17.5 C 23,20.53 20.53,23 17.5,23 C 15.99,23 14.62,22.39 13.63,21.4 L 5,30 C 5.27,30.29 5.57,30.5 6,30.5 C 6.43,30.5 6.72,30.3 7,30 L 13.5,23.5 C 14.6,24.23 16.08,24.5 17.5,24.5 C 21.37,24.5 24.5,21.37 24.5,17.5 C 24.5,15.57 23.72,13.82 22.45,12.55 Z"
      id="path9"
    />
    <path
      class="uuid-649bafaf-0542-435a-9a38-0f3fe5a7f45e"
      d="M 6,31 C 4.9,31 4,30.1 4,29 C 4,28.31 4.36,27.91 4.67,27.63 L 10.87,21.43 C 10.29,20.4 10,19.11 10,17.5 C 10,13.36 13.36,10 17.5,10 C 21.64,10 25,13.36 25,17.5 C 25,21.64 21.64,25 17.5,25 C 16.4,25 14.87,24.84 13.58,24.13 L 7.35,30.35 C 7.09,30.64 6.68,31 6,31 Z M 17.5,11 C 13.92,11 11,13.92 11,17.5 C 11,19.07 11.31,20.32 11.91,21.22 C 12.04,21.42 12.02,21.68 11.85,21.85 L 5.35,28.35 C 5.08,28.59 5,28.75 5,29 C 5,29.55 5.45,30 6,30 C 6.25,30 6.4,29.92 6.62,29.66 L 13.14,23.14 C 13.31,22.97 13.57,22.95 13.77,23.08 C 14.67,23.67 16,24 17.5,24 C 21.08,24 24,21.08 24,17.5 C 24,13.92 21.08,11 17.5,11 Z"
      id="path10"
    />
  </g>
</svg>
