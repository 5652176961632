import { ForecastTrend } from 'src/app/maps/trenddata/forecast-trend.model';

export class ForecastTrendAdapter {
  static adapt(items: any): ForecastTrend[] {
    const forecastTrends: ForecastTrend[] = [];

    if (items && items.length > 0) {
      items.forEach((e: any) => {
        forecastTrends.push({
          windSpeedKmhMax: e.windSpeedKmhMax,
          windSpeedKmh: e.windSpeedKmh,
          temperatureCelsius: e.temperatureCelsius,
          icingIntensity: e.icingIntensity,
          wetBulbTemperatureCelsius: e.wetBulbTemperatureCelsius,
          timestamp: new Date(e.forecastTime),
        });
      });
    }

    return forecastTrends;
  }
}
