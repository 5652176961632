import { Component, EventEmitter, Output } from '@angular/core';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';

@Component({
  selector: 'sis-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.scss'],
})
export class MaintenanceBannerComponent extends Unsubscriber {
  @Output() hide: EventEmitter<void>;

  bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  constructor(private screenSizeService: ScreenSizeService) {
    super();

    this.hide = new EventEmitter();
  }

  onclick() {
    this.hide.emit();
  }
}
