<div sis-enter-viewport-notifier (visibilityChange)="setMapVisibility($event)" *ngIf="!this.wasVisibleAtLeastOnce" style="height: 40px"></div>
<div
  *ngIf="this.layers && this.drawReady && this.wasVisibleAtLeastOnce"
  leaflet
  class="sis-pano-map"
  [leafletOptions]="options"
  [leafletLayers]="layers"
  (leafletMapReady)="onMapReady($event)"
  [(leafletCenter)]="center"
>
  <sis-meteo-layer [layerGroup]="meteoLayerGroup" [meteoStations]="meteoStations" [editMode]="editMode"></sis-meteo-layer>
  <sis-ropeway-layer [layerGroup]="ropewayLayerGroup" [ropeways]="ropeways"></sis-ropeway-layer>
  <sis-custom-marker-layer [layerGroup]="customMarkerLayerGroup" [customMarkers]="customMarkers" [editMode]="editMode"></sis-custom-marker-layer>
  <ion-img
    class="sis-zoom-in-control"
    *ngIf="bigScreenMode"
    src="../../../assets/icons-map/sis-icon-map-zoom-in.svg"
    (click)="onZoomIn($event)"
  ></ion-img>
  <ion-img
    class="sis-zoom-out-control"
    *ngIf="bigScreenMode"
    src="../../../assets/icons-map/sis-icon-map-zoom-out.svg"
    (click)="onZoomOut($event)"
  ></ion-img>
  <ion-img
    class="sis-fullscreen-control"
    *ngIf="bigScreenMode"
    src="../../../assets/icons-map/sis-icon-map-fullscreen.svg"
    matTooltip="{{ 'map.term.fullscreen' | translate }}"
    (click)="onFullscreen($event)"
    matTooltipPosition="left"
  ></ion-img>
  <ion-img
    class="sis-fit-to-view-control"
    *ngIf="bigScreenMode"
    src="../../../assets/icons-map/sis-icon-map-fit-view.svg"
    matTooltip="{{ 'map.term.center' | translate }}"
    (click)="onFitMapToView($event)"
    matTooltipPosition="left"
  ></ion-img>
  <ion-img
    class="sis-fit-to-height-control"
    *ngIf="bigScreenMode"
    src="../../../assets/icons-map/sis-icon-map-fit-height.svg"
    matTooltip="{{ 'map.term.scaleHeight' | translate }}"
    (click)="onFitMapToHeight($event)"
    matTooltipPosition="left"
  ></ion-img>
  <ion-img
    class="sis-marker-edit-control"
    *ngIf="bigScreenMode && (this.canEditMap$ | async)"
    [src]="this.editMode ? '../../../assets/icons-map/sis-icon-map-edit-active.svg' : '../../../assets/icons-map/sis-icon-map-edit.svg'"
    matTooltip="{{ 'general.term.edit' | translate }}"
    (click)="onEditMap($event)"
    matTooltipPosition="left"
    data-test="sis-marker-edit-control"
  ></ion-img>

  <div class="tooltip" *ngIf="this.popupVisible && this.bigScreenMode">
    <div>
      {{ tooltipText }}
    </div>
  </div>
</div>
