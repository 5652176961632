<ion-grid *ngIf="this.bigScreenMode$ | async" class="sis-global-page-grid sis-page-grid">
  <sis-page-title
    [title]="'general.term.parking' | translate"
    [featureId]="[[this.featureId]]"
    [reorderingEnabled]="this.reorderingEnabled"
    [showAddButton]="false"
    [showReorderButton]="this.hasEditPermission"
    [filterControl]="this.filterControl"
    (onReorderClick)="this.onReorderClick()"
    (onReorderCancelClick)="this.onReorderCancelClick()"
  >
  </sis-page-title>

  <ion-row>
    <ion-col style="padding: 0px">
      <div>
        <ion-reorder-group
          (ionItemReorder)="doReorder($event)"
          [disabled]="!this.reorderingEnabled"
          data-test="sis-parking-reorder-group"
        >
          <ion-item
            *ngFor="let item of this.displayedItems; let odd = odd"
            [ngClass]="odd ? 'sis-white-background' : 'sis-colored-background'"
            class="sis-reorder-group-item"
            lines="none"
          >
            <ion-grid style="padding: 0px">
              <sis-parking-item
                [item]="item"
                [bigScreenMode]="true"
                [showInfoCol]="this.anyItemHasInfo"
                [showButtons]="!this.reorderingEnabled"
                [labelLength]="this.labelMaxLength"
                (lastChange)="this.lastChange = $event"
                data-test="parking-item"
              >
              </sis-parking-item>
            </ion-grid>
            <ion-reorder slot="end">
              <ion-icon name="reorder-two" class="sis-reorder-handle"></ion-icon>
            </ion-reorder>
          </ion-item>
        </ion-reorder-group>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="sis-last-change">
    <ion-col *ngIf="this.lastChange">
      {{ 'general.phrase.lastChange' | translate }}: {{ this.lastChange.name }} ({{ this.lastChange.date |
      dateTimeFormat }})
    </ion-col>
  </ion-row>
</ion-grid>

<!-- Small screen -->
<ion-grid *ngIf="!(this.bigScreenMode$ | async)" class="sis-global-page-grid" style="padding-bottom: 60px">
  <sis-page-title
    [title]="'general.term.parking' | translate"
    [featureId]="[[this.featureId]]"
    [reorderingEnabled]="this.reorderingEnabled"
    [editPermission]="this.hasEditPermission"
    [filterControl]="this.filterControl"
    (onReorderClick)="this.onReorderClick()"
    (onReorderCancelClick)="this.onReorderCancelClick()"
    (openEditModal)="this.openEditModal()"
  >
  </sis-page-title>
  <sis-parking-item
    *ngFor="let item of this.displayedItems"
    [item]="item"
    [bigScreenMode]="false"
    [labelLength]="this.labelMaxLength"
    class="sis-alternate-background"
    (lastChange)="this.lastChange = $event"
  >
  </sis-parking-item>

  <ion-row class="sis-last-change">
    <ion-col *ngIf="this.lastChange">
      {{ 'general.phrase.lastChange' | translate }}: {{ this.lastChange.date | dateTimeFormat }}
    </ion-col>
  </ion-row>
</ion-grid>
