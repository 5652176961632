<ion-row *ngIf="this.gatewayResponses" class="sis-admin-response-box">
  <ion-col>
    Antwort:
    <ion-grid>
      <ion-row *ngFor="let row of this.rowsOfResponses" style="padding: 0px">
        <ion-col style="padding: 0px" *ngFor="let response of row" [size]="this.colSize">
          <ion-icon
            [name]="response.success ? 'checkmark-outline' : 'close-outline'"
            [style.background]="response.success ? 'var(--ion-color-success)' : 'var(--ion-color-danger)'"
            style="display: inline-block; vertical-align: middle; height: 18px"
          ></ion-icon>
          <span style="display: inline-block; vertical-align: middle; margin-left: 5px">{{ response.gatewayId }}</span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-col>
</ion-row>
