import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, Subject } from 'rxjs';
import { catchError, map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { SisMediaAssetOverrideUpdatedEvent } from 'src/app/core/eventbus/events';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { SisMediaItem } from 'src/app/sismedia/domain/sismedia-item.model';
import { SisMediaAssetOverrideEntryAdapter } from 'src/app/sismedia/sismedia-asset-override/domain/sismedia-asset-override-entry.adapter';
import { SisMediaAssetOverrideEntry } from 'src/app/sismedia/sismedia-asset-override/domain/sismedia-asset-override-entry.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SisMediaAssetOverrideService {
  static readonly insertOperation = 'insert';
  static readonly updateOperation = 'update';
  static readonly deleteOperation = 'delete';

  private readonly _insertingEntry$ = new Subject<boolean>();
  private readonly baseRequestUrl: string = '/sismedia/assetoverride/';
  private readonly basePostUrl: string = '/sismedia/assetoverride/update/';

  readonly insertingEntry$ = this._insertingEntry$.asObservable();

  constructor(
    private eventBus: EventBusService,
    private http: HttpClient,
    private screenSizeService: ScreenSizeService,
    private userMessageService: UserMessageService,
    private userSettingService: UserSettingsService
  ) {}

  requestAssetOverrideData(sisMediaItem: SisMediaItem): Observable<SisMediaAssetOverrideEntry[]> {
    const assetOverrideRequest$: Observable<SisMediaAssetOverrideEntry[]> = this.http
      .get(`${environment.baseUrlApi}${this.baseRequestUrl}${sisMediaItem.assetGuid}`)
      .pipe(
        map((data) => SisMediaAssetOverrideEntryAdapter.adapt(data).sort((a, b) => a.priority - b.priority)),
        shareReplay({
          refCount: true,
          bufferSize: 1,
        })
      );

    const assetOverrideUpdate$: Observable<SisMediaAssetOverrideEntry[]> = this.eventBus
      .observe(SisMediaAssetOverrideUpdatedEvent)
      .pipe(
        withLatestFrom(
          assetOverrideRequest$,
          this.userSettingService.userSettings$,
          this.screenSizeService.bigScreenMode$
        ),
        map(([event, assetOverrideData, userSettings, bigScreenMode]) => {
          const updatedEntry = assetOverrideData.find((d) => d.guid === event.sisMediaAssetOverrideEntry.guid);

          if (event.operation === SisMediaAssetOverrideService.updateOperation) {
            Object.assign(updatedEntry, event.sisMediaAssetOverrideEntry);

            if (event.updateSuccessful && event.changedBy === userSettings.userGuid) {
              const translateKey = 'general.phrase.saved';
              const toastPosition = bigScreenMode ? 'top' : 'bottom';
              const userMessage = new UserMessage({
                message: translateKey,
                icon: UserMessageIcon.success,
                durationMs: 2000,
                position: toastPosition,
                color: UserMessageColor.green,
              });

              this.userMessageService.presentToast(userMessage);
            }
          }

          if (event.operation === SisMediaAssetOverrideService.insertOperation) {
            assetOverrideData.push(event.sisMediaAssetOverrideEntry);
            this._insertingEntry$.next(false);
          }

          if (event.operation === SisMediaAssetOverrideService.deleteOperation) {
            assetOverrideData.splice(assetOverrideData.indexOf(updatedEntry), 1);
          }

          assetOverrideData.sort((a, b) => a.priority - b.priority);

          return assetOverrideData;
        })
      );

    return merge(assetOverrideRequest$, assetOverrideUpdate$);
  }

  async updateAssetOverride(assetOverrideData: SisMediaAssetOverrideEntry, operation: string): Promise<void> {
    if (operation === SisMediaAssetOverrideService.insertOperation) {
      this._insertingEntry$.next(true);
    }

    await firstValueFrom(
      this.http
        .post(`${environment.baseUrlApi}${this.basePostUrl}`, {
          SisMediaAssetOverrideEntry: assetOverrideData,
          Operation: operation,
        })
        .pipe(
          withLatestFrom(this.screenSizeService.bigScreenMode$),
          catchError((error: HttpErrorResponse, obs$) =>
            obs$.pipe(
              map(([, bigScreenMode]) => {
                const translateKey = 'general.phrase.saveFailed';
                const toastPosition = bigScreenMode ? 'top' : 'bottom';
                const userMessage = new UserMessage({
                  message: translateKey,
                  icon: UserMessageIcon.failed,
                  durationMs: 2000,
                  position: toastPosition,
                  color: UserMessageColor.red,
                });
                this.userMessageService.presentToast(userMessage);
                this._insertingEntry$.next(false);

                return error.status;
              })
            )
          )
        )
    );
  }
}
