import { Directive, Type } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { BusEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';

@Directive()
export class EventBusSubscriberBase extends Unsubscriber {
  constructor(public eventBus: EventBusService) {
    super();
  }

  observe<T extends BusEvent>(typeProvider: Type<T>, callback: (ev: T) => void) {
    this.eventBus
      .observe(typeProvider)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((ev: T) => callback(ev as T));
  }
}
