import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ContextMenuItem } from 'src/app/core/components/context-menu/context-menu-item.model';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { DeviceListHoverEvent, MapIconClickedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { FormattedTimestamp } from 'src/app/core/utils/formatted-timestamp';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { Alarm, AlarmLevel } from 'src/app/domain/alarm.model';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { AlarmLibraryModal } from 'src/app/maps/alarmlibrary/alarmlibrary.component';
import { CustomMarker } from 'src/app/maps/domain/custom-marker.model';
import { MasterData } from 'src/app/maps/domain/masterdata.model';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';

@Component({
  selector: 'sis-sidepane-alarmlist-item',
  templateUrl: './sidepane-alarmlist-item.component.html',
  styleUrls: ['./sidepane-alarmlist-item.component.scss'],
})
export class SidepaneAlarmlistItemComponent extends Unsubscriber implements OnInit, OnDestroy {
  private static readonly alarmClass = 'sis-alarm-item';
  private static readonly infoClass = 'sis-info-item';
  private static readonly warningClass = 'sis-warning-item';
  private static readonly pointerClass = 'sis-pointer-class';
  private static readonly defaultClass = 'sis-default-class';
  private static readonly ContextMenuItemSettings = 'general.term.settings';

  readonly contextMenuItems: ContextMenuItem[] = [
    {
      translationString: SidepaneAlarmlistItemComponent.ContextMenuItemSettings,
      requiredFeature: new Feature(FeatureId.COCKPIT_TELEMETRY, FeatureAccessLevel.READ),
    },
  ];

  contextMenuX: number;
  contextMenuY: number;
  showContextMenu: boolean = false;
  formattedTimestamp: FormattedTimestamp;
  cssClass: string;
  canAccessAlarmLibrary: boolean;

  displayedTitle: string;
  displayedText: string;

  private ropeway: Ropeway;
  private meteoStation: MeteoStation;
  private customMarker: CustomMarker;

  @Input() clickable: boolean;
  @Input() contextMenuDisabled: boolean = false;
  @Input() cssStyle: any;
  @Input() alarm: Alarm;
  @Input() showAliasInTitle = false;

  constructor(
    private eventBus: EventBusService,
    private masterDataService: MasterdataService,
    private userSettingService: UserSettingsService,
    private timeFormatService: TimeFormatService,
    private translateService: TranslateService,
    private modalCtrl: ModalController,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.canAccessAlarmLibrary = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_TELEMETRY, FeatureAccessLevel.READ))
      );
    });
  }

  ngOnChanges() {
    if (!this.alarm) {
      return;
    }

    this.masterDataService.masterData$.pipe(take(1)).subscribe((masterData) => {
      this.setValues(this.alarm, masterData);
    });
  }

  mouseEnter() {
    this.createHoverEvent(true);
  }

  mouseLeave() {
    this.createHoverEvent(false);
  }

  mouseClick() {
    if (this.clickable) {
      const event = new MapIconClickedEvent();
      event.ropeway = this.ropeway;
      event.meteoStation = this.meteoStation;
      event.customMarker = this.customMarker;
      this.eventBus.publish(event);
    }
  }

  ngOnDestroy() {
    this.createHoverEvent(false);
  }

  openContextMenu(event: any): void {
    if (this.contextMenuDisabled) {
      return;
    }

    const bounds = event.target.getBoundingClientRect();
    const alarmItemWidth = document.getElementsByClassName('sis-alarm-row')[0].clientWidth;
    const contextMenuWidth = 200;
    const eventPositionX = event.clientX ? event.clientX : event.center.x;
    const eventPositionY = event.clientY ? event.clientY : event.center.y;
    this.contextMenuX = eventPositionX > alarmItemWidth - contextMenuWidth ? alarmItemWidth - contextMenuWidth : eventPositionX;
    this.contextMenuX = this.contextMenuX < 0 ? 0 : this.contextMenuX;
    this.contextMenuY = eventPositionY - bounds.top;
    this.showContextMenu = true;
  }

  contextMenuItemSelected(selectedItem: ContextMenuItem): void {
    this.showContextMenu = false;

    if (selectedItem?.translationString === SidepaneAlarmlistItemComponent.ContextMenuItemSettings) {
      this.openAlarmLibrary();
    }
  }

  private async openAlarmLibrary() {
    if (!this.canAccessAlarmLibrary) {
      return;
    }

    combineLatest([this.destinationService.selectedTenantFeatures$, this.masterDataService.masterData$])
      .pipe(take(1))
      .subscribe(async ([features, masterData]) => {
        if (this.alarm && features.some((f) => f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_TELEMETRY, FeatureAccessLevel.READ)))) {
          const alarm = this.alarm;

          const underscorePos = alarm?.sisId.indexOf('_');
          const sisId = underscorePos > -1 ? alarm.sisId.substring(0, underscorePos) : alarm.sisId;

          const ropeway = masterData.ropeways.find((r) => r.sisId === sisId);

          const modalSisId = ropeway != null ? ropeway.sisId : masterData.gatewayId;
          const modalAlias = ropeway != null ? ropeway.alias : masterData.alias;

          const alarmCode = alarm.code.replace(new RegExp('^[A-Z]{2}[0-9]?_'), '');

          const modal = await this.modalCtrl.create({
            component: AlarmLibraryModal,
            componentProps: {
              sisId: modalSisId,
              alias: modalAlias,
              searchString: alarmCode,
            },
          });

          return modal.present();
        }
      });
  }

  private createHoverEvent(hover: boolean): void {
    if (!this.ropeway) {
      return;
    }

    const event = new DeviceListHoverEvent();
    event.deviceSisId = this.ropeway.sisId;
    event.hover = hover;
    this.eventBus.publish(event);
  }

  private setValues(alarm: Alarm, masterData: MasterData) {
    const underscorePosition = alarm?.sisId.indexOf('_');
    const deviceSisId = underscorePosition > -1 ? alarm.sisId.substring(0, underscorePosition) : alarm.sisId;

    if (this.cssStyle == null) {
      this.cssClass = this.getCssClass(alarm);
    }

    this.formattedTimestamp = new FormattedTimestamp(this.userSettingService, this.translateService, this.timeFormatService, alarm.timestamp);

    this.ropeway = masterData.ropeways.find((r) => r.sisId === deviceSisId);
    this.meteoStation = masterData.meteoStations.find((m) => m.meteoDeviceSisId === alarm.sisId);
    this.customMarker = masterData.customMarkers.find((c) => c.sisId === alarm.sisId);
    const ginStationName = alarm.code.startsWith('GIN') ? alarm.info : null;

    const alias = this.ropeway?.alias ?? this.meteoStation?.alias ?? this.customMarker?.alias ?? ginStationName ?? masterData.alias;

    if (alias && this.showAliasInTitle) {
      this.displayedTitle = alias;
      this.displayedText = `${alarm.code} ${alarm.text}`;
    } else {
      this.displayedTitle = alarm.code;
      this.displayedText = alarm.text;
    }
  }

  private getCssClass(alarm: Alarm): string {
    const cssClass = this.ropeway && this.clickable ? SidepaneAlarmlistItemComponent.pointerClass : SidepaneAlarmlistItemComponent.defaultClass;

    if (!alarm) {
      return cssClass;
    }

    if (alarm.level === AlarmLevel.WARNING) {
      return `${cssClass} ${SidepaneAlarmlistItemComponent.warningClass}`;
    }

    if (alarm.level === AlarmLevel.INFO || alarm.level === AlarmLevel.BYPASS || alarm.level === AlarmLevel.CHECK) {
      return `${cssClass} ${SidepaneAlarmlistItemComponent.infoClass}`;
    }

    return `${cssClass} ${SidepaneAlarmlistItemComponent.alarmClass}`;
  }
}
