<!-- Big Screen -->
<ion-row *ngIf="this.bigScreenMode && (this.total || this.lengthMeterTotal)" class="sis-text-row">
  <ion-col size="4.5">{{ this.name | translate }}</ion-col>
  <ion-col size="2">
    <ng-container *ngIf="this.total"> {{ this.today }} / {{ this.total }} </ng-container>
  </ion-col>

  <ion-col size="5.5">
    <ng-container *ngIf="this.lengthMeterTotal">
      {{ (this.lengthMeterToday / 1000).toFixed(1) }} / {{ (this.lengthMeterTotal / 1000).toFixed(1) }} km
    </ng-container>
  </ion-col>
</ion-row>

<!-- Small Screen -->
<ion-row *ngIf="!this.bigScreenMode && (this.total || this.lengthMeterTotal)" class="sis-text-row-small">
  <ion-col size="5" style="font-weight: normal">{{ this.name | translate }}</ion-col>
  <ion-col size="2">
    <ng-container *ngIf="this.total"> {{ this.today }} / {{ this.total }} </ng-container>
  </ion-col>

  <ion-col size="5">
    <ng-container *ngIf="this.lengthMeterTotal">
      {{ (this.lengthMeterToday / 1000).toFixed(1) }} / {{ (this.lengthMeterTotal / 1000).toFixed(1) }} km
    </ng-container>
  </ion-col>
</ion-row>
