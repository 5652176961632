<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <rect y="0" x="0" id="rect4" style="fill:none" height="112.5" width="93.75" />
  <g transform="matrix(1.876,0,0,1.876,5.863,14.82)" id="Kamera">
    <rect
      id="rect6"
      style="fill:none;stroke:#32849a;stroke-width:2.4px;stroke-linecap:round;stroke-linejoin:round"
      transform="rotate(30,43.18,37.53)"
      height="23.65"
      width="34.16"
      y="25.71"
      x="26.1"
    />
    <rect
      id="rect8"
      style="fill:none;stroke:#32849a;stroke-width:2.4px;stroke-linecap:round;stroke-linejoin:round"
      transform="rotate(30,61.2,47.76)"
      height="12.27"
      width="5.87"
      y="41.62"
      x="58.27"
    />
    <polygon
      id="polygon10"
      style="fill:none;stroke:#32849a;stroke-width:2.4px;stroke-linecap:round;stroke-linejoin:round"
      points="64.25,61.93 61.47,54.99 67.61,44.37 75,43.3 "
    />
    <polygon
      id="polygon14"
      style="fill:none;stroke:#32849a;stroke-width:2.4px;stroke-linecap:round;stroke-linejoin:round"
      points="34.64,54.43 18.75,57.5 18.75,49.83 34.64,49.83 "
    />
  </g>
</svg>
