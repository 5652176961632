import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Options, XAxisPlotLinesOptions } from 'highcharts';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { MeteoStationService } from 'src/app/maps/meteostation-service/meteostation.service';
import { ForecastTrend } from 'src/app/maps/trenddata/forecast-trend.model';
import { MeteoTrendService } from 'src/app/maps/trenddata/meteo-trend.service';
import { WetBulbTrend } from 'src/app/maps/trenddata/wet-bulb-trend.model';
import { ChartBase } from 'src/app/maps/widgets/charts/chart-base';
import { ChartSettings } from 'src/app/maps/widgets/charts/chart-settings.model';

@Component({
  selector: 'sis-wet-bulb-chart',
  templateUrl: './wet-bulb-chart.component.html',
  styleUrls: ['./wet-bulb-chart.component.scss'],
})
export class WetBulbChartComponent extends ChartBase implements OnInit {
  static readonly forecastTrendSeries = 'wetBulbForecastSeries';
  static readonly trendSeries = 'wetBulbSeries';

  private static readonly trendTranslateString = 'analytics.term.measuredValue';
  private static readonly forecastTrendTranslateString = 'analytics.term.forecastTrend';
  private static readonly unknownTranslateString = 'analytics.term.unknown';
  private static readonly titleTranslateString = 'ropeway.term.wetBulbTemp';

  private readonly defaultMaxY = 20;
  private readonly defaultMinY = 0;
  private readonly xAxisPlotlineNowId = 'xAxisPlotNow';
  private readonly meteoStation$ = this.chartAdapter.chartReady$.pipe(
    take(1),
    switchMap(() => this.meteoStationService.relevantMeteoStationWithWetBulbTemperature$)
  );

  private trendText: string;
  private forecastTrendText: string;
  private unknownText: string;
  private titleText: string;

  constructor(
    private meteoTrendService: MeteoTrendService,
    private meteoStationService: MeteoStationService,
    timeFormatService: TimeFormatService,
    translateService: TranslateService,
    chartSettings: ChartSettings
  ) {
    super(translateService, chartSettings?.inModal, timeFormatService);
  }

  ngOnInit(): void {
    this.meteoStation$
      .pipe(
        distinctUntilChanged(),
        filter((meteoStation) => !!meteoStation),
        tap((meteoStation) => this.initChart(meteoStation)),
        switchMap((meteoStation) => {
          const observables = [
            this.meteoTrendService.getWetBulbTemperatureTrends(meteoStation.guid),
            this.meteoTrendService.getForecastTrends(meteoStation.guid),
          ];

          return this.chartAdapter
            .loadData(observables)
            .pipe(map(([wetBulbTrend, forecastTrend]) => ({ meteoStation, wetBulbTrend, forecastTrend })));
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ wetBulbTrend, forecastTrend }) => {
        this.updateChartData(wetBulbTrend, forecastTrend);
        this.chartAdapter.hideLoading();
      });
  }

  private initChart(meteoStation: MeteoStation): void {
    this.title = this.getTitle(meteoStation);
    this.deviceAlias = meteoStation.ropeway?.alias;
    this.updateTitle();

    if (this.isModal) {
      this.updateModalTitle();
    }

    this.chartAdapter.showLoading();
    this.chartAdapter.removeAllSeries();
    this.chartAdapter.addSeries(WetBulbChartComponent.trendSeries, {
      name: this.trendText,
      id: WetBulbChartComponent.trendSeries,
      type: 'spline' as any,
      lineWidth: 2,
      className: '',
      color: this.getTrendLineColor(0),
    });

    if (meteoStation.hasForecast) {
      this.chartAdapter.addSeries(WetBulbChartComponent.forecastTrendSeries, {
        name: this.forecastTrendText,
        id: WetBulbChartComponent.forecastTrendSeries,
        type: 'spline' as any,
        className: 'line-forecast',
        lineWidth: 2,
        color: this.getForecastLineColor(1),
      });
    }

    this.chartAdapter.reflowChart(this.onDestroy$);
  }

  private updateChartData(trend: WetBulbTrend[], forecast: ForecastTrend[]): void {
    if (!trend || !forecast) {
      this.resetChart();
      this.chartAdapter.showNoData();
      return;
    }

    const minX = this.chartAdapter.getTimeInMsDaysAgo(1);
    const trends: WetBulbTrend[] = trend.filter((t) => t.timestamp.getTime() > minX);
    const forecasts: WetBulbTrend[] = this.adaptForecast(forecast, minX);

    if (trends.length === 0 && forecasts.length === 0) {
      this.resetChart();
      this.chartAdapter.showNoData();
      return;
    }

    const wetBulbForecasts = this.getRoundedTemperaturesWithTimestamp(forecasts).sort((i1, i2) => i1[0] - i2[0]);
    const wetBulbTrends = this.getRoundedTemperaturesWithTimestamp(trends).sort((i1, i2) => i1[0] - i2[0]);

    this.chartAdapter.setSeriesData(WetBulbChartComponent.forecastTrendSeries, wetBulbForecasts);
    this.chartAdapter.setSeriesData(WetBulbChartComponent.trendSeries, wetBulbTrends);

    this.minY = Math.min(this.defaultMinY, this.getMinYAxisValue(trends), this.getMinYAxisValue(forecasts));
    this.maxY = Math.max(this.defaultMaxY, this.getMaxYAxisValue(trends), this.getMaxYAxisValue(forecasts));

    if (!this.isZoomed) {
      this.chartAdapter.setYAxisExtremes(0, this.minY, this.maxY);
      this.chartAdapter.setXAxisExtremes(0, null, null);
    }

    if (this.isZoomed && this.isZoomedToEnd) {
      this.chartAdapter.setXAxisExtremes(0, this.zoomMin, null);
    }

    this.chartAdapter.updateDateXPlotLine(0, this.getXAxisDatePlotline());
  }

  private getRoundedTemperaturesWithTimestamp(trends: WetBulbTrend[] | ForecastTrend[]): number[][] {
    return trends.map((item: WetBulbTrend | ForecastTrend) => {
      const timeStampInMs = item.timestamp.getTime();
      const wetBulbTemperatureCelsius =
        item.wetBulbTemperatureCelsius != null ? Math.round(item.wetBulbTemperatureCelsius * 10) / 10 : null;
      return [timeStampInMs, wetBulbTemperatureCelsius];
    });
  }

  private resetChart(): void {
    this.chartAdapter.clearAllSeriesData();
  }

  private getTitle(meteoStation: MeteoStation): string {
    const alias = meteoStation?.alias ?? this.unknownText;
    return `${this.titleText} «${alias}»`;
  }

  private getXAxisDatePlotline(): XAxisPlotLinesOptions {
    return {
      id: this.xAxisPlotlineNowId,
      width: 1,
      value: new Date().getTime(),
      className: 'plot-line-x',
    };
  }

  private getMaxYAxisValue(trends: ForecastTrend[]): number {
    if (trends.length === 0) {
      return 0;
    }
    return Math.max(...trends.map((d) => d.wetBulbTemperatureCelsius ?? null));
  }

  private getMinYAxisValue(trends: ForecastTrend[]): number {
    if (trends.length === 0) {
      return 0;
    }
    return Math.min(...trends.map((d) => d.wetBulbTemperatureCelsius ?? null));
  }

  private adaptForecast(forecasts: ForecastTrend[], minTimestamp: number): WetBulbTrend[] {
    return forecasts
      .filter((item) => item.timestamp.getTime() >= minTimestamp)
      .map((item) => ({
        timestamp: item.timestamp,
        wetBulbTemperatureCelsius: item.wetBulbTemperatureCelsius,
      }));
  }

  protected translateTexts(translateService: TranslateService): void {
    translateService
      .get([
        WetBulbChartComponent.titleTranslateString,
        WetBulbChartComponent.trendTranslateString,
        WetBulbChartComponent.unknownTranslateString,
        WetBulbChartComponent.forecastTrendTranslateString,
      ])
      .pipe(take(1))
      .subscribe((res) => {
        this.titleText = res[WetBulbChartComponent.titleTranslateString];
        this.trendText = res[WetBulbChartComponent.trendTranslateString];
        this.unknownText = res[WetBulbChartComponent.unknownTranslateString];
        this.forecastTrendText = res[WetBulbChartComponent.forecastTrendTranslateString];

        if (this.chartAdapter) {
          this.chartAdapter.updateOptions({
            title: {
              text: this.isModal ? '' : this.title,
            },
            lang: {
              noData: this.noDataText,
              loading: this.loadingText,
            },
          });
        }

        if (this.isModal) {
          this.updateModalTitle();
        }
      });
  }

  protected getChartOptions(): Options {
    return {
      time: {
        useUTC: false,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      legend: {
        enabled: this.isModal,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      xAxis: {
        type: 'datetime',
        crosshair: this.isModal,
        tickInterval: this.isModal ? 60 * 60 * 1000 : 4 * 60 * 60 * 1000, // 1 hour in modal, otherwise 4 hours (in ms)
        events: {
          setExtremes: (event) => this.handleSetExtremesEvent(event),
        },
      },
      chart: {
        zoomType: 'xy',
        panKey: 'ctrl',
        panning: {
          enabled: true,
        },
        animation: false,
        style: {
          fontFamily: 'Myriad Pro',
        },
        resetZoomButton: {
          theme: {
            stroke: 'var(--ion-color-primary)',
            height: 8,
          },
        },
      },
      yAxis: {
        title: {
          text: '[°C]',
          align: 'high',
          offset: 0,
          rotation: 0,
          y: -15,
          style: {
            fontSize: '12px',
          },
        },
        lineWidth: 1,
        tickInterval: 10,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: this.isModal,
        split: true,
        valueSuffix: '°C',
        xDateFormat: '%d.%m.%y / %H:%M:%S',
      },
      lang: {
        noData: this.noDataText,
        loading: this.loadingText,
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      loading: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      colors: ['#434348', '#1c232a80', '#7cb5ec', '#1c232a80', '#7ce8ec', '#1c232a80'], // every second is for forecast series
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
          },
        },
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
    };
  }
}
