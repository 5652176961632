import * as originalL from 'leaflet';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace extendedL {
  class Curve extends originalL.Path {
    /*
     * Return path
     */
    getPath(): Array<string | Array<[]>>;
    /*
     * Set path
     */
    setPath(): Curve;
    /*
     * Get bounds
     */
    getBounds(): originalL.LatLngBounds;
    /*
     * Get center
     */
    getCenter(): originalL.LatLng;
  }
  /*
   * Drawing Bezier curves and other complex shapes.
   */
  function curve(path: any[], options?: originalL.PathOptions): Curve;
}

export const L: typeof extendedL & typeof originalL = originalL as any;
