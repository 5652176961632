<ion-row class="sis-row sis-global-hover" *ngIf="remoteAccess && this.ready" (click)="openRemoteAccess(remoteAccess)">
  <ion-col size="1.5">
    <ion-icon *ngIf="!blocked" class="sis-device-icon" [name]="typeIconName"></ion-icon>
    <ion-spinner *ngIf="blocked" class="sis-device-icon"></ion-spinner>
  </ion-col>
  <ion-col size="8" class="sis-admin-only-col">
    <span class="sis-name-text">{{ remoteAccess.name }}</span>
    <div *ngIf="this.remoteAccess.administratorsOnly" class="sis-admin-only">Admin Only</div>
  </ion-col>
  <ion-col [ngClass]="this.statusClass" size="0.8">
    <ion-icon [name]="statusIconName" class="sis-status-icon"></ion-icon>
  </ion-col>
  <ion-col [ngClass]="this.statusClass" size="1.7">
    <span class="sis-status-text">{{ this.status }}</span>
  </ion-col>
</ion-row>
