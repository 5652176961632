import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { GatewayResponseBoxComponent } from 'src/app/sisag-admin/gateway-response-box/gateway-response-box.component';
import { SisagAdminPage } from 'src/app/sisag-admin/sisag-admin.page';
import { SisMapEditTokenComponent } from 'src/app/sisag-admin/sismap-edit-token/sismap-edit-token.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatTooltipModule,
    RouterModule.forChild([
      {
        path: '',
        component: SisagAdminPage,
      },
    ]),
    TranslateModule,
  ],
  declarations: [SisagAdminPage, GatewayResponseBoxComponent, SisMapEditTokenComponent],
})
export class SisagAdminPageModule {}
