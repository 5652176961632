<div *ngIf="!this.stnetDisplayedItem" class="sis-spinner-div">
  <ion-spinner></ion-spinner>
</div>

<ion-grid class="sis-global-page-grid">
  <sis-page-title
    [title]="'general.term.stnet'"
  >
  </sis-page-title>

  <!-- editable entries-->
  <sis-stnet-editable
    *ngIf="this.stnetDisplayedItem"
    [stnetItem]="this.stnetItem"
    [stnetDisplayedItem]="this.stnetDisplayedItem"
    [calculatedData]="this.calculatedData"
    (formDataValid)="this.formDataValid = $event"
    (formDataModified)="this.formDataModified = $event"
  ></sis-stnet-editable>

  <!-- calculated entries -->
  <sis-stnet-calculated *ngIf="this.stnetDisplayedItem" [calculatedData]="this.calculatedData"></sis-stnet-calculated>

  <ion-row>
    <ion-col *ngIf="this.stnetDisplayedItem" class="sis-last-change">
      {{ "general.phrase.lastChange" | translate }}: {{ this.stnetDisplayedItem.changedByEmail }} ({{
      this.stnetDisplayedItem.lastChange | dateTimeFormat }})
    </ion-col>
  </ion-row>
  <ion-row class="sis-footer" *ngIf="this.stnetDisplayedItem">
    <ion-col>
      <sis-general-button
        [text]="'general.term.save'"
        [ngClass]="{'sis-global-button-padding-right' : this.bigScreenMode}"
        [btnType]="'Secondary'"
        [showSpinner]="this.isSaving"
        (sisClick)="this.writePermission && this.formDataValid && this.formDataModified && save()"
        [disabled]="!this.writePermission || !this.formDataValid || !this.formDataModified || this.isSaving"
        [width]="this.bigScreenMode ? '120px' : '100%'"
        data-test="stnet-edit-save-btn"
      ></sis-general-button>
      <sis-general-button
        [text]="'general.term.reset'"
        [ngClass]="{'sis-global-button-padding-right' : this.bigScreenMode}"
        [btnType]="'Secondary'"
        [showSpinner]="this.isSaving"
        (sisClick)="reset()"
        [disabled]="!this.writePermission || !this.formDataModified || this.isSaving"
        [width]="this.bigScreenMode ? '120px' : '100%'"
        data-test="stnet-edit-reset-btn"
      ></sis-general-button>
      <sis-general-button
        [text]="'stnet.term.export'"
        [btnType]="'Secondary'"
        [disabled]="!this.writePermission || this.formDataModified"
        (click)="this.writePermission && !this.formDataModified && exportSTnetData()"
        [width]="this.bigScreenMode ? '120px' : '100%'"
        data-test="stnet-edit-export-btn"
      ></sis-general-button>
    </ion-col>
  </ion-row>

  <ion-row *ngFor="let status of this.stnetStatusArray" style="font-size: 10pt; color: var(--ion-color-secondary)">
    <ion-col [size]="this.bigScreenMode ? 8 : 12"
      ><span style="padding-right: 10px">{{ 'stnet.phrase.response' | translate }}:</span
      ><span *ngIf="status.statusMessage">{{ status.statusMessage }}</span
      ><ion-spinner *ngIf="!status.statusMessage" name="dots" class="sis-status-spinner"></ion-spinner
      ><span style="padding-left: 20px">({{status.stationId}})</span></ion-col
    >
    <ion-col [size]="this.bigScreenMode ? 4 : 12" matTooltip="{{ status.snowReportUrl }}"
      ><a href="{{ status.snowReportUrl }}" target="_blank" style="font-size: 15px"
        >snow.myswitzerland.com <ion-icon name="open-outline" class="sis-snowreport-link"></ion-icon></a
    ></ion-col>
  </ion-row>
</ion-grid>
