import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, timer } from 'rxjs';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

declare const VERSION: string;

@Injectable({
  providedIn: 'root',
})
export class VersionService extends Unsubscriber {
  private readonly checkInterval = 60 * 60 * 1000;
  private toastDisplayed = false;

  constructor(httpClient: HttpClient, userMessageService: UserMessageService) {
    super();

    if (window.location.origin !== 'http://localhost:4200') {
      const webAppUrl = window.location.origin.includes('://localhost')
        ? 'http://localhost:5005'
        : window.location.origin;

      timer(0, this.checkInterval)
        .pipe(
          switchMap(() =>
            httpClient.get<{ version: string }>(`${webAppUrl}/controller/version`).pipe(
              catchError(() => {
                console.log('No connection to webapp server');
                return of(null);
              })
            )
          ),
          filter((data) => data != null),
          takeUntil(this.onDestroy$)
        )
        .subscribe((data) => {
          console.log('Current Version: ' + String(VERSION));
          console.log('Server version: ' + data.version);

          if (String(VERSION) !== data.version && !this.toastDisplayed) {
            userMessageService
              .createToast(
                new UserMessage({
                  message: 'versioning.phrase.update',
                  icon: UserMessageIcon.reload,
                  durationMs: 0,
                  position: 'top',
                  color: UserMessageColor.blue,
                })
              )
              .then((toast) => {
                toast.onDidDismiss().then(() => {
                  this.toastDisplayed = false;
                  window.location.reload();
                });
                toast.present();
              });
          }
        });
    } else {
      console.log('Started with ng serve: no version checking');
    }
  }
}
