import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, withLatestFrom } from 'rxjs/operators';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { MeteoLivedata } from 'src/app/maps/livedata/meteo-livedata.model';
import { MeteoLivedataService } from 'src/app/maps/livedata/meteo-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';

@Injectable({
  providedIn: 'root',
})
export class MeteoStationService {
  private selectedMeteoData$: Observable<[MeteoStation, MeteoStation[], MeteoLivedata[]]> =
    this.selectedMapElementService.selectedMapElement$.pipe(
      withLatestFrom(this.meteoLiveDataService.meteoLiveDatas$.pipe(startWith(<MeteoLivedata[]>[]))),
      map(([clickedEvent, liveDatas]) => {
        const relevantMeteoStations = this.getRelevantMeteoStations(clickedEvent.ropeway, clickedEvent.meteoStation);

        return [
          clickedEvent.meteoStation,
          relevantMeteoStations,
          this.getLivedatasForMeteoStations(liveDatas, relevantMeteoStations),
        ];
      }),
      shareReplay<[MeteoStation, MeteoStation[], MeteoLivedata[]]>({
        bufferSize: 1,
        refCount: true,
      })
    );

  readonly activeMeteoStation$: Observable<MeteoStation> = this.selectedMeteoData$.pipe(
    map(
      ([clickedMeteoStation, meteoStations, liveDatas]) =>
        clickedMeteoStation ?? this.findMeteoStationWithHighestWindSpeed(meteoStations, liveDatas)
    ),
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly relevantMeteoStationWithWetBulbTemperature$: Observable<MeteoStation> = this.selectedMeteoData$.pipe(
    map(([clickedMeteoStation, meteoStations, liveDatas]) => {
      if (clickedMeteoStation && this.meteoStationHasWetBulbTemperature(clickedMeteoStation, liveDatas)) {
        return clickedMeteoStation;
      }

      return (
        this.findMeteoStationWithForecast(meteoStations) ??
        this.findMeteoStationWithLowestWetBulbTemperature(meteoStations, liveDatas) ??
        meteoStations[0]
      );
    }),
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly meteoStationWithLowestTemperature$: Observable<MeteoStation> = this.selectedMeteoData$.pipe(
    map(([, meteoStations, liveDatas]) => this.findMeteoStationWithLowestTemperature(meteoStations, liveDatas)),
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly allMeteoStationsWithTemperature$: Observable<MeteoStation[]> = this.selectedMeteoData$.pipe(
    map(([, meteoStations, liveDatas]) => this.findMeteoStationsWithTemperature(meteoStations, liveDatas)),
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly meteoStationWithForecast$: Observable<MeteoStation> = this.selectedMeteoData$.pipe(
    map(([, meteoStations]) => this.findMeteoStationWithForecast(meteoStations)),
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private meteoLiveDataService: MeteoLivedataService,
    private selectedMapElementService: SelectedMapElementService
  ) {}

  private getRelevantMeteoStations(ropeway: Ropeway, meteoStation: MeteoStation): MeteoStation[] {
    return (ropeway?.meteoStations ?? [meteoStation]).filter((m) => !!m);
  }

  private getLivedatasForMeteoStations(liveDatas: MeteoLivedata[], meteoStations: MeteoStation[]): MeteoLivedata[] {
    return liveDatas.filter((d) => meteoStations.some((m) => m.meteoDeviceSisId === d.meteoDeviceSisId));
  }

  private findMeteoStationWithHighestWindSpeed(
    meteoStations: MeteoStation[],
    liveDatas: MeteoLivedata[]
  ): MeteoStation {
    let highestWindSpeedStationSisId: string;
    let highestWindSpeed = -1;
    liveDatas
      .filter((d) => !d.outdated)
      .forEach((d) => {
        if (d.windSpeedKmh > highestWindSpeed) {
          highestWindSpeed = d.windSpeedKmh;
          highestWindSpeedStationSisId = d.meteoDeviceSisId;
        }
      });

    return (
      meteoStations.find((m) => m.meteoDeviceSisId === highestWindSpeedStationSisId) ??
      meteoStations.find((m) => m.meteoDeviceSisId === liveDatas[0]?.meteoDeviceSisId) ??
      meteoStations[0]
    );
  }

  private findMeteoStationWithLowestWetBulbTemperature(
    meteoStations: MeteoStation[],
    liveDatas: MeteoLivedata[]
  ): MeteoStation {
    let lowestWetBulbTemperatureStationSisId: string;
    let lowestWetBulbTemperature = 100;
    liveDatas
      .filter((d) => !d.outdated && d.wetBulbTemperatureCelsius !== null)
      .forEach((d) => {
        if (d.wetBulbTemperatureCelsius < lowestWetBulbTemperature) {
          lowestWetBulbTemperature = d.wetBulbTemperatureCelsius;
          lowestWetBulbTemperatureStationSisId = d.meteoDeviceSisId;
        }
      });

    return meteoStations.find((m) => m.meteoDeviceSisId === lowestWetBulbTemperatureStationSisId) ?? null;
  }

  private findMeteoStationsWithTemperature(meteoStations: MeteoStation[], liveDatas: MeteoLivedata[]): MeteoStation[] {
    const liveDatasWithTemperature = liveDatas.filter((d) => d.temperatureCelsius != null);

    return meteoStations.filter((m) => liveDatasWithTemperature.some((d) => d.meteoDeviceSisId === m.meteoDeviceSisId));
  }

  private findMeteoStationWithLowestTemperature(
    meteoStations: MeteoStation[],
    liveDatas: MeteoLivedata[]
  ): MeteoStation {
    let lowestTemperatureStationSisId: string;
    let lowestTemperature = 100;

    const liveDatasWithTemperature = liveDatas.filter((d) => d.temperatureCelsius != null);

    liveDatasWithTemperature
      .filter((d) => !d.outdated)
      .forEach((d) => {
        if (d.temperatureCelsius < lowestTemperature) {
          lowestTemperature = d.temperatureCelsius;
          lowestTemperatureStationSisId = d.meteoDeviceSisId;
        }
      });

    return (
      meteoStations.find((m) => m.meteoDeviceSisId === lowestTemperatureStationSisId) ??
      meteoStations.find((m) => m.meteoDeviceSisId === liveDatasWithTemperature[0]?.meteoDeviceSisId)
    );
  }

  private meteoStationHasWetBulbTemperature(meteoStation: MeteoStation, liveDatas: MeteoLivedata[]): boolean {
    return liveDatas.some(
      (l) => l.meteoDeviceSisId === meteoStation.meteoDeviceSisId && l.wetBulbTemperatureCelsius != null
    );
  }

  private findMeteoStationWithForecast(meteoStations: MeteoStation[]): MeteoStation {
    return meteoStations.find((m) => m.hasForecast);
  }
}
