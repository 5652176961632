import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Webcam } from 'src/app/maps/domain/webcam.model';

@Component({
  selector: 'sis-webcam-modal',
  templateUrl: './webcam-modal.component.html',
  styleUrls: ['./webcam-modal.component.scss'],
})
export class WebcamModalComponent implements OnInit {
  @Input() webcams: Webcam[];
  @Input() startIndex = 0;

  title: string;
  showExternalImageOverlay: boolean;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.setImageIndex(this.startIndex);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  setImageIndex(index: number) {
    if (this.webcams && this.webcams.length > index) {
      const webcam = this.webcams[index];
      this.title = webcam.title;
      this.showExternalImageOverlay = webcam.isPublic;
    } else {
      this.title = '';
      this.showExternalImageOverlay = false;
    }
  }
}
