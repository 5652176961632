<ion-row *ngIf="this.bigScreenMode" class="sis-global-row-50 sis-row">
  <ion-col size="1" class="sis-center">
    <ion-icon [name]="this.deviceIconName" class="sis-device-icon" data-test="nms-device-icon"></ion-icon>
  </ion-col>
  <ion-col size="5" data-test="nms-device-name"
    >{{ item.deviceName }}<span class="sis-device-ip">&nbsp;({{ item.deviceIp }})</span>
  </ion-col>
  <ion-col size="3" [ngClass]="this.statusClass">
    <ion-icon [name]="this.statusIconName" class="sis-status-icon" data-test="nms-status-icon"></ion-icon>
    {{ item.status }}
  </ion-col>
  <ion-col size="3" *ngIf="this.isDateValid">
    {{ item.timestamp | dateTimeFormat }}
  </ion-col>
  <ion-col size="3" *ngIf="!this.isDateValid"></ion-col>
</ion-row>

<ion-row *ngIf="!this.bigScreenMode" class="sis-global-row-50 sis-row-small-screen">
  <ion-col size="2">
    <ion-icon [name]="this.deviceIconName" class="sis-device-icon"></ion-icon>
  </ion-col>
  <ion-col size="4">
    <span>
      <div>{{ item.deviceName }}</div>
      <div class="sis-device-ip-small-screen">{{ item.deviceIp }}</div>
    </span>
  </ion-col>
  <ion-col size="2" class="sis-global-center" [ngClass]="this.statusClass">
    <ion-icon [name]="this.statusIconName" class="sis-status-icon-small-screen"></ion-icon>
  </ion-col>
  <ion-col size="4" class="sis-lastupdate-small-screen">
    <span class="sis-global-right" *ngIf="this.isDateValid">
      <div>{{ item.timestamp | date : 'dd.MM.yyyy' }}</div>
      <div>{{ item.timestamp | date : 'HH:mm:ss' }}</div>
    </span>
  </ion-col>
</ion-row>
