import { Component, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { LedTicker } from 'src/app/led-ticker/domain/led-ticker.model';
import { LedTickerDisplayed } from 'src/app/led-ticker/domain/led-ticker-displayed.model';
import { LedTickerItem } from 'src/app/led-ticker/domain/led-ticker-item.model';
import { LedTickerService } from 'src/app/led-ticker/led-ticker.service';

@Component({
  selector: 'sis-led-ticker',
  templateUrl: './led-ticker.page.html',
  styleUrls: ['./led-ticker.page.scss'],
})
export class LedTickerPage extends Unsubscriber implements OnInit {
  private readonly selectedLedTicker$ = new ReplaySubject<LedTicker>(1);
  private readonly reset$ = new BehaviorSubject<void>(null);

  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  readonly displayedItems$ = this.selectedLedTicker$.pipe(
    distinctUntilChanged((a, b) => a.guid === b.guid),
    switchMap((ledTicker) => this.reset$.pipe(map(() => ledTicker))),
    takeUntil(this.onDestroy$),
    map((ledTicker) => {
      this.isSaving = false;
      this.isModified = false;
      this.selectedLedTicker = ledTicker;

      if (!ledTicker) {
        return [];
      }

      const groups = [...ledTicker.groupedItems.values()].sort((a, b) => a[0].pageNr - b[0].pageNr);

      return groups.map<LedTickerDisplayed>((group) => ({
        originalItems: group,
        clonedItems: group.map(this.copyLedTickerItem),
        maxTextLength: ledTicker.maxTextLength,
        ledTickerGuid: ledTicker.guid,
        ledTickerPageGuid: group[0].ledTickerPageGuid,
      }));
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly displayConfig$ = this.displayedItems$.pipe(
    distinctUntilChanged((prev, curr) => prev[0]?.ledTickerGuid === curr[0]?.ledTickerGuid),
    switchMap((displayedItems: LedTickerDisplayed[]) =>
      this.screenSizeService.bigScreenMode$.pipe(
        map((bigScreenMode) => {
          const columns = bigScreenMode ? 2 : 1;
          const groupCount = displayedItems.length;
          const rows = groupCount / columns;

          const displayConfig: string[][] = [];

          for (let i = 0; i < rows; i++) {
            displayConfig.push([]);
            for (let j = i * columns; j < i * columns + columns; j++) {
              if (displayedItems[j]) {
                displayConfig[i].push(displayedItems[j].ledTickerPageGuid);
                displayConfig[i]['colSize'] = 12 / columns;
              }
            }
          }

          return displayConfig;
        })
      )
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  ledTickers: LedTicker[];
  selectedLedTicker: LedTicker;

  bigScreenMode: boolean;
  isModified: boolean;
  isSaving: boolean;
  writePermission: boolean;

  constructor(
    private screenSizeService: ScreenSizeService,
    private logbookModalService: LogbookModalService,
    private ledTickerService: LedTickerService,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit() {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_LEDTICKER, FeatureAccessLevel.WRITE))
      );
    });

    this.ledTickerService.ledTickers$.pipe(takeUntil(this.onDestroy$)).subscribe((ledTickers) => {
      this.ledTickers = ledTickers;
      if (this.selectedLedTicker == null || !this.ledTickers.includes(this.selectedLedTicker)) {
        this.selectedLedTicker = ledTickers[0];
        this.selectedLedTicker$.next(ledTickers[0]);
      } else {
        if (ledTickers['updated'] === this.selectedLedTicker.guid) {
          this.reset();
        }
      }
    });
  }

  ledTickerSelected(event: any): void {
    this.selectedLedTicker$.next(event.target.value);
  }

  async save(): Promise<void> {
    this.isSaving = true;
    const displayedItems = await firstValueFrom(this.displayedItems$);

    const items = new Array<LedTickerItem>().concat(...displayedItems.map((item) => item.clonedItems));

    await this.ledTickerService.postLedTicker(items);
  }

  reset(): void {
    this.reset$.next();
  }

  async onFormDataModified(): Promise<void> {
    const displayedItems = await firstValueFrom(this.displayedItems$);
    const editedItems = new Array<LedTickerItem>().concat(...displayedItems.map((item) => item.clonedItems));

    const originalItems = new Array<LedTickerItem>().concat(...displayedItems.map((item) => item.originalItems));

    this.isModified = this.isDifferent(originalItems, editedItems);
  }

  private isDifferent(items1: LedTickerItem[], items2: LedTickerItem[]): boolean {
    return items1.some((i1) => {
      const og = items2.find((i2) => i2.ledTickerTextGuid === i1.ledTickerTextGuid);
      if (!og) {
        return undefined;
      }
      return i1.active != og.active || i1.alignment != og.alignment || i1.duration != og.duration || i1.text != og.text;
    });
  }

  private copyLedTickerItem(item: LedTickerItem): LedTickerItem {
    return { ...item };
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return !this.isModified;
  }
}
