import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, timer } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';

export class FormattedTimestamp {
  readonly formattedTimestamp$: Observable<string> = combineLatest([
    this.userSettingService.userSettings$,
    this.translateService.onLangChange.pipe(startWith(this.translateService.currentLang)),
  ]).pipe(
    switchMap(([userSettings]) => {
      if (userSettings.timeFormat === 1) {
        return this.timeFormatService.format(this.timestamp, userSettings.timeFormat);
      }

      return timer(0, 1000).pipe(
        switchMap(() => this.timeFormatService.format(this.timestamp, userSettings.timeFormat))
      );
    })
  );

  constructor(
    private userSettingService: UserSettingsService,
    private translateService: TranslateService,
    private timeFormatService: TimeFormatService,
    private timestamp: Date
  ) {}
}
