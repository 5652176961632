<svg version="1.1" viewBox="0 0 187.5 225" xmlns="http://www.w3.org/2000/svg">
  <path
    d="m35.06 171.3c-3.99 0-4.94-2.6-2.12-5.3l28.91-29c2.82-2.7 6.77-2.3 8.77 1.2l16.55 28.3c2 3.5 0.39 6.2-3.61 6.1z"
    fill="none"
    stroke="#32849a"
    stroke-miterlimit="10"
    stroke-width="4"
  />
  <path
    d="m121.7 116.8c-4.7 4.6-17.4 0-28.27-11.4-10.95-10.91-16.03-23.59-11.34-28.27 4.7-4.69 17.36 0.39 28.31 11.33 11.3 10.93 16 23.64 11.3 28.34z"
    fill="none"
    stroke="#32849a"
    stroke-miterlimit="10"
    stroke-width="4"
  />
  <path
    d="m82.15 77.28-47.89 46.42c-4.21 4.3 0.34 15.6 10.16 25.3 9.83 10 21.05 14.5 25.48 10.4l51.8-42.6"
    fill="none"
    stroke="#32849a"
    stroke-miterlimit="10"
    stroke-width="4"
  />
  <path
    id="crystal"
    d="m134.4 44.05v2.83l-2.2-1.3c-1.3-0.66-2.3-1.31-2.3-1.31s-2.3 3.92-2.4 4.02c0 0 1.6 0.87 3.5 1.97l3.4 2.06v8.7h-0.1c-0.1 0-1.8-1.09-3.8-2.17l-3.6-2.18v-7.94h-4.7v2.5 2.72c-0.2 0-1.3-0.65-2.6-1.52-1.4-0.87-2.7-1.41-2.7-1.41s-0.3 0.43-1 1.95c-0.7 0.98-1.3 1.96-1.3 1.96l2.6 1.62c1.5 0.77 2.6 1.53 2.6 1.53s-0.9 0.65-2.2 1.2c-1.5 0.98-2.2 1.52-2.2 1.52 0.1 0 2.2 3.8 2.3 3.8 0.2 0 0.9-0.43 3.5-1.95l3.4-1.85h0.1c4.5 2.72 7.4 4.24 7.4 4.24-0.1 0.1-7.4 4.35-7.5 4.35 0 0-1.5-0.87-3.5-1.85-1.8-1.09-3.4-2.07-3.5-2.07 0 0 0 0.11-0.1 0.11-0.5 0.87-2.2 3.81-2.2 3.81s0.6 0.32 2.3 1.42c1.3 0.64 2.3 1.18 2.3 1.41 0 0-1.2 0.54-2.5 1.3-1.4 0.87-2.6 1.63-2.6 1.63s0.2 0.32 1.1 1.96c0.6 1.09 1 2.06 1 2.06 0.1 0 1.3-0.65 2.8-1.63l2.6-1.41v5.11h4.7v-7.82l0.7-0.44c0.5-0.33 2.2-1.2 3.9-2.17l2.8-1.64v8.48l-3.4 2.06c-1.9 1.1-3.5 2.07-3.5 2.07s2.3 4.02 2.3 4.02c0.1 0 1.1-0.75 2.4-1.41 1.2-0.65 2.2-1.3 2.2-1.3v2.93 3.05h4.7v-6.09l0.1 0.11s1.1 0.43 2.2 1.19c1.5 0.98 2.1 1.31 2.1 1.31 0-0.22 2.4-3.91 2.4-4.03 0 0-1.5-0.87-3.4-1.95l-3.4-1.96v-4.23-4.25c0 0.11 1.8 0.98 3.7 2.18l3.6 2.07v7.7h4.7v-4.99l2.6 1.41c1.4 0.87 2.6 1.63 2.6 1.63s0.6-1.09 1.2-2.17c0.9-1.41 1.1-1.96 1.1-1.96s-1.2-0.65-2.6-1.52c-1.3-0.76-2.4-1.53-2.4-1.53s1-0.54 2.1-1.18c1.3-0.66 2.4-1.42 2.4-1.42s-2.3-3.81-2.4-3.92c0 0-1.6 0.77-3.4 1.85-3.3 2.07-3.5 2.07-3.6 2.07-0.1-0.11-7.3-4.25-7.3-4.35l7.4-4.24c0.2 0 1.6 0.98 3.5 1.96 1.8 1.08 3.3 1.84 3.3 1.84 0.1 0 2.4-3.8 2.4-3.8s-1-0.87-2.3-1.41c-1.7-0.98-2.1-1.31-2.1-1.31s1.2-0.76 2.5-1.53c1.4-0.75 2.6-1.51 2.6-1.51 0-0.11-2.3-3.91-2.4-3.91 0 0-1.1 0.64-2.5 1.41-1.3 0.87-2.5 1.41-2.5 1.41-0.1 0-0.1-1.09-0.1-2.72v-2.5h-4.6v7.94l-3.7 2.18c-2 1.08-3.8 2.17-3.8 2.17v-4.35-4.35l3.4-1.96 3.4-1.95-0.1-0.12s-0.6-1.08-1.1-2.06c-0.6-0.98-1.2-1.85-1.2-1.85s-1 0.66-2.2 1.31l-2.2 1.19v-5.98h-4.7z"
    fill="#91cddb"
  />
</svg>
