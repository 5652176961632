import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { NewsService } from 'src/app/domain/news/news.service';

@Component({
  selector: 'sis-main-menu-news',
  templateUrl: './main-menu-news.component.html',
  styleUrls: ['./main-menu-news.component.scss'],
})
export class MainMenuNewsComponent extends Unsubscriber {
  private readonly requestAllNewsFiles$ = new BehaviorSubject(false);

  readonly hasUnreadNews$ = this.newsService.hasUnreadNews$;

  readonly loadAllNewsFiles$ = combineLatest([
    this.requestAllNewsFiles$,
    this.newsService.unreadNewsFileNames$.pipe(take(1)),
    this.newsService.newsFileNames$.pipe(take(1)),
  ]).pipe(
    map(
      ([requestAllNewsFiles, unreadFileNames, allFileNames]) =>
        requestAllNewsFiles || !unreadFileNames.length || unreadFileNames.length === allFileNames.length
    )
  );

  readonly fileNames$ = this.loadAllNewsFiles$.pipe(
    switchMap((loadAllNewsFiles) => {
      if (loadAllNewsFiles) {
        return this.newsService.newsFileNames$.pipe(take(1));
      } else {
        return this.newsService.unreadNewsFileNames$.pipe(take(1));
      }
    }),
    switchMap((fileNames) =>
      this.translateService.onLangChange.pipe(
        map((langChangeEvent) => langChangeEvent.lang),
        startWith(this.translateService.currentLang),
        map((lang) => fileNames.filter((fileName) => fileName.includes(`_${lang}_`)))
      )
    )
  );

  @ViewChild('newsGrid') newsGrid: any;
  @ViewChild('newsTopRow') newsTopRow: any;

  open = false;

  constructor(private newsService: NewsService, private translateService: TranslateService) {
    super();
  }

  requestAllNewsFiles(): void {
    this.requestAllNewsFiles$.next(true);
  }

  onMenuItemClick(): void {
    this.newsService.updateNewsViewed();
    this.open = !this.open;

    setTimeout(() => {
      this.newsGrid.el.scrollIntoView();
      if (this.open) {
        this.newsTopRow.el.scrollIntoView();
      }
    });
  }
}
