import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, firstValueFrom, merge, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MediaCenterScreenUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MediaCenterNetworkStateService } from 'src/app/media-center/network-state/network-state.service';
import { MediaCenterPlaylistsService } from 'src/app/media-center/playlists/media-center-playlists.service';
import { MediaCenterRemoteAccessStatusService } from 'src/app/media-center/remote-access/remote-access-status.service';
import { ScreenAdapter } from 'src/app/media-center/screens/domain/screen.adapter';
import { Screen } from 'src/app/media-center/screens/domain/screen.model';
import { ScreenGroupAdapter } from 'src/app/media-center/screens/domain/screen-group.adapter';
import { ScreenGroup } from 'src/app/media-center/screens/domain/screen-group.model';
import { ScreenTypeAdapter } from 'src/app/media-center/screens/domain/screen-type.adapter';
import { ScreenType } from 'src/app/media-center/screens/domain/screen-type.model';
import { InfoBannerService } from 'src/app/media-center/screens/infobanner-modal/infobanner.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaCenterScreenService {
  private static readonly requestScreensUrl = '/mediacenter/screens';
  private static readonly updateScreenUrl = '/mediacenter/screen';
  private static readonly requestScreenTypesUrl = '/mediacenter/screentypes';
  private static readonly requestScreenGroupsUrl = '/mediacenter/screengroups';

  private readonly searchTerm$: ReplaySubject<string> = new ReplaySubject(1);

  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.READ);

  readonly screenTypes$: Observable<ScreenType[]> = this.destinationService.selectedTenantFeatures$.pipe(
    filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
    switchMap(() => this.http.get(`${environment.baseUrlApi}${MediaCenterScreenService.requestScreenTypesUrl}`)),
    map((data) => ScreenTypeAdapter.adapt(data)),
    map((types) => {
      types.sort((a, b) => a.type.localeCompare(b.type));
      return types;
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly screensRequest$: Observable<Screen[]> = this.destinationService.selectedTenantFeatures$.pipe(
    filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
    switchMap(() => this.http.get(`${environment.baseUrlApi}${MediaCenterScreenService.requestScreensUrl}`)),
    map((data) => ScreenAdapter.adapt(data)),
    switchMap((screens) =>
      this.screenTypes$.pipe(
        map((screenTypes) => {
          screens.forEach((screen) => {
            screen.screenTypeName = screenTypes.find((type) => type.id === screen.type)?.type;
          });
          return screens;
        })
      )
    ),
    switchMap((screens) =>
      merge(
        this.networkStateService.networkStates$.pipe(
          distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
          map((networkStates) => {
            screens.forEach((screen) => {
              const status = networkStates.find((n) => n.deviceIp === screen.ip)?.status;

              screen.connectionStatus = status;
            });
            return screens;
          })
        ),
        this.remoteAccessStatusService.remoteAccessStatuses$.pipe(
          distinctUntilChanged(),
          map((statuses) => {
            screens
              .filter((screen) => !!screen.remoteAccess)
              .forEach((screen) => {
                const status = statuses.find((s) => s.guid === screen.remoteAccess.guid);
                if (status) {
                  screen.remoteAccessOnline = status.online;
                }
              });
            return screens;
          })
        )
      )
    ),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly screenUpdate$: Observable<Screen[]> = this.eventBus.observe(MediaCenterScreenUpdatedEvent).pipe(
    withLatestFrom(this.destinationService.selectedTenant$),
    filter(([updatedEvent, tenant]) => updatedEvent?.tenantGuid === tenant?.guid),
    withLatestFrom(this.screensRequest$, this.screenTypes$),
    map(([[updatedEvent], screens, screenTypes]) => {
      updatedEvent.screens.forEach((screen) => {
        const updatedScreen = screens.find((s) => s.guid === screen.guid);
        const screenType = screenTypes.find((type) => type.id === screen.type);
        screen.screenTypeName = screenType ? screenType.type : null;

        if (updatedScreen) {
          updatedScreen.updating = false;

          if (updatedEvent.success) {
            if (updatedEvent.removed) {
              this.presentToast(true, 'mediacenter.phrase.deleteSuccess');
              screens.splice(screens.indexOf(updatedScreen), 1);
            } else {
              this.presentToast(true, 'general.phrase.saved');
              const remoteAccess = updatedScreen.remoteAccess;
              const remoteAccessOnline = updatedScreen.remoteAccessOnline;
              Object.assign(updatedScreen, screen);
              updatedScreen.remoteAccess = remoteAccess;
              updatedScreen.remoteAccessOnline = remoteAccessOnline;
            }
          }
        } else {
          if (!updatedEvent.removed && updatedEvent.success) {
            screens.push(screen);
            this.presentToast(true, 'mediacenter.phrase.screenCreated');
          }
        }
      });

      return screens;
    })
  );

  readonly screens$ = combineLatest([
    merge(this.screensRequest$, this.screenUpdate$),
    this.playlistService.playlists$.pipe(startWith([])),
    this.infoBannerService.infoBanner$.pipe(startWith([])),
  ]).pipe(
    map(([screens, playlists, infoBanners]) => {
      playlists.forEach((playlist) => (playlist.assignedToScreen = false));
      screens.forEach((screen) => {
        if (screen.playlistGuid != null) {
          const playlist = playlists.find((playlist) => playlist.guid === screen.playlistGuid);
          screen.playlistName = playlist?.name;
          if (playlist) {
            playlist.assignedToScreen = true;
          }
        } else {
          screen.playlistName = null;
        }
        const infoBanner = infoBanners.find((i) => i.screenGuids.some((g: string) => g == screen.guid));
        screen.hasInfoBannerMapping = infoBanner != null;
        screen.infoBannerName = infoBanner?.name;
      });
      return screens;
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly filteredScreens$ = combineLatest([this.screens$, this.searchTerm$]).pipe(
    map(([screens, searchTerm]) => {
      screens.sort((a, b) => a.location.localeCompare(b.location));
      if (searchTerm) {
        searchTerm = searchTerm.toLocaleLowerCase();
        screens = screens.filter(
          (d) =>
            d.location.toLowerCase().includes(searchTerm) ||
            d.label.toLowerCase().includes(searchTerm) ||
            d.playlistName?.toLowerCase().includes(searchTerm)
        );
      }

      return screens;
    })
  );

  private readonly screenGroupsRequest$: Observable<ScreenGroup[]> =
    this.destinationService.selectedTenantFeatures$.pipe(
      filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
      switchMap(() => this.http.get(`${environment.baseUrlApi}${MediaCenterScreenService.requestScreenGroupsUrl}`)),
      map((data) => ScreenGroupAdapter.adapt(data))
    );

  readonly screenGroups$ = this.screenGroupsRequest$.pipe(
    map((groups) => {
      const defaultGroup: ScreenGroup = { order: -1 };
      groups.push(defaultGroup);
      groups.sort((a, b) => a.order - b.order);
      return groups;
    })
  );

  constructor(
    private eventBus: EventBusService,
    private http: HttpClient,
    private destinationService: DestinationService,
    private playlistService: MediaCenterPlaylistsService,
    private infoBannerService: InfoBannerService,
    private networkStateService: MediaCenterNetworkStateService,
    private remoteAccessStatusService: MediaCenterRemoteAccessStatusService,
    private userMessageService: UserMessageService
  ) {}

  async updateScreens(screens: Screen[]): Promise<boolean> {
    return this.update(screens, false);
  }

  async deleteScreen(screen: Screen): Promise<boolean> {
    return this.update([screen], true);
  }

  private async update(screens: Screen[], deleting: boolean): Promise<boolean> {
    try {
      await firstValueFrom(
        this.http.post(`${environment.baseUrlApi}${MediaCenterScreenService.updateScreenUrl}`, {
          screens: screens,
          delete: deleting,
        })
      );
      return true;
    } catch {
      this.presentToast(false, 'general.phrase.saveFailed');
      return false;
    }
  }

  private presentToast(success: boolean, message: string): void {
    const color = success ? UserMessageColor.green : UserMessageColor.red;
    const icon = success ? UserMessageIcon.success : UserMessageIcon.failed;
    this.userMessageService.presentToast(new UserMessage({ message, icon, durationMs: 2000, position: 'top', color }));
  }

  filterScreens(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }
}
