import { UrlSegment } from '@angular/router';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';

export class SisRoute {
  static readonly Lift = 'lift';
  static readonly Slope = 'slope';
  static readonly CrossCountry = 'crosscountry';
  static readonly Gastro = 'gastro';
  static readonly Trail = 'trail';
  static readonly Bike = 'bike';
  static readonly Poi = 'poi';
  static readonly Sledging = 'sledging';

  static readonly MAPS = 'maps';
  static readonly ALARMLIST = 'alarmlist';
  static readonly SISITSERVICES = 'sisitservices';
  static readonly SISMEDIA = 'sismedia';
  static readonly SISMEDIASETTING = 'sismediasetting';
  static readonly PARKING = 'parking';
  static readonly BREAKDOWNINFO = 'breakdowninfo';
  static readonly OPERATINGINFO = 'operatinginfo';
  static readonly MEDIACENTER = 'mediacenter';
  static readonly INFOTEXT = 'infotext';
  static readonly EDITOR = 'editor';
  static readonly LASTSLOPECONTROL = 'lastslopecontrol';
  static readonly GLOBAL = 'global';
  static readonly USERMANAGEMENT = 'usermanagement';
  static readonly ERROR = 'error';
  static readonly METEOINFO = 'meteoinfo';
  static readonly STNET = 'stnet';
  static readonly SLOPESOPERATINGTIMES = 'slopesoperatingtimes';
  static readonly WEBLINKCOLLECTION = 'weblinkcollection';
  static readonly TIMETABLE = 'timetable';
  static readonly LEDTICKER = 'ledticker';
  static readonly EMAILDELIVERY = 'emaildelivery';
  static readonly SISAGADMIN = 'admin';
  static readonly FEATUREMANAGEMENT = 'featuremanagement';

  featureUrl: string;
  urlSegments: UrlSegment[] = [];

  constructor(urlSegments: string[], public requiredFeatureId: FeatureId) {
    this.featureUrl = '/' + urlSegments.join('/');

    urlSegments.forEach((urlSegment) => {
      this.urlSegments.push(new UrlSegment(urlSegment, {}));
    });
  }
}
