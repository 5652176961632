<ion-row>
  <ion-col>
    <ion-card mode="md">
      <ion-card-header>
        <ion-card-title>SisMap - Edit Token verwalten</ion-card-title>
      </ion-card-header>

      <ion-card-content class="sis-card-content">
        <ion-grid style="padding-left: 0">
          <ion-row>
            <ion-col size="4" style="padding-left: 0">
              <ion-select
                [(ngModel)]="this.selectedEditToken"
                interface="popover"
                [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
                class="sis-global-input"
              >
                <ion-select-option *ngFor="let item of this.itemsWithoutEditToken" [value]="item" data-test="ledticker-select-option">{{
                  item.name
                }}</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col
              ><sis-general-button
                text="Erstellen"
                width="200px"
                (sisClick)="this.updateEditToken(true, this.selectedEditToken)"
                btnAlign="Right"
                [disabled]="!this.selectedEditToken"
              >
              </sis-general-button
            ></ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid class="sis-edit-token-list-grid">
          <ion-row class="sis-edit-token-list-row" *ngFor="let item of this.itemsWithEditToken">
            <ion-col size="10">{{ item.name }}: {{ item.editToken }}</ion-col>
            <ion-col size="1">
              <a class="sis-edit-token-clickable" (click)="this.openUrl(item)" matTooltip="{{ 'general.term.openMap' | translate }}">
                <ion-icon class="sis-edit-token-icon" name="open-outline"> </ion-icon>
              </a>
            </ion-col>
            <ion-col size="1">
              <a class="sis-edit-token-clickable" (click)="this.updateEditToken(false, item)" matTooltip="{{ 'general.term.delete' | translate }}">
                <ion-icon name="trash-outline" class="sis-edit-token-icon"></ion-icon>
              </a>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-col>
</ion-row>
