import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { NewsService } from 'src/app/domain/news/news.service';

@Component({
  selector: 'sis-main-menu-news-item',
  templateUrl: './main-menu-news-item.component.html',
  styleUrls: ['./main-menu-news-item.component.scss'],
})
export class MainMenuNewsItemComponent implements OnInit {
  @Input() fileName: string;

  mdText: string;

  constructor(private newsService: NewsService) {}

  ngOnInit(): void {
    this.newsService
      .getFileText(this.fileName)
      .pipe(take(1))
      .subscribe((text) => (this.mdText = text));
  }
}
