import { Export } from 'src/app/domain/export/export.model';

export class ExportAdapter {
  static adapt(item: any): Export[] {
    const exports: Export[] =
      item?.map((e: any) => ({
        guid: e.guid,
        name: e.name,
        type: e.type,
      })) || [];

    exports.sort((a, b) => a.name.localeCompare(b.name));

    return exports;
  }
}
