<ion-grid class="sis-global-page-grid sis-global-no-scrollbar">
  <sis-page-title
    [title]="'general.term.meteoinfo'"
  >
  </sis-page-title>

  <div *ngIf="!this.displayedMeteoInfoItems" class="sis-spinner-div">
    <ion-spinner></ion-spinner>
  </div>

  <ng-container *ngIf="this.displayedMeteoInfoItems">
    <ion-row *ngFor="let row of this.displayedMeteoInfoItems?.source; let n = index" justify-content-center>
      <ion-col [size]="bigScreenMode ? 2.5 : 5">
        <sis-meteo-info-field-names
          [hasSTnetExport]="this.hasSTnetExport"
          [meteoInfoItemsInRow]="row"
        ></sis-meteo-info-field-names>
      </ion-col>
      <ion-col *ngFor="let meteoInfoItem of row; let i = index" [size]="bigScreenMode ? '' : 7">
        <sis-meteo-info-item
          [meteoInfoItem]="this.displayedMeteoInfoItems.source[n][i]"
          [meteoInfoDisplayedItem]="this.displayedMeteoInfoItems.editable[n][i]"
          [meteoInfoItemsInRow]="row"
          (formDataValid)="this.onFormDataValid($event, row.length * n + i)"
          (formDataModified)="this.onFormDataModified($event, row.length * n + i)"
        ></sis-meteo-info-item>
      </ion-col>
    </ion-row>
    <ion-row style="margin-top: 20px">
      <ion-col>
        <ion-grid style="padding: 0px">
          <ion-row>
            <ion-col *ngIf="this.lastChanged" style="font-size: 10pt">
              {{ "general.phrase.lastChange" | translate }}: {{ this.lastChanged.changedByEmail }} ({{
              this.lastChanged.lastChange | dateTimeFormat }})
            </ion-col>
          </ion-row>
          <ion-row class="sis-meteoinfo-footer">
            <ion-col>
              <sis-general-button
                [text]="'general.term.save'"
                [ngClass]="{'sis-global-button-padding-right' : this.bigScreenMode}"
                [btnType]="'Secondary'"
                (sisClick)="save()"
                [disabled]="!this.writePermission || !this.allItemsValid || !this.anyItemEdited || this.isSaving"
                [showSpinner]="this.isSaving"
                [width]="this.bigScreenMode ? '120px' : '100%'"
                data-test="meteo-info-save-btn"
              ></sis-general-button>
              <sis-general-button
                [text]="'general.term.reset'"
                [ngClass]="{'sis-global-button-padding-right' : this.bigScreenMode}"
                [btnType]="'Secondary'"
                (sisClick)="reset()"
                [disabled]="!this.writePermission || !this.anyItemEdited || this.isSaving"
                [showSpinner]="this.isSaving"
                [width]="this.bigScreenMode ? '120px' : '100%'"
                data-test="meteo-info-reset-btn"
              ></sis-general-button>
              <sis-general-button
                *ngIf="this.showSTnetButton"
                [text]="'stnet.term.export'"
                [btnType]="'Secondary'"
                (sisClick)="exportSTnetData()"
                [width]="this.bigScreenMode ? '120px' : '100%'"
                data-test="meteo-info-export-btn"
              ></sis-general-button>
            </ion-col>
          </ion-row>

          <ion-row
            *ngFor="let status of this.stnetStatusArray"
            style="font-size: 10pt; color: var(--ion-color-secondary)"
          >
            <ion-col [size]="this.bigScreenMode ? 8 : 12"
              ><span style="padding-right: 10px">{{ 'stnet.phrase.response' | translate }}:</span
              ><span *ngIf="status.statusMessage">{{ status.statusMessage }}</span
              ><ion-spinner *ngIf="!status.statusMessage" name="dots" class="sis-status-spinner"></ion-spinner
              ><span style="padding-left: 20px">({{status.stationId}})</span></ion-col
            >
            <ion-col
              [size]="this.bigScreenMode ? 4 : 12"
              matTooltip="{{ status.snowReportUrl }}"
              style="padding-left: 0px"
              ><a href="{{ status.snowReportUrl }}" target="_blank" style="font-size: 15px"
                >snow.myswitzerland.com <ion-icon name="open-outline" class="sis-snowreport-link"></ion-icon></a
            ></ion-col>
          </ion-row>
        </ion-grid> </ion-col></ion-row></ng-container
></ion-grid>
