import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { STnetStatus } from 'src/app/core/stnet/stnet-status.model';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { STnetCalculatedData } from 'src/app/stnet/domain/stnet-calculated-data.model';
import { STnetItemAdapter } from 'src/app/stnet/domain/stnet-item.adapter';
import { STnetItem } from 'src/app/stnet/domain/stnet-item.model';
import { STnetService } from 'src/app/stnet/stnet.service';

@Component({
  selector: 'sis-stnet-page',
  templateUrl: './stnet.page.html',
  styleUrls: ['./stnet.page.scss'],
})
export class STnetPage extends Unsubscriber implements OnInit {
  bigScreenMode: boolean;
  stnetItem: STnetItem;
  stnetDisplayedItem: STnetItem;
  writePermission: boolean;
  stnetStatusArray: STnetStatus[] = [];
  formDataValid: boolean;
  formDataModified: boolean;
  isSaving: boolean;

  // show only one set of calculated data on the page, even if there are multiple exports (stationIds)
  calculatedData: STnetCalculatedData;

  constructor(
    private destinationService: DestinationService,
    private stnetService: STnetService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit() {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((screenMode) => (this.bigScreenMode = screenMode));

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission =
        features?.some((f) =>
          f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_STNET, FeatureAccessLevel.WRITE))
        ) ?? false;
    });

    this.stnetService.stnetCalculatedData$.pipe(takeUntil(this.onDestroy$)).subscribe((calculatedData) => {
      this.calculatedData = calculatedData;
    });

    this.stnetService.stnetStatusArray$.pipe(takeUntil(this.onDestroy$)).subscribe((stnetStatusArray) => {
      this.stnetStatusArray = stnetStatusArray;
    });

    this.stnetService.stnetItems$.pipe(takeUntil(this.onDestroy$)).subscribe((stnetItem) => {
      this.isSaving = false;
      this.stnetItem = stnetItem;
      this.reset();
    });
  }

  async save(): Promise<void> {
    if (this.writePermission && !this.isSaving) {
      this.isSaving = true;
      const success = await this.stnetService.saveSTnetItems(this.stnetDisplayedItem);
      if (!success) {
        this.isSaving = false;
      }
    }
  }

  reset(): void {
    if (!this.isSaving) {
      this.stnetDisplayedItem = STnetItemAdapter.adapt(JSON.parse(JSON.stringify(this.stnetItem)));
    }
  }

  async exportSTnetData(): Promise<void> {
    if (this.writePermission) {
      await this.stnetService.exportSTnetItems();
    }
  }
}
