<!-- Whatever you wrap with the '<sis-novncviewer>' component will be rendered on a new window  -->
<ng-container *cdkPortal>
  <div *ngIf="this.connecting" style="height: 100%; width: 100%; background-color: #282828; display: flex">
    <span style="font-size: large; font-family: Arial; color: white; margin: auto" translate
      >user.message.remoteAccessConnection.establish</span
    >
  </div>

  <div [id]="this.remoteAccessDivId">
    <!-- This is where the remote screen will appear -->
  </div>
</ng-container>
