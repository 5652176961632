import { GinConfiguration } from 'src/app/maps/domain/gin-configuration.model';
import { GinLivedata } from 'src/app/maps/livedata/gin-livedata.model';

export class GinDetailViewDisplayData {
  readonly ginName: string;
  readonly dataTimestamp: string;
  readonly timestamp: Date;
  readonly value: string;
  readonly valueFormat: string;
  readonly unit: string;

  readonly externalLink: string;
  readonly order: number;

  constructor(configuration: GinConfiguration, liveData: GinLivedata) {
    if (liveData) {
      this.ginName = liveData.ginName;
      this.dataTimestamp = liveData.dataTimestamp;
      this.timestamp = liveData.timestamp;
      this.value = liveData.value.toString();
      this.unit = liveData.unit;

      if (liveData.outdated) {
        this.valueFormat = 'sis-gin-data-outdated';
      } else if (configuration?.alarmRangeHigh !== null && liveData.value >= configuration.alarmRangeHigh) {
        this.valueFormat = 'sis-gin-data-alarm';
      } else if (configuration?.warnRangeHigh !== null && liveData.value >= configuration.warnRangeHigh) {
        this.valueFormat = 'sis-gin-data-warning';
      } else {
        this.valueFormat = '';
      }

      if (configuration) {
        this.externalLink = configuration.externalLink;
        this.order = configuration.detailViewOrder;
      }
    }
  }
}
