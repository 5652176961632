import { WritablePart } from 'src/app/core/utils/type-helper';

export enum AlarmLevel {
  SYSTEM = 'system',
  FAULT = 'fault',
  ALARM = 'alarm',
  WARNING = 'warning',
  INFO = 'info',
  BYPASS = 'bypass',
  CHECK = 'check',
}

export class Alarm {
  static alarmLevelOrder = Object.values(AlarmLevel);

  static getLevel(level: string): AlarmLevel {
    let alarmLevel: AlarmLevel;

    try {
      alarmLevel = AlarmLevel[level.toUpperCase()];
    } catch (ex) {
      alarmLevel = AlarmLevel.FAULT;
      console.error(`No Alarmlevel for ${alarmLevel}`);
    }

    return alarmLevel;
  }

  static includesSisId(alarm: Alarm, sisId: string): boolean {
    const regex = new RegExp(sisId + '(_.*)?$');

    return regex.test(alarm.sisId);
  }

  readonly timestampMs: number;
  readonly timestamp: Date;

  sisId: string;
  tenantSisId: string;
  active: boolean;
  code: string;
  info: string;
  level: AlarmLevel;
  text: string;

  constructor(init: Partial<WritablePart<Alarm>> & { timestamp?: Date }) {
    Object.assign(this, init);
    this.timestamp = init.timestamp;
    this.timestampMs = init.timestamp?.getTime();
  }
}
