import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { AlarmlistService } from 'src/app/alarmlist/alarmlist.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Alarm, AlarmLevel } from 'src/app/domain/alarm.model';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { AlarmLibraryModal } from 'src/app/maps/alarmlibrary/alarmlibrary.component';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { MeteoForecastAlarmService } from 'src/app/maps/livedata/meteo-forecast-alarm.service';
import { RopewayAlarmService } from 'src/app/maps/livedata/ropeway-alarm.service';
import { MeteoStationService } from 'src/app/maps/meteostation-service/meteostation.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';

@Component({
  selector: 'sis-alarm-detailview',
  templateUrl: './alarm-detailview.component.html',
  styleUrls: ['./alarm-detailview.component.scss'],
})
export class AlarmDetailViewComponent extends Unsubscriber implements OnInit {
  private ropeway: Ropeway;
  private meteoStation: MeteoStation;

  activeAlarms: Alarm[];
  hasAlarm = false;
  canAccessAlarmLibrary: boolean;

  constructor(
    private modalCtrl: ModalController,
    private meteoStationService: MeteoStationService,
    private destinationService: DestinationService,
    private meteoForecastAlarmService: MeteoForecastAlarmService,
    private ropewayAlarmService: RopewayAlarmService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.canAccessAlarmLibrary = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_TELEMETRY, FeatureAccessLevel.READ))
      );
    });

    combineLatest([
      this.selectedMapElementService.selectedRopeway$,
      this.meteoStationService.activeMeteoStation$.pipe(startWith(<MeteoStation>null)),
      this.ropewayAlarmService.alarms$.pipe(startWith(<Alarm[]>[])),
      this.meteoForecastAlarmService.alarms$.pipe(startWith([])),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([ropeway, meteoStation, ropewayAlarms, forecastAlarms]) =>
        this.update(ropeway, meteoStation, ropewayAlarms, forecastAlarms)
      );
  }

  private update(ropeway: Ropeway, meteoStation: MeteoStation, ropewayAlarms: Alarm[], forecastAlarms: Alarm[]): void {
    this.ropeway = ropeway;
    this.meteoStation = meteoStation;

    const sisId = ropeway?.sisId ?? meteoStation?.meteoDeviceSisId ?? null;
    if (!sisId) {
      return;
    }

    const alarms: Alarm[] = [
      ...(ropewayAlarms?.filter((a) => a.active && a.sisId === sisId) ?? []),
      ...(forecastAlarms?.filter((a) => a.active && Alarm.includesSisId(a, sisId)) ?? []),
    ];

    if (!alarms?.length) {
      this.resetToDefault();
      return;
    }

    const highestLevelAlarmsPerCode = alarms.reduce((p: Map<string, Alarm>, c: Alarm) => {
      const alarm: Alarm = p.get(c.code);
      if (!alarm || (alarm.level === AlarmLevel.WARNING && c.level === AlarmLevel.ALARM)) {
        p.set(c.code, c);
      }
      return p;
    }, new Map<string, Alarm>());

    this.activeAlarms = AlarmlistService.sortByTypeAndTimestamp(Array.from(highestLevelAlarmsPerCode.values()));
    this.hasAlarm = this.activeAlarms.length > 0;
  }

  private resetToDefault(): void {
    this.activeAlarms = undefined;
    this.hasAlarm = false;
  }

  async openAlarmLibrary() {
    if (!(this.ropeway && this.ropeway.sisId) && !this.meteoStation) {
      return;
    }

    const sisId = this.ropeway ? this.ropeway.sisId : this.meteoStation.meteoDeviceSisId;
    const alias = this.ropeway ? this.ropeway.alias : this.meteoStation.alias;

    const modal = await this.modalCtrl.create({
      component: AlarmLibraryModal,
      componentProps: {
        sisId,
        alias,
      },
    });
    return modal.present();
  }
}
