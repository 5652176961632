import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';
import { map, takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { SisMediaSetting } from 'src/app/sismedia-setting/sismedia-setting.model';
import { SisMediaSettingService } from 'src/app/sismedia-setting/sismedia-setting.service';

@Component({
  selector: 'sis-media-setting',
  templateUrl: './sismedia-setting.page.html',
  styleUrls: ['./sismedia-setting.page.scss'],
})
export class SisMediaSettingPage extends Unsubscriber implements OnInit {
  private readonly requiredWriteFeature = new Feature(FeatureId.SISMEDIA_ASSET_EDIT, FeatureAccessLevel.WRITE);

  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;
  readonly writePermission$ = this.destinationService.selectedTenantFeatures$.pipe(
    map((features) => {
      return features?.some((f) => f.hasMinimumRequirementFor(this.requiredWriteFeature)) ?? false;
    })
  );

  private sisMediaSetting: SisMediaSetting;

  sisMediaSettingEdited: SisMediaSetting;
  formGroup: FormGroup;
  edited: boolean;
  saving: boolean;

  constructor(
    private sisMediaSettingService: SisMediaSettingService,
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup(
      {
        winterSeasonStartDate: new FormControl(null, [Validators.required]),
        winterSeasonEndDate: new FormControl(null, [Validators.required]),
        liftsOpenFrom: new FormControl<string>(null, [Validators.required]),
        liftsOpenTo: new FormControl<string>(null, [Validators.required]),
      },
      {
        validators: [this.validateSeasonDates(), this.validateLiftOpenTimes()],
      }
    );

    this.sisMediaSettingService.sisMediaSetting$.pipe(takeUntil(this.onDestroy$)).subscribe((sisMediaSetting) => {
      this.sisMediaSetting = sisMediaSetting ?? {
        tenantGuid: null,
        liftsOpenFrom: null,
        liftsOpenTo: null,
        winterSeasonEndDate: null,
        winterSeasonStartDate: null,
      };

      this.reset();
      this.saving = false;
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((values) => {
      if (this.sisMediaSettingEdited) {
        this.sisMediaSettingEdited.winterSeasonStartDate = new Date(values.winterSeasonStartDate);
        this.sisMediaSettingEdited.winterSeasonEndDate = new Date(values.winterSeasonEndDate);
        this.sisMediaSettingEdited.liftsOpenFrom = values.liftsOpenFrom;
        this.sisMediaSettingEdited.liftsOpenTo = values.liftsOpenTo;
      }

      this.edited = this.isEdited();
    });

    this.writePermission$.pipe(takeUntil(this.onDestroy$)).subscribe((writePermission) => {
      if (writePermission) {
        this.formGroup.enable();
      } else {
        this.formGroup.disable();
      }
    });
  }

  reset(): void {
    this.sisMediaSettingEdited = { ...this.sisMediaSetting };

    this.formGroup.controls.winterSeasonStartDate.setValue(
      moment(this.sisMediaSetting.winterSeasonStartDate).format('YYYY-MM-DD')
    );
    this.formGroup.controls.winterSeasonEndDate.setValue(
      moment(this.sisMediaSetting.winterSeasonEndDate).format('YYYY-MM-DD')
    );
    this.formGroup.controls.liftsOpenFrom.setValue(this.sisMediaSetting.liftsOpenFrom);
    this.formGroup.controls.liftsOpenTo.setValue(this.sisMediaSetting.liftsOpenTo);

    this.edited = false;
  }

  async save(): Promise<void> {
    if (!this.saving) {
      this.saving = true;
      this.saving = await this.sisMediaSettingService.postSisMediaSetting(this.sisMediaSettingEdited);
    }
  }

  private validateSeasonDates(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startControl = control.get('winterSeasonStartDate');
      const endControl = control.get('winterSeasonEndDate');

      const start = startControl.value as Date;
      const end = endControl.value as Date;

      if (start == null || end == null) {
        return { seasonDatesInvalid: true };
      }

      return start < end ? null : { seasonDatesInvalid: true };
    };
  }

  private validateLiftOpenTimes(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fromControl = control.get('liftsOpenFrom');
      const toControl = control.get('liftsOpenTo');

      const from = fromControl.value as string;
      const to = toControl.value as string;

      const regex = /^\d{2}:\d{2}$/g;

      return from?.match(regex) != null && to?.match(regex) != null && from < to
        ? null
        : { operatingTimesInvalid: true };
    };
  }

  private isEdited(): boolean {
    return (
      this.sisMediaSettingEdited != null &&
      (this.sisMediaSettingEdited.winterSeasonStartDate !== this.sisMediaSetting.winterSeasonStartDate ||
        this.sisMediaSettingEdited.winterSeasonEndDate !== this.sisMediaSetting.winterSeasonEndDate ||
        this.sisMediaSettingEdited.liftsOpenFrom !== this.sisMediaSetting.liftsOpenFrom ||
        this.sisMediaSettingEdited.liftsOpenTo !== this.sisMediaSetting.liftsOpenTo)
    );
  }
}
