import { RopewayAvailability } from 'src/app/maps/livedata/ropeway-availability.model';

export class RopewayAvailabilityAdapter {
  static adapt(items: any): RopewayAvailability[] {
    const livedata: RopewayAvailability[] = [];

    if (items) {
      items.forEach((element) => {
        livedata.push({
          tenantSisId: element.TenantSisId,
          ropewaySisId: element.RopewaySisId,
          availability: element.Availability,
        });
      });
    }

    return livedata;
  }
}
