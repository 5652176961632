import { BreakdownInfo } from 'src/app/breakdowninfo/domain/breakdowninfo.model';

export class BreakdownInfoAdapter {
  static adapt(items: any): BreakdownInfo[] {
    const result: BreakdownInfo[] = [];

    if (items) {
      items.forEach((e: any) => {
        result.push({ name: e.name, iconName: e.iconName, guid: e.guid, isUpdating: false });
      });
    }

    return result;
  }
}
