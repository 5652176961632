import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { skip, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MeteoInfoItem } from 'src/app/meteo-info/domain/meteo-info-item.model';

@Component({
  selector: 'sis-meteo-info-item',
  templateUrl: './meteo-info-item.component.html',
  styleUrls: ['./meteo-info-item.component.scss'],
})
export class MeteoInfoItemComponent extends Unsubscriber implements OnChanges, OnInit {
  readonly slopeCondition: Array<{ value: number; translationString: string }> = [
    { value: 800, translationString: 'stnet.phrase.condition.noInfo' },
    { value: 801, translationString: 'stnet.phrase.condition.good' },
    { value: 802, translationString: 'stnet.phrase.condition.goodFair' },
    { value: 803, translationString: 'stnet.phrase.condition.fairGood' },
    { value: 804, translationString: 'stnet.phrase.condition.fair' },
    { value: 805, translationString: 'stnet.phrase.condition.closed' },
    { value: 806, translationString: 'stnet.phrase.condition.eos' },
  ];

  readonly snowCondition: Array<{ value: number; translationString: string }> = [
    { value: 900, translationString: 'stnet.phrase.condition.noInfo' },
    { value: 901, translationString: 'stnet.phrase.condition.powder' },
    { value: 905, translationString: 'stnet.phrase.condition.hard' },
    { value: 909, translationString: 'stnet.phrase.condition.sulz' },
    { value: 913, translationString: 'stnet.phrase.condition.wet' },
  ];

  readonly avalancheLevel: Array<{ value: number; translationString: string }> = [
    { value: 0, translationString: 'meteoinfo.term.dangerlevel.noInfo' },
    { value: 1, translationString: 'meteoinfo.term.dangerlevel.low' },
    { value: 2, translationString: 'meteoinfo.term.dangerlevel.moderate' },
    { value: 3, translationString: 'meteoinfo.term.dangerlevel.considerable' },
    { value: 4, translationString: 'meteoinfo.term.dangerlevel.high' },
    { value: 5, translationString: 'meteoinfo.term.dangerlevel.veryHigh' },
  ];

  @Input() meteoInfoItem: MeteoInfoItem;
  @Input() meteoInfoDisplayedItem: MeteoInfoItem;
  @Input() meteoInfoItemsInRow: MeteoInfoItem[];

  @Output() formDataValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formDataModified: EventEmitter<boolean> = new EventEmitter<boolean>();

  bigScreenMode: boolean;
  writePermission: boolean;
  formGroup: FormGroup<{
    temperature: FormControl<number>;
    windSpeed: FormControl<number>;
    snowDepth: FormControl<number>;
    freshSnow: FormControl<number>;
    latestSnowfall: FormControl<any>;
    slopeConditionId: FormControl<number>;
    snowConditionId: FormControl<number>;
    avalancheDangerLevel: FormControl<number>;
  }>;
  formControls: {
    [key: string]: AbstractControl;
  };
  rerender: boolean = false;
  weatherIconUrl: string;
  title: string;
  showWeatherImportRows: boolean;
  hasWeatherImport: boolean;

  constructor(
    private destinationService: DestinationService,
    private screenSizeService: ScreenSizeService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.meteoInfoItem.weather) {
      this.weatherIconUrl = `/assets/meteoinfo/${this.meteoInfoItem.weather}.png`;
    }

    this.showWeatherImportRows = this.meteoInfoItemsInRow.some((m) => m.hasWeatherImport);
    this.hasWeatherImport = this.meteoInfoItem.hasWeatherImport;

    this.formGroup = new FormGroup({
      temperature: new FormControl<number>(
        null,
        this.hasWeatherImport ? [] : [Validators.pattern(/^-?\d+$/), Validators.required]
      ),
      windSpeed: new FormControl<number>(null, [Validators.min(0), Validators.pattern(/^\d+$/)]),
      snowDepth: new FormControl<number>(null, [Validators.min(0), Validators.pattern(/^\d+$/), Validators.required]),
      freshSnow: new FormControl<number>(null, [Validators.min(0), Validators.pattern(/^\d+$/), Validators.required]),
      latestSnowfall: new FormControl<any>(null, [Validators.required]),
      slopeConditionId: new FormControl<number>(null),
      snowConditionId: new FormControl<number>(null),
      avalancheDangerLevel: new FormControl<number>(null),
    });

    this.formControls = this.formGroup.controls;

    this.formGroup.valueChanges.pipe(skip(1), takeUntil(this.onDestroy$)).subscribe(() => {
      const changedItem = this.formGroup.getRawValue();
      if (this.meteoInfoDisplayedItem) {
        this.meteoInfoDisplayedItem.snowDepth = this.getNumberOrNull(changedItem.snowDepth);
        this.meteoInfoDisplayedItem.freshSnow = this.getNumberOrNull(changedItem.freshSnow);
        this.meteoInfoDisplayedItem.temperature = this.getNumberOrNull(changedItem.temperature);
        this.meteoInfoDisplayedItem.windSpeed = this.getNumberOrNull(changedItem.windSpeed);
        this.meteoInfoDisplayedItem.latestSnowfallUtc = new Date(changedItem.latestSnowfall);
        this.meteoInfoDisplayedItem.slopeConditionId = this.getNumberOrNull(changedItem.slopeConditionId);
        this.meteoInfoDisplayedItem.snowConditionId = this.getNumberOrNull(changedItem.snowConditionId);
        this.meteoInfoDisplayedItem.avalancheDangerLevel = this.getNumberOrNull(changedItem.avalancheDangerLevel);

        this.formDataValid.emit(this.formGroup.valid);
        this.formDataModified.emit(this.isEdited(this.meteoInfoItem, this.meteoInfoDisplayedItem));
      }
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((screenMode) => (this.bigScreenMode = screenMode));

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_METEOINFO, FeatureAccessLevel.WRITE))
      );

      if (this.writePermission) {
        this.formGroup.enable();
      } else {
        this.formGroup.disable();
      }

      if (this.meteoInfoItem) {
        this.setValues(this.meteoInfoItem);
      }
    });

    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.rerender = true;
      setTimeout(() => {
        this.rerender = false;
      }, 50);
    });
  }

  ngOnChanges(): void {
    if (this.meteoInfoItem) {
      this.setValues(this.meteoInfoItem);
      this.title = `${this.meteoInfoItem.location.toUpperCase()} - ${this.meteoInfoItem.altitude}`;
    }
  }

  setValues(meteoInfoItem: MeteoInfoItem): void {
    this.formGroup?.setValue(
      {
        snowDepth: meteoInfoItem.snowDepth,
        freshSnow: meteoInfoItem.freshSnow,
        temperature: meteoInfoItem.temperature,
        windSpeed: meteoInfoItem.windSpeed,
        latestSnowfall: moment(meteoInfoItem.latestSnowfallUtc).format('YYYY-MM-DD'),
        slopeConditionId: meteoInfoItem.slopeConditionId,
        snowConditionId: meteoInfoItem.snowConditionId,
        avalancheDangerLevel: meteoInfoItem.avalancheDangerLevel,
      },
      { emitEvent: false }
    );

    if (this.meteoInfoItem && this.hasWeatherImport) {
      this.formGroup.controls.temperature.disable({ emitEvent: false });
    }
  }

  private isEdited(item: MeteoInfoItem, otherItem: MeteoInfoItem): boolean {
    const different =
      item.snowDepth !== otherItem.snowDepth ||
      item.freshSnow !== otherItem.freshSnow ||
      item.temperature !== otherItem.temperature ||
      item.windSpeed !== otherItem.windSpeed ||
      moment(item.latestSnowfallUtc).format('YYYY-MM-DD') !==
        moment(otherItem.latestSnowfallUtc).format('YYYY-MM-DD') ||
      item.slopeConditionId !== otherItem.slopeConditionId ||
      item.avalancheDangerLevel !== otherItem.avalancheDangerLevel ||
      item.snowConditionId !== otherItem.snowConditionId;

    return different;
  }

  private getNumberOrNull(item: string | number): number {
    return Number.parseInt(item + '');
  }
}
