<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.cancel()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title class="sis-modal-title">{{ this.titleTranslationString | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <form *ngIf="this.formGroup" [formGroup]="this.formGroup">
    <!-- Big Screen -->
    <ion-grid class="sis-modal-grid" *ngIf="this.bigScreenMode$ | async">
      <ion-row class="vertical-align-content sis-modal-row-35">
        <ion-col size="4" class="sis-modal-input-label">
          {{ 'sismediaEdit.term.season' | translate }}
        </ion-col>
        <ion-col size="8">
          <ion-icon
            *ngIf="this.seasonIcon"
            class="sis-icon"
            [name]="this.writePermission ? this.seasonIcon.iconName : this.seasonIcon.iconNameDisabled"
            [matTooltip]="this.seasonIcon.translateKey | translate"
          ></ion-icon>
          <ion-select
            class="sis-sismedia-select"
            [ngClass]="{ 'sis-sismedia-input-error': this.formGroup.controls.season.errors }"
            interface="popover"
            [interfaceOptions]="{
            cssClass: 'sis-global-popover sis-global-noscrollbar sis-sismedia-select-popover',
            size: 'cover',
          }"
            formControlName="season"
            data-test="sismedia-asset-edit-season-select"
          >
            <ion-select-option [value]="0">{{ 'sismediaEdit.term.summerAndWinter' | translate }}</ion-select-option>
            <ion-select-option [value]="1">{{ 'sismediaEdit.term.summer' | translate }}</ion-select-option>
            <ion-select-option [value]="2">{{ 'sismediaEdit.term.winter' | translate }}</ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-modal-row-35">
        <ion-col size="4" class="sis-modal-input-label">
          {{ 'sismediaEdit.term.type' | translate }}
        </ion-col>
        <ion-col size="8">
          <ion-icon class="sis-icon" [src]="this.typeIcon" />
          <ion-select
            class="sis-sismedia-select"
            [ngClass]="{ 'sis-sismedia-input-error': this.formGroup.controls.type.errors }"
            interface="popover"
            [interfaceOptions]="{
              cssClass: 'sis-global-popover sis-global-noscrollbar sis-sismedia-select-popover',
              size: 'cover',
            }"
            formControlName="type"
            data-test="sismedia-asset-edit-type-select"
          >
            <ion-select-option *ngFor="let type of this.sisMediaAssetTypes$ | async" [value]="type.name">{{
              type.description
            }}</ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>
      <ion-row
        *ngFor="let fieldOptions of this.inputFieldOptions"
        class="vertical-align-content"
        [ngClass]="fieldOptions.inputType === 'textarea' ? 'sis-modal-row-70' : 'sis-modal-row-35'"
      >
        <ion-col size="4" class="sis-modal-input-label">
          {{ fieldOptions.inputLabelTranslateKey | translate }}
        </ion-col>

        <ion-col
          style="padding-right: 0"
          matTooltip="{{
            (fieldOptions.includedInImports && fieldOptions.includedInImports.includes(this.importType)
              ? this.importTypeNameTranslationString
              : ''
            ) | translate
          }}"
        >
          <ion-input
            *ngIf="fieldOptions.inputType === 'text'"
            type="text"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
            inputmode="text"
            [maxlength]="fieldOptions.maxLength"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-input
            *ngIf="fieldOptions.inputType === 'time'"
            type="time"
            class="sis-modal-input sis-modal-time-input"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
            inputmode="time"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-input
            sis-integer-input
            *ngIf="fieldOptions.inputType === 'number'"
            type="text"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
            inputmode="text"
            [maxlength]="fieldOptions.maxLength"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-input
            *ngIf="fieldOptions.inputType === 'tel'"
            type="tel"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName]?.errors }"
            inputmode="tel"
            placeholder="+41 41 123 45 67"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-input
            *ngIf="fieldOptions.inputType === 'email'"
            type="email"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
            inputmode="email"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-input
            *ngIf="fieldOptions.inputType === 'website'"
            type="url"
            class="sis-modal-input sis-modal-text-input"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
            inputmode="url"
            placeholder="https://example.ch"
            pattern="https://.*"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-input>
          <ion-textarea
            *ngIf="fieldOptions.inputType === 'textarea'"
            type="text"
            class="sis-global-textarea-no-scrollbar sis-modal-input sis-modal-textarea-input"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
            rows="3"
            inputmode="text"
            [maxlength]="fieldOptions.maxLength"
            [attr.data-test]="fieldOptions.dataTestKey"
            [formControlName]="fieldOptions.propertyName"
          ></ion-textarea>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-modal-row-35">
        <ion-col size="4" class="sis-modal-input-label">
          {{ 'sismedia.term.status' | translate }}
        </ion-col>
        <ion-col>
          <div
            [matTooltip]="
              (this.hasMySqlRouter$ | async) && !this.isNewItem
                ? ('sismediaEdit.phrase.mysqlAvailable' | translate)
                : null
            "
          >
            <ion-checkbox
              color="secondary"
              class="sis-checkbox-input"
              [checked]="this.hasStatus"
              formControlName="hasStatus"
              data-test="sismedia-edit-status-checkbox"
            ></ion-checkbox>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-modal-row-35 ion-no-padding" style="color: grey; max-height: 25px">
        <ion-col class="sis-modal-input-label" size="4">ID</ion-col>
        <ion-col class="sis-modal-input-label">
          {{ this.sisMediaItem.id }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-modal-row-35" style="color: grey; max-height: 25px">
        <ion-col class="sis-modal-input-label" size="4">Guid</ion-col>
        <ion-col>
          <span
            (click)="this.copyToClipboard(this.sisMediaItem.assetGuid)"
            style="cursor: copy"
            class="sis-modal-input-label"
          >
            {{ this.sisMediaItem.assetGuid }}
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- Small Screen -->
    <ion-grid *ngIf="!(this.bigScreenMode$ | async)" class="sis-modal-grid">
      <ion-row class="sis-sismedia-mobile-row">
        <ion-col style="padding-bottom: 0">
          {{ 'sismediaEdit.term.season' | translate }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-modal-row-40">
        <ion-col>
          <ion-icon
            class="sis-icon"
            [name]="this.writePermission ? this.seasonIcon.iconName : this.seasonIcon.iconNameDisabled"
            [matTooltip]="this.seasonIcon.translateKey | translate"
          ></ion-icon>
          <ion-select
            class="sis-sismedia-select"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls.season.errors }"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover sis-global-noscrollbar', size: 'cover' }"
            data-test="sismedia-asset-edit-season-select"
            formControlName="season"
          >
            <ion-select-option [value]="0">{{ 'sismediaEdit.term.summerAndWinter' | translate }}</ion-select-option>
            <ion-select-option [value]="1">{{ 'sismediaEdit.term.summer' | translate }}</ion-select-option>
            <ion-select-option [value]="2">{{ 'sismediaEdit.term.winter' | translate }}</ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>

      <ion-row class="sis-sismedia-mobile-row">
        <ion-col style="padding-bottom: 0">
          {{ 'sismediaEdit.term.type' | translate }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-modal-row-40">
        <ion-col>
          <ion-icon class="sis-icon" [src]="this.typeIcon" />
          <ion-select
            class="sis-sismedia-select"
            [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls.type.errors }"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover sis-global-noscrollbar', size: 'cover' }"
            data-test="sismedia-asset-edit-type-select"
            formControlName="type"
          >
            <ion-select-option *ngFor="let type of this.sisMediaAssetTypes$ | async" [value]="type.name">{{
              type.description
            }}</ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>
      <ng-container *ngFor="let fieldOptions of this.inputFieldOptions">
        <ion-row class="sis-sismedia-mobile-row">
          <ion-col style="padding-bottom: 0">
            {{ fieldOptions.inputLabelTranslateKey | translate }}
          </ion-col>
        </ion-row>
        <ion-row class="vertical-align-content sis-sismedia-mobile-row">
          <ion-col
            matTooltip="{{
              (fieldOptions.includedInImports && fieldOptions.includedInImports.includes(this.importType)
                ? this.importTypeNameTranslationString
                : ''
              ) | translate
            }}"
          >
            <ion-input
              *ngIf="fieldOptions.inputType === 'text'"
              type="text"
              class="sis-modal-input sis-modal-text-input"
              [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
              inputmode="text"
              [maxlength]="fieldOptions.maxLength"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-input
              *ngIf="fieldOptions.inputType === 'time'"
              type="time"
              class="sis-modal-input sis-modal-text-input"
              [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
              inputmode="time"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-input
              sis-integer-input
              *ngIf="fieldOptions.inputType === 'number'"
              type="text"
              class="sis-modal-input sis-modal-text-input"
              [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
              inputmode="text"
              [maxlength]="fieldOptions.maxLength"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-input
              *ngIf="fieldOptions.inputType === 'tel'"
              type="tel"
              class="sis-modal-input sis-modal-text-input"
              [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
              inputmode="tel"
              placeholder="+41 41 123 45 67"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-input
              *ngIf="fieldOptions.inputType === 'email'"
              type="email"
              class="sis-modal-input sis-modal-text-input"
              [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
              inputmode="email"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-input
              *ngIf="fieldOptions.inputType === 'website'"
              type="url"
              class="sis-modal-input sis-modal-text-input"
              [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
              inputmode="url"
              placeholder="https://"
              pattern="https://.*"
              [formControlName]="fieldOptions.propertyName"
            ></ion-input>
            <ion-textarea
              *ngIf="fieldOptions.inputType === 'textarea'"
              type="text"
              class="sis-modal-input sis-modal-textarea-input"
              [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls[fieldOptions.propertyName].errors }"
              rows="3"
              inputmode="text"
              [maxlength]="fieldOptions.maxLength"
              [formControlName]="fieldOptions.propertyName"
            ></ion-textarea>
          </ion-col>
        </ion-row>
      </ng-container>
      <ion-row class="vertical-align-content sis-sismedia-mobile-row">
        <ion-col>
          {{ 'sismedia.term.status' | translate }}
        </ion-col>
        <ion-col style="padding-top: 5px">
          <div
            [matTooltip]="
              (this.hasMySqlRouter$ | async) && !this.isNewItem
                ? ('sismediaEdit.phrase.mysqlAvailable' | translate)
                : null
            "
          >
            <ion-checkbox
              color="secondary"
              class="sis-checkbox-input"
              [checked]="this.hasStatus"
              data-test="sismedia-edit-status-checkbox"
              formControlName="hasStatus"
            ></ion-checkbox>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="vertical-align-content sis-sismedia-mobile-row" style="color: grey">
        <ion-col size="3">ID</ion-col>
        <ion-col>
          {{ this.sisMediaItem.id }}
        </ion-col>
      </ion-row>
      <ion-row class="vertical-align-content sis-sismedia-mobile-row" style="color: grey">
        <ion-col size="3">Guid</ion-col>
        <ion-col>
          <span (click)="this.copyToClipboard(this.sisMediaItem.assetGuid)" style="cursor: copy">
            {{ this.sisMediaItem.assetGuid }}
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [disabled]="!this.hasUnsavedChanges || this.isSaving || this.formGroup.invalid"
      [showSpinner]="this.isSaving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      data-test="sismedia-asset-edit-save-btn"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      (sisClick)="this.cancel()"
      [showSpinner]="this.isSaving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      data-test="sismedia-asset-edit-cancel-btn"
    ></sis-general-button>
    <sis-general-button
      *ngIf="!this.isNewItem"
      [text]="'general.term.delete'"
      [btnType]="'Red'"
      [btnAlign]="'Left'"
      (sisClick)="this.delete()"
      [disabled]="this.isSaving || !this.deleteAllowed"
      [showSpinner]="this.isSaving"
      [width]="(this.bigScreenMode$ | async) ? '120px' : '100%'"
      [sisTooltip]="this.deleteAllowedTooltip"
      data-test="sismedia-asset-edit-delete-btn"
    ></sis-general-button>
  </div>
</ion-footer>
