import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MathUtils } from 'src/app/core/utils/mathUtils';
import { WidgetUtils } from 'src/app/core/utils/widgetUtils';
import { Configuration } from 'src/app/maps/domain/configuration.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayOperationWidgetBase } from 'src/app/maps/widgets/ropeway-operation-widget-base';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-speed-widget',
  templateUrl: './speed-widget.component.html',
  styleUrls: ['./speed-widget.component.scss'],
})
export class SpeedWidgetComponent extends RopewayOperationWidgetBase implements OnInit {
  static rotationUnknown = 0;

  actualSpeedMps: string;
  rotationDeg = 0;
  background = '';

  constructor(ropewayLiveDataService: RopewayLivedataService, selectedMapElementService: SelectedMapElementService) {
    super(ropewayLiveDataService, selectedMapElementService);
  }

  ngOnInit(): void {
    this.ropewayLiveData$.pipe(takeUntil(this.onDestroy$)).subscribe((d) => {
      if (d.liveData?.actualSpeedMps == null) {
        this.resetToDefault();
        return;
      }

      const speed = d.liveData.actualSpeedMps;
      this.actualSpeedMps = Number(MathUtils.roundTo(speed, 1)).toFixed(1);
      this.rotateWidget(d.ropeway, speed);
    });
  }

  private rotateWidget(ropeway: Ropeway, actualSpeedMps: number): void {
    const configuration: Configuration = ropeway?.configurations?.find(
      (c) => c.configKey === Configuration.actualSpeedMps
    );

    if (!configuration) {
      this.noConfigError();
      return;
    }

    this.rotationDeg = Math.abs(
      WidgetUtils.calculateDegreeWithLimit(actualSpeedMps, configuration.nominalValue, 0, -180)
    );
    this.background = '';
  }

  private noConfigError(): void {
    this.rotationDeg = SpeedWidgetComponent.rotationUnknown;
    this.background = 'sis-error-bg';
  }

  private resetToDefault(): void {
    this.actualSpeedMps = WidgetBase.valueUnknown;
    this.rotationDeg = SpeedWidgetComponent.rotationUnknown;
  }
}
