import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-deviceson-widget',
  templateUrl: './deviceson-widget.component.html',
  styleUrls: ['./deviceson-widget.component.scss'],
})
export class DevicesonWidgetComponent extends WidgetBase implements OnInit {
  totalDevices: string;

  constructor(private masterdataService: MasterdataService) {
    super();
  }

  ngOnInit(): void {
    this.masterdataService.masterData$.pipe(takeUntil(this.onDestroy$)).subscribe((masterdata) => {
      if (masterdata?.ropeways) {
        this.totalDevices = masterdata.ropeways.length.toString();
      }
    });
  }
}
