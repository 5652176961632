import { Directive } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayLivedata } from 'src/app/maps/livedata/ropeway-livedata.model';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Directive()
export abstract class RopewayOperationWidgetBase extends WidgetBase {
  protected showOutdatedData = false;

  outdated: boolean;
  dataTimestamp: Date;

  ropewayLiveData$: Observable<{ ropeway?: Ropeway; liveData?: RopewayLivedata }> =
    this.selectedMapElementService.selectedRopeway$.pipe(
      switchMap((ropeway) => {
        if (!ropeway) {
          return of({});
        } else {
          return this.ropewayLiveDataService.ropewayLiveData$.pipe(
            map((ropewayLiveDatas) => {
              if (!ropewayLiveDatas) {
                return { ropeway };
              }
              const liveData = ropewayLiveDatas.find((data: RopewayLivedata) => data.deviceSisId === ropeway.sisId);

              this.outdated = liveData?.outdated;
              this.dataTimestamp = liveData?.deviceTimestamp;

              if (!liveData || (this.outdated && !this.showOutdatedData)) {
                return { ropeway };
              }

              return { ropeway, liveData };
            })
          );
        }
      })
    );

  constructor(
    private ropewayLiveDataService: RopewayLivedataService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }
}
