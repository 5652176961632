<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <rect width="187.5" height="225" style="fill: none" />
        <g>
          <path
            d="M142.12,159.34a27.64,27.64,0,0,0-27.64-27.64"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="114.48"
            y1="131.7"
            x2="114.48"
            y2="125.32"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="123.02"
            y1="133.05"
            x2="125.57"
            y2="125.19"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="130.72"
            y1="136.98"
            x2="135.58"
            y2="130.3"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="142.12"
            y1="159.34"
            x2="148.49"
            y2="159.34"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="99.69"
            y1="125.32"
            x2="99.69"
            y2="131.7"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="76.76"
            y1="125.32"
            x2="76.76"
            y2="131.7"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="142.12"
            y1="159.34"
            x2="148.49"
            y2="159.34"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <path
            d="M142.12,159.34a27.64,27.64,0,0,0-27.64-27.64"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="114.48"
            y1="131.7"
            x2="114.48"
            y2="123.44"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="136.84"
            y1="143.09"
            x2="143.52"
            y2="138.24"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="140.77"
            y1="150.8"
            x2="148.62"
            y2="148.25"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <path
            d="M142.12,159.34h8.25a35.9,35.9,0,0,0-35.89-35.9"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <polyline
            points="114.47 131.7 49.28 131.7 49.28 123.44 114.47 123.44"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="99.69"
            y1="123.44"
            x2="99.69"
            y2="129.82"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="76.76"
            y1="123.44"
            x2="76.76"
            y2="131.7"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <path
            d="M142.12,159.34A27.65,27.65,0,0,1,114.48,187"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="114.48"
            y1="186.99"
            x2="114.48"
            y2="193.36"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="123.02"
            y1="185.63"
            x2="125.57"
            y2="193.49"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="130.72"
            y1="181.71"
            x2="135.58"
            y2="188.38"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="142.12"
            y1="159.34"
            x2="148.49"
            y2="159.34"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="99.69"
            y1="193.36"
            x2="99.69"
            y2="186.99"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="76.76"
            y1="193.36"
            x2="76.76"
            y2="186.99"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="142.12"
            y1="159.34"
            x2="148.49"
            y2="159.34"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <path
            d="M142.12,159.34A27.65,27.65,0,0,1,114.48,187"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="114.48"
            y1="186.99"
            x2="114.48"
            y2="195.24"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="136.84"
            y1="175.59"
            x2="143.52"
            y2="180.44"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="140.77"
            y1="167.88"
            x2="148.62"
            y2="170.44"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <path
            d="M142.12,159.34h8.25a35.89,35.89,0,0,1-35.89,35.9"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <polyline
            points="114.47 186.99 49.28 186.99 49.28 195.24 114.47 195.24"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="99.69"
            y1="195.24"
            x2="99.69"
            y2="188.86"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="76.76"
            y1="195.24"
            x2="76.76"
            y2="186.99"
            style="fill: none;stroke: #979797;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <rect
            x="76.76"
            y="123.44"
            width="22.93"
            height="8.25"
            style="fill: none;stroke: #91cddb;stroke-miterlimit: 10;stroke-width: 4px"
          />
        </g>
        <g>
          <path
            d="M112.55,55.78c0,14.72-7.73,28.49-18.8,28.49S75,70.5,75,55.78,81.15,31,93.75,31,112.55,41.05,112.55,55.78Z"
            style="fill: none;stroke: #32849a;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <path
            d="M52.25,110.22c0-8.25,12.58-13.35,29.68-15.42l12,13.36L105.57,94.8c17.1,2.07,29.68,7.17,29.68,15.42"
            style="fill: none;stroke: #32849a;stroke-miterlimit: 10;stroke-width: 4px"
          />
        </g>
      </g>
    </g>
  </g>
</svg>
