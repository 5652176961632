export enum FeatureId {
  COCKPIT = 1000,
  COCKPIT_TELEMETRY = 1001,
  COCKPIT_WEBCAM = 1002,
  COCKPIT_REMOTEACCESS = 1003,
  COCKPIT_ENERGY = 1004,
  COCKPIT_FIRSTENTRIES = 1005,
  COCKPIT_FREQUENCIES = 1006,
  COCKPIT_KASSANDRA = 1007,
  COCKPIT_SNOWMAKING = 1008,
  COCKPIT_ABBSMARTSENSOR = 1009,
  COCKPIT_ECOMODE = 1010,
  COCKPIT_SAMBESI_WHATSUP = 1011,
  COCKPIT_TRUSCAN = 1012,
  COCKPIT_ROPEWAY_SETTINGS = 1013,
  COCKPIT_ROPEWAY_AVAILABILITY = 1014,
  COCKPIT_ROPEWAY_CLAMPINGCOUNT_RESET = 1015,

  SISMEDIA_GASTRO = 2000,
  SISMEDIA_LIFT = 2001,
  SISMEDIA_POI = 2002,
  SISMEDIA_SLEDGING = 2003,
  SISMEDIA_SLOPE = 2004,
  SISMEDIA_TRAIL = 2005,
  SISMEDIA_PARKING = 2006,
  SISMEDIA_CROSSCOUNTRY = 2007,
  SISMEDIA_BIKE = 2008,

  SISMEDIA_ASSET_EDIT = 2100,
  SISMEDIA_ASSETOVERRIDE = 2101,
  SISMEDIA_INFOTEXT = 2102,
  SISMEDIA_LASTSLOPECONTROL = 2103,
  SISMEDIA_MEDIACENTER = 2104,
  SISMEDIA_METEOINFO = 2105,
  SISMEDIA_STNET = 2106,
  SISMEDIA_TIMETABLE = 2107,
  SISMEDIA_LEDTICKER = 2108,
  SISMEDIA_EMAILDELIVERY = 2109,
  SISMEDIA_OPERATINGHOURS = 2110,
  SISMEDIA_MEDIACENTER_INFOBANNER = 2111,
  SISMEDIA_SLOPESOPERATINGTIMES = 2112,

  BREAKDOWNINFO = 3000,
  OPERATINGINFO = 3001,
  GLOBALVIEW = 3002,
  SISITSERVICES = 3003,
  WEBLINKCOLLECTION = 3004,
  LOGBOOK = 3005,
  USERMANAGEMENT = 3006,
  PARKING = 3007,

  WINDMONITOR = 4000,
}
