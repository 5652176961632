import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, firstValueFrom, map, merge, takeUntil, withLatestFrom } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { FeatureUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { FeatureAdapter } from 'src/app/feature-management/domain/feature.adapter';
import { FeatureManagementFeature } from 'src/app/feature-management/domain/feature.model';
import { UserManagementService } from 'src/app/user-management/user-management.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-feature-management',
  templateUrl: './feature-management.page.html',
  styleUrls: ['./feature-management.page.scss'],
})
export class FeatureManagementPage extends Unsubscriber implements OnInit {
  headers = [
    { size: '2', label: 'featuremanagement.term.active' },
    { size: '2', label: 'featuremanagement.term.admin' },
    { size: '8', label: 'featuremanagement.term.feature' },
  ];

  featureRanges = [
    { min: 0, max: 2000 },
    { min: 2000, max: 3000 },
    { min: 3000, max: Infinity },
  ];

  private readonly baseUrl: string = '/features';
  private readonly allFeatures$ = merge(
    this.http.get(`${environment.baseUrlApi}${this.baseUrl}`),
    this.eventBus.observe(FeatureUpdatedEvent).pipe(
      filter((e) => e.updateSuccessful && e.tenantGuid === this.tenantGuid),
      map((e) => e.features)
    )
  ).pipe(map((data) => FeatureAdapter.adapt(data)));

  private features: FeatureManagementFeature[];
  private tenantGuid: string;
  editedFeatures: FeatureManagementFeature[];
  isSaving: boolean;
  unsavedChanges: boolean;

  constructor(
    private userManagementService: UserManagementService,
    private userMessageService: UserMessageService,
    private userSettingsService: UserSettingsService,
    private destinationService: DestinationService,
    private http: HttpClient,
    private alertController: AlertController,
    private eventBus: EventBusService,
    private translateService: TranslateService
  ) {
    super();
  }

  async ngOnInit() {
    combineLatest([this.allFeatures$, this.userManagementService.featureTranslationMap$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([features, featureTranslationMap]) => {
        this.features = features;
        this.features.forEach((feature) => (feature.nameTranslationString = featureTranslationMap.get(feature.id)));
        this.editedFeatures = JSON.parse(JSON.stringify(features));
      });

    this.tenantGuid = (await firstValueFrom(this.destinationService.selectedTenant$)).guid;

    this.eventBus
      .observe(FeatureUpdatedEvent)
      .pipe(
        takeUntil(this.onDestroy$),
        withLatestFrom(this.userSettingsService.userSettings$),
        filter(([event, userSettings]) => event.changedBy === userSettings.userGuid)
      )
      .subscribe(([event]) => {
        this.isSaving = false;

        if (event.updateSuccessful) {
          const translateKey = 'general.phrase.saved';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.success,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.green,
          });
          this.userMessageService.presentToast(userMessage);
        } else {
          const translateKey = 'general.phrase.saveFailed';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.failed,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);
        }
      });
  }

  async save(): Promise<void> {
    this.isSaving = true;

    const removedFeatures = this.editedFeatures.filter(
      (editedFeature) => this.features.find((feature) => feature.id === editedFeature.id).active && !editedFeature.active
    );

    if (removedFeatures.length > 0) {
      await this.presentAlert(removedFeatures);
      return;
    }

    await this.postData();
  }

  reset(): void {
    this.editedFeatures = JSON.parse(JSON.stringify(this.features));
  }

  onChanged(): void {
    this.unsavedChanges = JSON.stringify(this.features) !== JSON.stringify(this.editedFeatures);
  }

  private async presentAlert(removedFeatures: FeatureManagementFeature[]): Promise<void> {
    const message = '- ' + removedFeatures.map((f) => f.nameTranslationString).join('<br>- ');

    const alert = await this.alertController.create({
      header: this.translateService.instant('general.term.danger'),
      subHeader: this.translateService.instant('user.message.featureManagement.delete'),
      message: message,
      htmlAttributes: {
        'data-test': 'feature-management-delete-alert',
      },
      buttons: [
        {
          text: this.translateService.instant('general.term.cancel'),
          id: 'data-test-feature-management-cancel-button',
          handler: () => {
            this.isSaving = false;
          },
        },
        {
          text: this.translateService.instant('general.term.delete'),
          id: 'data-test-feature-management-delete-button',
          handler: async () => {
            await this.postData();
          },
        },
      ],
      backdropDismiss: false,
      cssClass: 'sis-ion-alert',
    });

    await alert.present();
  }

  private async postData() {
    try {
      await firstValueFrom(
        this.http.post(`${environment.baseUrlApi}${this.baseUrl}`, {
          Features: this.editedFeatures.filter((feature) => feature.active).map((feature) => feature),
        })
      );
    } catch {
      const translateKey = 'general.phrase.saveFailed';
      const userMessage = new UserMessage({
        message: translateKey,
        icon: UserMessageIcon.failed,
        durationMs: 2000,
        position: 'top',
        color: UserMessageColor.red,
      });
      this.userMessageService.presentToast(userMessage);
      this.isSaving = false;
    }
  }
}
