import { TenantAdapter } from 'src/app/domain/tenant/tenant.adapter';
import { UserSettings } from 'src/app/domain/user-settings/user-settings.model';

export class UserSettingsAdapter {
  static adapt(item: any): UserSettings | null {
    if (item) {
      let timeFormat = item.timeFormat;
      if (!timeFormat) {
        timeFormat = 1;
      }
      return {
        userGuid: item.userGuid,
        defaultTenant: TenantAdapter.adaptTenant(item.defaultTenant),
        guiLanguage: item.guiLanguage,
        timeFormat,
        selectedSeason: item.selectedSeason,
        isAdministrator: item.isAdministrator,
        lastNewsViewed: item.lastNewsViewed != null ? new Date(item.lastNewsViewed) : null,
      };
    }
    return null;
  }
}
