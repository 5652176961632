import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { combineLatest, filter, firstValueFrom, map, switchMap, takeUntil } from 'rxjs';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { MathUtils } from 'src/app/core/utils/mathUtils';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { RopewayEcoModeSettings } from 'src/app/maps/domain/ropeway-ecomode/ropeway-ecomode-settings.model';
import { RopewayEcoModeSettingsService } from 'src/app/maps/domain/ropeway-ecomode/ropeway-ecomode-settings.service';
import { RopewayEcoModeLivedataService } from 'src/app/maps/livedata/ropeway-ecomode-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayEcoModeSettingsModal } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ropeway-ecomode-settings-modal.component';

@Component({
  selector: 'sis-ropeway-ecomode-detailview',
  templateUrl: './ropeway-ecomode-detailview.component.html',
  styleUrls: ['./ropeway-ecomode-detailview.component.scss'],
})
export class RopewayEcoModeDetailViewComponent extends Unsubscriber implements OnInit {
  private readonly requiredFeatureWrite = new Feature(FeatureId.COCKPIT_ECOMODE, FeatureAccessLevel.WRITE);

  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  readonly hasWritePermission$ = this.destinationService.selectedTenantFeatures$.pipe(
    map((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeatureWrite)))
  );

  percent: number;
  percentText: string;
  currentPeopleFrequencyText: string;

  ecoModeEnabledPLC: boolean;
  ecoModeEnabledCockpit: boolean;
  speedEmpty: number;
  speedLow: number;
  speedMedium: number;
  speedHigh: number;
  thresholdLow: number;
  thresholdLowPercent: number;
  thresholdLowText: string;
  thresholdMedium: number;
  thresholdMediumPercent: number;
  thresholdMediumText: string;
  thresholdHighText: string;

  livedataAvailable: boolean;
  ecoModePLCDisabledText: string;
  outdated: boolean;

  ropewayEcoModeSettings: RopewayEcoModeSettings;

  activeThreshold: number = -1;

  constructor(
    private ropewayEcoModeSettingsService: RopewayEcoModeSettingsService,
    private ropewayEcoModeLivedataService: RopewayEcoModeLivedataService,
    private selectedMapElementService: SelectedMapElementService,
    private modalCtrl: ModalController,
    private logbookModalService: LogbookModalService,
    private destinationService: DestinationService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedMapElementService.selectedRopeway$
      .pipe(
        filter((ropeway) => !!ropeway),
        switchMap((ropeway) =>
          combineLatest([
            this.ropewayEcoModeSettingsService.ropewayEcoModeSettings$.pipe(
              map((settings) => settings.find((d) => d.ropewayGuid === ropeway.guid))
            ),
            this.ropewayEcoModeLivedataService.ropewayEcoModeLiveData$.pipe(
              map((livedata) => livedata.find((d) => d.deviceSisId === ropeway.sisId))
            ),
          ])
        ),
        filter(
          ([settings, livedata]) =>
            settings != null && (livedata == null || settings.ropewaySisId === livedata.deviceSisId)
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([settings, livedata]) => {
        const maxTransportQuantityPerMinute = settings.maxTransportQuantityPerHour / 60;

        this.livedataAvailable = livedata != null;
        this.ecoModeEnabledCockpit = settings.ecoModeEnabledCockpit;
        this.speedEmpty = +MathUtils.roundTo(settings.speedEmpty, 1);
        this.speedLow = +MathUtils.roundTo(settings.speedLow, 1);
        this.speedMedium = +MathUtils.roundTo(settings.speedMedium, 1);
        this.speedHigh = +MathUtils.roundTo(settings.speedHigh, 1);
        this.thresholdLow = settings.thresholdLow;
        this.thresholdLowPercent = this.thresholdLow / 100;
        this.thresholdMedium = settings.thresholdMedium;
        this.thresholdMediumPercent = this.thresholdMedium / 100;

        this.thresholdLowText = this.getThresholdText(0, this.thresholdLowPercent, maxTransportQuantityPerMinute);

        this.thresholdMediumText = this.getThresholdText(
          this.thresholdLowPercent,
          this.thresholdMediumPercent,
          maxTransportQuantityPerMinute
        );

        this.thresholdHighText = this.getThresholdText(this.thresholdMediumPercent, 1, maxTransportQuantityPerMinute);

        if (this.livedataAvailable) {
          this.ecoModeEnabledPLC = livedata.ecoModeEnabledPLC;
          this.currentPeopleFrequencyText = `${MathUtils.roundTo(livedata.peoplePerMinute, 1)} Pers/min`;
          this.percent = livedata.workloadPercent / 100;
          this.percentText = `${MathUtils.roundTo(livedata.workloadPercent, 1)}%`;
          this.ecoModePLCDisabledText = this.ecoModeEnabledPLC ? '' : 'ecomode.phrase.ecoModePLCDisabled';

          this.activeThreshold = this.getActiveThreshold(this.percent);
          this.outdated = livedata.outdated;
        }

        this.ropewayEcoModeSettings = settings;
      });
  }

  async toggleEcoMode(event: any): Promise<void> {
    event.stopPropagation();
    const hasWritePermission = await firstValueFrom(this.hasWritePermission$);
    const currentState = this.ecoModeEnabledCockpit;

    if (hasWritePermission) {
      const confirmationText = currentState ? 'ecomode.phrase.turnOff' : 'ecomode.phrase.turnOn';
      const confirmed = await this.confirmationDialogService.presentAlert(confirmationText, 'general.term.confirm');

      if (confirmed) {
        this.ecoModeEnabledCockpit = !currentState;
        this.ropewayEcoModeSettings.ecoModeEnabledCockpit = !currentState;
        this.ropewayEcoModeSettingsService
          .saveRopewayEcoModeSettings(this.ropewayEcoModeSettings)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((success) => {
            if (!success) {
              this.ecoModeEnabledCockpit = currentState;
              this.ropewayEcoModeSettings.ecoModeEnabledCockpit = currentState;
            }
          });
      }
    }
  }

  async openEcoModeSettings(): Promise<void> {
    const hasWritePermission = await firstValueFrom(this.hasWritePermission$);

    if (hasWritePermission) {
      const modal = await this.modalCtrl.create({
        component: RopewayEcoModeSettingsModal,
        componentProps: {
          ropewayEcoModeSettings: this.ropewayEcoModeSettings,
        },
        cssClass: 'sis-ropeway-ecomode-settings-modal',
      });
      await modal.present();
    }
  }

  async openLogbook(): Promise<void> {
    await this.logbookModalService.presentLogbookModal([[FeatureId.COCKPIT_ECOMODE]]);
  }

  private getActiveThreshold(percent: number): number {
    if (percent >= this.thresholdMediumPercent) {
      return 3;
    }
    if (percent >= this.thresholdLowPercent) {
      return 2;
    }
    if (percent > 0) {
      return 1;
    }
    return 0;
  }

  private getThresholdText(
    lowerLimitPercent: number,
    upperLimitPercent: number,
    maxTransportQuantityPerMinute: number
  ): string {
    if (upperLimitPercent === 1) {
      const lowerPersMin = MathUtils.roundTo(maxTransportQuantityPerMinute * lowerLimitPercent, 1);

      return `≥ ${lowerPersMin} Pers/min`;
    }

    const upperLimitPersMin = MathUtils.roundTo(maxTransportQuantityPerMinute * upperLimitPercent, 1);

    return `< ${upperLimitPersMin} Pers/min`;
  }
}
