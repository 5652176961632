<ion-grid class="sis-global-page-grid">
  <sis-page-title
    [title]="'general.term.breakdowninfo'"
    [featureId]="[[this.featureId]]"
  >
  </sis-page-title>

  <!-- Big Screen -->
  <ion-list *ngIf="this.bigScreenMode">
    <ion-item
      class="sis-breakdowninfo"
      [ngClass]="{'sis-clickable' : this.writePermission}"
      *ngFor="let breakdownInfo of this.breakdownInfos; let i = index"
      (click)="this.showTextSelector(breakdownInfo)"
      data-test="breakdowninfo-item"
    >
      <div>
        <img class="sis-breakdowninfo-icon" [src]="breakdownInfo.iconName" />
        <div class="sis-breakdowninfo-name">{{ breakdownInfo.name }}</div>
      </div>
      <ion-item lines="none" style="width: 100%">
        <ion-spinner *ngIf="breakdownInfo.isUpdating" name="dots"></ion-spinner>
        <div *ngIf="!breakdownInfo.isUpdating" class="sis-breakdowninfo-item">
          <sis-breakdowninfo-item [entry]="breakdownInfo.activeText"></sis-breakdowninfo-item>
        </div>
      </ion-item>
    </ion-item>
  </ion-list>

  <!-- Small Screen -->
  <ion-list *ngIf="!this.bigScreenMode">
    <div *ngFor="let breakdownInfo of this.breakdownInfos; let i = index" data-test="breakdowninfo-item">
      <ion-list [ngClass]="{'sis-clickable' : this.writePermission}" (click)="this.showTextSelector(breakdownInfo)">
        <ion-item lines="none" style="height: 40px">
          <img class="sis-breakdowninfo-icon-small" [src]="breakdownInfo.iconName" />
          <div class="sis-breakdowninfo-name-small">{{ breakdownInfo.name }}</div>
        </ion-item>
        <ion-item>
          <ion-spinner *ngIf="breakdownInfo.isUpdating" name="dots"></ion-spinner>
          <div *ngIf="!breakdownInfo.isUpdating" class="sis-breakdowninfo-item">
            <sis-breakdowninfo-item [entry]="breakdownInfo.activeText"></sis-breakdowninfo-item>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </ion-list>

  <sis-general-button
    [text]="'breakdowninfo.term.library'"
    [btnAlign]="this.bigScreenMode ? 'Right' : ''"
    [btnType]="'Secondary'"
    [width]="this.bigScreenMode ? '120px' : '100%'"
    (sisClick)="showBreakdownInfoLibrary()"
    [disabled]="!this.writePermission"
    data-test="breakdowninfo-library-modal-btn"
  ></sis-general-button>
</ion-grid>
