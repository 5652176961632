<!-- BigScreen -->
<div *ngIf="this.bigScreenMode" class="sis-recipient-item">
  <form [formGroup]="this.formGroup">
    <ion-row data-test="email-delivery-address-row vertical-align-content" class="sis-global-hover">
      <ion-col size="4" style="padding-top: 2px">
        <input
          type="name"
          class="sis-global-input sis-global-text-input"
          [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.name.errors }"
          (onselectstart)="(this.formGroup.enabled ? false : true)"
          formControlName="name"
          maxlength="100"
          placeholder="{{ 'general.term.name' | translate }}"
          data-test="email-delivery-recipient-name"
        />
      </ion-col>
      <ion-col size="3" style="padding-top: 2px">
        <input
          type="name"
          class="sis-global-input sis-global-text-input"
          formControlName="location"
          maxlength="50"
          [placeholder]="this.formGroup.enabled ? this.locationTranslation : ''"
          data-test="email-delivery-recipient-location"
      /></ion-col>
      <ion-col size="4" style="padding-top: 2px">
        <input
          type="name"
          class="sis-global-input sis-global-text-input"
          [ngClass]="{ 'sis-global-input-error': this.formGroup.controls.email.errors }"
          formControlName="email"
          maxlength="200"
          size="30"
          placeholder="{{ 'general.term.email' | translate }}"
          data-test="email-delivery-recipient-email"
      /></ion-col>
      <ion-col></ion-col>
      <ion-col
        *ngIf="this.controlsEnabled && this.formGroup.disabled && !this.showSpinner"
        size="auto"
        class="sis-icon-col"
        style="padding-top: 2px"
      >
        <ion-icon
          name="create-outline"
          class="sis-global-pointer-cursor sis-hover-icon"
          data-test="email-delivery-recipient-edit-btn"
          (click)="this.edit()"
          matTooltip="{{ 'general.term.edit' | translate }}"
        >
        </ion-icon>

        <ion-icon
          name="trash-outline"
          class="sis-global-pointer-cursor sis-hover-icon"
          data-test="email-delivery-recipient-delete-btn"
          (click)="this.deleteRecipient()"
          matTooltip="{{ 'general.term.delete' | translate }}"
        >
        </ion-icon>
      </ion-col>
      <ion-col
        *ngIf="this.formGroup.enabled && !this.showSpinner"
        size="auto"
        class="sis-icon-col"
        style="padding-top: 2px"
      >
        <ion-icon
          name="checkmark-outline"
          class="sis-icon"
          data-test="email-delivery-recipient-save-btn"
          (click)="this.upsertRecipient()"
          matTooltip="{{ 'general.term.save' | translate }}"
          [ngStyle]="{
            opacity: !this.edited || this.formGroup.invalid ? 0.5 : 1,
            cursor: !this.edited || this.formGroup.invalid ? 'default' : 'pointer'
          }"
        >
        </ion-icon>
        <ion-icon
          name="close-outline"
          class="sis-global-pointer-cursor sis-icon"
          data-test="email-delivery-recipient-cancel-btn"
          (click)="this.cancelRecipientEdit()"
          matTooltip="{{ 'general.term.cancel' | translate }}"
        >
        </ion-icon>
      </ion-col>
      <ion-col *ngIf="this.showSpinner" size="auto" class="sis-icon-col" style="padding-top: 2px">
        <ion-spinner name="dots" class="sis-icon" style="height: 22px"></ion-spinner>
      </ion-col>
    </ion-row>
  </form>
</div>

<!-- SmallScreen -->
<div *ngIf="!this.bigScreenMode" class="sis-recipient-item-small">
  <form [formGroup]="this.formGroup">
    <ion-row class="sis-ion-row-small vertical-align-content">
      <ion-col size="auto" class="sis-label-small"> {{ 'general.term.name' | translate }}: </ion-col>
      <ion-col>
        <input
          type="name"
          class="sis-modal-input sis-modal-text-input"
          [disabled]="!this.formGroup.enabled"
          [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls.name.errors }"
          formControlName="name"
          maxlength="100"
          placeholder="{{ 'general.term.name' | translate }}"
        />
      </ion-col>
    </ion-row>
    <ion-row class="sis-ion-row-small vertical-align-content">
      <ion-col size="auto" class="sis-label-small"> {{ 'general.term.email' | translate }}: </ion-col>
      <ion-col>
        <input
          type="name"
          class="sis-modal-input sis-modal-text-input"
          [disabled]="!this.formGroup.enabled"
          [ngClass]="{ 'sis-modal-input-error': this.formGroup.controls.email.errors }"
          formControlName="email"
          maxlength="200"
          size="30"
          placeholder="{{ 'general.term.email' | translate }}"
        />
      </ion-col>
    </ion-row>
    <ion-row class="sis-ion-row-small vertical-align-content">
      <ion-col size="auto" class="sis-label-small"> {{ 'destination.term.location' | translate }}: </ion-col>
      <ion-col>
        <input
          type="name"
          class="sis-modal-input sis-modal-text-input"
          [disabled]="!this.formGroup.enabled"
          formControlName="location"
          maxlength="50"
          [placeholder]="this.formGroup.enabled ? this.locationTranslation : ''"
        />
      </ion-col>
      <ion-col
        *ngIf="this.controlsEnabled && this.formGroup.disabled && !this.showSpinner"
        size="auto"
        class="sis-icon-col"
      >
        <ion-icon
          name="create-outline"
          class="sis-global-pointer-cursor sis-hover-icon"
          data-test="email-delivery-recipient-edit-btn"
          (click)="this.edit(); $event.stopPropagation()"
          matTooltip="{{ 'general.term.edit' | translate }}"
        >
        </ion-icon>
        <ion-icon
          name="trash-outline"
          class="sis-global-pointer-cursor sis-hover-icon"
          data-test="email-delivery-recipient-delete-btn"
          (click)="this.deleteRecipient(); $event.stopPropagation()"
          matTooltip="{{ 'general.term.delete' | translate }}"
        >
        </ion-icon>
      </ion-col>
      <ion-col *ngIf="this.formGroup.enabled && !this.showSpinner" size="auto" class="sis-icon-col">
        <ion-icon
          name="checkmark-outline"
          class="sis-icon"
          data-test="email-delivery-recipient-save-btn"
          (click)="this.upsertRecipient(); $event.stopPropagation()"
          matTooltip="{{ 'general.term.save' | translate }}"
          [ngStyle]="{
            opacity: !this.edited || this.formGroup.invalid ? 0.5 : 1,
            cursor: !this.edited || this.formGroup.invalid ? 'default' : 'pointer'
          }"
        >
        </ion-icon>
        <ion-icon
          name="close-outline"
          class="sis-global-pointer-cursor sis-icon"
          data-test="email-delivery-recipient-cancel-btn"
          (click)="this.cancelRecipientEdit(); $event.stopPropagation()"
          matTooltip="{{ 'general.term.cancel' | translate }}"
        >
        </ion-icon>
      </ion-col>
      <ion-col *ngIf="this.showSpinner" size="auto" class="sis-icon">
        <ion-spinner name="dots"></ion-spinner>
      </ion-col>
    </ion-row>
  </form>
</div>
