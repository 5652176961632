import { BreakdownInfoText } from 'src/app/breakdowninfo/domain/breakdowninfo-text.model';
import { WritablePart } from 'src/app/core/utils/type-helper';

export class BreakdownInfoActiveText {
  readonly languageMap = new Map<string, BreakdownInfoText>();

  breakdownInfoGuid: string;
  color: string;
  titleDe: string;
  titleEn: string;
  titleFr: string;
  titleIt: string;
  textDe: string;
  textEn: string;
  textFr: string;
  textIt: string;
  timestamp: string;
  isUpdating: boolean;

  constructor(init?: Partial<WritablePart<BreakdownInfoActiveText>>) {
    Object.assign(this, init);

    if (init.titleDe != null) {
      this.languageMap.set('de', { title: this.titleDe, text: this.textDe });
    }
    if (init.titleEn != null) {
      this.languageMap.set('en', { title: this.titleEn, text: this.textEn });
    }
    if (init.titleFr != null) {
      this.languageMap.set('fr', { title: this.titleFr, text: this.textFr });
    }
    if (init.titleIt != null) {
      this.languageMap.set('it', { title: this.titleIt, text: this.textIt });
    }
  }
}
