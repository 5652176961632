import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { DomController, Platform } from '@ionic/angular';
import { DrawerState } from 'src/app/bottom-drawer/drawer-state';

@Component({
  selector: 'sis-bottom-drawer',
  templateUrl: 'bottom-drawer.html',
  styleUrls: ['bottom-drawer.scss'],
})
export class SisBottomDrawerComponent implements AfterViewInit, AfterViewChecked, OnChanges {
  private readonly BOUNCE_DELTA = 30;
  private readonly TITLE_BAR_HEIGHT = 48;

  @Input() dockedHeight = 50;
  @Input() shouldBounce = true;
  @Input() distanceTop = 0;
  @Input() transition = '0.25s ease-in-out';
  @Input() minimumHeight = 0;
  @Input() disableDrag: boolean;
  @Input() state: DrawerState;

  @Output() onDrawerStateChange: EventEmitter<DrawerState> = new EventEmitter<DrawerState>();

  private startPositionTop: number;
  private hammer: HammerManager;
  private innerScrollElement: any;

  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private domCtrl: DomController,
    private platform: Platform
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state?.currentValue != null) {
      this.setDrawerState(changes.state.currentValue);
    }

    const disableDrag = changes.disableDrag?.currentValue;
    if (disableDrag === true) {
      this.removePanEvents(this.hammer);
    }
    if (disableDrag === false) {
      this.addPanEvents(this.hammer);
    }
  }

  ngAfterViewChecked(): void {
    this.styleInnerScrollElementForSwipeActions();
  }

  ngAfterViewInit() {
    this.styleInnerScrollElementForSwipeActions();
    this.setDrawerState(this.state);

    this.hammer = new Hammer(this.elementRef.nativeElement);
    this.addPanEvents(this.hammer);
  }

  private styleInnerScrollElementForSwipeActions() {
    if (!this.innerScrollElement) {
      const shadowRoot = this.elementRef.nativeElement.querySelector(
        '.sis-bottom-drawer-scrollable-content'
      ).shadowRoot;
      if (!shadowRoot) {
        return;
      }
      this.innerScrollElement = shadowRoot.querySelector('.inner-scroll ');
      if (this.innerScrollElement) {
        this.renderer2.setStyle(
          this.innerScrollElement, // .scroll-content
          'touch-action',
          'none'
        );
      }
    }
  }

  private addPanEvents(hammer: HammerManager) {
    if (!hammer) {
      return;
    }
    hammer.get('pan').set({ enable: true, direction: Hammer.DIRECTION_VERTICAL });
    hammer.on('pan panstart panend', (ev: HammerInput) => {
      switch (ev.type) {
        case 'panstart':
          this.handlePanStart();
          break;
        case 'panend':
          this.handlePanEnd(ev);
          break;
        default:
          this.handlePan(ev);
      }
    });
  }

  private removePanEvents(hammer: HammerManager) {
    hammer.get('pan').set({ enable: false, direction: Hammer.DIRECTION_VERTICAL });
    hammer.off('pan panstart panend');
  }

  private setDrawerState(state: DrawerState) {
    this.renderer2.setStyle(this.elementRef.nativeElement, 'transition', this.transition);
    switch (state) {
      case DrawerState.Bottom:
        this.setTranslateY('calc(100vh - ' + this.minimumHeight + 'px)');
        break;
      case DrawerState.Docked:
        this.setTranslateY(this.platform.height() - this.dockedHeight + 'px');
        break;
      default:
        this.setTranslateY(this.distanceTop + 'px');
    }
  }

  private handlePanStart() {
    this.startPositionTop = this.elementRef.nativeElement.getBoundingClientRect().top;
  }

  private handlePanEnd(ev: HammerInput) {
    if (this.shouldBounce && ev.isFinal) {
      this.renderer2.setStyle(this.elementRef.nativeElement, 'transition', this.transition);

      switch (this.state) {
        case DrawerState.Docked:
          this.handleDockedPanEnd(ev);
          break;
        case DrawerState.Top:
          this.handleTopPanEnd(ev);
          break;
        default:
          this.handleBottomPanEnd(ev);
      }
    }
    this.onDrawerStateChange.emit(this.state);
  }

  private handleTopPanEnd(ev: HammerInput) {
    if (ev.deltaY > this.BOUNCE_DELTA) {
      this.state = DrawerState.Docked;
    } else {
      this.setTranslateY(this.distanceTop + 'px');
    }
  }

  private handleDockedPanEnd(ev: HammerInput) {
    const absDeltaY = Math.abs(ev.deltaY);
    if (absDeltaY > this.BOUNCE_DELTA && ev.deltaY < 0) {
      this.state = DrawerState.Top;
    } else if (absDeltaY > this.BOUNCE_DELTA && ev.deltaY > 0) {
      this.state = DrawerState.Bottom;
    } else {
      this.setTranslateY('calc(100vh - ' + this.dockedHeight + 'px');
    }
  }

  private handleBottomPanEnd(ev: HammerInput) {
    if (-ev.deltaY > this.BOUNCE_DELTA) {
      this.state = DrawerState.Docked;
    } else {
      this.setTranslateY('calc(100vh - ' + this.minimumHeight + 'px)');
    }
  }

  private handlePan(ev: any) {
    const pointerY = ev.center.y;
    const actualHeight = this.platform.height();
    this.renderer2.setStyle(this.elementRef.nativeElement, 'transition', 'none');
    if (pointerY > 0 && pointerY < actualHeight) {
      if (ev.additionalEvent === 'panup' || ev.additionalEvent === 'pandown') {
        const newTop = this.startPositionTop + ev.deltaY - this.TITLE_BAR_HEIGHT;

        if (newTop > actualHeight - this.minimumHeight) {
          this.setTranslateY(actualHeight - this.minimumHeight + 'px');
        } else {
          if (newTop >= this.distanceTop) {
            this.setTranslateY(newTop + 'px');
          } else if (newTop < this.distanceTop) {
            this.setTranslateY(this.distanceTop + 'px');
          }
        }
      }
    }
  }

  private setTranslateY(value: string) {
    this.domCtrl.write(() => {
      this.renderer2.setStyle(this.elementRef.nativeElement, 'transform', 'translateY(' + value + ')');
    });
  }
}
