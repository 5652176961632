import { FeatureManagementFeature } from 'src/app/feature-management/domain/feature.model';

export class FeatureAdapter {
  static adapt(item: any): FeatureManagementFeature[] {
    const features: FeatureManagementFeature[] = [];

    if (!item) {
      return features;
    }

    item.forEach((item) => {
      features.push({
        id: item.id,
        order: item.order,
        active: item.active,
        adminOnly: item.adminOnly,
      });
    });

    return features;
  }
}
