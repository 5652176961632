import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { RopewayStatusTimeAdapter } from 'src/app/maps/livedata/ropeway-status-time.adapter';
import { RopewayStatusTime } from 'src/app/maps/livedata/ropeway-status-time.model';

@Injectable({
  providedIn: 'root',
})
export class RopewayStatusTimeService extends LivedataServiceBase<RopewayStatusTime> {
  protected readonly endpointUri = 'livedata/ropewaystatustime';
  protected readonly requiredFeatures = [new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ)];

  readonly ropewayStatusTimes$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, RopewayStatusTimeAdapter.adapt, destinationService);
  }
}
