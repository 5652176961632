import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';
import { Alarm } from 'src/app/domain/alarm.model';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { EfaHistoryAdapter } from 'src/app/maps/widgets/detailviews/efa-detailview/domain/efa-history.adapter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RopewayEfaHistoryService {
  private readonly url = environment.baseUrlApi + '/ropewayefahistory';

  readonly ropewayEfaHistory$: Observable<Alarm[]> = this.selectedMapElementService.selectedRopeway$.pipe(
    switchMap((ropeway) => {
      if (!ropeway) {
        return of([]);
      }

      return timer(0, 10000).pipe(
        switchMap(() =>
          this.http
            .get(`${this.url}/${ropeway.sisId}`)
            .pipe(
              map((data: any) =>
                EfaHistoryAdapter.adapt(data).sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
              )
            )
        )
      );
    }),
    share()
  );

  constructor(private http: HttpClient, private selectedMapElementService: SelectedMapElementService) {}
}
