import { AlarmLibraryItem } from 'src/app/maps/alarmlibrary/domain/alarmlibrary-item.model';

export class AlarmLibraryItemAdapter {
  static adapt(item: any): AlarmLibraryItem[] {
    const alarms: AlarmLibraryItem[] = [];

    if (item) {
      item.forEach((a: any) => {
        alarms.push(
          new AlarmLibraryItem({
            sisId: a.sisId.toUpperCase(),
            code: a.code,
            message: a.message,
            level: a.level,
            defaultLevel: a.defaultLevel,
            info: a.info,
          })
        );
      });
    }
    return alarms;
  }
}
