import { DateTimeFormatPipe } from 'src/app/core/utils/date-time-format.pipe';

export class StoptimeDetailViewItem {
  private readonly hoursUnit = 'h';
  private readonly minutesUnit = 'min';
  private readonly secondsUnit = 's';

  readonly displayedBeginTime: string;
  readonly displayedEndTime: string;
  readonly displayedCode: string;

  displayedTime: string;
  displayedUnit: string;

  constructor(
    private offsetSpaToApiMilliseconds: number,
    private dateTimeFormatPipe: DateTimeFormatPipe,
    private beginTime: Date,
    private endTime: Date,
    private code: string,
    private level: string,
    public displayedMessage: string,
    public stopped: boolean
  ) {
    this.displayedBeginTime = this.dateTimeFormatPipe.transform(this.beginTime, false, false);
    this.displayedEndTime = this.dateTimeFormatPipe.transform(this.endTime, false, false);
    this.displayedCode = this.code;
    if (this.level && this.level != 'fault') {
      this.displayedCode += ` (${this.level[0].toUpperCase()})`;
    }

    this.updateDisplayValues();
  }

  updateDisplayValues(): void {
    if (!this.beginTime || !this.offsetSpaToApiMilliseconds) {
      return;
    }

    const startTimeMs = this.beginTime.getTime();

    const end = this.endTime ? this.endTime.getTime() : Date.now() - this.offsetSpaToApiMilliseconds;
    const milliseconds = end - startTimeMs;
    const seconds = milliseconds / 1000;

    const hours = Math.floor(seconds / 3600);
    if (hours > 0) {
      this.displayedTime = hours.toString();
      this.displayedUnit = this.hoursUnit;
      return;
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes > 0) {
      this.displayedTime = minutes.toString();
      this.displayedUnit = this.minutesUnit;
      return;
    }

    this.displayedTime = Math.floor(seconds).toString();
    this.displayedUnit = this.secondsUnit;
  }
}
