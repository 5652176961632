<div class="sis-contextmenu" [ngStyle]="{ 'left.px': x, 'top.px': y }" (mouseleave)="close()">
  <ion-list>
    <ion-item
      class="sis-contextmenu-item"
      *ngFor="let ropeway of this.selectableRopeways"
      (click)="this.selectRopeway(ropeway); $event.stopPropagation()"
      lines="full"
    >
      {{ ropeway.alias }}
    </ion-item>
  </ion-list>
</div>
