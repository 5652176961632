<div class="sis-title">{{ 'general.term.ropewayAvailability' | translate }}</div>
<ion-select
  class="sis-timespan-selector"
  interface="popover"
  [value]="this.timespanSelectors[0].value"
  (ionChange)="this.selectTimespan($event.detail.value)"
>
  <ion-select-option *ngFor="let timespan of this.timespanSelectors" [value]="timespan.value">{{
    timespan.translationString | translate
  }}</ion-select-option>
</ion-select>

<div class="sis-main-container">
  <div *ngIf="this.loadingData" class="sis-info">{{ 'analytics.phrase.dataLoading' | translate }}</div>
  <div *ngIf="this.noData" class="sis-info">{{ 'analytics.phrase.dataNone' | translate }}</div>
  <div class="sis-chart-container">
    <div class="sis-y-axis-container">
      <div [style.display]="this.meanValue * 100 < 95 ? 'block' : 'none'" class="sis-y-item">100%</div>
      <div class="sis-y-item"></div>
      <div [style.display]="this.meanValue * 100 > 3 ? 'block' : 'none'" class="sis-y-item">0%</div>
    </div>

    <div class="sis-chart">
      <div class="sis-chart-bar-container">
        <div
          *ngFor="let trend of this.trends"
          [style.height]="trend.barHeightPercent"
          [matTooltip]="trend.barTooltip"
          matTooltipPosition="above"
          class="sis-chart-bar"
          [ngClass]="{ 'sis-ignored': trend.ignore, 'sis-no-data': trend.availability === null }"
          [style.width]="this.chartBarWidth + 'px'"
          (contextmenu)="trend.ignorable && this.openIgnoreDialog(trend)"
          oncontextmenu="return false"
        ></div>
        <div
          *ngIf="this.meanValue && !this.loadingData && !this.noData"
          class="sis-mean-line"
          [style.bottom]="this.meanValuePercent"
        >
          <div class="sis-mean-value">{{ this.meanValuePercent }}</div>
        </div>
      </div>
      <div class="sis-x-axis-container">
        <span
          *ngFor="let trend of this.trends"
          [matTooltip]="trend.dateTooltip"
          class="sis-date-item"
          [style.width]="this.chartBarWidth + 'px'"
          >{{ trend.shownDate }}
        </span>
      </div>
    </div>
  </div>
</div>
