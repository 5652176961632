import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { Configuration } from 'src/app/maps/domain/configuration.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayTorqueTrend } from 'src/app/maps/trenddata/ropeway-torque-trend.model';
import { RopewayTorqueTrendService } from 'src/app/maps/trenddata/ropeway-torque-trend.service';
import { ChartAdapter } from 'src/app/maps/widgets/charts/chart-adapter';
import { ChartSettingsService } from 'src/app/maps/widgets/charts/chart-settings.service';
import { ChartSelector } from 'src/app/maps/widgets/charts/ropeway-chart/chart-selector.model';
import { RopewayChartHandler } from 'src/app/maps/widgets/charts/ropeway-chart/ropeway-chart-handler';

export class TorqueHandler extends RopewayChartHandler {
  static readonly torqueTranslateString = 'ropeway.term.torque';
  static readonly motorCurrentTranslateString = 'ropeway.term.motorcurrent';
  static readonly trendColor = '#009f10ff';

  private seriesName: string;
  private torqueTrends: RopewayTorqueTrend[];

  constructor(
    protected chartSettingsService: ChartSettingsService,
    protected chartAdapter: ChartAdapter,
    private ropewayTorqueTrendService: RopewayTorqueTrendService,
    translateService: TranslateService,
    ropeway: Ropeway,
    yAxisUnits: string[]
  ) {
    super(chartSettingsService, chartAdapter, translateService, ropeway, yAxisUnits);

    this.seriesId = ropeway.sisId + 'torque';
    const translateString = this.ropeway.configurations.some((c) => c.configKey === Configuration.driveTorqueNmKey)
      ? TorqueHandler.torqueTranslateString
      : TorqueHandler.motorCurrentTranslateString;
    this.seriesName = translateService.instant(translateString);
  }

  seriesObservable(): Observable<Array<{ seriesId: string; data: number[][] }>> {
    return this.chartSettingsService.getRopewaySetting(this.ropeway.sisId, this.seriesId).pipe(
      switchMap((enabled) => {
        if (enabled) {
          const self = this;
          this.chartAdapter.addSeries(this.seriesId, {
            name: this.seriesName,
            id: this.seriesId,
            type: 'spline' as any,
            lineWidth: 1,
            className: '',
            tooltip: {
              pointFormatter() {
                const data = self.torqueTrends.find((d) => d.timestamp.getTime() === this.x);
                const yText =
                  data.torqueAbsolute != null
                    ? `<b>${data.torqueAbsolute} ${data.unit}</b> (${Math.round(this.y)}%)`
                    : `<b>${Math.round(this.y)} ${data.unit}</b>`;
                return `<span style="color:${this.color}">\u25CF</span> ${self.seriesName}: ${yText}`;
              },
            },
            color: TorqueHandler.trendColor,
            yAxis: this.yAxisUnits.indexOf('%'),
          });

          return this.chartAdapter.loadData([this.ropewayTorqueTrendService.getTorqueData(this.ropeway)]).pipe(
            map((data) => {
              this.torqueTrends = data[0];
              return [{ seriesId: this.seriesId, data: this.getData(data[0]) }];
            })
          );
        } else {
          this.chartAdapter.removeSeries(this.seriesId);
          return of([{ seriesId: this.seriesId, data: null }]);
        }
      })
    );
  }

  getChartSelectors(): ChartSelector[] {
    return [
      {
        setting: this.seriesId,
        titleStrings: this.ropeway.configurations.some((c) => c.configKey === Configuration.driveTorqueNmKey)
          ? [TorqueHandler.torqueTranslateString]
          : [TorqueHandler.motorCurrentTranslateString],
        ropewaySisId: this.ropeway.sisId,
        selected$: this.chartSettingsService.getRopewaySetting(this.ropeway.sisId, this.seriesId),
      },
    ];
  }

  private getData(trends: RopewayTorqueTrend[]): number[][] {
    return (
      trends
        ?.map((item) => {
          const timeStampInMs = item.timestamp.getTime();
          return [timeStampInMs, item.torquePercent];
        })
        .sort((i1, i2) => i1[0] - i2[0]) ?? []
    );
  }
}
