import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { NmsItemComponent } from 'src/app/it-services/nms-item/nms-item.component';
import { SisItServicesPage } from 'src/app/it-services/sis-it-services.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CoreModule,
    RouterModule.forChild([
      {
        path: '',
        component: SisItServicesPage
      }
    ]),
    TranslateModule
  ],
  declarations: [SisItServicesPage, NmsItemComponent]
})
export class SisItServicesPageModule {}
