import { BehaviorSubject, Observable } from 'rxjs';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { Export } from 'src/app/domain/export/export.model';
import { SisMediaAssetCategory } from 'src/app/sismedia/domain/sismedia-asset-category.enum';
import { SisMediaAssetImportType } from 'src/app/sismedia/domain/sismedia-asset-import-type.enum';
import { SisMediaAssetStatus } from 'src/app/sismedia/domain/sismedia-asset-status.enum';
import { SisMediaAssetSubStatus } from 'src/app/sismedia/domain/sismedia-asset-substatus.enum';
import { SisMediaItemIconOptions } from 'src/app/sismedia/domain/sismedia-item-icon-options.model';
import { SisMediaSeason } from 'src/app/sismedia/domain/sismedia-season.enum';
import { SisMediaSisMapInfo } from 'src/app/sismedia/domain/sismedia-sismap-info.model';

export class SisMediaItem {
  static readonly Status = 'status';
  static readonly SubStatus = 'subStatus';
  static readonly AssetEdit = 'assetEdit';
  static readonly OperatingHours = 'operatingHours';
  static readonly ExportMappings = 'exportMappings';
  static readonly settings: string[] = [
    SisMediaItem.SubStatus,
    SisMediaItem.Status,
    SisMediaItem.AssetEdit,
    SisMediaItem.OperatingHours,
    SisMediaItem.ExportMappings,
  ];

  private updating$: Map<string, BehaviorSubject<boolean>> = new Map<string, BehaviorSubject<boolean>>();

  readonly iconOptions: SisMediaItemIconOptions[] = [
    {
      sisMediaStatus: SisMediaAssetStatus.Disabled,
      iconName: 'sis-status-disabled',
      colorDisabledClassName: 'sis-disabled-black',
      colorEnabledClassName: 'sis-black',
      tooltipTranslationString: 'sismedia.term.disabled',
      dataTestString: 'sismedia-state-disabled',
      dataTestOverrideString: 'sismedia-asset-override-state-disabled',
    },
    {
      sisMediaStatus: SisMediaAssetStatus.Closed,
      iconName: 'sis-status-closed',
      colorDisabledClassName: 'sis-disabled-red',
      colorEnabledClassName: 'sis-red',
      tooltipTranslationString: 'sismedia.term.closed',
      dataTestString: 'sismedia-state-closed',
      dataTestOverrideString: 'sismedia-asset-override-state-closed',
    },
    {
      sisMediaStatus: SisMediaAssetStatus.Preparation,
      iconName: 'sis-status-preparation',
      colorDisabledClassName: 'sis-disabled-yellow',
      colorEnabledClassName: 'sis-yellow',
      tooltipTranslationString: 'sismedia.term.preparation',
      dataTestString: 'sismedia-state-preparation',
      dataTestOverrideString: 'sismedia-asset-override-state-preparation',
    },
    {
      sisMediaStatus: SisMediaAssetStatus.Open,
      iconName: 'sis-status-open',
      colorDisabledClassName: 'sis-disabled-green',
      colorEnabledClassName: 'sis-green',
      tooltipTranslationString: 'sismedia.term.open',
      dataTestString: 'sismedia-state-open',
      dataTestOverrideString: 'sismedia-asset-override-state-open',
    },
  ];

  assetGuid: string;
  id: number;
  name: string;
  nameEn: string;
  contact: string;
  location: string;
  phone: string;
  email: string;
  website: string;
  label: string;
  type: string;
  lengthMeter: number;
  info: string;
  custom: string;
  throughput: number;
  firstRide: string;
  lastRide: string;
  travelTime: number;
  elevation: number;
  duration: number;
  status?: SisMediaAssetStatus;
  subStatus?: SisMediaAssetSubStatus;
  activeStatus: SisMediaAssetStatus;
  hasActiveOverride: boolean;
  statusOverrideActive: boolean;
  lastStatusChange: Date;
  cockpitOrder: number;
  assetCategory: SisMediaAssetCategory;
  season: SisMediaSeason;
  operatingHoursEnabled: boolean;
  importType?: SisMediaAssetImportType;

  exports: Export[];
  sisMapNames: string[];

  sisMapInfo: SisMediaSisMapInfo[];

  constructor(init?: Partial<WritablePart<SisMediaItem>>) {
    Object.assign(this, init);

    SisMediaItem.settings.forEach((s) => {
      const updating = new BehaviorSubject<boolean>(false);
      this.updating$.set(s, updating);
    });

    if (this.assetCategory === SisMediaAssetCategory.Lift && this.type == 'gondelbahn' && this.subStatus != null) {
      this.iconOptions[3] = {
        sisMediaStatus: SisMediaAssetStatus.Open,
        iconName: 'sis-status-open',
        colorDisabledClassName: 'sis-disabled-green',
        colorEnabledClassName: 'sis-green',
        tooltipTranslationString: 'sismedia.term.open',
        dataTestString: 'sismedia-state-open',
        dataTestOverrideString: 'sismedia-asset-override-state-open',
        subStatusOptions: [
          {
            sisMediaSubStatus: SisMediaAssetSubStatus.Convoy,
            iconName: 'sis-status-convoy',
            tooltipTranslationString: 'sismedia.term.convoy',
          },
          {
            sisMediaSubStatus: SisMediaAssetSubStatus.Circulation,
            iconName: 'sis-status-circulation',
            tooltipTranslationString: 'sismedia.term.circulation',
          },
        ],
      };
    }

    if (this.assetCategory === SisMediaAssetCategory.Lift && this.subStatus != null) {
      this.iconOptions[1] = {
        sisMediaStatus: SisMediaAssetStatus.Closed,
        iconName: 'sis-status-closed',
        colorDisabledClassName: 'sis-disabled-red',
        colorEnabledClassName: 'sis-red',
        tooltipTranslationString: 'sismedia.term.closed',
        dataTestString: 'sismedia-state-closed',
        dataTestOverrideString: 'sismedia-asset-override-state-closed',
        subStatusOptions: [
          {
            sisMediaSubStatus: SisMediaAssetSubStatus.Revision,
            iconName: 'sis-status-revision',
            tooltipTranslationString: 'sismedia.term.revision',
          },
          {
            sisMediaSubStatus: SisMediaAssetSubStatus.Storm,
            iconName: 'sis-status-storm',
            tooltipTranslationString: 'sismedia.term.storm',
          },
          {
            sisMediaSubStatus: SisMediaAssetSubStatus.Malfunction,
            iconName: 'sis-status-malfunction',
            tooltipTranslationString: 'sismedia.term.malfunction',
          },
        ],
      };
    }
  }

  getUpdating(setting: string): Observable<boolean> {
    return this.updating$.get(setting).asObservable();
  }

  setUpdating(setting: string, value: boolean): void {
    this.updating$.get(setting).next(value);
  }
}
