<div data-test="media-center-playlist-item">
  <ion-row
    [ngClass]="this.playlistRowClass"
    (click)="!this.inputSelected && this.toggleExpanded.emit($event.target)"
    class="sis-global-hover"
  >
    <ion-col class="sis-padding-left">
      <div class="sis-playlist-input-container">
        <input
          type="name"
          class="sis-global-input sis-global-text-input"
          maxlength="50"
          size="50"
          (click)="this.editPlaylistName($event)"
          (focus)="this.inputSelected = true"
          (blur)="this.inputSelected = false"
          [(ngModel)]="this.playlistEditCopy.name"
          (ngModelChange)="this.playlistNameChanged($event)"
          [disabled]="!this.expanded"
          [ngClass]="{
            'sis-global-input-error': this.invalidName,
            'sis-playlist-input-ignore-pointer': !this.expanded
          }"
          placeholder="{{ 'general.term.name' | translate }}"
          data-test="sis-playlist-name-input"
        />
        <div
          *ngIf="this.invalidName"
          matTooltip="{{
            (this.playlistEditCopy.name.length === 0
              ? 'mediacenter.phrase.nameRequired'
              : 'mediacenter.phrase.duplicateName'
            ) | translate
          }}"
          data-test="playlist-invalid-name"
        >
          <ion-icon name="warning" class="sis-playlist-invalid-name-icon"></ion-icon>
        </div>
      </div>
      <div *ngIf="this.expanded" class="sis-playlist-fadein-div" (click)="this.stopPropagation($event)">
        <span class="sis-playlist-fadein-span">{{ 'mediacenter.phrase.slideTransitionEnabled' | translate }}</span>
        <ion-checkbox
          [(ngModel)]="this.playlistEditCopy.fadeInEnabled"
          class="sis-playlist-fadein-checkbox"
          (click)="this.stopPropagation($event)"
          (ngModelChange)="this.setEdited(true)"
          color="secondary"
          data-test="playlist-fadein-checkbox"
        >
        </ion-checkbox>
        <span *ngIf="this.playlistEditCopy.fadeInEnabled" class="sis-playlist-fadein-span">{{
          'mediacenter.term.backgroundColor' | translate
        }}</span>
        <input
          *ngIf="this.playlistEditCopy.fadeInEnabled"
          type="color"
          [(ngModel)]="this.playlistEditCopy.backgroundColor"
          (click)="this.stopPropagation($event)"
          (ngModelChange)="this.setEdited(true)"
          style="cursor: pointer"
          data-test="playlist-backgroundcolor-input"
        />
      </div>
    </ion-col>
    <ion-col class="sis-global-center" size="1" *ngIf="this.bigScreenMode">
      <a
        class="sis-mediacenter-clickable"
        (click)="this.openSlideShowPreview(); $event.stopPropagation()"
        matTooltip="{{ 'mediacenter.phrase.openSlideShowPreview' | translate }}"
      >
        <ion-icon class="sis-mediacenter-icon" name="open-outline"> </ion-icon>
      </a>
    </ion-col>
    <ion-col
      size="2"
      class="sis-global-center"
      [ngStyle]="{ opacity: this.expanded ? 1 : this.otherExpanded ? 0.5 : 1 }"
    >
      {{ playlistEditCopy.slideMappings.length }}
    </ion-col>
    <ion-col size="1" class="sis-global-center">
      <a
        *ngIf="!playlist.updating"
        (click)="this.writePermission && deletePlaylist($event, playlist)"
        [ngClass]="
          this.writePermission && !this.playlist.isNew
            ? 'sis-mediacenter-clickable'
            : 'sis-mediacenter-clickable-no-permission'
        "
        matTooltip="{{ 'general.term.delete' | translate }}"
        data-test="playlist-delete-button"
      >
        <ion-icon class="sis-mediacenter-icon" name="trash-outline"></ion-icon>
      </a>
      <ion-spinner name="bubbles" *ngIf="playlist.updating"></ion-spinner>
    </ion-col>
    <ion-col size="0.5" class="sis-global-center">
      <ion-icon
        class="sis-mediacenter-clickable sis-playlist-expandable-icon"
        [name]="this.expanded ? 'chevron-up-outline' : 'chevron-down-outline'"
      ></ion-icon>
    </ion-col>
  </ion-row>

  <ion-row
    class="sis-playlist-bottom-border sis-playlist-highlight-background sis-playlist-expanded-container"
    style="pointer-events: none"
    *ngIf="this.expanded"
    data-test="playlist-expanded-container"
  >
    <ion-col>
      <ion-grid>
        <ion-row class="sis-playlist-slide-header">
          <ion-col size="2.7">{{ 'mediacenter.phrase.availableSlides' | translate }}</ion-col>
          <ion-col size="0.5">
            <ion-spinner *ngIf="this.searching"></ion-spinner>
          </ion-col>
          <ion-col size="2.7" style="pointer-events: auto">
            <ion-searchbar
              placeholder="{{ 'general.term.filter' | translate }}"
              type="text"
              [formControl]="searchControl"
              (ionChange)="this.searching = true"
              style="--background: white"
            >
            </ion-searchbar>
          </ion-col>
          <ion-col size="0.2"></ion-col>
          <ion-col size="5.9">{{ 'mediacenter.phrase.slideOnPlaylist' | translate }}</ion-col>
        </ion-row>
        <ion-row style="pointer-events: auto">
          <ion-col size="5.9">
            <ion-content sis-ion-content-scrollbar class="sis-playlist-slide-container">
              <ion-grid style="height: 100%">
                <cdk-virtual-scroll-viewport itemSize="55" minBufferPx="550" maxBufferPx="750">
                  <ion-row
                    *cdkVirtualFor="let slide of slidesFiltered; trackBy: this.itemId"
                    class="sis-playlist-slide-library-item"
                  >
                    <ion-col size="2" *ngIf="this.bigScreenMode"
                      ><a
                        style="color: inherit; text-decoration: none; outline: none"
                        target="_blank"
                        rel="noopener noreferrer"
                        [href]="slide.url"
                      >
                        <img class="sis-playlist-thumbnail" [src]="slide.thumbnail" /></a
                    ></ion-col>
                    <ion-col
                      class="sis-global-text-overflow-ellipsis"
                      [ngStyle]="{ opacity: slide.enabled && !slide.disabledByTimeControl ? 1 : 0.5 }"
                      ><a
                        style="color: inherit; text-decoration: none"
                        target="_blank"
                        rel="noopener noreferrer"
                        [href]="slide.url"
                        >{{ slide.name }}</a
                      ></ion-col
                    >
                    <ion-col size="0.8"
                      ><span *ngIf="!slide.enabled" matTooltip="{{ 'mediacenter.phrase.slideNotEnabled' | translate }}"
                        ><ion-icon
                          name="warning"
                          style="color: gray; padding-top: 4px; pointer-events: none"
                        ></ion-icon></span
                      ><span
                        *ngIf="slide.disabledByTimeControl"
                        matTooltip="{{ 'mediacenter.phrase.timeControlInfluenced' | translate }}"
                        ><ion-icon
                          name="time-outline"
                          style="color: gray; padding-top: 4px; pointer-events: none"
                          data-test="media-center-time-control-icon"
                        ></ion-icon></span
                    ></ion-col>

                    <ion-col class="sis-global-text-overflow-ellipsis" size="1.5">
                      {{ slide.fileType }}
                    </ion-col>
                    <ion-col size="1"> {{ slide.duration }}s </ion-col>
                    <ion-col size="0.7" class="sis-global-center" style="min-width: 50px">
                      <a
                        [ngClass]="
                          this.writePermission ? 'sis-mediacenter-clickable' : 'sis-mediacenter-clickable-no-permission'
                        "
                        matTooltip="{{ 'mediacenter.phrase.addSlideToPlaylist' | translate }}"
                        (click)="this.writePermission && this.addSlideToPlaylist(slide)"
                        data-test="add-slide-button"
                      >
                        <ion-icon
                          class="sis-mediacenter-icon"
                          name="add-circle-outline"
                          style="font-size: 1.2em"
                        ></ion-icon>
                      </a>
                    </ion-col>
                  </ion-row>
                </cdk-virtual-scroll-viewport>
              </ion-grid>
            </ion-content>
          </ion-col>
          <ion-col size="0.2"></ion-col>
          <ion-col size="5.9">
            <div class="sis-playlist-slide-container" #playlistScrollElement>
              <ion-reorder-group (ionItemReorder)="doReorder($event)" [disabled]="!this.writePermission">
                <ion-item
                  class="sis-playlist-slide-reorder-item"
                  *ngFor="let slideMapping of this.playlistEditCopy.slideMappings"
                >
                  <ion-grid>
                    <ion-row style="align-items: center">
                      <ion-col size="2" [ngStyle]="{ opacity: slideMapping.slide.enabled ? 1 : 0.5 }"
                        ><a
                          style="color: inherit; text-decoration: none; outline: none"
                          target="_blank"
                          rel="noopener noreferrer"
                          [href]="slideMapping.slide.url"
                        >
                          <img class="sis-playlist-thumbnail" [src]="slideMapping.slide.thumbnail"
                        /></a>
                      </ion-col>
                      <ion-col
                        class="sis-global-text-overflow-ellipsis"
                        [ngStyle]="{
                          opacity: slideMapping.slide.enabled && !slideMapping.slide.disabledByTimeControl ? 1 : 0.5
                        }"
                        ><a
                          style="color: inherit; text-decoration: none"
                          target="_blank"
                          rel="noopener noreferrer"
                          [href]="slideMapping.slide.url"
                          >{{ slideMapping.slide.name }}</a
                        ></ion-col
                      >
                      <ion-col size="1"
                        ><span
                          *ngIf="!slideMapping.slide.enabled"
                          matTooltip="{{ 'mediacenter.phrase.slideNotEnabled' | translate }}"
                          ><ion-icon
                            name="warning"
                            style="color: gray; pointer-events: none; padding-top: 4px"
                          ></ion-icon></span
                        ><span
                          *ngIf="slideMapping.slide.disabledByTimeControl"
                          matTooltip="{{ 'mediacenter.phrase.timeControlInfluenced' | translate }}"
                          ><ion-icon
                            name="time-outline"
                            style="color: gray; padding-top: 4px; pointer-events: none"
                            data-test="media-center-time-control-icon"
                          ></ion-icon></span
                      ></ion-col>
                      <ion-col size="2" style="min-width: 70px">
                        <input
                          type="name"
                          maxlength="3"
                          size="3"
                          class="sis-global-input sis-global-text-inpput sis-playlist-slide-duration"
                          matTooltip="{{ 'mediacenter.phrase.slideDurationPlaylist' | translate }}"
                          [value]="slideMapping.duration"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                          (keyup)="durationInput($event, slideMapping)"
                          [disabled]="!this.writePermission"
                        />
                        s
                      </ion-col>
                      <ion-col size="0.5" class="sis-global-center" style="min-width: 40px">
                        <a
                          [ngClass]="
                            this.writePermission
                              ? 'sis-mediacenter-clickable'
                              : 'sis-mediacenter-clickable-no-permission'
                          "
                          matTooltip="{{ 'mediacenter.phrase.removeSlideFromPlaylist' | translate }}"
                          (click)="this.writePermission && this.removeSlideFromPlaylist(slideMapping)"
                          data-test="remove-slide-button"
                        >
                          <ion-icon class="sis-mediacenter-icon" name="trash-outline"></ion-icon>
                        </a>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                  <ion-reorder slot="end" *ngIf="this.writePermission">
                    <ion-icon name="reorder-two" class="sis-playlist-reorder-handle"></ion-icon>
                  </ion-reorder>
                </ion-item>
              </ion-reorder-group>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="sis-playlist-button-row">
          <ion-col>
            <div style="float: right">
              <sis-general-button
                [text]="'general.term.save'"
                [btnType]="'Secondary'"
                (sisClick)="this.writePermission && savePlaylist()"
                [disabled]="!this.writePermission || !this.playlist.edited || this.invalidName"
                data-test="playlist-save-button"
              ></sis-general-button>
              <ion-col> </ion-col>
              <sis-general-button
                [text]="'general.term.cancel'"
                [btnType]="'Secondary'"
                (sisClick)="this.cancel()"
                [disabled]="!this.playlist.edited"
              ></sis-general-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</div>
