import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { DestinationService } from 'src/app/domain/destination/destination.service';

@Injectable()
export class HttpCurrentTenantInterceptor implements HttpInterceptor {
  private readonly TenantGuidHeaderKey: string = 'CurrentTenantGuid';

  constructor(private destinationService: DestinationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.destinationService.selectedTenant$.pipe(
      take(1),
      switchMap((tenant) => {
        if (tenant?.guid && !request.headers.has(this.TenantGuidHeaderKey)) {
          request = request.clone({ headers: request.headers.set(this.TenantGuidHeaderKey, tenant.guid.toString()) });
        }

        return next.handle(request);
      })
    );
  }
}
