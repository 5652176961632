import { LedTickerItem } from 'src/app/led-ticker/domain/led-ticker-item.model';

export class LedTickerItemAdapter {
  static adapt(item: any): LedTickerItem | null {
    if (!item) {
      return null;
    }

    return {
      active: item.active,
      alignment: item.alignment,
      duration: item.duration,
      ledTickerGuid: item.ledTickerGuid,
      ledTickerPageGuid: item.ledTickerPageGuid,
      ledTickerTextGuid: item.ledTickerTextGuid,
      pageNr: item.pageNr,
      rowNr: item.rowNr,
      text: item.text,
    };
  }
}
