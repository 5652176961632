import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayEcoModeTrend } from 'src/app/maps/trenddata/ropeway-ecomode-trend.model';
import { RopewayEcoModeTrendService } from 'src/app/maps/trenddata/ropeway-ecomode-trend.service';
import { ChartAdapter } from 'src/app/maps/widgets/charts/chart-adapter';
import { ChartSettingsService } from 'src/app/maps/widgets/charts/chart-settings.service';
import { ChartSelector } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/chart-selector.model';
import { EcoModeChartHandler } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-chart-handler';

export class EcoModeEnergyHandler extends EcoModeChartHandler {
  private static readonly speedTranslateString = 'ecomode.term.driveEnergy';
  private static readonly trendColor = '#20eacc';

  private seriesName: string;

  constructor(
    protected chartSettingsService: ChartSettingsService,
    protected chartAdapter: ChartAdapter,
    private ropewayEcoModeTrendService: RopewayEcoModeTrendService,
    translateService: TranslateService,
    ropeway: Ropeway,
    yAxisUnits: string[]
  ) {
    super(chartSettingsService, chartAdapter, translateService, ropeway, yAxisUnits);

    this.seriesId = ropeway.sisId + 'driveEnergyWh';
    this.seriesName = translateService.instant(EcoModeEnergyHandler.speedTranslateString);
  }

  seriesObservable(): Observable<Array<{ seriesId: string; data: number[][] }>> {
    return this.chartSettingsService.getRopewaySetting(this.ropeway.sisId, this.seriesId).pipe(
      switchMap((enabled) => {
        if (enabled) {
          this.chartAdapter.addSeries(this.seriesId, {
            name: this.seriesName,
            id: this.seriesId,
            type: 'area' as any,
            step: 'left',
            lineWidth: 1,
            className: '',
            tooltip: {
              valueSuffix: ' kW',
            },
            fillOpacity: 0.3,
            color: EcoModeEnergyHandler.trendColor,
            yAxis: this.yAxisUnits.indexOf('kw'),
          });

          return this.chartAdapter
            .loadData([this.ropewayEcoModeTrendService.getEcoModeData(this.ropeway.sisId)])
            .pipe(map((data) => [{ seriesId: this.seriesId, data: this.getData(data[0]) }]));
        } else {
          this.chartAdapter.removeSeries(this.seriesId);
          return of([{ seriesId: this.seriesId, data: null }]);
        }
      })
    );
  }

  getChartSelectors(): ChartSelector[] {
    return [
      {
        setting: this.seriesId,
        titleStrings: [EcoModeEnergyHandler.speedTranslateString],
        ropewaySisId: this.ropeway.sisId,
        selected$: this.chartSettingsService.getRopewaySetting(this.ropeway.sisId, this.seriesId),
      },
    ];
  }

  private getData(data: RopewayEcoModeTrend[]): number[][] {
    if (!data || data.length === 0) return [];
    const sortedData = data
      .filter((item) => item.timestamp && item.driveEnergyWh !== undefined)
      .map((item) => ({
        timestamp: item.timestamp?.getTime(),
        driveEnergyWh: item.driveEnergyWh,
      }))
      .sort((a, b) => a.timestamp - b.timestamp);

    const result: number[][] = [];
    for (let i = 1; i < sortedData.length; i++) {
      const previous = sortedData[i - 1];
      const current = sortedData[i];

      const timeDiffHours = (current.timestamp - previous.timestamp) / 3600000;

      const powerW = current.driveEnergyWh / timeDiffHours;
      const powerKw = powerW / 1000;
      const roundedPowerKw = Math.round(powerKw * 100) / 100;

      result.push([current.timestamp, roundedPowerKw]);
    }

    return result;
  }
}
