<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widget_Hintergrund_temp" data-name="Widget Hintergrund temp">
      <rect width="187.5" height="225" style="fill: none" />
    </g>
    <g id="Widgets">
      <g>
        <rect width="187.5" height="225" style="fill: none" />
        <rect width="187.5" height="225" style="fill: none" />
        <g id="g873">
          <circle style="fill: #91cddb" id="path822" cx="93.39" cy="131.2" r="18.03" />
          <rect style="fill: #91cddb" id="temperature" width="17.2" height="3" x="84.95" y="113" />
          <path
            d="M 103.6,29 A 10,10 0 0 0 83.6,29 V 115.4 A 18.75,18.75 0 1 0 103.6,115.4 V 115.4 Z"
            style="fill: none; stroke: #989898; stroke-width: 3px; stroke-miterlimit: 10"
            id="path9"
          />
          <rect
            style="fill: #91cddb"
            id="temperature"
            width="17.5"
            [attr.height]="this.innerBarHeight"
            x="84.92"
            [attr.y]="this.innerBarYValue"
          />
        </g>
      </g>
    </g>
    <g>
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
      >
        {{ this.temperatureCelsius }}
        <tspan id="unit" style="font-size: 24px">°C</tspan>
      </text>
    </g>
  </g>
</svg>
