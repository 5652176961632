import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { SisMediaSettingUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { SisMediaSettingAdapter } from 'src/app/sismedia-setting/sismedia-setting.adapter';
import { SisMediaSetting } from 'src/app/sismedia-setting/sismedia-setting.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SisMediaSettingService {
  private readonly baseRequestUrl: string = '/sismedia/settings';
  private readonly basePostUrl: string = '/sismedia/settings';

  private readonly sisMediaSettingRequest$: Observable<SisMediaSetting> =
    this.destinationService.selectedTenantFeatures$.pipe(
      distinctUntilChanged(),
      switchMap(() => this.http.get(`${environment.baseUrlApi}${this.baseRequestUrl}`)),
      map((data) => SisMediaSettingAdapter.adapt(data)),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

  private readonly sisMediaSettingUpdate$: Observable<SisMediaSetting> = this.eventBus
    .observe(SisMediaSettingUpdatedEvent)
    .pipe(
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([updatedEvent, tenant]) => updatedEvent?.sisMediaSetting.tenantGuid === tenant?.guid),
      withLatestFrom(this.sisMediaSettingRequest$, this.userSettingsService.userSettings$),
      map(([[updatedEvent], sisMediaSetting, userSettings]) => {
        if (updatedEvent.changedBy === userSettings.userGuid) {
          const translateKey = updatedEvent.updateSuccessful ? 'general.phrase.saved' : 'general.phrase.saveFailed';
          const icon = updatedEvent.updateSuccessful ? UserMessageIcon.success : UserMessageIcon.failed;
          const color = updatedEvent.updateSuccessful ? UserMessageColor.green : UserMessageColor.red;
          const userMessage = new UserMessage({
            message: translateKey,
            icon,
            durationMs: 2000,
            position: 'top',
            color,
          });
          this.userMessageService.presentToast(userMessage);
        }

        if (updatedEvent.updateSuccessful) {
          return updatedEvent.sisMediaSetting;
        }

        return sisMediaSetting;
      })
    );

  readonly sisMediaSetting$: Observable<SisMediaSetting> = merge(
    this.sisMediaSettingRequest$,
    this.sisMediaSettingUpdate$
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  constructor(
    private destinationService: DestinationService,
    private http: HttpClient,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService,
    private userMessageService: UserMessageService
  ) {}

  async postSisMediaSetting(sisMediaSetting: SisMediaSetting): Promise<boolean> {
    const tenant = await firstValueFrom(this.destinationService.selectedTenant$);
    sisMediaSetting.tenantGuid = tenant.guid;

    return firstValueFrom(
      this.http.post(`${environment.baseUrlApi}${this.basePostUrl}`, sisMediaSetting).pipe(
        map(() => true),
        catchError(() => {
          const translateKey = 'general.phrase.saveFailed';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.failed,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);
          return of(false);
        })
      )
    );
  }
}
