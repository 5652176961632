import { LastSlopeControl } from 'src/app/last-slope-control/domain/last-slope-control.model';

export class LastSlopeControlAdapter {
  static adapt(items: any): LastSlopeControl[] {
    const lastSlopeControlDatas: LastSlopeControl[] = [];

    if (items) {
      items.forEach((item) => {
        lastSlopeControlDatas.push({
          guid: item.guid,
          zone: item.zone,
          times: item.times,
          activeTime: item.activeTime,
        });
      });
    }

    return lastSlopeControlDatas;
  }
}
