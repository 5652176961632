import { TruScanData } from 'src/app/maps/livedata/truscan-data.model';

export class TruScanDataAdapter {
  static adapt(item: any): TruScanData[] {
    const livedata: TruScanData[] = [];

    if (item) {
      item.forEach((e: any) => {
        livedata.push({
          timestamp: e.Timestamp,
          ropeTimestamp: e.RopeTimestamp,
          ropeId: Number.parseInt(e.RopeId),
          health: e.Health,
          installationId: e.InstallationId,
          name: e.Name,
          ropewaySisId: e.RopewaySisId,
          tenantSisId: e.TenantSisId,
        });
      });
    }

    return livedata;
  }
}
