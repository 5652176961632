import { Component, Input } from '@angular/core';
import { LayerGroup } from 'leaflet';
import { Tenant } from 'src/app/domain/tenant/tenant.model';

@Component({
  selector: 'sis-tenant-layer',
  templateUrl: './tenant-layer.component.html',
  styleUrls: ['./tenant-layer.component.scss'],
})
export class TenantLayerComponent {
  @Input() tenantGroup: LayerGroup;
  @Input() tenants: Tenant[] = [];
}
