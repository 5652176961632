import { WritablePart } from 'src/app/core/utils/type-helper';

export class AlarmLibraryItem {
  static readonly notification = 'notification';
  static readonly level = 'level';
  static readonly allowedSettings: string[] = [AlarmLibraryItem.notification, AlarmLibraryItem.level];

  private readonly settings = new Map<string, boolean>();
  private readonly updating = new Map<string, boolean>();

  sisId: string;
  code: string;
  message: string;
  level: string;
  defaultLevel: string;
  info: string;

  constructor(init?: Partial<WritablePart<AlarmLibraryItem>>) {
    Object.assign(this, init);

    AlarmLibraryItem.allowedSettings.forEach((s) => {
      this.settings.set(s, false);
      this.updating.set(s, false);
    });
  }

  getSetting(setting: string): boolean {
    return this.settings.get(setting);
  }

  setSetting(setting: string, value: boolean): void {
    this.settings.set(setting, value);
  }

  getUpdating(setting: string): boolean {
    return this.updating.get(setting);
  }

  setUpdating(setting: string, value: boolean): void {
    this.updating.set(setting, value);
  }
}
