import { Alarm } from 'src/app/domain/alarm.model';

export class EfaHistoryAdapter {
  static adapt(items: any): Alarm[] {
    if (!items) {
      return [];
    }

    const alarms: Alarm[] = [];

    items.forEach((e: any) => {
      alarms.push(
        new Alarm({
          sisId: e.sisId,
          tenantSisId: e.tenantSisId,
          timestamp: new Date(e.timestamp),
          code: e.code,
          info: e.info,
          text: e.text,
        })
      );
    });

    return alarms;
  }
}
