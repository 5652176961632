import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { MeteoLivedata } from 'src/app/maps/livedata/meteo-livedata.model';

export class SnowmakingDetailViewDisplayData {
  readonly alias: string;

  readonly windSpeed: string = '';
  readonly temperature: string = '';
  readonly wetBulbTemperature: string = '';
  readonly order: number;

  constructor(meteoStation: MeteoStation, liveData: MeteoLivedata) {
    if (meteoStation) {
      this.alias = meteoStation.alias;
      this.order = meteoStation.snowmakingWidgetOrder;
    }

    if (liveData) {
      this.windSpeed = liveData.windSpeedKmh == null ? '' : liveData.outdated ? '?' : liveData.windSpeedKmh.toString();
      this.temperature =
        liveData.temperatureCelsius == null ? '' : liveData.outdated ? '?' : liveData.temperatureCelsius.toString();
      this.wetBulbTemperature =
        liveData.wetBulbTemperatureCelsius == null
          ? ''
          : liveData.outdated
          ? '?'
          : liveData.wetBulbTemperatureCelsius.toString();
    }
  }
}
