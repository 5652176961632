<ion-header>
  <ion-toolbar>
    <ion-title class="sis-videoBrowse-header" translate> mediacenter.term.videos </ion-title>
    <ion-button fill="clear" slot="end" (click)="this.closeModal()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="sis-content">
  <div *ngIf="!videos" class="sis-loading-text">
    <ion-spinner class="sis-spinner"></ion-spinner>
  </div>
  <ion-item
    *ngFor="let i of videos; trackBy: itemId"
    class="sis-mediacenter-row vertical-align-content"
    [ngClass]="
      i.progress
        ? 'sis-uploading'
        : i.deleted
        ? 'sis-deleted'
        : i.converting
        ? 'sis-converting'
        : i.conversionFailed
        ? 'sis-conversion-failed'
        : 'sis-mediacenter-row-hover'
    "
    data-test="sis-mediacenter-video-item"
    (click)="!i.conversionFailed && videoSelected(i)"
  >
    <div *ngIf="i.progress" class="sis-video-container">
      <progress max="100" [value]="i.progress"></progress>
    </div>
    <div *ngIf="!i.progress && !i.converting && !i.conversionFailed && i.thumbnailUrl" class="sis-video-container">
      <img id="singleVideo" class="sis-video" [src]="i.thumbnailUrl" alt="" />
    </div>
    <div
      *ngIf="!i.progress && (i.converting || i.conversionFailed)"
      data-test="sis-mediacenter-converting-info"
      class="sis-video-container sis-global-center"
    >
      <ion-grid *ngIf="i.converting">
        <ion-row>
          <ion-col>
            <ion-spinner name="dots" style="width: 100%"></ion-spinner>
          </ion-col>
        </ion-row>
        <ion-row style="margin-top: -15px">
          <ion-col data-test="sis-mediacenter-converting-info-text">
            {{ 'mediacenter.phrase.converting' | translate }}
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid *ngIf="i.conversionFailed">
        <ion-row>
          <ion-col data-test="sis-mediacenter-conversion-failed-info-text" class="sis-conversion-failed">
            {{ 'mediacenter.phrase.conversionFailed' | translate }}
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="sis-video-name-container">
      <div
        class="sis-video-name"
        [ngStyle]="i.conversionFailed ? { cursor: 'default' } : ''"
        data-test="sis-mediacenter-video-name"
      >
        {{ i.name }}
      </div>
      <ion-fab
        *ngIf="!i.progress && i.width && i.height"
        data-test="sis-mediacenter-video-resolution"
        vertical="bottom"
        horizontal="end"
        class="sis-video-size-display"
        >{{ i.width }} x {{ i.height }}</ion-fab
      >
    </div>
    <ion-fab horizontal="end" *ngIf="!i.progress && !i.converting">
      <ion-button class="sis-videobrowse-delete" expand="full" (click)="deleteFile(i, $event)">
        <ion-label>
          <ion-icon name="trash-outline" data-test="sis-mediacenter-video-delete-button"></ion-icon>
        </ion-label>
      </ion-button>
    </ion-fab>
  </ion-item>
</ion-content>
<ion-footer style="height: 55px" lines="none">
  <ion-button expand="full" class="sis-file-selector">
    <label class="sis-video-label vertical-align-content" for="file" translate>mediacenter.term.uploadFile</label>
  </ion-button>
</ion-footer>
<input type="file" id="file" [accept]="this.acceptedFileFormats" (change)="handleFileInput($event)" />
