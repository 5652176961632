import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Options } from 'highcharts';
import { combineLatest, distinctUntilChanged, filter, map, Observable, switchMap, take, takeUntil } from 'rxjs';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { EnergyLiveDataService } from 'src/app/maps/livedata/energy-livedata.service';
import { ModalPopupComponent } from 'src/app/maps/modal-popup/modal-popup.component';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { EnergyTrend } from 'src/app/maps/trenddata/energy-trend.model';
import { EnergyTrendService } from 'src/app/maps/trenddata/energy-trend.service';
import { ChartBase } from 'src/app/maps/widgets/charts/chart-base';
import { ChartSettings } from 'src/app/maps/widgets/charts/chart-settings.model';

interface EnergyDevice {
  deviceSisId: string;
  displayName: string;
}

@Component({
  selector: 'sis-ropeway-energy-chart',
  templateUrl: './ropeway-energy-chart.component.html',
  styleUrls: ['./ropeway-energy-chart.component.scss'],
})
export class RopewayEnergyChartComponent extends ChartBase implements OnInit {
  private readonly chartData$: Observable<{
    energyDevices: EnergyDevice[];
    ropewayAlias: string;
  }> = combineLatest([
    this.energyLiveDataService.energy$,
    this.selectedMapElementService.selectedRopeway$,
    this.chartAdapter.chartReady$,
  ]).pipe(
    map(([energyLiveDatas, ropeway]) => {
      if (ropeway) {
        return {
          energyDevices: energyLiveDatas
            .filter((d) => d.parentSisId === ropeway.sisId)
            .map((d) => ({ deviceSisId: d.deviceSisId, displayName: d.displayName })),
          ropewayAlias: ropeway?.alias,
        };
      }
      return null;
    })
  );

  private energyText: string;

  constructor(
    private energyTrendService: EnergyTrendService,
    private energyLiveDataService: EnergyLiveDataService,
    private selectedMapElementService: SelectedMapElementService,
    private modalController: ModalController,
    timeFormatService: TimeFormatService,
    translateService: TranslateService,
    chartSettings: ChartSettings
  ) {
    super(translateService, chartSettings?.inModal, timeFormatService);
  }

  ngOnInit(): void {
    this.chartData$
      .pipe(
        distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
        filter((d) => !!d),
        switchMap(({ energyDevices, ropewayAlias }) => {
          this.initChart(energyDevices, ropewayAlias);

          const trendObservables = energyDevices.map((d) => this.energyTrendService.requestEnergyTrend(d.deviceSisId));

          return this.chartAdapter.loadData(trendObservables).pipe(
            map((energyTrends: EnergyTrend[][]) => ({
              energyDevices,
              energyTrends,
            }))
          );
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ energyDevices, energyTrends }) => {
        this.updateChartData(energyDevices, energyTrends);
        this.chartAdapter.hideLoading();
      });
  }

  async openInModal(e: PointerEvent): Promise<void> {
    if (
      this.mouseMoved ||
      (e.target as SVGRectElement).getAttribute('class') === 'highcharts-button-box' ||
      e.target['localName'] === 'text'
    ) {
      e.stopPropagation();
      return;
    }

    if (!this.isModal) {
      const modal = await this.modalController.create({
        component: ModalPopupComponent,
        componentProps: { component: RopewayEnergyChartComponent },
      });
      return modal.present();
    }
  }

  private initChart(energyDevices: EnergyDevice[], ropewayAlias: string): void {
    this.deviceAlias = ropewayAlias;

    this.title = `${this.energyText} «${ropewayAlias}»`;

    this.updateTitle();

    if (this.isModal) {
      this.updateModalTitle();
    }

    this.chartAdapter.showLoading();
    this.chartAdapter.removeAllSeries();

    energyDevices.forEach((device, index) => {
      this.chartAdapter.addSeries(device.deviceSisId, {
        name: device.displayName,
        id: device.deviceSisId,
        type: 'area' as any,
        lineWidth: 1,
        className: '',
        color: this.getTrendLineColor(index),
      });
    });

    this.chartAdapter.reflowChart(this.onDestroy$);
  }

  private updateChartData(energyDevices: EnergyDevice[], energyTrends: EnergyTrend[][]): void {
    if (!energyTrends) {
      this.resetChart();
      return;
    }

    if (!energyTrends.length || energyTrends.every((t) => !t.length)) {
      this.resetChart();
      this.chartAdapter.showNoData();
      return;
    }

    energyTrends.forEach((trend, index) => {
      const chartData = this.getData(trend).sort((i1, i2) => i1[0] - i2[0]);

      this.chartAdapter.setSeriesData(energyDevices[index].deviceSisId, chartData);
    });

    this.maxY = this.getMaxYAxisValue(energyTrends);

    if (!this.isZoomed) {
      this.chartAdapter.setYAxisExtremes(0, this.minY, this.maxY);
      this.chartAdapter.setXAxisExtremes(0, null, null);
    }

    if (this.isZoomed && this.isZoomedToEnd) {
      this.chartAdapter.setXAxisExtremes(0, this.zoomMin, null);
    }
  }

  private getData(trend: EnergyTrend[]): number[][] {
    return trend.map((item) => {
      const timestampMs = item.timestamp.getTime();
      return [timestampMs, item.energyKwInterval];
    });
  }

  private getMaxYAxisValue(trends: EnergyTrend[][]): number {
    return Math.max(...trends.map((trend) => Math.max(...trend.map((t) => t.energyKwInterval ?? null)) ?? null));
  }

  private resetChart(): void {
    this.chartAdapter.clearAllSeriesData();
  }

  protected translateTexts(translateService: TranslateService): void {
    translateService
      .get(['ropeway.term.energy'])
      .pipe(take(1))
      .subscribe((res) => {
        this.energyText = res['ropeway.term.energy'];

        this.chartAdapter?.updateOptions({
          title: {
            text: this.isModal ? '' : this.title,
          },
        });

        if (this.isModal) {
          this.updateModalTitle();
        }
      });
  }

  protected getChartOptions(): Options {
    return {
      time: {
        useUTC: false,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      legend: {
        enabled: this.isModal,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      xAxis: {
        type: 'datetime',
        crosshair: this.isModal,
        tickInterval: 1 * 60 * 60 * 1000, // 1 hours in ms
        events: {
          setExtremes: (event) => this.handleSetExtremesEvent(event),
        },
      },
      chart: {
        zoomType: 'xy',
        panKey: 'ctrl',
        panning: {
          enabled: true,
        },
        animation: false,
        style: {
          fontFamily: 'Myriad Pro',
        },
        resetZoomButton: {
          theme: {
            stroke: 'var(--ion-color-primary)',
            height: 8,
          },
        },
      },
      yAxis: {
        title: {
          text: '[kW]',
          align: 'high',
          offset: 0,
          rotation: 0,
          y: -15,
          x: -15,
          style: {
            fontSize: '12px',
          },
        },
        lineWidth: 1,
        tickInterval: 100,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: this.isModal,
        split: true,
        valueSuffix: 'kW',
        xDateFormat: '%d.%m.%y / %H:%M:%S',
      },
      lang: {
        noData: this.noDataText,
        loading: this.loadingText,
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      loading: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
        area: {
          fillOpacity: 0.7,
        },
      },
    };
  }
}
