import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Options } from 'highcharts';
import moment from 'moment';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { Configuration } from 'src/app/maps/domain/configuration.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayTorqueTrend } from 'src/app/maps/trenddata/ropeway-torque-trend.model';
import { RopewayTorqueTrendService } from 'src/app/maps/trenddata/ropeway-torque-trend.service';
import { ChartBase } from 'src/app/maps/widgets/charts/chart-base';
import { ChartSettings } from 'src/app/maps/widgets/charts/chart-settings.model';

@Component({
  selector: 'sis-torque-chart',
  templateUrl: './torque-chart.component.html',
  styleUrls: ['./torque-chart.component.scss'],
})
export class TorqueChartComponent extends ChartBase implements OnInit {
  static readonly torqueSeries = 'torqueSeries';

  static readonly motorCurrentTranslateString = 'ropeway.term.motorcurrent';
  static readonly torqueTranslateString = 'ropeway.term.torque';

  private readonly plotlineAlarm = 'PLOT1';
  private readonly ropeway$ = this.chartAdapter.chartReady$.pipe(
    take(1),
    switchMap(() => this.selectedMapElementService.selectedRopeway$)
  );

  private motorCurrentText: string;
  private torqueText: string;
  private torqueTrends: RopewayTorqueTrend[];

  constructor(
    private ropewayTorqueTrendService: RopewayTorqueTrendService,
    private selectedMapElementService: SelectedMapElementService,
    timeFormatService: TimeFormatService,
    translateService: TranslateService,
    chartSettings: ChartSettings
  ) {
    super(translateService, chartSettings?.inModal, timeFormatService);
  }

  ngOnInit(): void {
    this.ropeway$
      .pipe(
        distinctUntilChanged(),
        filter((ropeway) => !!ropeway),
        tap((ropeway) => this.initChart(ropeway)),
        switchMap((ropeway) => {
          this.chartAdapter.showLoading();
          return this.chartAdapter.loadData([this.ropewayTorqueTrendService.getTorqueData(ropeway)]).pipe(
            map((data: [RopewayTorqueTrend[]]) => ({
              ropeway,
              data: data[0],
            }))
          );
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ ropeway, data }) => {
        this.updateChartData(ropeway, data);
        this.chartAdapter.hideLoading();
      });
  }

  private initChart(ropeway: Ropeway): void {
    this.title = this.getTitle(ropeway);
    this.deviceAlias = ropeway?.alias;

    this.updateTitle();

    if (this.isModal) {
      this.updateModalTitle();
    }

    this.chartAdapter.showLoading();
    this.chartAdapter.removeAllSeries();
    this.chartAdapter.addSeries(TorqueChartComponent.torqueSeries, {
      name: this.title,
      id: 'torque',
      type: 'spline' as any,
      lineWidth: 1,
      className: '',
      color: this.getTrendLineColor(),
    });

    this.chartAdapter.reflowChart(this.onDestroy$);
  }

  private updateChartData(ropeway: Ropeway, torqueTrends: RopewayTorqueTrend[]): void {
    this.torqueTrends = torqueTrends;

    if (!torqueTrends) {
      this.resetChart();
      return;
    }

    const minX = this.chartAdapter.getTimeInMsHoursAgo(4);

    this.torqueTrends = torqueTrends.filter((t) => t.timestamp.getTime() >= minX);

    if (this.torqueTrends.length === 0) {
      this.resetChart();
      this.chartAdapter.showNoData();
      return;
    }

    const colorZones = this.getZones();
    const torqueData = this.getData(this.torqueTrends).sort((i1, i2) => i1[0] - i2[0]);

    this.setPlotLines();
    this.chartAdapter.setSeriesOptions(TorqueChartComponent.torqueSeries, { zones: colorZones } as any);
    this.chartAdapter.setSeriesData(TorqueChartComponent.torqueSeries, torqueData);

    this.minY = ropeway.configurations?.find(
      (c) => c.configKey === Configuration.driveTorqueNmKey || c.configKey === Configuration.driveMotorCurrentAmpereKey
    )?.negativeValues
      ? Math.min(0, this.getMinYAxisValue(this.torqueTrends))
      : 0;
    this.maxY = Math.max(100, this.getMaxYAxisValue(this.torqueTrends));

    if (!this.isZoomed) {
      this.chartAdapter.setYAxisExtremes(0, this.minY, this.maxY);
      this.chartAdapter.setXAxisExtremes(0, null, null);
    }

    if (this.isZoomed && this.isZoomedToEnd) {
      this.chartAdapter.setXAxisExtremes(0, this.zoomMin, null);
    }
  }

  private getMaxYAxisValue(trends: RopewayTorqueTrend[]): number {
    return Math.max(...trends.map((d) => d.torquePercent ?? null));
  }

  private getMinYAxisValue(trends: RopewayTorqueTrend[]): number {
    return Math.min(...trends.map((d) => d.torquePercent ?? null));
  }

  private getZones(): any[] {
    const zones = [];

    zones.push({
      value: 100,
      className: 'zone-nominal',
    });
    zones.push({
      value: 400,
      className: 'zone-alarm',
    });

    return zones;
  }

  private setPlotLines(): void {
    this.chartAdapter.removeYPlotBand(0, this.plotlineAlarm);

    this.chartAdapter.addYPlotBand(0, {
      from: 100,
      to: 400,
      className: 'zone-medium',
      id: this.plotlineAlarm,
    });
  }

  private getData(trends: RopewayTorqueTrend[]): number[][] {
    return trends.map((item) => {
      const timeStampInMs = item.timestamp.getTime();
      return [timeStampInMs, item.torquePercent];
    });
  }

  private resetChart(): void {
    this.chartAdapter.clearAllSeriesData();
  }

  private getTitle(ropeway: Ropeway): string {
    return ropeway
      ? ropeway.configurations &&
        ropeway.configurations.find((c) => c.configKey === Configuration.driveMotorCurrentAmpereKey)
        ? this.motorCurrentText
        : this.torqueText
      : '';
  }

  protected translateTexts(translateService: TranslateService): void {
    translateService
      .get([TorqueChartComponent.motorCurrentTranslateString, TorqueChartComponent.torqueTranslateString])
      .pipe(take(1))
      .subscribe((res) => {
        this.motorCurrentText = res[TorqueChartComponent.motorCurrentTranslateString];
        this.torqueText = res[TorqueChartComponent.torqueTranslateString];

        if (this.chartAdapter) {
          this.chartAdapter.updateOptions({
            title: {
              text: this.isModal ? '' : this.title,
            },
          });
        }

        if (this.isModal) {
          this.updateModalTitle();
        }
      });
  }

  protected getChartOptions(): Options {
    const self = this;
    return {
      time: {
        useUTC: false,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      legend: {
        enabled: this.isModal,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      xAxis: {
        type: 'datetime',
        crosshair: this.isModal,
        tickInterval: 1 * 60 * 60 * 1000, // 1 hours in ms
        events: {
          setExtremes: (event) => this.handleSetExtremesEvent(event),
        },
      },
      chart: {
        zoomType: 'xy',
        panKey: 'ctrl',
        panning: {
          enabled: true,
        },
        animation: false,
        style: {
          fontFamily: 'Myriad Pro',
        },
        resetZoomButton: {
          theme: {
            stroke: 'var(--ion-color-primary)',
            height: 8,
          },
        },
      },
      yAxis: {
        title: {
          text: '[%]',
          align: 'high',
          offset: 15,
          rotation: 0,
          y: -15,
          style: {
            fontSize: '12px',
          },
        },
        lineWidth: 1,
        tickInterval: 20,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: this.isModal,
        split: true,
        formatter() {
          const data = self.torqueTrends.find((d) => d.timestamp.getTime() === this.x);
          const yText =
            data.torqueAbsolute != null
              ? `<b>${data.torqueAbsolute} ${data.unit}</b> (${Math.round(this.y)}%)`
              : `<b>${Math.round(this.y)} ${data.unit}</b>`;
          const bottomText = `<span style="font-size: 8pt">${moment(new Date(this.x)).format(
            'DD.MM.YYYY / HH:mm:ss'
          )}</span>`;
          return [bottomText, `<span style="color:${this.color}">\u25CF</span> ${yText}`];
        },
      },
      lang: {
        noData: this.noDataText,
        loading: this.loadingText,
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      loading: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
          },
        },
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
    };
  }
}
