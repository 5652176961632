import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { IntervalDayEditorComponent } from 'src/app/timetable/interval-day-editor-modal/interval-day-editor.component';
import { JourneyEditorComponent } from 'src/app/timetable/journey-editor-modal/journey-editor.component';
import { JourneyRowComponent } from 'src/app/timetable/journey-row/journey-row.component';
import { JourneyRowMobileComponent } from 'src/app/timetable/journey-row-mobile/journey-row-mobile.component';
import { SeasonEditorComponent } from 'src/app/timetable/season-editor/season-editor.component';
import { TimetableTimeItemComponent } from 'src/app/timetable/time-item/time-item.component';
import { TimetablePage } from 'src/app/timetable/timetable.page';
import { TimetableIntervalComponent } from 'src/app/timetable/timetable-interval/timetable-interval.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatTooltipModule,
    RouterModule.forChild([
      {
        path: '',
        component: TimetablePage,
      },
    ]),
    ScrollingModule,
    TranslateModule,
  ],
  declarations: [
    TimetablePage,
    JourneyEditorComponent,
    SeasonEditorComponent,
    JourneyRowComponent,
    JourneyRowMobileComponent,
    TimetableIntervalComponent,
    TimetableTimeItemComponent,
    IntervalDayEditorComponent,
  ],
})
export class TimetableModule {}
