import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ForecastTrendAdapter } from 'src/app/maps/trenddata/forecast-trend.adapter';
import { ForecastTrend } from 'src/app/maps/trenddata/forecast-trend.model';
import { TemperatureTrendAdapter } from 'src/app/maps/trenddata/temperature-trend.adapter';
import { TemperatureTrend } from 'src/app/maps/trenddata/temperature-trend.model';
import { WetBulbTrendAdapter } from 'src/app/maps/trenddata/wet-bulb-trend.adapter';
import { WetBulbTrend } from 'src/app/maps/trenddata/wet-bulb-trend.model';
import { WindTrendAdapter } from 'src/app/maps/trenddata/wind-trend.adapter';
import { WindTrend } from 'src/app/maps/trenddata/wind-trend.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MeteoTrendService {
  constructor(private http: HttpClient) {}

  getWindTrends(meteoStationGuid: string): Observable<WindTrend[]> {
    const url = environment.baseUrlApi + `/trenddata/meteo/trend/` + meteoStationGuid;
    return this.http.get(url).pipe(map((data) => WindTrendAdapter.adapt(data)));
  }

  getForecastTrends(meteoStationGuid: string): Observable<ForecastTrend[]> {
    const url = environment.baseUrlApi + `/trenddata/meteo/forecast/` + meteoStationGuid;
    return this.http.get(url).pipe(map((data) => ForecastTrendAdapter.adapt(data)));
  }

  getTemperatureTrends(meteoStationGuid: string): Observable<TemperatureTrend[]> {
    const url = environment.baseUrlApi + `/trenddata/meteo/trend/` + meteoStationGuid;
    return this.http.get(url).pipe(map((data) => TemperatureTrendAdapter.adapt(data)));
  }

  getWetBulbTemperatureTrends(meteoStationGuid: string): Observable<WetBulbTrend[]> {
    const url = environment.baseUrlApi + `/trenddata/meteo/trend/` + meteoStationGuid;
    return this.http.get(url).pipe(map((data) => WetBulbTrendAdapter.adapt(data)));
  }
}
