/* eslint-disable max-classes-per-file */
export interface SisBlobItem {
  filePath?: string;
  url?: string;
  fileSize?: number;
  width?: string;
  height?: string;
}

export interface SisBlobItemUpload extends SisBlobItem {
  progress?: number;
}

export interface SisBlobStorageRequestParameters {
  storageUri: string;
  storageAccessToken: string;
}

export interface SisBlobContainerRequestParameters extends SisBlobStorageRequestParameters {
  containerName: string;
}

export interface SisBlobFileRequestParameters extends SisBlobContainerRequestParameters {
  filePath: string;
}

export interface SisBlobItemsUpdate {
  filesSource: FilesSource;
  blobs: SisBlobItemUpload[];
}

export interface UploadCommand {
  file: File;
  folder: string;
  fileNamePrefix?: string;
  metadata?: {
    [propertyName: string]: string;
  };
}

export enum FilesSource {
  container,
  upload,
}
