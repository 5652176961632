<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ this.breakdownInfo.name }}
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="this.done(true)" data-test="breakdowninfo-textselector-modal-close-btn"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="sis-modal-grid sis-global-sis-scrollbar">
    <ion-list>
      <ion-radio-group [(ngModel)]="this.selectedLibraryItem">
        <ion-item *ngFor="let item of library" data-test="breakdowninfo-textselector-modal-item">
          <ion-label>
            <sis-breakdowninfo-item [entry]="item"></sis-breakdowninfo-item>
          </ion-label>
          <ion-radio slot="start" [value]="item" color="secondary"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
    <ion-fab vertical="none" horizontal="start" *ngIf="this.bigScreenMode">
      <ion-item (click)="this.openBreakdownInfo()" lines="none" button="true">
        <qrcode [qrdata]="this.infoLinkString" width="150" errorCorrectionLevel="M"></qrcode>
      </ion-item>
    </ion-fab>

    <ion-item *ngIf="!this.bigScreenMode" (click)="this.openBreakdownInfo()" lines="none" button="true">
      <qrcode [qrdata]="this.infoLinkString" width="150" errorCorrectionLevel="M"></qrcode>
    </ion-item>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.apply'"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      [btnType]="'Secondary'"
      (sisClick)="this.update(false)"
      data-test="breakdowninfo-textselector-modal-save-btn"
    ></sis-general-button>
  </div>
</ion-footer>
