import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { EventBusSubscriberBase } from 'src/app/core/eventbus/event-bus-subscriber-base';
import { MediaCenterSlideUpdatedEvent } from 'src/app/core/eventbus/events';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { Slide } from 'src/app/media-center/slides/domain/slide.model';
import { MediaCenterSlidesService } from 'src/app/media-center/slides/media-center-slides.service';

@Component({
  selector: 'sis-time-control-modal',
  templateUrl: './time-control-modal.component.html',
  styleUrls: ['./time-control-modal.component.scss'],
})
export class TimeControlModalComponent extends EventBusSubscriberBase implements OnInit {
  @Input() slide: Slide;

  bigScreenMode: boolean;

  writePermission: boolean;

  isSaving: boolean = false;
  hasUnsavedChanges: boolean;
  isResetable: boolean;
  slideEdit: Slide;

  formGroup: FormGroup;

  private requiredFeatureWrite: Feature;

  constructor(
    eventBus: EventBusService,
    private modalCtrl: ModalController,
    private destinationService: DestinationService,
    private screenSizeService: ScreenSizeService,
    private slidesService: MediaCenterSlidesService
  ) {
    super(eventBus);
  }

  ngOnInit() {
    this.setDateTime();
    this.slideEdit = JSON.parse(JSON.stringify(this.slide));

    this.formGroup = new FormGroup(
      {
        timeControlEnabled: new FormControl(this.slideEdit.timeControlEnabled),
        startDate: new FormControl(this.slide.startDate, [Validators.required]),
        endDate: new FormControl(this.slide.endDate, [Validators.required]),
        startTime: new FormControl(this.slide.startTime, [Validators.required]),
        endTime: new FormControl(this.slide.endTime, [Validators.required]),
        mon: new FormControl(this.slideEdit.mon),
        tue: new FormControl(this.slideEdit.tue),
        wed: new FormControl(this.slideEdit.wed),
        thu: new FormControl(this.slideEdit.thu),
        fri: new FormControl(this.slideEdit.fri),
        sat: new FormControl(this.slideEdit.sat),
        sun: new FormControl(this.slideEdit.sun),
      },
      {
        validators: [this.validateDatetime()],
      }
    );

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((values) => {
      Object.assign(this.slideEdit, values);
      this.checkForChanges();
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.requiredFeatureWrite = new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.WRITE);

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) => f.hasMinimumRequirementFor(this.requiredFeatureWrite));
      if (!this.writePermission) {
        this.formGroup.disable({ emitEvent: false });
      } else {
        this.formGroup.enable({ emitEvent: false });
      }
    });

    this.observe(MediaCenterSlideUpdatedEvent, (event) => {
      if (event.slide && event.slide.guid === this.slide.guid && !event.removed) {
        this.isSaving = false;
        this.reset();

        if (event.success) {
          this.close();
        }
      }
    });

    this.setEnabledStates(false);
  }

  validateDatetime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const timeControlEnabled = control.get('timeControlEnabled').value;
      const startControl = control.get('startDate');
      const endControl = control.get('endDate');

      const start = startControl.value;
      const end = endControl.value;

      const now = moment(new Date()).format('YYYY-MM-DD[T]HH:mm:ss');

      if (!timeControlEnabled || (end > now && start < end)) {
        return null;
      }

      if (start >= end) {
        return { dateTimesInvalid: true };
      }

      return { endDateTimeInvalid: true };
    };
  }

  save(): void {
    this.isSaving = true;
    this.slidesService.updateSlide(this.slideEdit);
  }

  reset(): void {
    this.formGroup.reset({
      timeControlEnabled: this.slide.timeControlEnabled,
      startDate: moment(this.slide.startDate).format('YYYY-MM-DD[T]HH:mm:ss'),
      endDate: moment(this.slide.endDate).format('YYYY-MM-DD[T]HH:mm:ss'),
      startTime: this.slide.startTime,
      endTime: this.slide.endTime,
      mon: this.slide.mon,
      tue: this.slide.tue,
      wed: this.slide.wed,
      thu: this.slide.thu,
      fri: this.slide.fri,
      sat: this.slide.sat,
      sun: this.slide.sun,
    });
    this.setEnabledStates(true);
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  private setDateTime() {
    if (this.slide.startDate && this.slide.endDate) {
      this.slide.startDate = moment(this.slide.startDate).format('YYYY-MM-DD[T]HH:mm:ss');
      this.slide.endDate = moment(this.slide.endDate).format('YYYY-MM-DD[T]HH:mm:ss');
    } else {
      const startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      this.slide.startDate = moment(startDate).format('YYYY-MM-DD[T]HH:mm:ss');
      const endDate = new Date();
      endDate.setFullYear(2099);
      endDate.setHours(0, 0, 0, 0);
      this.slide.endDate = moment(endDate).format('YYYY-MM-DD[T]HH:mm:ss');
    }
  }

  private atLeastOneWeekdayEnabled() {
    return (
      this.slideEdit.mon ||
      this.slideEdit.tue ||
      this.slideEdit.wed ||
      this.slideEdit.thu ||
      this.slideEdit.fri ||
      this.slideEdit.sat ||
      this.slideEdit.sun
    );
  }

  private checkForChanges(): void {
    this.setEnabledStates(false);
    this.hasUnsavedChanges =
      !Slide.areSlidesEqual(this.slideEdit, this.slide) &&
      this.atLeastOneWeekdayEnabled() &&
      !this.formGroup.errors?.dateTimesInvalid;
    this.isResetable = !Slide.areSlidesEqual(this.slideEdit, this.slide);
  }

  private setEnabledStates(emitEvent: boolean) {
    if (this.slideEdit.timeControlEnabled) {
      this.formGroup.enable({ emitEvent: emitEvent });
    } else {
      this.formGroup.disable({ emitEvent: emitEvent });
      this.formGroup.controls.timeControlEnabled.enable({ emitEvent: emitEvent });
    }
  }
}
