import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { take, takeUntil } from 'rxjs/operators';
import { AlarmlistService } from 'src/app/alarmlist/alarmlist.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Alarm, AlarmLevel } from 'src/app/domain/alarm.model';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { AlarmLibraryModal } from 'src/app/maps/alarmlibrary/alarmlibrary.component';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { DestinationAlarmService } from 'src/app/maps/livedata/destination-alarm.service';

@Component({
  selector: 'sis-destination-alarm-detailview',
  templateUrl: './destination-alarm-detailview.component.html',
  styleUrls: ['./destination-alarm-detailview.component.scss'],
})
export class DestinationAlarmDetailViewComponent extends Unsubscriber implements OnInit {
  private gatewaySisId: string;
  private destinationAlias: string;

  canAccessAlarmLibrary: boolean;
  activeAlarms: Alarm[];
  hasAlarm: boolean;

  constructor(
    private modalCtrl: ModalController,
    private masterdataService: MasterdataService,
    private destinationService: DestinationService,
    private destinationAlarmService: DestinationAlarmService
  ) {
    super();
  }

  async openAlarmLibrary() {
    if (!this.gatewaySisId) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: AlarmLibraryModal,
      componentProps: {
        sisId: this.gatewaySisId,
        alias: this.destinationAlias,
      },
    });
    return modal.present();
  }

  ngOnInit(): void {
    this.masterdataService.masterData$.pipe(take(1)).subscribe((data) => {
      this.gatewaySisId = data.gatewayId;
      this.destinationAlias = data.alias;
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.canAccessAlarmLibrary = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.COCKPIT_TELEMETRY, FeatureAccessLevel.READ))
      );
    });

    this.destinationAlarmService.alarms$.pipe(takeUntil(this.onDestroy$)).subscribe((alarms) => {
      this.update(alarms);
    });
  }

  private update(destinationAlarms: Alarm[]): void {
    const alarms: Alarm[] = destinationAlarms?.filter((a) => a.active) ?? [];

    const uniques = alarms.reduce((p: Map<string, Alarm>, c: Alarm) => {
      const alarm: Alarm = p.get(c.code);
      if (!alarm || (alarm.level === AlarmLevel.WARNING && c.level === AlarmLevel.ALARM)) {
        p.set(c.code, c);
      }
      return p;
    }, new Map<string, Alarm>());

    this.activeAlarms = AlarmlistService.sortByTypeAndTimestamp(Array.from(uniques.values()));
    this.hasAlarm = this.activeAlarms?.length > 0;
  }
}
