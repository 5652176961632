import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { NmsDataAdapter } from 'src/app/it-services/livedata/nms-data.adapter';
import { NmsData } from 'src/app/it-services/livedata/nms-data.model';

@Injectable({
  providedIn: 'root',
})
export class NmsDataService extends LivedataServiceBase<NmsData> {
  protected readonly requiredFeatures = [new Feature(FeatureId.SISITSERVICES, FeatureAccessLevel.READ)];
  protected readonly endpointUri = 'nms';

  readonly nmsDatas$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, NmsDataAdapter.adapt, destinationService);
  }
}
