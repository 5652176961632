import { WritablePart } from 'src/app/core/utils/type-helper';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

export class UserMessage {
  readonly timestamp: number;
  readonly messageParts: string[];

  message: string | string[];
  icon: UserMessageIcon;
  durationMs: number;
  position: 'top' | 'middle' | 'bottom';
  color: UserMessageColor;
  showCloseButton: boolean = false;

  constructor(init?: Partial<WritablePart<UserMessage>>) {
    Object.assign(this, init);
    this.timestamp = Date.now();

    if (Array.isArray(this.message)) {
      this.messageParts = this.message;
    } else {
      this.messageParts = [this.message];
    }
  }
}
