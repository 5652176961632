<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <rect width="187.5" height="225" style="fill: none" />
        <g>
          <ellipse
            cx="135.62"
            cy="35.02"
            rx="11.5"
            ry="8.21"
            transform="translate(98.53 170.09) rotate(-89.12)"
            style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
          />
          <path
            d="M140.43,145.22,125.65,145s2.16-52.58,2.21-55.86-1.34-28.6-1.34-28.6.68-10.37,7.25-10.27,10.84,5.52,11,8.76-.62,30.7-.62,30.7c-.15,9.85-6.31,46.37-6.31,46.37l10.69-.65"
            style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
          />
        </g>
        <path
          d="M57.66,73.44H100.1A9.82,9.82,0,0,1,110,83.16V102a9.81,9.81,0,0,1-9.85,9.71H57.66"
          style="fill: none; stroke: #989898; stroke-linecap: round; stroke-miterlimit: 10; stroke-width: 4px"
        />
        <path
          d="M50.9,96.85v37.59a2.33,2.33,0,0,0,2.32,2.32H58.8a2.33,2.33,0,0,0,2.32-2.32V96.85"
          style="fill: none; stroke: #989898; stroke-miterlimit: 10; stroke-width: 4px"
        />
        <path
          d="M61.12,87.54V69.7a2.33,2.33,0,0,0-2.32-2.32H53.22A2.33,2.33,0,0,0,50.9,69.7V87.54"
          style="fill: none; stroke: #989898; stroke-miterlimit: 10; stroke-width: 4px"
        />
        <line
          x1="31.8"
          y1="92.58"
          x2="83.53"
          y2="92.58"
          style="fill: none; stroke: #989898; stroke-miterlimit: 10; stroke-width: 4px"
        />
        <polyline
          points="68.43 105.19 83.81 92.58 68.43 80.77"
          style="fill: none; stroke: #989898; stroke-miterlimit: 10; stroke-width: 4px"
        />
      </g>
    </g>
    <g>
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
      >
        {{ firstEntriesTotal }}
        <tspan id="unit" style="font-size: 24px">Pers</tspan>
      </text>
    </g>
  </g>
</svg>
