import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayOperationWidgetBase } from 'src/app/maps/widgets/ropeway-operation-widget-base';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-utilization-widget',
  templateUrl: './utilization-widget.component.html',
  styleUrls: ['./utilization-widget.component.scss'],
})
export class UtilizationWidgetComponent extends RopewayOperationWidgetBase implements OnInit {
  private readonly barMaxHeight = 137.5;
  private readonly barStartYValue = 18.75;

  percent: string;
  innerBarHeight: string;
  innerBarYValue: string;

  constructor(ropewayLiveDataService: RopewayLivedataService, selectedMapElementService: SelectedMapElementService) {
    super(ropewayLiveDataService, selectedMapElementService);
  }

  ngOnInit(): void {
    this.ropewayLiveData$.pipe(takeUntil(this.onDestroy$)).subscribe((d) => {
      if (d.liveData?.vehicleOnTrackCountPercent == null && d.liveData?.vehicleCountPercent == null) {
        this.calcAndSetDisplayValues(null);
        return;
      }

      const vehicleCountPercentValue = d.liveData.vehicleOnTrackCountPercent ?? d.liveData.vehicleCountPercent;
      this.calcAndSetDisplayValues(vehicleCountPercentValue);
    });
  }

  private calcAndSetDisplayValues(vehicleCountPercentValue: number) {
    this.percent = vehicleCountPercentValue ? vehicleCountPercentValue.toString() : WidgetBase.valueUnknown;
    const innerHeight = (this.barMaxHeight / 100) * (vehicleCountPercentValue ? vehicleCountPercentValue : 0);
    this.innerBarHeight = innerHeight + 'px';
    this.innerBarYValue = this.barStartYValue + this.barMaxHeight - innerHeight + 'px';
  }
}
