import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { NmsData } from 'src/app/it-services/livedata/nms-data.model';
import { NmsDataService } from 'src/app/it-services/livedata/nms-data.service';

@Component({
  selector: 'sis-sis-it-services',
  templateUrl: './sis-it-services.page.html',
  styleUrls: ['./sis-it-services.page.scss'],
})
export class SisItServicesPage extends Unsubscriber implements OnInit {
  nmsDatas: NmsData[] = [];
  bigScreenMode: boolean;

  constructor(private nmsDataService: NmsDataService, private screenSizeService: ScreenSizeService) {
    super();
  }

  ngOnInit(): void {
    this.nmsDataService.nmsDatas$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.nmsDatas = data?.sort(this.sortNmsData.bind(this)) ?? [];
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));
  }

  private sortNmsData(a: NmsData, b: NmsData): number {
    const statusOrder = this.getOrderByStatus(a) - this.getOrderByStatus(b);
    if (statusOrder !== 0) {
      return statusOrder;
    }

    if (a.deviceType === b.deviceType) {
      return a.deviceName < b.deviceName ? -1 : 1;
    }

    return a.deviceType < b.deviceType ? -1 : 1;
  }

  private getOrderByStatus(nmsData: NmsData): number {
    switch (nmsData.status) {
      case 'online':
        return 1;
      case 'offline':
        return 2;
      default:
        return 3;
    }
  }
}
