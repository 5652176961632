import { Component, Input, OnChanges } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DateTimeFormatPipe } from 'src/app/core/utils/date-time-format.pipe';

@Component({
  selector: 'sis-outdated-icon',
  templateUrl: './outdated-icon.component.html',
  styleUrls: ['./outdated-icon.component.scss'],
})
export class OutdatedIconComponent extends Unsubscriber implements OnChanges {
  @Input() color: string;
  @Input() timestamp: Date;

  displayedTooltip: string;

  constructor(
    private dateTimeFormatPipe: DateTimeFormatPipe,
    private translate: TranslatePipe,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.ngOnChanges();
    });
  }

  ngOnChanges() {
    const formattedDate = this.dateTimeFormatPipe.transform(this.timestamp, true, false);
    const translatedTooltip = `${this.translate.transform('general.phrase.outdatedDataSince')}`;

    this.displayedTooltip = `${translatedTooltip}: ${formattedDate}`;
  }
}
