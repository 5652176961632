import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge } from 'rxjs';
import { map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { UserAlarmEvent } from 'src/app/core/eventbus/events';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { Alarm, AlarmLevel } from 'src/app/domain/alarm.model';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { RopewayAlarmAdapter } from 'src/app/maps/livedata/ropeway-alarm.adapter';

@Injectable({
  providedIn: 'root',
})
export class RopewayAlarmService extends LivedataServiceBase<Alarm> {
  protected readonly endpointUri = 'livedata/ropewayalarm';
  protected readonly requiredFeatures = [
    new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ),
    new Feature(FeatureId.COCKPIT_TELEMETRY, FeatureAccessLevel.READ),
  ];

  private readonly alarmUpdate$ = this.eventBusService.observe(UserAlarmEvent).pipe(
    withLatestFrom(this.liveData$, this.destinationService.selectedTenant$),
    map(([event, alarms, tenant]) => {
      if (event.alarm && tenant?.sisId === event.alarm.tenantSisId && event.alarm.level !== AlarmLevel.FAULT) {
        const updatedAlarm = alarms.find((a) => a.code === event.alarm.code);

        if (updatedAlarm) {
          Object.assign(updatedAlarm, event.alarm);
        } else {
          alarms.push(event.alarm);
        }
      }
      return alarms;
    })
  );

  readonly alarms$ = merge(this.liveData$, this.alarmUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(http: HttpClient, private eventBusService: EventBusService, destinationService: DestinationService) {
    super(http, RopewayAlarmAdapter.adapt, destinationService);
  }
}
