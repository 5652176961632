<!-- Big Screen -->
<ion-row *ngIf="this.bigScreenMode && this.item" class="sis-ion-row vertical-align-content sis-global-hover">
  <ion-col size="auto" class="ion-align-items-center" style="margin-right: 10px">
    <ion-icon class="sis-icon" [src]="this.icon" />
  </ion-col>
  <ion-col
    size="auto"
    style="min-width: 150px; max-width: 150px"
    class="ion-align-items-center"
    *ngIf="!this.isStatusUpdating"
  >
    <ion-icon
      *ngFor="let options of this.item.iconOptions"
      [name]="options.iconName"
      class="sis-statusicon"
      [ngClass]="options.activeClasses"
      (click)="this.iconClick(options.sisMediaStatus)"
      matTooltip="{{
        (this.item.status != null
          ? this.item.operatingHoursEnabled
            ? 'sismedia.term.noManualSwitching'
            : options.tooltipTranslationString
          : 'sismedia.term.noStatus'
        ) | translate
      }}"
      [attr.data-test]="options.dataTestString"
    ></ion-icon>
  </ion-col>
  <ion-col size="auto" style="min-width: 150px; max-width: 150px" *ngIf="this.isStatusUpdating">
    <ion-spinner *ngFor="let options of this.item.iconOptions" class="sis-spinner" name="dots"></ion-spinner>
  </ion-col>
  <ion-col class="sis-global-text-overflow-ellipsis" style="padding-left: 0px">
    <ion-grid style="padding: 0px; width: 100%">
      <ion-row style="padding: 0px">
        <ion-col
          size="auto"
          class="sis-label-col"
          [style.maxWidth]="this.showingLabelLength"
          [style.minWidth]="this.showingLabelLength"
          data-test="sis-sismedia-item-label"
        >
          {{ this.item.label }}
        </ion-col>
        <ion-col class="sis-global-text-overflow-ellipsis" data-test="sis-sismedia-item-name">
          {{ this.item.name }}
          <ion-icon
            *ngIf="this.item.season == 1 || this.item.season == 0"
            class="sis-season-icon"
            [name]="'sis-summer-grey'"
            data-test="sis-sismedia-item-season-icon-summer"
          ></ion-icon>
          <ion-icon
            *ngIf="this.item.season == 2 || this.item.season == 0"
            class="sis-season-icon"
            [name]="'sis-winter-grey'"
            data-test="sis-sismedia-item-season-icon-winter"
          ></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-col>
  <ion-col *ngIf="this.showInfoCol && this.showButtons" size="auto" class="sis-icon-col">
    <ion-icon *ngIf="this.item.info" name="information-circle-outline" matTooltip="{{ this.item.info }}"> </ion-icon>
  </ion-col>
  <ion-col *ngIf="this.showSisMapsCol && this.showButtons" size="auto" class="sis-icon-col">
    <ion-icon
      *ngIf="this.hasSisMaps"
      name="map-outline"
      [matTooltip]="this.sisMapsTooltipText"
      matTooltipClass="sis-global-tooltip"
      [ngClass]="this.sisMapIconClass"
      data-test="sismedia-asset-sismaps"
    >
    </ion-icon>
  </ion-col>
  <ion-col
    *ngIf="(this.hasExports || this.hasExportsEditPermission) && this.showButtons"
    size="auto"
    class="sis-icon-col"
  >
    <ion-icon
      name="share-outline"
      matTooltip="{{ ('sismedia.phrase.exportTooltipTitle' | translate) + this.exportTooltipText }}"
      matTooltipClass="sis-global-tooltip"
      [matTooltipDisabled]="!this.hasExports"
      [style.cursor]="this.hasExportsEditPermission ? 'pointer' : 'default'"
      [style.color]="this.hasExports ? null : 'rgb(0, 0, 0, 0.3)'"
      (click)="this.openExportsEditModal()"
      data-test="sismedia-asset-export"
    >
    </ion-icon>
  </ion-col>

  <ion-col
    *ngIf="this.assetOverrideReadPermission && this.hasAssetOverride && this.showButtons"
    size="auto"
    class="sis-icon-col"
  >
    <!-- asset override: lift, slope, trail -->
    <ion-icon
      *ngIf="this.item.status != null"
      name="time-outline"
      class="sis-global-pointer-cursor"
      [ngClass]="{ 'sis-no-override': !this.item.hasActiveOverride }"
      (click)="this.openOverrideModal()"
      matTooltip="{{ 'sismedia.phrase.assetoverride' | translate }}"
      matTooltipClass="sis-global-tooltip"
      data-test="sismedia-asset-override"
    >
    </ion-icon>
  </ion-col>
  <ion-col
    *ngIf="this.hasOperatingHours && this.operatingHoursReadPermission && this.showButtons"
    size="auto"
    class="sis-icon-col"
  >
    <!-- operating hours: gastro, poi -->
    <ion-icon
      *ngIf="this.item.status != null"
      name="sis-operating-hours"
      class="sis-global-pointer-cursor"
      [ngClass]="{ 'sis-no-operating-hours': !this.item.operatingHoursEnabled }"
      (click)="this.openOperatingHoursModal()"
      matTooltip="{{ 'general.term.operatinghours' | translate }}"
      data-test="sismedia-operating-hours"
    >
    </ion-icon>
  </ion-col>
  <ion-col *ngIf="this.assetEditPermission && this.showButtons" size="auto" class="sis-icon-col">
    <ion-icon
      name="create-outline"
      class="sis-global-pointer-cursor"
      style="margin-bottom: 2px"
      (click)="this.openEditModal()"
      matTooltip="{{ 'general.term.edit' | translate }}"
      data-test="sismedia-asset-edit"
    >
    </ion-icon>
  </ion-col>
</ion-row>

<!-- small Screen -->
<ion-row *ngIf="!this.bigScreenMode" class="sis-ion-row-small vertical-align-content">
  <ion-col size="auto" class="ion-align-items-center">
    <ion-icon class="sis-icon-small" [src]="this.icon" />
  </ion-col>
  <ion-col size="auto" class="ion-align-items-center" *ngIf="!this.isStatusUpdating">
    <ion-icon
      *ngFor="let options of this.item.iconOptions"
      [name]="options.iconName"
      class="sis-statusicon-small"
      [ngClass]="getStatusIconClasses(this.item, options)"
      (click)="!this.item.operatingHoursEnabled ? this.iconClick(options.sisMediaStatus) : presentToast()"
      matTooltip="{{
        (this.item.status != null
          ? this.item.operatingHoursEnabled
            ? 'sismedia.term.noManualSwitching'
            : options.tooltipTranslationString
          : 'sismedia.term.noStatus'
        ) | translate
      }}"
      [attr.data-test]="options.dataTestString"
    ></ion-icon>
  </ion-col>
  <ion-col size="auto" *ngIf="this.isStatusUpdating">
    <ion-spinner class="sis-spinner-small" name="dots"></ion-spinner>
    <ion-spinner class="sis-spinner-small" name="dots"></ion-spinner>
    <ion-spinner class="sis-spinner-small" name="dots"></ion-spinner>
    <ion-spinner class="sis-spinner-small" name="dots"></ion-spinner>
  </ion-col>
  <ion-col class="sis-global-text-overflow-ellipsis" (click)="this.presentActionSheet()">
    <ion-grid style="padding: 0px; width: 100%">
      <ion-row style="padding: 0px">
        <ion-col
          class="sis-label-col-small sis-global-text-overflow-ellipsis"
          size="auto"
          [style.maxWidth]="this.showingLabelLength"
          [style.minWidth]="this.showingLabelLength"
        >
          {{ this.item.label }}
        </ion-col>
        <ion-col class="sis-global-text-overflow-ellipsis sis-ion-col-center">
          {{ this.item.name }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-col>
</ion-row>
