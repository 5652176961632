import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { SisMediaItem } from 'src/app/sismedia/domain/sismedia-item.model';
import { SisMediaExportsEditPostData } from 'src/app/sismedia/sismedia-exports-edit-modal/sismedia-exports-edit-postdata.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SisMediaExportsEditService {
  private readonly basePostUrl: string = '/export/mapping';

  constructor(private http: HttpClient, private userMessageService: UserMessageService) {}

  postExportMappings(postData: SisMediaExportsEditPostData, sisMediaItem: SisMediaItem): Observable<boolean> {
    sisMediaItem.setUpdating(SisMediaItem.ExportMappings, true);
    return this.http.post(`${environment.baseUrlApi}${this.basePostUrl}`, postData).pipe(
      take(1),
      map(() => true),
      catchError(() => {
        sisMediaItem.setUpdating(SisMediaItem.ExportMappings, false);
        const translateKey = 'general.phrase.saveFailed';
        const userMessage = new UserMessage({
          message: translateKey,
          icon: UserMessageIcon.failed,
          durationMs: 2000,
          position: 'top',
          color: UserMessageColor.red,
        });
        this.userMessageService.presentToast(userMessage);

        return of(false);
      })
    );
  }
}
