<ion-grid class="sis-grid">
  <ion-row class="sis-title-row sis-larger-fontsize">
    <ion-col style="height: 40px; padding: 0px; vertical-align: middle">
      <img src="assets\sis-logo\siscontrol-color.svg" />
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="sis-login-text-col">Cockpit</ion-col>
  </ion-row>
  <ion-row class="sis-login-row sis-larger-fontsize" style="margin-top: 50px">
    <ion-col>
      {{ 'general.phrase.pleaseLogin' | translate }}
    </ion-col>
  </ion-row>
  <ion-row class="sis-login-row">
    <ion-col>
      <ion-button class="sis-login-button" type="submit" expand="block" (click)="this.login()" [disabled]="!this.msalLoginReady">Login</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>