<div
  class="sis-chart"
  oncontextmenu="return false"
  [chart]="chart"
  style="height: 100%; flex: auto"
  (mousedown)="onMouseDown()"
  (click)="this.chartClicked($event)"
  (mouseup)="onMouseUp()"
  (mousemove)="onMouseMove()"
></div>

<div class="sis-chart-buttons">
  <div class="sis-chart-button" *ngIf="this.isZoomed" (click)="this.resetZoom()">
    <span style="padding-left: 6px; padding-right: 9px">{{ 'analytics.phrase.resetZoom' | translate }}</span>
  </div>

  <div class="sis-chart-button" *ngIf="this.isModal" (click)="this.exportChartPdf()">
    <span style="padding-left: 6px; padding-right: 22px; position: relative"
      >PDF<ion-icon name="download-outline" style="zoom: 1.3; position: absolute; top: -1.5px; right: 3px"></ion-icon
    ></span>
  </div>

  <div class="sis-chart-button" (click)="this.dataSelectionOpen = !this.dataSelectionOpen">
    <span style="padding-left: 6px; padding-right: 9px">
      {{
        this.dataSelectionOpen
          ? ('analytics.phrase.hideDataSelectors' | translate)
          : ('analytics.phrase.showDataSelectors' | translate)
      }}
    </span>
  </div>
</div>

<ion-grid *ngIf="this.dataSelectionOpen" class="sis-chart-selector">
  <ion-row
    style="padding: 0; cursor: pointer"
    *ngFor="let selector of this.chartSelectors"
    (click)="this.toggleSetting(selector.ropewaySisId, selector.setting)"
  >
    <ion-col size="1.5" style="text-align: right">
      <input type="checkbox" [checked]="selector.selected$ | async" style="pointer-events: none; cursor: pointer" />
    </ion-col>
    <ion-col>
      <span *ngFor="let s of selector.titleStrings" style="padding-left: 5px">{{ s | translate }} </span>
    </ion-col>
  </ion-row>
</ion-grid>

<div *ngIf="this.showContextMenu">
  <sis-context-menu
    [x]="this.contextMenuX"
    [y]="this.contextMenuY"
    [menuItems]="this.contextMenuItems"
    (menuItemSelected)="this.contextMenuItemSelected($event)"
  ></sis-context-menu>
</div>
