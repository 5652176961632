import { SisConverter } from 'src/app/core/utils/sis-converter';
import { AlarmLevel } from 'src/app/domain/alarm.model';
import { GlobalAlarm } from 'src/app/geomap/livedata/globalalarm.model';

export class GlobalAlarmAdapter {
  static adapt(item: any): GlobalAlarm[] {
    const globalAlarms: GlobalAlarm[] = [];

    item.forEach((e: any) => {
      const alarmCounts = new Map<AlarmLevel, number>();

      alarmCounts.set(AlarmLevel.FAULT, SisConverter.toNumberOrUndefined(e.fault));
      alarmCounts.set(AlarmLevel.INFO, SisConverter.toNumberOrUndefined(e.info));
      alarmCounts.set(AlarmLevel.ALARM, SisConverter.toNumberOrUndefined(e.alarm));
      alarmCounts.set(AlarmLevel.WARNING, SisConverter.toNumberOrUndefined(e.warning));
      alarmCounts.set(AlarmLevel.BYPASS, SisConverter.toNumberOrUndefined(e.bypass));
      alarmCounts.set(AlarmLevel.CHECK, SisConverter.toNumberOrUndefined(e.check));
      alarmCounts.set(AlarmLevel.SYSTEM, SisConverter.toNumberOrUndefined(e.system));

      globalAlarms.push({ tenantSisId: e.tenantSisId, alarms: alarmCounts });
    });

    return globalAlarms;
  }
}
