import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { combineLatest, firstValueFrom } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MediaCenterInfoBannerType } from 'src/app/media-center/domain/mediacenter-configuration.model';
import { MediaCenterService } from 'src/app/media-center/media-center.service';
import { Screen } from 'src/app/media-center/screens/domain/screen.model';
import { InfoBanner } from 'src/app/media-center/screens/infobanner-modal/domain/infobanner.model';
import { InfoBannerService } from 'src/app/media-center/screens/infobanner-modal/infobanner.service';
import { InfoBannerScreensSelectorComponent } from 'src/app/media-center/screens/infobanner-modal/infobanner-screen-selector-modal/infobanner-screen-selector.component';
import { InfoBannerTextComponent } from 'src/app/media-center/screens/infobanner-modal/infobanner-text-modal/infobanner-text.component';
import { MediaCenterScreenService } from 'src/app/media-center/screens/media-center-screens.service';

@Component({
  templateUrl: './infobanner.component.html',
  styleUrls: ['./infobanner.component.scss', '../../media-center-global.scss'],
})
export class InfoBannerComponent extends Unsubscriber implements OnInit {
  readonly searchControl = new FormControl<string>('');

  searching = false;
  screen: Screen;
  isNewInfoBanner: boolean;
  infoBannerWritePermission: boolean;
  btnIsDisabled = true;
  bigScreenMode: boolean;
  allScreensOfTenant: Screen[];
  infoBanners: InfoBanner[];
  selectedInfoBanner?: InfoBanner;
  showPreviewButton: boolean;
  showBannerPosition: boolean;

  constructor(
    private modalCtrl: ModalController,
    private screenSizeService: ScreenSizeService,
    private mediaCenterService: MediaCenterService,
    private infoBannerService: InfoBannerService,
    private destinationService: DestinationService,
    private confirmationDialogService: ConfirmationDialogService,
    private screensService: MediaCenterScreenService
  ) {
    super();
  }

  ngOnInit() {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.infoBannerWritePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_MEDIACENTER_INFOBANNER, FeatureAccessLevel.WRITE))
      );
    });

    this.screensService.screens$.pipe(takeUntil(this.onDestroy$)).subscribe((screens) => {
      this.allScreensOfTenant = screens.filter((screen) => screen.infoBannerAllowed);
    });

    combineLatest([
      this.infoBannerService.infoBanner$,
      this.searchControl.valueChanges.pipe(debounceTime(200), startWith('')),
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([infoBanners, searchTerm]) => {
        this.searching = false;

        this.selectedInfoBanner = infoBanners.find((i) => i.screenGuids.find((i) => i == this.screen.guid));
        if (searchTerm != '') {
          infoBanners = infoBanners.filter((infoBanner) =>
            infoBanner.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase())
          );
        }

        this.infoBanners = infoBanners.sort((a, b) => (a.name < b.name ? -1 : 1));
        this.infoBanners.forEach((infoBanner) => (infoBanner.screenLocations = this.joinScreenLocations(infoBanner)));
      });

    this.mediaCenterService.configuration$.pipe(takeUntil(this.onDestroy$)).subscribe((configuration) => {
      this.showPreviewButton = configuration?.infoBannerType !== MediaCenterInfoBannerType.PlainText;
      this.showBannerPosition = configuration?.infoBannerType !== MediaCenterInfoBannerType.PlainText;
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  selectInfoBanner(infoBanner?: InfoBanner) {
    this.selectedInfoBanner = infoBanner;
  }

  itemId(_: number, playlist: any): string {
    return playlist.name;
  }

  async openTextModal(infoBanner: InfoBanner): Promise<void> {
    if (!this.infoBannerWritePermission) {
      return;
    }

    const configuration = await firstValueFrom(this.mediaCenterService.configuration$);

    const cssClass =
      configuration?.infoBannerType === MediaCenterInfoBannerType.PlainText ? 'sis-infobanner-plaintext-modal' : 'sis-infobanner-wysiwyg-modal';

    const modal = await this.modalCtrl.create({
      component: InfoBannerTextComponent,
      cssClass,
      backdropDismiss: false,
      componentProps: {
        screenLocation: this.screen.location,
        screenWidth: this.screen.screenWidth,
        screenHeight: this.screen.screenHeight,
        infoBanner,
      },
    });

    await modal.present();
  }

  openSlideShowPreview(screenLabel: string, infoBannerGuid: string): void {
    this.mediaCenterService.openSlideShowScreenPreview(screenLabel, null, null, null, infoBannerGuid);
  }

  joinScreenLocations(infoBanner: InfoBanner) {
    if (infoBanner.screenGuids?.length > 0) {
      const screenLocations = [];
      infoBanner.screenGuids.forEach((screenGuid) => {
        const screenWithInfoBanner = this.allScreensOfTenant.find((screen) => screen.guid === screenGuid);
        if (screenWithInfoBanner) {
          screenLocations.push(screenWithInfoBanner?.location);
        }
      });
      return ':\n\n' + screenLocations.map((x) => `- ${x}`).join('\n');
    }
  }

  async openScreensSelectorModal(infoBanner: InfoBanner, screen: Screen): Promise<void> {
    if (!this.infoBannerWritePermission || !infoBanner) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: InfoBannerScreensSelectorComponent,
      cssClass: 'sis-infobanner-screen-selector-modal',
      backdropDismiss: false,
      componentProps: {
        infoBanner,
        entryScreen: screen,
      },
    });

    modal.onDidDismiss().then((event) => {
      if (event.data) {
        this.close();
      }
    });

    await modal.present();
  }

  deleteInfoBanner(infoBanner: InfoBanner) {
    if (infoBanner.screenGuids?.length > 0) {
      this.confirmationDialogService.presentAlert(
        'infobanner.phrase.removeInfoBannerMappings',
        'general.term.understood'
      );
      return;
    }

    this.confirmationDialogService
      .presentAlert('infobanner.phrase.deleteInfoBanner', 'mediacenter.term.delete')
      .then((confirmed) => {
        if (confirmed) {
          this.infoBannerService.deleteInfoBanner(infoBanner).subscribe({
            next: () => { },
            error: () => {
              this.infoBannerService.presentToast(false, 'mapping', true);
            },
          });
        }
      });
  }
}
