import { Chart } from 'angular-highcharts';
import Highcharts, { AxisPlotBandsOptions, Options, SeriesOptionsType, XAxisPlotLinesOptions } from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
import { forkJoin, Observable, timer } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
exporting(Highcharts);
offline(Highcharts);

export class ChartAdapter {
  private static readonly intervalMs = 30000;

  static create(options: Options): ChartAdapter {
    return new ChartAdapter(options);
  }

  private chartRef: Highcharts.Chart;

  readonly chart: Chart;
  readonly chartReady$: Observable<void>;

  readonly series = new Map<string, Highcharts.Series>();

  constructor(options: Options) {
    this.chart = new Chart(options);
    this.chartReady$ = this.chart.ref$.pipe(map(() => null)); // this is a workaround to use the external highcharts module 'no-data-to-display'

    this.chart.ref$.pipe(take(1)).subscribe((chartRef) => {
      this.chartRef = chartRef;
    });
  }

  loadData(observables: Array<Observable<any>>): Observable<any[]> {
    return timer(0, ChartAdapter.intervalMs).pipe(switchMap(() => forkJoin(observables)));
  }

  addSeries(seriesName: string, options: SeriesOptionsType): void {
    this.series.set(seriesName, this.chartRef.addSeries(options));
  }

  removeSeries(seriesName: string): void {
    this.chartRef.get(seriesName)?.remove();
    this.series.delete(seriesName);
  }

  removeAllSeries(): void {
    while (this.chartRef.series.length) {
      this.chartRef.series[0].remove();
    }

    this.series.clear();
  }

  clearAllSeriesData(): void {
    this.chartRef.series.forEach((series) => series.setData(null));
  }

  showLoading(): void {
    this.chartRef.showLoading();
  }

  hideLoading(): void {
    this.chartRef.hideLoading();
  }

  showNoData(): void {
    (this.chartRef as any).showNoData();
  }

  exportData(title: string, subtitle: string): void {
    this.chartRef.exportChartLocal(
      {
        type: 'application/pdf',
        scale: 1,
        filename: title + '_' + subtitle,
        fallbackToExportServer: false,
        sourceHeight: 700,
        sourceWidth: 1400,
      },
      {
        chart: {
          type: 'spline',
          style: {
            fontFamily: 'Arial',
          },
        },
        title: {
          text: title,
          style: {
            fontSize: '14px',
          },
        },
        subtitle: {
          text: subtitle,
          style: {
            fontSize: '10px',
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: '8px',
            },
          },
        },
        yAxis: {
          title: {
            style: {
              fontSize: '8px',
            },
          },
          labels: {
            style: {
              fontSize: '8px',
            },
          },
        },
        colors: ['#91cddb', '#32849a'],
        plotOptions: {
          spline: {
            lineWidth: 1,
            marker: {
              enabled: false,
            },
          },
        },
      }
    );
  }

  setSeriesData(seriesName: string, data: number[][]): void {
    this.series.get(seriesName)?.setData(data);
  }

  setSeriesOptions(seriesName: string, options: Highcharts.SeriesOptionsType): void {
    this.series.get(seriesName)?.update(options);
  }

  setYAxisExtremes(axis: number, min: number, max: number): void {
    this.chartRef.yAxis[axis]?.setExtremes(min, max);
  }
  setXAxisExtremes(axis: number, min: number, max: number): void {
    this.chartRef.xAxis[axis]?.setExtremes(min, max);
  }

  removeYPlotBand(axis: number, id: string): void {
    this.chartRef.yAxis[axis]?.removePlotBand(id);
  }

  addYPlotBand(axis: number, options: AxisPlotBandsOptions): void {
    this.chartRef.yAxis[axis]?.addPlotBand(options);
  }

  updateDateXPlotLine(axis: number, options: XAxisPlotLinesOptions): void {
    this.chartRef.xAxis[axis]?.removePlotLine(options.id);
    this.chartRef.xAxis[axis]?.addPlotLine(options);
  }

  getTimeInMsDaysAgo(days: number): number {
    const result = new Date();
    result.setDate(result.getDate() - days);
    return result.getTime();
  }

  getTimeInMsHoursAgo(hours: number): number {
    const result = new Date();
    result.setHours(result.getHours() - hours);
    return result.getTime();
  }

  setTitle(title: string): void {
    this.chartRef.setTitle({ text: title }, null, false);
  }

  reflowChart(onDestroy$: Observable<void>): void {
    timer(100)
      .pipe(takeUntil(onDestroy$))
      .subscribe(() => {
        this.chartRef.reflow();
      });
  }

  updateOptions(options: Options, redraw = false): void {
    this.chartRef.update(options, redraw);
  }

  zoomOut(): void {
    this.chartRef.zoomOut();
  }
}
