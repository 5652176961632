const providers: any[] = [
  { provide: 'environment', useValue: 'Development' },
  { provide: 'baseUrl', useValue: 'http://localhost:3000' },
];

export const ENV_PROVIDERS = providers;

export const environment = {
  production: false,
  baseUrlApi: 'https://api-dev-cockpit.siscontrol.ch/api',
  baseUrlCockpitBlobStorage: 'https://devcockpit.blob.core.windows.net',
  baseUrlPublicBlobStorage: 'https://devsiscontrolpublic.blob.core.windows.net',
  baseUrlMediaCenterBlobStorage: 'https://devmediacenter.blob.core.windows.net',
  clientId: 'b009400b-ab97-4c72-8ac6-cd5827cf9013',
  msalScopes: ['https://siscontrol-api-dev.azurewebsites.net/user_impersonation'],
  msalProtectedResource: 'https://api-dev-cockpit.siscontrol.ch/api',
  msalAuthority: 'https://login.microsoftonline.com/4a9d9297-bd6c-4ce3-b82e-9f52b306646e',
  postLogoutRedirectUri: 'https://dev.siscontrol.ch/',
  webapp: 'https://dev-cockpit.siscontrol.ch',
  publicWebApp: 'https://dev.siscontrol.ch',
  slideshowPlayerWebApp: 'https://dev-player.siscontrol.ch',
  oldDataTimeout: 60000,
  firebase: {
    apiKey: 'AIzaSyAfjGWSEN4tGNMAGm2q2c-IksNMIIfp4a8',
    authDomain: 'siscontrolalarm.firebaseapp.com',
    databaseURL: 'https://siscontrolalarm.firebaseio.com',
    projectId: 'siscontrolalarm',
    storageBucket: '',
    messagingSenderId: '541428824865',
    appId: '1:541428824865:web:aa49b49670545bcc',
  },
  pageTitle: '(DEV) SisControl Cockpit',
  isUiTest: false,
  environment: 'development',
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  appVersion: require('../version.json').version,
};
