// eslint-disable-next-line simple-import-sort/imports
import { STnetStatusAdapter } from 'src/app/core/stnet/stnet-status.adapter';
import { STnetCalculatedDataAdapter } from 'src/app/stnet/domain/stnet-calculated-data.adapter';
import { STnetItemAdapter } from 'src/app/stnet/domain/stnet-item.adapter';
import { STnet } from 'src/app/stnet/domain/stnet.model';

export class STnetAdapter {
  static adapt(item: any): STnet {
    if (!item) {
      return {
        stnetItem: null,
        calculatedData: null,
        stnetStatusArray: [],
      };
    }

    return {
      stnetItem: STnetItemAdapter.adapt(item.sTnetData),
      calculatedData: STnetCalculatedDataAdapter.adapt(item.sTnetCalculatedData),
      stnetStatusArray:
        item.sTnetStatusList?.filter((v: any) => v).map((status: any) => STnetStatusAdapter.adapt(status)) ?? [],
    };
  }
}
