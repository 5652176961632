<svg id="svg" version="1.1" viewBox="0 0 187.5 225" xmlns="http://www.w3.org/2000/svg">
  <g id="widget" transform="translate(0, -15)">
    <rect width="187.5" height="225" style="fill: none" />
    <g transform="rotate(191.81 96.239 102.9)">
      <g transform="rotate(112.75 95.822 94.397)">
        <path
          d="m84.62 30.92a63.5 63.5 0 1 1-35.14 17.45"
          style="fill: none; stroke-miterlimit: 10; stroke-width: 4px; stroke: #32849a"
        />
      </g>
      <polyline
        transform="rotate(112.75 95.666 95.767)"
        points="30.1 52.64 49.48 48.27 48.97 68.14"
        style="fill: none; stroke-miterlimit: 10; stroke-width: 4px; stroke: #32849a"
      />
    </g>
    <g id="gondel" transform="matrix(.72758 0 0 .72758 25.54 28.052)">
      <g>
        <path
          d="m112.42 112.84 1.85 22-1.85 22a5.24 5.24 0 0 1-5.23 5.23h-26.88a5.24 5.24 0 0 1-5.23-5.23l-1.85-22 1.85-22a5.24 5.24 0 0 1 5.23-5.22h26.88a5.24 5.24 0 0 1 5.23 5.22z"
          style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px; stroke: #979797"
        />
        <line
          x1="93.75"
          x2="93.75"
          y1="107.62"
          y2="87.1"
          style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px; stroke: #979797"
        />
        <polygon
          points="81.72 114.69 80.03 134.82 107.47 134.82 105.78 114.69"
          style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px; stroke: #979797"
        />
        <line
          x1="93.75"
          x2="93.75"
          y1="134.82"
          y2="154.93"
          style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px; stroke: #979797"
        />
      </g>
      <path
        d="m47.17 102.07-3.06 0.85a4.31 4.31 0 1 1-1.06-3.85l1.13-0.32zm100.43-34.21a4.3 4.3 0 0 0-4.21 3.4l-3.07 0.85 3 3.32 1.14-0.31a4.31 4.31 0 1 0 3.14-7.26zm-89.93 27.14-11.56 3.2 3 3.32 11.57-3.2zm1.93-0.54 3 3.32 11.57-3.2-3-3.32zm24.83-6.86-11.57 3.2 3 3.33 11.56-3.21zm1.93-0.53 3 3.32 11.57-3.21-3-3.32zm25-6.95-11.51 3.21 3 3.32 11.57-3.21zm1.93-0.53 3 3.32 11.56-3.2-3-3.33zm25.06-6.94-11.57 3.2 3 3.32 11.61-3.17z"
        style="fill: #979797"
      />
    </g>
    <path
      d="m110.13 165.69 0.96-5.328h-9.216l-0.048-0.096 1.776-1.872c4.368-4.608 9.984-10.08 9.984-16.896 0-3.024-1.296-7.392-7.632-7.392-2.592 0-4.896 0.768-6.624 1.68l0.624 4.56c0.96-0.576 2.256-1.2 4.128-1.2 2.592 0 3.552 1.536 3.552 3.6 0 4.32-4.944 9.552-9.744 14.832l-3.504 3.888-0.72 4.224zm16.864 0 1.488-7.632h3.168l0.864-4.704h-3.168l3.6-18.864h-5.76l-13.392 19.392-0.72 4.176h10.224l-1.488 7.632zm-7.968-12.336v-0.096l4.368-6.48c1.056-1.632 2.016-3.36 3.264-5.424h0.192c-0.528 1.968-1.104 4.08-1.536 6.096l-1.152 5.904zm20.208 12.336 2.544-13.392c0.72-3.552 2.544-5.568 3.936-5.568 0.96 0 1.392 0.72 1.392 1.872 0 0.768-0.144 2.064-0.288 2.976l-2.688 14.112h6l2.688-14.112c0.336-1.776 0.528-3.264 0.528-4.608 0-3.024-1.296-5.136-4.224-5.136-2.544 0-4.512 1.392-5.904 3.312l-0.096-0.048 2.4-12.624h-6l-6.288 33.216z"
      style="fill: #4d4d4d; stroke-linejoin: round; stroke-width: 7; stroke: #ffffff"
      aria-label="24h"
    />
    <path
      d="m110.13 165.69 0.96-5.328h-9.216l-0.048-0.096 1.776-1.872c4.368-4.608 9.984-10.08 9.984-16.896 0-3.024-1.296-7.392-7.632-7.392-2.592 0-4.896 0.768-6.624 1.68l0.624 4.56c0.96-0.576 2.256-1.2 4.128-1.2 2.592 0 3.552 1.536 3.552 3.6 0 4.32-4.944 9.552-9.744 14.832l-3.504 3.888-0.72 4.224zm16.864 0 1.488-7.632h3.168l0.864-4.704h-3.168l3.6-18.864h-5.76l-13.392 19.392-0.72 4.176h10.224l-1.488 7.632zm-7.968-12.336v-0.096l4.368-6.48c1.056-1.632 2.016-3.36 3.264-5.424h0.192c-0.528 1.968-1.104 4.08-1.536 6.096l-1.152 5.904zm20.208 12.336 2.544-13.392c0.72-3.552 2.544-5.568 3.936-5.568 0.96 0 1.392 0.72 1.392 1.872 0 0.768-0.144 2.064-0.288 2.976l-2.688 14.112h6l2.688-14.112c0.336-1.776 0.528-3.264 0.528-4.608 0-3.024-1.296-5.136-4.224-5.136-2.544 0-4.512 1.392-5.904 3.312l-0.096-0.048 2.4-12.624h-6l-6.288 33.216z"
      style="fill: #4d4d4d; stroke-dasharray: 1.513, 1.513; stroke-width: 1.513"
      aria-label="24h"
    />
  </g>
  <g>
    <text
      [attr.x]="textPositionX"
      [attr.y]="textPositionY"
      id="value"
      style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
      [style.font-size]="fontSize"
    >
      {{ this.availability }}
    </text>
  </g>
</svg>
