import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { BreakdownInfoPage } from 'src/app/breakdowninfo/breakdowninfo.page';
import { BreakdownInfoInfoScreenComponent } from 'src/app/breakdowninfo/breakdowninfo-infoscreen/breakdowninfo-infoscreen.component';
import { BreakdownInfoItemComponent } from 'src/app/breakdowninfo/breakdowninfo-item/breakdowninfo-item.component';
import { BreakdownInfoLibraryComponent } from 'src/app/breakdowninfo/breakdowninfo-library/breakdowninfo-library.component';
import { BreakdownInfoLibraryItemComponent } from 'src/app/breakdowninfo/breakdowninfo-library/breakdowninfo-library-item/breakdowninfo-library-item.component';
import { BreakdownInfoTextSelectorComponent } from 'src/app/breakdowninfo/breakdowninfo-text-selector/breakdowninfo-text-selector.component';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: BreakdownInfoPage,
      },
    ]),
    TranslateModule,
    ReactiveFormsModule,
    QRCodeModule,
    CoreModule,
  ],
  declarations: [
    BreakdownInfoPage,
    BreakdownInfoTextSelectorComponent,
    BreakdownInfoLibraryComponent,
    BreakdownInfoLibraryItemComponent,
    BreakdownInfoItemComponent,
    BreakdownInfoInfoScreenComponent,
  ],
})
export class BreakdownInfoPageModule {}
