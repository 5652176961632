import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

enum ButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Medium = 'Medium',
  Red = 'Red',
}

enum ButtonAlign {
  Left = 'Left',
  Right = 'Right',
}

@Component({
  selector: 'sis-general-button',
  templateUrl: './general-button.component.html',
  styleUrls: ['./general-button.component.scss'],
})
export class GeneralButtonComponent implements OnChanges {
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() btnAlign: string;
  @Input() btnType: string;
  @Input() showSpinner: boolean;
  @Input() width: string;
  @Input() icon: string;
  @Input() sisTooltip: string;

  @Output() sisClick = new EventEmitter();

  buttonType: string;
  buttonAlign: string;

  ngOnChanges() {
    switch (this.btnType) {
      case ButtonType.Primary:
        this.buttonType = 'sis-primary-btn';
        break;
      case ButtonType.Secondary:
        this.buttonType = 'sis-secondary-btn';
        break;
      case ButtonType.Red:
        this.buttonType = 'sis-red-btn';
        break;
    }

    switch (this.btnAlign) {
      case ButtonAlign.Left:
        this.buttonAlign = 'sis-align-btn-left';
        break;
      case ButtonAlign.Right:
        this.buttonAlign = 'sis-align-btn-right';
        break;
    }
  }

  onClick(): void {
    if (!this.disabled) {
      this.sisClick.emit();
    }
  }
}
