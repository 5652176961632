import { WritablePart } from 'src/app/core/utils/type-helper';
import { Feature } from 'src/app/domain/feature/feature.model';
import { AccessType } from 'src/app/remote-access/access-type.enum';

export class ConnectionParameter {
  static readonly STATUS_OK: string = 'Ok';
  static readonly STATUS_ERROR: string = 'Error';

  status: string;
  host: string;
  port: string;
  path: string;
  siscose: string;
  accessType: AccessType;
  requiredFeatureForWriteAccess: Feature;

  constructor(init?: Partial<WritablePart<ConnectionParameter>>) {
    Object.assign(this, init);
  }
}
