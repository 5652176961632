import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Options } from 'highcharts';
import { combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { MeteoTrendService } from 'src/app/maps/trenddata/meteo-trend.service';
import { RopewaySpeedTrendService } from 'src/app/maps/trenddata/ropeway-speed-trend.service';
import { RopewayTorqueTrendService } from 'src/app/maps/trenddata/ropeway-torque-trend.service';
import { ChartBase } from 'src/app/maps/widgets/charts/chart-base';
import { ChartSettings } from 'src/app/maps/widgets/charts/chart-settings.model';
import { ChartSettingsService } from 'src/app/maps/widgets/charts/chart-settings.service';
import { ChartSelector } from 'src/app/maps/widgets/charts/ropeway-chart/chart-selector.model';
import { MeteoHandler } from 'src/app/maps/widgets/charts/ropeway-chart/meteo-handler';
import { RopewayChartHandler } from 'src/app/maps/widgets/charts/ropeway-chart/ropeway-chart-handler';
import { SpeedHandler } from 'src/app/maps/widgets/charts/ropeway-chart/speed-handler';
import { TorqueHandler } from 'src/app/maps/widgets/charts/ropeway-chart/torque-handler';

@Component({
  selector: 'sis-ropeway-chart',
  templateUrl: './ropeway-chart.component.html',
  styleUrls: ['./ropeway-chart.component.scss'],
})
export class RopewayChartComponent extends ChartBase implements OnInit {
  private readonly ropeway$ = this.chartAdapter.chartReady$.pipe(
    take(1),
    switchMap(() => this.selectedMapElementService.selectedRopeway$)
  );

  private readonly yAxisUnits = ['m/s', '%', 'km/h'];

  dataSelectionOpen: boolean = true;
  chartSelectors: ChartSelector[] = [];
  private availableCharts: RopewayChartHandler[] = [];

  readonly forecastEnabled$ = this.chartSettingsService.forecastEnabled$;
  readonly forecastTestActive$ = this.ropeway$.pipe(
    switchMap((ropeway) =>
      combineLatest(
        ropeway.meteoStations
          .filter((m) => m.hasForecast && m.forecastTestActive)
          .map((meteoStation) => this.chartSettingsService.getRopewaySetting(ropeway.sisId, meteoStation.guid))
      )
    ),
    map((bools) => bools.length && bools.some((b) => b))
  );

  constructor(
    private meteoTrendService: MeteoTrendService,
    private chartSettingsService: ChartSettingsService,
    private selectedMapElementService: SelectedMapElementService,
    private ropewaySpeedTrendService: RopewaySpeedTrendService,
    private ropewayTorqueTrendService: RopewayTorqueTrendService,
    timeFormatService: TimeFormatService,
    private translateService: TranslateService,
    chartSettings: ChartSettings
  ) {
    super(translateService, chartSettings?.inModal, timeFormatService);

    this.minY = null;
  }

  ngOnInit(): void {
    this.ropeway$
      .pipe(
        distinctUntilChanged(),
        filter((ropeway) => !!ropeway),
        switchMap((ropeway) => {
          this.title = ropeway.alias;
          this.initChart(ropeway);
          this.chartAdapter.showLoading();

          this.availableCharts = [
            new SpeedHandler(
              this.chartSettingsService,
              this.chartAdapter,
              this.ropewaySpeedTrendService,
              this.translateService,
              ropeway,
              this.yAxisUnits
            ),
            new TorqueHandler(
              this.chartSettingsService,
              this.chartAdapter,
              this.ropewayTorqueTrendService,
              this.translateService,
              ropeway,
              this.yAxisUnits
            ),
          ];

          ropeway.meteoStations.forEach((meteoStation) => {
            this.availableCharts.push(
              new MeteoHandler(
                this.chartSettingsService,
                this.chartAdapter,
                this.meteoTrendService,
                meteoStation,
                this.translateService,
                ropeway,
                this.yAxisUnits
              )
            );
          });
          this.chartSelectors = this.availableCharts.map((c) => c.getChartSelectors()).flat(1);

          return merge(...this.availableCharts.map((c) => c.seriesObservable()));
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe((seriesDatas: Array<{ seriesId: string; data: number[][] }>) => {
        seriesDatas.forEach((series) => {
          this.updateChart(series.seriesId, series.data);
        });
      });
  }

  async toggleSetting(ropewaySisId: string, setting: string): Promise<void> {
    await this.chartSettingsService.toggleRopewaySetting(ropewaySisId, setting);
  }

  private initChart(ropeway: Ropeway): void {
    this.deviceAlias = ropeway.alias;
    this.updateTitle();

    if (this.isModal) {
      this.updateModalTitle();
    }

    this.chartAdapter.showLoading();
    this.chartAdapter.removeAllSeries();
    this.chartAdapter.reflowChart(this.onDestroy$);
  }

  private updateChart(seriesId: string, data: number[][]): void {
    this.chartAdapter.hideLoading();
    this.chartAdapter.setSeriesData(seriesId, data);

    if (!this.isZoomed) {
      this.chartAdapter.setXAxisExtremes(0, null, null);
    }

    if (this.isZoomed && this.isZoomedToEnd) {
      this.chartAdapter.setXAxisExtremes(0, this.zoomMin, null);
    }
  }

  async toggleForecast(e: PointerEvent): Promise<void> {
    e.stopPropagation();
    this.chartSettingsService.toggleForecast();
  }

  resetZoom(): void {
    this.chartAdapter.zoomOut();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected override translateTexts(translateService: TranslateService): void {}

  protected getChartOptions(): Options {
    return {
      time: {
        useUTC: false,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      legend: {
        enabled: this.isModal,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      xAxis: {
        type: 'datetime',
        crosshair: this.isModal,
        tickInterval: 1 * 60 * 60 * 1000, // 1 hours in ms
        events: {
          setExtremes: this.handleSetExtremesEvent.bind(this),
        },
      },
      chart: {
        zoomType: 'xy',
        panKey: 'ctrl',
        alignTicks: false,
        panning: {
          enabled: true,
        },
        animation: false,
        style: {
          fontFamily: 'Myriad Pro',
        },
        resetZoomButton: {
          theme: {
            stroke: 'var(--ion-color-primary)',
            height: 8,
          },
          position: {
            y: -10000,
          },
        },
      },
      yAxis: [
        {
          title: {
            text: `[m/s]`,
            align: 'high',
            offset: 10,
            rotation: 0,
            y: -15,
            style: {
              fontSize: '12px',
            },
          },
          lineWidth: 1,
          tickInterval: 1,
          labels: {
            style: {
              fontSize: '12px',
            },
          },
          min: 0,
          softMax: 6,
          gridLineWidth: 0,
          showEmpty: false,
        },
        {
          title: {
            text: `[%]`,
            align: 'high',
            offset: 12,
            rotation: 0,
            y: -15,
            style: {
              fontSize: '12px',
            },
          },
          lineWidth: 1,
          labels: {
            style: {
              fontSize: '12px',
            },
          },
          softMin: 0,
          softMax: 100,
          gridLineWidth: 0,
          tickInterval: 20,
          showEmpty: false,
        },
        {
          title: {
            text: `[km/h]`,
            align: 'high',
            offset: 10,
            rotation: 0,
            y: -15,
            style: {
              fontSize: '12px',
            },
          },
          lineWidth: 1,
          tickInterval: 20,
          labels: {
            style: {
              fontSize: '12px',
            },
          },
          min: 0,
          softMax: 80,
          gridLineWidth: 0,
          showEmpty: false,
        },
      ],
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: this.isModal,
        split: true,
        valueSuffix: '',
        xDateFormat: '%d.%m.%y / %H:%M:%S',
      },
      lang: {
        noData: this.noDataText,
        loading: this.loadingText,
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      loading: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
          },
        },
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
    };
  }
}
