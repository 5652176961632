<ion-row class="sis-global-row-50 sis-title-row">
  <ion-col [size]="this.bigScreenMode ? 0.5 : 1"
    ><ion-icon name="calculator-outline" style="color: #1c232a; --ionicon-stroke-width: 20px"></ion-icon
  ></ion-col>
  <ion-col>{{ 'stnet.term.calculatedValues' | translate }}</ion-col>
</ion-row>

<ion-row>
  <ion-col *ngIf="this.bigScreenMode" size="2.5"></ion-col>
  <ion-col style="padding-left: 0px">
    <ion-grid *ngIf="this.calculatedData">
      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.liftsOpen'"
        [today]="this.calculatedData.liftsToday"
        [total]="this.calculatedData.liftsTotal"
        data-test="calculated-lifts"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.slopesOpen'"
        [today]="this.calculatedData.slopesToday"
        [total]="this.calculatedData.slopesTotal"
        [lengthMeterToday]="this.calculatedData.slopesLengthMeterToday"
        [lengthMeterTotal]="this.calculatedData.slopesLengthMeterTotal"
        data-test="calculated-slopes"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.valleyRunOpen'"
        [today]="this.calculatedData.valleyRunsToday"
        [total]="this.calculatedData.valleyRunsTotal"
        data-test="calculated-valley-run"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.crossCountryClassicOpen'"
        [lengthMeterToday]="this.calculatedData.classicTracksLengthMeterToday"
        [lengthMeterTotal]="this.calculatedData.classicTracksLengthMeterTotal"
        data-test="calculated-cross-country-classic"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.crossCountrySkatingOpen'"
        [lengthMeterToday]="this.calculatedData.skatingTracksLengthMeterToday"
        [lengthMeterTotal]="this.calculatedData.skatingTracksLengthMeterTotal"
        data-test="calculated-cross-country-skating"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.halfpipeOpen'"
        [today]="this.calculatedData.halfpipesToday"
        [total]="this.calculatedData.halfpipesTotal"
        data-test="calculated-halfpipe"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.snowparksOpen'"
        [today]="this.calculatedData.snowparksToday"
        [total]="this.calculatedData.snowparksTotal"
        data-test="calculated-snowpark"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.airboardSlopesOpen'"
        [today]="this.calculatedData.airboardSlopesToday"
        [total]="this.calculatedData.airboardSlopesTotal"
        data-test="calculated-airboard-slope"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.snowcrossOpen'"
        [today]="this.calculatedData.snowcrossTracksToday"
        [total]="this.calculatedData.snowcrossTracksTotal"
        data-test="calculated-snowcross"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.snowtubeOpen'"
        [today]="this.calculatedData.snowtubeTracksToday"
        [total]="this.calculatedData.snowtubeTracksTotal"
        data-test="calculated-snowtube"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.tobogganRunOpen'"
        [today]="this.calculatedData.tobogganRunsToday"
        [total]="this.calculatedData.tobogganRunsTotal"
        [lengthMeterToday]="this.calculatedData.tobogganRunsLengthMeterToday"
        [lengthMeterTotal]="this.calculatedData.tobogganRunsLengthMeterTotal"
        data-test="calculated-toboggan-run"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.snowshoeOpen'"
        [today]="this.calculatedData.snowshoeTrailsToday"
        [total]="this.calculatedData.snowshoeTrailsTotal"
        [lengthMeterToday]="this.calculatedData.snowshoeTrailsLengthMeterToday"
        [lengthMeterTotal]="this.calculatedData.snowshoeTrailsLengthMeterTotal"
        data-test="calculated-snowshoe"
      ></sis-stnet-calculated-item>

      <sis-stnet-calculated-item
        [bigScreenMode]="this.bigScreenMode"
        [name]="'stnet.term.winterhikingOpen'"
        [lengthMeterToday]="this.calculatedData.winterHikingTrailsLengthMeterToday"
        [lengthMeterTotal]="this.calculatedData.winterHikingTrailsLengthMeterTotal"
        data-test="calculated-winterhiking"
      ></sis-stnet-calculated-item>
    </ion-grid>
  </ion-col>
</ion-row>
