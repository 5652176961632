import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { firstValueFrom } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { Screen } from 'src/app/media-center/screens/domain/screen.model';
import { MediaCenterScreenService } from 'src/app/media-center/screens/media-center-screens.service';
import { ScreeneditorComponent } from 'src/app/media-center/screens/screeneditor-modal/screeneditor.component';
import { v4 as v4guid } from 'uuid';

@Component({
  selector: 'sis-media-center-screens',
  templateUrl: './media-center-screens.component.html',
  styleUrls: ['./media-center-screens.component.scss', '../media-center-global.scss'],
})
export class MediaCenterScreensComponent extends Unsubscriber implements OnInit {
  private screenOrders: Map<string, number>;

  searchControl = new FormControl<string>('');
  searching = false;
  writePermission: boolean;
  infoBannerReadPermission: boolean;
  reorderingEnabled: boolean = false;

  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;
  readonly screenGroups$ = this.screensService.screenGroups$;
  readonly filteredScreens$ = this.screensService.filteredScreens$;
  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  constructor(
    private destinationService: DestinationService,
    private screensService: MediaCenterScreenService,
    private screenSizeService: ScreenSizeService,
    private modalCtrl: ModalController,
    private logbookModalService: LogbookModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.WRITE))
      );
      this.infoBannerReadPermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_MEDIACENTER_INFOBANNER, FeatureAccessLevel.READ))
      );
    });

    this.searchControl.valueChanges.pipe(debounceTime(200), takeUntil(this.onDestroy$)).subscribe((search) => {
      this.searching = false;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { screenSearch: search !== '' && search != null ? search.toLowerCase() : null },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    });

    this.activatedRoute.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
      const searchTerm = params.screenSearch?.toLowerCase();

      if (this.searchControl.value !== searchTerm) {
        this.searchControl.setValue(searchTerm);
      }

      this.screensService.filterScreens(searchTerm);
    });
  }

  async onReorderClick() {
    this.reorderingEnabled = true;
    this.searchControl.disable({ emitEvent: false });
    this.screensService.filterScreens('');
    const screens = await firstValueFrom(this.screensService.screens$);
    this.screenOrders = new Map<string, number>(screens.map((screen) => [screen.guid, screen.order]));
  }

  async onReorderSaveClick() {
    this.reorderingEnabled = false;
    const screens = await firstValueFrom(this.screensService.filteredScreens$);
    const reorderedScreens = screens.filter((screen) => this.screenOrders.get(screen.guid) !== screen.order);
    this.screensService.updateScreens(reorderedScreens);
    this.searchControl.enable();
    this.screensService.filterScreens(this.searchControl.value);
  }

  async onReorderCancelClick() {
    this.reorderingEnabled = false;
    const screens = await firstValueFrom(this.screensService.filteredScreens$);
    screens.forEach((screen) => (screen.order = this.screenOrders.get(screen.guid)));

    this.searchControl.enable();
    this.screensService.filterScreens(this.searchControl.value);
  }

  async openEditscreen(screen?: Screen): Promise<void> {
    if (!this.writePermission) {
      return;
    }

    const screens = await firstValueFrom(this.screensService.screens$);

    if (!screen) {
      screen = {
        guid: v4guid(),
        infoBannerAllowed: false,
      };
    }

    const screenLabels: string[] = [];
    const screenNames: string[] = [];
    screens
      .filter((screens) => screens.guid !== screen.guid)
      .forEach((screen) => {
        screenLabels.push(screen.label);
        screenNames.push(screen.location);
      });

    const modal = await this.modalCtrl.create({
      component: ScreeneditorComponent,
      cssClass: 'sis-screeneditor-modal',
      backdropDismiss: false,
      componentProps: {
        screen: screen,
        allScreenLabels: screenLabels,
        allScreenNames: screenNames,
      },
    });
    await modal.present();
  }

  async openLogbook(): Promise<void> {
    await this.logbookModalService.presentLogbookModal([[FeatureId.SISMEDIA_MEDIACENTER]]);
  }
}
