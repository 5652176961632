import { MediaCenterRemoteAccessStatus } from 'src/app/media-center/remote-access/remote-access-status.model';

export class MediaCenterRemoteAccessStatusAdapter {
  static adapt(items: any): MediaCenterRemoteAccessStatus[] {
    const remoteAccessStatuses: MediaCenterRemoteAccessStatus[] =
      (items as any[])?.map((e: any) => ({ guid: e.Guid, online: e.Online })) ?? [];

    return remoteAccessStatuses;
  }
}
