<ion-grid
  class="sis-page-grid sis-global-noscrollbar"
  (scroll)="this.showContextMenu=false"
  (click)="this.showContextMenu = false"
>
  <sis-page-title [title]="'general.term.usermanagement'" [featureId]="[[this.featureId]]"> </sis-page-title>

  <sis-context-menu
    *ngIf="this.showContextMenu && this.users?.length && this.writePermission"
    [x]="this.contextMenuX"
    [y]="this.contextMenuY"
    [menuItems]="this.contextMenuItems"
    [title]="this.contextMenuTitle"
    (menuItemSelected)="this.contextMenuItemSelected($event)"
  ></sis-context-menu>

  <div *ngIf="!this.usersLoaded" class="sis-spinner-div">
    <ion-spinner></ion-spinner>
  </div>
  <!-- BIG SCREEN -->
  <div class="sis-table-container sis-global-sis-scrollbar" *ngIf="this.bigScreenMode">
    <table *ngIf="this.usersLoaded" mat-table [dataSource]="this.usersFiltered" class="sis-table">
      <ng-container matColumnDef="displayName" [sticky]="true">
        <th mat-header-cell *matHeaderCellDef class="sis-first-cell">
          <div class="sis-search-cell">
            <ion-searchbar
              placeholder="{{ 'general.term.filter' | translate }}"
              type="text"
              [formControl]="searchControl"
              (ionChange)="this.searching = true"
              data-test="searchbar"
              style="padding-top: 100px; width: 300px !important"
            >
            </ion-searchbar>
            <div class="checkbox-container">
              <ion-checkbox
                class="checkbox"
                [(ngModel)]="isCockpitChecked"
                (ngModelChange)="onCheckboxChange('isCockpitChecked', $event)"
                color="secondary"
              ></ion-checkbox>
              <ion-label>{{'general.term.cockpit' | translate}}</ion-label>

              <ion-checkbox
                class="checkbox"
                [(ngModel)]="isSisMediaChecked"
                (ngModelChange)="onCheckboxChange('isSisMediaChecked', $event)"
                color="secondary"
              ></ion-checkbox>
              <ion-label>{{'general.term.sismedia' | translate}}</ion-label>

              <ion-checkbox
                class="checkbox"
                [(ngModel)]="isGeneralChecked"
                (ngModelChange)="onCheckboxChange('isGeneralChecked', $event)"
                color="secondary"
              ></ion-checkbox>
              <ion-label>{{'general.term.general' | translate}}</ion-label>
            </div>

            <sis-general-button
              *ngIf="this.usersLoaded"
              class="sis-add-button"
              [text]="'usermanagement.phrase.addUser'"
              [btnType]="'Secondary'"
              [icon]="'person-add'"
              [disabled]="(this.maxUsersReached && !this.isAdministrator) || !this.writePermission"
              (sisClick)="this.addUser(this.managedTenants)"
              matTooltip="{{ 'usermanagement.phrase.maxUsersReached'| translate }}"
              [matTooltipDisabled]="!this.maxUsersReached"
              data-test="add-user-btn"
            >
            </sis-general-button>
            <div *ngIf="this.usersLoaded" class="sis-user-count">
              {{ 'usermanagement.phrase.userCount' | translate }}:
              <span [ngClass]="{'sis-max-users-reached' : this.maxUsersReached}"
                >{{ this.selectedTenant.activeUsers }}</span
              >
              / {{ this.selectedTenant.maxUsers }}
            </div>
          </div>
        </th>
        <td mat-cell class="sis-user-cell" *matCellDef="let user">
          <sis-user-management-item
            [user]="user"
            [tenantFeatures]="this.tenantFeatures"
            [writePermission]="this.writePermission"
            [usersLoaded]="this.usersLoaded"
            [isAdministrator]="this.isAdministrator"
            [managedTenants]="this.managedTenants"
            class="sis-global-center"
            (contextMenuOnUser)="this.openUserContextMenu($event.user, $event.event)"
            oncontextmenu="return false"
          ></sis-user-management-item>
        </td>
      </ng-container>

      <ng-container
        *ngFor="let tenantFeature of this.tenantFeatures; let i = index"
        [matColumnDef]="tenantFeature.featureId.toString()"
      >
        <th mat-header-cell *matHeaderCellDef class="sis-feature-header-cell">
          <div class="ion-text-nowrap">
            <span
              (contextmenu)="openBatchFeaturePermissionContextMenu($event, i)"
              oncontextmenu="return false"
              [matTooltip]="this.featureIdTranslatedFeatureNameMapping.get(tenantFeature.featureId)"
            >
              {{this.featureIdTranslatedFeatureNameMapping.get(tenantFeature.featureId)}}
              <ion-icon
                *ngIf="isAdminOnly(i)"
                name="sis-admin"
                color="secondary"
                class="sis-admin-icon"
                matTooltip="Admin Only"
              ></ion-icon>
            </span>
          </div>
        </th>
        <td mat-cell *matCellDef="let user" class="sis-feature-level-cell">
          <sis-access-level-selector
            [tenantFeature]="this.tenantFeature"
            [userGuid]="this.user.userGuid"
            [writePermission]="this.writePermission"
            data-test="sis-access-level-selector"
          ></sis-access-level-selector>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="sis-feature-row"></tr>
    </table>
  </div>
  <!-- ------------- -->

  <!-- MOBILE SCREEN -->
  <div class="sis-table-container sis-global-sis-scrollbar" *ngIf="!this.bigScreenMode">
    <table *ngIf="this.usersLoaded" mat-table [dataSource]="this.usersFiltered" class="sis-table">
      <ng-container matColumnDef="displayName" [sticky]="true">
        <th mat-header-cell *matHeaderCellDef class="sis-first-cell mobile">
          <div class="sis-search-cell mobile">
            <div class="searchbar-container">
              <ion-searchbar
                placeholder="{{ 'general.term.filter' | translate }}"
                type="text"
                [formControl]="searchControl"
                (ionChange)="this.searching = true"
                data-test="searchbar"
                style="padding-top: 100px; width: 150px"
              >
              </ion-searchbar>
              <a (click)="this.openCheckboxModal()">
                <ion-icon name="filter-outline" class="filter-icon"></ion-icon>
              </a>
            </div>
            <sis-general-button
              *ngIf="this.usersLoaded"
              class="sis-add-button"
              [text]="'usermanagement.phrase.addUser'"
              [btnType]="'Secondary'"
              [icon]="'person-add'"
              [disabled]="(this.maxUsersReached && !this.isAdministrator) || !this.writePermission"
              (sisClick)="this.addUser(this.managedTenants)"
              matTooltip="{{ 'usermanagement.phrase.maxUsersReached'| translate }}"
              [matTooltipDisabled]="!this.maxUsersReached"
              data-test="add-user-btn"
              width="150px"
            >
            </sis-general-button>
            <div *ngIf="this.usersLoaded" class="sis-user-count mobile">
              {{ 'usermanagement.phrase.userCount' | translate }}:
              <span [ngClass]="{'sis-max-users-reached' : this.maxUsersReached}"
                >{{ this.selectedTenant.activeUsers }}</span
              >
              / {{ this.selectedTenant.maxUsers }}
            </div>
          </div>
        </th>
        <td mat-cell class="sis-user-cell mobile" *matCellDef="let user">
          <sis-user-management-item
            [user]="user"
            [tenantFeatures]="this.tenantFeatures"
            [writePermission]="this.writePermission"
            [usersLoaded]="this.usersLoaded"
            [isAdministrator]="this.isAdministrator"
            [managedTenants]="this.managedTenants"
            class="sis-global-center"
            (contextMenuOnUser)="this.openUserContextMenu($event.user, $event.event)"
            oncontextmenu="return false"
          ></sis-user-management-item>
        </td>
      </ng-container>
      <ng-container
        *ngFor="let tenantFeature of this.tenantFeatures; let i = index"
        [matColumnDef]="tenantFeature.featureId.toString()"
      >
        <th mat-header-cell *matHeaderCellDef class="sis-feature-header-cell mobile">
          <div class="ion-text-nowrap">
            <span
              (contextmenu)="openBatchFeaturePermissionContextMenu($event, i)"
              oncontextmenu="return false"
              [matTooltip]="this.featureIdTranslatedFeatureNameMapping.get(tenantFeature.featureId)"
            >
              {{this.featureIdTranslatedFeatureNameMapping.get(tenantFeature.featureId)}}
              <ion-icon
                *ngIf="isAdminOnly(i)"
                name="sis-admin"
                color="secondary"
                class="sis-admin-icon"
                matTooltip="Admin Only"
              ></ion-icon>
            </span>
          </div>
        </th>
        <td mat-cell *matCellDef="let user" class="sis-feature-level-cell">
          <sis-access-level-selector
            [tenantFeature]="this.tenantFeature"
            [userGuid]="this.user.userGuid"
            [writePermission]="this.writePermission"
            data-test="sis-access-level-selector"
          ></sis-access-level-selector>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="sis-feature-row"></tr>
    </table>
  </div>
  <!-- ------------- -->
</ion-grid>
