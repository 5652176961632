import { Component, Input } from '@angular/core';

@Component({
  selector: 'sis-logbook-button',
  templateUrl: './logbook-button.component.html',
  styleUrls: ['./logbook-button.component.scss'],
})
export class LogbookButtonComponent {
  @Input() disabled: boolean;
  @Input() showText: boolean;
}
