<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="100%"
  viewBox="0 0 187.65 225"
>
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <rect width="187.5" height="225" style="fill: none" />
        <g>
          <path
            d="M154.68,84.75a5,5,0,0,0-10,0V128a9.38,9.38,0,1,0,10,0h0Z"
            style="fill: none; stroke: #32849a; stroke-miterlimit: 10; stroke-width: 4px"
          />
          <line
            x1="144.68"
            y1="112.44"
            x2="154.68"
            y2="112.44"
            style="fill: none; stroke: #32849a; stroke-miterlimit: 10; stroke-width: 4px"
          />
        </g>
        <g>
          <g>
            <polygon
              points="47.16 101 50.8 92.32 45.47 80.99 49.69 76.79 60.85 82.13 69.45 78.5 73.7 66.67 79.55 66.69 83.58 78.52 92.19 82.11 103.41 76.48 107.5 80.7 102.08 92.07 105.55 100.73 116.87 104.78 117.13 110.83 105.48 114.89 101.81 123.76 107.03 135.06 102.85 139.28 91.72 133.94 83.13 137.57 78.88 149.4 73 149.47 68.94 137.71 59.97 133.97 48.96 139.45 45.08 135.39 50.5 124.01 46.97 115.28 35.28 111.06 35.4 105.18 35.4 105.18 47.16 101 47.16 101"
              style="fill: none; stroke: #989898; stroke-miterlimit: 10; stroke-width: 4px"
            />
            <circle
              cx="76.23"
              cy="108.96"
              r="19.23"
              style="fill: none; stroke: #989898; stroke-miterlimit: 10; stroke-width: 4px"
            />
          </g>
          <g>
            <circle
              cx="113.96"
              cy="61.19"
              r="8.85"
              style="fill: none; stroke: #989898; stroke-miterlimit: 10; stroke-width: 4px"
            />
            <polygon
              points="95.45 59.67 96.49 67.5 89.45 76.34 93.53 81.49 103.41 76.48 110.19 78.79 114.22 90.01 121.36 89.07 122.22 78.32 128.77 73.27 139.09 75.39 141.82 68.73 132.4 62.58 131.29 55.06 138.85 46.55 134.46 40.85 125.37 45.45 117.27 42.86 113.76 32.33 106.63 33.27 106.63 33.27 106.02 44.35 106.02 44.35 99.76 49.18 99.76 49.18 88.89 46.95 88.89 46.95 86.16 53.61 86.16 53.61 95.45 59.67"
              style="fill: none; stroke: #989898; stroke-miterlimit: 10; stroke-width: 4px"
            />
          </g>
        </g>
      </g>
    </g>
    <g>
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
      >
        ?
        <tspan id="unit" style="font-size: 24px">°C</tspan>
      </text>
    </g>
  </g>
</svg>
