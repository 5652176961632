<ion-header class="sis-ion-header">
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="closeModal()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title class="sis-modal-title">{{ alias }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="this.bigScreenMode ? 'ion-padding-horizontal' : 'ion-no-padding'" data-test="alarmlibrary-body">
  <ion-grid class="ion-no-margin ion-no-padding sis-no-scroll">
    <ion-row>
      <ion-col size="8">
        <ion-searchbar
          [ngClass]="this.bigScreenMode ? 'sis-big-searchbar' : 'sis-small-searchbar'"
          data-test="sis-alarmlibrary-searchbar"
          placeholder="Filter..."
          type="text"
          [formControl]="searchControl"
        >
        </ion-searchbar>
      </ion-col>
      <ion-col style="padding: 0 10px 10px 0">
        <sis-logbook-button
          *ngIf="this.logbookAvailable$ | async"
          class="sis-logbook-button"
          [showText]="this.bigScreenMode"
          (click)="openLogbook()"
        >
        </sis-logbook-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="bigScreenMode" class="sis-header-row">
      <ion-col size="1.75">{{ 'alarming.term.alarmCode' | translate }}</ion-col>
      <ion-col size="8">{{ 'alarming.term.description' | translate }}</ion-col>
      <ion-col size="1.25">{{ 'alarming.term.level' | translate }}</ion-col>
      <ion-col size="1"></ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid class="ion-no-margin ion-no-padding sis-alarmlibrary-grid">
    <cdk-virtual-scroll-viewport itemSize="51" minBufferPx="1000" maxBufferPx="2000">
      <ion-row
        *cdkVirtualFor="let item of alarmsFiltered"
        [ngClass]="this.bigScreenMode ? 'sis-big-row sis-bottom-border' : 'sis-small-row sis-bottom-border'"
        data-test="alarmlibrary-row"
      >
        <ion-col [size]="this.bigScreenMode ? 1.75 : 4">{{ item.code }}</ion-col>
        <ion-col size="8" *ngIf="this.bigScreenMode">{{ item.message }}</ion-col>

        <ion-col [size]="this.bigScreenMode ? 1.25 : 5.5" *ngIf="this.isLevelUpdating(item)" class="sis-no-padding">
          <ion-spinner name="dots"></ion-spinner>
        </ion-col>
        <ion-col [size]="this.bigScreenMode ? 1.25 : 5.5" *ngIf="!this.isLevelUpdating(item)">
          <ion-select
            [value]="item.level"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover', size: 'cover' }"
            (ionChange)="this.override($event, item)"
            [ngClass]="this.bigScreenMode ? 'sis-modal-input' : 'sis-select-small'"
            *ngIf="this.isSwitchableLevel(item)"
          >
            <ion-select-option *ngFor="let level of this.getLevels(item)" [value]="level">{{
              this.getLevelTranslateString(level) | translate
            }}</ion-select-option>
          </ion-select>
          <span *ngIf="!this.isSwitchableLevel(item)">{{ this.getLevelTranslateString(item.level) | translate }}</span>
        </ion-col>
        <ion-col [size]="this.bigScreenMode ? 1 : 2.5" class="align-right">
          <sis-alarmlibrary-item-icon
            [setting]="this.notification"
            icon="sis-notifications"
            disabledIcon="sis-notifications-off"
            [alarm]="item"
            [ngClass]="this.bigScreenMode ? 'sis-alarmlibrary-button' : 'sis-alarmlibrary-button-small'"
          ></sis-alarmlibrary-item-icon>
        </ion-col>
        <div *ngIf="!this.bigScreenMode" class="sis-second-row">{{ item.message }}</div>
      </ion-row>
    </cdk-virtual-scroll-viewport>
  </ion-grid>
</ion-content>
