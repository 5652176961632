<ion-content sis-ion-content-scrollbar class="sis-mediacenter-content">
  <!-- Big Screen -->
  <ion-grid *ngIf="this.bigScreenMode" class="sis-mediacenter-no-scroll sis-mediacenter-grid ion-no-padding">
    <ion-row>
      <ion-col size="3" class="ion-no-padding">
        <ion-searchbar
          placeholder="{{ 'general.term.filter' | translate }}"
          type="text"
          [formControl]="searchControl"
          (ionChange)="this.searching = true"
          data-test="searchbar"
        >
        </ion-searchbar>
      </ion-col>
      <ion-col class="sis-global-center" size="0.5">
        <ion-spinner *ngIf="searching" style="height: 34px" name="bubbles"></ion-spinner>
      </ion-col>
      <ion-col style="align-self: center">
        <sis-logbook-button
          *ngIf="this.logbookAvailable$ | async"
          class="sis-mediacenter-logbook-button"
          [showText]="true"
          (click)="openLogbook()"
        ></sis-logbook-button>
      </ion-col>
      <ion-col size="auto">
        <sis-general-button
          class="sis-mediacenter-new"
          [text]="'mediacenter.phrase.newSlide'"
          [btnType]="'Secondary'"
          (sisClick)="this.writePermission && openEditor(null)"
          [disabled]="!this.writePermission"
          data-test="sis-mediacenter-new-slide-button"
        ></sis-general-button>
      </ion-col>
    </ion-row>
    <ion-row class="sis-mediacenter-header">
      <ion-col size="1" class="sis-mediacenter-padding-left">
        <ion-select
          interface="popover"
          [interfaceOptions]="{ cssClass: 'sis-global-popover' }"
          placeholder="Active"
          (ionChange)="this.updateQueryParam('active', $event.detail.value === 'all' ? null : $event.detail.value)"
          [(ngModel)]="selectedActivity"
          *ngIf="!this.rerender"
          data-test="activity-selector"
        >
          <ion-select-option value="all">{{ 'mediacenter.term.all' | translate }}</ion-select-option>
          <ion-select-option value="active">{{ 'mediacenter.term.active' | translate }}</ion-select-option>
          <ion-select-option value="inactive">{{ 'mediacenter.term.inactive' | translate }}</ion-select-option>
        </ion-select>
      </ion-col>
      <ion-col size="2" class="sis-mediacenter-padding-left">{{ 'general.term.thumbnail' | translate }}</ion-col>
      <ion-col>{{ 'general.term.name' | translate }}</ion-col>
      <ion-col size="1" class="sis-mediacenter-padding-left">
        <ion-select
          interface="popover"
          [interfaceOptions]="{ cssClass: 'sis-global-popover' }"
          placeholder="Type"
          (ionChange)="this.updateQueryParam('type', $event.detail.value === 'all' ? null : $event.detail.value)"
          [(ngModel)]="selectedType"
          *ngIf="!this.rerender"
          data-test="type-selector"
        >
          <ion-select-option value="all">{{ 'mediacenter.term.all' | translate }}</ion-select-option>
          <ion-select-option value="html">html</ion-select-option>
          <ion-select-option value="video">video</ion-select-option>
          <ion-select-option value="dynamic">dynamic</ion-select-option>
        </ion-select>
      </ion-col>
      <ion-col size="1">{{ 'mediacenter.term.timeControl' | translate }}</ion-col>
      <ion-col size="1">{{ 'mediacenter.term.displayDuration' | translate }}</ion-col>
      <ion-col size="1" style="min-width: 100px" class="sis-global-center">{{
        'general.term.edit' | translate
      }}</ion-col>
    </ion-row>
  </ion-grid>

  <!-- Big Screen -->
  <ion-grid *ngIf="this.bigScreenMode" class="sis-mediacenter-grid sis-mediacenter-table">
    <cdk-virtual-scroll-viewport itemSize="100" minBufferPx="1000" maxBufferPx="1100">
      <ion-row
        *cdkVirtualFor="let slide of slidesFiltered; trackBy: itemId"
        style="height: 100px"
        class="sis-mediacenter-row sis-global-hover"
        data-test="slides-row"
      >
        <ion-col size="1" class="sis-mediacenter-padding-left">
          <ion-checkbox
            *ngIf="!slide.updating"
            [checked]="slide.enabled"
            (click)="enabledInput($event, slide)"
            [disabled]="!this.writePermission"
            color="secondary"
          ></ion-checkbox>
          <ion-spinner name="bubbles" *ngIf="slide.updating"></ion-spinner>
        </ion-col>
        <ion-col size="2" class="sis-mediacenter-padding-left sis-link" (click)="this.openInNewTab(slide.url)">
          <img class="sis-mediacenter-thumbnail" [src]="slide.thumbnail" />
        </ion-col>
        <ion-col
          class="sis-mediacenter-padding-left sis-link"
          (click)="this.openInNewTab(slide.url)"
          data-test="slide-name"
        >
          {{ slide.name }}
        </ion-col>
        <ion-col size="1">
          {{ slide.fileType }}
        </ion-col>
        <ion-col size="1">
          <a
            [ngClass]="{
              'sis-mediacenter-clickable': this.readPermission,
              'sis-mediacenter-clickable-no-permission': !this.readPermission,
              'sis-mediacenter-time-control-icon-disabled': !slide.timeControlEnabled,
              'sis-mediacenter-time-control-icon-outdated': slide.disabledByTimeControl
            }"
            (click)="this.readPermission && openTimeControlModal(slide)"
            matTooltip="{{
              (!slide.timeControlEnabled
                ? 'mediacenter.phrase.timeControlDisabled'
                : slide.disabledByTimeControl
                ? 'mediacenter.phrase.timeControlOutdated'
                : 'mediacenter.phrase.timeControlActive'
              ) | translate
            }}"
            data-test="slide-time-control-button"
          >
            <ion-icon name="time-outline" class="sis-mediacenter-icon"></ion-icon>
          </a>
        </ion-col>
        <ion-col size="1" class="sis-mediacenter-padding-left">
          <p>
            <input
              type="name"
              class="sis-global-input sis-global-text-input sis-mediacenter-duration"
              [value]="slide.duration"
              onkeypress="(event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              (keyup)="durationInput($event, slide)"
              [disabled]="slide.updating || !this.writePermission"
            />
            s
          </p>
        </ion-col>
        <ion-col class="sis-global-center" size="1" style="min-width: 100px">
          <a
            *ngIf="!slide.updating && slide.canEdit"
            [ngClass]="this.writePermission ? 'sis-mediacenter-clickable' : 'sis-mediacenter-clickable-no-permission'"
            (click)="this.writePermission && this.openEditor(slide.guid)"
            data-test="edit-slide"
            matTooltip="{{ 'general.term.edit' | translate }}"
          >
            <ion-icon name="create-outline" class="sis-mediacenter-icon"></ion-icon>
          </a>
          <a
            *ngIf="!slide.updating && slide.canEdit"
            [ngClass]="this.writePermission ? 'sis-mediacenter-clickable' : 'sis-mediacenter-clickable-no-permission'"
            (click)="this.writePermission && deleteSlide(slide)"
            matTooltip="{{ 'general.term.delete' | translate }}"
            data-test="slide-delete-button"
          >
            <ion-icon name="trash-outline" class="sis-mediacenter-icon"></ion-icon>
          </a>
          <ion-spinner *ngIf="slide.updating" name="bubbles"></ion-spinner>
        </ion-col>
      </ion-row>
    </cdk-virtual-scroll-viewport>
  </ion-grid>

  <!-- Small Screen -->
  <ion-grid *ngIf="!this.bigScreenMode" class="sis-mediacenter-no-scroll sis-mediacenter-grid-mobile ion-no-padding">
    <ion-row class="sis-global-center sis-global-row">
      <ion-col>
        <ion-searchbar
          placeholder="{{ 'general.term.filter' | translate }}"
          type="text"
          [formControl]="searchControl"
          (ionChange)="this.searching = true"
          data-test="searchbar"
        >
        </ion-searchbar>
      </ion-col>
      <ion-col size="auto" style="min-width: 40px">
        <ion-spinner *ngIf="searching" name="bubbles" style="height: 1.5em"></ion-spinner>
      </ion-col>
      <ion-col size="auto">
        <sis-logbook-button
          *ngIf="this.logbookAvailable$ | async"
          class="sis-mediacenter-logbook-button"
          [showText]="false"
          (click)="openLogbook()"
        ></sis-logbook-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="!this.bigScreenMode" class="sis-mediacenter-grid-mobile sis-slides-grid">
    <cdk-virtual-scroll-viewport itemSize="150" minBufferPx="1000" maxBufferPx="1100" class="sis-virtual-scroll">
      <ion-row
        *cdkVirtualFor="let slide of slidesFiltered; trackBy: itemId"
        class="sis-mediacenter-row"
        style="height: 150px; padding-top: 10px"
        data-test="slides-row"
      >
        <ion-col>
          <ion-grid style="padding: 0px">
            <ion-row>
              <ion-col style="margin-bottom: 10px" (click)="this.openInNewTab(slide.url)">
                <b>{{ slide.name }}</b>
              </ion-col>
            </ion-row>
            <ion-row style="align-items: center">
              <ion-col size="4" (click)="this.openInNewTab(slide.url)">
                <img class="sis-mediacenter-thumbnail-small-screen" [src]="slide.thumbnail" />
              </ion-col>
              <ion-col size="8">
                <ion-grid class="ion-no-margin ion-no-padding">
                  <ion-row style="align-items: center">
                    <ion-col size="8">
                      {{ 'mediacenter.term.active' | translate }}
                    </ion-col>
                    <ion-col size="4">
                      <ion-checkbox
                        *ngIf="!slide.updating"
                        class="sis-mediacenter-checkbox-small-screen"
                        color="secondary"
                        [checked]="slide.enabled"
                        (click)="enabledInput($event, slide)"
                        [disabled]="!this.writePermission"
                      ></ion-checkbox>
                      <ion-spinner name="bubbles" *ngIf="slide.updating"></ion-spinner>
                    </ion-col>
                  </ion-row>
                  <ion-row style="align-items: center">
                    <ion-col size="8">
                      {{ 'mediacenter.term.displayDuration' | translate }}
                    </ion-col>
                    <ion-col size="4">
                      <p>
                        <input
                          type="name"
                          maxlength="3"
                          size="3"
                          class="sis-global-input sis-global-text-input sis-mediacenter-duration-small-screen"
                          [value]="slide.duration"
                          onkeypress="(event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                          (keyup)="durationInput($event, slide)"
                          [disabled]="slide.updating || !this.writePermission"
                        />
                        s
                      </p>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
    </cdk-virtual-scroll-viewport>
  </ion-grid>
</ion-content>
