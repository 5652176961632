<ion-grid class="sis-global-page-grid" [ngStyle]="{ 'padding-bottom': this.bigScreenMode ? null : '60px' }">
  <sis-page-title [title]="'general.term.ledticker'"> </sis-page-title>

  <ion-row>
    <ion-col [size]="this.bigScreenMode ? 6 : 12" style="padding: 10px 5px 5px 0">
      <ion-select
        [value]="this.selectedLedTicker"
        (ionChange)="this.ledTickerSelected($event)"
        interface="popover"
        [interfaceOptions]="{ cssClass: 'sis-global-popover' , size: 'cover' }"
        class="sis-global-input"
        data-test="ledticker-select"
        [disabled]="this.isModified"
      >
        <ion-select-option *ngFor="let ticker of this.ledTickers" [value]="ticker" data-test="ledticker-select-option"
          >{{ ticker.name }}</ion-select-option
        >
      </ion-select>
    </ion-col>
  </ion-row>
  <ion-row *ngFor="let row of this.displayConfig$ | async;" class="sis-led-ticker-group-row">
    <ion-col *ngFor="let col of row;" [size]="row.colSize">
      <sis-led-ticker-group
        [ledTickerPageGuid]="col"
        [ledTickerDisplayed$]="this.displayedItems$"
        (formDataModified)="this.onFormDataModified()"
        data-test="ledticker-page"
      ></sis-led-ticker-group>
    </ion-col>
  </ion-row>

  <ion-row class="sis-footer">
    <ion-col>
      <sis-general-button
        [text]="'general.term.save'"
        [ngClass]="{'sis-global-button-padding-right' : this.bigScreenMode}"
        [btnType]="'Secondary'"
        (sisClick)="this.save()"
        [disabled]="!this.writePermission || !this.isModified || this.isSaving"
        [showSpinner]="this.isSaving"
        [width]="this.bigScreenMode ? '120px' : '100%'"
        data-test="ledticker-save-btn"
      ></sis-general-button>
      <sis-general-button
        [text]="'general.term.reset'"
        [ngClass]="{'sis-general-button' : this.bigScreenMode}"
        [btnType]="'Secondary'"
        (sisClick)="this.reset()"
        [disabled]="!this.writePermission || !this.isModified || this.isSaving"
        [showSpinner]="this.isSaving"
        [width]="this.bigScreenMode ? '120px' : '100%'"
        data-test="ledticker-reset-btn"
      ></sis-general-button>
    </ion-col>
  </ion-row>
</ion-grid>
