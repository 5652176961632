import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { OperatingInfoEntry } from 'src/app/operating-info/domain/operating-info-entry.model';
import { OperatingInfoService } from 'src/app/operating-info/operating-info.service';
import { OperatingInfoInfoScreenComponent } from 'src/app/operating-info/operating-info-infoscreen/operating-info-infoscreen.component';
import { OperatingInfoEntryEditComponent } from 'src/app/operating-info/sis-operating-info-entry-edit/sis-operating-info-entry-edit.component';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './operating-info.page.html',
  styleUrls: ['./operating-info.page.scss'],
})
export class OperatingInfoPage extends Unsubscriber implements OnInit {
  private static readonly requiredFeature = new Feature(FeatureId.OPERATINGINFO, FeatureAccessLevel.WRITE);

  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  infoLinkString: string;
  library: OperatingInfoEntry[];
  selectedLibraryItem: OperatingInfoEntry;
  isActiveTextInLibrary: boolean;
  writePermission: boolean;
  bigScreenMode: boolean;
  activeText: OperatingInfoEntry;
  updating: boolean = false;
  featureId = FeatureId.OPERATINGINFO;

  get changed(): boolean {
    return this.activeText !== this.selectedLibraryItem;
  }

  constructor(
    private modalCtrl: ModalController,
    private destinationService: DestinationService,
    private logbookModalService: LogbookModalService,
    private operatingInfoService: OperatingInfoService,
    private sanitizer: DomSanitizer,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit() {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.operatingInfoService.updating
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((updating) => (this.updating = updating));
    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission =
        features.some((f) => f.hasMinimumRequirementFor(OperatingInfoPage.requiredFeature)) ?? false;
    });

    this.operatingInfoService.library$.pipe(takeUntil(this.onDestroy$)).subscribe((library) => {
      this.library = library.sort((a, b) => a.priority - b.priority);
      this.activeText = library.find((e) => e.active);
      this.reset();
    });

    this.generateInfoLink();
  }

  async updateActiveText(): Promise<void> {
    this.library.forEach((libraryEntry) => {
      libraryEntry.active = false;

      if (libraryEntry.guid === this.selectedLibraryItem.guid) {
        libraryEntry.active = true;
      }
    });
    await this.operatingInfoService.updateActiveText(this.library);
  }

  private generateInfoLink(): void {
    this.operatingInfoService.subUrl$.pipe(takeUntil(this.onDestroy$)).subscribe((subUrl) => {
      this.infoLinkString = environment.publicWebApp + '/operatinginfo/' + subUrl;
    });
  }

  async showLibraryItem(selectedOperatingInfoLibraryEntry: OperatingInfoEntry): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: OperatingInfoEntryEditComponent,
      cssClass: 'sis-operatinginfo-edit-modal',
      componentProps: {
        operatingInfoLibraryEntry: selectedOperatingInfoLibraryEntry,
      },
      backdropDismiss: false,
    });

    return modal.present();
  }

  async openOperatingInfo(externalWindow: boolean = false): Promise<void> {
    const safeInfoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.infoLinkString);

    if (!externalWindow && this.bigScreenMode) {
      const modal = await this.modalCtrl.create({
        component: OperatingInfoInfoScreenComponent,
        cssClass: 'sis-mobile-size-modal',
        componentProps: {
          safeInfoLink,
        },
      });

      return modal.present();
    }

    window.open(this.infoLinkString, '_blank');
  }

  reset() {
    this.selectedLibraryItem = this.library?.find((e) => e.active);
  }

  itemId(_: number, item: OperatingInfoEntry) {
    return item.guid;
  }
}
