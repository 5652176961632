<ion-grid class="sis-grid">
  <sis-page-title [title]="'alarming.phrase.activeAlarms'"></sis-page-title>
  <ion-row *ngIf="this.bigScreenMode" class="sis-header">
    <ion-col size="1.5" class="ion-text-left" style="white-space: nowrap; min-width: 200px">
      {{ 'general.term.datetime' | translate }}
      <sis-timeformat-button data-test="sis-timeformat-button"></sis-timeformat-button>
    </ion-col>
    <ion-col size="2.2" class="ion-text-left" style="min-width: 250px">{{
      'general.term.devicename' | translate
    }}</ion-col>
    <ion-col size="1.5" class="ion-text-left" style="min-width: 170px">{{
      'alarming.term.alarmId' | translate
    }}</ion-col>
    <ion-col size="1" class="ion-text-left">{{ 'alarming.term.level' | translate }}</ion-col>
    <ion-col class="ion-align-items-end">{{ 'alarming.term.description' | translate }}</ion-col>
  </ion-row>
  <sis-alarmlist-item
    *ngFor="let item of alarms; let i = index; trackBy: this.itemId"
    [index]="i"
    [alarm]="item"
    [bigScreenMode]="this.bigScreenMode"
  ></sis-alarmlist-item>
</ion-grid>
