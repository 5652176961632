import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { DeviceListHoverEvent, MapIconClickedEvent } from 'src/app/core/eventbus/events';
import { IconHelper } from 'src/app/core/icon-helper/icon-helper';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayStatusType } from 'src/app/maps/livedata/ropeway-status.model';
import { RopewayStatusService } from 'src/app/maps/livedata/ropeway-status.service';

@Component({
  selector: 'sis-devices-on-detailview-item',
  templateUrl: './devices-on-detailview-item.component.html',
  styleUrls: ['./devices-on-detailview-item.component.scss'],
})
export class DevicesOnDetailViewItemComponent extends Unsubscriber implements OnInit, OnDestroy {
  @Input() ropeway: Ropeway;

  cssClass: string;

  constructor(private eventBus: EventBusService, private ropewayStatusService: RopewayStatusService) {
    super();
  }

  ngOnInit(): void {
    this.ropewayStatusService.ropewayStatus$.pipe(takeUntil(this.onDestroy$)).subscribe((statusMap) => {
      this.cssClass = this.getCssClass(statusMap.get(this.ropeway.sisId));
    });
  }

  private getCssClass(ropewayStatusTypeMap: Map<RopewayStatusType, boolean>): string {
    if (ropewayStatusTypeMap) {
      if (
        ropewayStatusTypeMap.get(RopewayStatusType.OUTDATED) ||
        !ropewayStatusTypeMap.has(RopewayStatusType.OUTDATED)
      ) {
        return 'sis-alarmlevel-outdated';
      }
      if (ropewayStatusTypeMap.get(RopewayStatusType.ALARM) || ropewayStatusTypeMap.get(RopewayStatusType.FAULT)) {
        return 'sis-alarmlevel-alarm';
      }
      if (ropewayStatusTypeMap.get(RopewayStatusType.WARNING)) {
        return 'sis-alarmlevel-warning';
      }
      return 'sis-alarmlevel-none';
    }

    return 'sis-alarmlevel-outdated';
  }

  mouseEnter() {
    this.createHoverEvent(true);
  }

  mouseLeave() {
    this.createHoverEvent(false);
  }

  click() {
    const event = new MapIconClickedEvent();
    event.ropeway = this.ropeway;
    this.eventBus.publish(event);
  }

  ngOnDestroy() {
    this.createHoverEvent(false);
  }

  getIconName(): string {
    return IconHelper.getRopewayIconName(this.ropeway);
  }

  private createHoverEvent(hover: boolean): void {
    if (this.ropeway) {
      const event = new DeviceListHoverEvent();
      event.deviceSisId = this.ropeway.sisId;
      event.hover = hover;
      this.eventBus.publish(event);
    }
  }
}
