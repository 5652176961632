import { Component } from '@angular/core';
import { SelectChangeEventDetail } from '@ionic/angular';
import { BehaviorSubject, combineLatest, firstValueFrom, map, takeUntil } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { SambesiWhatsUpMessagesUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { SambesiWhatsUpService } from 'src/app/maps/livedata/sambesi-whatsup.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Component({
  selector: 'sis-sambesi-whatsup-detailview',
  templateUrl: './sambesi-whatsup-detailview.component.html',
  styleUrls: ['./sambesi-whatsup-detailview.component.scss'],
})
export class SambesiWhatsUpDetailViewComponent extends Unsubscriber {
  private readonly ropewayFilter$ = new BehaviorSubject<string>(undefined);

  isLoadingMessages = false;

  readonly messages$ = combineLatest([
    this.sambesiWhatsUpService.messages$,
    this.selectedMapElementService.selectedRopeway$,
    this.ropewayFilter$,
  ]).pipe(
    map(([messages, selectedRopeway, ropewayFilter]) =>
      selectedRopeway == null
        ? ropewayFilter == null
          ? messages
          : messages.filter((m) => m.ropewaySisId === ropewayFilter)
        : messages.filter((m) => m.ropewaySisId === selectedRopeway.sisId)
    )
  );

  readonly timestamp$ = this.messages$.pipe(
    map((messages) =>
      messages.length > 0
        ? new Date(messages.map((m) => m.timestamp.getTime()).reduce((a, b) => Math.max(a, b), 0))
        : undefined
    )
  );

  readonly ropeways$ = this.masterDataService.masterData$.pipe(map((masterData) => masterData.ropeways));

  constructor(
    private eventBus: EventBusService,
    public selectedMapElementService: SelectedMapElementService,
    public sambesiWhatsUpService: SambesiWhatsUpService,
    private destinationService: DestinationService,
    private masterDataService: MasterdataService,
    private userMessageService: UserMessageService
  ) {
    super();
  }

  ngOnInit() {
    this.eventBus
      .observe(SambesiWhatsUpMessagesUpdatedEvent)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.isLoadingMessages = false;
      });
  }

  async updateSambesiMessages() {
    this.isLoadingMessages = true;
    const selectedRopeway = await firstValueFrom(this.selectedMapElementService.selectedRopeway$);
    const selectedTenant = await firstValueFrom(this.destinationService.selectedTenant$);
    this.sambesiWhatsUpService.updateSambesiMessages(selectedTenant.guid, selectedRopeway?.sisId).subscribe({
      error: () => {
        this.isLoadingMessages = false;
        const translateKey = 'sambesi.message.failed';
        const userMessage = new UserMessage({
          message: translateKey,
          icon: UserMessageIcon.failed,
          durationMs: 2000,
          position: 'top',
          color: UserMessageColor.red,
        });

        this.userMessageService.presentToast(userMessage);
      },
    });
  }

  setRopewayFilter(sisId: CustomEvent<SelectChangeEventDetail>) {
    this.ropewayFilter$.next(sisId.detail.value);
  }

  clearRopewayFilter() {
    this.ropewayFilter$.next(undefined);
  }
}
