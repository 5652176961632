import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { ParkingPage } from 'src/app/parking/parking.page';
import { ParkingEditModalComponent } from 'src/app/parking/parking-item/parking-edit-modal/parking-edit-modal.component';
import { ParkingExportsEditModalComponent } from 'src/app/parking/parking-item/parking-exports-edit-modal/parking-exports-edit-modal.component';
import { ParkingItemComponent } from 'src/app/parking/parking-item/parking-item.component';
import { ParkingOccupancyModalComponent } from 'src/app/parking/parking-item/parking-occupancy-modal/parking-occupancy-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: ParkingPage,
      },
    ]),
    TranslateModule,
    MatTooltipModule,
    CoreModule,
  ],
  declarations: [ParkingPage, ParkingItemComponent, ParkingEditModalComponent, ParkingExportsEditModalComponent, ParkingOccupancyModalComponent],
})
export class ParkingPageModule {}
