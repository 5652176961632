import { WebLink } from 'src/app/weblink-collection/domain/weblink-item.model';
import { WebLinkItemCategory } from 'src/app/weblink-collection/domain/weblink-item-category.enum';
export class WebLinkAdapter {
  static adapt(items: any): WebLink[] {
    const webLinks: WebLink[] = [];

    if (items) {
      items.forEach((item: any) => {
        webLinks.push({
          guid: item.guid,
          displayOrder: item.displayOrder,
          category: item.category as WebLinkItemCategory,
          iconName: item.iconName,
          url: item.url,
          description: item.description,
          ownerGuid: item.ownerGuid,
        });
      });
    }

    return webLinks;
  }
}
