<div class="sis-detailview-container">
  <ion-grid class="sis-header">
    <ion-row>
      <ion-col size="1"></ion-col>
      <ion-col size="1" style="text-align: center">Id</ion-col>
      <ng-container *ngIf="this.hasClampingCountResetFeature$ | async">
        <ion-col class="value-list-item">{{ 'ropewayClampingCount.term.sinceReset' | translate }}</ion-col>
        <ion-col *ngIf="this.writePermission" size="1"></ion-col>
      </ng-container>
      <ion-col class="value-list-item">{{ 'general.term.total' | translate }}</ion-col>
      <ion-col size="1"></ion-col>
    </ion-row>
    <hr />
  </ion-grid>
  <div
    class="sis-content sis-global-center sis-global-no-scrollbar"
    [ngStyle]="
      (this.logbookAvailable$ | async) && (this.hasClampingCountResetFeature$ | async) ? '' : { height: '265px' }
    "
  >
    <ion-spinner *ngIf="this.loadingData" class="sis-spinner"></ion-spinner>
    <ion-grid *ngIf="!this.loadingData" class="ion-no-padding">
      <ion-row *ngFor="let clampingCount of this.clampingCounts; let i = index; trackBy: this.itemId" class="data-row">
        <ion-col size="1"></ion-col>
        <ion-col size="1">{{ clampingCount.vehicleId }}</ion-col>
        <ng-container *ngIf="this.hasClampingCountResetFeature$ | async">
          <ion-col
            class="value-list-item"
            matTooltip="{{ 'general.term.lastReset' | translate }}: {{
              clampingCount.metadata?.lastReset?.toLocaleString() ?? 'general.term.never' | translate
            }} {{ clampingCount.metadata?.email }}"
            >{{ clampingCount.clampingCount - (clampingCount.metadata?.totalValueOnReset ?? 0) }}</ion-col
          >
          <ion-col *ngIf="this.writePermission" size="1" class="reset-icon">
            <ion-spinner *ngIf="clampingCount.metadata?.isResetting" style="height: 16px; width: 16px"></ion-spinner>
            <ion-icon
              *ngIf="!clampingCount.metadata?.isResetting"
              name="refresh-outline"
              color="secondary"
              matTooltip="{{ 'general.term.reset' | translate }}"
              (click)="this.resetClampingCount(clampingCount)"
              style="cursor: pointer"
            ></ion-icon
          ></ion-col>
        </ng-container>
        <ion-col class="value-list-item">
          {{ clampingCount.clampingCount }}
        </ion-col>
        <ion-col size="1"></ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <sis-logbook-button
    class="sis-logbook-button"
    *ngIf="(this.logbookAvailable$ | async) && (this.hasClampingCountResetFeature$ | async)"
    (click)="openLogbook()"
    data-test="clamping-count-reset-logbook-button"
    [showText]="true"
  >
  </sis-logbook-button>
</div>
