import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { SisRoute } from 'src/app/domain/sis-route.model';
import { SisMediaAssetCategory } from 'src/app/sismedia/domain/sismedia-asset-category.enum';
import { SisMediaRouteParams } from 'src/app/sismedia/domain/sismedia-route-params.model';
import { SisMediaItemService } from 'src/app/sismedia/sismedia-item/sismedia-item.service';

@Component({
  selector: 'sis-media',
  templateUrl: './sismedia.page.html',
  styleUrls: ['./sismedia.page.scss'],
})
export class SisMediaPage extends Unsubscriber implements OnInit {
  private static readonly routeOptions: Map<string, SisMediaRouteParams> = new Map([
    [
      SisRoute.Lift,
      {
        excludedItemTypes: [],
        includedItemTypes: [],
        assetCategory: SisMediaAssetCategory.Lift,
        featureId: FeatureId.SISMEDIA_LIFT,
        titleTranslationString: 'general.term.lifts',
      },
    ],
    [
      SisRoute.Gastro,
      {
        excludedItemTypes: [],
        includedItemTypes: [],
        assetCategory: SisMediaAssetCategory.Gastro,
        featureId: FeatureId.SISMEDIA_GASTRO,
        titleTranslationString: 'general.term.gastro',
      },
    ],
    [
      SisRoute.Slope,
      {
        excludedItemTypes: ['airboard', 'rodeln', 'schlitteln', 'langlauf', 'langlaufklassisch', 'langlaufskating'],
        includedItemTypes: [],
        assetCategory: SisMediaAssetCategory.Slope,
        featureId: FeatureId.SISMEDIA_SLOPE,
        titleTranslationString: 'general.term.slopes',
      },
    ],
    [
      SisRoute.CrossCountry,
      {
        excludedItemTypes: [],
        includedItemTypes: ['langlauf', 'langlaufklassisch', 'langlaufskating'],
        assetCategory: SisMediaAssetCategory.Slope,
        featureId: FeatureId.SISMEDIA_CROSSCOUNTRY,
        titleTranslationString: 'general.term.crossCountry',
      },
    ],
    [
      SisRoute.Sledging,
      {
        excludedItemTypes: [],
        includedItemTypes: ['airboard', 'rodeln', 'schlitteln'],
        assetCategory: SisMediaAssetCategory.Slope,
        featureId: FeatureId.SISMEDIA_SLEDGING,
        titleTranslationString: 'general.term.sledging',
      },
    ],
    [
      SisRoute.Poi,
      {
        excludedItemTypes: [],
        includedItemTypes: [],
        assetCategory: SisMediaAssetCategory.Poi,
        featureId: FeatureId.SISMEDIA_POI,
        titleTranslationString: 'general.term.pois',
      },
    ],
    [
      SisRoute.Trail,
      {
        excludedItemTypes: ['bike', 'downhill'],
        includedItemTypes: [],
        assetCategory: SisMediaAssetCategory.Trail,
        featureId: FeatureId.SISMEDIA_TRAIL,
        titleTranslationString: 'general.term.trails',
      },
    ],
    [
      SisRoute.Bike,
      {
        excludedItemTypes: [],
        includedItemTypes: ['bike', 'downhill'],
        assetCategory: SisMediaAssetCategory.Trail,
        featureId: FeatureId.SISMEDIA_BIKE,
        titleTranslationString: 'general.term.bike',
      },
    ],
  ]);

  options: SisMediaRouteParams;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private sisMediaItemService: SisMediaItemService) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
      const category: string = params['category'];

      const options = SisMediaPage.routeOptions.get(category);

      this.options = options;

      if (!options) {
        this.router.navigate([params['destination'], 'sismedia', SisRoute.Lift]);
      }
    });
  }
}
