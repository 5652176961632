import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateTimeFormat',
  pure: false,
})
@Injectable({ providedIn: 'root' })
export class DateTimeFormatPipe implements PipeTransform {
  transform(date: Date, time: boolean = true, seconds: boolean = true): string {
    return moment(date)
      .local()
      .format(`${time ? 'DD.MM.YYYY ' : ''}HH:mm${seconds ? ':ss' : ''}`);
  }
}
