<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widget_Hintergrund_temp" data-name="Widget Hintergrund temp">
      <rect width="187.5" height="225" style="fill: none" />
    </g>
    <g id="Widgets">
      <g>
        <rect width="187.5" height="225" style="fill: none" />
        <rect x="150" y="56.25" width="18.75" height="150" style="fill: none" />
        <rect
          x="150"
          [attr.y]="this.innerBarYValue"
          width="18.75"
          [attr.height]="this.innerBarHeight"
          style="fill: #91cddb"
        />
        <rect
          x="150"
          y="18.75"
          width="18.75"
          height="137.5"
          style="fill: none; stroke: #989898; stroke-miterlimit: 10; stroke-width: 3px"
        />
        <g>
          <g>
            <path
              d="M122.46,72.52l1.66,19.78-1.66,19.78a4.72,4.72,0,0,1-4.7,4.7H93.56a4.72,4.72,0,0,1-4.71-4.7L87.19,92.3l1.66-19.78a4.72,4.72,0,0,1,4.71-4.7h24.2A4.72,4.72,0,0,1,122.46,72.52Z"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3px"
            />
            <line
              x1="105.66"
              y1="67.82"
              x2="105.66"
              y2="49.35"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3px"
            />
            <polygon
              points="118.01 92.3 116.49 74.19 94.83 74.19 93.31 92.3 118.01 92.3"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3px"
            />
            <line
              x1="105.66"
              y1="92.3"
              x2="105.66"
              y2="110.4"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3px"
            />
          </g>
          <g>
            <path
              d="M67.26,86.92l1.66,19.78-1.66,19.78a4.72,4.72,0,0,1-4.7,4.7H38.36a4.72,4.72,0,0,1-4.71-4.7L32,106.7l1.66-19.78a4.72,4.72,0,0,1,4.71-4.7h24.2A4.72,4.72,0,0,1,67.26,86.92Z"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3px"
            />
            <line
              x1="50.46"
              y1="82.22"
              x2="50.46"
              y2="63.75"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3px"
            />
            <polygon
              points="62.81 106.7 61.29 88.59 39.63 88.59 38.11 106.7 62.81 106.7"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3px"
            />
            <line
              x1="50.46"
              y1="106.7"
              x2="50.46"
              y2="124.8"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 3px"
            />
          </g>
          <path
            d="M28.43,72.57,26.82,73h0a4.34,4.34,0,1,1,.11-2.11Zm102-35a4.29,4.29,0,0,0-4.27,3.77l-4.57,1.27,3,3.32,3-.83a4.31,4.31,0,1,0,2.86-7.53ZM38.92,65.51l-11.56,3.2,3,3.32,11.57-3.2ZM40.85,65l3,3.32,11.57-3.2-3-3.32Zm24.83-6.88L54.12,61.3l3,3.32,11.57-3.21Zm1.93-.53,3,3.32,11.57-3.2-3-3.33Zm25-6.94L81.1,53.82l3,3.32,11.56-3.2Zm1.93-.54,3,3.32,11.56-3.2-3-3.32Zm25.06-6.94-11.57,3.2,3,3.32,11.56-3.2Z"
            style="fill: #989898"
          />
        </g>
      </g>
    </g>
    <g>
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
      >
        {{ this.percent }}
        <tspan id="unit" style="font-size: 24px">%</tspan>
      </text>
    </g>
  </g>
</svg>
