import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, lastValueFrom, of, ReplaySubject, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Feature } from 'src/app/domain/feature/feature.model';
import { RemoteAccess } from 'src/app/maps/domain/remote-access.model';
import { AccessType } from 'src/app/remote-access/access-type.enum';
import { ConnectionParameter } from 'src/app/remote-access/connection-parameter.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RemoteAccessService {
  private readonly _showRemoteAccessWindow$ = new Subject<{
    show: boolean;
    remoteAccess?: RemoteAccess;
  }>();
  private readonly _remoteAccessConnectionParameter$ = new ReplaySubject<ConnectionParameter>(1);
  private readonly blockedDuration: number = 5000;

  private connectedAgentId: string;
  private lastToast: HTMLIonToastElement;

  readonly showRemoteAccessWindow$ = this._showRemoteAccessWindow$.asObservable();
  readonly remoteAccessConnectionParameter$ = this._remoteAccessConnectionParameter$.asObservable();

  constructor(
    private http: HttpClient,
    private userMessageService: UserMessageService,
    private alertController: AlertController,
    private translateService: TranslateService
  ) {}

  async openSession(remoteAccess: RemoteAccess, requiredFeatureForWriteAccess: Feature): Promise<boolean> {
    if (remoteAccess.accessType === AccessType.VNC) {
      if (remoteAccess.agentId === this.connectedAgentId) {
        this.showRemoteAccess(remoteAccess);
        return true;
      }

      if (this.connectedAgentId) {
        const alertMessageResolved = await this.presentAlert();

        if (alertMessageResolved === 'show') {
          this.showRemoteAccess(remoteAccess);
          return true;
        }

        if (alertMessageResolved === 'close') {
          return true;
        }
      }
    }

    this.showRemoteAccess(remoteAccess);

    const connectionParameter = await firstValueFrom(
      this.http
        .get<ConnectionParameter>([environment.baseUrlApi, 'remoteaccess/connect', remoteAccess.agentId].join('/'))
        .pipe(
          catchError(() => {
            const statusError = new ConnectionParameter({
              status: ConnectionParameter.STATUS_ERROR,
            });
            return of(statusError);
          })
        )
    );

    if (connectionParameter.status === ConnectionParameter.STATUS_OK) {
      this.lastToast?.dismiss();
      this.connectedAgentId = remoteAccess.agentId;
      connectionParameter.requiredFeatureForWriteAccess = requiredFeatureForWriteAccess;
      this._remoteAccessConnectionParameter$.next(connectionParameter);
      return true;
    } else {
      this.pushConnectionErrorUserMessage();
      await this.closeSession(remoteAccess.agentId);
      return false;
    }
  }

  async closeSession(agentId?: string): Promise<void> {
    if (this.connectedAgentId || agentId) {
      const id = this.connectedAgentId || agentId;
      this.connectedAgentId = null;
      await this.insertRemoteAccessDisconnectedLog(id);
    }
    this._showRemoteAccessWindow$.next({ show: false });
    this._remoteAccessConnectionParameter$.next(null);
  }

  pushConnectionErrorUserMessage(): void {
    const userMessage = new UserMessage({
      message: 'user.message.remoteAccessConnection.error',
      icon: UserMessageIcon.failed,
      durationMs: this.blockedDuration,
      position: 'middle',
      color: UserMessageColor.red,
    });
    this.userMessageService.createSingleToast(userMessage).then((toast) => {
      this.lastToast?.dismiss();
      this.lastToast = toast;
      toast.present();
    });
  }

  private showRemoteAccess(remoteAccess: RemoteAccess): void {
    this._showRemoteAccessWindow$.next({ show: true, remoteAccess });
  }

  private async presentAlert(): Promise<'show' | 'replace' | 'close'> {
    return new Promise<'show' | 'replace' | 'close'>(async (resolve) => {
      const alert = await this.alertController.create({
        header: await this.getTranslatedString('user.message.remoteAccessConnection.alreadyRunning'),
        buttons: [
          {
            text: await this.getTranslatedString('general.term.show'),
            handler: () => {
              resolve('show');
            },
          },
          {
            text: await this.getTranslatedString('general.term.replace'),
            handler: () => {
              this.closeSession();
              setTimeout(() => resolve('replace'), 200);
            },
          },
          {
            text: await this.getTranslatedString('general.term.cancel'),
            role: 'cancel',
            handler: () => {
              resolve('close');
            },
          },
        ],
      });
      await alert.present();
    });
  }

  private async getTranslatedString(translateKey: string): Promise<string> {
    return lastValueFrom(this.translateService.get(translateKey));
  }

  private async insertRemoteAccessDisconnectedLog(agentId: string): Promise<void> {
    await firstValueFrom(this.http.post(`${environment.baseUrlApi}/remoteaccess/disconnect`, { agentId }));
  }
}
