<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.dismiss()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title class="ion-text-center" id="modal-popup-title"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="sis-chart-container">
  <ng-container *ngComponentOutlet="component; injector: isInModalInjector"></ng-container>
</ion-content>
