import { EnergyTrend } from 'src/app/maps/trenddata/energy-trend.model';

export class EnergyTrendAdapter {
  static adapt(items: any): EnergyTrend[] {
    const trends: EnergyTrend[] = [];

    if (items && items.length > 0) {
      let lastTimestampMs = new Date(items[0].timestamp).getTime();
      items.forEach((e: any) => {
        const timestampMsMaxExpectedValue = lastTimestampMs + 121_000;
        const timestampMs = new Date(e.timestamp).getTime();
        if (timestampMs > timestampMsMaxExpectedValue) {
          trends.push({ timestamp: new Date(timestampMsMaxExpectedValue) });
        }
        lastTimestampMs = timestampMs;

        trends.push({ energyKwInterval: e.energyKwInterval, timestamp: new Date(e.timestamp) });
      });
    }

    return trends;
  }
}
