import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { WebLink } from 'src/app/weblink-collection/domain/weblink-item.model';
import { WebLinkItemCategory } from 'src/app/weblink-collection/domain/weblink-item-category.enum';
import { WebLinkCollectionService } from 'src/app/weblink-collection/weblink-collection.service';
import { WeblinkEditModalComponent } from 'src/app/weblink-collection/weblink-edit-modal/weblink-edit-modal.component';

@Component({
  templateUrl: './weblink-collection.page.html',
  styleUrls: ['./weblink-collection.page.scss'],
})
export class WebLinkCollectionPage extends Unsubscriber implements OnInit {
  bigScreenMode: boolean;
  writePermission: boolean;
  readPermission: boolean;
  reorderingEnabled: boolean = false;
  tenantAlias: string;
  items: WebLink[];

  private webLinkItemOrders: Map<string, number>;

  categories = Object.values(WebLinkItemCategory).filter((value) => !isNaN(Number(value)));

  constructor(
    private screenSizeService: ScreenSizeService,
    private modalCtrl: ModalController,
    private destinationService: DestinationService,
    private webLinkCollectionService: WebLinkCollectionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.webLinkCollectionService.webLinks$.pipe(takeUntil(this.onDestroy$)).subscribe((webLinks) => {
      this.items = webLinks;
    });

    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => {
      this.bigScreenMode = bigScreenMode;
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.WEBLINKCOLLECTION, FeatureAccessLevel.WRITE))
      );

      this.readPermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.WEBLINKCOLLECTION, FeatureAccessLevel.READ))
      );
    });

    this.destinationService.selectedTenant$.pipe(takeUntil(this.onDestroy$)).subscribe((selectedTenant) => {
      this.tenantAlias = selectedTenant?.alias;
    });
  }

  async openEditModal(): Promise<void> {
    if (this.writePermission || this.readPermission) {
      const modal = await this.modalCtrl.create({
        component: WeblinkEditModalComponent,
        cssClass: 'sis-weblink-edit-modal',
        componentProps: {
          writePermission: this.writePermission,
        },
      });
      await modal.present();
    }
  }

  async onReorderClick(): Promise<void> {
    if (this.reorderingEnabled) {
      const reorderedItems: WebLink[] = this.items
        .filter((item) => this.webLinkItemOrders.get(item.guid) !== item.displayOrder)
        .map((item) => ({
          guid: item.guid,
          displayOrder: item.displayOrder,
          category: item.category,
          ownerGuid: item.ownerGuid,
        }));
      await this.webLinkCollectionService.insertOrUpdateItems(reorderedItems, 'update');
    } else {
      this.webLinkItemOrders = new Map<string, number>(this.items.map((item) => [item.guid, item.displayOrder]));
    }
    this.reorderingEnabled = !this.reorderingEnabled;
  }

  onReorderCancelClick(): void {
    this.items.forEach((item) => {
      item.displayOrder = this.webLinkItemOrders.get(item.guid);
    });
    this.webLinkCollectionService.resetWebLinks(this.items);
    this.reorderingEnabled = false;
  }
}
