import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { LastSlopeControlUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { LastSlopeControlAdapter } from 'src/app/last-slope-control/domain/last-slope-control.adapter';
import { LastSlopeControl } from 'src/app/last-slope-control/domain/last-slope-control.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LastSlopeControlService {
  private readonly baseRequestUrl: string = '/lastslopecontrol';
  private readonly basePostUrl: string = '/lastslopecontrol/update';
  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_LASTSLOPECONTROL, FeatureAccessLevel.READ);

  private readonly lastSlopeControlRequest$: Observable<LastSlopeControl[]> =
    this.destinationService.selectedTenantFeatures$.pipe(
      filter((features) => features.some((f) => f.hasMinimumRequirementFor(this.requiredFeature))),
      switchMap(() => this.http.get(`${environment.baseUrlApi}${this.baseRequestUrl}`)),
      map((data) => LastSlopeControlAdapter.adapt(data)),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

  private readonly lastSlopeControlUpdate$: Observable<LastSlopeControl[]> = this.eventBus
    .observe(LastSlopeControlUpdatedEvent)
    .pipe(
      withLatestFrom(this.lastSlopeControlRequest$),
      filter(([event, data]) => data.some((d) => d.guid === event.guid)),
      withLatestFrom(this.userSettingsService.userSettings$),
      map(([[event, data], userSettings]) => {
        if (event.updateSuccessful) {
          const updatedEntry = data.find((d) => d.guid === event.guid);
          updatedEntry.times = event.times;
          updatedEntry.activeTime = event.activeTime;
        }
        if (event.changedBy === userSettings.userGuid) {
          this.displayUserMessage(event.updateSuccessful);
        }

        return data;
      })
    );

  readonly lastSlopeControls$ = merge(this.lastSlopeControlRequest$, this.lastSlopeControlUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private eventBus: EventBusService,
    private http: HttpClient,
    private userMessageService: UserMessageService,
    private userSettingsService: UserSettingsService,
    private destinationService: DestinationService
  ) {}

  async updateLastSlopeControl(updatedLastSlopeControl: LastSlopeControl): Promise<void> {
    await firstValueFrom(
      this.http
        .post(`${environment.baseUrlApi}${this.basePostUrl}`, {
          guid: updatedLastSlopeControl.guid,
          zone: updatedLastSlopeControl.zone,
          times: updatedLastSlopeControl.times,
          activeTime: updatedLastSlopeControl.activeTime,
        })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.displayUserMessage(false);
            throw error;
          })
        )
    );
  }

  private displayUserMessage(updateSuccessful: boolean): void {
    const message = updateSuccessful ? 'general.phrase.saved' : 'general.phrase.saveFailed';
    const icon = updateSuccessful ? UserMessageIcon.success : UserMessageIcon.failed;
    const color = updateSuccessful ? UserMessageColor.green : UserMessageColor.red;
    const userMessage = new UserMessage({
      message,
      icon,
      durationMs: 2000,
      position: 'top',
      color,
    });
    this.userMessageService.presentToast(userMessage);
  }
}
