import 'src/app/core/utils/string-format';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DateTimeFormatPipe } from 'src/app/core/utils/date-time-format.pipe';

@Injectable({
  providedIn: 'root',
})
export class TimeFormatService {
  private static readonly shortTimeTranslateString = 'alarming.phrase.shortTime';
  private static readonly shortTimeHoursTranslateString = 'alarming.phrase.shortTime';
  private static readonly minTranslateString = 'alarming.abbr.minute';
  private static readonly hourTranslateString = 'alarming.abbr.hour';
  private static readonly dayTranslateString = 'alarming.term.day';
  private static readonly weekTranslateString = 'alarming.term.week';
  private static readonly monthTranslateString = 'alarming.term.month';
  private static readonly yearTranslateString = 'alarming.term.year';
  private static readonly daysTranslateString = 'alarming.term.days';
  private static readonly weeksTranslateString = 'alarming.term.weeks';
  private static readonly monthsTranslateString = 'alarming.term.months';
  private static readonly yearsTranslateString = 'alarming.term.years';

  private readonly msPerMinute = 60 * 1000;
  private readonly msPerHour = this.msPerMinute * 60;
  private readonly msPerDay = this.msPerHour * 24;
  private readonly msPerWeek = this.msPerDay * 7;
  private readonly msPerMonth = this.msPerDay * 30;
  private readonly msPerYear = this.msPerDay * 365;

  private readonly translatedStrings$ = this.translateService
    .stream([
      TimeFormatService.shortTimeTranslateString,
      TimeFormatService.shortTimeHoursTranslateString,
      TimeFormatService.minTranslateString,
      TimeFormatService.hourTranslateString,
      TimeFormatService.dayTranslateString,
      TimeFormatService.daysTranslateString,
      TimeFormatService.weekTranslateString,
      TimeFormatService.weeksTranslateString,
      TimeFormatService.monthTranslateString,
      TimeFormatService.monthsTranslateString,
      TimeFormatService.yearTranslateString,
      TimeFormatService.yearsTranslateString,
    ])
    .pipe(
      map((res) => ({
        shortTimeText: res[TimeFormatService.shortTimeTranslateString],
        shortTimeHoursText: res[TimeFormatService.shortTimeHoursTranslateString],
        minText: res[TimeFormatService.minTranslateString],
        hourText: res[TimeFormatService.hourTranslateString],
        dayText: res[TimeFormatService.dayTranslateString],
        daysText: res[TimeFormatService.daysTranslateString],
        weekText: res[TimeFormatService.weekTranslateString],
        weeksText: res[TimeFormatService.weeksTranslateString],
        monthText: res[TimeFormatService.monthTranslateString],
        monthsText: res[TimeFormatService.monthsTranslateString],
        yearText: res[TimeFormatService.yearTranslateString],
        yearsText: res[TimeFormatService.yearsTranslateString],
      })),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  constructor(private dateTimeFormatPipe: DateTimeFormatPipe, private translateService: TranslateService) {}

  private transformToRelativeTime(
    date: Date,
    translatedStrings: {
      shortTimeText: string;
      shortTimeHoursText: string;
      minText: string;
      hourText: string;
      dayText: string;
      daysText: string;
      weekText: string;
      weeksText: string;
      monthText: string;
      monthsText: string;
      yearText: string;
      yearsText: string;
    }
  ): string {
    if (date) {
      const current = new Date().valueOf();
      const inputMs = date.valueOf();

      const elapsed = current - inputMs;

      let retValue: number;
      let retValueHH = 0;
      let retValueMin = 0;
      let retText: string;
      let retTextHH: string;
      let retTextMin: string;

      let isHours = false;

      if (elapsed < this.msPerMinute) {
        retValue = 0;
        retText = translatedStrings.minText;
      } else if (elapsed < this.msPerHour) {
        retValue = Math.floor(elapsed / this.msPerMinute);
        retText = translatedStrings.minText;
      } else if (elapsed < this.msPerDay) {
        isHours = true;
        retValueHH = Math.floor(elapsed / this.msPerHour);
        retValueMin = Math.floor(elapsed / this.msPerMinute) - retValueHH * 60;
        retTextMin = translatedStrings.minText;
        retTextHH = translatedStrings.hourText;
      } else if (elapsed < this.msPerWeek) {
        const value = Math.floor(elapsed / this.msPerDay);
        retValue = value;
        retText = retValue > 1 ? translatedStrings.daysText : translatedStrings.dayText;
      } else if (elapsed < this.msPerMonth) {
        const value = Math.floor(elapsed / this.msPerWeek);
        retValue = value;
        retText = retValue > 1 ? translatedStrings.weeksText : translatedStrings.weekText;
      } else if (elapsed < this.msPerYear) {
        const value = Math.floor(elapsed / this.msPerMonth);
        retValue = value;
        retText = retValue > 1 ? translatedStrings.monthsText : translatedStrings.monthText;
      } else {
        const value = Math.floor(elapsed / this.msPerYear);
        retValue = value;
        retText = retValue > 1 ? translatedStrings.yearsText : translatedStrings.yearText;
      }

      if (!isHours) {
        return translatedStrings.shortTimeText.format(retValue.toString(), retText);
      } else {
        return translatedStrings.shortTimeHoursText.format(
          retValueHH.toString(),
          retTextHH,
          retValueMin.toString(),
          retTextMin
        );
      }
    }
  }

  format(date: Date, timeFormat: number): Observable<string> {
    if (timeFormat === 2) {
      return this.translatedStrings$.pipe(
        map((translatedStrings) => this.transformToRelativeTime(date, translatedStrings))
      );
    }

    return of(this.dateTimeFormatPipe.transform(date));
  }
}
