import { Injectable, Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusEvent } from 'src/app/core/eventbus/events';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private subjects$ = new Map<string, Subject<BusEvent>>();

  publish<T extends BusEvent>(event: T): void {
    const className = this.getInstanceName(event);

    this.getSubject(className).next(event);
  }

  observe<T extends BusEvent>(typeProvider: Type<T>): Observable<T> {
    const instance = new typeProvider();

    const className = this.getInstanceName(instance);

    const subject$ = this.getSubject(className);

    return subject$.pipe(map((event) => event as T));
  }

  private getSubject<T extends BusEvent>(typeId: string): Subject<BusEvent> {
    let subject$: Subject<BusEvent> = this.subjects$[typeId];
    if (!subject$) {
      subject$ = new Subject<T>();
      this.subjects$[typeId] = subject$;
    }
    return subject$;
  }

  private getInstanceName(instance: object): string {
    return Reflect.getMetadata(BusEvent.nameSymbol, instance);
  }
}
