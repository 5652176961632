import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MeteoInfoUpdatedEvent, STnetStatusUpdatedEvent } from 'src/app/core/eventbus/events';
import { STnetExportResponse } from 'src/app/core/stnet/stnet-export-response.model';
import { STnetStatus } from 'src/app/core/stnet/stnet-status.model';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { MeteoInfoAdapter } from 'src/app/meteo-info/domain/meteo-info.adapter';
import { MeteoInfo } from 'src/app/meteo-info/domain/meteo-info.model';
import { MeteoInfoItem } from 'src/app/meteo-info/domain/meteo-info-item.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MeteoInfoService {
  private readonly baseRequestUrl: string = '/meteoinfo/get';
  private readonly basePostUrl: string = '/meteoinfo/post';
  private readonly baseSTnetUrl: string = '/stnet/export/snowdata';
  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_METEOINFO, FeatureAccessLevel.READ);

  private readonly stnetStatusArrayUpdate$ = new Subject<STnetStatus[]>();

  private readonly meteoInfo$: Observable<MeteoInfo> = this.destinationService.selectedTenantFeatures$.pipe(
    distinctUntilChanged(),
    filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
    switchMap(() => this.http.get(`${environment.baseUrlApi}${this.baseRequestUrl}`)),
    map((data) => MeteoInfoAdapter.adapt(data)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly meteoInfoItems: Observable<MeteoInfoItem[]> = merge(
    this.meteoInfo$.pipe(map((meteoInfo) => meteoInfo.meteoInfoItems)),
    this.eventBus.observe(MeteoInfoUpdatedEvent).pipe(
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([updatedEvent, tenant]) => updatedEvent?.tenantGuid === tenant?.guid.toString()),
      withLatestFrom(this.meteoInfo$, this.userSettingsService.userSettings$),
      map(([[updatedEvent], meteoInfo, userSettings]) => {
        meteoInfo.meteoInfoItems.forEach((meteoInfoItem) => {
          const updatedEventItem = updatedEvent.meteoInfoItems.find((u) => u.guid === meteoInfoItem.guid);
          if (updatedEventItem) {
            meteoInfoItem.freshSnow = updatedEventItem.freshSnow;
            meteoInfoItem.latestSnowfallUtc = updatedEventItem.latestSnowfallUtc;
            meteoInfoItem.lastChange = updatedEventItem.lastChange;
            meteoInfoItem.temperature = updatedEventItem.temperature;
            meteoInfoItem.windSpeed = updatedEventItem.windSpeed;
            meteoInfoItem.snowDepth = updatedEventItem.snowDepth;
            meteoInfoItem.avalancheDangerLevel = updatedEventItem.avalancheDangerLevel;
            meteoInfoItem.snowConditionId = updatedEventItem.snowConditionId;
            meteoInfoItem.slopeConditionId = updatedEventItem.slopeConditionId;
            meteoInfoItem.changedByEmail = updatedEventItem.changedByEmail;
          }
        });

        if (updatedEvent?.changedBy === userSettings.userGuid) {
          const userMessage = updatedEvent.updateSuccessful
            ? new UserMessage({
                message: 'general.phrase.saved',
                icon: UserMessageIcon.success,
                durationMs: 2000,
                position: 'top',
                color: UserMessageColor.green,
              })
            : new UserMessage({
                message: 'general.phrase.saveFailed',
                icon: UserMessageIcon.failed,
                durationMs: 2000,
                position: 'top',
                color: UserMessageColor.red,
              });

          this.userMessageService.presentToast(userMessage);
        }

        return meteoInfo.meteoInfoItems;
      })
    )
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly stnetStatusArray$: Observable<STnetStatus[]> = merge(
    this.meteoInfo$.pipe(map((meteoInfo) => meteoInfo.stnetStatusArray)),
    this.stnetStatusArrayUpdate$,
    this.eventBus.observe(STnetStatusUpdatedEvent).pipe(
      withLatestFrom(
        this.destinationService.selectedTenant$,
        this.meteoInfo$.pipe(map((meteoInfo) => meteoInfo.stnetStatusArray))
      ),
      filter(
        ([stnetUpdate, tenant]) =>
          stnetUpdate?.status?.featureId === FeatureId.SISMEDIA_METEOINFO && stnetUpdate?.tenantGuid === tenant?.guid
      ),
      map(([stnetUpdate, , stnetStatusArray]) => {
        const updatedStatus = stnetStatusArray.find((status) => status.stationId === stnetUpdate.status.stationId);
        if (updatedStatus) {
          Object.assign(updatedStatus, stnetUpdate.status);
        } else {
          stnetStatusArray.push(stnetUpdate.status);
        }
        return stnetStatusArray;
      })
    )
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private eventBus: EventBusService,
    private http: HttpClient,
    private userMessageService: UserMessageService,
    private destinationService: DestinationService,
    private userSettingsService: UserSettingsService
  ) {}

  async saveMeteoInfoItems(meteoInfoItems: MeteoInfoItem[]): Promise<boolean> {
    const postData = meteoInfoItems.map((item) => ({
      guid: item.guid,
      temperature: item.temperature,
      windSpeed: item.windSpeed,
      latestSnowfallUtc: item.latestSnowfallUtc,
      snowDepth: item.snowDepth,
      freshSnow: item.freshSnow,
      avalancheDangerLevel: item.avalancheDangerLevel,
      snowConditionId: item.snowConditionId,
      slopeConditionId: item.slopeConditionId,
    }));

    return firstValueFrom(
      this.http.post(`${environment.baseUrlApi}${this.basePostUrl}`, postData).pipe(
        map(() => true),
        catchError(() => {
          const translateKey = 'general.phrase.saveFailed';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.failed,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);
          return of(false);
        })
      )
    );
  }

  async exportSTnetMeteoInfoItems(): Promise<void> {
    const exportResponses = await firstValueFrom(
      this.http.get<STnetExportResponse[]>(`${environment.baseUrlApi}${this.baseSTnetUrl}`).pipe(
        catchError(() => {
          const translateKey = 'meteoInfo.phrase.stnet.export.unsuccessful';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.failed,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.red,
          });
          this.userMessageService.presentToast(userMessage);
          return of<STnetExportResponse[]>([]);
        })
      )
    );

    const successResponses = exportResponses?.filter((response) => response.success);

    if (successResponses?.length && exportResponses.every((response) => response.success)) {
      const translateKey = 'stnet.phrase.export.successful';
      const userMessage = new UserMessage({
        message: translateKey,
        icon: UserMessageIcon.success,
        durationMs: 2000,
        position: 'top',
        color: UserMessageColor.green,
      });
      this.userMessageService.presentToast(userMessage);
    } else {
      const translateKey = 'meteoInfo.phrase.stnet.export.unsuccessful';
      const userMessage = new UserMessage({
        message: translateKey,
        icon: UserMessageIcon.failed,
        durationMs: 2000,
        position: 'top',
        color: UserMessageColor.red,
      });
      this.userMessageService.presentToast(userMessage);
    }

    if (successResponses) {
      this.stnetStatusArrayUpdate$.next(successResponses.map((response) => response.requestStatus));
    }
  }
}
