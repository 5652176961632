import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { WebLinkCollectionUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { WebLinkAdapter } from 'src/app/weblink-collection/domain/weblink-item.adapter';
import { WebLink } from 'src/app/weblink-collection/domain/weblink-item.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebLinkCollectionService {
  private readonly requiredFeature = new Feature(FeatureId.WEBLINKCOLLECTION, FeatureAccessLevel.READ);
  private readonly resetSubject = new Subject<WebLink[]>();

  private readonly webLinkRequest$: Observable<WebLink[]> = this.destinationService.selectedTenantFeatures$.pipe(
    filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
    switchMap(() => this.httpClient.get(`${environment.baseUrlApi}/weblinks`)),
    map((items) => WebLinkAdapter.adapt(items)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly webLinkCollectionUpdated$: Observable<WebLink[]> = this.eventBus
    .observe(WebLinkCollectionUpdatedEvent)
    .pipe(
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([event, tenant]) => event.tenantGuid === tenant.guid),
      withLatestFrom(this.webLinkRequest$, this.userSettingsService.userSettings$),
      map(([[event], currentWebLinks, userSettings]) => {
        if (event.updateSuccessful) {
          if (event.operation === 'insert') {
            const newWebLinks = WebLinkAdapter.adapt(event.webLinks);
            currentWebLinks.push(newWebLinks[0]);
          } else if (event.operation === 'update') {
            event.webLinks.forEach((updatedWebLink) => {
              const oldWebLink = currentWebLinks.find((webLink) => webLink.guid === updatedWebLink.guid);
              oldWebLink ? Object.assign(oldWebLink, updatedWebLink) : currentWebLinks.push(updatedWebLink);
            });
          } else if (event.operation === 'delete') {
            const webLinkToBeDeleted = currentWebLinks.find((webLink) => webLink.guid === event.webLinks[0].guid);
            currentWebLinks.splice(currentWebLinks.indexOf(webLinkToBeDeleted), 1);
          }
        }

        if (event.changedBy === userSettings.userGuid) {
          this.displayUserMessage(event.updateSuccessful, event.operation);
        }
        return currentWebLinks;
      })
    );

  readonly webLinks$ = merge(this.webLinkRequest$, this.webLinkCollectionUpdated$, this.resetSubject).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private httpClient: HttpClient,
    private destinationService: DestinationService,
    private http: HttpClient,
    private userMessageService: UserMessageService,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService
  ) {}

  async insertOrUpdateItems(webLinkPost: WebLink[], operation: string): Promise<void> {
    if (!webLinkPost?.length) {
      return;
    }

    try {
      await firstValueFrom(
        this.http.post(`${environment.baseUrlApi}/weblinks`, {
          webLinks: webLinkPost,
          operation,
        })
      );
    } catch {
      this.presentToast(false);
    }
  }

  private presentToast(success: boolean, deleted: boolean = false, assetName: string = null): void {
    const message = success
      ? deleted
        ? ["'", assetName, "' ", 'sismedia.phrase.assetDeleted']
        : 'general.phrase.saved'
      : 'general.phrase.saveFailed';
    const color = success ? UserMessageColor.green : UserMessageColor.red;
    const icon = success ? UserMessageIcon.success : UserMessageIcon.failed;
    this.userMessageService.presentToast(new UserMessage({ message, icon, durationMs: 2000, position: 'top', color }));
  }

  private displayUserMessage(updateSuccessful: boolean, operation: string): void {
    const message =
      operation === 'insert' && updateSuccessful
        ? 'weblinkcollection.phrase.entrySavedSuccess'
        : operation === 'update' && updateSuccessful
        ? 'weblinkcollection.phrase.entryUpdatedSucess'
        : operation === 'delete' && updateSuccessful
        ? 'weblinkcollection.phrase.entryDeletedSuccess'
        : operation === 'insert'
        ? 'weblinkcollection.phrase.entrySavedFailed'
        : operation === 'update'
        ? 'weblinkcollection.phrase.entryUpdatedFailed'
        : operation === 'delete'
        ? 'weblinkcollection.phrase.entryDeletedFailed'
        : 'general.phrase.apiError';

    const icon = updateSuccessful ? UserMessageIcon.success : UserMessageIcon.failed;
    const color = updateSuccessful ? UserMessageColor.green : UserMessageColor.red;
    const userMessage = new UserMessage({
      message,
      icon,
      durationMs: 2000,
      position: 'top',
      color,
    });
    this.userMessageService.presentToast(userMessage);
  }

  resetWebLinks(weblinks: WebLink[]): void {
    this.resetSubject.next(weblinks);
  }
}
