import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagedAsyncIterableIterator } from '@azure/core-paging';
import { BlobServiceClient } from '@azure/storage-blob';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private readonly blobServiceClient = new BlobServiceClient(environment.baseUrlCockpitBlobStorage);
  private readonly containerClient = this.blobServiceClient.getContainerClient('news');
  private readonly prefix = 'newsFiles/';

  private newsViewedUpdated = false;

  readonly newsFileNames$: Observable<string[]> = this.asyncToObservable(
    this.containerClient.listBlobsFlat({ prefix: 'newsFiles/' })
  ).pipe(
    map((blobItems) =>
      blobItems.map((blobItem) => blobItem.name.replace(this.prefix, '')).sort((a, b) => -a.localeCompare(b))
    ),
    shareReplay(1)
  );

  readonly unreadNewsFileNames$: Observable<string[]> = combineLatest([
    this.newsFileNames$,
    this.userSettingsService.userSettings$,
  ]).pipe(
    map(([fileNames, userSettings]) => {
      if (userSettings.lastNewsViewed == null) {
        return fileNames;
      }

      const year = userSettings.lastNewsViewed.getFullYear();
      const month = (userSettings.lastNewsViewed.getMonth() + 1).toString().padStart(2, '0');
      const day = userSettings.lastNewsViewed.getDate().toString().padStart(2, '0');
      const lastNewsViewed = `${year}-${month}-${day}`;

      return fileNames.filter((fileName) => fileName.split('_')[0].localeCompare(lastNewsViewed) > 0);
    }),
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly hasUnreadNews$: Observable<boolean> = this.unreadNewsFileNames$.pipe(
    map((fileNames) => fileNames.length > 0)
  );

  constructor(private userSettingsService: UserSettingsService, private httpClient: HttpClient) {}

  getFileText(fileName: string): Observable<string> {
    return this.httpClient.get(`${environment.baseUrlCockpitBlobStorage}/news/${this.prefix}${fileName}`, {
      responseType: 'text',
    });
  }

  async updateNewsViewed(): Promise<void> {
    if (!this.newsViewedUpdated) {
      await this.userSettingsService.updateUserSettings({ newsViewed: true });
      this.newsViewedUpdated = true;
    }
  }

  private asyncToObservable<T, TService>(iterable: PagedAsyncIterableIterator<T, TService>): Observable<T[]> {
    return new Observable<T[]>(
      (subscriber) =>
        void (async () => {
          try {
            const items: T[] = [];
            for await (const item of iterable as AsyncIterable<T>) {
              if (subscriber.closed) {
                return;
              }
              items.push(item);
            }
            subscriber.next(items);
            subscriber.complete();
          } catch (e) {
            subscriber.error(e);
          }
        })()
    );
  }
}
