import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import {
  EmailDeliveryRecipientUpsertedEvent,
  EmailDeliverySentEvent,
  HtmlToPdfGeneratedEvent,
} from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { Attachment } from 'src/app/email-delivery/domain/attachment.model';
import { EmailDeliveryAdapter } from 'src/app/email-delivery/domain/email-delivery.adapter';
import { EmailDelivery } from 'src/app/email-delivery/domain/email-delivery.model';
import { Recipient } from 'src/app/email-delivery/domain/recipient.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailDeliveryService {
  private readonly postGeneratePdfUrl: string = '/emaildelivery/pdfcreator';
  private readonly postSendEmailUrl: string = '/emaildelivery/post';
  private readonly postRecipientUpsertUrl: string = '/emaildelivery/receipientupsert';
  private readonly getUrl: string = '/emaildelivery/get';
  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_EMAILDELIVERY, FeatureAccessLevel.READ);

  private readonly emailDeliveryRequest$: Observable<EmailDelivery> =
    this.destinationService.selectedTenantFeatures$.pipe(
      filter((features) => features.some((f) => f.hasMinimumRequirementFor(this.requiredFeature))),
      switchMap(() => this.httpClient.get(`${environment.baseUrlApi}${this.getUrl}`)),
      map((data) => EmailDeliveryAdapter.adapt(data)),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

  private readonly emailUpdate$: Observable<EmailDelivery> = this.eventBus.observe(EmailDeliverySentEvent).pipe(
    withLatestFrom(this.destinationService.selectedTenant$),
    filter(([event, tenant]) => event.tenantGuid === tenant.guid),
    withLatestFrom(this.emailDeliveryRequest$),
    map(([[event], data]) => {
      this.presentEmailDeliveryToast(event.successful);
      if (event.successful) {
        data.text = event.emailText;
        data.subject = event.subjectText;
        data.lastSentAttachment = data.attachments.find((attachment) => attachment.guid === event.attachmentGuid);
      }
      return data;
    })
  );

  readonly emailDelivery$ = merge(this.emailDeliveryRequest$, this.emailUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly recipientsUpdate$: Observable<Recipient[]> = this.eventBus
    .observe(EmailDeliveryRecipientUpsertedEvent)
    .pipe(
      withLatestFrom(this.emailDelivery$.pipe(map((emailDelivery) => emailDelivery?.recipients))),
      withLatestFrom(this.userSettingsService.userSettings$),
      map(([[updatedEvent, data], userSettings]) => {
        if (updatedEvent.changedBy === userSettings.userGuid) {
          this.presentEmailRecipientUpdateToast(updatedEvent.successful);
        }
        if (updatedEvent.successful) {
          const updatedRecipient = data.find((d) => d.guid === updatedEvent.recipientGuid);
          if (updatedEvent.deleted) {
            data.splice(data.indexOf(updatedRecipient), 1);
            return data;
          }

          if (updatedRecipient) {
            updatedRecipient.email = updatedEvent.email;
            updatedRecipient.location = updatedEvent.location;
            updatedRecipient.name = updatedEvent.name;
          } else {
            data.push({
              guid: updatedEvent.recipientGuid,
              name: updatedEvent.name,
              location: updatedEvent.location,
              email: updatedEvent.email,
            });
          }
        }
        return data;
      })
    );

  readonly recipients$ = merge(
    this.emailDelivery$.pipe(map((emailDelivery) => emailDelivery?.recipients)),
    this.recipientsUpdate$
  ).pipe(
    map((recipient) => recipient.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly pdfGenerated$: Observable<Attachment> = this.eventBus.observe(HtmlToPdfGeneratedEvent).pipe(
    withLatestFrom(this.destinationService.selectedTenant$, this.userSettingsService.userSettings$),
    filter(
      ([event, tenant, user]) => !!tenant && event.tenantGuid === tenant.guid && event.changedBy === user.userGuid
    ),
    map(([event]) => {
      this.presentPdfGeneratedToast(event.successful);
      return event;
    }),
    switchMap((event) => {
      if (!event.successful) {
        return of(null);
      }
      return this.emailDelivery$.pipe(
        take(1),
        map((emailDelivery) => emailDelivery?.attachments?.find((attachment) => attachment.pdfUrl === event.pdfUrl)),
        switchMap((attachment) => {
          if (!attachment) {
            return of(null);
          }
          return this.checkAttachmentOutdated(attachment.pdfUrl).pipe(
            map((outdated) => {
              attachment.outdated = outdated;
              return attachment;
            })
          );
        })
      );
    })
  );

  constructor(
    private httpClient: HttpClient,
    private destinationService: DestinationService,
    private userSettingsService: UserSettingsService,
    private userMessageService: UserMessageService,
    private eventBus: EventBusService
  ) {}

  async postGeneratePdf(attachmentGuid: string): Promise<boolean> {
    return firstValueFrom(
      this.httpClient
        .post(
          `${environment.baseUrlApi}${this.postGeneratePdfUrl}`,
          { attachmentGuid },
          { headers: { 'Content-Type': 'application/json' } }
        )
        .pipe(
          map(() => true),
          catchError(() => {
            this.presentPdfGeneratedToast(false);
            return of(false);
          })
        )
    );
  }

  async postSendEmail(
    attachmentGuid: string,
    emailText: string,
    subjectText: string,
    recipient?: string
  ): Promise<boolean> {
    return firstValueFrom(
      this.httpClient
        .post(
          `${environment.baseUrlApi}${this.postSendEmailUrl}`,
          { attachmentGuid, text: emailText, subject: subjectText, testMailRecipient: recipient },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .pipe(
          map(() => true),
          catchError(() => {
            this.presentEmailDeliveryToast(false);
            return of(false);
          })
        )
    );
  }

  async postRecipientUpsert(recipient: Recipient, deleteRecipient: boolean): Promise<boolean> {
    return firstValueFrom(
      this.httpClient
        .post(
          `${environment.baseUrlApi}${this.postRecipientUpsertUrl}`,
          {
            recipientGuid: recipient.guid,
            name: recipient.name,
            email: recipient.email,
            location: recipient.location,
            delete: deleteRecipient,
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .pipe(
          map(() => true),
          catchError(() => {
            this.presentEmailRecipientUpdateToast(false);
            return of(false);
          })
        )
    );
  }

  checkAttachmentOutdated(url: string): Observable<boolean> {
    return this.httpClient.head(url, { observe: 'response' }).pipe(
      map((response) => {
        const lastModified = response.headers.get('Last-Modified');
        if (lastModified != null) {
          return Date.parse(lastModified) + 24 * 60 * 60 * 1000 < Date.now();
        }
        return true;
      }),
      catchError(() => of(true))
    );
  }

  private presentEmailDeliveryToast(success: boolean): void {
    const message = success
      ? 'emaildelivery.phrase.emailDeliverySuccessful'
      : 'emaildelivery.phrase.emailDeliveryFailed';
    this.presentToast(success, message);
  }

  private presentPdfGeneratedToast(success: boolean): void {
    const message = success
      ? 'emaildelivery.phrase.pdfGenerationSuccessful'
      : 'emaildelivery.phrase.pdfGenerationFailed';
    this.presentToast(success, message);
  }

  private presentEmailRecipientUpdateToast(success: boolean): void {
    const message = success ? 'general.phrase.saved' : 'general.phrase.saveFailed';
    this.presentToast(success, message);
  }

  private presentToast(success: boolean, message: string): void {
    const color = success ? UserMessageColor.green : UserMessageColor.red;
    const icon = success ? UserMessageIcon.success : UserMessageIcon.failed;
    this.userMessageService.presentToast(new UserMessage({ message, icon, durationMs: 2000, position: 'top', color }));
  }
}
