import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Options } from 'highcharts';
import { merge } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayEcoModeTrendService } from 'src/app/maps/trenddata/ropeway-ecomode-trend.service';
import { ChartBase } from 'src/app/maps/widgets/charts/chart-base';
import { ChartSettings } from 'src/app/maps/widgets/charts/chart-settings.model';
import { ChartSettingsService } from 'src/app/maps/widgets/charts/chart-settings.service';
import { ChartSelector } from 'src/app/maps/widgets/charts/ropeway-chart/chart-selector.model';
import { EcoModeActivatedCockpitHandler } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-activated-cockpit-handler';
import { EcoModeActualSpeedHandler } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-actual-speed-handler';
import { EcoModeChartHandler } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-chart-handler';
import { EcoModeEnabledPLCHandler } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-enabled-plc-handler';
import { EcoModeEnergyHandler } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-energy-handler';
import { EcoModePeoplePerMinuteHandler } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-people-per-minute-handler';
import { EcoModeTargetSpeedHandler } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-target-speed-handler';
import { EcoModeVehicleCountHandler } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-vehicle-count-handler';
import { EcoModeWorkloadHandler } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-workload-handler';

@Component({
  selector: 'sis-ecomode-chart',
  templateUrl: './ecomode-chart.component.html',
  styleUrls: ['./ecomode-chart.component.scss'],
})
export class EcoModeChartComponent extends ChartBase implements OnInit {
  private static readonly onTranslateString = 'general.term.on';
  private static readonly offTranslateString = 'general.term.off';

  private readonly ropeway$ = this.chartAdapter.chartReady$.pipe(
    take(1),
    switchMap(() => this.selectedMapElementService.selectedRopeway$)
  );

  private readonly yAxisUnits = ['m/s', '%', 'kw', 'ppm', 'bool'];
  private onText: string;
  private offText: string;

  dataSelectionOpen: boolean = true;
  chartSelectors: ChartSelector[] = [];
  private availableCharts: EcoModeChartHandler[] = [];

  constructor(
    private modalCtrl: ModalController,
    private chartSettingsService: ChartSettingsService,
    private selectedMapElementService: SelectedMapElementService,
    private ropewayEcoModeTrendService: RopewayEcoModeTrendService,
    timeFormatService: TimeFormatService,
    private translateService: TranslateService,
    chartSettings: ChartSettings,
    private cdr: ChangeDetectorRef
  ) {
    super(translateService, chartSettings?.inModal, timeFormatService);

    this.minY = null;
  }

  ngOnInit(): void {
    this.ropeway$
      .pipe(
        distinctUntilChanged(),
        filter((ropeway) => !!ropeway),
        switchMap((ropeway) => {
          this.title = ropeway.alias;
          this.initChart(ropeway);
          this.chartAdapter.showLoading();

          this.availableCharts = [
            new EcoModeActualSpeedHandler(
              this.chartSettingsService,
              this.chartAdapter,
              this.ropewayEcoModeTrendService,
              this.translateService,
              ropeway,
              this.yAxisUnits
            ),
            new EcoModeTargetSpeedHandler(
              this.chartSettingsService,
              this.chartAdapter,
              this.ropewayEcoModeTrendService,
              this.translateService,
              ropeway,
              this.yAxisUnits
            ),
            new EcoModeEnergyHandler(
              this.chartSettingsService,
              this.chartAdapter,
              this.ropewayEcoModeTrendService,
              this.translateService,
              ropeway,
              this.yAxisUnits
            ),
            new EcoModeActivatedCockpitHandler(
              this.chartSettingsService,
              this.chartAdapter,
              this.ropewayEcoModeTrendService,
              this.translateService,
              ropeway,
              this.yAxisUnits
            ),
            new EcoModeEnabledPLCHandler(
              this.chartSettingsService,
              this.chartAdapter,
              this.ropewayEcoModeTrendService,
              this.translateService,
              ropeway,
              this.yAxisUnits
            ),
            new EcoModePeoplePerMinuteHandler(
              this.chartSettingsService,
              this.chartAdapter,
              this.ropewayEcoModeTrendService,
              this.translateService,
              ropeway,
              this.yAxisUnits
            ),
            new EcoModeVehicleCountHandler(
              this.chartSettingsService,
              this.chartAdapter,
              this.ropewayEcoModeTrendService,
              this.translateService,
              ropeway,
              this.yAxisUnits
            ),
            new EcoModeWorkloadHandler(
              this.chartSettingsService,
              this.chartAdapter,
              this.ropewayEcoModeTrendService,
              this.translateService,
              ropeway,
              this.yAxisUnits
            ),
          ];
          this.chartSelectors = this.availableCharts.map((c) => c.getChartSelectors()).flat(1);

          return merge(...this.availableCharts.map((c) => c.seriesObservable()));
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe((seriesDatas: Array<{ seriesId: string; data: number[][] }>) => {
        seriesDatas.forEach((series) => {
          this.updateChart(series.seriesId, series.data);
        });
      });
  }

  async toggleSetting(ropewaySisId: string, setting: string): Promise<void> {
    await this.chartSettingsService.toggleRopewaySetting(ropewaySisId, setting);
  }

  private initChart(ropeway: Ropeway): void {
    this.deviceAlias = ropeway.alias;
    this.updateTitle();

    this.updateModalTitle();

    this.chartAdapter.showLoading();
    this.chartAdapter.removeAllSeries();
    this.chartAdapter.reflowChart(this.onDestroy$);
    this.cdr.detectChanges();
  }

  private updateChart(seriesId: string, data: number[][]): void {
    this.chartAdapter.hideLoading();
    this.chartAdapter.setSeriesData(seriesId, data);

    if (!this.isZoomed) {
      this.chartAdapter.setXAxisExtremes(0, null, null);
    }

    if (this.isZoomed && this.isZoomedToEnd) {
      this.chartAdapter.setXAxisExtremes(0, this.zoomMin, null);
    }
  }

  async toggleForecast(e: PointerEvent): Promise<void> {
    e.stopPropagation();
    this.chartSettingsService.toggleForecast();
  }

  resetZoom(): void {
    this.chartAdapter.zoomOut();
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected override translateTexts(translateService: TranslateService): void {
    translateService
      .get([EcoModeChartComponent.onTranslateString, EcoModeChartComponent.offTranslateString])
      .pipe(take(1))
      .subscribe((res) => {
        this.onText = res[EcoModeChartComponent.onTranslateString];
        this.offText = res[EcoModeChartComponent.offTranslateString];
      });
  }

  protected getChartOptions(): Options {
    const onText = this.onText;
    const offText = this.offText;
    return {
      time: {
        useUTC: false,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      legend: {
        enabled: true,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
        tickInterval: 1 * 60 * 60 * 1000, // 1 hours in ms
        events: {
          setExtremes: this.handleSetExtremesEvent.bind(this),
        },
      },
      chart: {
        zoomType: 'xy',
        panKey: 'ctrl',
        alignTicks: false,
        panning: {
          enabled: true,
        },
        animation: false,
        style: {
          fontFamily: 'Myriad Pro',
        },
        resetZoomButton: {
          theme: {
            stroke: 'var(--ion-color-primary)',
            height: 8,
          },
          position: {
            y: -10000,
          },
        },
      },
      yAxis: [
        {
          title: {
            text: `[m/s]`,
            align: 'high',
            offset: 10,
            rotation: 0,
            y: -15,
            style: {
              fontSize: '12px',
            },
          },
          lineWidth: 1,
          tickInterval: 1,
          labels: {
            style: {
              fontSize: '12px',
            },
          },
          min: 0,
          softMax: 6,
          gridLineWidth: 0,
          showEmpty: false,
        },
        {
          title: {
            text: `[%]`,
            align: 'high',
            offset: 25,
            rotation: 0,
            y: -15,
            x: 10,
            style: {
              fontSize: '12px',
            },
          },
          lineWidth: 1,
          labels: {
            y: 5,
            style: {
              fontSize: '12px',
            },
          },
          softMin: 0,
          softMax: 100,
          maxPadding: 0.08,
          endOnTick: false,
          gridLineWidth: 0,
          tickInterval: 20,
          showEmpty: false,
        },
        {
          title: {
            text: `[kW]`,
            align: 'high',
            offset: 15,
            rotation: 0,
            y: -15,
            x: 7,
            style: {
              fontSize: '12px',
            },
          },
          lineWidth: 1,
          tickInterval: 20,
          labels: {
            style: {
              fontSize: '12px',
            },
          },
          min: 0,
          softMax: 100,
          gridLineWidth: 0,
          showEmpty: false,
        },
        {
          title: {
            text: `[ppm]`,
            align: 'high',
            offset: 10,
            rotation: 0,
            y: -15,
            x: 7,
            style: {
              fontSize: '12px',
            },
          },
          lineWidth: 1,
          tickInterval: 5,
          labels: {
            style: {
              fontSize: '12px',
            },
          },
          min: 0,
          softMax: 30,
          gridLineWidth: 0,
          showEmpty: false,
        },
        {
          title: {
            text: ``,
            align: 'high',
            offset: 20,
            rotation: 0,
            y: -15,
            style: {
              fontSize: '12px',
            },
          },
          lineWidth: 1,
          tickInterval: 1,
          labels: {
            style: {
              fontSize: '12px',
            },
            formatter() {
              switch (this.value) {
                case 0:
                  return offText;
                case 1:
                  return onText;
              }
            },
            rotation: -90,
          },
          min: 0,
          softMax: 1,
          maxPadding: 0.08,
          endOnTick: false,
          gridLineWidth: 0,
          showEmpty: false,
        },
      ],
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        split: true,
        valueSuffix: '',
        xDateFormat: '%d.%m.%y / %H:%M:%S',
      },
      lang: {
        noData: this.noDataText,
        loading: this.loadingText,
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      loading: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
          },
        },
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
    };
  }
}
