import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { STnetPage } from 'src/app/stnet/stnet.page';
import { STnetCalculatedComponent } from 'src/app/stnet/stnet-calculated/stnet-calculated.component';
import { STnetCalculatedItemComponent } from 'src/app/stnet/stnet-calculated/stnet-calculated-item/stnet-calculated-item.component';
import { STnetEditableComponent } from 'src/app/stnet/stnet-editable/stnet-editable.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatTooltipModule,
    RouterModule.forChild([
      {
        path: '',
        component: STnetPage,
      },
    ]),
    TranslateModule,
  ],
  declarations: [STnetPage, STnetCalculatedComponent, STnetCalculatedItemComponent, STnetEditableComponent],
})
export class STnetPageModule {}
