import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayTorqueTrendAdapter } from 'src/app/maps/trenddata/ropeway-torque-trend.adapter';
import { RopewayTorqueTrend } from 'src/app/maps/trenddata/ropeway-torque-trend.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RopewayTorqueTrendService {
  constructor(private http: HttpClient) {}

  getTorqueData(ropeway: Ropeway): Observable<RopewayTorqueTrend[]> {
    const url = environment.baseUrlApi + `/trenddata/torque/` + ropeway.sisId;
    return this.http.get(url).pipe(map((data) => RopewayTorqueTrendAdapter.adapt(data, ropeway.configurations)));
  }
}
