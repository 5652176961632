import { Component, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { STnetCalculatedData } from 'src/app/stnet/domain/stnet-calculated-data.model';

@Component({
  selector: 'sis-stnet-calculated',
  templateUrl: './stnet-calculated.component.html',
  styleUrls: ['./stnet-calculated.component.scss'],
})
export class STnetCalculatedComponent extends Unsubscriber {
  @Input() calculatedData: STnetCalculatedData;

  bigScreenMode: boolean;
  readPermission: boolean;

  constructor(private screenSizeService: ScreenSizeService) {
    super();

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));
  }
}
