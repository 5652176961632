import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Options, XAxisPlotLinesOptions } from 'highcharts';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { MeteoStationService } from 'src/app/maps/meteostation-service/meteostation.service';
import { ForecastTrend } from 'src/app/maps/trenddata/forecast-trend.model';
import { MeteoTrendService } from 'src/app/maps/trenddata/meteo-trend.service';
import { TemperatureTrend } from 'src/app/maps/trenddata/temperature-trend.model';
import { ChartBase } from 'src/app/maps/widgets/charts/chart-base';
import { ChartSettings } from 'src/app/maps/widgets/charts/chart-settings.model';

@Component({
  selector: 'sis-temperature-chart',
  templateUrl: './temperature-chart.component.html',
  styleUrls: ['./temperature-chart.component.scss'],
})
export class TemperatureChartComponent extends ChartBase implements OnInit {
  static readonly plotlineWarn = 'PLOT1';
  static readonly trendSeriesId = 'trendSeries';
  static readonly forecastSeriesId: 'forecastSeries';

  private static readonly titleTranslateString = 'ropeway.term.temperature';
  private static readonly maxTranslateString = 'analytics.term.max';
  private static readonly avgTranslateString = 'analytics.term.avg';
  private static readonly forecastTranslateString = 'analytics.term.forecast';

  private readonly defaultYAxisMax = 20;
  private readonly defaultYAxisMin = 0;
  private readonly trendLineWidth = 2;
  private readonly forecastLineWidth = 1;
  private readonly xAxisPlotlineNowId = 'xAxisPlotNow';
  private readonly meteoStations$ = this.chartAdapter.chartReady$.pipe(
    take(1),
    switchMap(() => this.meteoStationService.allMeteoStationsWithTemperature$)
  );

  private forecastText: string;

  constructor(
    private meteoTrendDataService: MeteoTrendService,
    private meteoStationService: MeteoStationService,
    timeFormatService: TimeFormatService,
    translateService: TranslateService,
    chartSettings: ChartSettings
  ) {
    super(translateService, chartSettings?.inModal, timeFormatService);

    this.minY = this.defaultYAxisMin;
    this.maxY = this.defaultYAxisMax;
  }

  ngOnInit(): void {
    this.meteoStations$
      .pipe(
        distinctUntilChanged(),
        filter((meteoStations) => meteoStations?.length > 0),
        tap((meteoStations) => this.initChart(meteoStations)),
        switchMap((meteoStations) => {
          this.chartAdapter.showLoading();

          const observables = [];
          meteoStations.forEach((m) => {
            observables.push(this.meteoTrendDataService.getTemperatureTrends(m.guid));
            observables.push(this.meteoTrendDataService.getForecastTrends(m.guid));
          });

          return this.chartAdapter.loadData(observables).pipe(map((data) => ({ meteoStations, data })));
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(({ meteoStations, data }) => {
        for (let i = 0, d = 0; i < meteoStations.length; i++, d += 2) {
          this.updateChartData(data[d], data[d + 1], meteoStations[i].meteoStationIndex.toString());
        }
        this.chartAdapter.hideLoading();
      });
  }

  private initChart(meteoStations: MeteoStation[]) {
    this.deviceAlias = meteoStations[0].ropeway?.alias;
    this.updateTitle();

    this.chartAdapter.showLoading();
    this.chartAdapter.removeAllSeries();

    meteoStations.forEach((m, index) => {
      const meteostationIndex = m.meteoStationIndex.toString();

      this.chartAdapter.addSeries(TemperatureChartComponent.trendSeriesId + meteostationIndex, {
        name: m.alias,
        id: meteostationIndex,
        type: 'spline' as any,
        lineWidth: this.trendLineWidth,
        className: 'line-trend-' + meteostationIndex,
        color: this.getTrendLineColor(index),
      });

      if (meteoStations.find((m) => m.hasForecast)) {
        this.chartAdapter.addSeries(TemperatureChartComponent.forecastSeriesId + meteostationIndex, {
          name: `${this.forecastText} ${m.alias}`,
          id: meteostationIndex + 'forecast',
          type: 'spline' as any,
          lineWidth: this.forecastLineWidth,
          className: 'line-forecast',
          color: this.getForecastLineColor(index),
        });
      }
    });

    this.chartAdapter.reflowChart(this.onDestroy$);
  }

  private updateChartData(temperatureTrends: TemperatureTrend[], forecastTrend: ForecastTrend[], index: string): void {
    if (!temperatureTrends && !forecastTrend) {
      this.resetChart();
      return;
    }

    const minX = this.chartAdapter.getTimeInMsDaysAgo(1);
    const forecastTrends: TemperatureTrend[] = this.adaptForecast(forecastTrend, minX);

    if (temperatureTrends.length === 0 && forecastTrends.length === 0) {
      this.resetChart();
      this.chartAdapter.showNoData();
      return;
    }

    const temperatureCelsiusAverageData = this.getAverageData(temperatureTrends);
    const averageForecastData = this.getAverageData(forecastTrends).sort((i1, i2) => i1[0] - i2[0]);

    this.setPlotLines();
    this.chartAdapter.setSeriesData(TemperatureChartComponent.trendSeriesId + index, temperatureCelsiusAverageData);
    this.chartAdapter.setSeriesData(TemperatureChartComponent.forecastSeriesId + index, averageForecastData);

    this.minY = Math.min(
      this.defaultYAxisMin,
      this.getMinYAxisValue(temperatureTrends),
      this.getMinYAxisValue(forecastTrends),
      this.minY
    );
    this.maxY = Math.max(
      this.defaultYAxisMax,
      this.getMaxYAxisValue(temperatureTrends),
      this.getMaxYAxisValue(forecastTrends),
      this.maxY
    );

    if (!this.isZoomed) {
      this.chartAdapter.setYAxisExtremes(0, this.minY, this.maxY);
      this.chartAdapter.setXAxisExtremes(0, null, null);
    }

    if (this.isZoomed && this.isZoomedToEnd) {
      this.chartAdapter.setXAxisExtremes(0, this.zoomMin, null);
    }

    this.chartAdapter.updateDateXPlotLine(0, this.getXAxisDatePlotline());
  }

  private adaptForecast(forecastTrends: ForecastTrend[], minTimestamp: number): TemperatureTrend[] {
    return forecastTrends
      ? forecastTrends
          .filter((item) => item.timestamp.getTime() >= minTimestamp)
          .map((item) => ({
            temperatureCelsiusAverage: item.temperatureCelsius,
            timestamp: item.timestamp,
          }))
      : [];
  }

  private getMaxYAxisValue(trends: TemperatureTrend[]): number {
    const maxValue = Math.max(...trends.map((d) => d.temperatureCelsiusAverage ?? null));

    if (isNaN(maxValue)) {
      return 0;
    }

    return maxValue;
  }

  private getMinYAxisValue(trends: TemperatureTrend[]): number {
    const minValue = Math.min(...trends.map((d) => d.temperatureCelsiusAverage ?? null));

    if (isNaN(minValue)) {
      return 0;
    }

    return minValue;
  }

  private setPlotLines(): void {
    this.chartAdapter.removeYPlotBand(0, TemperatureChartComponent.plotlineWarn);
  }

  // returns an array with index 0 = Timestamp in ms, 1 = Average-Value
  private getAverageData(trends: TemperatureTrend[]): number[][] {
    return trends.map((item) => {
      const timeStampInMs = item.timestamp.getTime();
      return [timeStampInMs, item.temperatureCelsiusAverage];
    });
  }

  private resetChart(): void {
    this.chartAdapter.clearAllSeriesData();
  }

  private getXAxisDatePlotline(): XAxisPlotLinesOptions {
    return {
      id: this.xAxisPlotlineNowId,
      width: 1,
      value: new Date().getTime(),
      className: 'plot-line-x',
    };
  }

  protected translateTexts(translateService: TranslateService): void {
    translateService
      .get([
        TemperatureChartComponent.titleTranslateString,
        TemperatureChartComponent.maxTranslateString,
        TemperatureChartComponent.avgTranslateString,
        TemperatureChartComponent.forecastTranslateString,
      ])
      .pipe(take(1))
      .subscribe((res) => {
        this.title = res[TemperatureChartComponent.titleTranslateString];
        this.forecastText = res[TemperatureChartComponent.forecastTranslateString];

        if (this.chartAdapter) {
          this.chartAdapter.updateOptions({
            title: {
              text: this.isModal ? '' : this.title,
            },
          });
        }

        if (this.isModal) {
          this.updateModalTitle();
        }
      });
  }

  protected getChartOptions(): Options {
    return {
      time: {
        useUTC: false,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      legend: {
        enabled: this.isModal,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      xAxis: {
        type: 'datetime',
        crosshair: this.isModal,
        tickInterval: this.isModal ? 60 * 60 * 1000 : 4 * 60 * 60 * 1000, // 1 hour in modal, otherwise 4 hours (in ms)
        events: {
          setExtremes: (event) => this.handleSetExtremesEvent(event),
        },
      },
      chart: {
        zoomType: 'xy',
        panKey: 'ctrl',
        panning: {
          enabled: true,
        },
        animation: false,
        style: {
          fontFamily: 'Myriad Pro',
        },
        resetZoomButton: {
          theme: {
            stroke: 'var(--ion-color-primary)',
            height: 8,
          },
        },
      },
      yAxis: {
        title: {
          text: '[°C]',
          align: 'high',
          offset: 0,
          rotation: 0,
          y: -15,
          style: {
            fontSize: '12px',
          },
        },
        lineWidth: 1,
        tickInterval: 10,
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: this.isModal,
        split: true,
        valueSuffix: '°C',
        xDateFormat: '%d.%m.%y / %H:%M:%S',
      },
      lang: {
        noData: this.noDataText,
        loading: this.loadingText,
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      loading: {
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#666666',
        },
      },
      colors: ['#434348', '#1c232a80', '#7cb5ec', '#1c232a80', '#7ce8ec', '#1c232a80'], // every second is for forecast series
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
          },
        },
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
    };
  }
}
