import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, merge, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { OperatingInfoActiveTextUpdatedEvent, OperatingInfoLibraryUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { OperatingInfoEntry } from 'src/app/operating-info/domain/operating-info-entry.model';
import { OperatingInfoRequestDataAdapter } from 'src/app/operating-info/domain/operating-info-request-data.adapter';
import { OperatingInfoRequestData } from 'src/app/operating-info/domain/operating-info-request-data.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OperatingInfoService {
  static readonly insertOperation = 'insert';
  static readonly updateOperation = 'update';
  static readonly deleteOperation = 'delete';

  private static readonly dataRequestUrl: string = '/operatinginfo/getdata';
  private static readonly updateLibraryPostUrl: string = '/operatinginfo/updatelibraryitem';
  private static readonly useActiveTextPostUrl = '/operatinginfo/useactivetext';

  private readonly requiredFeature = new Feature(FeatureId.OPERATINGINFO, FeatureAccessLevel.READ);
  private readonly updating$ = new ReplaySubject<boolean>(1);

  readonly operatingInfoRequestData$: Observable<OperatingInfoRequestData> =
    this.destinationService.selectedTenantFeatures$.pipe(
      switchMap((features) => {
        if (!features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))) {
          return of({ libraryEntries: [], priorities: [], url: null });
        } else {
          return this.http
            .get(`${environment.baseUrlApi}${OperatingInfoService.dataRequestUrl}`)
            .pipe(map((data: any) => OperatingInfoRequestDataAdapter.adapt(data)));
        }
      }),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

  private readonly libraryRequest$: Observable<OperatingInfoEntry[]> = this.operatingInfoRequestData$.pipe(
    map((data) => data.libraryEntries)
  );

  private readonly activeTextUpdate$: Observable<OperatingInfoEntry[]> = this.eventBus
    .observe(OperatingInfoActiveTextUpdatedEvent)
    .pipe(
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([event, tenant]) => event?.updateSuccessful && event?.tenantGuid === tenant.guid),
      withLatestFrom(this.operatingInfoRequestData$, this.userSettingService.userSettings$),
      map(([[event], operatingInfo, userSettings]) => {
        if (event && event.changedBy === userSettings.userGuid) {
          this.presentToast(event.updateSuccessful);
          this.updating$.next(false);
        }

        operatingInfo.libraryEntries = event.operatingInfoEntries;

        return operatingInfo.libraryEntries;
      })
    );

  private readonly libraryUpdate$: Observable<OperatingInfoEntry[]> = this.eventBus
    .observe(OperatingInfoLibraryUpdatedEvent)
    .pipe(
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([event, tenant]) => event?.tenantGuid === tenant.guid),
      withLatestFrom(this.operatingInfoRequestData$, this.userSettingService.userSettings$),
      map(([[event], operatingInfo, userSettings]) => {
        const library = operatingInfo.libraryEntries;
        const oldEntry = library.find((l) => l.guid === event.operatingInfoLibraryEntry.guid);
        if (oldEntry) {
          if (!event.removed) {
            Object.assign(oldEntry, event.operatingInfoLibraryEntry);
          } else {
            library.splice(library.indexOf(oldEntry), 1);
          }
        } else {
          library.push(event.operatingInfoLibraryEntry);
        }

        if (event && event.changedBy === userSettings.userGuid) {
          this.presentToast(true);
        }

        this.updating$.next(false);
        return library;
      })
    );

  readonly subUrl$ = this.operatingInfoRequestData$.pipe(map((data) => data.url));

  readonly library$: Observable<OperatingInfoEntry[]> = merge(
    this.libraryRequest$,
    this.libraryUpdate$,
    this.activeTextUpdate$
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly updating: Observable<boolean> = this.updating$.pipe(
    distinctUntilChanged(),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private http: HttpClient,
    private userSettingService: UserSettingsService,
    private userMessageService: UserMessageService,
    private destinationService: DestinationService,
    private eventBus: EventBusService
  ) {}

  async updateLibrary(operatingInfoLibraryEntry: OperatingInfoEntry, operation: string): Promise<boolean> {
    this.updating$.next(true);

    const url = `${environment.baseUrlApi}${OperatingInfoService.updateLibraryPostUrl}`;

    try {
      await firstValueFrom(
        this.http.post(url, {
          OperatingInfoLibraryEntry: operatingInfoLibraryEntry,
          Operation: operation,
        })
      );
    } catch {
      await this.presentToast(false);
      this.updating$.next(false);
      return false;
    }

    return true;
  }

  async updateActiveText(operatingInfoEntries: OperatingInfoEntry[]): Promise<void> {
    this.updating$.next(true);

    const url = `${environment.baseUrlApi}${OperatingInfoService.useActiveTextPostUrl}`;

    try {
      await firstValueFrom(this.http.post(url, { OperatingInfoEntries: operatingInfoEntries }));
    } catch {
      await this.presentToast(false);
      this.updating$.next(false);
    }
  }

  private async presentToast(successful: boolean): Promise<void> {
    const translateKey = successful ? 'general.phrase.saved' : 'general.phrase.saveFailed';
    const userMessage = new UserMessage({
      message: translateKey,
      icon: successful ? UserMessageIcon.success : UserMessageIcon.failed,
      durationMs: 2000,
      position: 'top',
      color: successful ? UserMessageColor.green : UserMessageColor.red,
      showCloseButton: false,
    });
    await this.userMessageService.presentToast(userMessage);
  }
}
