import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TenantMarkerService {
  private readonly url = `${environment.baseUrlCockpitBlobStorage}/public-assets/icons/sis-marker-tenant.svg`;

  readonly svgAsHtml$ = this.httpClient.get(this.url, { responseType: 'text' }).pipe(shareReplay(1));

  constructor(private httpClient: HttpClient) {}
}
