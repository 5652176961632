<ion-grid class="sis-detailview-container sis-global-no-scrollbar">
  <sis-remote-access-detailview-item
    *ngFor="let remoteItem of this.remoteAccesses$ | async"
    [remoteAccess]="remoteItem"
    class="sis-detail-item"
  ></sis-remote-access-detailview-item>
  <sis-logbook-button
    class="sis-logbook-button"
    *ngIf="this.logbookAvailable$ | async"
    (click)="openLogbook()"
    data-test="remote-access-detail-view-logbook-button"
    [showText]="true"
  >
  </sis-logbook-button>
</ion-grid>
