<div class="message-container">
  <div class="message-type">
    <img
      src="https://devsambesi.blob.core.windows.net/sambesiwhatsup/changeType/{{
        sambesiWhatsUpMessage.changeType
      }}.png"
    />
  </div>
  <div class="spacer"></div>
  <div class="message-main">
    <div
      class="message-title"
      [matTooltip]="sambesiWhatsUpMessage.ropewaySisId + ' - ' + sambesiWhatsUpMessage.installationName"
      [matTooltipDisabled]="disabledTooltips.messageTitle"
      [matTooltipShowDelay]="200"
      (mouseenter)="updateTooltipDisabled(messageTitle, 'messageTitle')"
    >
      <div class="ropeway-icon">
        <img
          src="https://devsambesi.blob.core.windows.net/sambesiwhatsup/installationType/{{
            sambesiWhatsUpMessage.installationType
          }}.png"
        />
      </div>
      <div #messageTitle class="title-text">
        {{ sambesiWhatsUpMessage.ropewaySisId }} -
        {{ sambesiWhatsUpMessage.installationName }}
      </div>
    </div>
    <div
      class="node-name"
      #nodeName
      [matTooltip]="sambesiWhatsUpMessage.nodeName"
      [matTooltipDisabled]="disabledTooltips.nodeName"
      [matTooltipShowDelay]="200"
      (mouseenter)="updateTooltipDisabled(nodeName, 'nodeName')"
    >
      {{ sambesiWhatsUpMessage.nodeName }}
    </div>
    <div
      class="job-name"
      #jobName
      [matTooltip]="sambesiWhatsUpMessage.jobName"
      [matTooltipDisabled]="disabledTooltips.jobName"
      [matTooltipShowDelay]="200"
      (mouseenter)="updateTooltipDisabled(jobName, 'jobName')"
    >
      {{ sambesiWhatsUpMessage.jobName }}
    </div>
  </div>
  <div class="message-end">
    <div class="timestamp">{{ sambesiWhatsUpMessage.date | date : 'dd.MM.yy' }}</div>
    <div
      class="assigned-person"
      #assignedPerson
      [matTooltip]="sambesiWhatsUpMessage.assignedPerson"
      [matTooltipDisabled]="disabledTooltips.assignedPerson"
      [matTooltipShowDelay]="200"
      (mouseenter)="updateTooltipDisabled(assignedPerson, 'assignedPerson')"
    >
      {{ sambesiWhatsUpMessage.assignedPerson }}
    </div>
  </div>
</div>
