import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map, scan, shareReplay, withLatestFrom } from 'rxjs/operators';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { RopewayAlarmService } from 'src/app/maps/livedata/ropeway-alarm.service';
import { RopewayEfaService } from 'src/app/maps/livedata/ropeway-efa.service';
import { RopewayStatusAdapter } from 'src/app/maps/livedata/ropeway-status.adapter';
import { RopewayStatus, RopewayStatusType } from 'src/app/maps/livedata/ropeway-status.model';

@Injectable({
  providedIn: 'root',
})
export class RopewayStatusService extends LivedataServiceBase<RopewayStatus> {
  protected readonly endpointUri = 'livedata/ropewaystatus';
  protected readonly requiredFeatures = [new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ)];

  private readonly alarmUpdate$: Observable<Array<{ sisId: string; statusType: RopewayStatusType; active: boolean }>> =
    this.ropewayAlarmService.alarms$.pipe(
      map((alarms) =>
        alarms.map((alarm) => {
          const active = alarms.some((a) => a.sisId === alarm.sisId && a.level === alarm.level && a.active === true); // ???

          return { sisId: alarm.sisId, statusType: alarm.level as unknown as RopewayStatusType, active };
        })
      )
    );

  private readonly efaUpdate$: Observable<Array<{ sisId: string; statusType: RopewayStatusType; active: boolean }>> =
    this.ropewayEfaService.efas$.pipe(
      map((efas) =>
        efas.map((efa) => ({
          sisId: efa.sisId,
          statusType: efa.level as unknown as RopewayStatusType,
          active: efa.active,
        }))
      )
    );

  private readonly statusUpdate$: Observable<Array<{ sisId: string; statusType: RopewayStatusType; active: boolean }>> =
    this.liveData$.pipe(
      withLatestFrom(this.masterDataService.masterData$),
      map(([ropewayStatusLivedata, masterData]) => {
        const ropewayStatuses: Array<{ sisId: string; statusType: RopewayStatusType; active: boolean }> = [];
        const redundantControllerStatusDatas = ropewayStatusLivedata.filter(
          (s) => s.status === RopewayStatusType.CONTROLLERENABLED && s.deviceSisId.includes('.')
        );
        ropewayStatusLivedata.forEach((data) => {
          ropewayStatuses.push({ sisId: data.deviceSisId, statusType: data.status, active: data.active });
          ropewayStatuses.push({
            sisId: data.deviceSisId,
            statusType: RopewayStatusType.OUTDATED,
            active: data.outdated,
          });

          const otherControllerStatusData = redundantControllerStatusDatas.find(
            (s) => s.deviceSisId === this.getDeviceSisIdFromOtherController(data.deviceSisId)
          );
          if (data.status === RopewayStatusType.CONTROLLERENABLED && otherControllerStatusData) {
            const controllerActiveStatus = this.getControllerActiveStatus(data, otherControllerStatusData);
            ropewayStatuses.push({
              sisId: data.deviceSisId,
              statusType: RopewayStatusType.CONTROLLERACTIVE,
              active: controllerActiveStatus,
            });
          }
        });
        if (masterData.ropeways) {
          masterData.ropeways.forEach((r) => {
            const statusData = ropewayStatusLivedata.find((l) => l.deviceSisId === r.sisId);
            ropewayStatuses.push({ sisId: r.sisId, statusType: RopewayStatusType.OFFLINE, active: statusData == null });
          });
        }

        return ropewayStatuses;
      })
    );

  readonly ropewayStatus$: Observable<Map<string, Map<RopewayStatusType, boolean>>> = merge(
    this.alarmUpdate$,
    this.efaUpdate$,
    this.statusUpdate$
  ).pipe(
    scan((acc, value) => {
      value.forEach((v) => {
        if (!acc.has(v.sisId)) {
          acc.set(v.sisId, new Map<RopewayStatusType, boolean>([[v.statusType, v.active]]));
        } else {
          acc.get(v.sisId).set(v.statusType, v.active);
        }
      });
      return acc;
    }, new Map<string, Map<RopewayStatusType, boolean>>()),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    http: HttpClient,
    private masterDataService: MasterdataService,
    destinationService: DestinationService,
    private ropewayAlarmService: RopewayAlarmService,
    private ropewayEfaService: RopewayEfaService
  ) {
    super(http, RopewayStatusAdapter.adapt, destinationService);
  }

  private getControllerActiveStatus(status: RopewayStatus, otherStatus: RopewayStatus): boolean {
    const isPrimaryController = status && status.deviceSisId.includes('.1');
    const controllerIsActive = status && status.active;
    const otherControllerIsActive = otherStatus && otherStatus.active;

    return isPrimaryController
      ? controllerIsActive || !otherControllerIsActive
      : controllerIsActive && !otherControllerIsActive;
  }

  private getDeviceSisIdFromOtherController(deviceSisId: string): string {
    const controllerNumber = deviceSisId.includes('.') ? Number(deviceSisId.split('.')[1]) : 0;
    return deviceSisId.replace(/\..*/, `.${(controllerNumber % 2) + 1}`);
  }
}
