import { Category } from 'src/app/infotext/domain/category.model';
import { Infotext } from 'src/app/infotext/domain/infotext.model';

export class InfotextAdapter {
  static adapt(items: any): Category[] {
    const infotextsMap = new Map<number, Infotext[]>();
    const categoriesMap = new Map<number, Category>();
    if (items) {
      items.forEach((e: any) => {
        const infotext: Infotext = {
          guid: e.guid,
          categoryId: e.categoryId,
          titleDe: e.titleDe,
          titleEn: e.titleEn,
          titleFr: e.titleFr,
          titleIt: e.titleIt,
          textDe: e.textDe,
          textEn: e.textEn,
          textFr: e.textFr,
          textIt: e.textIt,
          textOrder: e.textOrder ?? 999,
        };

        categoriesMap.set(e.categoryId, {
          id: e.categoryId,
          categoryDe: e.categoryDe,
          categoryEn: e.categoryEn,
          categoryFr: e.categoryFr,
          categoryIt: e.categoryIt,
          infotexts: [],
        });

        if (infotextsMap.get(e.categoryId)) {
          infotextsMap.get(e.categoryId).push(infotext);
        } else {
          infotextsMap.set(e.categoryId, [infotext]);
        }
      });
      infotextsMap.forEach((infotexts, categoryId) => (categoriesMap.get(categoryId).infotexts = infotexts));
    }
    return [...categoriesMap.values()];
  }
}
