import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { DrawerState } from 'src/app/bottom-drawer/drawer-state';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MapIconClickedEvent, WidgetSelectedEvent } from 'src/app/core/eventbus/events';
import { IconHelper } from 'src/app/core/icon-helper/icon-helper';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Component({
  selector: 'sis-details-drawer',
  templateUrl: './details-drawer.component.html',
  styleUrls: ['./details-drawer.component.scss'],
})
export class DetailsDrawerComponent extends Unsubscriber implements OnInit {
  private readonly arrowUp = 'chevron-up-outline';
  private readonly arrowDown = 'chevron-down-outline';
  private readonly drawerBorderWidth = 2;
  private readonly drawerColumns = 4;
  private readonly widgetAspectRatio = 0.8;

  arrowIconName = this.arrowUp;
  ropeway: Ropeway;
  scrollable: boolean;
  disableDrag: boolean;
  drawerMinimumHeight = 0;
  drawerDistanceTop = 80;
  drawerDockedHeight =
    (this.platform.width() - (this.drawerColumns + 1) * this.drawerBorderWidth) /
      this.drawerColumns /
      this.widgetAspectRatio +
    (this.navigator.isIphone() ? 99 : 109);

  showControllerContextMenu: boolean;
  controllerContextMenuX: number;
  controllerContextMenuY: number;

  label: string;
  iconName: string;
  drawerState = DrawerState.Bottom;

  private empiricalDrawerContentHeightFitReducer = this.navigator.isIphone() ? 56 : 66;
  private destinationName: string;
  private meteoStation: MeteoStation;

  @ViewChild('drawerContent', { static: false }) drawerContent: ElementRef;

  constructor(
    private eventBus: EventBusService,
    private renderer: Renderer2,
    private platform: Platform,
    private userMessageService: UserMessageService,
    private screenSizeService: ScreenSizeService,
    private navigator: NavigatorService,
    private destinationService: DestinationService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.eventBus.observe(MapIconClickedEvent).pipe(
        distinctUntilChanged(
          (prev, curr) =>
            prev.ropeway === curr.ropeway &&
            prev.meteoStation === curr.meteoStation &&
            prev.customMarker === curr.customMarker &&
            !(!curr.ropeway && !curr.meteoStation && !curr.customMarker)
        ),
        startWith(new MapIconClickedEvent())
      ),
      this.screenSizeService.landscapeMode$,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([event, landscapeMode]) => this.onMapClicked(event, landscapeMode));

    combineLatest([this.eventBus.observe(WidgetSelectedEvent), this.screenSizeService.landscapeMode$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([event, landscapeMode]) => this.onWidgetSelected(event, landscapeMode));

    this.destinationService.selectedTenant$.pipe(takeUntil(this.onDestroy$)).subscribe((tenant) => {
      {
        this.destinationName = tenant.fullname;
        this.setLabel();
      }
    });

    this.screenSizeService.landscapeMode$.pipe(takeUntil(this.onDestroy$)).subscribe((landscapeMode) => {
      if (landscapeMode) {
        this.closeDrawer();
      }
    });
  }

  onDrawerStateChange(drawerState: DrawerState): void {
    switch (drawerState) {
      case DrawerState.Top:
        this.openDrawer();
        break;
      case DrawerState.Docked:
        this.dockDrawer(true);
        break;
      case DrawerState.Bottom:
        this.closeDrawer();
        break;
    }
  }

  showControllerSelector(div: any): void {
    this.showControllerContextMenu = this.ropeway && this.ropeway.hasRedundantControllers;
    this.controllerContextMenuX = div.offsetLeft + 65;
    this.controllerContextMenuY = div.offsetTop + 20;
  }

  hideControllerContextMenu(): void {
    this.showControllerContextMenu = false;
  }

  touchStart() {
    this.disableDrag = this.scrollable;
  }

  touchEnd() {
    this.disableDrag = false;
  }

  titleClicked(): void {
    if (this.drawerState === DrawerState.Docked) {
      this.openDrawer();
      return;
    }
    this.dockDrawer(true);
  }

  private setIconName(ropeway: Ropeway, meteoStation: MeteoStation): void {
    if (ropeway) {
      this.iconName = IconHelper.getRopewayIconName(this.ropeway);
    } else {
      if (meteoStation) {
        this.iconName = IconHelper.meteoStationIconName;
      } else {
        this.iconName = undefined;
      }
    }
  }

  private setMaxHeight(): void {
    if (!this.drawerContent) {
      return;
    }
    if (this.drawerState === DrawerState.Top) {
      this.renderer.setStyle(
        this.drawerContent.nativeElement,
        'max-height',
        this.platform.height() - this.drawerDistanceTop - this.empiricalDrawerContentHeightFitReducer + 'px'
      );
    }
    if (this.drawerState === DrawerState.Docked) {
      this.renderer.setStyle(this.drawerContent.nativeElement, 'max-height', this.drawerDockedHeight + 'px');
    }
  }

  private setArrowIcon(): void {
    this.arrowIconName = this.drawerState === DrawerState.Top ? this.arrowDown : this.arrowUp;
  }

  private onWidgetSelected(event: WidgetSelectedEvent, landscapeMode: boolean): void {
    if (!landscapeMode) {
      if (event.expandable) {
        this.openDrawer();
      } else {
        if (!event.codeTriggered) {
          this.dockDrawer(false);
        }
      }
    } else {
      this.presentToast();
    }
  }

  private onMapClicked(event: MapIconClickedEvent, landscapeMode: boolean): void {
    this.ropeway = event.ropeway;
    this.meteoStation = event.meteoStation;
    this.setIconName(this.ropeway, this.meteoStation);
    this.setLabel();
    if (!landscapeMode) {
      if (event.meteoStation || event.customMarker) {
        this.openDrawer();
      } else {
        if (!event.codeTriggered) {
          this.dockDrawer(false);
        }
      }
    } else if (event.ropeway !== undefined) {
      this.presentToast();
    }
  }

  private setLabel(): void {
    this.label = this.ropeway ? this.ropeway.alias : this.meteoStation ? this.meteoStation.alias : this.destinationName;
  }

  private closeDrawer() {
    this.drawerState = DrawerState.Bottom;
    this.setArrowIcon();
    this.scrollable = false;
    const event = new MapIconClickedEvent();
    event.codeTriggered = true;
    this.eventBus.publish(event);
  }

  private dockDrawer(sendEvent: boolean) {
    this.drawerState = DrawerState.Docked;
    this.setMaxHeight();
    this.setArrowIcon();
    this.scrollable = false;
    if (sendEvent) {
      const event = new WidgetSelectedEvent();
      event.codeTriggered = true;
      this.eventBus.publish(event);
    }
  }

  private openDrawer() {
    this.drawerState = DrawerState.Top;
    this.setMaxHeight();
    this.setArrowIcon();
    this.scrollable = true;
  }

  private presentToast() {
    this.userMessageService.presentToast(
      new UserMessage({
        message: 'map.message.BottomDrawerNotAvailable',
        icon: UserMessageIcon.info,
        durationMs: 2000,
        position: 'bottom',
        color: UserMessageColor.blue,
      })
    );
  }
}
