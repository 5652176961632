import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';

export class Feature {
  static readonly SISMEDIA_PREFIX = 'SISMEDIA';

  constructor(
    public readonly featureId: FeatureId,
    public featureAccessLevel: FeatureAccessLevel,
    public adminOnly?: boolean,
    public featureOrder?: number
  ) {}

  hasMinimumRequirementFor(feature: Feature): boolean {
    return this.featureId === feature.featureId && this.featureAccessLevel >= feature.featureAccessLevel;
  }
}
