import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { UserSettings } from 'src/app/domain/user-settings/user-settings.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends Unsubscriber {
  static readonly supportedLanguages = ['en', 'de', 'fr', 'it'];
  static readonly urlLanguage = '/user/language';
  static readonly urlTimeFormat = '/user/timeformat';

  readonly userName$ = this.msalBroadcastService.inProgress$.pipe(
    filter((interactionStatus) => interactionStatus === InteractionStatus.None),
    map(() => this.msalService.instance.getActiveAccount()?.name),
    shareReplay(1)
  );

  readonly isAuthenticated$ = this.msalBroadcastService.inProgress$.pipe(
    filter((interactionStatus) => interactionStatus === InteractionStatus.None),
    map(() => this.msalService.instance.getActiveAccount() != null),
    shareReplay(1)
  );

  constructor(
    private userSettingsService: UserSettingsService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private translateService: TranslateService
  ) {
    super();

    this.userSettingsService.userSettings$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((userSettings) => this.loadDefaultSettings(userSettings));
  }

  logout(): void {
    if (this.msalService.instance.getActiveAccount() != null) {
      this.msalService.logout();
    }
  }

  private loadDefaultSettings(userSettings: UserSettings) {
    if (userSettings && userSettings.defaultTenant) {
      if (userSettings.guiLanguage && UserService.supportedLanguages.includes(userSettings.guiLanguage)) {
        this.translateService.use(userSettings.guiLanguage);
      } else {
        this.translateService.use('de');
      }
    }
  }
}
