<ion-item class="menu-page-item" (click)="this.onMenuItemClick()">
  <div class="ion-menu-container">
    <ion-icon name="sis-icon-menu-news" class="ion-menu-icon"> </ion-icon>
    <ion-icon
      *ngIf="this.hasUnreadNews$ | async"
      name="sis-news-dot"
      class="sis-red-dot"
      data-test="news-red-dot"
    ></ion-icon>
  </div>
  <ion-label>{{ 'general.term.news' | translate }}</ion-label>
  <ion-icon
    class="menu-page-dropdown-icon"
    slot="end"
    [name]="this.open ? 'chevron-down-outline' : 'chevron-forward-outline'"
  ></ion-icon>
</ion-item>
<ion-grid
  class="sis-news-container"
  [ngStyle]="{ height: this.open ? '400px' : '0px' }"
  [ngClass]="{ 'sis-news-container-open': this.open }"
  #newsGrid
>
  <ion-row style="padding: 0px" #newsTopRow> </ion-row>
  <sis-main-menu-news-item
    *ngFor="let fileName of this.fileNames$ | async"
    [fileName]="fileName"
  ></sis-main-menu-news-item>
  <ion-row style="min-height: 30px"></ion-row>
  <ion-chip
    *ngIf="!(this.loadAllNewsFiles$ | async)"
    data-test="all-news-chip"
    class="sis-all-news-button sis-global-round-btn"
    color="secondary"
    (click)="this.requestAllNewsFiles()"
  >
    <ion-label>{{ 'news.phrase.loadReadNews' | translate }}</ion-label>
  </ion-chip>
</ion-grid>
