<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.cancel()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title class="sis-modal-title">
      {{ (isNewItem ? 'weblinkcollection.term.new' : 'weblinkcollection.term.edit') | translate }}</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <!-- Desktop -->
  <ion-grid *ngIf="this.bigScreenMode" class="sis-modal-grid">
    <form [formGroup]="this.formGroup">
      <ion-row class="sis-global-row-50">
        <ion-col size="3">
          {{ 'weblinkcollection.term.category' | translate }}
        </ion-col>
        <ion-col size="5">
          <ion-select
            style="min-width: 240px"
            class="sis-weblink-text-input"
            formControlName="category"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover' }"
            data-test="weblink-edit-category-select"
          >
            <ion-select-option *ngIf="this.writePermission" [value]="enumWebLinkCategoryItem.tenant">{{
              tenantAlias
            }}</ion-select-option>
            <ion-select-option *ngIf="!this.isExistingPublicItem" [value]="enumWebLinkCategoryItem.private">{{
              'weblinkcollection.term.private' | translate
            }}</ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>

      <ion-row class="sis-global-row-50">
        <ion-col size="3">
          {{ 'weblinkcollection.term.type' | translate }}
        </ion-col>
        <ion-col size="9">
          <ion-col
            class="sis-weblink-icon"
            *ngFor="let icon of icons"
            [disabled]="this.isSaving"
            [ngClass]="{ 'sis-weblink-icon-selected': icon === this.webLinkItemEdit.iconName && !this.showExtraIcon }"
            data-test="weblink-edit-preselected-icons"
          >
            <ion-icon [name]="icon" class="sis-global-pointer-cursor" (click)="selectIcon(icon)"></ion-icon>
          </ion-col>
        </ion-col>
      </ion-row>
      <ion-row class="sis-global-row-50">
        <ion-col size="3" (click)="openMoreIcons()" class="sis-weblink-text-underline sis-global-pointer-cursor">
          {{ 'weblinkcollection.term.extraIcons' | translate }}
        </ion-col>
        <ion-col size="3">
          <ion-input
            type="text"
            class="sis-weblink-text-input"
            formControlName="extraIcon"
            inputmode="text"
            placeholder="{{ 'weblinkcollection.term.extraIconName' | translate }}"
            data-test="weblink-edit-extraicon-input"
          ></ion-input>
        </ion-col>
        <ion-col
          *ngIf="this.formGroup.controls.extraIcon.valid && this.showExtraIcon"
          data-test="sis-weblink-extraicon"
        >
          <ion-icon [name]="this.webLinkItemEdit.extraIcon" class="sis-weblink-extra-icon sis-weblink-icon-selected">
          </ion-icon>
        </ion-col>
        <ion-col
          *ngIf="!this.formGroup.controls.extraIcon.valid && !this.isSaving"
          data-test="sis-weblink-extraicon-invalid"
        >
          <div class="sis-weblink-invalid-message">
            {{ 'weblinkcollection.phrase.invalidExtraIcon' | translate }}
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="sis-global-row-50">
        <ion-col size="3"> URL </ion-col>
        <ion-col>
          <ion-input
            type="text"
            class="sis-weblink-text-input"
            inputmode="text"
            formControlName="url"
            data-test="weblink-edit-url-input"
          ></ion-input>
        </ion-col>
      </ion-row>
      <ion-row class="sis-global-row-50">
        <ion-col size="3">
          {{ 'weblinkcollection.term.description' | translate }}
        </ion-col>
        <ion-col>
          <ion-input
            type="text"
            class="sis-weblink-text-input"
            inputmode="text"
            formControlName="description"
            data-test="weblink-edit-description-input"
          ></ion-input>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>

  <!-- Small Screen -->
  <ion-grid *ngIf="!this.bigScreenMode" class="sis-modal-grid">
    <form [formGroup]="this.formGroup">
      <ion-row>
        <ion-col class="sis-weblink-subtitle">
          {{ 'weblinkcollection.term.category' | translate }}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-select
            class="sis-weblink-text-input"
            formControlName="category"
            interface="popover"
            [interfaceOptions]="{ cssClass: 'sis-global-popover' }"
          >
            <ion-select-option *ngIf="this.writePermission" [value]="enumWebLinkCategoryItem.tenant">{{
              tenantAlias
            }}</ion-select-option>
            <ion-select-option *ngIf="!this.isExistingPublicItem" [value]="enumWebLinkCategoryItem.private">{{
              'weblinkcollection.term.private' | translate
            }}</ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="sis-weblink-subtitle">
          {{ 'weblinkcollection.term.type' | translate }}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col
          *ngFor="let icon of icons"
          class="sis-weblink-icon-small"
          [disabled]="this.isSaving"
          [ngClass]="{ 'sis-weblink-icon-selected': icon === this.webLinkItemEdit.iconName && !this.showExtraIcon }"
        >
          <ion-icon [name]="icon" (click)="selectIcon(icon)"></ion-icon>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col (click)="openMoreIcons()" class="sis-weblink-subtitle sis-weblink-text-underline">
          {{ 'weblinkcollection.term.extraIcons' | translate }}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ion-input
            type="text"
            class="sis-weblink-text-input"
            formControlName="extraIcon"
            inputmode="text"
            placeholder="{{ 'weblinkcollection.term.extraIconName' | translate }}"
          ></ion-input>
        </ion-col>
        <ion-col
          *ngIf="this.formGroup.controls.extraIcon.valid && this.showExtraIcon"
          class="sis-weblink-extra-icon sis-weblink-icon-selected"
        >
          <ion-icon [name]="this.webLinkItemEdit.extraIcon"> </ion-icon>
        </ion-col>
        <ion-col *ngIf="!this.formGroup.controls.extraIcon.valid && !this.isSaving">
          <div class="sis-weblink-invalid-message-small">
            {{ 'weblinkcollection.phrase.invalidExtraIcon' | translate }}
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col class="sis-weblink-subtitle">URL</ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-input type="text" class="sis-weblink-text-input" inputmode="text" formControlName="url"></ion-input>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col class="sis-weblink-subtitle">
          {{ 'weblinkcollection.term.description' | translate }}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-input
            type="text"
            class="sis-weblink-text-input"
            inputmode="text"
            formControlName="description"
          ></ion-input>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [btnType]="'Secondary'"
      (sisClick)="this.save()"
      [disabled]="this.isSaving || !this.formGroup?.valid"
      [showSpinner]="this.isSaving"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      data-test="weblink-edit-save-btn"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      (sisClick)="this.cancel()"
      [showSpinner]="this.isSaving"
      [width]="this.bigScreenMode ? '120px' : '100%'"
    ></sis-general-button>
    <sis-general-button
      *ngIf="!this.isNewItem"
      [text]="'general.term.delete'"
      [btnType]="'Red'"
      [btnAlign]="'Left'"
      (sisClick)="this.delete()"
      [disabled]="this.isSaving"
      [showSpinner]="this.isSaving"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      data-test="weblink-edit-delete-btn"
    ></sis-general-button>
  </div>
</ion-footer>
