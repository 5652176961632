import { Component, Input } from '@angular/core';
import { LayerGroup } from 'leaflet';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';

@Component({
  selector: 'sis-ropeway-layer',
  templateUrl: './ropeway-layer.component.html',
  styleUrls: ['./ropeway-layer.component.scss'],
})
export class RopewayLayerComponent extends Unsubscriber {
  @Input() layerGroup: LayerGroup;
  @Input() ropeways: Ropeway[] = [];
}
