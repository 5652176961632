import { BehaviorSubject } from 'rxjs';
import { WritablePart } from 'src/app/core/utils/type-helper';
import { Feature } from 'src/app/domain/feature/feature.model';
import { Coords } from 'src/app/maps/domain/coords.model';
import { v4 as v4guid } from 'uuid';

export class Tenant {
  readonly features$ = new BehaviorSubject<Feature[]>([]);

  guid: string = v4guid();
  sisId: string;
  alias: string;
  fullname: string;
  gatewayId: string;
  panoMapFilename: string;
  logoFilename: string;
  weatherTrendLink: string;
  positionGeoMap: Coords = { x: 0, y: 0 };
  maxUsers: number;
  hasRopewaysOrLifts: boolean;
  hasMySqlRouter: boolean;
  underMaintenance: boolean;
  timeZoneId: string;

  constructor(init?: Partial<WritablePart<Tenant>>, features: Feature[] = []) {
    Object.assign(this, init);
    this.features$.next(features);
  }
}
