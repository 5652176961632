<div *ngIf="this.bigScreenMode" class="sis-user-management-item">
  <div class="sis-user">
    <div>{{ this.user.displayName }}<img *ngIf="this.user.isSisag" src="assets/sis-logo/sis-a-color.svg" /></div>
    <div class="sis-user-email" data-test="user-email">{{ this.user.email }}</div>
  </div>
  <div class="sis-user-management-icons">
    <ion-icon
      name="key-outline"
      class="sis-global-pointer-cursor sis-hover-icon"
      (click)="emitContextMenuOnUser($event); $event.stopPropagation()"
      matTooltip="{{ 'usermanagement.term.permissionChange' | translate }}"
      data-test="usermanagement-permission-icon"
    >
    </ion-icon>
    <ion-icon
      name="create-outline"
      class="sis-global-pointer-cursor sis-hover-icon"
      (click)="this.openEditModal(this.user, this.managedTenants, this.isAdministrator)"
      matTooltip="{{ 'general.term.edit' | translate }}"
      data-test="usermanagement-edit-icon"
    >
    </ion-icon>
    <ion-icon
      *ngIf="!this.user.invitationAccepted"
      matTooltip="{{ 'usermanagement.phrase.invitationNotAccepted' | translate }}"
      class="sis-invitation-notaccepted-icon"
      name="warning-outline"
      data-test="usermanagement-warning-icon"
    ></ion-icon>
  </div>
</div>

<div *ngIf="!this.bigScreenMode" class="sis-user-management-item mobile">
  <div class="sis-user">
    <div>{{ this.user.displayName }}<img *ngIf="this.user.isSisag" src="assets/sis-logo/sis-a-color.svg" /></div>
    <div class="sis-user-email" data-test="user-email">{{ this.user.email }}</div>
  </div>
</div>
