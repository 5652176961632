import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MapIconClickedEvent, RopewaySettingsUpdatedEvent } from 'src/app/core/eventbus/events';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { MasterData } from 'src/app/maps/domain/masterdata.model';
import { MasterdataService } from 'src/app/maps/domain/masterdata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { CustomMarkerWidgetListService } from 'src/app/maps/widget-list/widget-list-services/custom-marker-widget-list.service';
import { DestinationWidgetListService } from 'src/app/maps/widget-list/widget-list-services/destination-widget-list.service';
import { Widget } from 'src/app/maps/widget-list/widget-list-services/domain/widget.model';
import { WidgetList } from 'src/app/maps/widget-list/widget-list-services/domain/widget-list.model';
import { MeteoStationWidgetListService } from 'src/app/maps/widget-list/widget-list-services/meteo-station-widget-list.service';
import { RopewayWidgetListService } from 'src/app/maps/widget-list/widget-list-services/ropeway-widget-list.service';

@Injectable({
  providedIn: 'root',
})
export class WidgetListService {
  private columns = 4;

  readonly widgetList$: Observable<WidgetList> = combineLatest([
    this.destinationService.selectedTenantFeatures$.pipe(distinctUntilChanged()),
    this.screenSizeService.bigScreenMode$,
    this.selectedMapElementService.selectedMapElement$,
    this.masterDataService.masterData$.pipe(
      distinctUntilChanged((prev, curr) => curr.sisId === prev.sisId),
      filter((masterData) => masterData?.sisId?.length > 0),
      switchMap((masterData) =>
        this.eventBus.observe(RopewaySettingsUpdatedEvent).pipe(
          distinctUntilChanged(
            (prev, curr) => prev?.ropewaySettings.availability === curr?.ropewaySettings.availability
          ),
          startWith(null),
          map(() => masterData)
        )
      )
    ),
  ]).pipe(
    switchMap(([userFeatures, bigScreenMode, mapIconClickedEvent, masterData]) =>
      this.buildWidgetList(userFeatures, bigScreenMode, mapIconClickedEvent, masterData)
    ),
    map((widgets) => ({
      widgets,
      columns: this.columns,
    }))
  );

  constructor(
    private ropewayWidgetListService: RopewayWidgetListService,
    private destinationWidgetListService: DestinationWidgetListService,
    private meteoStationWidgetListService: MeteoStationWidgetListService,
    private customMarkerWidgetListService: CustomMarkerWidgetListService,
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService,
    private masterDataService: MasterdataService,
    private selectedMapElementService: SelectedMapElementService,
    private eventBus: EventBusService
  ) {}

  private buildWidgetList(
    userFeatures: Feature[],
    bigScreenMode: boolean,
    mapIconClickedEvent: MapIconClickedEvent,
    masterData: MasterData
  ): Observable<Widget[]> {
    if (mapIconClickedEvent.ropeway) {
      return this.ropewayWidgetListService.buildWidgetList(mapIconClickedEvent, userFeatures, bigScreenMode);
    }

    if (mapIconClickedEvent.meteoStation) {
      return this.meteoStationWidgetListService.buildWidgetList(mapIconClickedEvent);
    }

    if (mapIconClickedEvent.customMarker) {
      return this.customMarkerWidgetListService.buildWidgetList(mapIconClickedEvent, userFeatures, bigScreenMode);
    }

    return this.destinationWidgetListService.buildWidgetList(masterData, userFeatures);
  }
}
