import { UserAlarmConfig } from 'src/app/maps/alarmlibrary/domain/useralarmconfig.model';

export class UserAlarmConfigAdapter {
  static adapt(item: any): UserAlarmConfig[] {
    const userAlarmConfigs: UserAlarmConfig[] = [];

    if (item) {
      item.forEach((e: any) => {
        userAlarmConfigs.push({
          userGuid: e.userGuid,
          sisId: e.sisId,
          alarmCode: e.alarmCode,
          setting: e.setting,
          enabled: e.enabled,
        });
      });
    }

    return userAlarmConfigs;
  }
}
