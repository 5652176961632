import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayOperationWidgetBase } from 'src/app/maps/widgets/ropeway-operation-widget-base';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-cabinposition-widget',
  templateUrl: './cabinposition-widget.component.html',
  styleUrls: ['./cabinposition-widget.component.scss'],
})
export class CabinPositionWidgetComponent extends RopewayOperationWidgetBase implements OnInit {
  cabinPositionMeter: string;

  constructor(ropewayLiveDataService: RopewayLivedataService, selectedMapElementService: SelectedMapElementService) {
    super(ropewayLiveDataService, selectedMapElementService);
  }

  ngOnInit(): void {
    this.ropewayLiveData$.pipe(takeUntil(this.onDestroy$)).subscribe((d) => {
      if (d.liveData?.cabinPositionMeter == null) {
        this.cabinPositionMeter = WidgetBase.valueUnknown;
        return;
      }

      this.cabinPositionMeter = d.liveData.cabinPositionMeter.toString();
    });
  }
}
