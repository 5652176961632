import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferProgressEvent } from '@azure/core-http';
import { PagedAsyncIterableIterator } from '@azure/core-paging';
import {
  BlobDeleteResponse,
  BlobDownloadResponseParsed,
  BlobItem,
  BlobServiceClient,
  BlockBlobClient,
  ContainerClient,
  ContainerItem,
} from '@azure/storage-blob';
import { from, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  SisBlobContainerRequestParameters,
  SisBlobFileRequestParameters,
  SisBlobStorageRequestParameters,
} from 'src/app/core/blob-storage/blob-storage.types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BlobStorageService {
  constructor(private httpClient: HttpClient) {}

  getContainers(request: SisBlobStorageRequestParameters): Observable<ContainerItem[]> {
    const blobServiceClient = this.buildBlobServiceClient(request);
    return this.asyncToObservable(blobServiceClient.listContainers());
  }

  listBlobsInContainer(request: SisBlobContainerRequestParameters): Observable<BlobItem[]> {
    const containerClient = this.getContainerClient(request);
    return this.asyncToObservable(containerClient.listBlobsFlat({ includeMetadata: true }));
  }

  downloadBlobItem(request: SisBlobFileRequestParameters): Observable<BlobDownloadResponseParsed> {
    const blockBlobClient = this.getBlockBlobClient(request);
    return from(blockBlobClient.download());
  }

  deleteBlobItem(request: SisBlobFileRequestParameters): Observable<BlobDeleteResponse> {
    const blockBlobClient = this.getBlockBlobClient(request);
    return from(blockBlobClient.delete());
  }

  uploadToBlobStorage(
    file: File,
    request: SisBlobFileRequestParameters,
    metadata?: {
      [propertyName: string]: string;
    }
  ): Observable<number> {
    const blockBlobClient = this.getBlockBlobClient(request);
    return this.uploadFile(blockBlobClient, file, metadata);
  }

  getBlobStorageRequestParameters(): Observable<SisBlobStorageRequestParameters> {
    return this.httpClient.get<string>(`${environment.baseUrlApi}/mediacenter/blobstorage`).pipe(
      map<string, SisBlobStorageRequestParameters>((sasToken) => ({
        storageUri: environment.baseUrlMediaCenterBlobStorage,
        storageAccessToken: sasToken,
      }))
    );
  }

  private getBlockBlobClient(request: SisBlobFileRequestParameters): BlockBlobClient {
    const containerClient = this.getContainerClient(request);
    return containerClient.getBlockBlobClient(request.filePath);
  }

  private getContainerClient(request: SisBlobContainerRequestParameters): ContainerClient {
    const blobServiceClient = this.buildBlobServiceClient(request);
    return blobServiceClient.getContainerClient(request.containerName);
  }

  private buildBlobServiceClient(options: SisBlobStorageRequestParameters): BlobServiceClient {
    const connectionString = `BlobEndpoint=${options.storageUri};SharedAccessSignature=${options.storageAccessToken}`;
    return BlobServiceClient.fromConnectionString(connectionString);
  }

  private uploadFile(
    blockBlobClient: BlockBlobClient,
    file: File,
    metadata?: {
      [propertyName: string]: string;
    }
  ): Observable<number> {
    return new Observable<number>((subscriber) => {
      blockBlobClient
        .uploadData(file, {
          onProgress: (progress: TransferProgressEvent) => subscriber.next(progress.loadedBytes),
          blobHTTPHeaders: {
            blobContentType: file.type,
          },
          metadata,
        })
        .then(
          () => {
            subscriber.next(file.size);
            subscriber.complete();
          },
          (error: any) => subscriber.error(error)
        );
    }).pipe(distinctUntilChanged());
  }

  private asyncToObservable<T, TService>(iterable: PagedAsyncIterableIterator<T, TService>): Observable<T[]> {
    return new Observable<T[]>(
      (subscriber) =>
        void (async () => {
          try {
            const items: T[] = [];
            for await (const item of iterable as AsyncIterable<T>) {
              if (subscriber.closed) {
                return;
              }
              items.push(item);
            }
            subscriber.next(items);
            subscriber.complete();
          } catch (e) {
            subscriber.error(e);
          }
        })()
    );
  }
}
