import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { firstValueFrom, map, startWith } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sis-sisag-admin',
  templateUrl: './sisag-admin.page.html',
  styleUrls: ['./sisag-admin.page.scss'],
})
export class SisagAdminPage {
  mapGuid = new FormControl<string>(null);
  gatewayId = new FormControl<string>(null);

  updateGatewayUpdateScriptResponses: Array<{ gatewayId: string; success: boolean }>;
  restartGatewaysResponses: Array<{ gatewayId: string; success: boolean }>;

  readonly gatewayIdsInvalid$ = this.gatewayId.valueChanges.pipe(
    startWith(''),
    map((value) => !this.getGatewayIdsString(value))
  );

  readonly canManageGateways = ['local', 'development', 'production'].includes(environment.environment);

  constructor(private httpClient: HttpClient) {}

  async initializeSisMapAssetStatus(): Promise<void> {
    const guid = this.mapGuid.value;
    const url = `${environment.baseUrlApi}/sismapassetstatus`;

    await firstValueFrom(this.httpClient.post(url, { MapGuid: guid }));
  }

  async restartGateway(): Promise<void> {
    const gatewayIdsString = this.getGatewayIdsString(this.gatewayId.value);

    const url = `${environment.baseUrlApi}/sisadmin/restartgateway/${gatewayIdsString}`;

    try {
      this.restartGatewaysResponses = await firstValueFrom(
        this.httpClient.get<Array<{ gatewayId: string; success: boolean }>>(url)
      );
      this.restartGatewaysResponses.sort((a, b) => a.gatewayId.localeCompare(b.gatewayId));
    } catch (e) {
      this.restartGatewaysResponses = [{ gatewayId: e, success: false }];
    }
  }

  async uploadGatewayUpdateScript(): Promise<void> {
    const url = `${environment.baseUrlApi}/sisadmin/updatescriptmoduleupload`;

    try {
      this.updateGatewayUpdateScriptResponses = await firstValueFrom(
        this.httpClient.get<Array<{ gatewayId: string; success: boolean }>>(url)
      );
      this.updateGatewayUpdateScriptResponses.sort((a, b) => a.gatewayId.localeCompare(b.gatewayId));
    } catch (e) {
      this.updateGatewayUpdateScriptResponses = [{ gatewayId: e, success: false }];
    }
  }

  private getGatewayIdsString(gatewayIds: string): string {
    return gatewayIds
      ?.trim()
      .replace(/\s/g, '')
      .split(',')
      .filter((s) => s.length)
      .join(',');
  }
}
