// eslint-disable-next-line simple-import-sort/imports
import { Buffer } from 'buffer';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom, merge, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { UserManagementUserUpdatedEvent, UserPermissionUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { FeatureAdapter } from 'src/app/domain/feature/feature.adapter';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { UserAdapter } from 'src/app/user-management/domain/user.adapter';
import { User } from 'src/app/user-management/domain/user.model';
import { UserManagementDataAdapter } from 'src/app/user-management/domain/user-management-data.adapter';
import { UserManagementData } from 'src/app/user-management/domain/user-management-data.model';
import { UserPermissionUpdateData } from 'src/app/user-management/user-permission-update-data.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';
import { NIL as emptyGuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private readonly featureTranslationMap: Map<string, FeatureId> = new Map([
    ['general.term.cockpit', FeatureId.COCKPIT],
    ['general.term.energy', FeatureId.COCKPIT_ENERGY],
    ['destination.term.firstEntries', FeatureId.COCKPIT_FIRSTENTRIES],
    ['destination.term.personFrequencies', FeatureId.COCKPIT_FREQUENCIES],
    ['destination.term.kassandraForecast', FeatureId.COCKPIT_KASSANDRA],
    ['destination.term.snowmaking', FeatureId.COCKPIT_SNOWMAKING],
    ['general.term.webcam', FeatureId.COCKPIT_WEBCAM],
    ['ropeway.term.remoteAccess', FeatureId.COCKPIT_REMOTEACCESS],
    ['general.term.abbSmartSensor', FeatureId.COCKPIT_ABBSMARTSENSOR],
    ['general.term.truScan', FeatureId.COCKPIT_TRUSCAN],
    ['general.term.telemetry', FeatureId.COCKPIT_TELEMETRY],
    ['general.term.ropewaySettings', FeatureId.COCKPIT_ROPEWAY_SETTINGS],
    ['general.term.mediacenter', FeatureId.SISMEDIA_MEDIACENTER],
    ['general.term.mediacenter.infoBanner', FeatureId.SISMEDIA_MEDIACENTER_INFOBANNER],
    ['general.term.infotext', FeatureId.SISMEDIA_INFOTEXT],
    ['general.term.lastSlopeControl', FeatureId.SISMEDIA_LASTSLOPECONTROL],
    ['general.term.slopesOperatingTimes', FeatureId.SISMEDIA_SLOPESOPERATINGTIMES],
    ['sismedia.phrase.assetoverride', FeatureId.SISMEDIA_ASSETOVERRIDE],
    ['general.term.lifts', FeatureId.SISMEDIA_LIFT],
    ['general.term.slopes', FeatureId.SISMEDIA_SLOPE],
    ['general.term.crossCountry', FeatureId.SISMEDIA_CROSSCOUNTRY],
    ['general.term.sledging', FeatureId.SISMEDIA_SLEDGING],
    ['general.term.trails', FeatureId.SISMEDIA_TRAIL],
    ['general.term.bike', FeatureId.SISMEDIA_BIKE],
    ['general.term.gastro', FeatureId.SISMEDIA_GASTRO],
    ['general.term.pois', FeatureId.SISMEDIA_POI],
    ['general.term.parking', FeatureId.PARKING],
    ['sismediaEdit.term.editAsset', FeatureId.SISMEDIA_ASSET_EDIT],
    ['general.term.meteoinfo', FeatureId.SISMEDIA_METEOINFO],
    ['general.term.stnet', FeatureId.SISMEDIA_STNET],
    ['general.term.timetable', FeatureId.SISMEDIA_TIMETABLE],
    ['general.term.breakdowninfo', FeatureId.BREAKDOWNINFO],
    ['general.term.operatinginfo', FeatureId.OPERATINGINFO],
    ['global.title', FeatureId.GLOBALVIEW],
    ['general.term.sisitservices', FeatureId.SISITSERVICES],
    ['general.term.usermanagement', FeatureId.USERMANAGEMENT],
    ['logbook.term.logbook', FeatureId.LOGBOOK],
    ['general.term.weblinkcollection', FeatureId.WEBLINKCOLLECTION],
    ['general.term.ledticker', FeatureId.SISMEDIA_LEDTICKER],
    ['general.term.emaildelivery', FeatureId.SISMEDIA_EMAILDELIVERY],
    ['general.term.operatinghours', FeatureId.SISMEDIA_OPERATINGHOURS],
    ['general.term.ropewayEcoMode', FeatureId.COCKPIT_ECOMODE],
    ['general.term.sambesiWhatsUp', FeatureId.COCKPIT_SAMBESI_WHATSUP],
    ['general.term.ropewayAvailability', FeatureId.COCKPIT_ROPEWAY_AVAILABILITY],
    ['general.term.ropewayClampingCountReset', FeatureId.COCKPIT_ROPEWAY_CLAMPINGCOUNT_RESET],
    ['general.term.windmonitor', FeatureId.WINDMONITOR],
  ]);

  private readonly baseUsersRequestUrl: string = '/usermanagement/users';
  private readonly basePermissionPostUrl: string = '/usermanagement/permission';
  private readonly baseFeaturesRequestUrl: string = '/usermanagement/features';
  private readonly baseBatchPermissionPostUrl: string = '/usermanagement/batchpermission';
  private readonly baseUserInfoRequestUrl: string = '/usermanagement/userinfo';

  private readonly updateRequest$ = new BehaviorSubject<void>(null);

  private readonly requiredFeature = new Feature(FeatureId.USERMANAGEMENT, FeatureAccessLevel.READ);

  private readonly userManagementDataRequest$: Observable<UserManagementData> = this.destinationService.selectedTenantFeatures$.pipe(
    switchMap((features) => {
      if (features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))) {
        return this.http.get(`${environment.baseUrlApi}${this.baseUsersRequestUrl}`).pipe(
          map((data) => UserManagementDataAdapter.adapt(data)),
          catchError(() => {
            this.showFailureToast('general.phrase.dataFetchingError');
            return EMPTY;
          })
        );
      } else {
        return EMPTY;
      }
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly userManagementDataUpdated$: Observable<UserManagementData> = merge(
    this.eventBus.observe(UserPermissionUpdatedEvent).pipe(
      withLatestFrom(this.userManagementDataRequest$, this.destinationService.selectedTenant$, this.userSettingService.userSettings$),
      map(([event, userManagementData, tenant, userSettings]) => {
        if (tenant.guid === event?.tenantGuid) {
          const user = userManagementData.users.find((u) => u.userGuid === event.userGuid);
          if (user) {
            const feature = user.features.find((f) => f.featureId === event.featureId);
            if (feature) {
              feature.featureAccessLevel = event.featureAccessLevel;
            } else {
              user.features.push(new Feature(event.featureId, event.featureAccessLevel));
            }
          }
        }

        if (event.changedBy === userSettings.userGuid) {
          const translateKey = 'usermanagement.message.accesslevel.updated';
          const userMessage = new UserMessage({
            message: translateKey,
            icon: UserMessageIcon.success,
            durationMs: 2000,
            position: 'top',
            color: UserMessageColor.green,
          });
          this.userMessageService.presentToast(userMessage);
        }

        return userManagementData;
      })
    ),
    this.eventBus.observe(UserManagementUserUpdatedEvent).pipe(
      withLatestFrom(this.userManagementDataRequest$, this.destinationService.selectedTenant$, this.userSettingService.userSettings$),
      map(([event, userManagementData, tenant, userSettings]) => {
        if (!event.updateSuccessful && event.user.userGuid === emptyGuid) {
          this.showFailureToast('usermanagement.phrase.userAddError');
          return userManagementData;
        }
        if (!event.updateSuccessful) {
          this.showFailureToast('usermanagement.phrase.userUpdateError');
          return userManagementData;
        }

        userManagementData.managedTenants = event.managedTenants;

        const user = userManagementData.users.find((u) => u.userGuid === event.user.userGuid);
        if (user) {
          // User existed on current tenant
          if (event.user.tenantGuids.includes(tenant.guid)) {
            // update an existing user
            Object.assign(user, event.user);
            this.showSuccessToast(event.user.displayName, event.user.email, 'usermanagement.phrase.userUpdated');
          } else {
            // user is not mapped anymore to this tenant
            const users = userManagementData.users;
            const index = users.findIndex((u) => u.userGuid === event.user.userGuid);
            if (index > -1) {
              users.splice(index, 1);
              this.showSuccessToast(event.user.displayName, event.user.email, 'usermanagement.phrase.userDeleted');
            }
          }
        } else {
          // User did not exist on current tenant
          if (event.user.tenantGuids.includes(tenant.guid)) {
            // user is added to this tenant
            userManagementData.users.push(event.user);
            if (event.changedBy === userSettings.userGuid) {
              this.showSuccessToast(event.user.displayName, event.user.email, 'usermanagement.phrase.userCreated');
            } else {
              this.showSuccessToast(event.user.displayName, event.user.email, 'usermanagement.phrase.userAdded');
            }
          } else {
            // User created, added, updated or deleted (basically any change) in other tenant and was and is not set in current tenant
            if (event.changedBy === userSettings.userGuid) {
              if (event.user.tenantGuids.length === 0) {
                this.showSuccessToast(event.user.displayName, event.user.email, 'usermanagement.phrase.userDeleted');
              } else {
                this.showSuccessToast(event.user.displayName, event.user.email, 'usermanagement.phrase.userUpdated');
              }
            }
          }
        }
        return userManagementData;
      })
    )
  );

  readonly userManagementData$ = merge(this.userManagementDataRequest$, this.userManagementDataUpdated$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly tenantFeatures$: Observable<Feature[]> = combineLatest([this.destinationService.selectedTenant$, this.updateRequest$]).pipe(
    switchMap(() => this.http.get(`${environment.baseUrlApi}${this.baseFeaturesRequestUrl}`)),
    map((data) => FeatureAdapter.adapt(data)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly featureTranslationMap$: Observable<Map<FeatureId, string>> = this.translateService
    .stream(Array.from(this.featureTranslationMap.keys()))
    .pipe(
      map((res) => {
        const translatedFeaturesMap = new Map<FeatureId, string>();
        Object.entries(res).forEach((translation) => {
          translatedFeaturesMap.set(this.featureTranslationMap.get(translation[0]), translation[1].toString());
        });

        return translatedFeaturesMap;
      }),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

  private activeUserToast: HTMLIonToastElement;

  constructor(
    private eventBus: EventBusService,
    private http: HttpClient,
    private userMessageService: UserMessageService,
    private userSettingService: UserSettingsService,
    private translateService: TranslateService,
    private destinationService: DestinationService,
    private toastService: UserMessageService
  ) {}

  requestData(): void {
    this.updateRequest$.next();
  }

  async updateUserPermission(userPermissionUpdateData: UserPermissionUpdateData): Promise<void> {
    await firstValueFrom(
      this.http.post(`${environment.baseUrlApi}${this.basePermissionPostUrl}`, userPermissionUpdateData).pipe(
        catchError(() => {
          this.showFailureToast('usermanagement.message.accesslevel.failed');
          return of();
        })
      )
    );
  }

  async setBatchPermissions(featuresToUpdate: Feature[], userGuids: string[]): Promise<void> {
    if (featuresToUpdate.length > 0 && userGuids.length > 0) {
      await firstValueFrom(
        this.http
          .post(`${environment.baseUrlApi}${this.baseBatchPermissionPostUrl}`, {
            userGuids: userGuids,
            features: featuresToUpdate,
          })
          .pipe(
            catchError(() => {
              this.showFailureToast('usermanagement.message.accesslevel.failed');
              return of();
            })
          )
      );
    }
  }

  async updateUser(user: User): Promise<boolean> {
    return firstValueFrom(
      this.http.post(`${environment.baseUrlApi}/usermanagement/user/update`, user).pipe(
        map(() => true),
        catchError(() => {
          this.showFailureToast('usermanagement.phrase.userUpdateError');
          return of(false);
        })
      )
    );
  }

  async deleteUser(user: User): Promise<boolean> {
    user.tenantGuids = [];
    return firstValueFrom(
      this.http.post(`${environment.baseUrlApi}/usermanagement/user/update`, user).pipe(
        map(() => true),
        catchError(() => {
          this.showFailureToast('usermanagement.phrase.userDeleteError');
          return of(false);
        })
      )
    );
  }

  async addUser(user: User): Promise<boolean> {
    return firstValueFrom(
      this.http.post(`${environment.baseUrlApi}/usermanagement/user/update`, user).pipe(
        map(() => true),
        catchError(() => {
          this.showFailureToast('usermanagement.phrase.userAddError');
          return of(false);
        })
      )
    );
  }

  getUserInfoRequest(email: string): Observable<User> {
    const emailEncoded = Buffer.from(email, 'utf8').toString('base64');
    const url = `${environment.baseUrlApi}${this.baseUserInfoRequestUrl}/${emailEncoded}`;
    return this.http.get(url).pipe(
      map((user) => {
        if (user) {
          this.showInfoToast('usermanagement.phrase.emailAlreadyExists');
          return UserAdapter.adapt([user])[0];
        }
        return null;
      }),
      catchError(() => {
        this.showFailureToast('general.phrase.dataFetchingError');
        return EMPTY;
      })
    );
  }

  async showFailureToast(message: string): Promise<void> {
    await this.showUserMessageToast(
      new UserMessage({
        message: message,
        icon: UserMessageIcon.failed,
        durationMs: 4000,
        position: 'top',
        color: UserMessageColor.red,
        showCloseButton: false,
      })
    );
  }

  private async showInfoToast(message: string): Promise<void> {
    await this.showUserMessageToast(
      new UserMessage({
        message: message,
        icon: UserMessageIcon.info,
        durationMs: 4000,
        position: 'top',
        color: UserMessageColor.blue,
        showCloseButton: false,
      })
    );
  }

  private async showSuccessToast(displayName: string, email: string, message: string): Promise<void> {
    await this.showUserMessageToast(
      new UserMessage({
        message: [`${displayName} &lt;${email}&gt;<br>`, message],
        icon: UserMessageIcon.success,
        durationMs: 3000,
        position: 'top',
        color: UserMessageColor.green,
        showCloseButton: false,
      })
    );
  }

  private async showUserMessageToast(message: UserMessage): Promise<void> {
    const toast = await this.toastService.createSingleToast(message);

    await this.activeUserToast?.dismiss();
    await toast.present();
    this.activeUserToast = toast;
  }
}
