import { TimetableIntervalAdapter } from 'src/app/timetable/domain/timetable-interval.adapter';
import { TimetableSeasonAdapter } from 'src/app/timetable/domain/timetable-season.adapter';
import { TimetableTrack } from 'src/app/timetable/domain/timetable-track.model';

export class TimetableTrackAdapter {
  static adapt(items: any): TimetableTrack[] {
    const tracks: TimetableTrack[] = [];

    if (items) {
      items.forEach((item: any) => {
        const seasons = TimetableSeasonAdapter.adapt(item.seasons);
        seasons.sort((a, b) => new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime());

        const intervals = TimetableIntervalAdapter.adapt(item.intervals);
        intervals.sort((a, b) => a.interval - b.interval);
        intervals.forEach((interval) => interval.intervalDays.sort((a, b) => a.date.getTime() - b.date.getTime()));

        tracks.push({
          guid: item.guid,
          tenantGuid: item.tenantGuid,
          name: item.name,
          displayOrder: item.displayOrder,
          type: item.type,
          seasons,
          intervals,
          stations: item.stations,
        });
      });
    }

    return tracks;
  }
}
