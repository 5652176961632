import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';

@Injectable({ providedIn: 'root' })
export class CanActivateGuard {
  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userSettingsService: UserSettingsService
  ) {}

  canActivate(): Observable<boolean> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None || status === InteractionStatus.Startup),
      map(() => this.msalService.instance.getAllAccounts().length > 0)
    );
  }

  isAdministrator(): Observable<boolean> {
    return this.userSettingsService.userSettings$.pipe(map((u) => u.isAdministrator));
  }
}
