import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { RopewaySettingsUpdatedEvent, TenantUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MasterdataAdapter } from 'src/app/maps/domain/masterdata.adapter';
import { MasterData } from 'src/app/maps/domain/masterdata.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MasterdataService {
  static readonly urlPath = '/masterdata';

  private readonly requiredFeature = new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ);

  private readonly emptyMasterData: MasterData = {
    sisId: '',
    gatewayId: '',
    firstEntriesGroups: [],
    meteoStations: [],
    ropeways: [],
    customMarkers: [],
  };

  private readonly masterDataRequest$: Observable<MasterData> = this.destinationService.selectedTenantFeatures$.pipe(
    distinctUntilChanged((pre, curr) => pre === curr),
    switchMap((features) => {
      if (features == null || !this.hasCockpitFeature(features)) {
        return of(this.emptyMasterData);
      } else {
        return merge(
          of(this.emptyMasterData),
          this.http.get(environment.baseUrlApi + MasterdataService.urlPath).pipe(map((data: any) => MasterdataAdapter.adapt(data)))
        );
      }
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly ropewaySettingsUpdate$: Observable<MasterData> = this.eventBus.observe(RopewaySettingsUpdatedEvent).pipe(
    filter((event) => event.updateSuccessful),
    map((event) => event.ropewaySettings),
    withLatestFrom(this.masterDataRequest$),
    map(([ropewaySettingsUpdate, masterData]) => {
      const updatedRopeway = masterData.ropeways.find((r) => r.guid === ropewaySettingsUpdate.guid);
      if (!updatedRopeway) {
        return masterData;
      }
      updatedRopeway.alias = ropewaySettingsUpdate.alias;
      updatedRopeway.fullname = ropewaySettingsUpdate.fullname;
      updatedRopeway.constructedBy = ropewaySettingsUpdate.constructedBy;
      updatedRopeway.controllerType = ropewaySettingsUpdate.controllerType;
      updatedRopeway.maxTransportQuantityPerHour = ropewaySettingsUpdate.maxTransportQuantityPerHour;
      updatedRopeway.availability = ropewaySettingsUpdate.availability;
      updatedRopeway.availabilityIsAvailable = ropewaySettingsUpdate.availabilityIsAvailable;

      return masterData;
    })
  );

  private readonly tenantUpdate$: Observable<MasterData> = this.eventBus.observe(TenantUpdatedEvent).pipe(
    filter((event) => event.updateSuccessful),
    map((event) => event.tenant),
    withLatestFrom(this.masterDataRequest$),
    map(([tenantUpdatedEvent, masterData]) => {
      if (tenantUpdatedEvent.guid === masterData.guid) {
        masterData.alias = tenantUpdatedEvent.alias;
        masterData.fullname = tenantUpdatedEvent.fullname;
        masterData.gatewayId = tenantUpdatedEvent.gatewayId;
        masterData.logoFilename = tenantUpdatedEvent.logoFilename;
        masterData.panoMapFilename = tenantUpdatedEvent.panoMapFilename;
        masterData.sisId = tenantUpdatedEvent.sisId;

        return masterData;
      }
    })
  );

  readonly masterData$: Observable<MasterData> = merge(this.masterDataRequest$, this.ropewaySettingsUpdate$, this.tenantUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(private http: HttpClient, private destinationService: DestinationService, private eventBus: EventBusService) {}

  private hasCockpitFeature(features: Feature[]): boolean {
    return features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature));
  }
}
