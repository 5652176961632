import 'leaflet.fullscreen';

import { Component, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LatLngBoundsExpression, LatLngExpression, Layer, LayerGroup, layerGroup, Map, MapOptions } from 'leaflet';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { Tenant } from 'src/app/domain/tenant/tenant.model';
import { TenantService } from 'src/app/domain/tenant/tenant.service';

declare let L: any;
@Component({
  selector: 'sis-geo-map',
  templateUrl: './geomap.component.html',
  styleUrls: ['./geomap.component.scss'],
})
export class GeoMapComponent extends Unsubscriber implements OnInit {
  private defaultBounds: LatLngBoundsExpression = [
    [47.8819, 5.73742],
    [45.70702, 10.58819],
  ]; // Switzerland
  private defaultCenter: LatLngExpression = [46.798268, 8.231462];

  fullScreenControl: any;
  map: Map;
  tenantGroup: LayerGroup = layerGroup();
  tenants: Tenant[] = [];
  options: MapOptions;
  layers: Layer[];
  layersControl: any;
  bigScreenMode: boolean;

  constructor(
    private zone: NgZone,
    private translateService: TranslateService,
    private tenantService: TenantService,
    private screenSizeService: ScreenSizeService
  ) {
    super();

    this.initLeafletConfigureOptions();
    this.initLayers();
    this.initLayerControl();
  }

  private initLayers() {
    // https://github.com/Asymmetrik/ngx-leaflet#add-custom-layers-base-layers-markers-shapes-etc
    this.layers = [layerGroup([this.tenantGroup])];
  }

  private initLayerControl() {
    // https://github.com/Asymmetrik/ngx-leaflet#add-a-layers-control
    this.updateLayerControl();
    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.updateLayerControl();
    });
  }

  private updateLayerControl() {
    this.translateService
      .get(['general.term.destinations'])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res) => {
        this.layersControl = {
          overlays: {
            [res['general.term.destinations']]: this.tenantGroup,
          },
        };
      });
  }

  private initLeafletConfigureOptions() {
    // https://github.com/Asymmetrik/ngx-leaflet#create-and-configure-a-map
    this.options = {
      zoomControl: false,
      zoom: 8.6,
      center: this.defaultCenter,
      minZoom: 8.6,
      maxZoom: 11,
      zoomSnap: 0.1,
      zoomDelta: 0.1,
      maxBoundsViscosity: 0.5,
      maxBounds: this.defaultBounds,
    };
  }

  ngOnInit() {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.tenantService.tenants$.pipe(takeUntil(this.onDestroy$)).subscribe((tenants) => (this.tenants = tenants));
  }

  async onMapReady(map: Map) {
    this.map = map;
    this.setupMap();
  }

  private setupMap() {
    const tileLayer = L.tileLayer(
      'https://api.mapbox.com/styles/v1/{userId}/{styleId}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        userId: 'siscontrol',
        styleId: 'ck56fb86x0awv1cp3f9tt8xn1',
        accessToken: 'pk.eyJ1Ijoic2lzY29udHJvbCIsImEiOiJjazRueTRhOTEwanpiM2RzM2pzcXM1c3dvIn0.0panxf8Dw_Skci3gdY5uuw',
      }
    );
    this.layers.push(tileLayer);
    this.addFullScreenControl();
    this.fitBounds();
  }

  onMapFullscreenToggle() {
    timer(50)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() =>
        this.zone.run(() => {
          this.fitBounds();
        })
      );
  }

  onFitBounds(event: Event) {
    event.stopPropagation();
    this.fitBounds();
  }

  fitBounds() {
    this.map.fitBounds(this.defaultBounds);
  }

  onZoomIn(event: Event) {
    event.stopPropagation();
    this.zoomIn();
  }

  zoomIn() {
    this.map.setZoom(this.map.getZoom() + 0.5);
  }

  onZoomOut(event: Event) {
    event.stopPropagation();
    this.zoomOut();
  }

  zoomOut() {
    this.map.setZoom(this.map.getZoom() - 0.5);
  }

  onFullscreen(event: Event) {
    event.stopPropagation();
    this.fullscreen();
  }

  fullscreen() {
    this.fullScreenControl.toggleFullScreen();
    this.fitBounds();
  }

  private addFullScreenControl() {
    if (this.fullScreenControl) {
      return;
    }
    this.fullScreenControl = L.control
      .fullscreen({
        forceSeparateButton: true,
      })
      .addTo(this.map);
    this.map.on('enterFullscreen exitFullscreen', () => this.onMapFullscreenToggle());
  }
}
