import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, merge, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { InfotextUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { Category } from 'src/app/infotext/domain/category.model';
import { InfotextAdapter } from 'src/app/infotext/domain/infotext.adapter';
import { Infotext } from 'src/app/infotext/domain/infotext.model';
import { InfotextPostData } from 'src/app/infotext/domain/infotext-postdata.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InfotextService {
  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_INFOTEXT, FeatureAccessLevel.READ);

  private readonly baseRequestUrl: string = '/sismedia/infotext';
  private readonly basePostUrl: string = '/sismedia/infotext/update';

  private readonly categoriesRequest$: Observable<Category[]> = this.destinationService.selectedTenantFeatures$.pipe(
    switchMap((features) => {
      if (features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))) {
        return this.http
          .get(`${environment.baseUrlApi}${this.baseRequestUrl}`)
          .pipe(map((data: any) => InfotextAdapter.adapt(data)));
      } else {
        return of([]);
      }
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  private readonly categoriesUpdate$: Observable<Category[]> = this.eventBus.observe(InfotextUpdatedEvent).pipe(
    withLatestFrom(this.categoriesRequest$, this.userSettingsService.userSettings$),
    map(([event, categories, userSettings]) => {
      const category = categories.find((c) => c.id === event.categoryId);
      if (category) {
        const infotextData = category.infotexts.find((i) => i.guid === event.guid);
        if (infotextData) {
          infotextData.textDe = event.textDe;
          infotextData.textEn = event.textEn;
          infotextData.textFr = event.textFr;
          infotextData.textIt = event.textIt;

          infotextData.translatedText = this.getValueByLang(infotextData, 'text');
        }
      }
      if (event.changedBy === userSettings.userGuid.toString()) {
        this.presentToast(event.updateSuccessful, event.failReason);
      }
      return categories;
    })
  );

  readonly categories$ = merge(this.categoriesRequest$, this.categoriesUpdate$).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService,
    private http: HttpClient,
    private translateService: TranslateService,
    private userMessageService: UserMessageService,
    private destinationService: DestinationService
  ) {}

  async updateInfotext(updatedInfotext: InfotextPostData): Promise<boolean> {
    try {
      await firstValueFrom(
        this.http.post(`${environment.baseUrlApi}${this.basePostUrl}`, {
          infotextEntry: updatedInfotext,
        })
      );
    } catch {
      return false;
    }

    return true;
  }

  getValueByLang(obj: Category | Infotext, property: 'category' | 'text' | 'title'): string {
    return obj[property + this.translateService.currentLang.replace(/^\w/g, (c) => c.toUpperCase())];
  }

  private presentToast(success: boolean, failReason?: string): void {
    const message = success
      ? 'general.phrase.saved'
      : failReason
      ? ['general.phrase.saveFailed', ' (', failReason, ')']
      : 'general.phrase.saveFailed';
    const icon = success ? UserMessageIcon.success : UserMessageIcon.failed;
    const color = success ? UserMessageColor.green : UserMessageColor.red;
    this.userMessageService.presentToast(new UserMessage({ message, icon, durationMs: 2000, position: 'top', color }));
  }
}
