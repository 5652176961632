<ion-header>
  <ion-toolbar>
    <ion-title class="sis-breakdowninfo-header">
      <ion-icon class="sis-breakdowninfo-icon" [name]="this.iconName"></ion-icon>
      <ion-label class="sis-breakdowninfo-title">{{ breakdownInfo.name }}</ion-label>
    </ion-title>
    <ion-button fill="clear" slot="end" (click)="this.done()">
      <ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>
<ion-content scroll-y="false">
  <iframe class="sis-iframe" [src]="this.safeInfoLink" frameborder="0"></iframe>
</ion-content>
