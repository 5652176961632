import { LedTicker } from 'src/app/led-ticker/domain/led-ticker.model';
import { LedTickerItemAdapter } from 'src/app/led-ticker/domain/led-ticker-item.adapter';
import { LedTickerItem } from 'src/app/led-ticker/domain/led-ticker-item.model';

export class LedTickerAdapter {
  private static readonly maxRows = 20;

  static adapt(item: any): LedTicker[] {
    if (!item || !item.ledTickers) {
      return [];
    }

    const tickerMap = new Map<string, LedTicker>();

    item.ledTickers.forEach((item) => {
      if (!tickerMap.has(item.ledTickerGuid)) {
        tickerMap.set(item.ledTickerGuid, {
          guid: item.ledTickerGuid,
          groupCount: this.maxRows / Number.parseInt(item.rows),
          itemsPerGroup: Number.parseInt(item.rows),
          maxTextLength: Number.parseInt(item.cols),
          name: item.name,
          items: [],
          groupedItems: new Map<string, LedTickerItem[]>(),
        });
      }

      const ledTicker = tickerMap.get(item.ledTickerGuid);

      const ledTickerItem = LedTickerItemAdapter.adapt(item);

      if (!ledTicker.groupedItems.has(ledTickerItem.ledTickerPageGuid)) {
        ledTicker.groupedItems.set(ledTickerItem.ledTickerPageGuid, []);
      }

      ledTicker.groupedItems.get(ledTickerItem.ledTickerPageGuid).push(ledTickerItem);

      ledTicker.items.push(ledTickerItem);
    });

    return [...tickerMap.values()];
  }
}
