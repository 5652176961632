import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { WebLinkCollectionPage } from 'src/app/weblink-collection/weblink-collection.page';
import { WeblinkEditModalComponent } from 'src/app/weblink-collection/weblink-edit-modal/weblink-edit-modal.component';
import { WeblinkGroupComponent } from 'src/app/weblink-collection/weblink-group/weblink-group.component';
import { WebLinkItemComponent } from 'src/app/weblink-collection/weblink-item/weblink-item.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatTooltipModule,
    RouterModule.forChild([
      {
        path: '',
        component: WebLinkCollectionPage,
      },
    ]),
    TranslateModule,
  ],
  declarations: [WebLinkCollectionPage, WebLinkItemComponent, WeblinkEditModalComponent, WeblinkGroupComponent],
})
export class WebLinkCollectionModule {}
