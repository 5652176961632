import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import {
  distinctUntilChanged,
  filter,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  takeUntil,
  timer,
  withLatestFrom,
} from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { RopewayAvailabilityTrendUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { RopewayAvailabilityTrend } from 'src/app/maps/trenddata/ropeway-availability-trend.model';
import { RopewayAvailabilityTrendService } from 'src/app/maps/trenddata/ropeway-availability-trend.service';
import { RopewayAvailabilityTrendChartItem } from 'src/app/maps/widgets/detailviews/ropeway-availability-detailview/ropeway-availability-trend-chart-item.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Component({
  selector: 'sis-ropeway-availability-detailview',
  templateUrl: './ropeway-availability-detailview.component.html',
  styleUrls: ['./ropeway-availability-detailview.component.scss'],
})
export class RopewayAvailabilityDetailViewComponent extends Unsubscriber implements OnInit {
  private static readonly requiredWriteFeature = new Feature(
    FeatureId.COCKPIT_ROPEWAY_AVAILABILITY,
    FeatureAccessLevel.WRITE
  );
  private readonly selectedTimespan$: ReplaySubject<string> = new ReplaySubject(1);

  private readonly trendDataMonth$ = this.selectedMapElementService.selectedRopeway$.pipe(
    distinctUntilChanged(),
    filter((ropeway) => !!ropeway),
    switchMap((ropeway) =>
      this.ropewayAvailabilityTrendService.getAvailabilityLongtermTrendData(ropeway.sisId, 'month')
    ),
    map((data: RopewayAvailabilityTrend[]) => {
      this.loadingData = false;
      const trends: RopewayAvailabilityTrendChartItem[] = data.map((d) => {
        const tooltip = this.toRoundedString(d.availability * 100, 1) + '%';

        return {
          availability: d.availability,
          barHeightPercent: tooltip,
          barTooltip: tooltip,
          dateTooltip: `${this.translateService.instant('general.term.week')} ${d.week} ${d.year}`,
          shownDate: `${d.week}`,
          ignore: d.ignore,
          ignorable: false,
          date: d.date,
        };
      });

      return trends.sort((a, b) => (a.date < b.date ? -1 : 1));
    })
  );

  private readonly trendDataYear$ = this.selectedMapElementService.selectedRopeway$.pipe(
    distinctUntilChanged(),
    filter((ropeway) => !!ropeway),
    switchMap((ropeway) =>
      this.ropewayAvailabilityTrendService.getAvailabilityLongtermTrendData(ropeway.sisId, 'year')
    ),
    map((data: RopewayAvailabilityTrend[]) => {
      this.loadingData = false;

      const trends: RopewayAvailabilityTrendChartItem[] = data.map((d) => {
        const tooltip = this.toRoundedString(d.availability * 100, 1) + '%';

        return {
          availability: d.availability,
          barHeightPercent: tooltip,
          barTooltip: tooltip,
          dateTooltip: `${d.month}.${d.year}`,
          shownDate: `${d.month}`,
          ignore: d.ignore,
          ignorable: false,
          date: d.date,
        };
      });

      return trends.sort((a, b) => (a.date < b.date ? -1 : 1));
    })
  );

  private readonly trendDataWeek$ = this.selectedMapElementService.selectedRopeway$.pipe(
    distinctUntilChanged(),
    filter((ropeway) => !!ropeway),
    switchMap((ropeway) =>
      timer(0, 10000).pipe(
        switchMap(() => this.ropewayAvailabilityTrendService.getAvailabilityTrendData(ropeway.sisId))
      )
    ),
    takeUntil(this.onDestroy$),
    map((data: RopewayAvailabilityTrend[]) => {
      this.loadingData = false;
      const trends: RopewayAvailabilityTrendChartItem[] = [];

      const today = new Date();

      for (let i = 6; i >= 0; i--) {
        const d = new Date();
        d.setDate(today.getDate() - i);
        const timezoneOffset = d.getTimezoneOffset();

        const day = new Date(
          Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - i, 0, timezoneOffset)
        );

        const dataForDay = data?.find((d) => this.isSameDay(d.date, day));
        if (dataForDay) {
          const availabilityPercent = this.toRoundedString(dataForDay.availability * 100, 1) + '%';
          let tooltip = availabilityPercent;
          if (dataForDay.ignore) {
            tooltip += ` [${this.translateService.instant('ropewayAvailability.phrase.ignored')}]`;
          }

          trends.push({
            ropewaySisId: dataForDay.ropewaySisId,
            tenantSisId: dataForDay.tenantSisId,
            availability: dataForDay.availability,
            barHeightPercent: availabilityPercent,
            barTooltip: tooltip,
            dateTooltip: moment(dataForDay.date).format('DD.MM.YYYY'),
            shownDate: moment(dataForDay.date).format('DD.MM.'),
            ignore: dataForDay.ignore,
            ignorable: true,
            date: dataForDay.date,
          });
        } else {
          trends.push({
            availability: null,
            barHeightPercent: '100%',
            barTooltip: '?',
            dateTooltip: moment(day).format('DD.MM.YYYY'),
            shownDate: moment(day).format('DD.MM.'),
            ignore: true,
            ignorable: true,
            date: day,
          });
        }
      }

      return trends;
    })
  );

  private readonly shownTrendData$: Observable<RopewayAvailabilityTrendChartItem[]> = this.selectedTimespan$.pipe(
    switchMap((timespan) => {
      if (timespan === 'week') {
        return this.trendDataWeek$;
      }
      if (timespan === 'month') {
        return this.trendDataMonth$;
      }
      if (timespan === 'year') {
        return this.trendDataYear$;
      }
    })
  );

  readonly timespanSelectors = [
    { value: 'week', translationString: 'general.term.week' },
    { value: 'month', translationString: 'general.term.month' },
    { value: 'year', translationString: 'general.term.year' },
  ];

  loadingData: boolean = true;
  noData: boolean = false;
  meanValue: number;
  meanValuePercent: string;
  chartBarWidth: number;

  trends: RopewayAvailabilityTrendChartItem[] = [];
  writePermission: boolean;

  constructor(
    private ropewayAvailabilityTrendService: RopewayAvailabilityTrendService,
    private selectedMapElementService: SelectedMapElementService,
    private confirmationDialogService: ConfirmationDialogService,
    private eventBusService: EventBusService,
    private userSettingsService: UserSettingsService,
    private userMessageService: UserMessageService,
    private destinationService: DestinationService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedTimespan$.next('week');

    this.shownTrendData$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      if (!data || data.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
        this.trends = data;
        this.chartBarWidth = (320 - (this.trends.length + 1) * 5) / this.trends.length;
      }

      this.calculateMeanValue();
    });

    this.eventBusService
      .observe(RopewayAvailabilityTrendUpdatedEvent)
      .pipe(withLatestFrom(this.userSettingsService.userSettings$), takeUntil(this.onDestroy$))
      .subscribe(async ([updatedEvent, userSettings]) => {
        if (userSettings.userGuid === updatedEvent.changedBy) {
          const message = updatedEvent.updateSuccessful ? 'general.phrase.saved' : 'general.phrase.saveFailed';
          const icon = updatedEvent.updateSuccessful ? UserMessageIcon.success : UserMessageIcon.failed;
          const color = updatedEvent.updateSuccessful ? UserMessageColor.green : UserMessageColor.red;
          this.userMessageService.presentToast(
            new UserMessage({ message, icon, durationMs: 2000, position: 'top', color })
          );
        }

        if (updatedEvent.updateSuccessful) {
          const updatedTrend = this.trends.find(
            (t) =>
              t.ropewaySisId === updatedEvent.ropewaySisId &&
              t.tenantSisId === updatedEvent.tenantSisId &&
              t.date.toISOString() === updatedEvent.date
          );
          if (updatedTrend) {
            updatedTrend.ignore = updatedEvent.ignore;
            let tooltip = this.toRoundedString(updatedTrend.availability * 100, 1) + '%';
            if (updatedTrend.ignore) {
              tooltip += ` [${this.translateService.instant('ropewayAvailability.phrase.ignored')}]`;
            }
            updatedTrend.barTooltip = tooltip;

            this.calculateMeanValue();
          }
        }
      });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission =
        features.some((f) => f.hasMinimumRequirementFor(RopewayAvailabilityDetailViewComponent.requiredWriteFeature)) ??
        false;
    });
  }

  async openIgnoreDialog(trend: RopewayAvailabilityTrendChartItem): Promise<void> {
    if (trend.availability !== null && this.writePermission) {
      const confirmText = trend.ignore
        ? 'ropewayAvailability.phrase.includeTrend'
        : 'ropewayAvailability.phrase.ignoreTrend';
      const confirmed = await this.confirmationDialogService.presentAlert(confirmText, 'general.term.yes');

      if (confirmed) {
        trend.ignore = !trend.ignore;

        await this.ropewayAvailabilityTrendService.postIgnore(
          trend.tenantSisId,
          trend.ropewaySisId,
          trend.date.toISOString(),
          trend.ignore
        );
      }
    }
  }

  selectTimespan(timespan: string): void {
    this.trends = [];
    this.noData = false;
    this.loadingData = true;
    this.selectedTimespan$.next(timespan);
  }

  private calculateMeanValue(): void {
    const relevantTrendData = this.trends.filter((a) => !a.ignore);
    this.meanValue =
      relevantTrendData.reduce((sum, current) => (sum += current.availability), 0) / relevantTrendData.length;
    this.meanValuePercent = this.toRoundedString(this.meanValue * 100, 1) + '%';
  }

  private isSameDay(d1: Date, d2: Date): boolean {
    return (
      d1.getUTCDate() === d2.getUTCDate() &&
      d1.getUTCMonth() === d2.getUTCMonth() &&
      d1.getUTCFullYear() === d2.getUTCFullYear()
    );
  }

  private toRoundedString(value: number, decimals: number): string {
    return Number.parseFloat(value.toFixed(decimals)).toString();
  }
}
