import { LayerGroup, Polyline } from 'leaflet';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayCssClassNames } from 'src/app/maps/map/ropeway-layer/ropeway-line/ropeway-css-classnames';

export class RopewayPolyLine {
  line: Polyline;
  highlight: Polyline;
  border: Polyline;
  clickable: Polyline;

  constructor(private ropeway: Ropeway) {}

  create(ropewayGroup: LayerGroup): void {
    const coords = this.getCoordsOfRopewayPolyline(this.ropeway);

    // ropeway highlight behind border one for increased visibility when selected
    this.highlight = this.createPolyline(coords, RopewayCssClassNames.cssRopewayInvisible);

    // ropeway border behind visible one for increased visibility
    this.border = this.createPolyline(coords, RopewayCssClassNames.cssRopewayDefault + RopewayCssClassNames.cssBorder);

    // ropeway itself
    this.line = this.createPolyline(coords, RopewayCssClassNames.cssRopewayDefault);

    // Bigger invisible ropeway line on top of visible one for increased click tolerance
    this.clickable = this.createPolyline(coords, RopewayCssClassNames.cssRopewayClickable);

    ropewayGroup.addLayer(this.highlight);
    ropewayGroup.addLayer(this.border);
    ropewayGroup.addLayer(this.line);
    ropewayGroup.addLayer(this.clickable);
  }

  private createPolyline(coords: any[], cssClassName: string) {
    const polyline = new Polyline(coords, {
      interactive: true,
      className: cssClassName,
    });
    return polyline;
  }

  // Get coords of the ropeway polyline ("y1,x1|y2,x2|...") from the ropeway masterdata to an array (["y1","x1"] ["y2","x2"] [...])
  private getCoordsOfRopewayPolyline(ropeway: Ropeway) {
    const coords = [];
    const splittedCoords = ropeway.pathPanoMap.split('|');
    for (const i of splittedCoords) {
      coords.push(i.split(','));
    }
    return coords;
  }

  getRopewayElements(): Element[] {
    return [this.line.getElement(), this.border.getElement(), this.highlight.getElement(), this.clickable.getElement()];
  }
}
