// import { SisConverter } from 'src/app/core/utils/sis-converter';
import { GinLivedata } from 'src/app/maps/livedata/gin-livedata.model';
import { SisOutdatedHelper } from 'src/app/maps/livedata/sis-outdated-helper';

export class GinLivedataAdapter {
  static adapt(item: any): GinLivedata[] {
    const livedata: GinLivedata[] = [];

    if (item) {
      item.forEach((e: any) => {
        livedata.push({
          id: e.Id,
          ginName: e.Name,
          timestamp: e.Timestamp,
          dataTimestamp: GinLivedataAdapter.formatDate(e.DataTimestamp),
          value: e.Value.toFixed(1),
          unit: e.Unit,
          outdated: SisOutdatedHelper.isOutdated(e.DataTimestamp, 3600000),
        });
      });
    }

    return livedata;
  }

  private static formatDate(date: string): string {
    const d = new Date(date);
    return `${d.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })} ${d.toLocaleTimeString('de-DE')}`;
  }
}
