<ion-content class="sis-mediacenter-content">
  <ion-grid class="sis-mediacenter-no-scroll sis-mediacenter-grid ion-no-padding">
    <ion-row>
      <ion-col [size]="this.bigScreenMode ? 3 : 12" class="ion-no-padding">
        <ion-searchbar
          *ngIf="!(this.expandedPlaylistGuid || this.newPlaylist)"
          placeholder="{{ 'general.term.filter' | translate }}"
          type="text"
          [formControl]="searchControl"
          (ionChange)="this.searching = true"
          data-test="playlist-searchbar"
        >
        </ion-searchbar>
      </ion-col>
      <ion-col class="sis-global-center" size="0.5">
        <ion-spinner *ngIf="searching" style="height: 34px" name="bubbles"></ion-spinner>
      </ion-col>
      <ion-col style="align-self: center">
        <sis-logbook-button
          *ngIf="this.logbookAvailable$ | async"
          class="sis-mediacenter-logbook-button"
          [showText]="this.bigScreenMode"
          (click)="openLogbook()"
        ></sis-logbook-button>
      </ion-col>
      <ion-col size="auto">
        <sis-general-button
          *ngIf="this.bigScreenMode"
          class="sis-mediacenter-new"
          [text]="'mediacenter.phrase.newPlaylist'"
          [btnType]="'Secondary'"
          (sisClick)="this.writePermission && this.insertNewPlaylist()"
          [disabled]="!this.writePermission || this.expandedPlaylistGuid || this.newPlaylist"
        ></sis-general-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="this.bigScreenMode" class="sis-mediacenter-header">
      <ion-col class="sis-mediacenter-padding-left">{{ 'general.term.name' | translate }}</ion-col>
      <ion-col size="1" class="sis-global-center">{{ 'mediacenter.term.slideshow' | translate }}</ion-col>
      <ion-col size="2" class="sis-global-center">{{ 'mediacenter.phrase.numberOfSlides' | translate }}</ion-col>
      <ion-col size="1" class="sis-global-center">{{ 'general.term.delete' | translate }}</ion-col>
      <ion-col size="0.5"></ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid class="sis-mediacenter-grid" style="height: calc(100% - 130px); padding-top: 0">
    <ion-content sis-ion-content-scrollbar #itemGridContent>
      <sis-media-center-playlist-item
        *ngIf="this.newPlaylist"
        [playlist]="this.newPlaylist"
        [expanded]="true"
        [otherExpanded]="false"
        (removeNewPlaylist)="this.removeNewPlaylist()"
      ></sis-media-center-playlist-item>
      <sis-media-center-playlist-item
        *ngFor="let playlist of this.playlistsFiltered; trackBy: this.trackByPlaylistName"
        [playlist]="playlist"
        [expanded]="this.expandedPlaylistGuid == playlist.guid && !this.newPlaylist"
        [otherExpanded]="this.expandedPlaylistGuid != undefined || this.newPlaylist"
        (toggleExpanded)="this.toggleExpanded(playlist.guid, $event)"
        (edited)="this.checkEdited()"
      >
      </sis-media-center-playlist-item>
    </ion-content>
  </ion-grid>
</ion-content>
