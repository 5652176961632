export interface AbbSmartSensorData {
  timestamp: Date;
  assetId: number;
  deviceSisId: string;
  tenantSisId: string;
  conditionIndexForAvailability: AbbSmartSensorConditionStatus;
  conditionIndexForEnvironment: AbbSmartSensorConditionStatus;
  conditionIndexForReliability: AbbSmartSensorConditionStatus;
  conditionIndexForStress: AbbSmartSensorConditionStatus;
  overallConditionIndex: AbbSmartSensorConditionStatus;
}

export enum AbbSmartSensorConditionStatus {
  Poor = 0,
  Tolerable = 1,
  Good = 2,
  NotApplicable = 3,
  Unknown = 4,
}
