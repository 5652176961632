import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, filter, map, merge, Observable, of, shareReplay, switchMap, withLatestFrom } from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { EcoModeSettingsUpdatedEvent, RopewaySettingsUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { RopewayEcoModeSettingsAdapter } from 'src/app/maps/domain/ropeway-ecomode/ropeway-ecomode-settings.adapter';
import { RopewayEcoModeSettings } from 'src/app/maps/domain/ropeway-ecomode/ropeway-ecomode-settings.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RopewayEcoModeSettingsService {
  private readonly baseRequestUrl: string = '/ropeway/ecomodesettings';
  private readonly basePostUrl: string = '/ropeway/ecomodesettings';
  private readonly requiredFeature = new Feature(FeatureId.COCKPIT_ECOMODE, FeatureAccessLevel.READ);

  private readonly ropewayEcoModeSettingsRequest$: Observable<RopewayEcoModeSettings[]> =
    this.destinationService.selectedTenantFeatures$.pipe(
      switchMap((features) => {
        if (features.some((f) => f.hasMinimumRequirementFor(this.requiredFeature))) {
          return this.http.get(`${environment.baseUrlApi}${this.baseRequestUrl}`);
        }
        return of([]);
      }),
      map((data) => RopewayEcoModeSettingsAdapter.adapt(data)),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

  private readonly ropewayEcoModeSettingsUpdate$: Observable<RopewayEcoModeSettings[]> = this.eventBus
    .observe(EcoModeSettingsUpdatedEvent)
    .pipe(
      withLatestFrom(this.ropewayEcoModeSettingsRequest$),
      filter(([event, ropewayEcoModeSettings]) =>
        ropewayEcoModeSettings.some((d) => d.ropewayGuid === event.ropewayEcoModeSettings.ropewayGuid)
      ),
      withLatestFrom(this.userSettingsService.userSettings$),
      map(([[event, ropewayEcoModeSettings], userSettings]) => {
        if (event.moduleUpdateSuccessful) {
          const updatedEntry = ropewayEcoModeSettings.find(
            (d) => d.ropewayGuid === event.ropewayEcoModeSettings.ropewayGuid
          );
          Object.assign(updatedEntry, event.ropewayEcoModeSettings);
        }
        if (event.changedBy === userSettings.userGuid) {
          this.displayUserMessage(event.updateSuccessful, event.moduleUpdateSuccessful);
        }

        return ropewayEcoModeSettings;
      })
    );

  private readonly ropewaySettingsUpdate$: Observable<RopewayEcoModeSettings[]> = this.eventBus
    .observe(RopewaySettingsUpdatedEvent)
    .pipe(
      withLatestFrom(this.ropewayEcoModeSettingsRequest$),
      filter(([event, ropewayEcoModeSettings]) =>
        ropewayEcoModeSettings.some((d) => d.ropewayGuid === event.ropewaySettings.guid)
      ),
      map(([event, ropewayEcoModeSettings]) => {
        ropewayEcoModeSettings.find((r) => r.ropewayGuid === event.ropewaySettings.guid).maxTransportQuantityPerHour =
          event.ropewaySettings.maxTransportQuantityPerHour;
        return ropewayEcoModeSettings;
      })
    );

  readonly ropewayEcoModeSettings$ = merge(
    this.ropewayEcoModeSettingsRequest$,
    this.ropewayEcoModeSettingsUpdate$,
    this.ropewaySettingsUpdate$
  ).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(
    private destinationService: DestinationService,
    private http: HttpClient,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService,
    private userMessageService: UserMessageService
  ) {}

  saveRopewayEcoModeSettings(settings: RopewayEcoModeSettings): Observable<boolean> {
    return this.http.post(`${environment.baseUrlApi}${this.basePostUrl}`, settings).pipe(
      map(() => true),
      catchError(() => {
        this.displayUserMessage(false, false);
        return of(false);
      })
    );
  }

  private displayUserMessage(updateSuccessful: boolean, moduleUpdateSuccessful: boolean): void {
    const message = updateSuccessful
      ? moduleUpdateSuccessful
        ? 'general.phrase.saved'
        : 'ecomode.message.moduleUpdateFailed'
      : 'general.phrase.saveFailed';
    const icon = updateSuccessful
      ? moduleUpdateSuccessful
        ? UserMessageIcon.success
        : UserMessageIcon.info
      : UserMessageIcon.failed;
    const color = updateSuccessful
      ? moduleUpdateSuccessful
        ? UserMessageColor.green
        : UserMessageColor.orange
      : UserMessageColor.red;
    const durationMs = moduleUpdateSuccessful ? 2000 : 5000;
    const userMessage = new UserMessage({
      message,
      icon,
      durationMs,
      position: 'top',
      color,
    });
    this.userMessageService.presentToast(userMessage);
  }
}
