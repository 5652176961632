import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { BreakdownInfoAdapter } from 'src/app/breakdowninfo/domain/breakdowninfo.adapter';
import { BreakdownInfo } from 'src/app/breakdowninfo/domain/breakdowninfo.model';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BreakdownInfoService {
  private readonly baseUrl: string = '/breakdowninfo';

  private readonly requiredFeature = new Feature(FeatureId.BREAKDOWNINFO, FeatureAccessLevel.READ);

  readonly breakdownInfos$: Observable<BreakdownInfo[]> = this.destinationService.selectedTenantFeatures$.pipe(
    switchMap((features) => {
      if (features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))) {
        return this.http
          .get(`${environment.baseUrlApi}${this.baseUrl}`)
          .pipe(map((breakdownInfo: any) => BreakdownInfoAdapter.adapt(breakdownInfo)));
      } else {
        return of([]);
      }
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(private http: HttpClient, private destinationService: DestinationService) {}
}
