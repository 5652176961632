import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { Screen } from 'src/app/media-center/screens/domain/screen.model';
import { MediaCenterScreenService } from 'src/app/media-center/screens/media-center-screens.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Component({
  templateUrl: './operatinghour.component.html',
  styleUrls: ['./operatinghour.component.scss', '../../media-center-global.scss'],
})
export class OperatinghourComponent extends Unsubscriber implements OnInit {
  screen: Screen;
  formGroup: FormGroup;
  initStartTime: string;
  initEndTime: string;

  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  private editedScreen: Screen;

  constructor(
    private modalCtrl: ModalController,
    private userMessageService: UserMessageService,
    private screensService: MediaCenterScreenService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit() {
    this.editedScreen = JSON.parse(JSON.stringify(this.screen));

    this.formGroup = new FormGroup({
      startTime: new FormControl<string>(this.editedScreen?.startTime || '00:00', [Validators.required]),
      endTime: new FormControl<string>(this.editedScreen?.endTime || '00:00', [Validators.required]),
      continuousOperation: new FormControl<boolean>(this.editedScreen?.continuousOperation),
    });

    if (this.editedScreen?.continuousOperation) {
      this.formGroup.controls.startTime.disable({ emitEvent: false });
      this.formGroup.controls.endTime.disable({ emitEvent: false });
    } else {
      this.formGroup.controls.startTime.enable({ emitEvent: false });
      this.formGroup.controls.endTime.enable({ emitEvent: false });
    }

    this.initStartTime = this.editedScreen.startTime;
    this.initEndTime = this.editedScreen.endTime;

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.editedScreen.startTime = value.continuousOperation ? '00:00' : value.startTime;
      this.editedScreen.endTime = value.continuousOperation ? '00:00' : value.endTime;
    });
  }

  continuousOperationChanged(event: any): void {
    if (event.detail.checked) {
      this.formGroup.controls.startTime.setValue('00:00', { emitEvent: false });
      this.formGroup.controls.endTime.setValue('00:00', { emitEvent: false });
      this.formGroup.controls.startTime.disable({ emitEvent: false });
      this.formGroup.controls.endTime.disable({ emitEvent: false });
    } else {
      this.formGroup.controls.startTime.enable({ emitEvent: false });
      this.formGroup.controls.endTime.enable({ emitEvent: false });
      this.formGroup.controls.startTime.setValue(this.initStartTime, { emitEvent: false });
      this.formGroup.controls.endTime.setValue(this.initEndTime, { emitEvent: false });
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async save(): Promise<void> {
    this.screen.updating = true;

    try {
      await this.screensService.updateScreens([this.editedScreen]);
      this.presentToast(true);
      this.screen.updating = false;
      this.initStartTime = this.editedScreen.startTime;
      this.initEndTime = this.editedScreen.endTime;
    } catch {
      this.screen.updating = false;
      this.presentToast(false);
    }

    this.close();
  }

  private presentToast(success: boolean): void {
    const message = success ? 'general.phrase.saved' : 'general.phrase.saveFailed';
    const color = success ? UserMessageColor.green : UserMessageColor.red;
    const icon = success ? UserMessageIcon.success : UserMessageIcon.failed;
    this.userMessageService.presentToast(new UserMessage({ message, icon, durationMs: 2000, position: 'top', color }));
  }
}
