import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent } from '@ionic/angular';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { Playlist } from 'src/app/media-center/playlists/domain/playlist.model';
import { MediaCenterPlaylistsService } from 'src/app/media-center/playlists/media-center-playlists.service';
import { v4 as v4guid } from 'uuid';

@Component({
  selector: 'sis-media-center-playlists',
  templateUrl: './media-center-playlists.component.html',
  styleUrls: ['./media-center-playlists.component.scss', '../media-center-global.scss'],
})
export class MediaCenterPlaylistsComponent extends Unsubscriber implements OnInit, OnDestroy {
  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;
  writePermission: boolean;
  readPermission: boolean;
  bigScreenMode: boolean;
  searching = false;
  playlists: Playlist[];
  playlistsFiltered: Playlist[];
  searchControl = new FormControl<string>('');
  searchTerm: string;
  expandedPlaylistGuid: string;
  anyPlaylistEditedAndUnsaved: boolean = false;
  newPlaylist: Playlist;

  @ViewChild('itemGridContent') itemGridContent: IonContent;

  constructor(
    private playlistService: MediaCenterPlaylistsService,
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService,
    private logbookModalService: LogbookModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.playlistService.playlists$.pipe(takeUntil(this.onDestroy$)).subscribe((playlists) => {
      this.playlists = playlists.sort((a, b) => a.name.localeCompare(b.name));
      this.checkEdited();
      if (!this.playlists.some((p) => p.guid === this.expandedPlaylistGuid)) {
        this.expandedPlaylistGuid = undefined;
      }
      this.filterPlaylists();
    });

    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroy$)).subscribe((search: string) => {
      this.searching = false;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { playlistSearch: search?.toLowerCase() },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.WRITE))
      );
      this.readPermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.READ))
      );
    });

    this.playlistService.ownUpdateCompleted.pipe(takeUntil(this.onDestroy$)).subscribe(([playlist, success]) => {
      if (success && playlist.guid === this.newPlaylist?.guid) {
        this.newPlaylist = undefined;
      }
    });

    this.activatedRoute.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
      this.searchTerm = params.playlistSearch?.toLowerCase();

      if (this.searchControl.value !== this.searchTerm) {
        this.searchControl.setValue(this.searchTerm);
      }

      this.filterPlaylists();
    });
  }

  ngOnDestroy(): void {
    this.playlistService.unsavedChanges$.next(false);
  }

  toggleExpanded(guid: string, scrollToElement: any): void {
    this.checkEdited();
    if (!this.anyPlaylistEditedAndUnsaved) {
      if (guid === this.expandedPlaylistGuid) {
        this.expandedPlaylistGuid = undefined;
      } else {
        this.expandedPlaylistGuid = guid;

        let element = scrollToElement;
        while ((element.tagName as string)?.toLowerCase() !== 'ion-row') {
          element = element.parentElement;
        }

        this.itemGridContent.scrollToPoint(0, element?.offsetTop - 1 ?? 0, 100);
      }
    }
  }

  insertNewPlaylist(): void {
    if (!this.anyPlaylistEditedAndUnsaved) {
      const newPlaylist = new Playlist(v4guid(), '', true, '#000000');
      newPlaylist.isNew = true;
      this.newPlaylist = newPlaylist;
      this.expandedPlaylistGuid = undefined;
      this.toggleExpanded(newPlaylist.guid, null);
    }
  }

  removeNewPlaylist(): void {
    this.newPlaylist = undefined;
    this.checkEdited();
  }

  checkEdited(): void {
    this.anyPlaylistEditedAndUnsaved = this.newPlaylist != null || this.playlists.some((p) => p.edited);
    this.playlistService.unsavedChanges$.next(this.anyPlaylistEditedAndUnsaved);
  }

  async openLogbook(): Promise<void> {
    await this.logbookModalService.presentLogbookModal([[FeatureId.SISMEDIA_MEDIACENTER]]);
  }

  trackByPlaylistName(_: number, playlist: Playlist): string {
    return playlist.name;
  }

  private filterPlaylists(): void {
    let filteredPlaylists = this.playlists;

    if (this.searchTerm) {
      filteredPlaylists = this.playlists.filter((d) => d.name.toLowerCase().includes(this.searchTerm));
    }

    this.playlistsFiltered = filteredPlaylists;
  }
}
