<ion-row *ngIf="ropeway" class="sis-row" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" (click)="click()">
  <ion-col size="2" class="sis-icon-col">
    <ion-icon class="sis-ropeway-icon" [name]="getIconName()"></ion-icon>
  </ion-col>
  <ion-col class="sis-text-col">
    <span class="sis-big-text">{{ ropeway.alias }}</span>
    <div class="sis-small-text">{{ ropeway.fullname }} ({{ ropeway.sisId | uppercase }})</div>
  </ion-col>
  <ion-col size="1" class="sis-text-col">
    <ion-icon class="sis-alarm-icon" [ngClass]="cssClass" name="warning"></ion-icon>
  </ion-col>
</ion-row>
