<svg
  width="100%"
  viewBox="0 0 187.5 225"
  version="1.1"
  id="svg"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg"
  style="cursor: pointer"
  (click)="this.openChartModal()"
>
  <g>
    <path
      d="m48.832 97.062 20.845-23.059 17.478 16.494 14.771-15.207 9.2029 12.693 27.662-14.827"
      style="fill: none; stroke-linecap: square; stroke-width: 4.1998; stroke: #32849a"
    />
    <path
      d="m47.965 145.45 0.63201-18.485 24.473-14.287 36.571 2.6753 28.465-10.374v39.821"
      style="fill: #cccccc; stroke-width: 4.1998; stroke: #cccccc"
    />
    <path
      d="m47.996 158.16 0.63151-19.051 24.454-16.78 39.392 16.642 25.593-10.496v29.015"
      style="fill: #91cddb; stroke-width: 4.2619; stroke: #91cddb"
    />
    <path
      d="m48.468 143.98 16.406 4.7539 11.563-19.051 17.176 11.072 23.588-35.631 21.323 15.186"
      style="fill: none; stroke-linecap: square; stroke-width: 4.1998; stroke: #32849a"
    />
    <g transform="translate(-1.6)">
      <path
        d="m149.47 158h-100.59v-98.095"
        style="fill: none; stroke-linecap: square; stroke-width: 3.7569; stroke: #808080"
      />
      <path d="m48.769 50.539 4.7687 11.089h-9.5374z" style="fill: #808080" />
      <path d="m157.57 158.02-11.089 4.7687v-9.5374z" style="fill: #808080" />
    </g>
  </g>
  <rect width="187.5" height="225" style="fill: none" />
</svg>
