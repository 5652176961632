import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  merge,
  Observable,
  of,
  scan,
  shareReplay,
  Subject,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { SlopesOperatingTimesUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { SlopesOperatingTimesAdapter } from 'src/app/slopes-operating-times/domain/slopes-operating-times.adapter';
import { SlopesOperatingTimes } from 'src/app/slopes-operating-times/domain/slopes-operating-times.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SlopesOperatingTimesService {
  private readonly baseRequestUrl: string = '/slopesoperatingtimes';
  private readonly basePostUrl: string = '/slopesoperatingtimes/update';
  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_SLOPESOPERATINGTIMES, FeatureAccessLevel.READ);

  private readonly changes$ = new Subject<{ guid: string; editedSlopesOperatingTimes: SlopesOperatingTimes | null }>();

  private readonly slopesOperatingTimesRequest$: Observable<SlopesOperatingTimes[]> =
    this.destinationService.selectedTenantFeatures$.pipe(
      distinctUntilChanged(),
      filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
      switchMap(() => this.httpClient.get(`${environment.baseUrlApi}${this.baseRequestUrl}`)),
      map((data) => SlopesOperatingTimesAdapter.adapt(data)),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      })
    );

  private readonly slopesOperatingTimesUpdate$: Observable<SlopesOperatingTimes[]> = this.eventBus
    .observe(SlopesOperatingTimesUpdatedEvent)
    .pipe(
      withLatestFrom(this.destinationService.selectedTenant$),
      filter(([updatedEvent, tenant]) => updatedEvent?.tenantGuid === tenant?.guid),
      withLatestFrom(this.slopesOperatingTimesRequest$, this.userSettingsService.userSettings$),
      map(([[updatedEvent], slopeOperatingTimes, userSettings]) => {
        if (updatedEvent.changedBy === userSettings.userGuid) {
          const translateKey = updatedEvent.updateSuccessful ? 'general.phrase.saved' : 'general.phrase.saveFailed';
          const icon = updatedEvent.updateSuccessful ? UserMessageIcon.success : UserMessageIcon.failed;
          const color = updatedEvent.updateSuccessful ? UserMessageColor.green : UserMessageColor.red;
          const userMessage = new UserMessage({
            message: translateKey,
            icon,
            durationMs: 2000,
            position: 'top',
            color,
          });
          this.userMessageService.presentToast(userMessage);
        }

        if (updatedEvent.updateSuccessful) {
          const updatedSlopesOperatingTimesItem = slopeOperatingTimes.find((s) => s.guid === updatedEvent.guid);
          if (updatedSlopesOperatingTimesItem) {
            updatedSlopesOperatingTimesItem.firstRide = updatedEvent.firstRide;
            updatedSlopesOperatingTimesItem.lastRide = updatedEvent.lastRide;
            updatedSlopesOperatingTimesItem.lastSlopeControl = updatedEvent.lastSlopeControl;
          }
        }

        return slopeOperatingTimes;
      })
    );

  readonly slopesOperatingTimes$: Observable<SlopesOperatingTimes[]> = merge(
    this.slopesOperatingTimesRequest$,
    this.slopesOperatingTimesUpdate$
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  readonly unsavedChanges$ = this.changes$.pipe(
    scan((acc, curr) => {
      acc.set(curr.guid, curr.editedSlopesOperatingTimes);

      return acc;
    }, new Map<string, SlopesOperatingTimes | null>()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  readonly updated$ = this.eventBus.observe(SlopesOperatingTimesUpdatedEvent).pipe(
    withLatestFrom(this.destinationService.selectedTenant$, this.userSettingsService.userSettings$),
    filter(
      ([updatedEvent, tenant, userSettings]) =>
        updatedEvent.tenantGuid === tenant?.guid && updatedEvent.changedBy === userSettings.userGuid
    )
  );

  readonly requestReset$ = new Subject<void>();

  constructor(
    private httpClient: HttpClient,
    private destinationService: DestinationService,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService,
    private userMessageService: UserMessageService
  ) {}

  async saveChanges(): Promise<boolean> {
    const unsavedChanges = await firstValueFrom(this.unsavedChanges$);

    const itemsToSave = [...unsavedChanges.values()].filter((v) => !!v);
    if (itemsToSave.length) {
      return firstValueFrom(
        this.httpClient.post(`${environment.baseUrlApi}${this.basePostUrl}`, itemsToSave).pipe(
          map(() => true),
          catchError(() => of(false))
        )
      );
    }

    return true;
  }

  setEdited(guid: string, editedSlopesOperatingTimes: SlopesOperatingTimes | null): void {
    this.changes$.next({ guid, editedSlopesOperatingTimes });
  }
}
