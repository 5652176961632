import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { ChartAdapter } from 'src/app/maps/widgets/charts/chart-adapter';
import { ChartSettingsService } from 'src/app/maps/widgets/charts/chart-settings.service';
import { ChartSelector } from 'src/app/maps/widgets/charts/ropeway-chart/chart-selector.model';

export abstract class RopewayChartHandler {
  seriesId: string;

  abstract seriesObservable(): Observable<Array<{ seriesId: string; data: number[][] }>>;

  abstract getChartSelectors(): ChartSelector[];

  constructor(
    protected chartSettingsService: ChartSettingsService,
    protected chartAdapter: ChartAdapter,
    protected translateService: TranslateService,
    protected ropeway: Ropeway,
    protected yAxisUnits: string[]
  ) {}
}
