import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { TimetableSeason } from 'src/app/timetable/domain/timetable-season.model';
import { TimetableService } from 'src/app/timetable/timetable.service';

@Component({
  templateUrl: './season-editor.component.html',
  styleUrls: ['./season-editor.component.scss'],
})
export class SeasonEditorComponent extends Unsubscriber implements OnInit {
  season: TimetableSeason;
  seasons: TimetableSeason[];
  formGroup: FormGroup;
  canSave = false;
  edited = false;

  private editedSeason: TimetableSeason;

  constructor(private modalCtrl: ModalController, private timetableService: TimetableService) {
    super();
  }

  ngOnInit() {
    this.editedSeason = JSON.parse(JSON.stringify(this.season));

    this.formGroup = new FormGroup(
      {
        name: new FormControl(this.editedSeason?.name, [Validators.required, this.validateName()]),
        validFrom: new FormControl(this.editedSeason?.validFrom),
        validUntil: new FormControl(this.editedSeason?.validUntil),
      },
      { validators: [this.validateDates()] }
    );

    this.formGroup.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((values) => {
      Object.assign(this.editedSeason, values);
      this.edited = !this.isEqual(this.season, this.editedSeason);
      this.canSave = !this.formGroup.invalid && this.edited;
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async save(): Promise<void> {
    if (!this.formGroup.invalid && !this.isEqual(this.season, this.editedSeason)) {
      await this.timetableService.updateSeason(this.editedSeason);
      this.close();
    }
  }

  private isEqual(s1: TimetableSeason, s2: TimetableSeason): boolean {
    return s1.name === s2.name && s1.validFrom === s2.validFrom && s1.validUntil === s2.validUntil;
  }

  private validateName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const name = control.value?.toLowerCase();

      if (this.seasons.filter((s) => s.guid !== this.season.guid).some((s) => s.name.toLowerCase() === name)) {
        return { nameInvalid: 'timetable.phrase.nameExists' };
      }

      return null;
    };
  }

  private validateDates(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startControl = control.get('validFrom');
      const endControl = control.get('validUntil');

      const start = startControl.value;
      const end = endControl.value;

      const now = moment(new Date()).format('YYYY-MM-DD');

      const datesInvalid = end < now || start >= end;

      const seasonsOverlap = this.seasons
        .filter((s) => s.guid !== this.season.guid && !s.isEmergencyTimetable)
        .map((season) => start <= season.validUntil && end >= season.validFrom)
        .some((s) => s);

      const errorMessage = datesInvalid
        ? 'timetable.phrase.datesInvalid'
        : seasonsOverlap
        ? 'timetable.phrase.overlappingSeasons'
        : null;

      return datesInvalid || seasonsOverlap ? { dateTimesInvalid: errorMessage } : null;
    };
  }
}
