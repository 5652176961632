<ion-row
  *ngIf="this.alarm && this.bigScreenMode"
  [ngClass]="this.cssClass"
  class="sis-big-row"
  data-test="sis-alarmlist-item"
>
  <ion-col data-test="sis-alarmlist-item-timestamp" size="1.5" style="min-width: 200px">{{
    this.timestamp.formattedTimestamp$ | async
  }}</ion-col>
  <ion-col size="2.2" class="sis-global-text-overflow-ellipsis" style="min-width: 250px">{{ this.alias }}</ion-col>
  <ion-col size="1.5" class="ion-text-left" style="min-width: 170px">{{ this.alarm.code }}</ion-col>
  <ion-col size="1" class="ion-text-left">{{ this.levelTranslateString | translate }}</ion-col>
  <ion-col class="ion-align-items-end sis-global-text-overflow-ellipsis">{{ this.alarm.text }}</ion-col>
</ion-row>
<ion-row
  *ngIf="this.alarm && !this.bigScreenMode"
  [ngClass]="this.cssClass"
  class="sis-small-row ion-justify-content-end"
>
  <ion-col class="sis-global-text-overflow-ellipsis" style="font-weight: bold">{{ this.alias }}</ion-col>
  <ion-col size="4" style="min-width: 150px">
    <span class="ion-float-right">
      <i>{{ this.timestamp.formattedTimestamp$ | async }}</i>
    </span>
  </ion-col>
  <div class="sis-small-text sis-global-text-overflow-ellipsis">
    <span>{{ this.alarm.code }}</span>
    {{ this.alarm.text }}
  </div>
</ion-row>
