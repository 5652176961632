<div class="sis-operating-info-color" [style.background-color]="this.color"></div>
<ion-grid style="height: 145px">
  <ion-row>
    <ion-col>
      <ion-grid class="sis-operating-info-entry sis-global-sis-scrollbar">
        <ion-row>
          <ion-col class="sis-operating-info-description" data-test="operating-info-item-description">
            {{ this.description }}
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="sis-operating-info-text">
            <div [innerHTML]="this.text"></div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
    <ion-col *ngIf="this.editable" size="auto">
      <ion-grid style="height: 100%">
        <ion-row>
          <ion-col>
            <a
              (click)="this.delete()"
              matTooltip="{{ 'general.term.delete' | translate }}"
              data-test="sis-operating-info-delete-button"
              [ngClass]="
                this.entry.active
                  ? 'sis-operating-info-icon-clickable-no-permission'
                  : 'sis-operating-info-icon-clickable'
              "
            >
              <ion-icon style="pointer-events: none" name="trash-outline"></ion-icon>
            </a>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <a
              (click)="this.showLibraryItem()"
              matTooltip="{{ 'general.term.edit' | translate }}"
              data-test="sis-operating-info-edit-button"
              class="sis-operating-info-icon-clickable"
            >
              <ion-icon style="pointer-events: none" name="create-outline"></ion-icon>
            </a>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
