import '@elfalem/leaflet-curve';

import { parseCurveCoords } from 'src/app/core/leaflet-helper/curve-coords';
import { L } from 'src/app/core/leaflet-helper/leaflet-curve-declaration';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { RopewayCssClassNames } from 'src/app/maps/map/ropeway-layer/ropeway-line/ropeway-css-classnames';

export class RopewayCurve {
  line: any;
  highlight: any;
  border: any;
  clickable: any;

  private defaultWeight = 6;

  constructor(private ropeway: Ropeway) {}

  create(ropewayGroup: L.LayerGroup): void {
    const coords = parseCurveCoords(this.ropeway.pathPanoMap);

    // ropeway itself
    this.line = L.curve(coords, {
      interactive: true,
      weight: this.defaultWeight,
      className: RopewayCssClassNames.cssRopewayDefault,
    });

    // ropeway highlight behind border one for increased visibility when selected
    this.highlight = L.curve(coords, {
      interactive: true,
      weight: this.line.options.weight * 2.5,
      opacity: 0,
      className: RopewayCssClassNames.cssRopewayInvisible,
    });

    // Bigger invisible ropeway line on top of visible one for increased click tolerance
    this.clickable = L.curve(coords, {
      interactive: true,
      bubblingMouseEvents: false,
      fill: false,
      weight: this.line.options.weight * 6,
      opacity: 0,
      className: RopewayCssClassNames.cssRopewayClickable,
    });

    // ropeway border behind visible one for increased visibility
    this.border = L.curve(coords, {
      interactive: true,
      weight: this.line.options.weight + 5,
      className: RopewayCssClassNames.cssRopewayDefault + RopewayCssClassNames.cssBorder,
    });

    ropewayGroup.addLayer(this.highlight);
    ropewayGroup.addLayer(this.border);
    ropewayGroup.addLayer(this.line);
    ropewayGroup.addLayer(this.clickable);
  }

  getRopewayElements(): any[] {
    return [this.highlight.getElement(), this.border.getElement(), this.line.getElement(), this.clickable.getElement()];
  }
}
