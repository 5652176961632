import { WritablePart } from 'src/app/core/utils/type-helper';

export class RopewayTorqueTrend {
  static unitNewton = 'Nm';
  static unitAmpere = 'A';
  static unitPercent = '%';
  static unitNone = '';

  case: number;

  torqueAbsolute: number = null;
  torquePercent: number = null;
  timestamp: Date = null;
  unit: string = RopewayTorqueTrend.unitNone;

  constructor(init?: Partial<WritablePart<RopewayTorqueTrend>>) {
    Object.assign(this, init);
  }
}
