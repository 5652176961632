import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { GatewayResponse } from 'src/app/sisag-admin/gateway-response-box/gateway-response.model';

@Component({
  selector: 'sis-gateway-response-box',
  templateUrl: './gateway-response-box.component.html',
  styleUrls: ['./gateway-response-box.component.scss'],
})
export class GatewayResponseBoxComponent extends Unsubscriber implements OnChanges, OnInit {
  private numberOfRows: number;
  colSize: string;
  rowsOfResponses: GatewayResponse[][] = [];

  @Input() gatewayResponses: GatewayResponse[] = [];

  constructor(public screenSizeService: ScreenSizeService) {
    super();
  }

  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => {
      this.numberOfRows = bigScreenMode ? 8 : 3;
      this.colSize = (12 / this.numberOfRows).toString();
    });
  }

  ngOnChanges(): void {
    if (this.gatewayResponses) {
      this.gatewayResponses.sort((a, b) => (a.success > b.success ? 1 : -1));
      let row = 0;
      this.rowsOfResponses[row] = [];

      this.gatewayResponses.forEach((r, index) => {
        this.rowsOfResponses[row].push(r);
        if (index % this.numberOfRows === this.numberOfRows - 1) {
          row++;
          this.rowsOfResponses[row] = [];
        }
      });
    }
  }
}
