import { BreakdownInfoActiveText } from 'src/app/breakdowninfo/domain/breakdowninfo-activetext.model';

export class BreakdownInfoActiveTextAdapter {
  static adapt(item: any): BreakdownInfoActiveText[] {
    const activetext: BreakdownInfoActiveText[] = [];

    item.forEach((e: any) => {
      activetext.push(
        new BreakdownInfoActiveText({
          breakdownInfoGuid: e.breakdownInfoGuid,
          color: e.color,
          titleDe: e.titleDe,
          titleEn: e.titleEn,
          titleFr: e.titleFr,
          titleIt: e.titleIt,
          textDe: e.textDe,
          textEn: e.textEn,
          textFr: e.textFr,
          textIt: e.textIt,
          timestamp: e.timestamp,
        })
      );
    });

    return activetext;
  }
}
