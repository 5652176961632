<sis-outdated-icon
  *ngIf="this.outdated"
  color="var(--ion-color-danger)"
  [timestamp]="this.dataTimestamp"
></sis-outdated-icon>
<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="100%"
  viewBox="0 0 187.51 225"
>
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widget_Hintergrund_temp" data-name="Widget Hintergrund temp">
      <rect x="0.01" width="187.5" height="225" style="fill: none" />
    </g>
    <g id="Widgets">
      <g>
        <rect width="187.5" height="225" style="fill: none" />
        <g>
          <line
            x1="46.87"
            y1="127.25"
            x2="140.62"
            y2="127.25"
            style="fill: none; stroke: #32849a; stroke-miterlimit: 10; stroke-width: 4px"
          />
          <line
            x1="140.62"
            y1="146"
            x2="46.87"
            y2="146"
            style="fill: none; stroke: #32849a; stroke-miterlimit: 10; stroke-width: 4px"
          />
          <g>
            <path
              d="M112.42,62l1.85,22-1.85,22a5.24,5.24,0,0,1-5.23,5.23H80.3a5.24,5.24,0,0,1-5.22-5.23L73.23,84l1.85-22a5.25,5.25,0,0,1,5.22-5.23h26.89A5.25,5.25,0,0,1,112.42,62Z"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <line
              x1="93.75"
              y1="56.74"
              x2="93.75"
              y2="36.23"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <polygon
              points="107.47 83.95 105.78 63.82 81.72 63.82 80.03 83.95 107.47 83.95"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <line
              x1="93.75"
              y1="83.95"
              x2="93.75"
              y2="104.06"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <path
              d="M151.92,21.3a4.32,4.32,0,0,1-7.46,2.95l-1.14.31-3-3.32,3.07-.85a4.31,4.31,0,0,1,8.53.91ZM44.18,47.88l-1.13.32a4.32,4.32,0,1,0,1.06,3.85l3.07-.85Zm13.49-3.74L46.11,47.35l3,3.32,11.56-3.21Zm1.93-.53,3,3.32,11.57-3.2-3-3.33m13.27-3.67-11.57,3.2,3,3.32,11.56-3.2Zm1.93-.54,3,3.33,11.57-3.21-3-3.32m13.49-3.74L99.85,32.46l3,3.32,11.57-3.21Zm1.93-.53,3,3.32,11.57-3.21-3-3.32m13.49-3.74L126.83,25l3,3.32,11.56-3.2Z"
              style="fill: #979797"
            />
          </g>
          <polyline
            points="125.25 139.87 140.62 127.25 125.25 115.45"
            style="fill: none; stroke: #32849a; stroke-miterlimit: 10; stroke-width: 4px"
          />
          <polyline
            points="62.55 158.21 46.87 146 62.55 133.79"
            style="fill: none; stroke: #32849a; stroke-miterlimit: 10; stroke-width: 4px"
          />
        </g>
      </g>
    </g>
    <g>
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
        [style.fill]="this.outdated ? 'var(--ion-color-medium)' : '#1c232a'"
      >
        {{ tripCount }}
      </text>
    </g>
  </g>
</svg>
