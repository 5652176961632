import { Component, Input } from '@angular/core';

@Component({
  selector: 'sis-stnet-calculated-item',
  templateUrl: './stnet-calculated-item.component.html',
  styleUrls: ['./stnet-calculated-item.component.scss'],
})
export class STnetCalculatedItemComponent {
  @Input() bigScreenMode: boolean;
  @Input() name: string;
  @Input() today: number;
  @Input() total: number;
  @Input() lengthMeterToday: number;
  @Input() lengthMeterTotal: number;
}
