import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MediaCenterNetworkStateAdapter } from 'src/app/media-center/network-state/network-state.adapter';
import { MediaCenterNetworkState } from 'src/app/media-center/network-state/network-state.model';

@Injectable({
  providedIn: 'root',
})
export class MediaCenterNetworkStateService extends LivedataServiceBase<MediaCenterNetworkState> {
  protected readonly requiredFeatures = [new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.READ)];
  protected readonly endpointUri = 'mediacenter/network';

  readonly networkStates$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, MediaCenterNetworkStateAdapter.adapt, destinationService);
  }
}
