import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { EnergyLiveDataService } from 'src/app/maps/livedata/energy-livedata.service';
import { MeteoForecastService } from 'src/app/maps/livedata/meteo-forecast.service';
import { MeteoLivedataService } from 'src/app/maps/livedata/meteo-livedata.service';
import { RopewayLivedataService } from 'src/app/maps/livedata/ropeway-livedata.service';

@Component({
  selector: 'sis-maps',
  templateUrl: './maps.page.html',
  styleUrls: ['./maps.page.scss'],
})
export class MapsPage extends Unsubscriber implements OnInit, OnDestroy {
  hasDestination: boolean;
  bigScreenMode: boolean;

  constructor(
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService,
    private energyLiveDataService: EnergyLiveDataService,
    private meteoForecastService: MeteoForecastService,
    private meteoLiveDataService: MeteoLivedataService,
    private ropewayLiveDataService: RopewayLivedataService
  ) {
    super();

    this.hasDestination = false;
  }

  ngOnInit(): void {
    this.destinationService.selectedTenant$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.hasDestination = false;

      setTimeout(() => {
        this.hasDestination = true;
      });
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    // keep an active subscription on the livedata services needed for the widgetlist
    combineLatest([
      this.ropewayLiveDataService.ropewayLiveData$,
      this.meteoLiveDataService.meteoLiveDatas$,
      this.meteoForecastService.forecasts$,
      this.energyLiveDataService.energy$,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }
}
