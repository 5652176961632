import { Directive } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import { AxisSetExtremesEventObject, Options } from 'highcharts';
import { firstValueFrom } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { ContextMenuItem } from 'src/app/core/components/context-menu/context-menu-item.model';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { TimeFormatService } from 'src/app/core/utils/time-format.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { ChartAdapter } from 'src/app/maps/widgets/charts/chart-adapter';

@Directive()
export abstract class ChartBase extends Unsubscriber {
  private static readonly noDataTranslateString = 'analytics.phrase.dataNone';
  private static readonly loadingTranslateString = 'analytics.phrase.dataLoading';

  static readonly ContextMenuItemSave = 'general.term.save';

  private readonly trendLineColor = ['#32849a', '#91cddb', 'black'];
  private readonly forecastLineColor = ['#949499', '#636366', 'black'];

  protected readonly chartAdapter: ChartAdapter;
  protected deviceAlias: string;
  protected minY = 0;
  protected maxY = null;
  protected noDataText: string;
  protected loadingText: string;

  private clickStarted: boolean;

  readonly chart: Chart;

  title: string;
  mouseMoved: boolean;
  isZoomed: boolean;
  isZoomedToEnd: boolean;
  zoomMin: number;

  readonly contextMenuItems: ContextMenuItem[] = [
    {
      translationString: ChartBase.ContextMenuItemSave,
      requiredFeature: new Feature(FeatureId.COCKPIT_TELEMETRY, FeatureAccessLevel.READ),
    },
  ];

  showContextMenu: boolean;
  contextMenuX: number;
  contextMenuY: number;

  constructor(
    translateService: TranslateService,
    public isModal: boolean,
    private timeFormatService: TimeFormatService
  ) {
    super();

    translateService.onLangChange
      .pipe(startWith(translateService.currentLang), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.noDataText = translateService.instant(ChartBase.noDataTranslateString);
        this.loadingText = translateService.instant(ChartBase.loadingTranslateString);

        if (this.chartAdapter) {
          this.chartAdapter.updateOptions({
            lang: {
              noData: this.noDataText,
              loading: this.loadingText,
            },
          });
        }

        this.translateTexts(translateService);
      });

    const chartOptions = this.getChartOptions();
    if (this.isModal) {
      chartOptions.chart.spacingTop = 50;
    }
    this.chartAdapter = ChartAdapter.create(chartOptions);
    this.chart = this.chartAdapter.chart;
  }

  contextMenuItemSelected(selectedItem: ContextMenuItem): void {
    this.showContextMenu = false;

    if (selectedItem?.translationString === ChartBase.ContextMenuItemSave) {
      this.exportChartPdf();
    }
  }

  chartClicked(e: PointerEvent): void {
    if (
      this.mouseMoved ||
      (e.target as SVGRectElement).getAttribute('class') === 'highcharts-button-box' ||
      e.target['localName'] === 'text'
    ) {
      e.stopPropagation();
    }

    this.clickStarted = false;
  }

  onMouseDown(): void {
    this.clickStarted = true;
  }

  onMouseUp(): void {
    this.clickStarted = false;
  }

  onMouseMove(): void {
    this.mouseMoved = this.clickStarted;
  }

  async exportChartPdf(): Promise<void> {
    const timeString = await firstValueFrom(this.timeFormatService.format(new Date(Date.now()), 1));
    const subTitle = this.deviceAlias ? `${this.deviceAlias} - ${timeString}` : timeString;

    this.chartAdapter.exportData(this.title, subTitle);
  }

  protected updateModalTitle() {
    const titleElement = document.getElementById('modal-popup-title');

    if (titleElement) {
      titleElement.innerText = this.title;
    }
  }

  protected getTrendLineColor(index = 0): string {
    if (index < this.trendLineColor.length) {
      return this.trendLineColor[index];
    }
    return this.trendLineColor[0];
  }

  protected getForecastLineColor(index = 0): string {
    if (index < this.forecastLineColor.length) {
      return this.forecastLineColor[index];
    }
    return this.forecastLineColor[0];
  }

  protected updateTitle() {
    this.chartAdapter.setTitle(this.isModal ? '' : this.title);
  }

  protected handleSetExtremesEvent(event: AxisSetExtremesEventObject) {
    if (event.trigger == 'zoom') {
      this.isZoomed = event.min != undefined || event.max != undefined;

      setTimeout(() => {
        this.isZoomedToEnd = event.max === event.dataMax;
        this.zoomMin = event.min;

        if (!this.isZoomed) {
          this.chartAdapter.setYAxisExtremes(0, this.minY, this.maxY);
        }
      });
    }
  }

  protected abstract translateTexts(translateService: TranslateService): void;
  protected abstract getChartOptions(): Options;
}
