export enum WidgetName {
  // Destination
  Energy,
  FirstEntries,
  DevicesOn,
  WindTendency,
  WeatherTrend,
  Kassandra,
  DestinationAlarm,
  Snowmaking,
  GIN,

  // Ropeway
  Wind,
  Speed,
  Torque,
  Efa,
  RopeKm,
  TripCount,
  OperatingHours,
  Stoptime,
  Temperature,
  Icing,
  WetBulb,
  PassengerCount,
  Utilization,
  Alarm,
  CabinPosition,
  CarriageTilt,
  GearTemperature,
  Zones,
  Webcam,
  TransportWeight,
  ClampingCount,
  RemoteAccess,
  RopewayEcoMode,
  SambesiWhatsUp,
  RopewayChart,
  TruScan,
  RopewaySettings,
  RopewayAvailability,

  // Destination & Ropeway
  AbbSmartSensor,
}
