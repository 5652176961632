<div
  class="sis-chart"
  oncontextmenu="return false"
  [chart]="chart"
  style="height: 100%"
  (mousedown)="onMouseDown()"
  (click)="this.chartClicked($event)"
  (mouseup)="onMouseUp()"
  (mousemove)="onMouseMove()"
></div>

<div
  *ngIf="this.meteoStationHasForecast"
  class="sis-forecast-toggle"
  [ngClass]="{ 'sis-forecast-toggle-modal': this.isModal }"
  (click)="this.toggleForecast($event)"
>
  <div
    *ngIf="this.forecastTestActive"
    class="sis-forecast-test"
    matTooltip="{{ 'wind.chart.forecastTestActive' | translate }}"
  >
    <ion-icon name="information-circle-outline" color="secondary" style="zoom: 1.4"></ion-icon>
  </div>
  <ion-label style="vertical-align: middle" style="margin-left: 5px">{{
    'analytics.term.forecast' | translate
  }}</ion-label>
  <input type="checkbox" [checked]="this.forecastEnabled$ | async" />
</div>

<div class="sis-pdf-export" *ngIf="this.isModal" (click)="this.exportChartPdf()">
  <ion-label color="primary">PDF</ion-label>
  <ion-icon name="download-outline" color="primary" style="zoom: 1.3"></ion-icon>
</div>

<div *ngIf="this.showContextMenu">
  <sis-context-menu
    [x]="this.contextMenuX"
    [y]="this.contextMenuY"
    [menuItems]="this.contextMenuItems"
    (menuItemSelected)="this.contextMenuItemSelected($event)"
  ></sis-context-menu>
</div>
