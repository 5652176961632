import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { combineLatest, take } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { Screen } from 'src/app/media-center/screens/domain/screen.model';
import { InfoBanner } from 'src/app/media-center/screens/infobanner-modal/domain/infobanner.model';
import { InfoBannerMapping } from 'src/app/media-center/screens/infobanner-modal/domain/infobanner-mapping.model';
import { InfoBannerService } from 'src/app/media-center/screens/infobanner-modal/infobanner.service';
import { InfoBannerScreen } from 'src/app/media-center/screens/infobanner-modal/infobanner-screen-selector-modal/domain/infobanner-screen.model';
import { InfoBannerScreenGroup } from 'src/app/media-center/screens/infobanner-modal/infobanner-screen-selector-modal/domain/infobanner-screen-group.model';
import { MediaCenterScreenService } from 'src/app/media-center/screens/media-center-screens.service';

@Component({
  templateUrl: './infobanner-screen-selector.component.html',
  styleUrls: ['./infobanner-screen-selector.component.scss', '../../../media-center-global.scss'],
})
export class InfoBannerScreensSelectorComponent extends Unsubscriber implements OnInit {
  infoBanner: InfoBanner;
  entryScreen: Screen;
  screenGroups: InfoBannerScreenGroup[] = [];
  ready: boolean;

  allScreensOfTenantWithInfobannerAllowed: Screen[];
  ignoreIonChangeEvent: boolean;
  allGroupsSelected: boolean;

  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  constructor(
    private modalCtrl: ModalController,
    private infoBannerService: InfoBannerService,
    private screensService: MediaCenterScreenService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.screensService.screenGroups$, this.screensService.screens$, this.infoBannerService.infoBanner$])
      .pipe(take(1))
      .subscribe(([screenGroups, screens, infoBanners]) => {
        screenGroups.forEach((screenGroup) => {
          screenGroup.screens = screens
            .filter((screen) => screen.groupGuid == screenGroup.guid)
            .sort((a, b) => a.order - b.order);
        });

        const groupsWithScreens = screenGroups.filter(
          (screenGroups) =>
            screenGroups.screens.length > 0 && screenGroups.screens.some((screen) => screen.infoBannerAllowed)
        );

        groupsWithScreens.forEach((group) => {
          const infoBannerScreenGroup = new InfoBannerScreenGroup(group.guid, group.name);

          group.screens.forEach((screen) => {
            if (screen.infoBannerAllowed) {
              const activeInfoBanner = infoBanners.find((infoBanner) => infoBanner.screenGuids?.includes(screen.guid));

              infoBannerScreenGroup.screens.push({
                guid: screen.guid,
                label: screen.label,
                location: screen.location,
                activeInfoBannerGuid: activeInfoBanner?.guid,
                activeInfoBannerName: activeInfoBanner?.name,
                newInfoBannerActive:
                  screen.guid === this.entryScreen.guid || activeInfoBanner?.guid === this.infoBanner.guid,
              });
            }
          });

          this.screenGroups.push(infoBannerScreenGroup);
        });
      });

    this.ready = true;
  }

  selectAllScreens(event: CustomEvent): void {
    this.screenGroups.forEach((screenGroup) => this.selectAllScreensOfGroup(screenGroup, event));
  }

  selectAllScreensOfGroup(group: InfoBannerScreenGroup, event: CustomEvent) {
    group.screens.forEach((screen) => this.selectScreen(screen, event));
  }

  selectScreen(screen: InfoBannerScreen, event: CustomEvent) {
    this.ignoreIonChange();
    screen.newInfoBannerActive = event.detail.checked;

    this.allGroupsSelected = this.screenGroups.every((screenGroup) => screenGroup.groupActive);
  }

  save() {
    const activatedInfoBannerMapping: InfoBannerMapping = {
      infoBannerGuid: this.infoBanner.guid,
      screenGuids: this.screenGroups.flatMap<string>((group) =>
        group.screens.filter((screen) => screen.newInfoBannerActive).flatMap((screen) => screen.guid)
      ),
    };

    const deactivatedInfoBannerMapping: InfoBannerMapping = {
      infoBannerGuid: null,
      screenGuids: this.screenGroups.flatMap<string>((group) =>
        group.screens
          .filter((screen) => screen.activeInfoBannerGuid === this.infoBanner.guid && !screen.newInfoBannerActive)
          .flatMap((screen) => screen.guid)
      ),
    };

    this.infoBannerService
      .updateInfoBannerMapping([activatedInfoBannerMapping, deactivatedInfoBannerMapping])
      .subscribe({
        next: () => {
          this.close(true);
        },
        error: () => {
          this.infoBannerService.presentToast(false, 'mapping', false);
        },
      });
  }

  close(closeInfoBannerModal: boolean) {
    this.modalCtrl.dismiss(closeInfoBannerModal);
  }

  // To prevent the "ionChange" event from being triggered when the checked property is set programmatically, we ignore the events for 100 ms.
  // On ionic v7 only user action such as a click will emit ionChange event.
  private ignoreIonChange() {
    if (!this.ignoreIonChangeEvent) {
      this.ignoreIonChangeEvent = true;
      setTimeout(() => (this.ignoreIonChangeEvent = false), 100);
    }
  }
}
