import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { KassandraForecastAdapter } from 'src/app/maps/livedata/kassandra-forecast.adapter';
import { KassandraForecastData } from 'src/app/maps/livedata/kassandra-forecast.model';

@Injectable({
  providedIn: 'root',
})
export class KassandraForecastService extends LivedataServiceBase<KassandraForecastData> {
  protected readonly endpointUri = 'livedata/kassandra';
  protected readonly requiredFeatures = [
    new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ),
    new Feature(FeatureId.COCKPIT_KASSANDRA, FeatureAccessLevel.READ),
  ];

  readonly kassandra$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, KassandraForecastAdapter.adapt, destinationService);
  }
}
