import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { firstValueFrom, switchMap, takeUntil, tap } from 'rxjs';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MediaCenterService } from 'src/app/media-center/media-center.service';
import { Playlist } from 'src/app/media-center/playlists/domain/playlist.model';
import { MediaCenterPlaylistsService } from 'src/app/media-center/playlists/media-center-playlists.service';
import { Screen } from 'src/app/media-center/screens/domain/screen.model';
import { ScreenGroup } from 'src/app/media-center/screens/domain/screen-group.model';
import { InfoBannerComponent } from 'src/app/media-center/screens/infobanner-modal/infobanner.component';
import { MediaCenterScreenService } from 'src/app/media-center/screens/media-center-screens.service';
import { OperatinghourComponent } from 'src/app/media-center/screens/operatinghour-modal/operatinghour.component';
import { RemoteAccessService } from 'src/app/remote-access/remote-access.service';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'sis-media-center-screen-group',
  templateUrl: './screen-group.component.html',
  styleUrls: ['./screen-group.component.scss', '../../media-center-global.scss'],
})
export class MediaCenterScreenGoupComponent extends Unsubscriber {
  readonly bigScreenMode$ = this.screenSizeService.bigScreenMode$;

  screens: Screen[];
  screensPlaylist: Playlist[];
  playlists: any[];


  @Input() group: ScreenGroup;
  @Input() filter: string;
  @Input() infoBannerReadPermission: boolean;
  @Input() writePermission: boolean;
  @Input() openEditscreen: () => void;
  @Input() reorderingEnabled: boolean;

  constructor(
    private mediaCenterService: MediaCenterService,
    private destinationService: DestinationService,
    private userMessageService: UserMessageService,
    private screensService: MediaCenterScreenService,
    private confirmationDialogService: ConfirmationDialogService,
    private screenSizeService: ScreenSizeService,
    private remoteAccessService: RemoteAccessService,
    private playlistService: MediaCenterPlaylistsService,
    private modalCtrl: ModalController
  ) {
    super();
  }

  ngOnInit() {
    this.screensService.filteredScreens$
      .pipe(
        tap((screens) => {
          this.screens = screens.filter((screen) =>
            screen.groupGuid === this.group.guid || (this.group.guid == null && screen.groupGuid == null)
          );
          this.screens = this.screens.sort((a, b) => a.order - b.order);
        }),
        switchMap(() => this.playlistService.playlists$),
        takeUntil(this.onDestroy$)
      )
      .subscribe((playlists) => {
        this.playlists = [...playlists.sort((a, b) => a.name.localeCompare(b.name))];
        this.playlists.unshift({ guid: null, name: '' });
      });
  }


  doReorder(ev: any) {
    ev.detail.complete(this.screens);
    this.screens.forEach((item, index) => (item.order = index));
  }

  async deleteScreen(screen: Screen): Promise<void> {
    if (!this.writePermission) {
      return;
    }

    const confirmed = await this.confirmationDialogService.presentAlert('mediacenter.phrase.deleteScreen', 'mediacenter.term.delete');
    if (confirmed) {
      screen.updating = true;
      await this.screensService.deleteScreen(screen);
      screen.updating = false;
    }
  }

  async openSlideShowPreview(screenLabel: string, screenWidth: number, screenHeight: number, previewUrl: string | null): Promise<void> {
    await this.mediaCenterService.openSlideShowScreenPreview(screenLabel, screenWidth, screenHeight, previewUrl);
  }

  async copyUrl(screenLabel: string, previewUrl: string | null): Promise<void> {
    const tenant = await firstValueFrom(this.destinationService.selectedTenant$);
    const url = previewUrl || (tenant ? `${environment.slideshowPlayerWebApp}/slideshow/${tenant.sisId}/screen/${screenLabel}` : undefined);

    navigator.clipboard.writeText(url);
    const toast = await this.userMessageService.createSingleToast(
      new UserMessage({
        message: 'mediacenter.phrase.copiedToClipboard',
        icon: UserMessageIcon.info,
        durationMs: 2000,
        position: 'top',
        color: UserMessageColor.white,
      })
    );
    await toast.present();
  }

  async openOperatinghourModal(screen?: Screen): Promise<void> {
    if (!this.writePermission) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: OperatinghourComponent,
      cssClass: 'sis-operatinghour-modal',
      backdropDismiss: false,
      componentProps: {
        screen,
      },
    });
    await modal.present();
  }

  async openInfoBannerModal(screen: Screen): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: InfoBannerComponent,
      cssClass: 'sis-infobanner-modal',
      backdropDismiss: false,
      componentProps: {
        screen: screen,
      },
    });
    await modal.present();
  }

  async openRemoteAccess(screen: Screen): Promise<void> {
    await this.remoteAccessService.openSession(screen.remoteAccess, new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.WRITE));
  }

  itemId(_: number, screen: Screen): string {
    return screen.guid;
  }

  async selectPlaylist(playlistGuid: string, screen: Screen): Promise<void> {
    screen.playlistGuid = playlistGuid;

    await this.screensService.updateScreens([screen]);
  }
}
