import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { ApplicationRef, Component, ComponentFactoryResolver, Injector, OnInit, ViewChild } from '@angular/core';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { RemoteAccess } from 'src/app/maps/domain/remote-access.model';
import { AccessType } from 'src/app/remote-access/access-type.enum';
import { ConnectionParameter } from 'src/app/remote-access/connection-parameter.model';
import RFB from 'src/app/remote-access/noVNC/core/rfb.js';
import { RemoteAccessService } from 'src/app/remote-access/remote-access.service';

@Component({
  selector: 'sis-remote-access',
  templateUrl: './remote-access.component.html',
})
export class RemoteAccessComponent extends Unsubscriber implements OnInit {
  static readonly remoteAccessDivId = 'remoteAccessContent';

  readonly remoteAccessDivId = RemoteAccessComponent.remoteAccessDivId;

  private readonly defaultExternalWindowWidth = 1200;
  private readonly defaultExternalWindowHeight = 900;

  private readonly onWindowUnloadFn = this.onWindowUnload.bind(this);
  private rfb: RFB;
  private externalWindowNoVncAccess: Window = null;
  private externalWindowHttpAccess: Window = null;
  private remoteAccessAgentId: string;
  private bigScreenMode: boolean;

  connecting = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    private remoteAccessService: RemoteAccessService,
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService
  ) {
    super();
  }

  @ViewChild(CdkPortal) portal: CdkPortal;

  ngOnInit() {
    this.remoteAccessService.remoteAccessConnectionParameter$
      .pipe(
        filter((connection) => !!connection),
        takeUntil(this.onDestroy$),
        switchMap((connection) =>
          this.destinationService.selectedTenantFeatures$.pipe(
            take(1),
            map<Feature[], [ConnectionParameter, boolean]>((features) => [
              connection,
              !features.some((feature) => feature.hasMinimumRequirementFor(connection.requiredFeatureForWriteAccess)),
            ])
          )
        )
      )
      .subscribe(([connectionParameter, viewOnly]) => {
        if (connectionParameter?.status === ConnectionParameter.STATUS_OK) {
          switch (connectionParameter.accessType) {
            case AccessType.VNC:
              this.startHTTPClient(connectionParameter.url);
              // this.startVNCClient(
              //   connectionParameter.host,
              //   connectionParameter.port,
              //   connectionParameter.siscose,
              //   connectionParameter.path,
              //   viewOnly
              // );
              break;
            case AccessType.HTTP:
              this.startHTTPClient(connectionParameter.host);
          }
        }
      });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.remoteAccessService.showRemoteAccessWindow$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      if (!data) {
        return;
      }

      if (!data.show) {
        this.remoteAccessAgentId = null;
        this.externalWindowNoVncAccess?.close();
        return;
      }

      if (data.remoteAccess?.accessType === AccessType.VNC) {
        const externalWindowDimensions = this.getAvailableWindowDimensions(data.remoteAccess.windowDimensions);
        this.externalWindowHttpAccess = window.open('', '', externalWindowDimensions);
        this.externalWindowHttpAccess.addEventListener('unload', this.onWindowUnloadFn);
        // if (this.remoteAccessAgentId != null) {
        //   this.externalWindowNoVncAccess?.focus();
        // } else {
        //   this.createAndAttachExternalWindowNoVnc(data.remoteAccess);
        //   this.externalWindowNoVncAccess?.focus();
        //   this.connecting = true;
        //   this.remoteAccessAgentId = data.remoteAccess.agentId;
        // }
      }

      if (data.remoteAccess?.accessType === AccessType.HTTP) {
        const externalWindowDimensions = this.getAvailableWindowDimensions(data.remoteAccess.windowDimensions);
        this.externalWindowHttpAccess = window.open('', '', externalWindowDimensions);
        this.externalWindowHttpAccess.addEventListener('unload', this.onWindowUnloadFn);
      }
    });
  }

  private startVNCClient(host: string, port: string, encodedPassword: string, path: string, viewOnly: boolean) {
    const url = 'wss://' + host + (port ? ':' + port : '') + '/' + path;
    const password = atob(encodedPassword);

    const target = this.getTarget();
    if (target == null) {
      this.remoteAccessService.closeSession();
      return;
    }

    this.rfb = new RFB(target, this.externalWindowNoVncAccess?.document, url, {
      credentials: { password },
    });

    this.rfb.viewOnly = viewOnly;

    this.rfb.addEventListener('connect', () => {
      this.scaleWindowContent();
      this.connecting = false;
    });

    this.rfb.addEventListener('disconnect', (event) => {
      this.remoteAccessService.closeSession();

      if (event?.detail && !event.detail.clean) {
        this.remoteAccessService.pushConnectionErrorUserMessage();
      }
    });

    if (this.externalWindowNoVncAccess == null) {
      this.rfb?.disconnect();
      this.rfb = null;
    }
  }

  private startHTTPClient(url: string) {
    this.externalWindowHttpAccess.top.location.href = url;
  }

  private createAndAttachExternalWindowNoVnc(remoteAccess: RemoteAccess): void {
    const externalWindowDimensions = this.getAvailableWindowDimensions(remoteAccess.windowDimensions);
    this.externalWindowNoVncAccess = this.bigScreenMode
      ? window.open('', '', externalWindowDimensions)
      : window.open('', '_blank');
    this.externalWindowNoVncAccess.document.body.style.margin = '0';
    this.externalWindowNoVncAccess.document.body.style.overflow = 'hidden';
    this.externalWindowNoVncAccess.document.title = `${remoteAccess.parentAlias} - ${remoteAccess.name}`;
    this.externalWindowNoVncAccess.addEventListener('unload', this.onWindowUnloadFn);
    this.externalWindowNoVncAccess.addEventListener('resize', () => {
      this.scaleWindowContent();
    });

    const host = new DomPortalOutlet(
      this.externalWindowNoVncAccess.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );

    host.attach(this.portal);
  }

  private getTarget(): HTMLElement {
    return this.externalWindowNoVncAccess?.document.getElementById(RemoteAccessComponent.remoteAccessDivId);
  }

  private scaleWindowContent() {
    const windowContent: HTMLCanvasElement = this.externalWindowNoVncAccess.document.getElementsByTagName('canvas')[0];
    if (windowContent) {
      const scale = Math.min(
        this.externalWindowNoVncAccess.innerHeight / windowContent.height,
        this.externalWindowNoVncAccess.innerWidth / windowContent.width
      );
      this.rfb.scaleViewport = scale;
    }
  }

  private onWindowUnload(): void {
    this.externalWindowNoVncAccess?.removeEventListener('unload', this.onWindowUnloadFn);
    this.externalWindowNoVncAccess = null;
    this.externalWindowHttpAccess?.removeEventListener('unload', this.onWindowUnloadFn);
    this.externalWindowHttpAccess = null;
    this.rfb?.disconnect();
    this.rfb = null;
    this.remoteAccessService.closeSession();
  }

  private getAvailableWindowDimensions(windowDimensions: string): string {
    const browserTitleBarHeight = 70;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height - browserTitleBarHeight;

    const [configWidth, configHeight] = windowDimensions?.split(',').map((n) => Number.parseInt(n)) ?? [
      this.defaultExternalWindowWidth,
      this.defaultExternalWindowHeight,
    ];

    let windowWidth = configWidth;
    let windowHeight = configHeight;

    if (configWidth > screenWidth || configHeight > screenHeight) {
      const configRatio = configWidth / configHeight;
      const screenRatio = screenWidth / screenHeight;

      if (screenRatio > configRatio) {
        windowHeight = Math.round(screenHeight * 0.8);
        const scale = windowHeight / configHeight;
        windowWidth = Math.round(configWidth * scale);
      } else {
        windowWidth = Math.round(screenWidth * 0.8);
        const scale = windowWidth / configWidth;
        windowHeight = Math.round(configHeight * scale);
      }
    }

    return `width=${windowWidth},height=${windowHeight}`;
  }
}
