<ion-grid class="sis-detailview-container sis-global-no-scrollbar" data-test="gin-detailview-container">
  <div class="sis-sticky">
    <ion-row class="sis-title-row">
      <ion-col size="8">{{ 'general.term.measurementPoint' | translate }}</ion-col>
    </ion-row>
    <hr />
  </div>

  <ng-container *ngFor="let data of this.displayDatas; let i = index; let odd = odd; trackBy: this.trackByGinName">
    <ion-row
      *ngIf="this.data.value"
      class="sis-data-row"
      [ngClass]="{ odd: odd }"
      data-test="gin-entries-on-gin-detailview"
    >
      <ion-col size="8">{{ this.data.ginName }}</ion-col>
      <ion-col
        class="ion-text-right"
        [ngClass]="this.data.valueFormat"
        [matTooltip]="this.data.dataTimestamp"
        matTooltipClass="sis-global-tooltip"
        data-test="gin-value"
        >{{ this.data.value }}&nbsp;</ion-col
      >
      <ion-col class="ion-text-left" [ngClass]="this.data.valueFormat">{{ this.data.unit }}</ion-col>
      <ion-col size="auto">
        <a
          [href]="this.data.externalLink || 'javascript:void(0)'"
          target="_blank"
          [ngClass]="{ 'sis-gin-icon-open-disabled': !this.data.externalLink }"
        >
          <ion-icon
            name="open-outline"
            class="sis-icon-open"
            [matTooltip]="this.data.externalLink"
            matTooltipClass="sis-global-tooltip"
          ></ion-icon>
        </a>
      </ion-col>
    </ion-row>
  </ng-container>
  <ion-row>
    <ion-col class="sis-gin-link-col">
      <a href="https://www.info.gin.admin.ch/" target="_blank" class="sis-gin-link">
        {{ 'gin.term.platformName' | translate }}
        <ion-icon name="open-outline" class="sis-gin-bottom-link-open"></ion-icon>
      </a>
    </ion-col>
  </ion-row>
</ion-grid>
