import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ContextMenuItem } from 'src/app/core/components/context-menu/context-menu-item.model';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { DestinationService } from 'src/app/domain/destination/destination.service';

@Component({
  selector: 'sis-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent extends Unsubscriber implements OnInit {
  @Input() x = 0;
  @Input() y = 0;
  @Input() menuItems: ContextMenuItem[];
  @Input() title: string;

  @Output() menuItemSelected = new EventEmitter<ContextMenuItem>();

  constructor(private destinationService: DestinationService) {
    super();
  }

  ngOnInit() {
    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.menuItems.forEach((menuItem) => {
        menuItem.disabled = !features.some((f) => f.hasMinimumRequirementFor(menuItem.requiredFeature));
      });
    });
  }

  emitSelectedItem(menuitem: ContextMenuItem) {
    this.menuItemSelected.emit(menuitem);
  }

  close() {
    this.emitSelectedItem(null);
  }
}
