import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import Highcharts, { Options } from 'highcharts';
import { Observable, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { TruScanData } from 'src/app/maps/livedata/truscan-data.model';
import { TruScanDataService } from 'src/app/maps/livedata/truscan-data.service';

@Component({
  selector: 'sis-truscan-item',
  templateUrl: './truscan-item.component.html',
  styleUrls: ['./truscan-item.component.scss'],
})
export class TruScanItemComponent extends Unsubscriber implements OnInit {
  @Input() truScan: TruScanData;

  chart: Chart;
  private chartRef: Highcharts.Chart;

  chartOptions: Options = {
    chart: {
      type: 'gauge',
      backgroundColor: 'transparent',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: '45px',
    },
    title: undefined,
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: null,
      center: ['50%', '80%'],
      size: '40px',
    },
    yAxis: {
      min: 0,
      max: 100,
      tickPosition: 'inside',
      tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
      tickLength: 0,
      tickWidth: 2,
      minorTickInterval: null,
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      plotBands: [
        {
          from: 0,
          to: 10,

          color: 'var(--ion-color-danger)',
          thickness: 7,
        },
        {
          from: 10,
          to: 50,
          color: 'var(--ion-color-yellow)',
          thickness: 7,
        },
        {
          from: 50,
          to: 100,
          color: 'var(--ion-color-success)',
          thickness: 7,
        },
      ],
    },
    credits: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        type: 'gauge',
        dataLabels: {
          enabled: false,
        },
        dial: {
          radius: '60%',
          backgroundColor: 'var(--ion-color-tertiary)',
          baseWidth: 4,
          baseLength: '0%',
          rearLength: '0%',
        },
        pivot: {
          backgroundColor: 'var(--ion-color-tertiary)',
          radius: 3,
        },
        data: [0],
      },
    ],
  };

  constructor(private truScanDataService: TruScanDataService) {
    super();
  }

  ngOnInit() {
    this.chart = new Chart(this.chartOptions);

    this.chart.ref$.pipe(take(1)).subscribe((chartRef) => {
      this.chartRef = chartRef;
      this.reflowChart(this.onDestroy$);
    });

    this.truScanDataService.truScanDatas$.pipe(takeUntil(this.onDestroy$)).subscribe((truScanDatas) => {
      this.truScan = truScanDatas.find((data) => data.ropeId === this.truScan.ropeId);
      this.setSeriesData(this.truScan.health);
    });
  }

  reflowChart(onDestroy$: Observable<void>): void {
    timer(100)
      .pipe(takeUntil(onDestroy$))
      .subscribe(() => {
        this.chartRef.reflow();
      });
  }

  setSeriesData(ropeHealth: number): void {
    if (this.chart) {
      this.chart.removePoint(0);
      this.chart.addPoint(ropeHealth);
    }
  }
}
