import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakdownInfoLibraryComponent } from 'src/app/breakdowninfo/breakdowninfo-library/breakdowninfo-library.component';
import { BreakdownInfoTextSelectorComponent } from 'src/app/breakdowninfo/breakdowninfo-text-selector/breakdowninfo-text-selector.component';
import { BreakdownInfo } from 'src/app/breakdowninfo/domain/breakdowninfo.model';
import { BreakdownInfoService } from 'src/app/breakdowninfo/domain/breakdowninfo.service';
import { BreakdownInfoActiveText } from 'src/app/breakdowninfo/domain/breakdowninfo-activetext.model';
import { BreakdownInfoActiveTextService } from 'src/app/breakdowninfo/domain/breakdowninfo-activetext.service';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './breakdowninfo.page.html',
  styleUrls: ['./breakdowninfo.page.scss'],
})
export class BreakdownInfoPage extends Unsubscriber implements OnInit {
  private static readonly requiredFeature = new Feature(FeatureId.BREAKDOWNINFO, FeatureAccessLevel.WRITE);

  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;
  readonly baseUrlPublicAssets = environment.baseUrlCockpitBlobStorage + '/public-assets';

  breakdownInfos: BreakdownInfo[] = [];
  activeTexts: BreakdownInfoActiveText[];
  writePermission: boolean;
  bigScreenMode: boolean;
  featureId = FeatureId.BREAKDOWNINFO;

  constructor(
    private breakdownInfoService: BreakdownInfoService,
    private breakdownInfoActiveTextService: BreakdownInfoActiveTextService,
    private modalCtrl: ModalController,
    private destinationService: DestinationService,
    private logbookModalService: LogbookModalService,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit() {
    this.breakdownInfoService.breakdownInfos$.pipe(takeUntil(this.onDestroy$)).subscribe((breakdownInfos) => {
      this.breakdownInfos = breakdownInfos.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.breakdownInfos.forEach((breakdownInfo) => {
        breakdownInfo.iconName = `${this.baseUrlPublicAssets}/icons/sismedia/${breakdownInfo.iconName}`;
      });
    });

    this.breakdownInfoActiveTextService.activeTexts$.pipe(takeUntil(this.onDestroy$)).subscribe((activeTexts) => {
      this.activeTexts = activeTexts;
      for (const breakdownInfo of this.breakdownInfos) {
        breakdownInfo.isUpdating = false;
      }
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission =
        features.some((f) => f.hasMinimumRequirementFor(BreakdownInfoPage.requiredFeature)) ?? false;
    });

    combineLatest([this.breakdownInfoService.breakdownInfos$, this.breakdownInfoActiveTextService.activeTexts$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([breakdownInfos, activeTexts]) => {
        breakdownInfos.forEach(
          (breakdownInfo) =>
            (breakdownInfo.activeText = activeTexts.find(
              (activeText) => activeText.breakdownInfoGuid === breakdownInfo.guid
            ))
        );
      });
  }

  async showBreakdownInfoLibrary(): Promise<void> {
    if (!this.writePermission) {
      return Promise.resolve();
    }

    const modal = await this.modalCtrl.create({
      component: BreakdownInfoLibraryComponent,
      cssClass: 'sis-breakdown-library-modal',
    });

    return modal.present();
  }

  async showTextSelector(selectedBreakdownInfoItem: BreakdownInfo): Promise<void> {
    if (!this.writePermission) {
      return Promise.resolve();
    }

    const modal = await this.modalCtrl.create({
      component: BreakdownInfoTextSelectorComponent,
      componentProps: {
        breakdownInfo: selectedBreakdownInfoItem,
      },
      backdropDismiss: false,
    });

    return modal.present();
  }
  
}
