<ion-grid class="sis-global-page-grid">
  <sis-page-title [title]="'general.term.slopesOperatingTimes'" [featureId]="[[this.featureId]]"> </sis-page-title>

  <ion-row class="sis-operating-times-row">
    <ion-col class="ion-no-padding">
      <ion-grid class="ion-no-padding">
        <sis-slopes-operating-times-item
          *ngFor="let guid of this.slopesOperatingTimesGuids$ | async"
          [guid]="guid"
        ></sis-slopes-operating-times-item>
      </ion-grid>
    </ion-col>
  </ion-row>

  <ion-row class="sis-footer">
    <ion-col>
      <sis-general-button
        [text]="'general.term.save'"
        [ngClass]="{'sis-global-button-padding-right' : this.bigScreenMode}"
        [btnType]="'Secondary'"
        [showSpinner]="this.isSaving"
        (sisClick)="this.save()"
        [disabled]="!this.writePermission || !(this.hasChanges$ | async) || this.isSaving"
        [width]="this.bigScreenMode ? '120px' : '100%'"
        data-test="slopes-operating-times-save-btn"
      ></sis-general-button>
      <sis-general-button
        [text]="'general.term.reset'"
        [ngClass]="{'sis-global-button-padding-right' : this.bigScreenMode}"
        [btnType]="'Secondary'"
        [showSpinner]="this.isSaving"
        (sisClick)="this.reset()"
        [disabled]="!this.writePermission || !(this.hasChanges$ | async) || this.isSaving"
        [width]="this.bigScreenMode ? '120px' : '100%'"
        data-test="slopes-operating-times-reset-btn"
      ></sis-general-button>
    </ion-col>
  </ion-row>
</ion-grid>
