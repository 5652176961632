import { TranslateService } from '@ngx-translate/core';
import { map, merge, Observable, of, switchMap } from 'rxjs';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { ForecastTrend } from 'src/app/maps/trenddata/forecast-trend.model';
import { MeteoTrendService } from 'src/app/maps/trenddata/meteo-trend.service';
import { WindTrend } from 'src/app/maps/trenddata/wind-trend.model';
import { ChartAdapter } from 'src/app/maps/widgets/charts/chart-adapter';
import { ChartSettingsService } from 'src/app/maps/widgets/charts/chart-settings.service';
import { ChartSelector } from 'src/app/maps/widgets/charts/ropeway-chart/chart-selector.model';
import { RopewayChartHandler } from 'src/app/maps/widgets/charts/ropeway-chart/ropeway-chart-handler';

export class MeteoHandler extends RopewayChartHandler {
  private static readonly maxTranslateString = 'analytics.term.max';
  private static readonly avgTranslateString = 'analytics.term.avg';
  private static readonly forecastAvgTranslateString = 'analytics.term.forecastAvg';
  private static readonly forecastMaxTranslateString = 'analytics.term.forecastMax';

  private readonly trendLineColor = ['#0013ccff', '#efb700ff', '#cc00bcff'];
  private readonly forecastLineColor = ['#1395b9ff', '#8b774aff', '#ff3f82ff'];

  private meteoStationIndex: number;
  private meteoStationSetting: string;
  private meteoStationForecastSetting: string;
  private seriesIdAvg: string;
  private seriesIdMax: string;
  private seriesIdForecastAvg: string;
  private seriesIdForecastMax: string;

  private seriesNameAvg: string;
  private seriesNameMax: string;
  private seriesNameForecastAvg: string;
  private seriesNameForecastMax: string;

  constructor(
    protected chartSettingsService: ChartSettingsService,
    protected chartAdapter: ChartAdapter,
    private meteoTrendService: MeteoTrendService,
    private meteoStation: MeteoStation,
    translateService: TranslateService,
    ropeway: Ropeway,
    yAxisUnits: string[]
  ) {
    super(chartSettingsService, chartAdapter, translateService, ropeway, yAxisUnits);

    this.meteoStationIndex = ropeway.meteoStations.indexOf(this.meteoStation);
    this.meteoStationSetting = this.meteoStation.meteoDeviceSisId;
    this.meteoStationForecastSetting = this.meteoStation.meteoDeviceSisId + 'forecast';
    this.seriesIdAvg = this.meteoStationSetting + 'avg';
    this.seriesIdMax = this.meteoStationSetting + 'max';
    this.seriesIdForecastAvg = this.meteoStationForecastSetting + 'avg';
    this.seriesIdForecastMax = this.meteoStationForecastSetting + 'max';

    this.seriesNameAvg = this.meteoStation.alias + ' ' + translateService.instant(MeteoHandler.avgTranslateString);
    this.seriesNameMax = this.meteoStation.alias + ' ' + translateService.instant(MeteoHandler.maxTranslateString);
    this.seriesNameForecastAvg =
      this.meteoStation.alias + ' ' + translateService.instant(MeteoHandler.forecastAvgTranslateString);
    this.seriesNameForecastMax =
      this.meteoStation.alias + ' ' + translateService.instant(MeteoHandler.forecastMaxTranslateString);
  }

  seriesObservable(): Observable<Array<{ seriesId: string; data: number[][] }>> {
    return merge(this.getTrendObservable(), this.getForecastObservable());
  }

  getChartSelectors(): ChartSelector[] {
    const selectors = [
      {
        setting: this.meteoStation.meteoDeviceSisId,
        titleStrings: ['ropeway.term.wind', this.meteoStation.alias],
        ropewaySisId: this.ropeway.sisId,
        selected$: this.chartSettingsService.getRopewaySetting(this.ropeway.sisId, this.meteoStation.meteoDeviceSisId),
      },
    ];

    if (this.meteoStation.hasForecast) {
      selectors.push({
        setting: this.meteoStation.meteoDeviceSisId + 'forecast',
        titleStrings: ['ropeway.term.wind', this.meteoStation.alias, 'general.term.forecast'],
        ropewaySisId: this.ropeway.sisId,
        selected$: this.chartSettingsService.getRopewaySetting(
          this.ropeway.sisId,
          this.meteoStation.meteoDeviceSisId + 'forecast'
        ),
      });
    }

    return selectors;
  }

  private getTrendObservable(): Observable<Array<{ seriesId: string; data: number[][] }>> {
    return this.chartSettingsService.getRopewaySetting(this.ropeway.sisId, this.meteoStationSetting).pipe(
      switchMap((enabled) => {
        if (enabled) {
          this.chartAdapter.addSeries(this.seriesIdAvg, {
            name: this.seriesNameAvg,
            id: this.seriesIdAvg,
            type: 'spline' as any,
            lineWidth: 1,
            tooltip: {
              valueSuffix: ' km/h',
            },
            color: this.trendLineColor[this.meteoStationIndex % this.trendLineColor.length],
            yAxis: this.yAxisUnits.indexOf('km/h'),
          });
          this.chartAdapter.addSeries(this.seriesIdMax, {
            name: this.seriesNameMax,
            id: this.seriesIdMax,
            type: 'spline' as any,
            lineWidth: 2,
            tooltip: {
              valueSuffix: ' km/h',
            },
            color: this.trendLineColor[this.meteoStationIndex % this.trendLineColor.length],
            yAxis: this.yAxisUnits.indexOf('km/h'),
          });

          return this.chartAdapter.loadData([this.meteoTrendService.getWindTrends(this.meteoStation.guid)]).pipe(
            map((data) => [
              { seriesId: this.seriesIdAvg, data: this.getAverageTrends(data[0]) },
              { seriesId: this.seriesIdMax, data: this.getMaxTrends(data[0]) },
            ])
          );
        } else {
          this.chartAdapter.removeSeries(this.seriesIdAvg);
          this.chartAdapter.removeSeries(this.seriesIdMax);
          return of([
            { seriesId: this.seriesIdAvg, data: null },
            { seriesId: this.seriesIdMax, data: null },
          ]);
        }
      })
    );
  }

  private getForecastObservable(): Observable<Array<{ seriesId: string; data: number[][] }>> {
    if (!this.meteoStation.hasForecast) {
      return of([]);
    }

    return this.chartSettingsService.getRopewaySetting(this.ropeway.sisId, this.meteoStationForecastSetting).pipe(
      switchMap((enabled) => {
        if (enabled) {
          this.chartAdapter.addSeries(this.seriesIdForecastAvg, {
            name: this.seriesNameForecastAvg,
            id: this.seriesIdForecastAvg,
            type: 'spline' as any,
            className: 'line-forecast',
            tooltip: {
              valueSuffix: ' km/h',
            },
            lineWidth: 1,
            color: this.forecastLineColor[this.meteoStationIndex % this.forecastLineColor.length],
            yAxis: this.yAxisUnits.indexOf('km/h'),
          });
          this.chartAdapter.addSeries(this.seriesIdForecastMax, {
            name: this.seriesNameForecastMax,
            id: this.seriesIdForecastMax,
            type: 'spline' as any,
            className: 'line-forecast',
            tooltip: {
              valueSuffix: ' km/h',
            },
            lineWidth: 2,
            color: this.forecastLineColor[this.meteoStationIndex % this.forecastLineColor.length],
            yAxis: this.yAxisUnits.indexOf('km/h'),
          });

          return this.chartAdapter.loadData([this.meteoTrendService.getForecastTrends(this.meteoStation.guid)]).pipe(
            map((data) => [
              { seriesId: this.seriesIdForecastAvg, data: this.getAverageTrends(this.adaptForecast(data[0])) },
              { seriesId: this.seriesIdForecastMax, data: this.getMaxTrends(this.adaptForecast(data[0])) },
            ])
          );
        } else {
          this.chartAdapter.removeSeries(this.seriesIdForecastAvg);
          this.chartAdapter.removeSeries(this.seriesIdForecastMax);
          return of([
            { seriesId: this.seriesIdForecastAvg, data: null },
            { seriesId: this.seriesIdForecastMax, data: null },
          ]);
        }
      })
    );
  }

  private adaptForecast(forecasts: ForecastTrend[]): WindTrend[] {
    return forecasts.map((item) => ({
      maxValue: item.windSpeedKmhMax,
      meanValue: item.windSpeedKmh,
      timestamp: item.timestamp,
    }));
  }

  // returns an array with index 0 = Timestamp in ms, 1 = Average-Value
  private getAverageTrends(trends: WindTrend[]): number[][] {
    return trends
      .map((item) => {
        const timeStampInMs = item.timestamp.getTime();
        return [timeStampInMs, item.meanValue];
      })
      .sort((i1, i2) => i1[0] - i2[0]);
  }

  // returns an array with index 0 = Timestamp in ms, 1 = Max-Value
  private getMaxTrends(trends: WindTrend[]): number[][] {
    return trends
      .map((item) => {
        const timeStampInMs = item.timestamp.getTime();
        return [timeStampInMs, item.maxValue];
      })
      .sort((i1, i2) => i1[0] - i2[0]);
  }
}
