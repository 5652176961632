<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="100%"
  viewBox="0 0 187.5 225.02"
>
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widget_Hintergrund_temp" data-name="Widget Hintergrund temp">
      <rect width="187.5" height="225" style="fill: none" />
    </g>
    <g id="Widgets">
      <g>
        <rect width="187.5" height="225" style="fill: none" />
        <g>
          <g>
            <ellipse
              cx="129.02"
              cy="73.4"
              rx="5.6"
              ry="7.84"
              transform="translate(-1.11 1.98) rotate(-0.88)"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <path
              d="M122.43,97.82c.17-3.8.32-7.18.36-8,.1-2.21,3-5.9,7.49-6s4.95,7,4.95,7-.43,3.2-.92,7"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
          </g>
          <g>
            <ellipse
              cx="97.59"
              cy="78.78"
              rx="5.6"
              ry="7.26"
              transform="translate(-1.19 1.5) rotate(-0.88)"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <path
              d="M91.16,97.82c.09-1.91.17-3.37.19-3.88.1-2,3-5.47,7.49-5.53s4.94,6.47,4.94,6.47-.17,1.18-.42,2.94"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
          </g>
          <g>
            <ellipse
              cx="63.85"
              cy="69.38"
              rx="5.6"
              ry="7.84"
              transform="translate(-1.05 0.99) rotate(-0.88)"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <path
              d="M57.08,97.82c.23-5.18.49-10.91.54-12.07.1-2.21,3-5.9,7.49-6s5,7,5,7-.77,5.75-1.42,11"
              style="fill: none; stroke: #32849a; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
          </g>
          <g>
            <path
              d="M157.87,55.67a10.05,10.05,0,0,0-10-10H39.66a10.05,10.05,0,0,0-10,10L26.09,97.82,29.63,140a10.05,10.05,0,0,0,10,10H147.84a10.05,10.05,0,0,0,10-10l3.54-42.15ZM36.71,97.82l3.54-42.15h107l3.53,42.15Z"
              style="fill: none; stroke: #989898; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <line
              x1="93.75"
              y1="45.64"
              x2="93.75"
              y2="21.85"
              style="fill: none; stroke: #989898; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
          </g>
          <g>
            <line
              x1="72.52"
              y1="55.67"
              x2="72.52"
              y2="139.94"
              style="fill: none; stroke: #989898; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <line
              x1="114.98"
              y1="55.67"
              x2="114.98"
              y2="139.94"
              style="fill: none; stroke: #989898; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
          </g>
        </g>
      </g>
    </g>
    <g>
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
      >
        ?
        <tspan id="unit" style="font-size: 24px">Pers</tspan>
      </text>
    </g>
  </g>
</svg>
