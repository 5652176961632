import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { AlarmLibraryItemAdapter } from 'src/app/maps/alarmlibrary/domain/alarmlibrary-item.adapter';
import { AlarmLibraryItem } from 'src/app/maps/alarmlibrary/domain/alarmlibrary-item.model';
import { AlarmLibraryOverride } from 'src/app/maps/alarmlibrary/domain/alarmlibraryoverride.model';
import { UserAlarmConfigAdapter } from 'src/app/maps/alarmlibrary/domain/useralarmconfig.adapter';
import { UserAlarmConfig } from 'src/app/maps/alarmlibrary/domain/useralarmconfig.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AlarmLibraryService {
  static readonly LevelFault = 'fault';
  static readonly LevelWarning = 'warning';
  static readonly LevelAlarm = 'alarm';
  static readonly LevelInfo = 'info';
  static readonly LevelCheck = 'check';
  static readonly LevelBypass = 'bypass';

  constructor(private http: HttpClient, private userSettingService: UserSettingsService) {}

  getAlarmLibrary(sisId: string): Observable<AlarmLibraryItem[]> {
    return forkJoin([
      this.requestAlarmLibraryData(sisId, environment.baseUrlApi),
      this.requestUserAlarmConfigData(sisId, environment.baseUrlApi),
    ]).pipe(
      take(1),
      map(([alarmLibraryData, userAlarmConfigData]) => {
        alarmLibraryData.forEach((alarmLibraryEntry) => {
          const userAlarmConfigs = userAlarmConfigData.filter(
            (config) => config.alarmCode === alarmLibraryEntry.code.toLowerCase()
          );
          if (userAlarmConfigs) {
            userAlarmConfigs.forEach((userAlarmConfig) => {
              alarmLibraryEntry.setSetting(userAlarmConfig.setting, userAlarmConfig.enabled);
            });
          }
        });
        return alarmLibraryData;
      })
    );
  }

  updateSetting(sisId: string, alarmCode: string, setting: string, enabled: boolean): void {
    const url = `${environment.baseUrlApi}/user/alarmconfig`;
    this.userSettingService.userSettings$
      .pipe(
        take(1),
        switchMap((userSettings) =>
          this.http
            .post(url, { sisId, alarmCode, setting, enabled, userGuid: userSettings.userGuid })
            .pipe(catchError(() => of()))
        )
      )
      .subscribe();
  }

  updateAlarmLibraryOverride(overrideSettings: AlarmLibraryOverride): void {
    const url = `${environment.baseUrlApi}/alarmlibrary/override`;
    this.userSettingService.userSettings$
      .pipe(
        take(1),
        switchMap((userSettings) => {
          overrideSettings.userGuid = userSettings.userGuid;
          return this.http.post(url, overrideSettings).pipe(catchError(() => of()));
        })
      )
      .subscribe();
  }

  private requestAlarmLibraryData(deviceSisId: string, baseUrlApi: string): Observable<AlarmLibraryItem[]> {
    const url = `${baseUrlApi}/alarmlibrary/${deviceSisId}`;
    return this.http.get(url).pipe(map((data: any) => AlarmLibraryItemAdapter.adapt(data)));
  }

  private requestUserAlarmConfigData(deviceSisId: string, baseUrlApi: string): Observable<UserAlarmConfig[]> {
    const url = `${baseUrlApi}/user/alarmconfig/${deviceSisId}`;
    return this.http.get(url).pipe(map((data) => UserAlarmConfigAdapter.adapt(data)));
  }
}
