import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as nodata from 'highcharts/modules/no-data-to-display.src';
import { BottomDrawerModule } from 'src/app/bottom-drawer/bottom-drawer.module';
import { CoreModule } from 'src/app/core/core.module';
import { DetailsDrawerComponent } from 'src/app/maps/details-drawer/details-drawer.component';
import { CustomMarkerIconComponent } from 'src/app/maps/map/custom-marker-layer/custom-marker-icon/custom-marker-icon.component';
import { CustomMarkerLayerComponent } from 'src/app/maps/map/custom-marker-layer/custom-marker-layer.component';
import { EnterTheViewportNotifierDirective } from 'src/app/maps/map/enter-viewport-notifier.directive';
import { MapComponent } from 'src/app/maps/map/map.component';
import { MeteoLayerComponent } from 'src/app/maps/map/meteo-layer/meteo-layer.component';
import { WindIconComponent } from 'src/app/maps/map/meteo-layer/wind-icon/wind-icon.component';
import { RopewayLayerComponent } from 'src/app/maps/map/ropeway-layer/ropeway-layer.component';
import { RopewayLineComponent } from 'src/app/maps/map/ropeway-layer/ropeway-line/ropeway-line.component';
import { MapsPage } from 'src/app/maps/maps.page';
import { ModalPopupComponent } from 'src/app/maps/modal-popup/modal-popup.component';
import { WidgetListComponent } from 'src/app/maps/widget-list/widget-list.component';
import { ControllerSelectorComponent } from 'src/app/maps/widget-sidepane/controller-selector/controller-selector.component';
import { SidepaneAlarmlistComponent } from 'src/app/maps/widget-sidepane/sidepane-alarmlist/sidepane-alarmlist.component';
import { SidepaneAlarmlistItemComponent } from 'src/app/maps/widget-sidepane/sidepane-alarmlist/sidepane-alarmlist-item/sidepane-alarmlist-item.component';
import { WebcamComponent } from 'src/app/maps/widget-sidepane/webcam/webcam.component';
import { WebcamModalComponent } from 'src/app/maps/widget-sidepane/webcam/webcam-modal/webcam-modal.component';
import { WidgetSidepaneComponent } from 'src/app/maps/widget-sidepane/widget-sidepane.component';
import { AbbSmartSensorWidgetComponent } from 'src/app/maps/widgets/abbsmartsensor-widget/abbsmartsensor-widget.component';
import { AlarmWidgetComponent } from 'src/app/maps/widgets/alarm-widget/alarm-widget.component';
import { CabinPositionWidgetComponent } from 'src/app/maps/widgets/cabinposition-widget/cabinposition-widget.component';
import { CarriagetiltWidgetComponent } from 'src/app/maps/widgets/carriagetilt-widget/carriagetilt-widget.component';
import { ChartSettings } from 'src/app/maps/widgets/charts/chart-settings.model';
import { IcingChartComponent } from 'src/app/maps/widgets/charts/icing-chart/icing-chart.component';
import { RopewayChartComponent } from 'src/app/maps/widgets/charts/ropeway-chart/ropeway-chart.component';
import { RopewayEnergyChartComponent } from 'src/app/maps/widgets/charts/ropeway-energy-chart/ropeway-energy-chart.component';
import { SpeedChartComponent } from 'src/app/maps/widgets/charts/speed-chart/speed-chart.component';
import { TemperatureChartComponent } from 'src/app/maps/widgets/charts/temperature-chart/temperature-chart.component';
import { TorqueChartComponent } from 'src/app/maps/widgets/charts/torque-chart/torque-chart.component';
import { WetBulbChartComponent } from 'src/app/maps/widgets/charts/wet-bulb-chart/wet-bulb-chart.component';
import { WindChartComponent } from 'src/app/maps/widgets/charts/wind-chart/wind-chart.component';
import { ClampingCountWidgetComponent } from 'src/app/maps/widgets/clampingcount-widget/clampingcount-widget.component';
import { DestinationAlarmWidgetComponent } from 'src/app/maps/widgets/destination-alarm-widget/destination-alarm-widget.component';
import { AbbSmartSensorDetailViewComponent } from 'src/app/maps/widgets/detailviews/abbsmartsensor-detailview/abbsmartsensor-detailview.component';
import { AbbSmartSensorItemComponent } from 'src/app/maps/widgets/detailviews/abbsmartsensor-detailview/abbsmartsensor-item/abbsmartsensor-item.component';
import { AlarmDetailViewComponent } from 'src/app/maps/widgets/detailviews/alarm-detailview/alarm-detailview.component';
import { ClampingCountDetailViewComponent } from 'src/app/maps/widgets/detailviews/clampingcount-detailview/clampingcount-detailview.component';
import { DestinationAlarmDetailViewComponent } from 'src/app/maps/widgets/detailviews/destination-alarm-detailview/destination-alarm-detailview.component';
import { DevicesOnDetailViewComponent } from 'src/app/maps/widgets/detailviews/devices-on-detailview/devices-on-detailview.component';
import { DevicesOnDetailViewItemComponent } from 'src/app/maps/widgets/detailviews/devices-on-detailview/devices-on-detailview-item/devices-on-detailview-item.component';
import { EfaDetailViewComponent } from 'src/app/maps/widgets/detailviews/efa-detailview/efa-detailview.component';
import { EnergyDetailViewComponent } from 'src/app/maps/widgets/detailviews/energy-detailview/energy-detailview.component';
import { FirstEntriesDetailViewComponent } from 'src/app/maps/widgets/detailviews/first-entries-detailview/first-entries-detailview.component';
import { GINDetailViewComponent } from 'src/app/maps/widgets/detailviews/gin-detailview/gin-detailview.component';
import { RemoteAccessDetailViewComponent } from 'src/app/maps/widgets/detailviews/remote-access-detailview/remote-access-detailview.component';
import { RemoteAccessDetailViewItemComponent } from 'src/app/maps/widgets/detailviews/remote-access-detailview/remote-access-detailview-item/remote-access-detailview-item.component';
import { RopewayAvailabilityDetailViewComponent } from 'src/app/maps/widgets/detailviews/ropeway-availability-detailview/ropeway-availability-detailview.component';
import { EcoModeChartComponent } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ecomode-chart/ecomode-chart.component';
import { RopewayEcoModeSettingsModal } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/modal/ropeway-ecomode-settings-modal.component';
import { RopewayEcoModeDetailViewComponent } from 'src/app/maps/widgets/detailviews/ropeway-ecomode-detailview/ropeway-ecomode-detailview.component';
import { RopewayEnergyDetailViewComponent } from 'src/app/maps/widgets/detailviews/ropeway-energy-detailview/ropeway-energy-detailview.component';
import { RopewaySettingsModal } from 'src/app/maps/widgets/detailviews/ropeway-settings-detailview/modal/ropeway-settings-modal.component';
import { RopewaySettingsDetailViewComponent } from 'src/app/maps/widgets/detailviews/ropeway-settings-detailview/ropeway-settings-detailview.component';
import { SambesiWhatsUpDetailViewComponent } from 'src/app/maps/widgets/detailviews/sambesi-whatsup-detailview/sambesi-whatsup-detailview.component';
import { SambesiWhatsUpMessageComponent } from 'src/app/maps/widgets/detailviews/sambesi-whatsup-detailview/sambesi-whatsup-message/sambesi-whatsup-message.component';
import { SnowmakingDetailViewComponent } from 'src/app/maps/widgets/detailviews/snowmaking-detailview/snowmaking-detailview.component';
import { StoptimeDetailViewComponent } from 'src/app/maps/widgets/detailviews/stoptime-detailview/stoptime-detailview.component';
import { TransportWeightDetailViewComponent } from 'src/app/maps/widgets/detailviews/transport-weight-detailview/transport-weight-detailview.component';
import { TruScanDetailViewComponent } from 'src/app/maps/widgets/detailviews/truscan-detailview/truscan-detailview.component';
import { TruScanItemComponent } from 'src/app/maps/widgets/detailviews/truscan-detailview/truscan-item/truscan-item.component';
import { WebcamDetailViewComponent } from 'src/app/maps/widgets/detailviews/webcam-detailview/webcam-detailview.component';
import { DevicesonWidgetComponent } from 'src/app/maps/widgets/deviceson-widget/deviceson-widget.component';
import { EfaWidgetComponent } from 'src/app/maps/widgets/efa-widget/efa-widget.component';
import { EnergyWidgetComponent } from 'src/app/maps/widgets/energy-widget/energy-widget.component';
import { FirstEntriesWidgetComponent } from 'src/app/maps/widgets/first-entries-widget/first-entries-widget.component';
import { GeartempWidgetComponent } from 'src/app/maps/widgets/geartemp-widget/geartemp-widget.component';
import { GINWidgetComponent } from 'src/app/maps/widgets/gin-widget/gin-widget.component';
import { IcingWidgetComponent } from 'src/app/maps/widgets/icing-widget/icing-widget.component';
import { KassandraWidgetComponent } from 'src/app/maps/widgets/kassandra-widget/kassandra-widget.component';
import { OpHoursWidgetComponent } from 'src/app/maps/widgets/ophours-widget/ophours-widget.component';
import { PassengerCountWidgetComponent } from 'src/app/maps/widgets/passengercount-widget/passengercount-widget.component';
import { RemoteAccessWidgetComponent } from 'src/app/maps/widgets/remote-access-widget/remote-access-widget.component';
import { RopekmWidgetComponent } from 'src/app/maps/widgets/ropekm-widget/ropekm-widget.component';
import { RopewayChartWidgetComponent } from 'src/app/maps/widgets/ropeway-chart-widget/ropeway-chart-widget.component';
import { RopewayEcoModeWidgetComponent } from 'src/app/maps/widgets/ropeway-ecomode-widget/ropeway-ecomode-widget.component';
import { RopewaySettingsWidgetComponent } from 'src/app/maps/widgets/ropeway-settings-widget/ropeway-settings-widget.component';
import { SambesiWhatsUpWidgetComponent } from 'src/app/maps/widgets/sambesi-whatsup-widget/sambesi-whatsup-widget.component';
import { SnowmakingWidgetComponent } from 'src/app/maps/widgets/snowmaking-widget/snowmaking-widget.component';
import { SpeedWidgetComponent } from 'src/app/maps/widgets/speed-widget/speed-widget.component';
import { StoptimeWidgetComponent } from 'src/app/maps/widgets/stoptime-widget/stoptime-widget.component';
import { TemperatureWidgetComponent } from 'src/app/maps/widgets/temperature-widget/temperature-widget.component';
import { TorqueWidgetComponent } from 'src/app/maps/widgets/torque-widget/torque-widget.component';
import { TransportWeightWidgetComponent } from 'src/app/maps/widgets/transportweight-widget/transportweight-widget.component';
import { TripsWidgetComponent } from 'src/app/maps/widgets/trips-widget/trips-widget.component';
import { TruScanWidgetComponent } from 'src/app/maps/widgets/truscan-widget/truscan-widget.component';
import { UtilizationWidgetComponent } from 'src/app/maps/widgets/utilization-widget/utilization-widget.component';
import { WeathertrendWidgetComponent } from 'src/app/maps/widgets/weathertrend-widget/weathertrend-widget.component';
import { WebcamWidgetComponent } from 'src/app/maps/widgets/webcam-widget/webcam-widget.component';
import { WetBulbWidgetComponent } from 'src/app/maps/widgets/wet-bulb-widget/wet-bulb-widget.component';
import { WindWidgetComponent } from 'src/app/maps/widgets/wind-widget/wind-widget.component';
import { WindtendencyWidgetComponent } from 'src/app/maps/widgets/windtendency-widget/windtendency-widget.component';
import { ZonesWidgetComponent } from 'src/app/maps/widgets/zones-widget/zones-widget.component';

const routes: Routes = [
  {
    path: '',
    component: MapsPage,
  },
];

@NgModule({
  imports: [
    BottomDrawerModule,
    ChartModule,
    CommonModule,
    CoreModule,
    FormsModule,
    IonicModule,
    LeafletModule,
    MatIconModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    ScrollingModule,
    TranslateModule,
  ],
  declarations: [
    AbbSmartSensorDetailViewComponent,
    AbbSmartSensorItemComponent,
    AbbSmartSensorWidgetComponent,
    AlarmDetailViewComponent,
    AlarmWidgetComponent,
    CabinPositionWidgetComponent,
    CarriagetiltWidgetComponent,
    ClampingCountDetailViewComponent,
    ClampingCountWidgetComponent,
    ControllerSelectorComponent,
    CustomMarkerIconComponent,
    CustomMarkerLayerComponent,
    DestinationAlarmDetailViewComponent,
    DestinationAlarmWidgetComponent,
    DetailsDrawerComponent,
    DevicesOnDetailViewComponent,
    DevicesOnDetailViewItemComponent,
    DevicesonWidgetComponent,
    EcoModeChartComponent,
    EfaDetailViewComponent,
    EfaWidgetComponent,
    EnergyDetailViewComponent,
    EnergyWidgetComponent,
    EnterTheViewportNotifierDirective,
    FirstEntriesDetailViewComponent,
    FirstEntriesWidgetComponent,
    GeartempWidgetComponent,
    GINWidgetComponent,
    GINDetailViewComponent,
    IcingChartComponent,
    IcingWidgetComponent,
    KassandraWidgetComponent,
    MapComponent,
    MapsPage,
    MeteoLayerComponent,
    ModalPopupComponent,
    OpHoursWidgetComponent,
    PassengerCountWidgetComponent,
    RemoteAccessDetailViewComponent,
    RemoteAccessDetailViewItemComponent,
    RemoteAccessWidgetComponent,
    RopewayAvailabilityDetailViewComponent,
    RopekmWidgetComponent,
    RopewayChartComponent,
    RopewayChartWidgetComponent,
    RopewayEcoModeDetailViewComponent,
    RopewayEcoModeSettingsModal,
    RopewayEcoModeWidgetComponent,
    RopewayEnergyChartComponent,
    RopewayEnergyDetailViewComponent,
    RopewayLayerComponent,
    RopewayLineComponent,
    RopewaySettingsDetailViewComponent,
    RopewaySettingsModal,
    RopewaySettingsWidgetComponent,
    SambesiWhatsUpDetailViewComponent,
    SambesiWhatsUpMessageComponent,
    SambesiWhatsUpWidgetComponent,
    SidepaneAlarmlistComponent,
    SidepaneAlarmlistItemComponent,
    SnowmakingDetailViewComponent,
    SnowmakingWidgetComponent,
    SpeedChartComponent,
    SpeedWidgetComponent,
    StoptimeDetailViewComponent,
    StoptimeWidgetComponent,
    TemperatureChartComponent,
    TemperatureWidgetComponent,
    TorqueChartComponent,
    TorqueWidgetComponent,
    TransportWeightDetailViewComponent,
    TransportWeightWidgetComponent,
    TripsWidgetComponent,
    TruScanItemComponent,
    TruScanDetailViewComponent,
    TruScanWidgetComponent,
    UtilizationWidgetComponent,
    WeathertrendWidgetComponent,
    WebcamComponent,
    WebcamDetailViewComponent,
    WebcamModalComponent,
    WebcamWidgetComponent,
    WetBulbChartComponent,
    WetBulbWidgetComponent,
    WidgetListComponent,
    WidgetSidepaneComponent,
    WindChartComponent,
    WindIconComponent,
    WindtendencyWidgetComponent,
    WindWidgetComponent,
    ZonesWidgetComponent,
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, nodata] }, // add as factory to your providers,
    { provide: ChartSettings, useValue: new ChartSettings() },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MapsPageModule {}
