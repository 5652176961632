<ion-grid class="sis-truscan-container sis-global-no-scrollbar" data-test="truscan-detailview">
  <sis-truscan-item
    *ngFor="let data of this.truScanDatas; trackBy: itemId"
    [truScan]="data"
    class="sis-detail-item"
    data-test="truscan-item"
  ></sis-truscan-item>
</ion-grid>

<ion-grid class="sis-global-sticky-bottom">
  <ion-row>
    <ion-col>
      <ion-grid *ngIf="this.timestamp" style="padding-left: 7px">
        <ion-row>
          <ion-col>
            <span class="sis-timestamp">{{ 'general.phrase.lastUpdate' | translate }}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="sis-timestamp" data-test="truscanTimestamp">{{
            timestamp | date : 'dd.MM.yyyy HH:mm'
          }}</ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
    <ion-col size="auto" style="padding-right: 7px">
      <a href="https://trucockpit.ch/" target="_blank" data-test="truscanLink"
        >TRUcockpit <ion-icon class="sis-icon-open" name="open-outline"></ion-icon
      ></a>
    </ion-col>
  </ion-row>
</ion-grid>
