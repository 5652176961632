import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { environment } from 'src/environments/environment';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'sis-app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends Unsubscriber implements OnDestroy, OnInit {
  isIframe = true;
  isLoggedIn = false;
  loginInProgress = false;

  private isInitialized = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private translateService: TranslateService,
    private appService: AppService,
    private modalCtrl: ModalController,
    titleService: Title
  ) {
    super();

    this.translateService.setDefaultLang('de');

    if (environment.isUiTest) {
      this.mockLoginForUiTests();
      this.translateService.use('de');
    } else {
      const browserLanguage = this.translateService.getBrowserLang();
      this.translateService.use(browserLanguage.match(/de|en|fr|it/) ? browserLanguage : 'en');
    }

    titleService.setTitle(environment.pageTitle);
  }

  ngOnInit() {
    this.isIframe = this.isInIframe();
    this.appService.cleanUpLocalStorage();
    this.setLoggedIn();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status) => status === InteractionStatus.None),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.setLoggedIn();
        this.checkAndSetActiveAccount();
        this.initializeApp();
      });

    this.msalBroadcastService.inProgress$.pipe(delay(0), takeUntil(this.onDestroy$)).subscribe((status) => {
      this.loginInProgress = status === InteractionStatus.Login || status === InteractionStatus.Logout || status === InteractionStatus.HandleRedirect;
    });
  }

  reloadApp(): void {
    window.location.reload();
  }

  isInIframe(): boolean {
    return window !== window.parent && !window.opener;
  }

  @HostListener('document:keydown', ['$event'])
  async handleKeyDownEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      const modal = await this.modalCtrl.getTop();
      if (modal) {
        await this.modalCtrl.dismiss();
      }
    }
  }

  private setLoggedIn() {
    this.isLoggedIn = this.msalService.instance.getAllAccounts().length > 0;
  }

  private checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  private initializeApp() {
    if (this.isIframe || !this.isLoggedIn || this.isInitialized) {
      return;
    }

    this.platform.ready().then((readySource) => {
      if (readySource === 'cordova') {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
    });

    this.isInitialized = true;
  }

  private mockLoginForUiTests(): void {
    const uiTestAccountInfo: AccountInfo = {
      homeAccountId: 'sis',
      environment: 'test',
      tenantId: 'uitest',
      username: 'UI Test User',
      localAccountId: 'sis-local',
      name: 'UI Test User',
    };

    this.msalBroadcastService['inProgress$'] = new BehaviorSubject<InteractionStatus>(InteractionStatus.None).asObservable();
    this.msalService.instance.getAllAccounts = () => [uiTestAccountInfo];
    this.msalService.instance.getActiveAccount = () => uiTestAccountInfo;
    this.msalService.instance.setActiveAccount = () => {};

    window['uiTestSignalRMessage$'] = new Subject<any>();
  }
}
