<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <g>
          <line
            x1="49.99"
            y1="48.59"
            x2="49.99"
            y2="160.38"
            style="fill: none;stroke: #32849a;stroke-linecap: round;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <g>
            <path
              d="M139.67,82.72c0,8.62-3.59,18.33-8,18.33s-8-9.71-8-18.33,3.59-15.6,8-15.6S139.67,74.1,139.67,82.72Z"
              style="fill: none;stroke: #32849a;stroke-miterlimit: 10;stroke-width: 4px"
            />
            <path
              d="M107.25,102.75c-4.79,0-9.06-10.81-9.06-20.14s4.77-17.16,9.56-17.16"
              style="fill: none;stroke: #32849a;stroke-miterlimit: 10;stroke-width: 4px"
            />
            <path
              d="M83.5,104.41c-5.31,0-9.61-11.83-9.61-22.11s4.3-18.54,9.61-18.54"
              style="fill: none;stroke: #32849a;stroke-miterlimit: 10;stroke-width: 4px"
            />
            <path
              d="M131.66,101.05l-71.69,5c-5.31,0-9.61-12.57-9.61-23.73s4.3-20.2,9.61-20.2l71.69,5"
              style="fill: none;stroke: #32849a;stroke-miterlimit: 10;stroke-width: 4px"
            />
          </g>
        </g>
        <g *ngIf="windTendency > 0">
          <path
            d="M79.83,159.38c26.83,0,61.88-1.87,61.88-30"
            style="fill: none;stroke: #ed1c24;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <polyline
            points="132.11 137.6 141.81 129.24 148.32 141.69"
            style="fill: none;stroke: #ed1c24;stroke-miterlimit: 10;stroke-width: 4px"
          />
        </g>
        <g *ngIf="windTendency === 0">
          <polyline
            points="135.79 135.19 146.43 144.38 135.79 153.56"
            style="fill: none;stroke: #f18f2a;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <line
            x1="79.83"
            y1="144.37"
            x2="146.43"
            y2="144.37"
            style="fill: none;stroke: #f18f2a;stroke-miterlimit: 10;stroke-width: 4px"
          />
        </g>
        <g *ngIf="0 > windTendency">
          <path
            d="M79.83,129.24c26.83,0,61.88,1.86,61.88,30"
            style="fill: none;stroke: #009244;stroke-miterlimit: 10;stroke-width: 4px"
          />
          <polyline
            points="132.11 151.01 141.81 159.38 148.32 146.93"
            style="fill: none;stroke: #009244;stroke-miterlimit: 10;stroke-width: 4px"
          />
        </g>
        <rect width="187.5" height="225" style="fill: none" />
      </g>
    </g>
  </g>
</svg>
