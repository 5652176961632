import { WritablePart } from 'src/app/core/utils/type-helper';

export class Configuration {
  static readonly driveTorqueNmKey = 'driveTorqueNm';
  static readonly driveMotorCurrentAmpereKey = 'driveMotorCurrentAmpere';
  static readonly actualSpeedMps = 'actualSpeedMps';
  static readonly icingIntensity = 'icingIntensity';

  id: number;
  configKey: string;
  nominalValue: number;
  warnRangeLow: number;
  warnRangeHigh: number;
  alarmRangeLow: number;
  alarmRangeHigh: number;
  negativeValues: boolean;

  constructor(init?: Partial<WritablePart<Configuration>>) {
    Object.assign(this, init);
  }
}
