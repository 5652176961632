import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AlarmlistService } from 'src/app/alarmlist/alarmlist.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Alarm, AlarmLevel } from 'src/app/domain/alarm.model';

@Component({
  selector: 'sis-sidepane-alarmlist',
  templateUrl: './sidepane-alarmlist.component.html',
  styleUrls: ['./sidepane-alarmlist.component.scss'],
})
export class SidepaneAlarmlistComponent extends Unsubscriber implements OnInit {
  static readonly GRID_PADDING = 0;
  static readonly ITEM_HEIGHT = 49;
  static readonly MAX_VISIBLE_ITEMS = 4;

  static readonly ALARM_TITLE_EMPTY = 'sis-alarm-title-empty';
  static readonly ALARM_TITLE_ALARM = 'sis-alarm-title';
  static readonly ALARM_TITLE_WARNING = 'sis-warning-title';
  static readonly ALARM_TITLE_INFO = 'sis-info-title';

  readonly alarmIconLevels = [AlarmLevel.ALARM, AlarmLevel.FAULT, AlarmLevel.SYSTEM, AlarmLevel.WARNING];

  class: string;
  activeAlarms: Alarm[];
  hasAlarms = false;
  activeAlarmCount: number;
  gridHeight: number;

  constructor(private alarmListService: AlarmlistService) {
    super();
  }

  ngOnInit() {
    this.alarmListService.alarms$.pipe(takeUntil(this.onDestroy$)).subscribe((alarms) => {
      this.updateValues(alarms);
    });
  }

  private getColorClass(): string {
    if (!this.activeAlarms) {
      return SidepaneAlarmlistComponent.ALARM_TITLE_EMPTY;
    }

    if (this.activeAlarms.find((a) => a.level === AlarmLevel.ALARM || a.level === AlarmLevel.FAULT || a.level === AlarmLevel.SYSTEM)) {
      return SidepaneAlarmlistComponent.ALARM_TITLE_ALARM;
    }

    if (this.activeAlarms.find((a) => a.level === AlarmLevel.WARNING)) {
      return SidepaneAlarmlistComponent.ALARM_TITLE_WARNING;
    }

    return SidepaneAlarmlistComponent.ALARM_TITLE_ALARM;
  }

  private getGridHeight(activeAlarmCount: number): number {
    return (
      Math.min(activeAlarmCount, SidepaneAlarmlistComponent.MAX_VISIBLE_ITEMS) * SidepaneAlarmlistComponent.ITEM_HEIGHT +
      SidepaneAlarmlistComponent.GRID_PADDING
    );
  }

  private updateValues(alarms: Alarm[]): void {
    const activeAlarms = AlarmlistService.filterActive(alarms);
    const filteredAlarms = activeAlarms.filter(
      (a) => a.level === AlarmLevel.FAULT || a.level === AlarmLevel.ALARM || a.level === AlarmLevel.WARNING || a.level === AlarmLevel.SYSTEM
    );

    this.activeAlarms = AlarmlistService.sortByTypeAndTimestamp(filteredAlarms);

    this.activeAlarmCount = this.activeAlarms.length;
    this.hasAlarms = this.activeAlarmCount > 0;
    this.class = this.activeAlarmCount > 0 ? this.getColorClass() : SidepaneAlarmlistComponent.ALARM_TITLE_EMPTY;
    this.gridHeight = this.getGridHeight(this.activeAlarmCount);
  }

  itemId(_: number, alarm: Alarm): string {
    return alarm.code;
  }
}
