import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { MediaCenterRemoteAccessStatusAdapter } from 'src/app/media-center/remote-access/remote-access-status.adapter';
import { MediaCenterRemoteAccessStatus } from 'src/app/media-center/remote-access/remote-access-status.model';

@Injectable({
  providedIn: 'root',
})
export class MediaCenterRemoteAccessStatusService extends LivedataServiceBase<MediaCenterRemoteAccessStatus> {
  protected readonly endpointUri = 'mediacenter/remoteaccess/status';
  protected readonly requiredFeatures = [new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.READ)];

  readonly remoteAccessStatuses$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, MediaCenterRemoteAccessStatusAdapter.adapt, destinationService);
  }
}
