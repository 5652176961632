import { SisConverter } from 'src/app/core/utils/sis-converter';
import { MeteoLivedata } from 'src/app/maps/livedata/meteo-livedata.model';
import { SisOutdatedHelper } from 'src/app/maps/livedata/sis-outdated-helper';

export class MeteoLivedataAdapter {
  static adapt(item: any): MeteoLivedata[] {
    const livedata: MeteoLivedata[] = [];

    if (item) {
      item.forEach((e: any) => {
        livedata.push({
          meteoDeviceSisId: e.MeteoDeviceSisId,
          deviceTimestamp: e.DeviceTimestamp,
          gatewayTimestamp: e.GatewayTimestamp,
          windDirectionDegreeAxis: SisConverter.toNumberOrUndefined(e.WindDirectionDegreeAxis),
          windDirectionDegreeNorth: SisConverter.toNumberOrUndefined(e.WindDirectionDegreeNorth),
          windSpeedKmh: SisConverter.toNumberOrUndefined(e.WindSpeedKmh),
          temperatureCelsius: SisConverter.toNumberOrUndefined(e.TemperatureCelsius),
          wetBulbTemperatureCelsius: SisConverter.toNumberOrUndefined(e.WetBulbTemperatureCelsius),
          outdated: SisOutdatedHelper.isOutdated(e.DeviceTimestamp),
        });
      });
    }

    return livedata;
  }
}
