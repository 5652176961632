import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Ropeway } from 'src/app/maps/domain/ropeway.model';
import { EnergyLiveData } from 'src/app/maps/livedata/energy-livedata.model';
import { EnergyLiveDataService } from 'src/app/maps/livedata/energy-livedata.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-energy-widget',
  templateUrl: './energy-widget.component.html',
  styleUrls: ['./energy-widget.component.scss'],
})
export class EnergyWidgetComponent extends WidgetBase implements OnInit {
  displayedValue: string = WidgetBase.valueUnknown;

  constructor(
    private energyLiveDataService: EnergyLiveDataService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.selectedMapElementService.selectedRopeway$, this.energyLiveDataService.energy$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([ropeway, energyLiveDatas]) => {
        this.update(ropeway, energyLiveDatas);
      });
  }

  private update(ropeway: Ropeway, liveDatas: EnergyLiveData[]): void {
    if (ropeway) {
      liveDatas = liveDatas?.filter((d) => d.parentSisId === ropeway.sisId);
    }

    if (!liveDatas?.length || liveDatas.every((d) => d.outdated || d.powerW == null)) {
      this.resetToDefault();
      return;
    }

    const total = liveDatas
      .filter((d) => !d.outdated && d.powerW != null)
      .reduce((sum: number, current: EnergyLiveData) => sum + current.powerW / 1000, 0);

    this.displayedValue = Math.round(total).toString();
  }

  private resetToDefault(): void {
    this.displayedValue = WidgetBase.valueUnknown;
  }
}
