import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { MainMenuEntry } from 'src/app/main-menu/main-menu-entry.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Component({
  selector: 'sis-main-menu-list',
  templateUrl: './main-menu-list.component.html',
  styleUrls: ['./main-menu-list.component.scss'],
})
export class MainMenuListComponent {
  @Input() menuCtrl: MenuController;
  @Input() appPages: MainMenuEntry[] = [];
  @Output() menuItemClicked = new EventEmitter();

  constructor(private router: Router, private userMessageService: UserMessageService) {}

  menuItemClick(mainMenuEntry: MainMenuEntry) {
    this.toggleSubmenu(mainMenuEntry);

    this.menuItemClicked.emit();

    if (mainMenuEntry.url) {
      this.menuCtrl.close('mainMenu');
      this.navigatePage(mainMenuEntry.url);
    }
  }

  private toggleSubmenu(mainMenuEntry: MainMenuEntry) {
    if (mainMenuEntry.submenu?.length > 0) {
      mainMenuEntry.open = !mainMenuEntry.open;
    }

    this.appPages.forEach((menuEntry) => {
      if (mainMenuEntry !== menuEntry && !menuEntry.submenu?.includes(mainMenuEntry)) {
        menuEntry.open = false;
      }
    });
  }

  private navigatePage(url: string) {
    this.router.navigate([url]);
  }

  private presentToast() {
    this.userMessageService.presentToast(
      new UserMessage({
        message: 'user.message.mobilePageNotAvailable',
        icon: UserMessageIcon.info,
        durationMs: 2000,
        position: 'bottom',
        color: UserMessageColor.blue,
      })
    );
  }
}
