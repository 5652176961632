import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { SlopesOperatingTimesPage } from 'src/app/slopes-operating-times/slopes-operating-times.page';
import { SlopesOperatingTimesItemComponent } from 'src/app/slopes-operating-times/slopes-operating-times-item/slopes-operating-times-item.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatTooltipModule,
    RouterModule.forChild([
      {
        path: '',
        component: SlopesOperatingTimesPage,
      },
    ]),
    TranslateModule,
  ],
  declarations: [SlopesOperatingTimesPage, SlopesOperatingTimesItemComponent],
})
export class SlopesOperatingTimesPageModule {}
