<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 187.5 225">
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widgets">
      <g>
        <g>
          <g>
            <path
              d="M112.42,112.84l1.85,22-1.85,22a5.24,5.24,0,0,1-5.23,5.23H80.31a5.24,5.24,0,0,1-5.23-5.23l-1.85-22,1.85-22a5.24,5.24,0,0,1,5.23-5.22h26.88A5.24,5.24,0,0,1,112.42,112.84Z"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <line
              x1="93.75"
              y1="107.62"
              x2="93.75"
              y2="87.1"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <polygon
              points="107.47 134.82 105.78 114.69 81.72 114.69 80.03 134.82 107.47 134.82"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
            <line
              x1="93.75"
              y1="134.82"
              x2="93.75"
              y2="154.93"
              style="fill: none; stroke: #979797; stroke-linecap: round; stroke-linejoin: round; stroke-width: 4px"
            />
          </g>
          <path
            d="M47.17,102.07l-3.06.85a4.31,4.31,0,1,1-1.06-3.85l1.13-.32ZM147.6,67.86a4.3,4.3,0,0,0-4.21,3.4l-3.07.85,3,3.32,1.14-.31a4.31,4.31,0,1,0,3.14-7.26ZM57.67,95l-11.56,3.2,3,3.32,11.57-3.2Zm1.93-.54,3,3.32,11.57-3.2-3-3.32ZM84.43,87.6,72.86,90.8l3,3.33,11.56-3.21Zm1.93-.53,3,3.32,11.57-3.21-3-3.32Zm25-6.95L99.85,83.33l3,3.32,11.57-3.21Zm1.93-.53,3,3.32,11.56-3.2-3-3.33Zm25.06-6.94-11.57,3.2,3,3.32L141.39,76Z"
            style="fill: #979797"
          />
        </g>
        <rect width="187.5" height="225" style="fill: none" />
        <g>
          <path
            d="M102.39,29.92l12.37,12.37v17.5L102.39,72.17H84.89L72.51,59.79V42.29L84.89,29.92h17.5m2.9-7H82L65.51,39.39v23.3L82,79.17h23.3l16.47-16.48V39.39L105.29,22.92Z"
            [ngStyle]="{ fill: this.statusColor }"
          />
          <g>
            <path
              d="M75.05,55.91a5.88,5.88,0,0,0,2.55.6c1.41,0,2.12-.73,2.12-1.75s-.69-1.68-2-2.53C75.6,51,74.8,49.31,74.8,47.91c0-2.48,1.65-4.55,4.87-4.55a5.1,5.1,0,0,1,2.44.55l-.48,2.6a3.66,3.66,0,0,0-2-.53c-1.29,0-1.91.78-1.91,1.61S78.22,49,79.88,50a5,5,0,0,1,2.8,4.44c0,2.83-2.09,4.69-5.1,4.69a6.91,6.91,0,0,1-3-.62Z"
              [ngStyle]="{ fill: this.statusColor }"
            />
            <path d="M86.14,46.28H83.4V43.5h8.51v2.78H89.15V59h-3Z" [ngStyle]="{ fill: this.statusColor }" />
            <path
              d="M102.35,51c0,5.84-2.07,8.21-5,8.21-3.52,0-4.9-3.73-4.9-8s1.68-7.89,5.13-7.89C101.29,43.31,102.35,47.43,102.35,51Zm-6.78.28c0,3.51.66,5.31,1.88,5.31s1.77-2.3,1.77-5.45c0-2.72-.41-5.18-1.79-5.18C96.23,45.93,95.57,47.91,95.57,51.25Z"
              [ngStyle]="{ fill: this.statusColor }"
            />
            <path
              d="M104.26,43.73a15.31,15.31,0,0,1,3.15-.3c1.79,0,3.22.25,4.21,1.19A4.49,4.49,0,0,1,113,48a5.15,5.15,0,0,1-1.31,3.72,5.31,5.31,0,0,1-3.77,1.5,4,4,0,0,1-.6,0V59h-3Zm3,7.06a4.1,4.1,0,0,0,.51,0c1.51,0,2.18-1.08,2.18-2.53s-.55-2.42-1.95-2.42a2.25,2.25,0,0,0-.74.12Z"
              [ngStyle]="{ fill: this.statusColor }"
            />
          </g>
        </g>
      </g>
    </g>
    <g>
      <text
        [attr.x]="textPositionX"
        [attr.y]="textPositionY"
        id="value"
        style="text-anchor: middle; fill: #1c232a; font-family: MyriadPro-Regular, Myriad Pro"
        [style.font-size]="fontSize"
      >
        {{ this.displayedTime }}
        <tspan id="unit" style="font-size: 24px">{{ this.displayedUnit }}</tspan>
      </text>
    </g>
  </g>
</svg>
