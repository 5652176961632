import { EditToken } from 'src/app/sisag-admin/domain/edit-token.model';
export class EditTokenAdapter {
  static adapt(items: any): EditToken[] {
    const editTokens: EditToken[] = [];

    if (items) {
      items.forEach((item: any) => {
        editTokens.push({
          guid: item.guid,
          tenantSisId: item.tenantSisId,
          name: item.name,
          areaUrlName: item.areaUrlName,
          season: item.season,
          editToken: item.editToken,
        });
      });
    }

    return editTokens;
  }
}
