import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, timer } from 'rxjs';
import { catchError, map, repeat, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebcamService {
  private static readonly requiredFeature = new Feature(FeatureId.COCKPIT_WEBCAM, FeatureAccessLevel.READ);

  private expiryDuration$ = new ReplaySubject<number>(1);

  private repeatTimer$ = this.expiryDuration$.pipe(
    switchMap((d) => timer(d)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly sasToken$: Observable<string> = this.destinationService.selectedTenantFeatures$.pipe(
    switchMap((features) => {
      if (features.some((f) => f.hasMinimumRequirementFor(WebcamService.requiredFeature))) {
        return this.requestSasToken().pipe(
          map((sasToken) => {
            let expiryDuration: number;
            if (!sasToken) {
              expiryDuration = 0;
            } else {
              const expiryParam = new URLSearchParams(sasToken).get('se');
              const expiryDate = new Date(expiryParam).valueOf();
              expiryDuration = expiryDate - Date.now() - 10000;
            }

            return { sasToken, expiryDuration };
          }),
          catchError(() => of({ sasToken: null, expiryDuration: 3000 })),
          tap(({ expiryDuration }) => this.expiryDuration$.next(expiryDuration)),
          repeat({ delay: () => this.repeatTimer$ }),
          map(({ sasToken }) => sasToken)
        );
      } else {
        return of(null);
      }
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(private http: HttpClient, private destinationService: DestinationService) {}

  requestSasToken(): Observable<string> {
    const url = `${environment.baseUrlApi}/webcam/blobstorage`;
    return this.http.get(url).pipe(
      catchError(() => of(null)),
      map((sas) => (sas ? sas.toString() : null)),
      take(1)
    );
  }
}
