<ion-header>
  <ion-toolbar>
    <ion-button fill="clear" slot="end" (click)="this.done()">
      <ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>
<ion-content scroll-y="false">
  <iframe class="sis-iframe" [src]="this.safeInfoLink" frameborder="0"></iframe>
</ion-content>
