import { SisConverter } from 'src/app/core/utils/sis-converter';
import { RopewayStatus } from 'src/app/maps/livedata/ropeway-status.model';
import { SisOutdatedHelper } from 'src/app/maps/livedata/sis-outdated-helper';

export class RopewayStatusAdapter {
  static adapt(item: any): RopewayStatus[] {
    const livedata: RopewayStatus[] = [];

    if (item) {
      item.forEach((e: any) => {
        livedata.push(
          new RopewayStatus({
            deviceSisId: e.DeviceSisId,
            deviceTimestamp: e.DeviceTimestamp,
            gatewayTimestamp: e.GatewayTimestamp,
            status: RopewayStatus.getStatus(e.Status),
            active: SisConverter.toBoolean(e.Active),
            outdated: SisOutdatedHelper.isOutdated(e.Timestamp, 75000),
          })
        );
      });
    }

    return livedata;
  }
}
