import { ScreenGroup } from 'src/app/media-center/screens/domain/screen-group.model';

export class ScreenGroupAdapter {
  static adapt(items: any): ScreenGroup[] {
    const result: ScreenGroup[] =
      (items as any[])?.map<ScreenGroup>((e: any) => ({
        guid: e.guid,
        name: e.name,
        order: e.order,
        screens: [],
      })) || [];

    return result;
  }
}
