import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { BreakdownInfoLibraryItemComponent } from 'src/app/breakdowninfo/breakdowninfo-library/breakdowninfo-library-item/breakdowninfo-library-item.component';
import { BreakdownInfoLibraryService } from 'src/app/breakdowninfo/domain/breakdowninfo-library.service';
import { BreakdownInfoLibraryEntry } from 'src/app/breakdowninfo/domain/breakdowninfo-library-entry.model';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';

@Component({
  selector: 'sis-breakdowninfo-library',
  templateUrl: './breakdowninfo-library.component.html',
  styleUrls: ['./breakdowninfo-library.component.scss'],
})
export class BreakdownInfoLibraryComponent extends Unsubscriber implements OnInit {
  library: BreakdownInfoLibraryEntry[];
  bigScreenMode: boolean;

  constructor(
    private breakdownInfoLibraryService: BreakdownInfoLibraryService,
    private modalCtrl: ModalController,
    private screenSizeService: ScreenSizeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.breakdownInfoLibraryService.library$.pipe(takeUntil(this.onDestroy$)).subscribe((library) => {
      this.library = library.sort((a, b) => (a.color < b.color ? -1 : 1));
    });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  async showLibraryItem(selectedBreakdownInfoLibraryEntry: BreakdownInfoLibraryEntry): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: BreakdownInfoLibraryItemComponent,
      componentProps: {
        breakdownInfoLibraryEntry: selectedBreakdownInfoLibraryEntry,
      },
      cssClass: 'sis-breakdown-library-item-modal',
    });

    return modal.present();
  }
}
