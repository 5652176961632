<div class="sis-detailview-container">
  <ion-row *ngIf="!(this.selectedMapElementService.selectedRopeway$ | async)" class="filter-container">
    <ion-col class="filter-label" size="2">
      <p>{{ 'sambesi.term.filter' | translate }}</p>
    </ion-col>
    <ion-col>
      <ion-select
        #select
        interface="popover"
        [interfaceOptions]="{ cssClass: 'sis-global-popover' , size: 'cover' , arrow:false }"
        class="sis-global-input"
        placeholder="SisId"
        (ionChange)="setRopewayFilter($event)"
        [value]="ropewayFilter$ | async"
      >
        <ion-select-option *ngFor="let ropeway of ropeways$ | async" [value]="ropeway.sisId">
          {{ ropeway.sisId }}
        </ion-select-option>
      </ion-select>
    </ion-col>
    <ion-col size="1" *ngIf="select?.value !== undefined">
      <ion-button
        (click)="clearRopewayFilter()"
        size="small"
        fill="clear"
        color="medium"
        class="clear-button ion-no-padding"
      >
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>

  <cdk-virtual-scroll-viewport
    itemSize="58"
    class="scroll-container sis-global-no-scrollbar"
    minBufferPx="320"
    maxBufferPx="640"
  >
    <sis-sambesi-whatsup-message
      *cdkVirtualFor="let message of messages$ | async"
      [sambesiWhatsUpMessage]="message"
    ></sis-sambesi-whatsup-message>
  </cdk-virtual-scroll-viewport>

  <ion-grid class="sambesi-footer">
    <ion-row>
      <ion-col size="2">
        <ion-button size="small" (click)="updateSambesiMessages()" [disabled]="isLoadingMessages">
          <mat-icon
            *ngIf="!isLoadingMessages"
            slot="icon-only"
            aria-hidden="false"
            aria-label="Refresh"
            fontIcon="sync"
          ></mat-icon>
          <ion-spinner *ngIf="isLoadingMessages" class="loading-spinner" name="dots"></ion-spinner>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-grid *ngIf="timestamp$ | async as timestamp" class="ion-no-padding">
          <ion-row>
            <ion-col>
              <span class="sis-timestamp">{{ 'general.phrase.lastUpdate' | translate }}</span>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="sis-timestamp">{{ timestamp | date : 'dd.MM.yyyy HH:mm' }}</ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
      <ion-col size="auto">
        <a *ngIf="sambesiWhatsUpService.citrixLink$ | async as citrixLink" [href]="citrixLink" target="_blank">
          Sambesi Citrix <ion-icon class="sis-icon-open" name="open-outline"></ion-icon>
        </a>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
