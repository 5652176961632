import { Component, Input, OnChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { OperatingInfoEntry } from 'src/app/operating-info/domain/operating-info-entry.model';
import { OperatingInfoService } from 'src/app/operating-info/operating-info.service';
import { OperatingInfoEntryEditComponent } from 'src/app/operating-info/sis-operating-info-entry-edit/sis-operating-info-entry-edit.component';

@Component({
  selector: 'sis-operating-info-item',
  templateUrl: './operating-info-item.component.html',
  styleUrls: ['./operating-info-item.component.scss'],
})
export class OperatingInfoItemComponent extends Unsubscriber implements OnChanges {
  @Input() entry: OperatingInfoEntry;
  @Input() editable: boolean;

  color: string;
  description: string;
  text: string;

  constructor(
    private translateService: TranslateService,
    private operatingInfoService: OperatingInfoService,
    private modalCtrl: ModalController,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super();
    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.update());
    this.operatingInfoService.library$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.update());
  }

  ngOnChanges(): void {
    this.update();
  }

  private update() {
    if (!this.entry) {
      return;
    }

    const langKey = this.translateService.currentLang;
    const text = this.entry.text.find((text) => text.language === langKey) ?? this.entry.text[0];

    this.color = this.entry.color;
    this.description = text.description;
    this.text = text.text;
  }

  async showLibraryItem(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: OperatingInfoEntryEditComponent,
      cssClass: 'sis-operatinginfo-edit-modal',
      componentProps: {
        operatingInfoLibraryEntry: this.entry,
      },
      backdropDismiss: false,
    });

    return modal.present();
  }

  async delete(): Promise<void> {
    const confirmed = await this.confirmationDialogService.presentAlert(
      'mediacenter.phrase.deleteFile',
      'general.term.delete'
    );

    if (confirmed) {
      await this.operatingInfoService.updateLibrary(this.entry, OperatingInfoService.deleteOperation);
    }
  }
}
