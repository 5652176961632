import { Component, Input } from '@angular/core';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { SambesiWhatsUpMessage } from 'src/app/maps/livedata/sambesi-whatsup-message.model';

interface DisabledToolTips {
  messageTitle: boolean;
  nodeName: boolean;
  jobName: boolean;
  assignedPerson: boolean;
}

@Component({
  selector: 'sis-sambesi-whatsup-message',
  templateUrl: './sambesi-whatsup-message.component.html',
  styleUrls: ['./sambesi-whatsup-message.component.scss'],
})
export class SambesiWhatsUpMessageComponent extends Unsubscriber {
  @Input() sambesiWhatsUpMessage: SambesiWhatsUpMessage;
  disabledTooltips: DisabledToolTips = {
    messageTitle: false,
    nodeName: false,
    jobName: false,
    assignedPerson: false,
  };

  constructor() {
    super();
  }

  updateTooltipDisabled(element: HTMLElement, name: keyof DisabledToolTips) {
    this.disabledTooltips[name] = element.scrollWidth <= element.clientWidth;
  }
}
