import { Alarm } from 'src/app/domain/alarm.model';

export class DestinationAlarmAdapter {
  static adapt(item: any): Alarm[] {
    const alarms: Alarm[] = [];

    if (item) {
      item.forEach((e: any) => {
        alarms.push(
          new Alarm({
            sisId: e.TenantSisId ?? e.PartitionKey,
            tenantSisId: e.TenantSisId ?? e.PartitionKey,
            timestamp: new Date(e.GatewayTimestamp),
            active: e.Active,
            code: e.Code,
            level: Alarm.getLevel(e.Level),
            text: e.Text,
          })
        );
      });
    }

    return alarms;
  }
}
