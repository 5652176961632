import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { TruScan } from 'src/app/maps/domain/truscan.model';
import { TruScanData } from 'src/app/maps/livedata/truscan-data.model';
import { TruScanDataService } from 'src/app/maps/livedata/truscan-data.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';

@Component({
  selector: 'sis-truscan-detailview',
  templateUrl: './truscan-detailview.component.html',
  styleUrls: ['./truscan-detailview.component.scss'],
})
export class TruScanDetailViewComponent extends Unsubscriber implements OnInit {
  truScanInstallations: TruScan[];
  truScanDatas: TruScanData[];
  timestamp: Date;

  constructor(
    private truScanDataService: TruScanDataService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.selectedMapElementService.selectedRopeway$, this.truScanDataService.truScanDatas$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([ropeway, truScanData]) => {
        if (ropeway) {
          const truScans = ropeway.truScanInstallations;
          this.truScanDatas = truScanData
            .filter((t) => t.installationId == truScans[0].installationId)
            .sort((a, b) => (a.ropeTimestamp > b.ropeTimestamp ? -1 : 1));
          this.timestamp = this.truScanDatas.reduce((a, b) => (b.timestamp > a.timestamp ? b : a)).timestamp;
        }
      });
  }

  itemId(_: number, data: TruScanData): number {
    return data.ropeId;
  }
}
