<div
  class="sis-div {{ this.buttonAlign }}"
  [matTooltip]="this.sisTooltip | translate"
  matTooltipClass="sis-global-tooltip"
>
  <ion-button
    class="{{ this.buttonType }}"
    (click)="this.onClick()"
    [disabled]="disabled"
    [ngStyle]="{ width: this.width }"
  >
    <ion-label *ngIf="!this.showSpinner">{{ text | translate }}</ion-label>
    <ion-icon *ngIf="this.icon && !this.showSpinner" [name]="this.icon" slot="end"></ion-icon>
    <ion-label *ngIf="this.showSpinner">
      <ion-spinner name="dots"></ion-spinner>
    </ion-label>
  </ion-button>
</div>
