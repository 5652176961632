import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'sis-user-management-filter',
  templateUrl: './user-management-filter.component.html',
})
export class UserManagementFilterComponent {
  @Input() isCockpitChecked: boolean;
  @Input() isSisMediaChecked: boolean;
  @Input() isGeneralChecked: boolean;

  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }

  apply() {
    this.modalController.dismiss({
      isCockpitChecked: this.isCockpitChecked,
      isSisMediaChecked: this.isSisMediaChecked,
      isGeneralChecked: this.isGeneralChecked,
    });
  }
}
