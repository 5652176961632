import { SisConverter } from 'src/app/core/utils/sis-converter';
import { Alarm } from 'src/app/domain/alarm.model';

export class UserAlarmAdapter {
  static adapt(item: any): Alarm[] {
    const alarms: Alarm[] = [];

    item.forEach((e: any) => {
      const timestamp = e.DeviceTimestamp != null ? new Date(e.DeviceTimestamp) : new Date(e.GatewayTimestamp);
      alarms.push(
        new Alarm({
          sisId: e.SisId,
          tenantSisId: e.TenantSisId ?? e.PartitionKey,
          timestamp,
          active: SisConverter.toBoolean(e.Active),
          code: e.Code,
          info: e.Info,
          level: Alarm.getLevel(e.Level),
          text: e.Text,
        })
      );
    });

    return alarms;
  }
}
