import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { take, takeUntil } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { EventBusSubscriberBase } from 'src/app/core/eventbus/event-bus-subscriber-base';
import { WidgetSelectedEvent } from 'src/app/core/eventbus/events';
import { NavigatorService } from 'src/app/core/navigator/navigator.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { ModalPopupComponent } from 'src/app/maps/modal-popup/modal-popup.component';
import { WidgetDisplay } from 'src/app/maps/widget-list/widget-display.model';
import { Widget } from 'src/app/maps/widget-list/widget-list-services/domain/widget.model';
import { WidgetAction } from 'src/app/maps/widget-list/widget-list-services/domain/widget-action.enum';
import { WidgetList } from 'src/app/maps/widget-list/widget-list-services/domain/widget-list.model';
import { WidgetName } from 'src/app/maps/widget-list/widget-list-services/domain/widgetname.enum';
import { WidgetListService } from 'src/app/maps/widget-list/widget-list-services/widget-list.service';

@Component({
  selector: 'sis-widget-list',
  templateUrl: './widget-list.component.html',
  styleUrls: ['./widget-list.component.scss'],
})
export class WidgetListComponent extends EventBusSubscriberBase implements OnInit {
  readonly GRID_L = 'sis-grid-left';
  readonly GRID_M = 'sis-grid-mid';
  readonly GRID_R = 'sis-grid-right';
  readonly NO_BOTTOM_BORDER = 'sis-grid-selected-bottom';
  readonly MUTED_LEFT_BORDER = 'sis-grid-notselected';
  readonly MUTED_BORDER = 'sis-grid-not-expanded-rows';
  readonly MUTED_SVG = 'sis-svg-not-expanded';

  private borders: string[] = [];
  private template: WidgetList;
  private widgetDisplayMap: Map<WidgetName, WidgetDisplay>;

  widgets: Widget[][];
  isIphone: boolean;
  bigScreenMode: boolean;

  @ViewChild('ionContent', { static: false }) ionContent: IonContent;
  @ViewChildren('widgetCol') widgetCols: QueryList<ElementRef>;

  constructor(
    private mapElementSelectedService: WidgetListService,
    private modalController: ModalController,
    private screenSizeService: ScreenSizeService,
    private navigatorService: NavigatorService,
    private destinationService: DestinationService,
    eventBus: EventBusService
  ) {
    super(eventBus);

    this.isIphone = this.navigatorService.isIphone();
  }

  ngOnInit() {
    this.mapElementSelectedService.widgetList$.pipe(takeUntil(this.onDestroy$)).subscribe((widgetList) => {
      this.widgetDisplayMap = this.setupWidgetMap(widgetList);
    });

    this.destinationService.selectedTenant$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.closeAllCharts());

    this.observe<WidgetSelectedEvent>(WidgetSelectedEvent, (event) => {
      if (!event.expandable) {
        this.closeAllCharts();
      }
    });

    this.screenSizeService.bigScreenMode$.pipe(takeUntil(this.onDestroy$)).subscribe((bigScreenMode) => {
      this.bigScreenMode = bigScreenMode;
      this.doScrollMagic(this.bigScreenMode);
    });

    this.onDestroy$.pipe(take(1)).subscribe(() => {
      this.eventBus.publish(new WidgetSelectedEvent());
    });
  }

  onClick(widget: Widget): void {
    if (!widget) {
      return;
    }

    this.closeAllChartsBut(widget.name);
    widget.toggleExpanded();

    if (widget.expanded) {
      this.scrollToDetailView();
    }
  }

  hasLink(widget: Widget): boolean {
    return widget && widget.action === WidgetAction.ExternalLink;
  }

  widgetCursor(widget: Widget): string {
    return this.hasLink(widget) || widget.expandable ? 'pointer' : 'default';
  }

  detailCursor(widget: Widget): string {
    return this.bigScreenMode && widget && widget.detailView && widget.detailView.modal ? 'pointer' : 'default';
  }

  getWidgetClass(widget: Widget): string {
    if (!widget) {
      return;
    }

    const widgetDisplay = this.widgetDisplayMap.get(widget.name);
    let classes: string = widget.css;

    // No widget is expanded
    const expandedWidget = this.getExpandedWidget();
    if (!expandedWidget) {
      return classes;
    }

    const expandedRow = expandedWidget.row;
    const expandedCol = expandedWidget.col;

    // If this is the expanded widget, remove its bottom border
    if (widget.expanded) {
      return classes.concat(` ${this.NO_BOTTOM_BORDER}`);
    }

    classes = classes.concat(` ${this.MUTED_SVG}`);

    // If this widget is one position to the right of the expanded widget, leave it default
    if (widgetDisplay.row === expandedRow && widgetDisplay.col === expandedCol + 1) {
      return classes;
    }

    // If this widget is on the same row as the expanded widget, mute its left border
    if (widgetDisplay.row === expandedRow) {
      return classes.concat(` ${this.MUTED_LEFT_BORDER}`);
    }

    // If this widget is directly above the expanded widget, only mute its left border
    if (widgetDisplay.row === expandedRow - 1 && widgetDisplay.col === expandedCol) {
      return classes.concat(` ${this.MUTED_LEFT_BORDER}`);
    }

    // Mute the left and bottom borders of any other widget
    return classes.concat(` ${this.MUTED_BORDER}`);
  }

  async presentModal(template: Widget): Promise<void> {
    const detailView = template.detailView;
    if (!(detailView && detailView.modal && detailView.component) || !this.bigScreenMode) {
      return;
    }
    const modal = await this.modalController.create({
      component: ModalPopupComponent,
      componentProps: { component: detailView.component },
    });
    return modal.present();
  }

  private setupWidgetMap(template: WidgetList): Map<WidgetName, WidgetDisplay> {
    const widgetMap = new Map<WidgetName, WidgetDisplay>();

    if (!template || template.columns === 0) {
      this.widgets = this.setUpWidgetArray(widgetMap);
      return widgetMap;
    }

    this.template = template;
    const widgets = template.widgets;
    const columns = template.columns;

    this.borders[0] = this.GRID_L;
    for (let k = 1; k < columns; k++) {
      this.borders[k] = k === columns - 1 ? this.GRID_R : this.GRID_M;
    }

    let i: number;
    for (i = 0; i < widgets.length; i++) {
      this.setUpWidget(i, columns, widgetMap, widgets[i]);
    }
    this.setUpPlaceholderWidgets(i, columns, widgetMap);

    this.widgets = this.setUpWidgetArray(widgetMap);

    return widgetMap;
  }

  private setUpWidget(i: number, columns: number, widgetMap: Map<WidgetName, WidgetDisplay>, widget: Widget): void {
    const row = Math.floor(i / columns);
    const col = i % columns;

    if (!widget) {
      return;
    }

    widget.css = this.borders[col];

    widgetMap.set(widget.name, { widget, row, col });
  }

  private setUpPlaceholderWidgets(i: number, columns: number, widgetMap: Map<WidgetName, WidgetDisplay>): void {
    const remaining = columns - (i % columns);
    if (remaining !== columns) {
      for (let e = 0; e < remaining; e++, i++) {
        const row = Math.floor(i / columns);
        const col = i % columns;
        const emptyWidget = this.getPlaceholderWidget(this.borders[col]);

        widgetMap.set(emptyWidget.name, { widget: emptyWidget, row, col });
      }
    }
  }

  private getPlaceholderWidget(css: string): Widget {
    return new Widget({ css });
  }

  private setUpWidgetArray(widgetMap: Map<WidgetName, WidgetDisplay>): Widget[][] {
    const result: Widget[][] = [];
    widgetMap.forEach((value) => {
      if (!result[value.row]) {
        result[value.row] = [];
      }
      result[value.row][value.col] = value.widget;
    });
    return result;
  }

  private closeAllCharts(): void {
    this.closeAllChartsBut(null);
  }

  private closeAllChartsBut(widgetName: WidgetName): void {
    if (!this.template) {
      return;
    }
    this.template.widgets.forEach((widget: Widget) => {
      if (widget.name !== widgetName) {
        widget.close();
      }
    });
  }

  private getExpandedWidget(): WidgetDisplay {
    let expandedWidget: WidgetDisplay;
    this.widgetDisplayMap.forEach((v) => {
      if (v.widget.expanded) {
        expandedWidget = v;
        return;
      }
    });
    return expandedWidget;
  }

  private doScrollMagic(bigScreenMode: boolean) {
    if (this.isIphone) {
      return;
    }

    const checkExist = setInterval(() => {
      const contents = document.querySelectorAll('#ion-content-widget-list');

      if (contents) {
        contents.forEach((content) => {
          const inner = content.shadowRoot.querySelector('main');

          if (inner) {
            clearInterval(checkExist);

            if (bigScreenMode && !this.navigatorService.isMac()) {
              inner.style.marginRight = '-16.5px';
              inner.style.overflowY = 'scroll';
              inner.style.overflowX = 'hidden';
            } else {
              inner.style.marginRight = '0px';
              inner.style.overflowY = 'var(--overflow)';
            }
          }
        });
      }
    }, 50);
  }

  private scrollToDetailView() {
    if (this.isIphone) {
      return;
    }

    let detailViewContainer: HTMLElement;
    const checkExist = setInterval(() => {
      detailViewContainer = document.getElementById('detailview-container');
      if (detailViewContainer && detailViewContainer.offsetParent && this.ionContent) {
        clearInterval(checkExist);
        const height = this.widgetCols.first.nativeElement.offsetHeight;
        const offset = (detailViewContainer.offsetParent as HTMLElement).offsetTop - height - 2;
        this.ionContent.scrollToPoint(0, offset, 500);
      }
    }, 50);
  }
}
