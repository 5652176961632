import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { FeatureManagementPage } from 'src/app/feature-management/feature-management.page';
import { FeatureManagementItemComponent } from 'src/app/feature-management/feature-management-item/feature-management-item.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatTooltipModule,
    RouterModule.forChild([
      {
        path: '',
        component: FeatureManagementPage,
      },
    ]),
    TranslateModule,
    ScrollingModule,
  ],
  declarations: [FeatureManagementPage, FeatureManagementItemComponent],
})
export class FeatureManagementPageModule {}
