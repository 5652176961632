import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { SisMediaOperatingHoursUpdatedEvent } from 'src/app/core/eventbus/events';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { SisMediaOperatingHours } from 'src/app/sismedia/sismedia-operating-hours-modal/domain/sismedia-operating-hours.model';
import { SisMediaOperatingHoursEntryAdapter } from 'src/app/sismedia/sismedia-operating-hours-modal/domain/sismedia-operating-hours-entry.adapter';
import { SisMediaOperatingHoursPostData } from 'src/app/sismedia/sismedia-operating-hours-modal/domain/sismedia-operating-hours-postdata.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SisMediaOperatingHoursService {
  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_OPERATINGHOURS, FeatureAccessLevel.READ);
  private readonly baseRequestUrl: string = '/sismedia/operatinghours';
  private readonly basePostUrl: string = '/sismedia/operatinghours';

  constructor(
    private destinationService: DestinationService,
    private http: HttpClient,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService,
    private userMessageService: UserMessageService
  ) {}

  getOperatingHours(assetGuid: string, assetCategory: number): Observable<SisMediaOperatingHours> {
    return this.destinationService.selectedTenantFeatures$.pipe(
      filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
      distinctUntilChanged(),
      switchMap(() => this.http.get(`${environment.baseUrlApi}${this.baseRequestUrl}/${assetGuid}/${assetCategory}`)),
      map((data) => SisMediaOperatingHoursEntryAdapter.adapt(data)),
      switchMap((data) =>
        this.eventBus.observe(SisMediaOperatingHoursUpdatedEvent).pipe(
          filter(
            (updatedEvent) =>
              updatedEvent?.assetGuid === assetGuid &&
              (JSON.stringify(data.entries) !== JSON.stringify(updatedEvent.operatingHours) ||
                data.enabled !== updatedEvent.operatingHoursEnabled)
          ),
          withLatestFrom(this.destinationService.selectedTenant$),
          filter(([updatedEvent, tenant]) => updatedEvent?.tenantGuid === tenant?.guid),
          withLatestFrom(this.userSettingsService.userSettings$),
          map(([[updatedEvent], userSettings]) => {
            if (updatedEvent.changedBy === userSettings.userGuid) {
              const translateKey = 'general.phrase.saved';
              const userMessage = new UserMessage({
                message: translateKey,
                icon: UserMessageIcon.success,
                durationMs: 2000,
                position: 'top',
                color: UserMessageColor.green,
              });
              this.userMessageService.presentToast(userMessage);
            }
            data = {
              enabled: updatedEvent.operatingHoursEnabled,
              entries: updatedEvent.operatingHours,
            };
            return data;
          }),
          startWith(data)
        )
      )
    );
  }

  postOperatingHours(operatingHoursPostData: SisMediaOperatingHoursPostData): Observable<boolean> {
    return this.http.post(`${environment.baseUrlApi}${this.basePostUrl}`, operatingHoursPostData).pipe(
      map(() => true),
      catchError(() => {
        const translateKey = 'general.phrase.saveFailed';
        const userMessage = new UserMessage({
          message: translateKey,
          icon: UserMessageIcon.failed,
          durationMs: 2000,
          position: 'top',
          color: UserMessageColor.red,
        });
        this.userMessageService.presentToast(userMessage);

        return of(false);
      })
    );
  }
}
