import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import moment from 'moment-timezone';
import { firstValueFrom, merge, NEVER, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { MediaCenterSlideUpdatedEvent, MediaCenterThumbnailUpdatedEvent } from 'src/app/core/eventbus/events';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { SisRoute } from 'src/app/domain/sis-route.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { SlideAdapter } from 'src/app/media-center/slides/domain/slide.adapter';
import { Slide } from 'src/app/media-center/slides/domain/slide.model';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';
import { environment } from 'src/environments/environment';
import { findIana } from 'windows-iana';

@Injectable({
  providedIn: 'root',
})
export class MediaCenterSlidesService {
  private static readonly requestSlidesUrl = '/mediacenter/slides';
  private static readonly settingsSlideUrl = '/mediacenter/slide/settings';
  private static readonly uploadSlideUrl = '/mediacenter/slide/upload';
  private static readonly deleteSlideUrl = '/mediacenter/slide/delete';

  private readonly requiredFeature = new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.READ);

  private readonly slidesRequest$: Observable<Slide[]> = this.destinationService.selectedTenantFeatures$.pipe(
    filter((features) => features.some((feature) => feature.hasMinimumRequirementFor(this.requiredFeature))),
    switchMap(() => this.http.get(`${environment.baseUrlApi}${MediaCenterSlidesService.requestSlidesUrl}`)),
    map((data) => SlideAdapter.adapt(data)),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  private readonly slideUpdate$: Observable<Slide[]> = this.eventBus.observe(MediaCenterSlideUpdatedEvent).pipe(
    withLatestFrom(this.destinationService.selectedTenant$),
    filter(([updatedEvent, tenant]) => updatedEvent?.tenantGuid === tenant?.guid),
    withLatestFrom(this.userSettingsService.userSettings$, this.slidesRequest$),
    map(([[updatedEvent], userSettings, slides]) => {
      if (updatedEvent.changedBy === userSettings.userGuid) {
        this.presentToast(updatedEvent.success, updatedEvent.removed, updatedEvent.settings);
      }

      const updatedSlide = slides.find((s) => s.guid === updatedEvent.slide.guid);

      if (updatedSlide) {
        updatedSlide.updating = false;

        if (updatedEvent.success) {
          if (updatedEvent.removed) {
            slides.splice(slides.indexOf(updatedSlide), 1);
          } else {
            if (updatedSlide.thumbnailUrl != null) {
              updatedEvent.slide.thumbnailUrl = updatedSlide.thumbnailUrl;
            }
            Object.assign(updatedSlide, updatedEvent.slide);
          }
        }
      } else {
        if (!updatedEvent.removed && updatedEvent.success) {
          slides.push(updatedEvent.slide);
        }
      }

      return slides;
    })
  );

  private readonly thumbnailUpdate$: Observable<Slide[]> = this.eventBus.observe(MediaCenterThumbnailUpdatedEvent).pipe(
    withLatestFrom(this.slidesRequest$),
    map(([updatedEvent, slides]) => {
      const updatedSlide = slides.find((slide) => slide.url === updatedEvent.url);
      if (updatedSlide) {
        updatedSlide.thumbnailUrl = this.addRandomString(updatedEvent.thumbnailUrl);
      }

      return slides;
    })
  );

  private readonly slidesMerged$ = merge(this.slidesRequest$, this.slideUpdate$).pipe(
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  private readonly slidesTimeControlEnabledUpdate$: Observable<never> = timer(1000, 5000).pipe(
    withLatestFrom(this.slidesMerged$, this.destinationService.selectedTenant$),
    switchMap(([, slides, tenant]) => {
      const timeZoneIANA = findIana(tenant.timeZoneId)[0];
      const now = moment().tz(timeZoneIANA);
      const currentDay = now.isoWeekday() - 1; // isoWeekDay: mon = 1, sun = 7
      const dateNow = now.format('YYYY-MM-DDTHH:mm:ss');
      const timeNow = now.format('HH:mm');

      slides.forEach((slide) => {
        if (slide.timeControlEnabled) {
          const slideActiveThisWeekday = slide[Slide.daysArray[currentDay]];
          const slideActiveToday = dateNow >= slide.startDate && dateNow < slide.endDate;
          const slideActiveTimeOfDay = this.isTimeActive(slide.startTime, slide.endTime, timeNow);

          slide.disabledByTimeControl = !slideActiveThisWeekday || !slideActiveToday || !slideActiveTimeOfDay;
          return;
        }

        slide.disabledByTimeControl = false;
      });

      return NEVER; // this observable does not emit a value, so that it doesn't cause an update for subscribers
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  readonly slides$ = merge(this.slidesMerged$, this.thumbnailUpdate$, this.slidesTimeControlEnabledUpdate$).pipe(
    shareReplay({
      refCount: true,
      bufferSize: 1,
    })
  );

  constructor(
    private eventBus: EventBusService,
    private http: HttpClient,
    private destinationService: DestinationService,
    private userMessageService: UserMessageService,
    private userSettingsService: UserSettingsService,
    private confirmationDialogService: ConfirmationDialogService,
    router: Router
  ) {
    // Workaround to always keep a subscription on slidesRequest$ while on a mediacenter page
    // Prevents unnecessary requests to the api
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url.includes(`/${SisRoute.MEDIACENTER}`)),
        distinctUntilChanged(),
        switchMap((isMediaCenterPage) => {
          if (isMediaCenterPage) {
            return this.slidesRequest$;
          } else {
            return of([]);
          }
        })
      )
      .subscribe();
  }

  updateSlide(slide: Slide): void {
    this.update(slide, false);
  }

  deleteSlide(slide: Slide): void {
    this.confirmationDialogService
      .presentAlert('mediacenter.phrase.deleteFile', 'mediacenter.term.delete')
      .then((confirmed) => {
        if (confirmed) {
          if (slide.inPlaylist) {
            this.confirmationDialogService
              .presentAlert('mediacenter.phrase.slideInUse', 'mediacenter.phrase.deleteAnyway')
              .then((confirmed) => {
                if (confirmed) {
                  this.update(slide, true);
                }
              });
          } else {
            this.update(slide, true);
          }
        }
      });
  }

  async uploadSlide(slideName: string, slideGuid: string, data: string, type: string): Promise<void> {
    try {
      const url = `${environment.baseUrlApi}${MediaCenterSlidesService.uploadSlideUrl}`;
      await firstValueFrom(this.http.post(url, { html: data, slideName, slideGuid, contentType: type }));
    } catch {
      const event = new MediaCenterSlideUpdatedEvent();
      event.slide = new Slide({ name: slideName });
      event.removed = false;
      event.success = false;
      this.eventBus.publish(event);
      this.presentToast(false);
    }
  }

  private async update(slide: Slide, deleting: boolean): Promise<void> {
    try {
      const url = deleting ? MediaCenterSlidesService.deleteSlideUrl : MediaCenterSlidesService.settingsSlideUrl;
      await firstValueFrom(this.http.post(`${environment.baseUrlApi}${url}`, slide));
    } catch {
      slide.updating = false;
      this.presentToast(false);
    }
  }

  private addRandomString(url: string): string {
    return `${url}?${Math.random() * 10000}`;
  }

  private presentToast(success: boolean, removed: boolean = false, settings: boolean = false): void {
    const message = success
      ? removed
        ? 'general.phrase.fileDeleted'
        : settings
        ? 'mediacenter.phrase.settingsSaved'
        : 'mediacenter.phrase.saved'
      : 'mediacenter.phrase.failed';
    const color = success ? UserMessageColor.green : UserMessageColor.red;
    const icon = success ? UserMessageIcon.success : UserMessageIcon.failed;
    this.userMessageService.presentToast(new UserMessage({ message, icon, durationMs: 2000, position: 'top', color }));
  }

  private isTimeActive(startTime: string, endTime: string, timeNow: string): boolean {
    if (startTime === endTime) {
      return true;
    }

    if (startTime < endTime) {
      return timeNow >= startTime && timeNow < endTime;
    }

    return timeNow >= startTime || timeNow < endTime;
  }
}
