<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="closeModal()">
      <ion-icon class="sis-close-button" name="close"></ion-icon>
    </ion-button>
    <ion-title>{{ 'general.phrase.selectLanguage' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list>
    <ion-item
      *ngFor="let l of languages"
      class="sis-language-item"
      (click)="swapLanguage(l.key)"
      [class.active]="l.key === this.selectedLanguage"
    >
      <ion-label>{{ l.name }}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
