<ion-grid *ngIf="this.interval" style="padding: 0px">
  <ion-row class="sis-timetable-header">
    <ion-col size="auto" style="min-width: 300px">{{ 'timetable.term.date' | translate }}</ion-col>
    <ion-col>{{ 'timetable.term.operatingStart' | translate }}</ion-col>
    <ion-col>{{ 'timetable.term.operatingEnd' | translate }}</ion-col>
    <ion-col *ngIf="this.bigScreenMode$ | async" size="auto" style="min-width: 100px">{{ 'general.term.edit' | translate }}</ion-col>
  </ion-row>

  <ion-row *ngFor="let day of this.intervalDays$ | async" class="sis-timetable-interval-day-row" data-test="timetable-interval-day">
    <ion-col size="auto" style="min-width: 100px" class="sis-timetable-padding-left">{{ day.date | weekday | translate }}</ion-col>
    <ion-col size="auto" style="min-width: 200px" class="sis-timetable-padding-left">{{ day.date | date : 'dd.MM.yy' }}</ion-col>
    <ion-col class="sis-timetable-padding-left" data-test="sis-timetable-operating-start">{{ day.operatingStart }}</ion-col>
    <ion-col class="sis-timetable-padding-left" data-test="sis-timetable-operating-end">{{ day.operatingEnd }}</ion-col>
    <ion-col *ngIf="this.bigScreenMode$ | async" size="auto" style="min-width: 100px" class="sis-timetable-padding-left">
      <a
        [ngClass]="(this.writePermission$ | async) ? 'sis-timetable-clickable' : 'sis-timetable-clickable-no-permission'"
        style="padding: 3px 6px"
        (click)="this.openIntervalDayModal(day)"
        data-test="timetable-edit-interval-day-button"
        matTooltip="{{ 'general.term.edit' | translate }}"
      >
        <ion-icon name="create-outline" class="sis-timetable-icon-small"> </ion-icon>
      </a>
    </ion-col>
  </ion-row>
</ion-grid>
