import { SisMediaAssetOverrideEntry } from 'src/app/sismedia/sismedia-asset-override/domain/sismedia-asset-override-entry.model';

export class SisMediaAssetOverrideEntryAdapter {
  static adapt(items: any): SisMediaAssetOverrideEntry[] {
    const assetOverrideDatas: SisMediaAssetOverrideEntry[] = [];

    if (items) {
      items.forEach((item) => {
        const assetOverrideData: SisMediaAssetOverrideEntry = {
          guid: item.guid,
          assetGuid: item.assetGuid,
          description: item.description,
          overrideStatus: item.overrideStatus,
          startTime: item.startTime,
          endTime: item.endTime,
          active: item.active,
          priority: item.priority,
        };

        assetOverrideDatas.push(assetOverrideData);
      });
    }

    return assetOverrideDatas;
  }
}
