import { STnetStatus as STnetStatus } from 'src/app/core/stnet/stnet-status.model';

export class STnetStatusAdapter {
  static adapt(item: any): STnetStatus | null {
    if (!item) {
      return null;
    }

    return {
      stationId: item.stationId,
      responseId: item.responseId?.toLowerCase(),
      statusCode: item.statusCode,
      statusMessage: item.statusMessage,
      featureId: item.featureId,
      snowReportUrl: item.snowReportUrl,
    };
  }
}
