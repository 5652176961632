import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LivedataServiceBase } from 'src/app/core/livedata/livedata-base.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { RopewayEcoModeLivedataAdapter } from 'src/app/maps/livedata/ropeway-ecomode-livedata.adapter';
import { RopewayEcoModeLivedata } from 'src/app/maps/livedata/ropeway-ecomode-livedata.model';

@Injectable({
  providedIn: 'root',
})
export class RopewayEcoModeLivedataService extends LivedataServiceBase<RopewayEcoModeLivedata> {
  protected readonly endpointUri = 'livedata/ropewayecomode';
  protected readonly requiredFeatures = [
    new Feature(FeatureId.COCKPIT, FeatureAccessLevel.READ),
    new Feature(FeatureId.COCKPIT_ECOMODE, FeatureAccessLevel.READ),
  ];

  readonly ropewayEcoModeLiveData$ = this.liveData$;

  constructor(http: HttpClient, destinationService: DestinationService) {
    super(http, RopewayEcoModeLivedataAdapter.adapt, destinationService);
  }
}
