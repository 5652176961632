import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { EventBusService } from 'src/app/core/eventbus/event-bus.service';
import { InfotextUpdatedEvent } from 'src/app/core/eventbus/events';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { Category } from 'src/app/infotext/domain/category.model';
import { Infotext } from 'src/app/infotext/domain/infotext.model';
import { InfotextPostData } from 'src/app/infotext/domain/infotext-postdata.model';
import { InfotextService } from 'src/app/infotext/infotext.service';

@Component({
  templateUrl: './texteditor-modal.html',
  styleUrls: ['./texteditor-modal.scss'],
})
export class TexteditorModal extends Unsubscriber implements OnInit {
  edited = false;
  saving = false;
  title: string;
  bigScreenMode: boolean;
  editableData: InfotextPostData;

  @Input() infotext: Infotext;

  constructor(
    private modalCtrl: ModalController,
    private infotextService: InfotextService,
    private translateService: TranslateService,
    private screenSizeService: ScreenSizeService,
    private eventBus: EventBusService,
    private userSettingsService: UserSettingsService
  ) {
    super();
  }

  ngOnInit() {
    this.init();

    this.translateService.onLangChange
      .pipe(takeUntil(this.onDestroy$), startWith([]))
      .subscribe(() => (this.title = this.infotextService.getValueByLang(this.infotext, 'title')));

    this.eventBus
      .observe(InfotextUpdatedEvent)
      .pipe(
        withLatestFrom(this.userSettingsService.userSettings$),
        filter(([event, userSettings]) => event.changedBy === userSettings.userGuid),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([event]) => {
        if (event.guid === this.infotext.guid && this.saving && event.updateSuccessful) {
          this.close();
        }
        this.saving = false;
      });

    this.infotextService.categories$
      .pipe(
        takeUntil(this.onDestroy$),
        map<Category[], Infotext[]>((categories) =>
          categories.reduce((p, c) => {
            p.push(...c.infotexts);
            return p;
          }, [])
        )
      )
      .subscribe((infotexts) => {
        const infotext = infotexts.find((i) => i.guid === this.infotext.guid);
        if (infotext && !this.edited) {
          this.infotext = infotext;
          this.edited = this.isEdited(infotext);
        }
      });

    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  async save(): Promise<void> {
    if (!this.saving) {
      if (!this.edited) {
        this.close();
      } else {
        this.saving = true;
        this.saving = await this.infotextService.updateInfotext(this.editableData);
      }
    }
  }

  clear(): void {
    this.editableData.textDe = '';
    this.editableData.textEn = '';
    this.editableData.textFr = '';
    this.editableData.textIt = '';

    this.edited = this.isEdited(this.infotext);
  }

  inputChanged(): void {
    this.edited = this.isEdited(this.infotext);
  }

  private init(): void {
    this.editableData = {
      guid: this.infotext.guid,
      categoryId: this.infotext.categoryId,
      textDe: this.infotext.textDe,
      textEn: this.infotext.textEn,
      textFr: this.infotext.textFr,
      textIt: this.infotext.textIt,
    };

    this.edited = false;
  }

  private isEdited(infotext: Infotext): boolean {
    return (
      this.editableData.guid !== infotext.guid ||
      this.editableData.textDe !== infotext.textDe ||
      this.editableData.textEn !== infotext.textEn ||
      this.editableData.textFr !== infotext.textFr ||
      this.editableData.textIt !== infotext.textIt
    );
  }
}
