import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { AbbSmartSensorConditionStatus, AbbSmartSensorData } from 'src/app/maps/livedata/abbsmartsensor-data.model';
import { AbbSmartSensorDataService } from 'src/app/maps/livedata/abbsmartsensor-data.service';
import { SelectedMapElementService } from 'src/app/maps/selected-map-element.service';

@Component({
  selector: 'sis-abbsmartsensor-widget',
  templateUrl: './abbsmartsensor-widget.component.html',
  styleUrls: ['./abbsmartsensor-widget.component.scss'],
})
export class AbbSmartSensorWidgetComponent extends Unsubscriber implements OnInit {
  static readonly cssClassGood = 'sis-abbsmartsensor-good';
  static readonly cssClassTolerable = 'sis-abbsmartsensor-tolerable';
  static readonly cssClassPoor = 'sis-abbsmartsensor-poor';
  static readonly cssClassUnknown = 'sis-abbsmartsensor-unknown';

  class: string = AbbSmartSensorWidgetComponent.cssClassGood;

  constructor(
    private abbSmartSensorDataService: AbbSmartSensorDataService,
    private selectedMapElementService: SelectedMapElementService
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.abbSmartSensorDataService.abbSmartSensorDatas$.pipe(filter((abbSmartSensorDatas) => !!abbSmartSensorDatas)),
      this.selectedMapElementService.selectedRopeway$,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([abbSmartSensorDatas, ropeway]) => {
        this.class = this.getCssClass(abbSmartSensorDatas, ropeway?.sisId);
      });
  }

  private getCssClass(abbSmartSensorDatas: AbbSmartSensorData[], selectedRopewaySisId: string): string {
    let cssClass = AbbSmartSensorWidgetComponent.cssClassGood;

    let sensorData = abbSmartSensorDatas;

    if (selectedRopewaySisId) {
      sensorData = sensorData.filter((data) => data.deviceSisId === selectedRopewaySisId);
    }

    if (sensorData.some((data) => data.overallConditionIndex <= AbbSmartSensorConditionStatus.Unknown)) {
      cssClass = AbbSmartSensorWidgetComponent.cssClassUnknown;
    }

    if (sensorData.some((data) => data.overallConditionIndex <= AbbSmartSensorConditionStatus.NotApplicable)) {
      cssClass = AbbSmartSensorWidgetComponent.cssClassGood;
    }

    if (sensorData.some((data) => data.overallConditionIndex < AbbSmartSensorConditionStatus.Good)) {
      cssClass = AbbSmartSensorWidgetComponent.cssClassTolerable;
    }

    if (sensorData.some((data) => data.overallConditionIndex < AbbSmartSensorConditionStatus.Tolerable)) {
      cssClass = AbbSmartSensorWidgetComponent.cssClassPoor;
    }

    return cssClass;
  }
}
