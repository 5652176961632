import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { SisRoute } from 'src/app/domain/sis-route.model';
import { CKEditorQueryParams } from 'src/app/media-center/ckeditor/ckeditor-queryparams.model';
import { MediaCenterQueryParams } from 'src/app/media-center/domain/mediacenter-queryparams.model';
import { Screen } from 'src/app/media-center/screens/domain/screen.model';
import { Slide } from 'src/app/media-center/slides/domain/slide.model';
import { MediaCenterSlidesService } from 'src/app/media-center/slides/media-center-slides.service';
import { TimeControlModalComponent } from 'src/app/media-center/slides/time-control-modal/time-control-modal.component';
import { UserMessage } from 'src/app/user-message/user-message.model';
import { UserMessageService } from 'src/app/user-message/user-message.service';
import { UserMessageColor } from 'src/app/user-message/user-message-color';
import { UserMessageIcon } from 'src/app/user-message/user-message-icon';

@Component({
  selector: 'sis-media-center-slides',
  templateUrl: './media-center-slides.component.html',
  styleUrls: ['./media-center-slides.component.scss', '../media-center-global.scss'],
})
export class MediaCenterSlidesComponent extends Unsubscriber implements OnInit {
  private readonly inputValuesChanged$ = new Subject<Slide>();
  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;

  searchControl = new FormControl<string>('');
  slides: Slide[];
  slidesFiltered: Slide[];
  searching = false;
  searchTerm: string;
  writePermission: boolean;
  readPermission: boolean;
  bigScreenMode: boolean;
  selectedActivity: string = 'all';
  selectedType: string = 'all';
  rerender: boolean = false;

  daysArray: string[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  private queryParams: MediaCenterQueryParams;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private screenSizeService: ScreenSizeService,
    private userMessageService: UserMessageService,
    private destinationService: DestinationService,
    private slidesService: MediaCenterSlidesService,
    private translateService: TranslateService,
    private modalCtrl: ModalController,
    private logbookModalService: LogbookModalService
  ) {
    super();
  }

  ngOnInit() {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.slidesService.slides$.pipe(takeUntil(this.onDestroy$)).subscribe((slides) => {
      this.slides = [...slides.sort((a, b) => a.name?.localeCompare(b.name))];
      this.filterSlides();
    });

    this.inputValuesChanged$.pipe(takeUntil(this.onDestroy$), debounceTime(1000)).subscribe((updatedFileSettings) => {
      this.saveSlideUpdate(updatedFileSettings);
    });

    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroy$)).subscribe((search: string) => {
      this.searching = false;
      this.updateQueryParam('slideSearch', search?.toLowerCase());
    });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.WRITE))
      );
      this.readPermission = features.some((f) =>
        f.hasMinimumRequirementFor(new Feature(FeatureId.SISMEDIA_MEDIACENTER, FeatureAccessLevel.READ))
      );
    });

    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.rerender = true;
      setTimeout(() => {
        this.rerender = false;
      }, 50);
    });

    this.activatedRoute.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params) => {
      this.queryParams = params as MediaCenterQueryParams;
      this.searchTerm = this.queryParams.slideSearch?.toLowerCase();
      this.selectedType = this.queryParams.type ?? 'all';
      this.selectedActivity = this.queryParams.active ?? 'all';

      if (this.searchControl.value !== this.searchTerm) {
        this.searchControl.setValue(this.searchTerm);
      }

      this.filterSlides();
    });
  }

  deleteSlide(slide: Slide) {
    this.slidesService.deleteSlide(slide);
  }

  openEditor(slideGuid: string) {
    if (!this.writePermission) {
      return;
    }

    if (this.bigScreenMode) {
      const queryParams: CKEditorQueryParams = {};

      Object.assign(queryParams, this.queryParams);

      if (slideGuid) {
        queryParams.slide = slideGuid;
      }

      this.router.navigate([SisRoute.EDITOR], { relativeTo: this.activatedRoute.parent, queryParams });
    } else {
      this.userMessageService.presentToast(
        new UserMessage({
          message: 'mediacenter.phrase.notAvailableOnMobile',
          icon: UserMessageIcon.info,
          durationMs: 1000,
          position: 'top',
          color: UserMessageColor.blue,
        })
      );
    }
  }

  async openTimeControlModal(slide: Slide) {
    const modal = await this.modalCtrl.create({
      component: TimeControlModalComponent,
      componentProps: {
        slide: slide,
      },
      cssClass: 'sis-time-control-modal',
      backdropDismiss: false,
    });

    return modal.present();
  }

  async openLogbook(): Promise<void> {
    await this.logbookModalService.presentLogbookModal([[FeatureId.SISMEDIA_MEDIACENTER]]);
  }

  durationInput(event: any, slide: Slide): void {
    const duration = Number.parseInt(event.target.value, 10);

    if (this.writePermission && duration && !Number.isNaN(duration) && duration >= 0 && duration !== slide.duration) {
      slide.duration = duration;
      this.inputValuesChanged$.next(slide);
    }
  }

  enabledInput(event: any, slide: Slide): void {
    if (this.writePermission) {
      const enabled = !event.target.checked;

      slide.enabled = enabled;

      this.saveSlideUpdate(slide);
    }
  }

  compareWith(s1: Screen, s2: Screen): boolean {
    return s1 && s2 ? s1.label === s2.label : s1 === s2;
  }

  itemId(_: number, slide: Slide): string {
    return slide.url;
  }

  openInNewTab(url: string): void {
    const separator = url.includes('?') ? '&' : '?';
    window.open(`${url}${separator}${Math.round(Math.random() * 10000)}`, '_blank');
  }

  updateQueryParam(param: string, value: any) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { [param]: value },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private saveSlideUpdate(updatedSlide: Slide): void {
    if (updatedSlide && this.writePermission) {
      updatedSlide.updating = true;
      this.slidesService.updateSlide(updatedSlide);
    }
  }

  private filterSlides(): void {
    let filteredSlides = this.slides;

    if (this.searchTerm) {
      filteredSlides = this.slides.filter((d) => d.name.toLowerCase().includes(this.searchTerm));
    }

    if (this.selectedActivity === 'active') {
      filteredSlides = filteredSlides.filter((p) => p.enabled);
    } else if (this.selectedActivity === 'inactive') {
      filteredSlides = filteredSlides.filter((p) => !p.enabled);
    }

    if (this.selectedType === 'html') {
      filteredSlides = filteredSlides.filter((p) => p.fileType == 'html');
    } else if (this.selectedType === 'video') {
      filteredSlides = filteredSlides.filter((p) => p.fileType == 'video');
    } else if (this.selectedType === 'dynamic') {
      filteredSlides = filteredSlides.filter((p) => p.fileType == 'dynamic');
    }

    this.slidesFiltered = filteredSlides;
  }
}
