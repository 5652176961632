import { WindTrend } from 'src/app/maps/trenddata/wind-trend.model';

export class WindTrendAdapter {
  static adapt(items: any): WindTrend[] {
    const trends: WindTrend[] = [];
    if (items && items.length > 0) {
      let lastTimestampMs = new Date(items[0].timestamp).getTime();
      items.forEach((e: any) => {
        const timestampMs = new Date(e.timestamp).getTime();
        const timestampMsMaxExpectedValue = lastTimestampMs + 600_000;
        if (timestampMs > timestampMsMaxExpectedValue) {
          trends.push({ timestamp: new Date(timestampMsMaxExpectedValue) });
        }
        lastTimestampMs = timestampMs;

        trends.push({
          maxValue: e.windSpeedKmhMax != null ? Number(e.windSpeedKmhMax) : null,
          meanValue: e.windSpeedKmhAverage != null ? Number(e.windSpeedKmhAverage) : null,
          timestamp: new Date(e.timestamp),
        });
      });
    }

    return trends;
  }
}
