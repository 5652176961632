<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 187.5 225"
  style="cursor: pointer"
>
  <g id="Ebene_2" data-name="Ebene 2">
    <g id="Widget_Hintergrund_temp" data-name="Widget Hintergrund temp">
      <rect width="187.5" height="225" style="fill: none" />
    </g>
    <g id="Widgets">
      <g>
        <g>
          <rect width="187.5" height="225" style="fill: none" />
          <text
            text-anchor="middle"
            dominant-baseline="middle"
            x="50%"
            y="80"
            style="font-size: 52px; fill: #1c232a; font-family: Myriad Pro"
          >
            {{ 'general.term.efa' | translate }}
          </text>
          <text
            [attr.x]="textPositionX"
            [attr.y]="textPositionY"
            id="value"
            style="text-anchor: middle; fill: #1c232a"
            [style.font-size]="fontSize"
            [style.font-family]="fontFamily"
          >
            {{ efacode }}
          </text>
        </g>
        <path
          id="EFA_Rahmen"
          data-name="EFA Rahmen"
          d="M55.92,37.5l6,6h-12l-6-6Zm20,0h-12l6,6h12Zm8,0,6,6h12l-6-6Zm31.75,0h-12l6,6h12Zm8,0,6,6h12l-6-6Zm-87.72,0H28.27V48.38l6,6V43.5h7.66Zm10,69,6,6h12l-6-6Zm32,0h-12l6,6h12Zm8,0,6,6h12l-6-6Zm31.75,0h-12l6,6h12Zm8,0,6,6h12l-6-6Zm27.87-10.37V106.5h-7.88l6,6h7.89V102.13ZM37.94,106.5H34.27v-4.37l-6-6V112.5H43.93ZM28.27,76.37v12l6,6v-12Zm6-14-6-6v12l6,6Zm119.26,14v12l6,6v-12Zm6-14-6-6v12l6,6ZM143.65,37.5l6,6h3.89v4.88l6,6V37.5Z"
          [ngClass]="this.cssClass"
        />
      </g>
    </g>
  </g>
</svg>
