<ion-header>
  <ion-toolbar>
    <ion-button slot="end" fill="clear" (click)="this.close()">
      <ion-icon class="sis-close-button" name="close-outline"> </ion-icon>
    </ion-button>
    <ion-title>{{
      this.user ? ('usermanagement.phrase.editUser' | translate) : ('usermanagement.phrase.addUser' | translate)
    }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ion-grid class="sis-modal-grid">
    <ion-row>
      <form [formGroup]="this.formGroup">
        <ion-item class="form-group">
          <ion-label fixed>{{ 'general.term.email' | translate }}:</ion-label>
          <ion-input
            #emailInput
            type="email"
            class="form-control"
            formControlName="email"
            (ionBlur)="this.checkExistingUser($event)"
          ></ion-input>
        </ion-item>
        <ion-item class="form-group">
          <ion-label fixed>{{ 'usermanagement.term.displayName' | translate }}:</ion-label>
          <ion-spinner name="dots" *ngIf="this.showSpinner"></ion-spinner>
          <ion-input #displayNameInput type="text" class="form-control" formControlName="displayName"></ion-input>
        </ion-item>
        <ion-row>
          <ion-col>
            <ion-item lines="none">
              <ion-label fixed>{{ 'general.term.destination' | translate }}:</ion-label>
            </ion-item>
          </ion-col>
          <ion-col>
            <ion-item lines="none">
              <ion-label fixed>{{ 'general.term.features' | translate }}:</ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <cdk-virtual-scroll-viewport itemSize="40" minBufferPx="250" maxBufferPx="300">
          <div
            *cdkVirtualFor="let tenant of this.managedTenants; trackBy: tenantAlias"
            class="sis-tenant-item sis-global-hover"
            data-test="usermanagement-tenant"
          >
            <ion-checkbox
              (ionChange)="editUserTenantMapping(tenant.guid, $event.detail.checked)"
              [checked]="
                this.editedUser.tenantGuids.includes(tenant.guid) || (!this.user && activeTenantGuid === tenant.guid)
              "
              [disabled]="
                (tenant.activeUsers >= tenant.maxUsers &&
                  !this.user?.tenantGuids.includes(tenant.guid) &&
                  !this.isAdministrator) ||
                (this.isLoggedInUser && tenant.guid === this.activeTenantGuid) ||
                !tenant.hasWritePermission
              "
              data-test="tenant-checkbox"
              color="secondary"
            ></ion-checkbox>
            <div
              class="sis-tenant-name"
              [ngClass]="tenant.guid === this.activeTenantGuid ? 'sis-tenant-name-bold' : null"
            >
              {{ tenant.alias }}
            </div>
            <ion-icon
              matTooltip="{{ 'usermanagement.phrase.maxUsersReached' | translate }}"
              *ngIf="tenant.activeUsers >= tenant.maxUsers"
              class="sis-maxusers-reached-icon"
              name="warning-outline"
              data-test="usermanagement-warning-icon"
            ></ion-icon>
            <sis-tenant-feature-icon-component class="sis-tenant-feature-icon-component" lines="none" [tenant]="tenant">
            </sis-tenant-feature-icon-component>
          </div>
        </cdk-virtual-scroll-viewport>
      </form>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div *ngIf="this.bigScreenMode">
    <sis-general-button
      class="sis-modal-btn"
      text="usermanagement.term.deleteUser"
      width="150px"
      btnAlign="Left"
      btnType="Red"
      [showSpinner]="this.isDeleting"
      (click)="this.onDelete()"
      [disabled]="!this.user || this.isLoggedInUser"
      data-test="delete-btn"
    >
    </sis-general-button>

    <sis-general-button
      text="general.term.save"
      width="100px"
      [btnType]="'Secondary'"
      [showSpinner]="this.isSaving"
      (click)="this.onSave()"
      [disabled]="!this.modifiedAndValid"
      data-test="save-btn"
      class="sis-global-button-padding-right"
    >
    </sis-general-button>
    <sis-general-button
      text="general.term.cancel"
      [btnType]="'Secondary'"
      (click)="this.close()"
      data-test="cancel-btn"
    >
    </sis-general-button>
  </div>
  <div *ngIf="!this.bigScreenMode">
    <sis-general-button
      text="usermanagement.term.deleteUser"
      width="100%"
      btnType="Red"
      [showSpinner]="this.isDeleting"
      (click)="this.onDelete()"
      [disabled]="!this.user || this.isLoggedInUser"
      data-test="delete-btn"
    >
    </sis-general-button>
    <br />
    <sis-general-button
      text="general.term.save"
      width="100%"
      [btnType]="'Secondary'"
      [showSpinner]="this.isSaving"
      (click)="this.onSave()"
      [disabled]="!this.modifiedAndValid"
      data-test="save-btn"
    >
    </sis-general-button>
    <br />
    <sis-general-button
      width="100%"
      text="general.term.cancel"
      [btnType]="'Secondary'"
      (click)="this.close()"
      data-test="cancel-btn"
    >
    </sis-general-button>
  </div>
</ion-footer>
