import { Component, Input, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { UserSettingsService } from 'src/app/domain/user-settings/user-settings.service';
import { UserManagementService } from 'src/app/user-management/user-management.service';
import { UserPermissionUpdateData } from 'src/app/user-management/user-permission-update-data.model';

@Component({
  selector: 'sis-access-level-selector',
  templateUrl: './access-level-selector.component.html',
  styleUrls: ['./access-level-selector.component.scss'],
})
export class AccessLevelSelector extends Unsubscriber implements OnInit {
  private readonly user$ = this.userManagementService.userManagementData$.pipe(
    map((data) => data.users.find((p) => p.userGuid === this.userGuid)),
    filter((user) => !!user)
  );

  @Input() tenantFeature: Feature;
  @Input() userGuid: string;
  @Input() writePermission: boolean;

  private updateData: UserPermissionUpdateData;
  private feature: Feature;

  accessLevels = new Map<FeatureAccessLevel, { label: string; translateString: string }>();
  popoverOptions: any = {};
  accessLevelClass: string;
  translatedFeatureName: string;
  disabled: boolean;
  label: string;
  selectedAccessLevel: FeatureAccessLevel;

  constructor(private userManagementService: UserManagementService, private userSettingService: UserSettingsService) {
    super();
  }

  ngOnInit() {
    this.user$.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      this.feature =
        user.features.find((f) => f.featureId === this.tenantFeature.featureId) ??
        new Feature(this.tenantFeature.featureId, FeatureAccessLevel.NONE);

      if (this.tenantFeature.featureAccessLevel >= FeatureAccessLevel.NONE) {
        this.accessLevels.set(FeatureAccessLevel.NONE, {
          label: ' ',
          translateString: 'usermanagement.accesslevel.none',
        });
      }

      if (this.tenantFeature.featureAccessLevel >= FeatureAccessLevel.READ) {
        this.accessLevels.set(FeatureAccessLevel.READ, {
          label: 'R',
          translateString: 'usermanagement.accesslevel.read',
        });
      }

      if (this.tenantFeature.featureAccessLevel >= FeatureAccessLevel.WRITE) {
        this.accessLevels.set(FeatureAccessLevel.WRITE, {
          label: 'W',
          translateString: 'usermanagement.accesslevel.write',
        });
      }

      this.updateValues();
    });

    this.updatePopoverOptions();

    this.userSettingService.userSettings$.pipe(take(1)).subscribe((userSettings) => {
      this.disabled =
        !this.writePermission ||
        (userSettings.userGuid === this.userGuid && this.tenantFeature.featureId === FeatureId.USERMANAGEMENT);
    });
  }

  async changeAccessLevel(featureAccessLevel: FeatureAccessLevel): Promise<void> {
    if (this.feature.featureAccessLevel === featureAccessLevel) {
      return;
    }

    this.feature.featureAccessLevel = featureAccessLevel;

    this.updateValues();

    this.updateData = {
      userGuid: this.userGuid,
      featureId: this.tenantFeature.featureId,
      featureAccessLevel: this.feature.featureAccessLevel,
    };
    await this.userManagementService.updateUserPermission(this.updateData);
  }

  private updatePopoverOptions(): void {
    combineLatest([this.userManagementService.featureTranslationMap$, this.user$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([featureTranslationMap, user]) => {
        this.popoverOptions = {
          header: featureTranslationMap.get(this.tenantFeature.featureId),
          subHeader: user.displayName,
        };
        this.translatedFeatureName = this.popoverOptions.header;
      });
  }

  private updateValues(): void {
    this.selectedAccessLevel = this.feature.featureAccessLevel;
    this.label = this.accessLevels.get(this.selectedAccessLevel)?.label;
    this.accessLevelClass = 'sis-access-level-' + FeatureAccessLevel[this.feature.featureAccessLevel].toLowerCase();
  }
}
