import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { from, isObservable, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private confirmationDialogService: ConfirmationDialogService, private router: Router) {}

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    // or if we route a second time via app.resolver.ts (from :default to <destination>)
    if (
      !component ||
      this.router.getCurrentNavigation().finalUrl.root.children['primary'].segments[0].path !== 'default'
    ) {
      return of(true);
    }

    const canDeactivate = component.canDeactivate();

    if (isObservable(canDeactivate)) {
      return canDeactivate.pipe(switchMap((cD) => this.getCanDeactivateObservable(cD)));
    }

    return this.getCanDeactivateObservable(canDeactivate);
  }

  private getCanDeactivateObservable(cD: boolean): Observable<boolean> {
    return cD
      ? of(true)
      : // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
        // when navigating away from your angular app, the browser will show a generic warning message
        // see http://stackoverflow.com/a/42207299/7307355
        from(this.confirmationDialogService.presentAlert('general.phrase.unsavedChanges', 'OK'));
  }
}
