import { FirstEntriesData } from 'src/app/maps/livedata/first-entries.model';

export class FirstEntriesAdapter {
  static adapt(item: any): FirstEntriesData[] {
    const entries: FirstEntriesData[] = [];

    if (item) {
      item.forEach((e: any) => {
        entries.push({
          rowKey: e.RowKey,
          measuredValue: e.MeasuredValue,
          frequenciesValue: e.FrequenciesValue,
          measuredAt: new Date(e.MeasuredAt),
          timeoutInSeconds: e.Timeout,
        });
      });
    }

    return entries;
  }
}
