import { ExportAdapter } from 'src/app/domain/export/export.adapter';
import { Parking } from 'src/app/parking/domain/parking.model';

export class ParkingAdapter {
  static adapt(items: any): Parking[] {
    const result: Parking[] = [];

    if (items) {
      items.forEach((e: any) => {
        result.push(
          new Parking({
            id: e.id,
            guid: e.guid,
            name: e.name,
            label: e.label,
            status: e.status,
            lastStatusChange: new Date(e.lastStatusChange),
            cockpitOrder: e.cockpitOrder == null ? 999 : e.cockpitOrder,
            nameEn: e.nameEn,
            info: e.info,
            custom: e.custom,
            currentOccupancy: e.currentOccupancy,
            occupancyOffset: e.occupancyOffset,
            occupancyTimestamp: new Date(e.occupancyTimestamp),
            maxCapacity: e.maxCapacity,
            autoUpdate: e.autoUpdate,
            autoStatus: e.autoStatus,
            exports: ExportAdapter.adapt(e.exports),
          })
        );
      });
    }

    return result;
  }
}
