<svg version="1.1" viewBox="0 0 187.5 225" xmlns="http://www.w3.org/2000/svg">
  <g id="Icon" transform="matrix(2.9403,0,0,2.9403,18.573,45.738)" fill="#91cddb">
    <path
      d="m51.056 1.9622-3.5067 5.9707-3.7749-5.9707z"
      style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.7005; stroke: #91cddb"
    />
    <path
      d="m29.209 18.331-3.5067-5.9707-3.7749 5.9707z"
      style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.7005; stroke: #91cddb"
    />
    <path
      d="m32.85 8.1219v8.1556"
      style="fill: #008080; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.7005; stroke: #91cddb"
    />
    <path
      d="m36.492 6.0686v8.1556"
      style="fill: #008080; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.7005; stroke: #91cddb"
    />
    <path
      d="m40.133 4.0154v8.1556"
      style="fill: #008080; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.7005; stroke: #91cddb"
    />
    <path
      d="m43.775 1.9622v8.1556"
      style="fill: #008080; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.7005; stroke: #91cddb"
    />
    <path
      d="m29.209 10.175v8.1556"
      style="fill: #008080; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.7005; stroke: #91cddb"
    />
    <path
      d="m4.0378 28.2s-2.9541 0.09781-3.098-2.7327c-0.155-3.0479 3.3345-7.8753 4.9207-7.851 1.5862 0.024279 5.0034 5.2208 4.9536 8.2638-0.039639 2.4224-3.1031 2.3199-3.1031 2.3199s1.1391-1.068 0.73435-2.7907c-0.26519-1.1286-1.1205-1.9881-2.5792-2.0039-1.4587-0.015822-2.3695 1.0278-2.6314 2.1631-0.36557 1.5843 0.80315 2.6315 0.80315 2.6315z"
      style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.7005; stroke: #91cddb"
    />
    <path
      d="m8.9681 20.664 3.2681-0.003631 3.3283 1.7798"
      style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.7005; stroke: #91cddb"
    />
    <path
      d="m15.564 25.838-2.8976 1e-6 -2.2775-2.2424"
      style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.7005; stroke: #91cddb"
    />
    <path
      d="m49.89 20.75h-30.5v-8.24l1-4.49h-4.67v20.72h-1v3.78h5.67v-3.78h-1v-3h4.71l2 4h4.08l-1.17-4h7.88v-1.16l13-0.98z"
      style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.7005; stroke: #989898"
    />
  </g>
</svg>
