import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { SisMediaPage } from 'src/app/sismedia/sismedia.page';
import { AssetOverrideItemComponent } from 'src/app/sismedia/sismedia-asset-override/asset-override-item/asset-override-item.component';
import { SisMediaAssetOverrideComponent } from 'src/app/sismedia/sismedia-asset-override/sismedia-asset-override.page';
import { SismediaCategoryComponent } from 'src/app/sismedia/sismedia-category/sismedia-category.component';
import { SisMediaEditModalComponent } from 'src/app/sismedia/sismedia-edit-modal/sismedia-edit-modal.component';
import { SisMediaExportsEditModalComponent } from 'src/app/sismedia/sismedia-exports-edit-modal/sismedia-exports-edit-modal.component';
import { SisMediaItemComponent } from 'src/app/sismedia/sismedia-item/sismedia-item.component';
import { SisMediaOperatingHoursItemComponent } from 'src/app/sismedia/sismedia-operating-hours-modal/sismedia-operating-hours-item/sismedia-operating-hours-item.component';
import { SisMediaOperatingHoursModalComponent } from 'src/app/sismedia/sismedia-operating-hours-modal/sismedia-operating-hours-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: SisMediaPage,
      },
    ]),
    TranslateModule,
    MatTooltipModule,
    CoreModule,
  ],
  declarations: [
    SisMediaPage,
    SisMediaItemComponent,
    SisMediaAssetOverrideComponent,
    AssetOverrideItemComponent,
    SismediaCategoryComponent,
    SisMediaEditModalComponent,
    SisMediaOperatingHoursModalComponent,
    SisMediaOperatingHoursItemComponent,
    SisMediaExportsEditModalComponent,
  ],
})
export class SisMediaPageModule {}
