import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogbookModalService } from 'src/app/core/components/logbook/logbook-modal/logbook-modal.service';
import { Unsubscriber } from 'src/app/core/unsubscriber';
import { ConfirmationDialogService } from 'src/app/core/utils/confirmation-dialog.service';
import { ScreenSizeService } from 'src/app/core/utils/screen-size.service';
import { DestinationService } from 'src/app/domain/destination/destination.service';
import { Feature } from 'src/app/domain/feature/feature.model';
import { FeatureAccessLevel } from 'src/app/domain/feature/feature-access-level.model';
import { FeatureId } from 'src/app/domain/feature/feature-id.model';
import { SisMediaAssetStatus } from 'src/app/sismedia/domain/sismedia-asset-status.enum';
import { SisMediaItem } from 'src/app/sismedia/domain/sismedia-item.model';
import { SisMediaAssetOverrideEntry } from 'src/app/sismedia/sismedia-asset-override/domain/sismedia-asset-override-entry.model';
import { SisMediaAssetOverrideService } from 'src/app/sismedia/sismedia-asset-override/sismedia-asset-override.service';
import { NIL as emptyGuid } from 'uuid';

@Component({
  selector: 'sis-sismedia-asset-override',
  templateUrl: './sismedia-asset-override.page.html',
  styleUrls: ['./sismedia-asset-override.page.scss'],
})
export class SisMediaAssetOverrideComponent extends Unsubscriber implements OnInit {
  private static readonly requiredFeature = new Feature(FeatureId.SISMEDIA_ASSETOVERRIDE, FeatureAccessLevel.WRITE);

  private readonly changesMap = new Map<string, boolean>();

  readonly logbookAvailable$ = this.logbookModalService.logbookAvailable$;
  readonly insertingEntry$ = this.sisMediaAssetOverrideService.insertingEntry$;

  item: SisMediaItem;
  featureId: FeatureId;
  assetOverrideDatas: SisMediaAssetOverrideEntry[];
  hasEntries = false;
  writePermission: boolean;
  bigScreenMode: boolean;
  hasChanges: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private sisMediaAssetOverrideService: SisMediaAssetOverrideService,
    private screenSizeService: ScreenSizeService,
    private destinationService: DestinationService,
    private logbookModalService: LogbookModalService,
    private translateService: TranslateService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.screenSizeService.bigScreenMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((bigScreenMode) => (this.bigScreenMode = bigScreenMode));

    this.sisMediaAssetOverrideService
      .requestAssetOverrideData(this.item)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.item.hasActiveOverride = data.some((o) => o.active);
        this.hasEntries = data && data.length > 0;
        this.assetOverrideDatas = data;
      });

    this.destinationService.selectedTenantFeatures$.pipe(takeUntil(this.onDestroy$)).subscribe((features) => {
      this.writePermission = features.some((f) =>
        f.hasMinimumRequirementFor(SisMediaAssetOverrideComponent.requiredFeature)
      );
    });
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }

  changed({ guid, changed }): void {
    this.changesMap.set(guid, changed);

    this.hasChanges = [...this.changesMap.values()].some((v) => v);
  }

  cancel(): void {
    if (this.hasChanges) {
      this.confirmationDialogService
        .presentAlert('mediacenter.phrase.exitWithoutSaving', 'mediacenter.term.exit')
        .then((confirm) => {
          if (confirm) {
            this.closeModal();
          }
        });
    } else {
      this.closeModal();
    }
  }

  async openLogbook(): Promise<void> {
    await this.logbookModalService.presentLogbookModal([[FeatureId.SISMEDIA_ASSETOVERRIDE, this.featureId]]);
  }

  async addEntry(): Promise<void> {
    if (this.writePermission) {
      const description = await firstValueFrom(this.translateService.get('assetoverride.phrase.newEntry'));
      const newEntry: SisMediaAssetOverrideEntry = {
        guid: emptyGuid,
        assetGuid: this.item.assetGuid,
        description,
        overrideStatus: SisMediaAssetStatus.Closed,
        startTime: '00:00',
        endTime: '00:00',
        active: false,
        priority: this.assetOverrideDatas.reduce((acc, value) => Math.max(acc, value.priority), 0) + 1,
      };

      await this.sisMediaAssetOverrideService.updateAssetOverride(
        newEntry,
        SisMediaAssetOverrideService.insertOperation
      );
    }
  }
}
