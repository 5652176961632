<ion-grid class="sis-global-page-grid">
  <sis-sismap-edit-token></sis-sismap-edit-token>
  <ion-row>
    <ion-col>
      <ion-card mode="md">
        <ion-card-header>
          <ion-card-title>SisMap - Status initialisieren</ion-card-title>
        </ion-card-header>

        <ion-card-content class="sis-card-content">
          <ion-grid>
            <ion-row>
              <ion-col>
                Hier kann das Kopieren des aktuellen Status von allen SisMedia Assets auf einer SisMap in den TableStorage ausgelöst werden. Wenn
                keine mapGuid angegeben wird, wird dies für alle SisMaps gemacht.
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>MapGuid:</ion-col>
            </ion-row>
            <ion-row style="padding-left: 5px">
              <ion-col class="sis-input">
                <ion-input [formControl]="this.mapGuid" />
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <sis-general-button text="Initialisieren" width="200px" (sisClick)="this.initializeSisMapAssetStatus()" btnAlign="Right">
                </sis-general-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-card mode="md">
        <ion-card-header>
          <ion-card-title>Gateway - Updatescript aktualisieren</ion-card-title>
          <ion-card-subtitle *ngIf="!this.canManageGateways" style="color: var(--ion-color-danger)"
            >Diese Funktion ist auf Staging nicht verfügbar</ion-card-subtitle
          >
        </ion-card-header>

        <ion-card-content *ngIf="this.canManageGateways" class="sis-card-content">
          <ion-grid>
            <ion-row>
              <ion-col>
                Hier können die Gateway Update Skripts auf den Gateways aktualisiert werden. Das Update Skript wird auf allen erreichbaren Gateways
                aktualisiert.<br />Zuerst muss das gateway-update Skript in den Blobstorage hochgeladen werden.
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>BlobStorage Pfad:<br />DEV: <i>devcockpit/admin</i><br />PROD: <i>prodcockpit/admin</i> </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <sis-general-button text="Updatescript aktualisieren" width="200px" (sisClick)="this.uploadGatewayUpdateScript()" btnAlign="Right">
                </sis-general-button>
              </ion-col>
            </ion-row>
          </ion-grid>
          <sis-gateway-response-box [gatewayResponses]="this.updateGatewayUpdateScriptResponses"></sis-gateway-response-box>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-card mode="md">
        <ion-card-header>
          <ion-card-title>Gateway - Neustart</ion-card-title>
          <ion-card-subtitle *ngIf="!this.canManageGateways" style="color: var(--ion-color-danger)"
            >Diese Funktion ist auf Staging nicht verfügbar</ion-card-subtitle
          >
        </ion-card-header>

        <ion-card-content *ngIf="this.canManageGateways" class="sis-card-content">
          <ion-grid>
            <ion-row>
              <ion-col
                >Hier können einzelne oder mehrere Gateways neu gestartet werden. Bei mehreren Gateways sind die GatewayIds durch ein Komma zu trennen
                (z.B.: dev000, dev001)
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>GatewayId(s):</ion-col>
            </ion-row>
            <ion-row style="padding-left: 5px">
              <ion-col class="sis-input">
                <ion-input [formControl]="this.gatewayId" />
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <sis-general-button
                  [disabled]="gatewayIdsInvalid$ | async"
                  text="Gateway(s) Neustarten"
                  width="200px"
                  (sisClick)="this.restartGateway()"
                  btnAlign="Right"
                >
                </sis-general-button>
              </ion-col>
            </ion-row>
          </ion-grid>
          <sis-gateway-response-box *ngIf="this.canRestartGateways" [gatewayResponses]="this.restartGatewaysResponses"></sis-gateway-response-box>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
