<ion-header>
  <ion-toolbar>
    <ion-title class="sis-infotext-header"> {{ title }} </ion-title>
    <ion-button fill="clear" slot="end" (click)="this.close()"
      ><ion-icon class="sis-close-button" name="close"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content sis-ion-content-scrollbar>
  <div class="sis-language-item">
    <span class="sis-language-label">Deutsch</span>
    <ion-textarea
      class="sis-textarea sis-global-textarea-no-scrollbar sis-global-textarea"
      rows="5"
      [(ngModel)]="editableData.textDe"
      (ionChange)="this.inputChanged()"
    ></ion-textarea>
  </div>
  <div class="sis-language-item">
    <span class="sis-language-label">English</span>
    <ion-textarea
      class="sis-textarea sis-global-textarea-no-scrollbar sis-global-textarea"
      rows="5"
      [(ngModel)]="editableData.textEn"
      (ionChange)="this.inputChanged()"
    ></ion-textarea>
  </div>
  <div class="sis-language-item">
    <span class="sis-language-label">Français</span>
    <ion-textarea
      class="sis-textarea sis-global-textarea-no-scrollbar sis-global-textarea"
      rows="5"
      [(ngModel)]="editableData.textFr"
      (ionChange)="this.inputChanged()"
    ></ion-textarea>
  </div>
  <div class="sis-language-item">
    <span class="sis-language-label">Italiano</span>
    <ion-textarea
      class="sis-textarea sis-global-textarea-no-scrollbar sis-global-textarea"
      rows="5"
      [(ngModel)]="editableData.textIt"
      (ionChange)="this.inputChanged()"
    ></ion-textarea>
  </div>
</ion-content>

<ion-footer class="ion-no-border sis-modal-footer">
  <div>
    <sis-general-button
      [text]="'general.term.save'"
      class="sis-global-button-padding-right"
      [btnType]="'Secondary'"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      (sisClick)="this.save()"
      [disabled]="!this.edited"
    ></sis-general-button>
    <sis-general-button
      [text]="'general.term.cancel'"
      [btnType]="'Secondary'"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      (sisClick)="this.close()"
      [disabled]="this.saving"
    ></sis-general-button>
    <sis-general-button
      [text]="'infotext.term.clear'"
      [btnType]="'Red'"
      [btnAlign]="'Left'"
      [width]="this.bigScreenMode ? '120px' : '100%'"
      (sisClick)="this.clear()"
      [disabled]="this.saving"
    ></sis-general-button>
  </div>
</ion-footer>
