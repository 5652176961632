import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MeteoStation } from 'src/app/maps/domain/meteostation.model';
import { MeteoLivedata } from 'src/app/maps/livedata/meteo-livedata.model';
import { MeteoLivedataService } from 'src/app/maps/livedata/meteo-livedata.service';
import { MeteoStationService } from 'src/app/maps/meteostation-service/meteostation.service';
import { WidgetBase } from 'src/app/maps/widgets/widget-base';

@Component({
  selector: 'sis-temperature-widget',
  templateUrl: './temperature-widget.component.html',
  styleUrls: ['./temperature-widget.component.scss'],
})
export class TemperatureWidgetComponent extends WidgetBase implements OnInit {
  private readonly maxTemperatureCelsius: number = 40;
  private readonly minTemperatureCelsius: number = -25;
  private readonly barMaxHeight = 93;
  private readonly barStartYValue = 116.3;

  temperatureCelsius: string;
  innerBarHeight: string;
  innerBarYValue: string;

  constructor(private meteoStationService: MeteoStationService, private meteoLiveDataService: MeteoLivedataService) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.meteoLiveDataService.meteoLiveDatas$,
      this.meteoStationService.meteoStationWithLowestTemperature$,
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([meteoLiveDatas, meteoStation]) => {
        this.update(meteoLiveDatas, meteoStation);
      });
  }

  private update(meteoLiveDatas: MeteoLivedata[], meteoStation: MeteoStation): void {
    if (!meteoLiveDatas || !meteoStation) {
      this.resetToDefault();
      return;
    }

    const meteoLiveData = this.findDataForStation(meteoStation, meteoLiveDatas);
    if (!meteoLiveData || meteoLiveData.outdated) {
      this.resetToDefault();
      return;
    }

    this.calcAndSetDisplayValues(meteoLiveData.temperatureCelsius);
  }

  private resetToDefault(): void {
    this.calcAndSetDisplayValues(null);
  }

  private findDataForStation(meteoStation: MeteoStation, liveDatas: MeteoLivedata[]): MeteoLivedata {
    return liveDatas.find((d) => d.meteoDeviceSisId === meteoStation.meteoDeviceSisId);
  }

  private calcAndSetDisplayValues(temperatureCelsiusValue: number) {
    this.temperatureCelsius =
      temperatureCelsiusValue != null ? temperatureCelsiusValue.toString() : WidgetBase.valueUnknown;
    let innerHeight =
      (this.barMaxHeight / (this.maxTemperatureCelsius + Math.abs(this.minTemperatureCelsius))) *
      (temperatureCelsiusValue ? temperatureCelsiusValue + Math.abs(this.minTemperatureCelsius) : 0);

    innerHeight =
      innerHeight <= this.barMaxHeight && innerHeight >= 0
        ? innerHeight
        : innerHeight > this.barMaxHeight
        ? this.barMaxHeight
        : 0;
    this.innerBarHeight = innerHeight + 'px';
    this.innerBarYValue = this.barStartYValue - innerHeight + 'px';
  }
}
